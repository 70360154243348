(function () {
    'use strict';

    function APIController($scope, authService, accountService, $rootScope, $window, integrationService) {

        var vm = this, initialKeys = [];
        vm.authService = authService;

        $rootScope.viewData = $rootScope.viewData || {};
        $rootScope.viewData.breadcrumbs = $rootScope.viewData.breadcrumbs || [];
        $rootScope.viewData.breadcrumbs = [{
            title: 'API',
            link_name: 'API',
            link_url: '#/api',
            help_url: $rootScope.help_url
        }];
    
        $scope.secretsVisible = false;

        $scope.showDocsTab = false;
        $scope.showDropCowboyCredentials = true;

        $scope.staticContent = {
            intro: "Directly integrate into your software using our open API. To get started, please read our documentation (<a href='http://www.callcowboy.com/api' target='_blank'>http://www.callcowboy.com/api</a>)",
            tabs: [
                {
                    title: "Settings",
                    content: {
                        credentials: {
                            titles: {
                                standard: "Authentication Credentials",
                                dropcowboy: "Drop Cowboy integration"
                            },
                            fields: ["Key", "user_id", "Secret", "name", "value"]
                        },
                        webhook: {
                          title: 'Webhooks',
                          subtitle: 'Manage your webhooks below',
                        },
                        addWebhookBtnTitle: '+ Add webhook',
                        editBtnText: "Edit",
                        saveBtnText: "Save",
                        newBtnText: "New",
                        generateBtnText: "Generate new credentials",
                        cancelBtnText: "Cancel",
                        loader: "Loading",
                        saving: "Saving"
                    },
                },
                {
                    title: "Docs",
                    content: {
                        getStarted: {
                            title: "Getting Started",
                            text: "Invoke our api from your application by sending an HTTP POST containing a json document with the attributes listed below.  Once delivery is attempted, you will receive a callback containing the delivery status to the webhook you specified in your api settings.",
                        },
                        ringlessVoicemail: {
                            title: "Sending Ringless Voicemail",
                            href: "https://api.dropcowboy.com/v1/rvm",
                            method: "POST",
                            contentType: {
                                title: "Content-Type:",
                                text: "application/json"
                            },
                            attributes: {
                                title: "Attributes",
                                array: [
                                    {
                                        attr: "team_id",
                                        decr: "Located on the API Settings tab",
                                        type: "STRING",
                                        example: "Example: <Your Account>"
                                    },
                                    {
                                        attr: "secret",
                                        decr: "Located on the API Settings tab",
                                        type: "STRING",
                                        example: "Example: <Your Secret>"
                                    },
                                    {
                                        attr: "audio_url",
                                        decr: "A url to the voicemail audio to play (mp3 or wav)",
                                        type: "STRING",
                                        example: "Example: https://example.com/your-file.mp3"
                                    },
                                    {
                                        attr: "audio_type",
                                        decr: "The file type of audio_url: 'mp3' or 'wav'",
                                        type: "STRING",
                                        example: "Example: 'mp3'"
                                    },
                                    {
                                        attr: "phone_number",
                                        decr: "Your contact's phone number in E.164 format",
                                        type: "STRING",
                                        example: "Example: '+15552223333'"
                                    },
                                    {
                                        attr: "caller_id",
                                        decr: "The sender's phone number in E.164 format",
                                        type: "STRING",
                                        example: "Example: '+15557778888'"
                                    },
                                    {
                                        attr: "callback_url",
                                        decr: "Optional: Override the default RVM webhook url that you specified in your API settings.",
                                        type: "STRING",
                                        example: "Example: 'http://example.com"
                                    },
                                    {
                                        attr: "foreign_id",
                                        decr: "A value to identify this drop in your system.  Will be passed through to you in the webhook callback.  Max length 256 characters.",
                                        type: "STRING",
                                        example: "Example: <Your system's ID>"
                                    }
                                ]
                            },
                            example: {
                                title: "Example:",
                                text_1 : "curl --header \"Content-Type: application/json\" \\",
                                text_2: "--data '{\"team_id\": \"<your team_id>\", \"secret\": \"<your secret>\", \"audio_url\": \"https://example.com/your-file.mp3\", \"audio_type\": \"mp3\", \"phone_number\": \"+15552223333\", \"caller_id\":\"+15557778888\", \"foreign_id\": \"<your system value>\"}' \\ ",
                                text_3: "https://api.dropcowboy.com/v1/rvm"
                            },
                            callback: {
                                title: "System will call the callback url specified in the settings tab with json content like this:",
                                success: {'drop_id': '5c8eb7940a057a4ab0823e1a', 'phone_number': '+15552224444', 'attempt_date': '2019-03-17T21:10:26.000Z', 'status': 'success', 'reason': '', 'dnc': false, 'product_cost': 0.099, 'network': { 'name': 'Verizon Wireless:6006 - SVR/2', 'type': 'wireless' }, 'foreign_id': "<your system's id>"},
                                failure: {'drop_id': '5c8ee427a8d9ef4758bfaca5', 'phone_number': '+15552224444', 'attempt_date': '2019-03-18T00:20:26.000Z', 'status': 'failure', 'reason': 'VoiceMail NotDetected', 'dnc': false, 'product_cost': 0, 'network': { 'name':'Verizon Wireless:6006 - SVR/2', 'type': 'wireless' }, 'foreign_id': "<your system's id>"}
                            }
                        }
                    }
                },
            ],
        };

        $scope.loading = {
            credentials: true,
            webhooks: true,
        };
        
        $scope.saving = {
            credentials: false
        };

        $scope.data = {
            api: null,
            user: authService.getUser(),
            webhooks: []
        };

        $scope.loadCredentials = function() {
            $scope.loading.credentials = true;

            let req = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId()
            };

            accountService.getApiKeys(req)
                .then(results => {
                    $scope.data.api = results;
                    $scope.loading.credentials = false;
                    if(Object.keys($scope.data.api).length === 0) {
                        loadPlaceholders();
                    }
                    $scope.$apply();
                })
                .catch(err => {
                    swal("Server Error", "We were unable to retrieve API credentials for your account.  Try again later.", "error");
                    console.log(err);
                    $scope.loading.credentials = false;
                    $scope.$apply();
                });
        };

        $scope.generateCredentials = function(type) {
            $scope.loading.credentials = true;

            let req = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                type: type
            };

            accountService.generateApiKeys(req)
                .then(results => {
                    $scope.data.api[type] = results;
                    $scope.loading.credentials = false;
                    $scope.secretsVisible = true;
                    $scope.$apply();
                })
                .catch(err => {
                    swal("Server Error", "We were unable to generate API credentials for your account.  Try again later.", "error");
                    console.log(err);
                    $scope.loading.credentials = false;
                    $scope.$apply();
                });
        };

        $scope.showGenerateCredsModal = function(type) {
            swal({
                title: 'Warning',
                text: 'Once you generate new credentials, the existing ones will stop working. Are you sure you want to proceed?',
                buttons: ['Cancel', 'Generate'],
                closeOnClickOutside: false
            }).then((isConfirm) => {
                if (isConfirm) {
                    $scope.generateCredentials(type);
                }
            });
        };

        $scope.updateCredentials = function(type) {

            if (!$scope.loading.credentials) {

                $scope.loading.credentials = true;

                let req = {
                    team_id: authService.getTeamId(),
                    user_id: authService.getUserId(),
                    type: type,
                    key: $scope.data.api[type].key,
                    secret: $scope.data.api[type].secret
                };

                accountService.updateApiKeys(req)
                    .then(results => {
                        $scope.data.api[type] = results;
                        $scope.secretsVisible = false;
                    })
                    .catch(err => {
                        swal("Server Error", "We were unable to update your account.  Try again later.", "error");
                        console.log(err);
                    })
                    .then(() => {
                        $scope.loading.credentials = false;
                        $scope.$apply();
                    });
            }
        };

        function loadPlaceholders() {
            $scope.data.api.standard = {
                key: '',
                secret: '',
                type: 'standard'
            };
        };

        $scope.cancelEdit = function() {
            $scope.secretsVisible = false;
        };

        $scope.getWebhooks = () => {
            $scope.data.webhooks = [];
            $scope.loading.webhooks = true;
            integrationService.list()
                .then(res => {
                    integrationService.set(res);
                    $scope.data.webhooks = integrationService.get()['webhook'];
                    $scope.loading.webhooks = false;
                })
        }

        $scope.onInit = function() {
            $window.scrollTo(0, 0);
            $scope.loadCredentials();
            $scope.getWebhooks();

            $('#createWebhook').on('hide.bs.modal', function (e) {
                $scope.getWebhooks();
            });
        };

        $scope.deleteWebhook = (integrationId) => {
            integrationService.deleteWebhook(integrationId)
                .then(() => {
                    $scope.getWebhooks();
                })
        }

        if (authService.hasAccount()) {
            $scope.onInit();
        }
        else {
            $scope.$on('auth_complete', function(event, args) {
                $scope.onInit();
            });
        }
    }
    module.exports = APIController;
})();
