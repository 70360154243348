(function() {
    'use strict';

    function AddNumbersModalController($rootScope, $scope, $window, $location, $cookies, authService, phoneService, accountService, userService) {

        var vm = this;
        vm.authService = authService;

        $scope.data = {
            numbers: [],
            rent_number: null,
            number_type: 'Local',
            area_code: '',
            iso_country_code: 'US',
            isoToCountry: {
                'CA': 'Canada',
                'US': 'United States'
            },
            trial_limit: 3,
            trial_limit_desc: 'You are limited to purchasing three phone numbers during your free trial.  To purchase additional phone numbers, please end your free trial by visiting \'My Account->My Subscription\' and complete the checkout process.'
        };

        $scope.loading = {
            numbers: false,
            rent: false
        };

        $scope.staticData = {
            title: 'Add Phone Number(s)',
            findBlock: {
                title: 'Find available numbers',
                subtitle: 'Select a country and search by area code...',
                numberType: ['Local', 'Toll Free'],
                searchInputPlaceholder: 'Prefix / Area Code',
                searchBtn: 'Search',
                optionMessage: '* MMS messaging not available for toll-free numbers'
            },
            resultBlock: {
                title: 'Available numbers',
                subtitle: 'These numbers are available to rent...',
                loader: 'Loading',
                table: {
                    header: ['Phone Number'],
                    optionTd: {
                        mainText: 'Premium Feature',
                        smallText: 'Membership Required'
                    },
                    actionsTd: {
                        upgradeBtn: 'Become a Member',
                        rentBtn: 'Rent Number'
                    },
                    noRes: 'No numbers match your search criteria. Change your criteria and try again.'
                }
            },
            form: {}
        };

        $scope.formatPhone = function(value) {
            const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
            const numberFormat = ($scope.data.iso_country_code === 'US' || $scope.data.iso_country_code === 'CA') ? libphonenumber.PhoneNumberFormat.NATIONAL : libphonenumber.PhoneNumberFormat.INTERNATIONAL

            let phoneLocale = value;

            try {
                const firstSymbol = (value.charAt(0) === '+') ? '' : '+';
                const parsed = phoneUtil.parse(firstSymbol + value, $scope.data.iso_country_code);
                if (parsed) {
                    phoneLocale = phoneUtil.format(parsed, numberFormat);
                }
            }
            catch(e) {
                console.log(e);
            }
            return phoneLocale;
        };

        $scope.setIsoCountry = function(value) {
            $scope.data.iso_country_code = value;
        };

        $scope.searchNumbers = function(event, phoneNumber) {
            if(event){
                event.preventDefault();
            }

            $scope.loading.numbers = true;

            let req = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                country_iso: $scope.data.iso_country_code,
                pattern: phoneNumber || $scope.data.area_code
            };

            if ($scope.data.iso_country_code === 'US' && $scope.data.number_type === 'Toll Free') {
                req.type = 'tollfree';
            }

            phoneService.searchAvailable(req)
                .then(results => {
                    $scope.data.numbers = results || [];
                    $scope.loading.numbers = false;
                    $cookies.remove('checkoutPhoneNumber');
                    $scope.$apply();
                })
                .catch(err => {
                    console.log(err);
                    $scope.loading.numbers = false;
                    $cookies.remove('checkoutPhoneNumber');
                    $scope.$apply();
                });
        };

        $scope.tryRent = function(number) {
            $scope.rent(number);
        };

        $scope.rent = function(value) {
            const existingNumbers = $rootScope.team_phone_numbers || [];
            if (existingNumbers.length >= $scope.data.trial_limit && $rootScope.isFreeTrial()) {
                swal({
                    title: "Free Trial Limit",
                    text: $scope.data.trial_limit_desc,
                    buttons: ['OK', 'View Subscriptions'],
                    closeOnClickOutside: false,
                    icon: 'info'
                }).then( confirm => {
                    if (confirm) {
                        $rootScope.openLocation('subscriptions');
                    }
                });

                return;
            }

            if(!$scope.loading.rent) {
                $scope.loading.rent = true;
                $scope.data.rent_number = value;
                let req = {
                    team_id: authService.getTeamId(),
                    user_id: authService.getUserId(),
                    numbers: [value]
                };

                phoneService.rentNumber(req)
                    .then(results => {
                        if (!results.numbers) {
                            swal('Error', 'We were unable to rent the selected number. Please try with a different one.', 'error');
                        } else {

                            $rootScope.loadNumbers();

                            // Give the user a dial-in number also so that they can access the dialer remotely
                            if (!existingNumbers.length) {
                                $scope.__rentDialIn();
                            }

                            if ($rootScope.isFreeTrial()) {
                                $scope.searchNumbers();
                            } else {
                                swal({
                                    title: 'Apply Changes?',
                                    text: 'Your phone number was added to your shopping cart. Continue adding numbers or proceed to checkout?',
                                    buttons: ['Keep Adding', 'Checkout'],
                                    dangerMode: true,
                                    closeOnClickOutside: false
                                }).then((confirm) => {
                                    if (!confirm) {
                                        $scope.searchNumbers();
                                    }
                                    else {
                                        $location.path('/subscriptions');
                                    }
                                });
                            }
                        }
                    })
                    .catch(err => {
                        swal('Error', 'We were unable to rent the selected number. Please try with a different one.', 'error');
                    })
                    .then(() => {
                        $location.search({});
                        $scope.data.rent_number = null;
                        $scope.loading.rent = false;
                        $scope.$apply();
                    });
            }
        };

        $scope.__rentDialIn = function() {
            const dialInReq = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId()
            };

            phoneService.rentDialIn(dialInReq)
                .then(results => {
                    $rootScope.$broadcast('dialIn.changed');
                });
        };

        $scope.onInit = function() {
            $scope.searchNumbers(null, $cookies.get('checkoutPhoneNumber'));

            $scope.user = $rootScope.user;

            $('#add-numbers-modal').on('hide.bs.modal', function() {
                $scope.data.loading = false;
            });
        };

        $scope.onInit();
    }

    module.exports = AddNumbersModalController;
})();
