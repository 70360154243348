(function () {
    'use strict';

    function RuleConfigController(authService, $rootScope, $scope, $location, automationService, contactService, integrationService, phoneService, dispatcherService, tagsService) {
        var vm = this;

        $rootScope.viewData.breadcrumbs = $rootScope.viewData.breadcrumbs || [];
        $rootScope.viewData.breadcrumbs = [{
            title: 'Automation',
            link_name: 'Automation',
            link_url: '#/automation',
            help_url: $rootScope.help_url
        }, {
            title: 'Rule Configuration',
            link_name: 'Rule Configuration',
            link_url: '#/rule-config'
        }];

        $scope.data = {
            loading: false,
            saving: false,
            isFormSubmitted: false,
            availableWebhookEvents: automationService.getEvents(),
            availableActions: automationService.getActionsAsArray(),
            lists: [],
            tags: []
        };

        $scope.forms = {
            rule_name: {
                value: null,
                is_valid: true
            },
            event: {
                value: null,
                is_valid: true
            },
            actions: [],
        };

        $scope.staticData = {
            nameBlock: {
                title: 'Automation Rules',
                subtitle: 'Define a set of rules to handle an event'
            },
            ruleFormBlock: {
                name: {
                    title: 'Rule Name',
                    inputPlaceholder: 'Rule Name',
                    errorMessage: 'Rule Name is required.'
                },
                event: {
                    title: 'Event',
                    inputPlaceholder: 'Please Choose Event...',
                    errorMessage: 'Event type is required.'
                },
                filters: {
                    title: 'Filters',
                    inputPlaceholder: '',
                }
            },
            actionsBlock: {
                rowTitle: 'Action',
                actionType: 'Please choose action type',
                selectListTitle: 'List',
                selectTagTitle: 'Tag',
                selectTagErrorMessage: 'Tag is required.',
                selectFromListTitle: 'From List',
                selectToListTitle: 'To List',
                selectListErrorMessage: 'List is required.',
                selectCallRouteTitle: 'Forward Calls To',
                selectCallRouteErrorMessage: 'Call route is required.',
                selectRecordingTitle: 'Voicemail Recording',
                selectRecordingErrorMessage: 'Media is required.',
                deleteTitle: 'Delete Rule',
                actionsErrorMessage: 'Please, add at least 1 action',
                actionsTypeErrorMessage: 'Action type is not valid.'
            },
            addBlock: {
                addRule: 'Add Action',
                createRule: 'Create Rule',
                saveChanges: 'Update Rule',
            },
            noRes: 'No Actions',
            loader: 'Loading',
            saving: 'Saving'
        };

        // filters start
        $scope.entities = {
            team: { title: 'Team', icon: 'fa-regular fa-users-rectangle' },
            user: { title: 'User', icon: 'fa-regular fa-circle-user' },
            contact: { title: 'Contact', icon: 'fa-regular fa-address-book' },
            call: { title: 'Call', icon: 'fa-regular fa-circle-phone' },
            call_recording: { title: 'Call Recording', icon: 'fa-regular fa-circle-microphone-lines' },
            integration: { title: 'Integration', icon: 'fa-regular fa-gears' },
            timeline_entry: { title: 'Timeline Entry', icon: 'fa-regular fa-circle-calendar' },
            status: { title: 'Status', icon: 'fa-regular fa-circle-check' },
            note: { title: 'Note', icon: 'fa-regular fa-note' },
            list: { title: 'List', icon: 'fa-regular fa-square-list' },
            export_contacts: { title: 'Export Contacts', icon: 'fa-regular fa-file-export' },
            import_contacts: { title: 'Import Contacts', icon: 'fa-regular fa-file-import' }
        };
        $scope.attributes = {
            first_name: { title: 'First Name' },
            last_name: { title: 'Last Name' },
            list_name: { title: 'List Name' },
            type: { title: 'Type' },
            text: { title: 'Text' },
            status: { title: 'Name' },
            name: { title: 'Name' },
            disposition: { title: 'Disposition' },
            from: { title: 'From' },
            to: { title: 'To' }
        };
        $scope.operators = {
            is: { title: 'is', type: 'any' },
            is_not: { title: 'is not', type: 'any'  },
            starts_with: { title: 'starts with', type: 'input' },
            ends_with: { title: 'ends with', type: 'input' },
            contains: { title: 'contains', type: 'input' },
            does_not_contain: { title: 'does not contain', type: 'input' },
            is_unknown: { title: 'is unknown', type: undefined },
            has_any_value: { title: 'has any value', type: undefined }
        };

        // call, call_recording, integration, timeline_entry, list, team

        $scope.availableAttributesSelectList = [
            { entity: 'contact', attribute: 'first_name', type: 'input' },
            { entity: 'contact', attribute: 'last_name', type: 'input' },
            { entity: 'user', attribute: 'first_name', type: 'input' },
            { entity: 'user', attribute: 'last_name', type: 'input' },
            { entity: 'call', attribute: 'type', type: 'select', selectOptions: [
                    { title: 'Contact', value: 'contact'},
                    { title: 'External', value: 'external'},
                ]
            },
            { entity: 'call', attribute: 'disposition', type: 'select', selectOptions: [] },
            { entity: 'call_recording', attribute: 'from', type: 'input'},
            { entity: 'call_recording', attribute: 'to', type: 'input'},
            { entity: 'integration', attribute: 'type', type: 'select', selectOptions: [] },
            { entity: 'status', attribute: 'name', type: 'input' },
            { entity: 'list', attribute: 'list_name', type: 'select', selectOptions: [] },
            { entity: 'note', attribute: 'text', type: 'input' },
            { entity: 'export_contacts', attribute: 'status', type: 'input' },
            { entity: 'import_contacts', attribute: 'status', type: 'input' },
        ];

        $scope.attributesSelectList = [];

        // selected filters
        $scope.filters = [];
        $scope.newFilter = {
            selected: null,
            operator: undefined,
            type: undefined,
            value: undefined,
            isValid: false
        };
        $scope.filterSubmitted = false;

        $scope.initEditFilter = function(item) {
            $scope.filterSubmitted = false;
            item.edit = {
                selected: {
                    title: $scope.getOptionsForOperatorDropdown(item).title,
                    entity: item.entity,
                    attribute: item.attribute
                },
                operator: item.operator,
                type: $scope.getOptionsForOperatorDropdown(item).type,
                value: item.value
            };
            item.title = $scope.getOptionsForOperatorDropdown(item.edit.selected).title;
            item.edit.isValid = $scope.isFilterValid(item);
        };

        $scope.closePopover = function(popoverId) {
            $(popoverId).popover('hide');
        };

        $(document).on('click', '#close-add-filter-popover', function () {
            $scope.closePopover('#dropdown-add-filter');
        });

        $scope.hideClosePopoverEventsListenerInit = function(filter) {
            $('#dropdown-' + filter.id).on('hide.bs.popover', function() {
                $scope.initEditFilter(filter);
                $('#btn-group-dropdown-' + filter.id).removeClass('show');
            });
            $('#dropdown-' + filter.id).on('show.bs.popover', function() {
                $('#btn-group-dropdown-' + filter.id).addClass('show');
            });
            $(document).on('click', '#close-filter-popover-' + filter.id, function () {
                $scope.closePopover('#dropdown-' + filter.id);
            });
        };

        $scope.initPopover = function(popoverId, contentId, popoverDivId) {
            var templateStr = '<div class="popover filter-popover" id="' + popoverDivId + '" role="tooltip"><div class="popover-body"></div></div>';
            $('#' + popoverId).popover({
                container: 'body',
                placement: 'bottom',
                html: true,
                content: $('#' + contentId),
                template: templateStr,
                trigger: 'click'
            });
        };

        $scope.$on('ngRepeatFinished', function() {
            _.forEach($scope.filters, function(filter) {
                $scope.initPopover('dropdown-' + filter.id, 'filterPopover' + filter.id, 'filter-popover-' + filter.id);
                $scope.hideClosePopoverEventsListenerInit(filter);
            });
            $(document).on('click', '.filter-btn', function () {
                $('.filter-btn').not(this).popover('hide');
            });
            $('.btn-group-dropdown').on('show.bs.dropdown', function() {
                $('.filter-btn').popover('hide');
            });
            $(document).on('click', '.ui-select-container', function () {
                $('.filter-btn').popover('hide');
            });
        });

        $scope.$on('$stateChangeStart', function() {
            $('.filter-btn').popover('hide');
        });

        $scope.initAddFilterPopover = function() {
            $scope.initPopover('dropdown-add-filter', 'addFilterPopover', 'add-filter-popover');
            $('#dropdown-add-filter').on('hide.bs.popover', function() {
                $scope.resetNewFilter();
                $('#btn-group-dropdown-add-filter').removeClass('show');
            });
            $('#dropdown-add-filter').on('show.bs.popover', function() {
                $('#btn-group-dropdown-add-filter').addClass('show');
            });
        };

        $scope.initFilters = function() {
            _.forEach($scope.filters, function(item) {
                $scope.initEditFilter(item);
            });
            $scope.initAddFilterPopover();
        };
        $scope.initFilters();

        $scope._changeOperatorValue = function(filterObj, popoverId) {
            filterObj.isValid = $scope.isFilterValid(filterObj);
            setTimeout(function() {
                $(popoverId).popover('update');
            }, 200);
        };

        $scope.changeOperatorValue = function(filter) {
            $scope._changeOperatorValue(filter.edit,'#dropdown-' + filter.id);
        };

        $scope.changeOperatorValueNewFilter = function() {
            $scope._changeOperatorValue($scope.newFilter,'#dropdown-add-filter');
        };

        $scope._changeOptionOperator = function(option, filterObj, popoverId) {
            filterObj.value = option.value;
            filterObj.isValid = $scope.isFilterValid(filterObj);
            setTimeout(function() {
                $(popoverId).popover('update');
            }, 200);
        };

        $scope.changeOptionOperator = function(option, filter) {
            $scope._changeOptionOperator(option, filter.edit,'#dropdown-' + filter.id);
        };

        $scope.changeOptionOperatorNewFilter = function(option) {
            $scope._changeOptionOperator(option, $scope.newFilter,'#dropdown-add-filter');
        };

        $scope._changeAttribute = function(filter, attribute) {
            filter.type = attribute.type;
            filter.selected = {
                title: attribute.title,
                entity: attribute.entity,
                attribute: attribute.attribute
            };
            filter.isValid = $scope.isFilterValid(filter);
        };

        $scope.changeAttribute = function(filter, attribute) {
            $scope._changeAttribute(filter, attribute);
        };

        $scope.changeAttributeNewFilter = function(attribute) {
            $scope._changeAttribute($scope.newFilter, attribute);
        };

        $scope.getSelectedOptionForOperator = function(filter) {
            return $scope.getObjectByField($scope.getOptionsForOperatorDropdown(filter.selected).selectOptions, 'value', filter.value);
        };

        $scope.getOptionsForOperatorDropdown = function(filter) {
            return _.find($scope.attributesSelectList, function(o) { return (o.entity === filter.entity && o.attribute === filter.attribute); });
        };

        $scope.getObjectByField = function(array, field, value) {
            return _.find(array, function(o) { return o[field] === value; });
        };

        $scope._setOperator = function(filterObj, operator, popoverId) {
            filterObj.value = undefined;
            filterObj.operator = operator;
            filterObj.isValid = $scope.isFilterValid(filterObj);
            setTimeout(function() {
                $(popoverId).popover('update');
            }, 200);
        };

        $scope.setOperator = function(filter, operator) {
            $scope._setOperator(filter.edit, operator, '#dropdown-' + filter.id);
        };

        $scope.setOperatorNewFilter = function(operator) {
            $scope._setOperator($scope.newFilter, operator, '#dropdown-add-filter');
        };

        $scope._resetFilter = function(filter) {
            filter.selected = null;
            filter.operator = undefined;
            filter.value = undefined;
            filter.type = undefined;
            filter.isValid = false;

            $scope.filterSubmitted = false;
        };

        $scope.resetNewFilter = function() {
            $scope._resetFilter($scope.newFilter);
        };

        $scope.isFilterValid = function(filter) {
            var isValid = !!(filter && filter.selected !== null && filter.operator &&
                (($scope.operators[filter.operator].type && filter.value) ||
                    (!$scope.operators[filter.operator].type && !filter.value)));
            return isValid;
        };

        $scope.editFilter = function(dropdownId, filter) {
            filter.edit.isValid = $scope.isFilterValid(filter.edit);
            $scope.filterSubmitted = true;

            if(filter.edit.isValid) {
                filter.entity = filter.edit.selected.entity;
                filter.attribute = filter.edit.selected.attribute;
                filter.operator = filter.edit.operator;
                filter.value = filter.edit.value;

                $scope.initEditFilter(filter);
                $scope.closePopover(dropdownId);
            }
        };

        $scope.addFilter = function(dropdownId) {
            $scope.newFilter.isValid = $scope.isFilterValid($scope.newFilter);
            $scope.filterSubmitted = true;

            if($scope.newFilter.isValid) {
                var id = ($scope.filters.length > 0) ? $scope.filters[$scope.filters.length - 1].id + 1 : 0;
                var filter = {
                    id: id,
                    entity: $scope.newFilter.selected.entity,
                    attribute: $scope.newFilter.selected.attribute,
                    operator: $scope.newFilter.operator,
                    value: $scope.newFilter.value
                };
                $scope.initEditFilter(filter);
                $scope.filters.push(filter);
                $scope.resetNewFilter();
                $scope.closePopover(dropdownId);
            }
        };

        $scope.removeFilter = function(dropdownId, filter) {
            $scope.closePopover(dropdownId);
            _.remove($scope.filters, function(o) {
                return (o.id === filter.id && o.entity === filter.entity && o.attribute === filter.attribute && o.operator === filter.operator && o.value === filter.value);
            });
        };
        // filter end

        $scope.getDataForActions = () => {
            $scope.data.loading = true;
            var teamId = authService.getTeamId();
            var userId = authService.getUserId();

            const listsRequest = {
                team_id: teamId,
                user_id: userId,
                get_total: true
            };

            const vmsRequest = {
                team_id: teamId,
                user_id: userId,
                recording_type: 'voicemail',
                names: true,
            };

            const callsRequest = {
                team_id: teamId,
                user_id: userId,
                names: true,
                omit_missed: true,
                call_type: "contact,external",
            };

            const dispositionsRequest = {
                team_id: teamId,
                user_id: userId,
                with_lists: true,
            };

            const tagsRequest = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
            };

            let callRoutesRequest = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId()
            };

            return Promise.all([
                contactService.getLists(listsRequest),
                integrationService.getRvmData(),
                phoneService.getVms(vmsRequest),
                integrationService.list(callsRequest),
                dispatcherService.getListsDispositions(dispositionsRequest),
                tagsService.getTags(tagsRequest),
                phoneService.getIvrs(callRoutesRequest)
            ])
                .then(data => {
                    const [
                        listsResults,
                        { media, numbers },
                        voicemails,
                        integrations,
                        dispositions,
                        tags,
                        ivrs
                    ] = data;

                    $scope.data.lists = listsResults.lists;
                    $scope.data.tags = tags;
                    const listAttr = $scope.availableAttributesSelectList.find(attr => attr.entity === 'list');
                    if (typeof listAttr !== 'undefined') {
                        listAttr.selectOptions = $scope.data.lists.map(list => ({title: list.list_name, value: list.list_id}));
                    }

                    var ivrsTemp = _.filter(ivrs, ['type', 'voice']);
                    $scope.data.ivrs = _.sortBy(ivrsTemp, ['is_default', 'created_at']).reverse();

                    $scope.data.media = media;
                    $scope.data.voicemails = voicemails;
                    $scope.data.dispositions = dispositions;
                    const integrationAttr = $scope.availableAttributesSelectList.find(attr => attr.entity === 'integration');
                    if (typeof integrationAttr !== 'undefined') {
                        integrationAttr.selectOptions = [];
                        integrations.forEach(integration => {
                            if (typeof integrationAttr.selectOptions.find(addedIntegration => addedIntegration.value === integration.integration_type) === 'undefined') {
                                integrationAttr.selectOptions.push({title: $scope.toTitleCase(integration.integration_type.replace('_', ' ')), value: integration.integration_type});
                            }
                        });
                    }
                    const dispositionsAttr = $scope.availableAttributesSelectList.find(attr => attr.entity === 'call' && attr.attribute === 'disposition');
                    if (typeof dispositionsAttr !== 'undefined') {
                        dispositionsAttr.selectOptions = Object.keys($scope.data.dispositions).map(dispositionKey => {
                            const disposition = $scope.data.dispositions[dispositionKey];
                            return {
                                value: `${disposition.is_default ? 'default' : disposition.list_id}:${disposition.id}`,
                                title: `${disposition.is_default ? 'All Lists' : disposition.list_name}: ${disposition.friendly}`,
                            };
                        });
                    }
                })
                .finally(() => {
                    if (!$scope.data.ruleId) {
                        $scope.data.loading = false;
                    }
                });
        };

        $scope.onInit = () => {
            $scope.data.ruleId = $location.search().id;

            $scope.getDataForActions()
                .then(() => {
                    if ($scope.data.ruleId) {
                        return $scope.getRule($scope.data.ruleId)
                    }
                    return Promise.resolve();
                })
                .finally(() => {
                    $scope.data.loading = false;
                    $scope.$apply();
                });
        };

        $scope.getRule = (ruleId) => {
            return automationService.getRule(ruleId)
                .then(data => {
                    $scope.forms.rule_name.value = data.rule_name;
                    $scope.forms.event.value = data.event_type;
                    $scope.changeEvent($scope.getEvent(data.event_type));
                    $scope.forms.actions = data.actions.map(ruleAction => {
                        const action = {
                            type: ruleAction.type,
                            action_properties: {}
                        };
                        switch (action.type) {
                            case automationService.getActions().CONTACT_LIST_ADD.name:
                            case automationService.getActions().CONTACT_LIST_REMOVE.name:
                                const listToAdd = {
                                    selected: null,
                                    is_valid: true,
                                }
                                const list = $scope.data.lists.find(availableList => availableList.list_id === ruleAction.list_id);
                                if (typeof list !== 'undefined') {
                                    listToAdd.selected = list;
                                }
                                action.action_properties.list = listToAdd;
                                break;
                            case automationService.getActions().CONTACT_TAG_ADD.name:
                            case automationService.getActions().CONTACT_TAG_REMOVE.name:
                                const tagToAdd = {
                                    selected: null,
                                    is_valid: true,
                                }
                                const tag = $scope.data.tags.find(availableTag => availableTag.tag_id === ruleAction.tag_id);
                                if (typeof tag !== 'undefined') {
                                    tagToAdd.selected = tag;
                                }
                                action.action_properties.tag = tagToAdd;
                                break;
                            case automationService.getActions().CONTACT_LIST_MOVE.name:
                                const fromListToAdd = {
                                    selected: null,
                                    is_valid: true,
                                }
                                const toListToAdd = {
                                    selected: null,
                                    is_valid: true,
                                }
                                const fromList = $scope.data.lists.find(availableList => availableList.list_id === ruleAction.from_list_id);
                                const toList = $scope.data.lists.find(availableList => availableList.list_id === ruleAction.to_list_id);
                                if (typeof fromList !== 'undefined') {
                                    fromListToAdd.selected = fromList;
                                }
                                action.action_properties.from_list = fromListToAdd;

                                if (typeof toList !== 'undefined') {
                                    toListToAdd.selected = toList;
                                }
                                action.action_properties.to_list = toListToAdd;
                                break;
                            case automationService.getActions().CONTACT_RVM_SEND.name:
                                const mediaToAdd = {
                                    selected: null,
                                    is_valid: true,
                                }
                                const callRouteToAdd = {
                                    selected: null,
                                    is_valid: true,
                                }
                                const media = $scope.data.media.find(availableMedia => availableMedia.media_id === ruleAction.media_id);
                                const callRoute = $scope.data.ivrs.find(availableCallRoute => availableCallRoute.ivr_id === ruleAction.call_route_id);
                                if (typeof media !== 'undefined') {
                                    mediaToAdd.selected = media;
                                }
                                action.action_properties.media = mediaToAdd;

                                if (typeof callRoute !== 'undefined') {
                                    callRouteToAdd.selected = callRoute;
                                }
                                action.action_properties.to = callRouteToAdd;
                                break;
                        }
                        return action;
                    });

                    $scope.filters = data.filters;
                    $scope.initFilters();
                })
        }

        if (authService.hasAccount()) {
            $scope.onInit();
        }
        else {
            $scope.$on('auth_complete', function(event, args) {
                $scope.onInit();
            });
        }

        $scope.getEvent = (event) => {
            const eventObj = $scope.data.availableWebhookEvents.find(availableEventObj => availableEventObj.event === event);
            return !!eventObj ? eventObj : {};
        }

        $scope.getAvailableActions = () => {
            const eventObj = $scope.data.availableWebhookEvents.find(availableEventObj => availableEventObj.event === $scope.forms.event.value);
            if (eventObj) {
                return eventObj.availableActions;
            }
            return [];
        }

        $scope.getActionLabel = (actionName) => {
            const actionObj = $scope.data.availableActions.find(action => action.name === actionName);
            return typeof actionObj !== 'undefined' ? actionObj.label : null
        }

        $scope.deleteRule = (ruleIndex) => {
            $scope.forms.actions.splice(ruleIndex, 1);
        }

        $scope.addAction = () => {
            $scope.forms.actions.push({
                type: null,
                action_properties: {}
            })
        }

        $scope.changeActionType = (action, selectedAction) => {
            action.type = selectedAction.name;
            action.action_properties = {};
            switch (selectedAction.name) {
                case automationService.getActions().CONTACT_LIST_ADD.name:
                case automationService.getActions().CONTACT_LIST_REMOVE.name:
                    action.action_properties = {
                        list: {
                            selected: null,
                            is_valid: true,
                        }
                    };
                    break;
                case automationService.getActions().CONTACT_TAG_ADD.name:
                case automationService.getActions().CONTACT_TAG_REMOVE.name:
                    action.action_properties = {
                        tag: {
                            selected: null,
                            is_valid: true,
                        }
                    };
                    break;
                case automationService.getActions().CONTACT_LIST_MOVE.name:
                    action.action_properties = {
                        from_list: {
                            selected: null,
                            is_valid: true,
                        },
                        to_list: {
                            selected: null,
                            is_valid: true,
                        },
                    };
                    break;
                case automationService.getActions().CONTACT_RVM_SEND.name:
                    action.action_properties = {
                        media: {
                            selected: null,
                            is_valid: true,
                        },
                        to: {
                            selected: null,
                            is_valid: true,
                        }
                    };
                    break;
            }
        }

        $scope.changeEvent = (eventObj) => {
            $scope.forms.event.value = eventObj.event;
            $scope.forms.actions = $scope.forms.actions.filter(createdAction => {
                if (!createdAction.type) {
                    return true;
                }
                return typeof eventObj.availableActions.find(action => action.name === createdAction.type) !== "undefined"
            })
            $scope.attributesSelectList = [];
            $scope.attributesSelectList = $scope.availableAttributesSelectList.filter(attribute => {
                var isAvailable = typeof eventObj.availableFilterEntities.find(availableEntity => attribute.entity === availableEntity) !== "undefined";
                if(isAvailable) {
                    attribute.title = $scope.entities[attribute.entity].title + ': ' + $scope.attributes[attribute.attribute].title;
                }
                return isAvailable;
            });

            $scope.filters = [];
        }

        $scope.validateControl = (type) => {
            if ($scope.data.isFormSubmitted) {
                $scope.forms[type].is_valid = !!$scope.forms[type].value;
            }
        }

        $scope.isListAddRemoveValid = function(action) {
            action.action_properties.list.is_valid = !!action.action_properties.list.selected;
        };
        $scope.isTagAddRemoveValid = function(action) {
            action.action_properties.tag.is_valid = !!action.action_properties.tag.selected;
        };
        $scope.isListMoveFromValid = function(action) {
            action.action_properties.from_list.is_valid = !!action.action_properties.from_list.selected;
        };
        $scope.isListMoveToValid = function(action) {
            action.action_properties.to_list.is_valid = !!action.action_properties.to_list.selected;
        };
        $scope.isListMoveValid = function(action) {
            action.action_properties.from_list.is_valid = !!action.action_properties.from_list.selected;
            action.action_properties.to_list.is_valid = !!action.action_properties.to_list.selected;
        };
        $scope.isRvmSendMediaValid = function(action) {
            action.action_properties.media.is_valid = !!action.action_properties.media.selected;
        };
        $scope.isRvmSendToValid = function(action) {
            action.action_properties.to.is_valid = !!action.action_properties.to.selected;
        };
        $scope.isRvmSendValid = function(action) {
            action.action_properties.media.is_valid = !!action.action_properties.media.selected;
            action.action_properties.to.is_valid = !!action.action_properties.to.selected;
        };

        $scope.invalidActionErrors = () => {
            if (!$scope.forms.actions.length) {
                return $scope.staticData.actionsBlock.actionsErrorMessage;
            }

            const invalidActions = $scope.forms.actions.filter(action => {
                if (!action.type) {
                    return true
                }

                switch (action.type) {
                    case automationService.getActions().CONTACT_LIST_ADD.name:
                    case automationService.getActions().CONTACT_LIST_REMOVE.name:
                        return !action.action_properties.list.is_valid;
                    case automationService.getActions().CONTACT_TAG_ADD.name:
                    case automationService.getActions().CONTACT_TAG_REMOVE.name:
                        return !action.action_properties.tag.is_valid;
                    case automationService.getActions().CONTACT_LIST_MOVE.name:
                        return !action.action_properties.from_list.is_valid || !action.action_properties.to_list.is_valid;
                    case automationService.getActions().CONTACT_RVM_SEND.name:
                        return !action.action_properties.media.is_valid || !action.action_properties.to.is_valid;
                }
            })

            if (invalidActions.length) {
                return $scope.staticData.actionsBlock.actionsTypeErrorMessage;
            }
            
            return null;
        }

        $scope.validateForm = () => {
            $scope.data.isFormSubmitted = true;

            $scope.validateControl('rule_name');
            $scope.validateControl('event');

            $scope.forms.actions.forEach(action => {
                switch (action.type) {
                    case automationService.getActions().CONTACT_LIST_ADD.name:
                    case automationService.getActions().CONTACT_LIST_REMOVE.name:
                        $scope.isListAddRemoveValid(action);
                        break;
                    case automationService.getActions().CONTACT_TAG_ADD.name:
                    case automationService.getActions().CONTACT_TAG_REMOVE.name:
                        $scope.isTagAddRemoveValid(action);
                        break;
                    case automationService.getActions().CONTACT_LIST_MOVE.name:
                        $scope.isListMoveValid(action);
                        break;
                    case automationService.getActions().CONTACT_RVM_SEND.name:
                        $scope.isRvmSendValid(action);
                        break;
                }
            })

            if (!$scope.forms.rule_name.is_valid ||
                !$scope.forms.event.is_valid ||
                !!$scope.invalidActionErrors()) {
                return false;
            }

            return true;
        }

        $scope.save = () => {
            if (!$scope.validateForm()) {
                return;
            }
            const filtersReq = _.cloneDeep($scope.filters);

            const req = Object.assign(Object.keys($scope.forms).reduce((reqObj, key) => {
                if (key === 'actions') {
                    if (key === 'actions') {
                        reqObj.actions = [];
                        $scope.forms[key].map(action => {
                            const actionObj = {
                                type: action.type
                            };
                            switch (action.type) {
                                case automationService.getActions().CONTACT_LIST_ADD.name:
                                case automationService.getActions().CONTACT_LIST_REMOVE.name:
                                    actionObj.list_id = action.action_properties.list.selected.list_id;
                                    break;
                                case automationService.getActions().CONTACT_TAG_ADD.name:
                                case automationService.getActions().CONTACT_TAG_REMOVE.name:
                                    actionObj.tag_id = action.action_properties.tag.selected.tag_id;
                                    break;
                                case automationService.getActions().CONTACT_LIST_MOVE.name:
                                    actionObj.from_list_id = action.action_properties.from_list.selected.list_id;
                                    actionObj.to_list_id = action.action_properties.to_list.selected.list_id;
                                    break;
                                case automationService.getActions().CONTACT_RVM_SEND.name:
                                    actionObj.media_id = action.action_properties.media.selected.media_id;
                                    actionObj.call_route_id = action.action_properties.to.selected.ivr_id;
                                    break;
                            }
                            reqObj.actions.push(actionObj);
                        })
                    }
                } else {
                    reqObj[key] = $scope.forms[key].value;
                }
                return reqObj;
            }, {}), {
                filters: filtersReq.map(filter => {
                    delete filter.edit;
                    return filter;
                })
            });

            let promise = Promise.resolve();
            if ($scope.data.ruleId) {
                promise = promise
                    .then(() => automationService.updateRule($scope.data.ruleId, req))
            } else {
                promise = promise
                    .then(() => automationService.createRule(req))
            }

            promise
                .then(() => {
                    $scope.data.saving = false;
                    $location.path('automation');
                })

        }

        $scope.toTitleCase = (str) => {
            return str.replace(
                /\w\S*/g,
                (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
            );
        }
    }

    module.exports = RuleConfigController;
})();


