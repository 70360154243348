(function() {
    'use strict';

    function WebformEditController($rootScope, $scope, authService, contactService, $location) {
        var vm = this;
        vm.authService = authService;

        $rootScope.viewData = $rootScope.viewData || {};
        $rootScope.viewData.breadcrumbs = $rootScope.viewData.breadcrumbs || [];
        $rootScope.viewData.breadcrumbs = [{
            title: 'Web Forms',
            link_name: 'Web Forms',
            link_url: '#/webforms',
            help_url: $rootScope.help_url
        }, {
            title: ($location.search().webformId ? 'Edit' : 'Create') + ' Web Form',
            link_name: ($location.search().webformId ? 'Edit' : 'Create') + ' Web Form',
            link_url: ''
        }];

        $scope.staticData = {
            formHeaderFields: [
                { label: 'Web Form Name', placeholder: 'Web Form Name' },
                { label: 'List', placeholder: 'Please select...' },
                { label: 'Success Message', placeholder: 'Success Message' },
                { label: 'Error Message', placeholder: 'Error Message' }
            ],
            fields: {
                title: 'Web Form Fields',
                addFieldBtn: 'Add Field',
                saveBtnTitle: 'Save Form',
                inputs: [
                    { label: 'Label', placeholder: 'Label' },
                    { label: 'Type', placeholderStandard: 'Standard Fields', placeholderCustom: 'Custom Fields' },
                    { label: 'Placeholder', placeholder: 'Placeholder' },
                    { label: 'Required' },
                    { label: 'Custom CSS Classes' },
                    { label: 'Hide Label' }
                ],
                fieldOptions: [
                    {
                        title: 'Custom CSS Classes',
                        options: [
                            { label: 'Container', placeholder: 'Container' },
                            { label: 'Label', placeholder: 'Label' },
                            { label: 'Input', placeholder: 'Input' }
                        ]
                    },
                    {
                        title: 'Options for Multi-line Text',
                        options: [
                            { label: 'Visible Rows', placeholder: 'Visible Rows' }
                        ]
                    },
                    {
                        title: 'Options for Number',
                        options: [
                            { label: 'Min', placeholder: 'Min' },
                            { label: 'Max', placeholder: 'Max' },
                            { label: 'Step', placeholder: 'Step' }
                        ]
                    },
                    {
                        title: 'Options for Hidden',
                        options: [
                            { label: 'Value', placeholder: 'Value' }
                        ]
                    },
                    {
                        title: 'Options for Select',
                        options: [
                            { label: 'Label', placeholder: 'Label' },
                            { label: 'Value', placeholder: 'Value' }
                        ],
                        actions: [
                            'Add Option Above',
                            'Add Option Below',
                            'Move Option Up',
                            'Move Option Down',
                            'Delete Option'
                        ]
                    }
                ],
                actions: [
                    'Add Field Above',
                    'Add Field Below',
                    'Move Field Up',
                    'Move Field Down',
                    'Delete Field'
                ]
            },
            loading: 'Loading',
            noRes: 'Empty List'
        };

        $scope.data = {
            webform: {},
            lists: [],
            fieldTypes: [
                // standard fields
                { value: 'main_phone', label: 'Main Phone', isCustom: false },
                { value: 'secondary_phone', label: 'Secondary Phone', isCustom: false },
                { value: 'first_name', label: 'First Name', isCustom: false },
                { value: 'last_name', label: 'Last Name', isCustom: false },
                { value: 'company', label: 'Company', isCustom: false },
                { value: 'email', label: 'Email', isCustom: false },
                { value: 'fax_number', label: 'Fax', isCustom: false },
                { value: 'address', label: 'Address', isCustom: false },
                { value: 'suite/apt', label: 'Suite/Apt', isCustom: false },
                { value: 'city', label: 'City', isCustom: false },
                { value: 'state', label: 'State', isCustom: false },
                { value: 'country', label: 'Country', isCustom: false },
                { value: 'zip_code', label: 'Zip Code', isCustom: false },
                { value: 'record_id', label: 'Record ID', isCustom: false },
                { value: 'reason', label: 'Reason', isCustom: false },
                // custom fields
                { value: 'custom_string', label: 'Single-line Text', isCustom: true },
                // { value: 'custom_number', label: 'Number', isCustom: true },
                // { value: 'custom_date', label: 'Date', isCustom: true },
                // { value: 'custom_email', label: 'Extra Email', isCustom: true },
                // { value: 'custom_select', label: 'Select', isCustom: true },
                { value: 'custom_text', label: 'Multi-line Text', isCustom: true },
                // { value: 'custom_hidden', label: 'Hidden', isCustom: true }
            ]
        };

        $scope.loading = {
            webform: true
        };

        $scope.getFieldTypes = function(isCustom) {
            let fields = [];
            for (let i = 0; i < $scope.data.fieldTypes.length; i++) {
                let f = $scope.data.fieldTypes[i];
                if (f.isCustom !== isCustom) { continue; }
                fields.push(f);
            }
            return fields;
        };

        $scope.fieldTypeChanged = function(field) {
            if (field.type.indexOf('custom_') === 0) {
                field.name = 'custom_' + Date.now();
            } else {
                field.name = field.type;
            }
            for (let i = 0; i < $scope.data.fieldTypes.length; i++) {
                let o = $scope.data.fieldTypes[i];
                if (field.type === o.value) {
                    field.label = (o.isCustom ? '' : o.label);
                }
            }
        };

        $scope.addField = function(field, isAbove) {
            let index = (
                field ? $scope.__getFieldIndex(field) : $scope.data.webform.fields.length - 1 );
            if (!isAbove) { index++; }
            let f = $scope.__createField();
            $scope.data.webform.fields.splice(index, 0, f);
            window.setTimeout(function() {
                $('.field-label').eq(index).focus();
            }, 100);
        };

        $scope.deleteField = function(field) {
            let index = $scope.__getFieldIndex(field);
            $scope.data.webform.fields.splice(index, 1);
        };

        $scope.moveFieldUp = function(field) {
            let index = $scope.__getFieldIndex(field);
            $scope.__applySwap($scope.data.webform.fields, index, index-1);
        };

        $scope.moveFieldDown = function(field) {
            let index = $scope.__getFieldIndex(field);
            $scope.__applySwap($scope.data.webform.fields, index, index+1);
        };

        $scope.addSelectOption = function(field, opt, isAbove) {
            let index = $scope.__getOptionIndex(field, opt);
            if (!isAbove) { index++; }
            let op = $scope.__createSelectOption();
            field.options.splice(index, 0, op);
        };

        $scope.moveSelectOptionUp = function(field, opt) {
            let index = $scope.__getOptionIndex(field, opt);
            $scope.__applySwap(field.options, index, index-1);
        };

        $scope.moveSelectOptionDown = function(field, opt) {
            let index = $scope.__getOptionIndex(field, opt);
            $scope.__applySwap(field.options, index, index+1);
        };

        $scope.deleteSelectOption = function(field, opt) {
            if (field.options.length === 1) { return; }
            let index = $scope.__getOptionIndex(field, opt);
            field.options.splice(index, 1);
        };

        $scope.__createSelectOption = function() {
            return { label: '', value: '', id: Date.now() };
        };

        $scope.__getFieldIndex = function(field) {
            for (let i = 0; i < $scope.data.webform.fields.length; i++) {
                if ($scope.data.webform.fields[i].name === field.name) { return i; }
            }
            return -1;
        };

        $scope.__getOptionIndex = function(field, opt) {
            for (let i = 0; i < field.options.length; i++) {
                if (field.options[i].id === opt.id) { return i; }
            }
            return -1;
        };

        $scope.__applySwap = function(a, i, j) {
            if (i < 0 || i >= a.length || j < 0 || j >= a.length) { return; }
            let t = a[i];
            a[i] = a[j];
            a[j] = t;
        };

        $scope.hideFieldType = function(field, type) {
            if (!$scope.data.webform.fields || type.isCustom || field.name === type.value) {
                return false;
            }
            for (let i = 0; i < $scope.data.webform.fields.length; i++) {
                let f = $scope.data.webform.fields[i];
                if (f.name === type.value) { return true; }
            }
            return false;
        };

        $scope.toggleFieldOptions = function(event) {
            let field = $(event.target).closest('.webforms-list-item');
            if ( !field.hasClass('field-options-open') ) {
                field.addClass('field-options-open');
            } else {
                field.removeClass('field-options-open');
            }
        };

        $scope.forceRequiredField = function(field) {
            if (field.name === 'main_phone') { field.required = true; }
        };

        $scope.saveWebform = function() {
            let error = $scope.__getWebformError();
            if (error) {
                swal('Error', error, 'error');
                return;
            }
            if ( !$scope.data.webform.webform_id ) {
                $scope.__createWebform();
                return;
            }

            $scope.loading.webform = true;
            const request = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                webform_id: $scope.data.webform.webform_id,
                webform: $scope.data.webform
            };

            contactService.updateWebform(request)
                .then(result => {
                    swal('Saved', 'The web form was succesfully saved.', 'success');
                    $location.path('/webforms/detail').search({webformId: $scope.data.webform.webform_id});
                })
                .catch(err => {
                    console.error(err);
                })
                .then(() => {
                    $scope.loading.webform = false;
                    $scope.$apply();
                });
        };

        $scope.__getWebformError = function() {
            let w = $scope.data.webform;
            w.webform_name = $scope.__trim(w.webform_name);
            w.success_msg = $scope.__trim(w.success_msg);
            w.error_msg = $scope.__trim(w.error_msg);
            if (!w.webform_name) { return 'Please enter a web form name.'; }
            if (!w.default_list_id) { return 'Please select a list.'; }
            if ( (w.success_msg || w.error_msg) && !(w.success_msg && w.error_msg) ) {
                return 'Please enter success and error messages, or leave both blank.';
            }
            if (!w.fields.length) { return 'Please add at least one field.'; }
            let hasMainPhone = false;
            for (let i = 0; i < w.fields.length; i++) {
                let f = w.fields[i];
                if (!f.label) { return 'Please enter a label for each field.'; }
                if (f.type === 'main_phone') { hasMainPhone = true; }
            }
            if (!hasMainPhone) { return 'Please include a Main Phone field.'; }
            return '';
        };

        $scope.__trim = function(s) {
            return s.replace(/^\s+|\s+$/gm, '');
        };

        $scope.__createWebform = function() {
            const request = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                webform: $scope.data.webform
            };

            contactService.createWebform(request)
                .then(result => {
                    swal('Saved', 'The web form was succesfully saved.', 'success');
                    $location.path('/webforms/detail').search({webformId: result.webform_id});
                })
                .catch(err => {
                    console.error(err);
                })
                .then(() => {
                    $scope.loading.webform = false;
                    $scope.$apply();
                });
        };

        $scope.__loadWebform = function(webformId) {
            const request = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                webform_id: webformId,
            };

            contactService.getWebformDetails(request)
                .then(webform => {
                    let fields = [];
                    for (let i = 0; i < webform.fields.length; i++) {
                        let f = webform.fields[i];
                        fields.push( $scope.__extendField(f) );
                    }
                    $scope.data.webform = webform;
                    $scope.data.webform.fields = fields;
                    if ( $location.search().duplicate ) {
                        $scope.data.webform.webform_id = '';
                    }
                    $scope.loading.webform = false;
                    $scope.$apply();
                })
                .catch(err => {
                    console.error(err);
                });
        };

        $scope.__initWebform = function() {
            // add some standard fields
            let fields = [], last = Math.min($scope.data.fieldTypes.length-1, 11);
            for (let i = 0; i <= last; i++) {
                let f = $scope.data.fieldTypes[i];
                if (f.isCustom) { continue; }
                fields.push( $scope.__createField(f) );
            }
            $scope.data.webform = {
                webform_name: '',
                default_list_id: '',
                success_msg: '',
                error_msg: '',
                fields: fields
            };
            $scope.loading.webform = false;
            $scope.$apply();
        };

        $scope.__createField = function(f) {
            // creates a field from a fieldType
            return {
                name: (f ? f.value : 'custom_' + Date.now()),
                type: (f ? f.value : 'custom_string'),
                label: (f ? f.label : ''),
                required: (f && f.value === 'main_phone'),
                placeholder: (f ? f.label : ''),
                class_names: { container: '', label: '', input: '' },
                rows: 3,
                min: '',
                max: '',
                step: 1,
                value: '',
                options: [ $scope.__createSelectOption() ],
                class_names_enabled: false,
                hide_label: true
            };
        };

        $scope.__extendField = function(f) {
            // extends a database field
            return {
                name: f.name,
                type: f.type,
                label: f.label,
                required: !!f.required,
                placeholder: f.placeholder || '',
                class_names: f.class_names || { container: '', label: '', input: '' },
                rows: f.rows || 3,
                min: (typeof f.min !== 'undefined' ? f.min : ''),
                max: (typeof f.max !== 'undefined' ? f.max : ''),
                step: f.step || 1,
                value: (typeof f.value !== 'undefined' ? f.value : ''),
                options: $scope.__extendOptions(f),
                class_names_enabled: !!f.class_names,
                hide_label: f.hide_label
            };
        };

        $scope.__extendOptions = function(f) {
            if (!f.options) { return [ $scope.__createSelectOption() ]; }
            for (let i = 0; i < f.options.length; i++) {
                f.options[i].id = i;
            }
            return f.options;
        };

        $scope.onInit = function() {
            const listsReq = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId()
            };

            contactService.getLists(listsReq)
                .then(lists => {
                    if (!lists || !lists.length) {
                        swal('Error', 'Please create a contact list before creating a web form.  Contacts will be placed into this list once they complete your web form.', 'error');
                        $location.path('/lists');
                        return;
                    }
                    $scope.data.lists = lists;

                    if ( $location.search().webformId ) {
                        $scope.__loadWebform( $location.search().webformId );
                        return;
                    }
                    $scope.__initWebform();
                })
                .catch(err => {
                    console.error(err);
                });
        };

        if (authService.hasAccount()) {
            $scope.onInit();
        }
        else {
            $scope.$on('auth_complete', function(event, args) {
                $scope.onInit();
            });
        }
    }
    module.exports = WebformEditController;
})();
