(function() {
    'use strict';

    function MyDashboardController(
        $rootScope, $scope, $location, $interval, authService, userService, tasksService, generalHelperService, contactService,
        phoneService, campaignService, dncService, boardsService, mediaService, voiceService, templateService, domainService,
        automationService, tagsService, $window,
    ) {
        var vm = this;

        vm.authService = authService;

        $rootScope.viewData = $rootScope.viewData || {};
        $rootScope.viewData.breadcrumbs = $rootScope.viewData.breadcrumbs || [];
        $rootScope.viewData.breadcrumbs = [{
            title: 'My Dashboard',
            link_name: 'My Dashboard',
            link_url: '#!my-dashboard',
            help_url: $rootScope.help_url
        }];

        // widget functions
        $scope.getInboxStats = function() {
            return tasksService.getUnreadTasksCount()
                .then(function(tasksCounts) {
                    if (tasksCounts.total) {
                        const stats = [
                            { label: 'Calls', value: (tasksCounts.inbound_call || 0) + (tasksCounts.missed_call || 0) },
                            { label: 'Emails', value: tasksCounts.email || 0 },
                            { label: 'Fax', value: tasksCounts.fax || 0 },
                            { label: 'Note', value: tasksCounts.note || 0 },
                            { label: 'SMS', value: tasksCounts.sms || 0 },
                            { label: 'VMS', value: tasksCounts.voicemail || 0 }
                        ];

                        const payload = {
                            title: 'UNREAD NOTIFICATIONS',
                            subtitle: { value: tasksCounts.total || 0, label: 'total' },
                            columns: []
                        };
                        for (let i = 0; i < stats.length; i += 3) {
                            const chunk = stats.slice(i, i + 3);
                            payload.columns.push(chunk);
                        }

                        return payload;
                    }

                    return { noStatsMessage: 'You\'re all caught up! No new notifications at the moment. If you want to review past messages, feel free to check your Inbox.' };
                });
        };
        $scope.getBucketsStats = function() {
            const reqParams = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId()
            };
            return boardsService.getBoards(reqParams)
                .then(function(boardData) {
                    if (boardData.length) {
                        const boards = boardData.sort((a, b) => a.updated_at < b.updated_at ? 1 : -1).slice(0, 3);
                        return {
                            title: 'RECENT UPDATES',
                            rows: boards.map(function(board) {
                                const timestamp = moment(board.updated_at);
                                return [
                                    { value: board.name, htmlTitle: board.name, colSize: 9 },
                                    { value: timestamp.format('MM/DD'), htmlTitle: timestamp.format('MM/DD/YYYY HH:mm'), colSize: 3 }
                                ];
                            })
                        };
                    }

                    return { noStatsMessage: 'It looks like you don\'t have any contact buckets set up yet. Head over to the Buckets dashboard to get started!' };
                });
        };
        $scope.getOutboundCallStats = function() {
            const request = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId()
            };
            return phoneService.getGlobalStatistics(request)
                .then(function(statistics) {
                    if (Object.keys(statistics).length) {
                        return {
                            title: 'CURRENT STATISTICS',
                            stats: [
                                { value: statistics.calls || 0, label: 'calls' },
                                { value: moment.utc(statistics.duration * 1000).format('HH:mm:ss') || '00:00:00', label: 'talk time' },
                                { value: statistics.messages || 0, label: 'messages' }
                            ]
                        };
                    }

                    return { noStatsMessage: 'It looks like you don\'t have any activity yet. Head over to the Outbound Calling to get started!' };
                });
        };
        $scope.getRVMStats = function() {
            const noStatsMessage = 'You don\'t have any RVM activity this week. Check out the RVM Campaigns dashboard to get sending!';
            var teamId = authService.getTeamId();
            var userId = authService.getUserId();

            const request = {
                team_id: teamId,
                user_id: userId,
                skip: 0,
                limit: 1
            };

            return campaignService.getCampaigns(request)
                .then(function(campaignsResults) {
                    if (campaignsResults.totalItems && campaignsResults.totalItems > 0) {
                        const campaign = campaignsResults.campaigns[0];

                        let req = {
                            campaign_id: campaign.campaign_id,
                            team_id: teamId,
                            user_id: userId,
                            yearly: true,
                            monthly: true,
                            daily: true,
                            hourly: true,
                            timezone: moment.tz.guess(),
                            start_at: moment().subtract(7,'d').valueOf()
                        };

                        return campaignService.getSummary(req)
                            .then(function(campaignResult) {
                                const keys = campaignResult.summary ? Object.keys(campaignResult.summary) : [];
                                if (keys.length) {
                                    const summary = campaignResult.summary[keys[keys.length - 1]];
                                    return {
                                        title: '7 DAY STATS',
                                        subtitle: { value: campaign.name },
                                        stats: [
                                            { value: summary.rvm.success || 0, label: 'sent' },
                                            { value: summary.rvm.failure || 0, label: 'failed' },
                                            { value: summary.rvm.pending || 0, label: 'pending' }
                                        ]
                                    };
                                }

                                return { noStatsMessage: noStatsMessage };
                            });
                    }

                    return { noStatsMessage: noStatsMessage };
                });
        };
        $scope.getCallTrackingStats = function() {
            const request = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId()
            };
            return phoneService.getGlobalStatistics(request)
                .then(function(statistics) {
                    if (Object.keys(statistics).length) {
                        return {
                            title: 'CALL TRACKING ROLLUP',
                            stats: [
                                { value: statistics.messages || 0, label: 'texts' },
                                { value: statistics.calls || 0, label: 'calls' },
                                { value: statistics.conversions || 0, label: 'conversions' }
                            ]
                        };
                    }

                    return { noStatsMessage: `You can track calls, texts, and conversions with ${$rootScope.settings.APP_NAME}. Explore our Call Tracking to find out more!` };
                });
        };
        $scope.getAgentActivityStats = function() {
            const request = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId()
            };

            return userService.getGlobalStatistics(request)
                .then(function(statistics) {
                    if (Object.keys(statistics).length) {
                        return {
                            title: 'ACTIVE AGENTS',
                            subtitle: { value: statistics.online, label: 'online' },
                            stats: [
                                { value: statistics.talking_to, label: 'talking to contacts' },
                                { value: statistics.working_inbox, label: 'working inbox' },
                                { value: statistics.offline, label: 'offline' }
                            ]
                        };
                    }

                    return { noStatsMessage: 'Did you know you can monitor agents from the Agent Activity dashboard?' };
                });
        };
        $scope.getContacts = function() {
            const request = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                skip: 0,
                limit: 3
            };

            return contactService.getContacts(request)
                .then(function(contactResponse) {
                    if (contactResponse.total_contacts) {
                        const listItems = contactResponse.contacts.map(function(contact) {
                            return (contact.firstName ? contact.firstName + (contact.lastName ? ' ' + contact.lastName : '') : contact.email || contact.main_phone);
                        });
                        return {
                            title: 'RECENTLY ACTIVE',
                            subtitle: { value: contactResponse.total_contacts, label: 'total contacts' },
                            listItems: listItems
                        };
                    }

                    return { noStatsMessage: 'You don\'t have any contacts yet! Head over to the Contacts page to import and manage your contacts.' };
                });
        };
        $scope.getDNCNumbers = function() {
            const request = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                skip: 0,
                limit: 3
            };

            return dncService.getLists(request)
                .then(function(dncResponse) {
                    if (dncResponse.total_count) {
                        const listItems = dncResponse.data.map(function(contact) {
                            return contact.phone_number;
                        });
                        return {
                            title: 'RECENT DNC',
                            subtitle: { value: dncResponse.total_count, label: 'blocked contacts' },
                            listItems: listItems
                        };
                    }

                    return { noStatsMessage: 'You don\'t have any blocked contacts! Did you know you can block and manage blocked contacts from the DNC page?' };
                });
        };
        $scope.getListStats = function() {
            const request = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId()
            };

            return contactService.getLists(request)
                .then(function(results) {
                    if (results.length) {
                        const average = results.reduce(function(avg, value, _, { length }) {
                            return avg + value.abandonment.rate / length;
                        }, 0);

                        const recentLists = results.slice(0, 3);
                        return {
                            title: 'ABANDONMENT RATE',
                            subtitle: { value: (average * 100).toFixed(0) + '%', label: 'on avg' },
                            rows: recentLists.map(function(list) {
                                const abandonment = (list.abandonment.rate * 100).toFixed(0) + '%';
                                return [
                                    { value: list.list_name, htmlTitle: list.list_name, colSize: 9 },
                                    { value: abandonment, htmlTitle: abandonment + ' abandonment rate', colSize: 3 },
                                ];
                            })
                        };
                    }

                    return { noStatsMessage: 'Looks like you haven\'t created any contact lists yet. Head over to the Lists page to get started!' };
                });
        };
        $scope.getRecordings = function() {
            const request = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                sort: 'desc',
                include_urls: true,
                media_exists: true,
                skip: 0,
                limit: 3
            };

            return mediaService.listMediaUrls(request)
                .then(function(results) {
                    if (results.total) {
                        return {
                            title: 'NEWEST RECORDINGS',
                            subtitle: { value: results.total, label: 'total' },
                            listItems: results.medias.map(function(media) {
                                return media.name;
                            })
                        };
                    }

                    return { noStatsMessage: 'No recording have been set up yet. To start using recordings, head to the Recordings page!' };
                });
        };
        $scope.getVoices = function() {
            const request = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                sort: 'desc',
                skip: 0,
                limit: 3,
                include_urls: true,
                media_exists: true,
            };

            return voiceService.listVoiceUrls(request)
                .then(function(results){
                    if (results.voices && results.voices.length) {
                        return {
                            title: 'RECENTLY ADDED VOICES',
                            listItems: results.voices.filter(voice => !!voice).map(voice => voice.name)
                        };
                    }

                    return { noStatsMessage: 'You don\'t have any voices yet, but if you check out our Voices page you can get started!' };
                });
        };
        $scope.getPhoneNumbers = function() {
            const req = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId()
            };

            return phoneService.getNumbers(req)
                .then(function(results) {
                    if (results.limits && results.limits.used > 0) {
                        return {
                            title: 'PHONE NUMBER DATA',
                            stats: [
                                { value: results.limits.used, label: 'numbers' },
                                { value: results.limits.sms_enabled, label: 'sms enabled' },
                                { value: results.limits.team_limit, label: 'team limit' }
                            ]
                        };
                    }

                    return { noStatsMessage: 'You don\'t have any phone numbers yet! Head over to the Phone Numbers page to buy some, so you can make and receive calls!' };
                });
        };
        $scope.getCallRoutingStats = function() {
            const req = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
            };

            return phoneService.getIvrs(req)
                .then(function(results) {
                    if (results.length) {
                        const smallList = results.slice(0, 3);
                        return {
                            title: 'IVR ROUTES',
                            subtitle: { value: results.length, label: 'total' },
                            rows: smallList.map(function(route) {
                                return [
                                    { value: route.name, htmlTitle: route.name, colSize: 9 },
                                    { value: route.type, htmlTitle: route.type, colSize: 3 },
                                ];
                            })
                        };
                    }

                    return { noStatsMessage: 'There are no IVRs on your account. You should check out Call Routing to automate your inbound call flow!' };
                });
        };
        $scope.getScripts = function() {
            const request = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                type: 'script',
                skip: 0,
                limit: 3
            };
            return templateService.getTemplates(request)
                .then(function (results) {
                    if (results.totalItems) {
                        return {
                            title: 'RECENTLY ADDED SCRIPTS',
                            subtitle: { value: results.totalItems, label: 'total' },
                            rows: results.templates.map(function(script) {
                                const timestamp = moment(script.updated_at);
                                return [
                                    { value: script.name, htmlTitle: script.name, colSize: 9 },
                                    { value: timestamp.format('MM/DD'), htmlTitle: timestamp.format('MM/DD/YYYY HH:mm'), colSize: 3 }
                                ];
                            })
                        };
                    }

                    return { noStatsMessage: 'You haven\'t made any scripts yet. Head over to the Scripts page to explore those features!' };
                });
        };
        $scope.getEmailChanges = function() {
            const request = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
            };
            return domainService.getLists(request)
                .then(function(results) {
                    if (results.length) {
                        const smallList = results.slice(0, 3);
                        return {
                            title: 'DOMAINS',
                            subtitle: { value: results.length, label: 'total' },
                            rows: smallList.map(function(domainObj) {
                                const timestamp = moment(domainObj.modified_at);
                                return [
                                    { value: domainObj.sub_domain, htmlTitle: domainObj.full_domain, colSize: 4 },
                                    { value: domainObj.parent_domain, htmlTitle: domainObj.full_domain, colSize: 5 },
                                    { value: timestamp.format('MM/DD'), htmlTitle: timestamp.format('MM/DD/YYYY HH:mm'), colSize: 3 }
                                ];
                            })
                        };
                    }

                    return { noStatsMessage: 'You have no email domains or email scripts built. If you go to the Email page, you can manage those features!' };
                });
        };
        $scope.getRecentlyTriggeredAutomation = function() {
            return automationService.getRules()
                .then(function(automationResults) {
                    if (automationResults.length) {
                        const smallList = automationResults.sort((a, b) => a.last_triggered_at < b.last_triggered_at ? 1 : -1).slice(0, 3);
                        return {
                            title: 'RECENTLY USED RULES',
                            subtitle: { value: automationResults.length, label: 'total' },
                            rows: smallList.map(function(rule) {
                                const timestamp = moment(rule.last_triggered_at);
                                return [
                                    { value: rule.rule_name, htmlTitle: rule.rule_name, colSize: 8 },
                                    { value: timestamp.format('MM/DD/YY'), htmlTitle: 'Last triggered: ' + timestamp.format('MM/DD/YYYY HH:mm'), colSize: 4 }
                                ];
                            })
                        };
                    }

                    return { noStatsMessage: 'It doesn\'t look like you\'ve set up any automation rules yet. Head to the Automation page to get started!' };
                });
        };
        $scope.getTagStats = function() {
            return tagsService.getTags()
                .then(function(results) {
                    if (results.length) {
                        return {
                            title: 'TAG COUNT',
                            stats: [
                                { value: results.length, label: 'total tags' }
                            ]
                        };
                    }

                    return { noStatsMessage: 'You have no tags yet, but if you head to the Tags page you can starting creating and customizing tags!' };
                });
        };
        $scope.getWebForms = function() {
            const request = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                skip: 0,
                limit: 0,
                get_total: true
            };

            return contactService.getWebforms(request)
                .then(function(results) {
                    if (results.total_webforms && results.total_webforms > 0) {
                        return {
                            title: 'WEB FORM COUNT',
                            stats: [
                                { value: results.total_webforms, label: 'total web forms' }
                            ]
                        };
                    }

                    return { noStatsMessage: 'You haven\'t built a web form yet! You should check out our Web Forms page and try out the feature!' };
                });
        };

        $scope.staticData = {
            navItems: [
                {
                    key: 'inbox',
                    href: 'inbox',
                    icon: 'fa-inbox-in',
                    title: 'Inbox',
                    desc: 'Streamline your communication with a centralized inbox that organizes all messages for efficient management and response',
                    widget: { action: $scope.getInboxStats, type: 'columnStats' }
                },
                {
                    key: 'buckets',
                    href: 'buckets',
                    icon: 'fa-objects-align-top',
                    title: 'Buckets',
                    desc: 'Categorize and prioritize your tasks effortlessly with Buckets, ensuring you stay organized and focused on what matters most',
                    widget: { action: $scope.getBucketsStats, type: 'table' }
                },
                {
                    key: 'outboundCalling',
                    href: 'dash',
                    icon: 'fa-phone-arrow-up-right',
                    title: 'Outbound Calling',
                    desc: 'Enhance your outreach with our seamless outbound calling feature that connects you with leads efficiently and effectively',
                    widget: { action: $scope.getOutboundCallStats, type: 'statDisplay' }
                },
                {
                    key: 'rvmCampaigns',
                    href: 'bulk-campaigns',
                    icon: 'fa-voicemail',
                    title: 'RVM Campaigns',
                    desc: 'Launch targeted RVM campaigns to deliver personalized messages directly to voicemail, increasing engagement without interrupting your audience',
                    widget: { action: $scope.getRVMStats, type: 'statDisplay' }
                },
                {
                    key: 'callTracking',
                    href: 'tracking',
                    icon: 'fa-chart-network',
                    title: 'Call Tracking',
                    desc: 'Gain valuable insights into your communication efforts with Call Tracking, enabling you to analyze call performance and optimize strategies',
                    widget: { action: $scope.getCallTrackingStats, type: 'statDisplay' }
                },
                {
                    key: 'agentActivity',
                    href: 'activity',
                    icon: 'fa-chart-user',
                    title: 'Agent Activity',
                    desc: 'Monitor agent activity in real-time to boost productivity, streamline workflows, and ensure your team is performing at its best',
                    widget: { action: $scope.getAgentActivityStats, type: 'statDisplay' }
                },
                {
                    key: 'contacts',
                    href: 'contacts',
                    icon: 'fa-people-group',
                    title: 'Contacts',
                    desc: 'Manage your contacts effortlessly with a comprehensive system that keeps all customer information organized and accessible',
                    widget: { action: $scope.getContacts, type: 'list' }
                },
                {
                    key: 'dnc',
                    href: 'dnc',
                    icon: 'fa-ban',
                    title: 'DNC',
                    desc: 'Ensure compliance and respect customer preferences with our Do Not Contact (DNC) feature that helps you manage communication lists effectively',
                    widget: { action: $scope.getDNCNumbers, type: 'list' }
                },
                {
                    key: 'lists',
                    href: 'lists',
                    icon: 'fa-images-user',
                    title: 'Lists',
                    desc: 'Create and manage segmented lists to target your audience more effectively and improve the success of your marketing campaigns',
                    widget: { action: $scope.getListStats, type: 'table' }
                },
                {
                    key: 'recordings',
                    href: 'recordings',
                    icon: 'fa-microphone',
                    title: 'Recordings',
                    desc: 'Capture and review call recordings to enhance training, improve quality assurance, and refine communication strategies',
                    widget: { action: $scope.getRecordings, type: 'list' }
                },
                {
                    key: 'voices',
                    href: 'voices',
                    icon: 'fa-volume-up',
                    title: 'Voices',
                    desc: 'Choose from a variety of natural-sounding voices for your communications to ensure your messages resonate with your audience',
                    widget: { action: $scope.getVoices, type: 'list' }
                },
                {
                    key: 'phoneNumbers',
                    href: 'phone-numbers',
                    icon: 'fa-phone-office',
                    title: 'Phone Numbers',
                    desc: 'Acquire local and toll-free phone numbers to enhance your business presence and improve customer accessibility',
                    widget: { action: $scope.getPhoneNumbers, type: 'statDisplay' }
                },
                {
                    key: 'callRouting',
                    href: 'call-routing',
                    icon: 'fa-route',
                    title: 'Call Routing',
                    desc: 'Optimize your customer interactions with intelligent call routing that connects callers to the right agents quickly and efficiently',
                    widget: { action: $scope.getCallRoutingStats, type: 'table' }
                },
                {
                    key: 'scripts',
                    href: 'scripts',
                    icon: 'fa-scroll-old',
                    title: 'Scripts',
                    desc: 'Provide your agents with effective scripts to ensure consistent messaging and enhance customer interactions during calls',
                    widget: { action: $scope.getScripts, type: 'table' }
                },
                // {
                //     key: 'macros',
                //     href: 'sms-macros',
                //     icon: 'fa-message-lines',
                //     title: 'Macros',
                //     widget: {}
                // },
                // {
                //     key: 'chatBot',
                //     href: 'sms-autoresponders',
                //     icon: 'fa-message-bot',
                //     title: 'Chat Bot',
                //     widget: {}
                // },
                {
                    key: 'email',
                    href: 'email',
                    icon: 'fa-envelope',
                    title: 'Email',
                    desc: 'Engage your audience effectively with our integrated email feature that allows for seamless communication and targeted campaigns',
                    widget: { action: $scope.getEmailChanges, type: 'table' }
                },
                {
                    key: 'automation',
                    href: 'automation',
                    icon: 'fa-gear',
                    title: 'Automation',
                    desc: 'Save time and reduce manual effort with Automation features that streamline repetitive tasks and improve overall efficiency',
                    widget: { action: $scope.getRecentlyTriggeredAutomation, type: 'table' }
                },
                {
                    key: 'tags',
                    href: 'tags',
                    icon: 'fa-tags',
                    title: 'Tags',
                    desc: 'Use tags to categorize and segment your contacts, making it easier to personalize communication and enhance engagement',
                    widget: { action: $scope.getTagStats, type: 'statDisplay' }
                },
                {
                    key: 'webForms',
                    href: 'webforms',
                    icon: 'fa-pen-field',
                    title: 'Web Forms',
                    desc: 'Capture leads and gather valuable information effortlessly with customizable web forms that integrate seamlessly with your system',
                    widget: { action: $scope.getWebForms, type: 'statDisplay' }
                },
                // {
                //     key: 'byoc',
                //     href: 'byoc',
                //     icon: 'fa-inbox',
                //     title: 'BYOC',
                //     widget: {}
                // }
            ],
            activeMenuOptions: [
                { title: 'Open', action: 'openLink' },
                { title: 'Remove', action: 'removeFromNav' }
            ],
            inactiveMenuOptions: [
                { title: 'Open', action: 'openLink' },
                { title: 'Add', action: 'addToNav' }
            ],
            featureAds: [
                {
                    href: 'recordings',
                    icon: 'fa-microphone',
                    title: 'Recordings',
                    desc: 'Looks like you have outbound calls set up, you should try our recording feature!'
                }
            ],
            checklistWidget: {
                href: 'checklist',
                icon: 'fa-list',
                title: 'Getting Started',
                desc: 'Looks like you haven\'t finished our "Getting Started" checklist yet!'
            }
        };

        const localMenu = localStorage.getItem('my_dash_items');
        $scope.data = {
            activeNav: localMenu ? JSON.parse(localMenu) : $scope.staticData.navItems,
            inactiveNav: [],
            targetedFeatureAds: [],
            checklistCompleted: false,
            allFeaturesBool: false
        };

        $scope.showFeaturesModal = function() {
            $('#featuresModal').modal('show');
        };

        $scope.hideFeaturesModal = function() {
            $('#featuresModal').modal('hide');
        };

        $scope.getActiveWidgets = async function() {
            const { user_preferences } = $rootScope.secureLS.get('user');

            if (user_preferences && user_preferences.nav) {
                const { nav } = user_preferences;
                let newActiveNav = $scope.staticData.navItems.filter(function(navItem) {
                    return !!(!navItem.key || nav[navItem.key]);
                });

                localStorage.setItem('my_dash_items', JSON.stringify(newActiveNav));
                await Promise.all(newActiveNav.map(async (navItem) => {
                    if (!navItem.widget.action) {
                        navItem.widget.payload = { noActionMessage: 'no stats to display' };
                    } else {
                        try {
                            navItem.widget.payload = await navItem.widget.action();
                        } catch (err) {
                            navItem.widget.payload = { noActionMessage: 'no stats to display' };
                        }
                    }
                }));

                $scope.data.activeNav = newActiveNav;
                $scope.data.inactiveNav = $scope.staticData.navItems.filter(function(navItem) {
                    return !navItem.key || !nav[navItem.key];
                });

                // if (nav.outboundCalling && !nav.recordings) {
                //     const recordingAd = $scope.staticData.featureAds.filter(function(featureAd) {
                //         return featureAd.href === 'recordings';
                //     });
                //     if (recordingAd.length) {
                //         $scope.data.targetedFeatureAds.push(recordingAd[0]);
                //     }
                // }
            } else {
                let newActiveNav = $scope.staticData.navItems;
                for (let i = 0; i < $scope.staticData.navItems.length; i++) {
                    const navItem = newActiveNav[i];
                    if (!newActiveNav[i].widget.action) {
                        newActiveNav[i].widget.payload = { noActionMessage: 'no stats to display' };
                    }
                    try {
                        newActiveNav[i].widget.payload = await navItem.widget.action();
                    } catch (err) {
                        newActiveNav[i].widget.payload = { noActionMessage: 'no stats to display' };
                    }
                }
                $scope.data.activeNav = newActiveNav;
            }
        };

        $scope.updateNav = function(widget, bool, key) {
            const user = $rootScope.secureLS.get('user');
            if (!user.user_preferences) {
                user.user_preferences = {};
            }
            if (!user.user_preferences.nav) {
                user.user_preferences.nav = {
                    inbox: true,
                    buckets: true,
                    outboundCalling: true,
                    rvmCampaigns: true,
                    callTracking: true,
                    agentActivity: true,
                    contacts: true,
                    dnc: true,
                    lists: true,
                    recordings: true,
                    voices: true,
                    phoneNumbers: true,
                    callRouting: true,
                    scripts: true,
                    macros: true,
                    chatBot: true,
                    email: true,
                    automation: true,
                    tags: true,
                    webForms: true,
                    byoc: true
                };
            }
            const { nav } = user.user_preferences;
            const newNav = nav;

            newNav[widget.key] = bool;
            user.user_preferences.nav = newNav;
            $rootScope.secureLS.set('user', user);
            var userId = authService.getUserId();

            let request = {
                team_id: authService.getTeamId(),
                user_id: userId,
                user_preferences: user.user_preferences,
                updated_user_id: userId
            };

            userService.updateUser(request)
                .then(function(res) {
                    $rootScope.$broadcast(key, newNav);
                    $scope.getActiveWidgets()
                        .then(function(res) {
                            $scope.$apply();
                        });
                })
                .catch(function(err) {
                    console.error(err);
                });
        };

        $scope.handleMenuClick = function(menuItem, widget) {
            switch(menuItem.action) {
                case 'openLink': {
                    $window.open('#/' + widget.href);
                    break;
                }
                case 'removeFromNav': {
                    $scope.updateNav(widget, false, 'my-dashboard:removeFromNav');
                    break;
                }
                case 'addToNav': {
                    $scope.updateNav(widget, true, 'my-dashboard:addToNav');
                    break;
                }
                default: {
                    break;
                }
            }
        };

        $scope.checkChecklistProgress = function() {
            const userId = localStorage.getItem('auth_user_id');
            const localStorageNamespace = 'checklist-' + userId;
            const checklist = localStorage.getItem(localStorageNamespace);

            if (checklist) {
                const checklistItems = JSON.parse(checklist).items;

                $scope.data.checklistCompleted = Object.keys(checklistItems).map(function(key) {
                    return checklistItems[key].skipped || checklistItems[key].checked;
                }).every(function(bool) { return bool; });
            }
        };

        $scope.destroyTooltip = function(selector) {
            const tooltip = angular.element(document)
                .find(selector + ' [data-toggle="tooltip"]');
            tooltip.tooltip('dispose');
        };

        $scope.initTooltip = function() {
            $scope.destroyTooltip('#widget-tooltip');
            generalHelperService.waitForElement('#widget-tooltip')
                .then(element => {
                    $(element).tooltip();
                });
        };

        $scope.onInit = function() {
            $scope.getActiveWidgets()
                .then(function(res) {
                    $scope.$apply();
                });
            $scope.initTooltip();
            $scope.checkChecklistProgress();
        };

        if (authService.hasAccount()) {
            $scope.onInit();
        } else {
            $scope.$on('auth_complete', function(event, args) {
                $scope.onInit();
            });
        }
    }
    module.exports = MyDashboardController;
})();
