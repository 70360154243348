(function () {
    'use strict';

    function MailboxesController($rootScope, $scope, authService, domainService, mailboxesService) {
        var vm = this;

        $scope.staticContent = {
            errors: {
                required: 'Field is required',
            },
            labels: [
                'Mailbox',
                'Subdomain',
                'Email Mailboxes'
            ]
        }
        $scope.data = {
            type: null,
            mailboxes: [],
            domains: [],
            isLoading: false,
            hideLabel: false,
            mailboxForm: {
                isSubmitted: false,
                subdomain: null,
                mailbox: '',
                user_id: null,
            }
        }

        $scope.isValid = function (field) {
            if (field === 'subdomain' && $scope.data.domains[0].disabled) {
                return false;
            }
            if (Object.keys($scope.data.mailboxForm).includes(field)) {
                return typeof $scope.data.mailboxForm[field] !== 'undefined' && $scope.data.mailboxForm[field] !== null && !!$scope.data.mailboxForm[field];
            }
            return false;
        }

        $scope.loadDomains = function () {
            const teamId = authService.getTeamId();
            const userId = authService.getUserId();

            const request = {
                team_id: teamId,
                user_id: userId,
            };

            return domainService.getLists(request)
                .then(results => {
                    $scope.data.domains = results || [];
                    if ($scope.data.domains.length === 0) {
                        $scope.data.domains.push({full_domain: '(No Subdomains)', disabled: true});
                        $scope.data.mailboxForm.subdomain = {full_domain: '(No Subdomains)', disabled: true}
                    }
                    $scope.$apply();
                })
                .catch(err => {
                    console.error(err);
                    $scope.$apply();
                });
        };

        $scope.loadMailboxes = function () {
            $scope.data.isLoading = true;
            $scope.data.mailboxes = []
            const teamId = authService.getTeamId();
            const userId = authService.getUserId();

            const request = {
                team_id: teamId,
                user_id: userId,
            };

            if ($scope.data.type === 'user') {
                request.mailbox_user_id = $scope.data.mailboxForm.user_id;
            }

            if ($scope.data.type === 'user_group') {
                request.mailbox_user_group_id = $scope.data.mailboxForm.user_group_id;
            }
            return mailboxesService.getMailboxes(request)
                .then(results => {
                    $scope.data.mailboxes = results || [];
                    $scope.data.isLoading = false;
                    $scope.$apply();
                })
                .catch(err => {
                    console.error(err);
                    $scope.$apply();
                });
        };

        $scope.resetMailboxForm = function() {
            $scope.data.mailboxForm.subdomain = null;
            $scope.data.mailboxForm.mailbox = '';
            $scope.data.mailboxForm.isSubmitted = false;
        }

        $scope.createMailbox = function () {
            $scope.data.isLoading = true;
            $scope.data.mailboxForm.isSubmitted = true;
            const teamId = authService.getTeamId();
            const userId = authService.getUserId(); $scope.data.domains[0].disabled
            if (!$scope.isValid('mailbox') || !$scope.isValid('subdomain') || $scope.data.domains[0].disabled) {
                $scope.data.isLoading = false;
                return;
            }

            const request = {
                team_id: teamId,
                user_id: userId,
                mailbox: $scope.data.mailboxForm.mailbox,
                domain_id: $scope.data.mailboxForm.subdomain.domain_id,
                title: `${$scope.data.mailboxForm.mailbox}@${$scope.data.mailboxForm.subdomain.full_domain}`
            };

            if ($scope.data.type === 'user') {
                request.mailbox_user_id = $scope.data.mailboxForm.user_id;
            }

            if ($scope.data.type === 'user_group') {
                request.mailbox_user_group_id = $scope.data.mailboxForm.user_group_id;
            }

            return mailboxesService.createMailbox(request)
                .then(results => {
                    $scope.data.isLoading = false;
                    $scope.resetMailboxForm();
                    return $scope.loadMailboxes();
                })
                .catch(err => {
                    $scope.data.isLoading = false;
                    swal("Error", err.message, "error");
                    console.error(err);
                    $scope.$apply();
                });
        }

        $scope.removeMailbox = function (item) {
            $scope.data.isLoading = true;
            const teamId = authService.getTeamId();
            const userId = authService.getUserId();

            const request = {
                team_id: teamId,
                user_id: userId,
                mailbox_id: item.mailbox_id,
            };

            if ($scope.data.type === 'user') {
                request.mailbox_user_id = $scope.data.mailboxForm.user_id;
            }

            if ($scope.data.type === 'user_group') {
                request.mailbox_user_group_id = $scope.data.mailboxForm.user_group_id;
            }

            return mailboxesService.removeMailbox(request)
                .then(() => {
                    $scope.data.isLoading = false;
                    return $scope.loadMailboxes()
                })
                .catch(err => {
                    $scope.data.isLoading = false;
                    console.error(err);
                    $scope.$apply();
                });
        }

        vm.$onInit = function () {
            $scope.data.type = vm.type;
            if ($scope.data.type === 'user') {
                $('#addEditUserModal').on('show.bs.modal', function (e) {
                    $scope.data.mailboxes = [];
                    $scope.data.mailboxForm.user_id = $scope.$parent.data.userId;
                    Promise.all([
                        $scope.loadDomains(),
                        $scope.loadMailboxes()
                    ]);
                });
                $('#addEditUserModal').on('hide.bs.modal', function (e) {
                    $scope.data.mailboxForm.user_id = null;
                    $scope.resetMailboxForm();
                });
            } else {
                $scope.data.mailboxForm.user_group_id = vm.userGroupId;
                $scope.data.hideLabel = vm.hideLabel;
                Promise.all([
                    $scope.loadDomains(),
                    $scope.loadMailboxes()
                ]);
            }
        }
    }

    module.exports = MailboxesController;
})();
