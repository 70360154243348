(function () {
    'use strict';

    function DropCowboyApiService($rootScope) {

        const get = function(url, data) {
            return new Promise((resolve, reject) => {

                let dataKeys = Object.keys(data || {});
                if (data && dataKeys.length) {
                    let params = {};
                    for (let i = 0; i < dataKeys.length; i++) {
                        let k = dataKeys[i];
                        if (k == 'team_id' || k == 'secret') { continue; }
                        params[k] = data[k];
                    }
                    let queryStr = new URLSearchParams(params).toString();
                    url += '?' + queryStr;
                }

                $.ajax(url, {dataType: 'json', method: 'GET', headers: { 'x-team-id': data.team_id, 'x-secret': data.secret }})
                    .done(function (data, textStatus, jqXHR) {
                        resolve(data);
                    })
                    .fail(function (jqXHR, textStatus, errorThrown) {
                        reject(errorThrown);
                    });
            });
        };

        const post = function(url, data) {
            return new Promise((resolve, reject) => {

                $.ajax(url, {
                    data : JSON.stringify(data),
                    contentType : 'application/json',
                    dataType: 'json',
                    method: 'POST',
                    headers: { 'x-team-id': data.team_id, 'x-secret': data.secret }
                })
                    .done(function (data, textStatus, jqXHR) {
                        resolve(data);
                    })
                    .fail(function (jqXHR, textStatus, errorThrown) {
                        reject(jqXHR.responseJSON);
                    });
            });
        };

        const del = function(url, data) {
            return new Promise((resolve, reject) => {

                $.ajax(url, {data : data, dataType: 'json', method: 'DELETE', headers: { 'x-team-id': data.team_id, 'x-secret': data.secret }})
                    .done(function (data, textStatus, jqXHR) {
                        resolve(data);
                    })
                    .fail(function (jqXHR, textStatus, errorThrown) {
                        reject(errorThrown);
                    });
            });
        };

        return {
            get: get,
            post: post,
            del: del
        };
    }
    module.exports = DropCowboyApiService;
})();
