(function () {
    'use strict';

    function InboxRightSidebarController($rootScope, $scope, $location, authService, tagsService, contactService, storeSelectedTaskService) {
        var vm = this;
        $scope.staticData = {
            unknownContact: 'Unknown Contact',
            contactMenu: ['Open Contact'],
            contactInfoFields: [
                'Company',
                'Email',
                'Phone',
                'Fax',
                'Address',
                'Tags',
                'Notes',
                'Lists'
            ],
            loading: 'Loading'
        };
        $scope.defaultAvatar = $rootScope.settings.NO_AVATAR_IMG;

        $scope.data = {
            selectedTask: null,
            contact: null,
            contactTags: [],
            contactLists: [],
            mainFiledsDisplayLimit: 5
        };

        $scope.loading = {
            contact: false
        };

        $scope.loadContactLists = function() {
            var teamId = authService.getTeamId();
            var userId = authService.getUserId();

            const request = {
                team_id: teamId,
                user_id: userId
            };

            contactService.getLists(request)
                .then(results => {
                    $scope.data.contactLists = results;
                    $scope.$apply();
                })
                .catch(err => {
                    console.error(err);
                });
        };

        $scope.getListName = function(listId) {
            const lists = $scope.data.contactLists;
            var list = _.find(lists, { 'list_id': listId });
            return (!list) ? 'Unknown' : list.list_name;
        };

        $scope.loadTagsList = function() {
            var teamId = authService.getTeamId();
            var userId = authService.getUserId();

            const request = {
                team_id: teamId,
                user_id: userId
            };

            tagsService.getTags(request)
                .then(results => {
                    $scope.data.contactTags = results;
                    $scope.$apply();
                })
                .catch(err => {
                    console.error(err);
                });
        };

        $scope.getTag = function(tag, index, currentTags) {
            const tags = $scope.data.contactTags;
            var foundTag = _.find(tags, { 'tag_id': tag.tag_id });
            if(foundTag) {
                currentTags[index] = foundTag;
                return true;
            }
            return false;
        };

        $scope.toggleRightSideBar = function() {
            $rootScope.$broadcast('toggleRightSideBar');
        };

        $scope.viewDetails = function () {
            $location.path('/contacts/detail').search('id=' + $scope.data.contact.contact_id);
        };

        $scope.showCompanyBlock = function() {
            return !!($scope.data.contact.company && $scope.data.contact.company !== null && $scope.data.contact.company.length > 0);
        };
        $scope.showEmailBlock = function() {
            return !!($scope.data.contact.email && $scope.data.contact.email !==null && $scope.data.contact.email.length > 0);
        };
        $scope.showMainPhoneBlock = function() {
            return !!($scope.data.contact.main_phone && $scope.data.contact.main_phone !==null && $scope.data.contact.main_phone.length > 0);
        };
        $scope.showFaxNumber = function() {
            return !!($scope.data.contact.fax_number && $scope.data.contact.fax_number !==null && $scope.data.contact.fax_number.length > 0);
        };
        $scope.showAddress = function() {
            return !!($scope.data.contact.address && $scope.data.contact.address !==null && $scope.data.contact.address.length > 0);
        };

        $scope.setCustomFieldsDisplayItems = function() {
            var displayFieldsLength = $scope.data.contact.displayFields.length;
            $scope.data.contact.customFieldsDisplayItems = (displayFieldsLength > $scope.data.mainFiledsDisplayLimit) ? $scope.data.mainFiledsDisplayLimit : displayFieldsLength;
        };

        $scope.mainBlockSeeMoreLess = function() {
            var mainBlock = document.getElementById('contact-main-block');
            mainBlock.classList.add('collapse-animation-main-block');

            if(!$scope.data.contact.showAllFields) {
                $scope.data.contact.customFieldsDisplayItems = $scope.data.contact.displayFields.length;
                $scope.data.contact.showAllFields = true;
            } else {
                $scope.setCustomFieldsDisplayItems();
                $scope.data.contact.showAllFields = false;
            }

            document.querySelector('#contact-main-block').addEventListener('transitionend', function() {
                mainBlock.classList.remove('collapse-animation-main-block');
            });
        };

        $scope.resizeContactMainBlock = function(size) {
            var mainBlock = document.getElementById('contact-main-block');
            mainBlock.style.height = size.innerHeight + 'px';
        };

        $scope.formatContactCustomFields = function(contact) {
            let fieldData = contact.field_data || [];
            return _.sortBy($rootScope.customFields, 'sort_index').map(customField => {
                const contactCustomData = fieldData.find(field => field.type === 'custom_field' && field.custom_field_id === customField.custom_field_id);
                if (typeof contactCustomData !== 'undefined') {
                    if (customField.type === 'list') {
                        const selectedItem = customField.list_items.find(item => item.value === contactCustomData.value);
                        if (typeof selectedItem !== 'undefined') {
                            customField.value = selectedItem;
                        }
                    } else {
                        customField.value = contactCustomData.value;
                    }
                } else {
                    customField.value = null;
                }
                customField.fieldId = customField.custom_field_id;
                customField.fieldType = 'customField';
                return customField;
            });
        };

        $scope.createMainDisplayField = function(type, title, value, icon) {
            var field = {
                fieldId: type,
                fieldType: type,
                title: title,
                value: value,
                icon: icon
            };
            if(type === 'address') {
                field.city = $scope.data.contact.city;
                field.state = $scope.data.contact.state;
                field.zip_code = $scope.data.contact.zip_code;
                field['suite/apt'] = $scope.data.contact['suite/apt'];
                field.country = $scope.data.contact.country;
            }
            return field;
        };

        $scope.addMainDisplayField = function(checkFieldFunctionName, type, title, value, icon) {
            if($scope[checkFieldFunctionName]()) {
                $scope.data.contact.displayFields.unshift($scope.createMainDisplayField(type, title, value, icon));
            }
        };

        $scope.loadContact = function(id) {
            $scope.loading.contact = true;
            var teamId = authService.getTeamId();
            var userId = authService.getUserId();

            const request = {
                team_id: teamId,
                user_id: userId,
                contact_id: id,
            };

            contactService.getContactDetails(request)
                .then(result => {
                    if (result.status !== 200) {
                        return;
                    }
                    $scope.data.contact = result.contact;
                    if (!Array.isArray($scope.data.contact.tags)) {
                        $scope.data.contact.tags = [];
                    }
                    $scope.data.contact.displayFields = [];
                    $scope.data.contact.displayFields = $scope.formatContactCustomFields($scope.data.contact);
                    $scope.addMainDisplayField('showAddress', 'address', $scope.staticData.contactInfoFields[4], $scope.data.contact.address, 'fa-light fa-location-dot');
                    $scope.addMainDisplayField('showFaxNumber', 'faxPhone', $scope.staticData.contactInfoFields[3], $scope.data.contact.fax_number, 'fa-light fa-fax');
                    $scope.addMainDisplayField('showMainPhoneBlock', 'mainPhone', $scope.staticData.contactInfoFields[2], $scope.data.contact.main_phone, 'fa-light fa-phone');
                    $scope.addMainDisplayField('showEmailBlock', 'email', $scope.staticData.contactInfoFields[1], $scope.data.contact.email, 'fa-light fa-at');
                    $scope.addMainDisplayField('showCompanyBlock', 'company', $scope.staticData.contactInfoFields[0], $scope.data.contact.company, 'fa-light fa-buildings');

                    $scope.setCustomFieldsDisplayItems();
                })
                .catch(err => {
                    console.error(err);
                })
                .then(() => {
                    $scope.loading.contact = false;
                    $scope.$apply();
                });
        };

        $scope.addNoteToList = function(note) {
            $scope.data.contact.notes.push(note);
        };

        $scope.removeNoteFromList = function(note) {
            $scope.data.contact.notes = _.remove($scope.data.contact.notes, function (n) {
                return n.note_id !== note.note_id;
            });
        };

        $scope.updateNoteText = function(note) {
            var index = _.findIndex($scope.data.contact.notes, function(item) { return item.note_id === note.note_id; });
            if(index !== -1) {
                $scope.data.contact.notes[index].note = _.cloneDeep(note.newEdit);
            }
        };

        $scope.setSelectedTask = function(task) {
            if(task && task !== null) {
                $scope.loadContact(task.contact_id);
                $scope.data.selectedTask = task;
            }
        };

        vm.$onInit = function() {
            $scope.loadTagsList();
            $scope.loadContactLists();

            $scope.setSelectedTask(storeSelectedTaskService.getTask());
        };

        $scope.$on('setSelectedTask', function (event, task) {
            $scope.data.selectedTask = null;
            $scope.setSelectedTask(task);
        });

        $scope.$on('updateContactNotesEvent', function (event, note, action) {
            switch (action) {
                case 'add': $scope.addNoteToList(note); break;
                case 'remove': $scope.removeNoteFromList(note); break;
                case 'edit': $scope.updateNoteText(note); break;
                case 'deleteEntry': $scope.removeNoteFromList(note); break;
            }
        });
    }

    module.exports = InboxRightSidebarController;
})();
