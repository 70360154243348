(function() {
   'use strict';

   function MarketplaceController($rootScope, $scope, $location, $window, authService, automationService) {

      var vm = this;
      vm.authService = authService;

      $rootScope.viewData = $rootScope.viewData || {};
      $rootScope.viewData.breadcrumbs = $rootScope.viewData.breadcrumbs || [];
      $rootScope.viewData.breadcrumbs = [{
         title: 'Marketplace',
         link_name: 'Marketplace',
         link_url: '#/marketplace',
         help_url: $rootScope.help_url
      }];

      $scope.data = {};
      $scope.loading = true;

      $scope.staticText = {};

      $scope.getAccessToken = async () => {
         let auth;
         const username = 'hunter@dropcowboy.com';
         const usertoken = 'HOFeQ7y3Esiktqgrx627p8';

         try {
            auth = await $rootScope.app_automation.api.authenticateUser(username, usertoken);
            $rootScope.app_automation.set('accessToken', auth.token);

            return { token: auth.token };
         } catch (err) {
            if (err.response && err.response.status === 403) {
               // Virtual user not yet created in Appmixer. Create one.
               try {
                  auth = await $rootScope.app_automation.api.signupUser(username, usertoken);
                  $rootScope.app_automation.set('accessToken', auth.token);

                  return { token: auth.token };
               } catch (err) {
                  alert('Something went wrong creating a virtual user. ' + err.message);
               }
            } else {
               alert('Something went wrong authenticating a virtual user. '+ err.message);
            }
         }
      };

      $scope.onAutomationAuth = () => {

         if ($rootScope.app_automation) {

            let req = {
               team_id: authService.getTeamId(),
               user_id: authService.getUserId()
            };

            $scope.getAccessToken(req)
               .then(results => {
                  if (results && results.token) {
                     const integrations = $rootScope.app_automation.ui.Integrations({
                        el: '#mp-placeholder',
                        options: {
                           showHeader: true
                        }
                     });
                     const wizard = $rootScope.app_automation.ui.Wizard();

                     integrations.on('integration:create', templateId => {
                        wizard.close();
                        wizard.set('flowId', templateId);
                        wizard.open();
                     });
                     integrations.on('integration:edit', integrationId => {
                        wizard.close();
                        wizard.set('flowId', integrationId);
                        wizard.open();
                     });
                     wizard.on('flow:start-after', () => integrations.reload());
                     wizard.on('flow:remove-after', () => {
                        integrations.reload();
                        wizard.close();
                     });

                     integrations.open();

                     $scope.app_automation_available = true;
                     $scope.loading = false;
                  }
                  else {
                     $scope.loading = false;
                  }
                  $scope.$apply();
               })
               .catch(err => {
                  console.log(err);
                  $scope.loading = false;
                  $scope.$apply();
               });
         }
         else {
            $scope.loading = false;
         }
      }

      $scope.onInit = () => {
         $scope.onAutomationAuth();
      }

      if (authService.hasAccount()) {
         $scope.onInit();
      }
      else {
         $scope.$on('auth_complete', function(event, args) {
            $scope.onInit();
         });

         $scope.$on('automation_auth', function(event, args) {
            $scope.onAutomationAuth();
         });
      }
   }
   module.exports = MarketplaceController;
})();



