(function() {
    'use strict';

    function VoicemailTypePlaceholderListItemController(authService) {
        var vm = this;
        vm.authService = authService;

    }
    module.exports = VoicemailTypePlaceholderListItemController;
})();
