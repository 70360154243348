(function() {
    'use strict';

    function GeneralHelperService() {
        let self = this;

        function waitForElement(selector) {
            return new Promise(resolve => {
                if (document.querySelector(selector)) {
                    return resolve(document.querySelector(selector));
                }

                const observer = new MutationObserver(mutations => {
                    if (document.querySelector(selector)) {
                        observer.disconnect();
                        resolve(document.querySelector(selector));
                    }
                });

                observer.observe(document.body, {
                    childList: true,
                    subtree: true
                });
            });
        }

        function onRemoveElement(selector) {
            return new Promise(resolve => {
                const element = document.querySelector(selector);
                const parent= element.parentNode;

                if (!parent) { return resolve(null); }

                const obs = new MutationObserver(mutations => {
                    for (const mutation of mutations) {
                        for (const el of mutation.removedNodes) {
                            if (el === element) {
                                obs.disconnect();
                                resolve(element);
                            }
                        }
                    }
                });

                obs.observe(parent, {
                    childList: true
                });
            });
        }

        return {
            waitForElement: waitForElement,
            onRemoveElement: onRemoveElement
        };
    }
    module.exports = GeneralHelperService;
})();

