(function() {
    'use strict';

    function AuthLocalService($rootScope, $state, $cookies, intercomService) {
        //Use this service to prevent circular dependency in the AuthService.

        function logoutClear(callback) {

            intercomService.shutdownIntercom();

            $rootScope.authenticated = false;
            $rootScope.user = {};
            $rootScope.team = {};
            $rootScope.user_id = null;
            $rootScope.team_id = null;

            if($rootScope.ably && $rootScope.teamChannel && $rootScope.userChannel) {
                $rootScope.ably.teamChannel.unsubscribe($rootScope.ably.onEvent);
                $rootScope.ably.userChannel.unsubscribe($rootScope.ably.onEvent);
                $rootScope.ably.channel = null;
            }

            // Remove tokens and expiry time from localStorage
            localStorage.removeItem('auth_token');
            localStorage.removeItem('auth_expiry');
            localStorage.removeItem('auth_user_id');
            localStorage.removeItem('auth_impersonated_by');
            localStorage.removeItem('auth_team_id');
            $rootScope.secureLS.remove('user');

            localStorage.removeItem('new_user_first_signin');
            localStorage.removeItem('right_side_panel');
            $cookies.remove('campaign_results_to_print');
            if(swal.getState().isOpen) {
                swal.close();
            }
            $.toast().reset('all');
            $('.custom-popover-toggle').popover('hide');

            $rootScope.onLogout();

            callback();
        }

        function localLogout(redirectToSignIn = true) {

            $('.preloader').fadeIn();

            logoutClear(()=> {
                if (redirectToSignIn) {
                    $state.go('empty-wrapper.signin');
                }
            });
        }

        return {
            logoutClear: logoutClear,
            localLogout: localLogout
        };
    }
    module.exports = AuthLocalService;
})();

