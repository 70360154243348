(function () {
    'use strict';

    function WelcomeController(authService, $rootScope, $scope, $window, $location) {

        $rootScope.viewData = $rootScope.viewData || {};
        $rootScope.viewData.breadcrumbs = $rootScope.viewData.breadcrumbs || [];
        $rootScope.viewData.breadcrumbs = [{
            title: 'Welcome',
            link_name: 'Welcome',
            link_url: '#/welcome',
            help_url: $rootScope.help_url
        }];

        $scope.staticData = {
            header: {
                subtitleHighlighted: 'Let\'s get started',
                title: 'Welcome to your ',
                title1: 'Free',
                title2: ' Forever CRM',
                subtitle: 'Your all-in-one mass communication and contact management solution'
            },
            gettingStarted: {
                title: 'Getting Started Checklist',
                text: `Explore everything that ${$rootScope.settings.APP_NAME} has to offer by taking a guided tour of your new CRM and communication platform.`,
                href: '#/checklist',
                target: '',
                btnText: 'View Checklist',
            },
            needHelp: {
                title: 'Still Need Help?',
                text: 'We\'re here to help!  Visit our online help center or chat with one of our friendly support agents.',
                href: 'https://help.callcowboy.com/',
                target: '_blank',
                btnText: 'Visit Help Center',
            },
            buttons: {
                viewBtn: 'View Walkthrough',
                subscriptionBtn: 'Select Subscription Plan',
            }
        };

        $scope.currentTab = null;
        $scope.menu = {
            'overview': { title: 'Platform Overview' },
            'phone-numbers': { title: 'Virtual Phone Numbers' },
            'dialing': { title: 'Automated Dialing' },
            'rvm': { title: 'Ringless Voicemail Campaigns' },
            'tracking': { title: 'Call Tracking & Recording' },
            'inbox': { title: 'Shared Inbox' },
            'crm': { title: 'Contact Management' },
            'collecting-leads': { title: 'Collecting Leads & Opt-in' }
        };

        $scope.content = {
            'overview': {
                title: 'Platform Overview',
                text: [
                    `${$rootScope.settings.APP_NAME} provides a comprehensive communication and contact management solution for organizations of any size. Whether you need virtual phone numbers for your business, need an automated dialing solution for your outbound sales team, need an inbound call center solution for your support team, want to communicate with your customers in bulk via ringless voicemail, want detailed call tracking and call recording, or just want to grow your leads and manage your contacts, this platform has the tools you need to succeed.`,
                    `Start by purchasing phone numbers and setting up call routing so that your customers can call and text you.  Utilize our automated dialing and ringless voicemail solutions to call your contacts efficiently in bulk.  Finally, track your conversations in your inbox and call tracking dashboard to view analytics and measure your results.  Welcome to ${$rootScope.settings.APP_NAME}!`
                ],
                walkthroughTourId: 'tourId',
                upsellModalTab: 'contact_center',
                overview: {
                    loading: true,
                    player: undefined,
                    title: 'Feature Overview',
                    videoId: 'tbZzittG-Ko'
                },
                titleDetails: 'Adding Users Details',
                textDetails: [
                    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore\n' +
                    'magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo\n' +
                    'consequat.'
                ],
                subFeatures: [
                    {
                        title: 'Adding Users Sub-Feature 1',
                        text: [
                            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore\n' +
                            'magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo\n' +
                            'consequat.',
                            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.'
                        ]
                    },
                    {
                        title: 'Adding Users Sub-Feature 2',
                        text: [
                            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.'
                        ]
                    },
                    {
                        title: 'Adding Users Sub-Feature 3',
                        text: [
                            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore\n' +
                            'magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo\n' +
                            'consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore\n' +
                            'magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo\n' +
                            'consequat.'
                        ]
                    }
                ]
            },
            'phone-numbers': {
                title: 'Virtual Phone Numbers',
                text: [
                    `Acquire local and toll-free phone numbers tailored for your business through ${$rootScope.settings.APP_NAME}. Our virtual assistant answers every call and boasts an array of advanced features designed to enhance customer interactions. These features encompass multiple user extensions, voicemail services, customizable call queues, seamless call forwarding, reliable call recording, accurate call transcription, and customizable time-of-day rules.`,
                    'Leverage the purchased numbers as your distinctive caller ID for various purposes such as outbound calls, SMS communications, ringless voicemail campaigns, and call tracking. Prominently display these numbers on your website, business cards, and all marketing collateral to bolster your brand image.',
                    `Delve into comprehensive analytics that provide insights based on phone numbers, call routing, geographical patterns, and time-specific trends. For queued calls, streamline the process by directing them to your shared ${$rootScope.settings.APP_NAME} inbox. Prioritize, assign, flag for follow-up, and encourage collaborative efforts among your agents within the contact center, ensuring efficient customer support.`
                ],
                walkthroughTourId: 'tourId',
                upsellModalTab: 'contact_center',
                overview: {
                    loading: true,
                    player: undefined,
                    title: 'Feature Overview',
                    videoId: 'tbZzittG-Ko'
                },
                titleDetails: 'Adding Phone Numbers',
                textDetails: [
                    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore\n' +
                    'magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo\n' +
                    'consequat.'
                ],
                subFeatures: [
                    {
                        title: 'Adding Users Sub-Feature 1',
                        text: [
                            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore\n' +
                            'magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo\n' +
                            'consequat.',
                            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.'
                        ]
                    },
                    {
                        title: 'Adding Users Sub-Feature 2',
                        text: [
                            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.'
                        ]
                    },
                    {
                        title: 'Adding Users Sub-Feature 3',
                        text: [
                            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore\n' +
                            'magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo\n' +
                            'consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore\n' +
                            'magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo\n' +
                            'consequat.'
                        ]
                    }
                ]
            },
            'dialing': {
                title: 'Automated Dialing',
                text: [
                    `Significantly boost your team\'s outbound calling efficiency, talk time, and number of calls per day with ${$rootScope.settings.APP_NAME}\'s automated dialing services.  Sequentially dial through your contact lists with our auto dialer service or supercharge your outreach with our predictive dialer, reaching up to three contacts simultaneously and seamlessly connecting with the first respondent to answer, without any audio lag.`,
                    'Both services seamlessly integrate with your mobile phone, desk phone, and web browser to provide your agents with an unprecedented ability to automatically dial contacts from any device while working from home, office, or on the go.',
                    'Effortlessly monitor call results and gauge contact sentiment using customizable dispositions. Automate post-call follow-ups based on outcomes through tailored workflow actions. Dive deep into your campaign performance insights, analyzing dispositions, optimal calling times, and weekly trends to fine-tune your strategies. Elevate your sales game with our automated dialing services today!'
                ],
                walkthroughTourId: 'tourId',
                upsellModalTab: 'contact_center',
                overview: {
                    loading: true,
                    player: undefined,
                    title: 'Feature Overview',
                    videoId: 'tbZzittG-Ko'
                },
                titleDetails: 'Setting up your CRM Details',
                textDetails: [
                    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore\n' +
                    'magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo\n' +
                    'consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore\n' +
                    'magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo\n' +
                    'consequat.'
                ],
                subFeatures: [
                    {
                        title: 'Setting up your CRM Sub-Feature 1',
                        text: [
                            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore\n' +
                            'magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo\n' +
                            'consequat.'
                        ]
                    },
                    {
                        title: 'Setting up your CRM Sub-Feature 2',
                        text: [
                            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore\n' +
                            'magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo\n' +
                            'consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore\n' +
                            'magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo\n' +
                            'consequat.'
                        ]
                    },
                    {
                        title: 'Setting up your CRM Sub-Feature 3',
                        text: [
                            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore\n' +
                            'magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo\n' +
                            'consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore\n' +
                            'magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo\n' +
                            'consequat.'
                        ]
                    }
                ]
            },
            'rvm': {
                title: 'Ringless Voicemail Campaigns',
                text: [
                    'Elevate your customer outreach with the power of Ringless Voicemail - audio messages sent directly to your contact\'s voicemail inbox. Utilizing ringless voicemail, you can now deliver personalized and impactful voicemails straight to recipients\' inboxes, all without causing any disruptions.',
                    'Initiate direct voicemail messages seamlessly within your workflow or effortlessly dispatch them to your entire contact roster. Send pre-recorded audio messages or leverage our pioneering Mimic AI technology to send customized audio content to each contact at scale. Through the innovation of Mimic AI™, your voice can be cloned, and generative AI comes into play to formulate unique audio messages tailored to each individual on your list!'
                ],
                walkthroughTourId: 'tourId',
                upsellModalTab: 'contact_center',
                overview: {
                    loading: true,
                    player: undefined,
                    title: 'Feature Overview',
                    videoId: 'tbZzittG-Ko'
                },
                titleDetails: 'Setting up Inbound Call Routes Details',
                textDetails: [
                    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore\n' +
                    'magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo\n' +
                    'consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore\n' +
                    'magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo\n' +
                    'consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore\n' +
                    'magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo\n' +
                    'consequat.'
                ],
                subFeatures: [
                    {
                        title: 'Setting up Inbound Call Routes Sub-Feature 1',
                        text: [
                            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore\n' +
                            'magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo\n' +
                            'consequat.',
                            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.'
                        ]
                    },
                    {
                        title: 'Setting up Inbound Call Routes Sub-Feature 2',
                        text: [
                            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.'
                        ]
                    },
                    {
                        title: 'Setting up Inbound Call Routes Sub-Feature 3',
                        text: [
                            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore\n' +
                            'magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo\n' +
                            'consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore\n' +
                            'magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo\n' +
                            'consequat.'
                        ]
                    }
                ]
            },
            'tracking': {
                title: 'Call Tracking & Recording',
                text: [
                    'Revolutionize your business approach with data-driven analytics tailored for both inbound and outbound calling while visualizing your business dynamics through intricate graphs and charts.  Unlock the power to comprehensively assess employee prowess, peak activity periods, geographical influences, advertising performance, and unveil intricate business insights, empowering you to dynamically refine your marketing endeavors.',
                    'Capture every pivotal moment by effortlessly recording and transcribing every call. This invaluable resource paints a vivid picture of agent performance and client interactions, poised for future perusal. Seamlessly revisit past dialogues, equipping you with indispensable context when nurturing leads, securing a remarkable conversion rate. Elevate your business strategy today!'
                ],
                walkthroughTourId: 'tourId',
                upsellModalTab: 'contact_center',
                overview: {
                    loading: true,
                    player: undefined,
                    title: 'Feature Overview',
                    videoId: 'tbZzittG-Ko'
                },
                titleDetails: 'Using Ringless Voicemail & SMS Details',
                textDetails: [
                    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore\n' +
                    'magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo\n' +
                    'consequat.'
                ],
                subFeatures: [
                    {
                        title: 'Using Ringless Voicemail & SMS Sub-Feature 1',
                        text: [
                            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore\n' +
                            'magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo\n' +
                            'consequat.',
                            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.'
                        ]
                    },
                    {
                        title: 'Using Ringless Voicemail & SMS Sub-Feature 2',
                        text: [
                            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.',
                            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore\n' +
                            'magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo\n' +
                            'consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore\n' +
                            'magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo\n' +
                            'consequat.'
                        ]
                    },
                    {
                        title: 'Using Ringless Voicemail & SMS Sub-Feature 3',
                        text: [
                            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore\n' +
                            'magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo\n' +
                            'consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore\n' +
                            'magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo\n' +
                            'consequat.'
                        ]
                    }
                ]
            },
            'inbox': {
                title: 'Shared Inbox',
                text: [
                    `Elevate your contact center operations with ${$rootScope.settings.APP_NAME}\'s dynamic shared inbox. Seamlessly enhance agent collaboration, conversation prioritization, and customer follow-ups.  Experience call queuing, task routing, and a unified view of all inbound communications, spanning calls, SMS, emails, and even faxes.`,
                    `Empower your users with advanced tools: highlight crucial conversations, delegate tasks to specific users or teams, and schedule conversational reminders.  Crafting exceptional customer journeys often demands collective effort. With ${$rootScope.settings.APP_NAME}, fostering collaborative communication becomes effortless, meeting the demands of your discerning customers.`
                ],
                walkthroughTourId: 'tourId',
                upsellModalTab: 'contact_center',
                overview: {
                    loading: true,
                    player: undefined,
                    title: 'Feature Overview',
                    videoId: 'tbZzittG-Ko'
                },
                titleDetails: 'Using the Inbox Details',
                textDetails: [
                    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore\n' +
                    'magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo\n' +
                    'consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore\n' +
                    'magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo\n' +
                    'consequat.'
                ],
                subFeatures: [
                    {
                        title: 'Using the Inbox Sub-Feature 1',
                        text: [
                            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore\n' +
                            'magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo\n' +
                            'consequat.',
                            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.'
                        ]
                    },
                    {
                        title: 'Using the Inbox Sub-Feature 2',
                        text: [
                            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.',
                            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.'
                        ]
                    },
                    {
                        title: 'Using the Inbox Sub-Feature 3',
                        text: [
                            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore\n' +
                            'magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo\n' +
                            'consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore\n' +
                            'magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo\n' +
                            'consequat.'
                        ]
                    }
                ]
            },
            'crm': {
                title: 'Contact Management',
                text: [
                    `Enhance your lead management process with ${$rootScope.settings.APP_NAME}\'s Free Forever CRM – an advanced and efficient communication solution that elevates customer relationship management.`,
                    'Tailor contact data fields to your business needs and store comprehensive information. Seamlessly track conversations and document touch points across various channels: phone, SMS, email, fax, ringless voicemail, and beyond. Automate workflows to initiate actions or send emails, SMS, and ringless voicemails as leads advance through the customer lifecycle.',
                    `Experience the power of ${$rootScope.settings.APP_NAME}\'s Free Forever CRM to refine customer relationships and enhance their journey across your sales cycle. This is the ultimate tool for optimizing customer relationships and maximizing their progression through your sales cycle.`
                ],
                walkthroughTourId: 'tourId',
                upsellModalTab: 'contact_center',
                overview: {
                    loading: true,
                    player: undefined,
                    title: 'Feature Overview',
                    videoId: 'tbZzittG-Ko'
                },
                titleDetails: 'Setting up your Email Details',
                textDetails: [
                    `Enhance your lead management process with ${$rootScope.settings.APP_NAME}\'s Free Forever CRM – an advanced and efficient communication solution that elevates customer relationship management.`,
                    'Tailor contact data fields to your business needs and store comprehensive information. Seamlessly track conversations and document touch points across various channels: phone, SMS, email, fax, ringless voicemail, and beyond. Automate workflows to initiate actions or send emails, SMS, and ringless voicemails as leads advance through the customer lifecycle.',
                    `Experience the power of ${$rootScope.settings.APP_NAME}\'s Free Forever CRM to refine customer relationships and enhance their journey across your sales cycle. This is the ultimate tool for optimizing customer relationships and maximizing their progression through your sales cycle.`
                ],
                subFeatures: [
                    {
                        title: 'Setting up your Email Sub-Feature 1',
                        text: [
                            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore\n' +
                            'magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo\n' +
                            'consequat.',
                            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.'
                        ]
                    },
                    {
                        title: 'Setting up your Email Sub-Feature 2',
                        text: [
                            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore\n' +
                            'magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo\n' +
                            'consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore\n' +
                            'magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo\n' +
                            'consequat.'
                        ]
                    },
                    {
                        title: 'Setting up your Email Sub-Feature 3',
                        text: [
                            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore\n' +
                            'magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo\n' +
                            'consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore\n' +
                            'magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo\n' +
                            'consequat.'
                        ]
                    }
                ]
            },
            'collecting-leads': {
                title: 'Collecting Leads & Opt-in',
                text: [
                    `Expand your reach and ensure adherence to regulations by seamlessly incorporating ${$rootScope.settings.APP_NAME}\'s lead collection web forms into your website.`,
                    `Garner the desired focus for your newly acquired leads by automatically initiating personalized communication and workflow actions whenever a new lead joins.  Efficiently engage new prospects by dialing through your newly acquired prospects via ${$rootScope.settings.APP_NAME}\'s automated dialing services.  By promptly engaging with leads, you minimize the likelihood of them seeking alternatives and increase the probability of fostering business relationships with your company.`
                ],
                walkthroughTourId: 'tourId',
                upsellModalTab: 'contact_center',
                overview: {
                    loading: true,
                    player: undefined,
                    title: 'Collecting Leads & Opt-in Overview',
                    videoId: 'tbZzittG-Ko'
                },
                titleDetails: 'Collecting Leads & Opt-in Details',
                textDetails: [
                    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore\n' +
                    'magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo\n' +
                    'consequat.'
                ],
                subFeatures: [
                    {
                        title: 'Collecting Leads & Opt-in Sub-Feature 1',
                        text: [
                            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore\n' +
                            'magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo\n' +
                            'consequat.',
                            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.'
                        ]
                    },
                    {
                        title: 'Collecting Leads & Opt-in Sub-Feature 2',
                        text: [
                            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.'
                        ]
                    },
                    {
                        title: 'Collecting Leads & Opt-in Sub-Feature 3',
                        text: [
                            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore\n' +
                            'magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo\n' +
                            'consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore\n' +
                            'magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo\n' +
                            'consequat.'
                        ]
                    }
                ]
            }
        };

        $scope.openUpsellModal = function(tabName) {
            var tab = (tabName && tabName.length > 0) ? tabName : 'contact_center';

            //modal mode: 'default',
            //active tab: 'ringless_voicemail'
            $rootScope.showUpsellModal('default', tab, false);
        };

        $scope.viewWalkthrough = function(tourId) {
            Intercom('startTour', tourId);
        };

        $scope.initYoutubeVideo = function() {

            function onYouTubePlayer() {

                if($scope.currentTab !== null && !$scope.content[$scope.currentTab].overview.player) {
                    let youtubeContainer = $('#youtube-container-player-' + $scope.currentTab);
                    let width = youtubeContainer.outerWidth();
                    let height = youtubeContainer.outerHeight();

                    $scope.content[$scope.currentTab].overview.player = new YT.Player('player-' + $scope.currentTab, {
                        width: width,
                        height: height,
                        videoId: $scope.content[$scope.currentTab].overview.videoId,
                        playerVars: {
                            autoplay: 0,
                            mute: 0,
                            controls: 2,
                            rel: 0,
                            iv_load_policy: 3,
                            modestbranding: 1,
                            origin: window.location.origin,
                            events: {
                                'onReady': () => {
                                    $scope.content[$scope.currentTab].loading = false;
                                }
                            }
                        },
                        host: 'https://www.youtube.com',
                        loading: 'lazy'
                    });
                }
            }

            if(typeof window.YT === 'undefined') {
                let tag = document.createElement('script');
                tag.src = 'https://www.youtube.com/iframe_api';
                let firstScriptTag = document.getElementsByTagName('script')[0];
                firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

                window.onYouTubePlayerAPIReady = function () {
                    onYouTubePlayer();
                };
            } else {
                onYouTubePlayer();
            }
        };

        $scope.changeMenuTab = function(tabKey) {
            $scope.currentTab = tabKey;
        };

        $scope.$on('finishRenderTabs', function() {
            $scope.initWelcomeTabs();

            let tabParam = $location.search().tab;
            let firstMenu = Object.keys($scope.menu)[0];
            let tab = (tabParam) ? tabParam : firstMenu;

            $scope.changeMenuTab(tab);
        });

        $scope.scrollToTab = function(id, prevTab, callback) {
            var screenWidth = (window.innerWidth > 0) ? window.innerWidth : this.screen.width;
            var scrollTop = ((screenWidth <= 909) || (screenWidth <= 1144 && screenWidth >= 1024)) ? 0 : 300;

            if ($(window).scrollTop() >= scrollTop && prevTab !== null) {
                var pageOffset = 90;
                var tabOffset = $('#' + id).offset().top;
                var offset = tabOffset - pageOffset;
                $('html, body').stop();
                $('html, body').animate({
                    scrollTop: offset
                }, 350, function() {
                    callback();
                });
            } else { callback(); }
        };

        $scope.initWelcomeTabs = function() {
            $scope.$watch('currentTab', function(newVal, oldVal) {
                if(newVal !== null) {
                    $scope.initYoutubeVideo();

                    $('#' + newVal + '-tab').on('shown.bs.tab', function () {
                        $scope.scrollToTab(newVal, oldVal, function() { });
                    });
                    $('#' + newVal + '-tab').on('hide.bs.tab', function () {
                        if($scope.content[newVal].overview.player && $scope.content[newVal].overview.player.playerInfo.playerState === 1) {
                            $scope.content[newVal].overview.player.pauseVideo();
                        }
                    });

                    $('#' + newVal + '-tab').tab('show');
                }
            });
        };

        $scope.onInit = function() {
            $window.scrollTo(0, 0);
        };

        if (authService.hasAccount()) {
            $scope.onInit();
        }
        else {
            $scope.$on('auth_complete', function(event, args) {
                $scope.onInit();
            });
        }

    }

    module.exports = WelcomeController;
})();
