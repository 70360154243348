(function() {
    'use strict';

    function OutcomesController(authService, $rootScope, $scope, $window) {
        var vm = this;
        vm.authService = authService;

        $rootScope.viewData = $rootScope.viewData || {};
        $rootScope.viewData.breadcrumbs = $rootScope.viewData.breadcrumbs || [];
        $rootScope.viewData.breadcrumbs = [{
            title: 'Outcomes',
            link_name: 'Outcomes',
            link_url: '#/outcomes',
            help_url: $rootScope.help_url
        }];
    
        $scope.editAbbr = function() {
            $('#outcomesTable').editableTableWidget({editor: $('#editInputExampleOutcomes')}, 4, 'outcomesAbbrField');
        };
        $scope.editName = function() {
            $('#outcomesTable').editableTableWidget({editor: $('#editInputExampleOutcomes')}, 30, 'textField');
        };
        
        $scope.testObject = {
            title: "Outcomes",
            subtitle: "lorem ipsum lorem ipsum",
            addBtn: {
                title: "Add"
            },
            dropDownMainMenu: [
                {
                    title: "Add Outcome"
                }
            ],
            table: {
                header: ["Key", "Code", "Name", "Actions"]
            },
            inputs: {
                sendTextPlaceholder: "Please, enter message",
                triggerZapierPlaceholder: "Please, enter zap name"
            },
            dropDownOptionsEachRow: [
                {
                    title: "Add Outcome Before"
                },
                {
                    title: "Add Outcome After"
                },
                {
                    title: "Delete Outcome"
                }
            ]
        };
        
        $scope.actions = [
            {
                key: "Do Nothing",
                value: ""
            },
            {
                key: "Add to Campaign",
                value: "add_to_campaign"
            },
            {
                key: "Add to DNC",
                value: "add_to_dnc"
            },
            {
                key: "Bad Number",
                value: "bad_number"
            },
            {
                key: "Call Again",
                value: "call_again"
            },
            {
                key: "Send Text",
                value: "send_text"
            },
            {
                key: "Trigger Zapier",
                value: "trigger_zapier"
            }
        ];
        
        $scope.campaigns = [
            {
                key: "Please, select campaign",
                value: ""
            },
            {
                key: "Campaign 1",
                value: "1"
            },
            {
                key: "Campaign 2",
                value: "2"
            },
            {
                key: "Campaign 3",
                value: "3"
            },
            {
                key: "Campaign 4",
                value: "4"
            },
            {
                key: "Campaign 5",
                value: "5"
            }
        ];
    
        $scope.testArray = [
            {
                id: 1,
                abbr: "NC",
                name: "No Contact",
                action: ''
            },
            {
                id: 2,
                abbr: "DNC",
                name: "Do Not Call",
                action: ''
            },
            {
                id: 3,
                abbr: "RD",
                name: "Redial",
                action: ''
            },
            {
                id: 4,
                abbr: "ALT",
                name: "Call ALT Number",
                action: ''
            },
            {
                id: 5,
                abbr: "SALE",
                name: "SALE",
                action: ''
            },
            {
                id: 6,
                abbr: "NC",
                name: "No Contact",
                action: ''
            },
            {
                id: 7,
                abbr: "NC",
                name: "No Contact",
                action: ''
            },
            {
                id: 8,
                abbr: "NC",
                name: "No Contact",
                action: ''
            },
            {
                id: 9,
                abbr: "NC",
                name: "No Contact",
                action: ''
            },
            {
                id: 10,
                abbr: "NC",
                name: "No Contact",
                action: ''
            },
            {
                id: 11,
                abbr: "NC",
                name: "No Contact",
                action: ''
            },
            {
                id: 12,
                abbr: "NC",
                name: "No Contact",
                action: ''
            },
            {
                id: 13,
                abbr: "NC",
                name: "No Contact",
                action: ''
            },
            {
                id: 14,
                abbr: "NC",
                name: "No Contact",
                action: ''
            },
            {
                id: 15,
                abbr: "NC",
                name: "No Contact",
                action: ''
            },
            {
                id: 16,
                abbr: "NC",
                name: "No Contact",
                action: ''
            },
            {
                id: 17,
                abbr: "NC",
                name: "No Contact",
                action: ''
            },
            {
                id: 18,
                abbr: "NC",
                name: "No Contact",
                action: ''
            },
            {
                id: 19,
                abbr: "NC",
                name: "No Contact",
                action: ''
            },
            {
                id: 20,
                abbr: "NC",
                name: "No Contact",
                action: ''
            },
            {
                id: 21,
                abbr: "NC",
                name: "No Contact",
                action: ''
            },
            {
                id: 22,
                abbr: "NC",
                name: "No Contact",
                action: ''
            },
            {
                id: 23,
                abbr: "NC",
                name: "No Contact",
                action: ''
            },
            {
                id: 24,
                abbr: "NC",
                name: "No Contact",
                action: ''
            },
            {
                id: 25,
                abbr: "NC",
                name: "No Contact",
                action: ''
            },
            {
                id: 26,
                abbr: "NC",
                name: "No Contact",
                action: ''
            },
            {
                id: 27,
                abbr: "NC",
                name: "No Contact",
                action: ''
            },
            {
                id: 28,
                abbr: "NC",
                name: "No Contact",
                action: ''
            },
            {
                id: 29,
                abbr: "NC",
                name: "No Contact",
                action: ''
            },
            {
                id: 30,
                abbr: "NC",
                name: "No Contact",
                action: ''
            },
            {
                id: 31,
                abbr: "NC",
                name: "No Contact",
                action: ''
            },
            {
                id: 32,
                abbr: "NC",
                name: "No Contact",
                action: ''
            },
            {
                id: 33,
                abbr: "NC",
                name: "No Contact",
                action: ''
            },
            {
                id: 34,
                abbr: "NC",
                name: "No Contact",
                action: ''
            },
            {
                id: 35,
                abbr: "NC",
                name: "No Contact",
                action: ''
            },
            {
                id: 36,
                abbr: "NC",
                name: "No Contact",
                action: ''
            },
            {
                id: 37,
                abbr: "NC",
                name: "No Contact",
                action: ''
            },
            {
                id: 38,
                abbr: "NC",
                name: "No Contact",
                action: ''
            },
            {
                id: 39,
                abbr: "NC",
                name: "No Contact",
                action: ''
            },
            {
                id: 40,
                abbr: "NC",
                name: "No Contact",
                action: ''
            },
            {
                id: 41,
                abbr: "NC",
                name: "No Contact",
                action: ''
            },
            {
                id: 42,
                abbr: "NC",
                name: "No Contact",
                action: ''
            },
            {
                id: 43,
                abbr: "NC",
                name: "No Contact",
                action: ''
            },
            {
                id: 44,
                abbr: "NC",
                name: "No Contact",
                action: ''
            },
            {
                id: 45,
                abbr: "NC",
                name: "No Contact",
                action: ''
            },
            {
                id: 46,
                abbr: "NC",
                name: "No Contact",
                action: ''
            },
            {
                id: 47,
                abbr: "NC",
                name: "No Contact",
                action: ''
            },
            {
                id: 48,
                abbr: "NC",
                name: "No Contact",
                action: ''
            },
            {
                id: 49,
                abbr: "NC",
                name: "No Contact",
                action: ''
            },
            {
                id: 50,
                abbr: "NC",
                name: "No Contact",
                action: ''
            },
            {
                id: 51,
                abbr: "NC",
                name: "No Contact",
                action: ''
            },
            {
                id: 52,
                abbr: "NC",
                name: "No Contact",
                action: ''
            },
            {
                id: 53,
                abbr: "NC",
                name: "No Contact",
                action: ''
            },
            {
                id: 54,
                abbr: "NC",
                name: "No Contact",
                action: ''
            },
            {
                id: 55,
                abbr: "NC",
                name: "No Contact",
                action: ''
            },
            {
                id: 56,
                abbr: "NC",
                name: "No Contact",
                action: ''
            },
            {
                id: 57,
                abbr: "NC",
                name: "No Contact",
                action: ''
            },
            {
                id: 58,
                abbr: "NC",
                name: "No Contact",
                action: ''
            },
            {
                id: 59,
                abbr: "NC",
                name: "No Contact",
                action: ''
            },
            {
                id: 60,
                abbr: "NC",
                name: "No Contact",
                action: ''
            },
            {
                id: 61,
                abbr: "NC",
                name: "No Contact",
                action: ''
            },
            {
                id: 62,
                abbr: "NC",
                name: "No Contact",
                action: ''
            },
            {
                id: 63,
                abbr: "NC",
                name: "No Contact",
                action: ''
            },
            {
                id: 64,
                abbr: "NC",
                name: "No Contact",
                action: ''
            },
            {
                id: 65,
                abbr: "NC",
                name: "No Contact",
                action: ''
            },
            {
                id: 66,
                abbr: "NC",
                name: "No Contact",
                action: ''
            },
            {
                id: 67,
                abbr: "NC",
                name: "No Contact",
                action: ''
            },
            {
                id: 68,
                abbr: "NC",
                name: "No Contact",
                action: ''
            },
            {
                id: 69,
                abbr: "NC",
                name: "No Contact",
                action: ''
            },
            {
                id: 70,
                abbr: "NC",
                name: "No Contact",
                action: ''
            },
            {
                id: 71,
                abbr: "NC",
                name: "No Contact",
                action: ''
            },
            {
                id: 72,
                abbr: "NC",
                name: "No Contact",
                action: ''
            },
            {
                id: 73,
                abbr: "NC",
                name: "No Contact",
                action: ''
            },
            {
                id: 74,
                abbr: "NC",
                name: "No Contact",
                action: ''
            },
            {
                id: 75,
                abbr: "NC",
                name: "No Contact",
                action: ''
            },
            {
                id: 77,
                abbr: "NC",
                name: "No Contact",
                action: ''
            },
            {
                id: 78,
                abbr: "NC",
                name: "No Contact",
                action: ''
            },
            {
                id: 79,
                abbr: "NC",
                name: "No Contact",
                action: ''
            },
            {
                id: 80,
                abbr: "NC",
                name: "No Contact",
                action: ''
            },
            {
                id: 81,
                abbr: "NC",
                name: "No Contact",
                action: ''
            },
            {
                id: 82,
                abbr: "NC",
                name: "No Contact",
                action: ''
            },
            {
                id: 83,
                abbr: "NC",
                name: "No Contact",
                action: ''
            },
            {
                id: 84,
                abbr: "NC",
                name: "No Contact",
                action: ''
            },
            {
                id: 85,
                abbr: "NC",
                name: "No Contact",
                action: ''
            },
            {
                id: 86,
                abbr: "NC",
                name: "No Contact",
                action: ''
            },
            {
                id: 87,
                abbr: "NC",
                name: "No Contact",
                action: ''
            },
            {
                id: 88,
                abbr: "NC",
                name: "No Contact",
                action: ''
            },
            {
                id: 89,
                abbr: "NC",
                name: "No Contact",
                action: ''
            },
            {
                id: 90,
                abbr: "NC",
                name: "No Contact",
                action: ''
            },
            {
                id: 91,
                abbr: "NC",
                name: "No Contact",
                action: ''
            },
            {
                id: 92,
                abbr: "NC",
                name: "No Contact",
                action: ''
            },
            {
                id: 93,
                abbr: "NC",
                name: "No Contact",
                action: ''
            },
            {
                id: 94,
                abbr: "NC",
                name: "No Contact",
                action: ''
            },
            {
                id: 95,
                abbr: "NC",
                name: "No Contact",
                action: ''
            },
            {
                id: 96,
                abbr: "NC",
                name: "No Contact",
                action: ''
            },
            {
                id: 97,
                abbr: "NC",
                name: "No Contact",
                action: ''
            },
            {
                id: 98,
                abbr: "NC",
                name: "No Contact",
                action: ''
            },
            {
                id: 99,
                abbr: "NC",
                name: "No Contact",
                action: ''
            }
        ];
        
        $scope.totalItems = $scope.testArray.length;
        $scope.currentPage = 1;
        $scope.itemsPerPage = 10;
        $scope.maxSize = 3; 

        $scope.setPage = function (pageNo) {
            $scope.currentPage = pageNo;
        };

        $scope.pageChanged = function() {
            $window.scrollTo(0, 0);
        };

        $scope.setItemsPerPage = function(num) {
            $scope.itemsPerPage = num;
            $scope.currentPage = 1; //reset to first page
        };
    }
    module.exports = OutcomesController;
})();
