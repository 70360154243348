(function () {
    'use strict';

    function VoicesController($rootScope, $scope, $location, authService, voiceService, $interval, $window, $timeout) {
        var vm = this;
        vm.authService = authService;

        $rootScope.viewData = $rootScope.viewData || {};
        $rootScope.viewData.breadcrumbs = $rootScope.viewData.breadcrumbs || [];
        $rootScope.viewData.breadcrumbs = [{
            title: 'Voices',
            link_name: 'Voices',
            link_url: '#/voices',
            help_url: $rootScope.help_url
        }];
    
        $scope.staticData = {
            title: "Voices",
            subtitle: "You can manage your voices below...",
            infoBlock: {
                title: 'Helpful Tips:',
                list: [
                    "To clone our voice, we need 30 to 60 seconds of you talking.",
                    "The better your microphone, the better your voice clone will sound.",
                    "You can talk about anything for 30-60 seconds.  It doesn't matter what you talk about or if you make mistakes.",
                    "Talk in a tone that you would use when talking to your customers.  Try not to read a script.  Our AI will mimic your tone when generating audio."
                ]
            },
            addBtn: {
                title: "Add Voice"
            },
            table: {
                header: ["Name", "Date Uploaded"],
                actions: [
                    {
                        title: "Delete Voice"
                    }
                ],
                noRes: "You don't have any voices."
            },
            loading: "Loading",
            uploading: "Uploading",
            saving: "Saving",
            modal: {
                title: "Add Voice",
                nameBlock: {
                    title: "Voice Name",
                    desc: "Enter a name for your voice."
                },
                genderBlock: {
                    title: "Biological Sex",
                    desc: "Select the biological sex of the voice you are cloning.  This will improve the accuracy of the voice cloning process."
                },
                optionsBlock: {
                    title: "Audio Source",
                    subtitle: "Please select your audio source containing the voice to clone.  Please make sure you have little to no background noise in your recording and talk in the tone that you would like our AI to mimic.",
                    option1: {
                        title: "Upload a File",
                        desc: "Drop a .mp3 or .wav file containing approximately 60 seconds of speech and no background noise."
                    },
                    option2: {
                        title: "Dial in by Phone",
                        desc: "Call the phone number below to record your message.",
                        ul: {
                            text1: "Call",
                            text2: "from your phone.",
                            text3: "Enter your pin:",
                            text4: "Record your message when prompted."
                        },
                        descAfter: "This page will automatically updated once you record your message."
                    }
                },
                fileUploadedMessage: "Success! File uploaded!",
                submitBtn: "Save"
            }
        };
    
        $scope.voices = [];
        $scope.isGetStarted = false;
        
        $scope.newVoice = {};
        
        $scope.loading = {
            voicesFirstLoad: true,
            voices: false,
            media: false,
            uploading: false,
            saving: false
        };
        $scope.currentOpenedPlayerId = -1;
        
        $scope.dropify = {
            options: {},
            events: {}
        };
        
        $scope.dialIn = {
            voice_id: null,
            phone: '',
            pin: ''
        };
        
        $scope.policy = {};
        $scope.stopTimeDialIn = undefined;

        $scope.totalItems = 0;
        $scope.currentPage = 1;
        $scope.itemsPerPage = 8;
        $scope.maxSize = 3;

        $(document).on("hide.bs.modal", "#addRecordingModal", function() {
            if($scope.newVoice.saved) {
                $scope.loading.media = false;
                $scope.cleanModal();
            } else {
                $scope.confirmDelete($scope.newVoice, function(res) {
                    $scope.loading.media = false;
                    $scope.cleanModal();
                    $scope.$apply();
                });
            }
            
        });
        
        $scope.saveNewRecording = function() {
            if($scope.newVoice.name && $scope.newVoice.uploaded) {
                $scope.loading.saving = true;
                
                if($scope.newVoice.option === 'dial_in_by_phone') {
                    $interval.cancel($scope.stopTimeDialIn);
                }

                let request = {
                    team_id: authService.getTeamId(),
                    user_id: authService.getUserId(),
                    voice_id: $scope.newVoice.voice_id,
                    name: $scope.newVoice.name,
                    gender: $scope.newVoice.gender
                };

                voiceService.cloneVoice(request)
                   .then(results => {
                       $scope.newVoice.saved = true;
                       $scope.loading.saving = false;
                       $scope.$apply();

                       $('#addRecordingModal').modal('hide');
                       swal("Success!", "New Voice is now available", "success");
                       $scope.loadVoices(true);
                   })
                   .catch(err => {
                       console.log(err);
                       swal("Cancelled", "Unable to update Voice", "error");
                       $scope.loading.saving = false;
                       $scope.$apply();
                   });
            }
        };
        
        $scope.validation = function() {
            return ($scope.newVoice.name && $scope.newVoice.uploaded);
        };
        
        $scope.__watchForRecording = function(dialIn, ext) {
            return new Promise((resolve, reject) => {

                const request = {
                    team_id: authService.getTeamId(),
                    user_id: authService.getUserId(),
                    voice_id: dialIn.voice_id,
                    ext: ext
                };

                voiceService.getUrl(request)
                    .then(results => resolve(results))
                    .catch(err => resolve());
            });
        };
        
        $scope.watchForRecording = function() {
            var dialIn = $scope.dialIn || {};
            if (dialIn.voice_id) {
                Promise.all([$scope.__watchForRecording(dialIn, '.mp3'), $scope.__watchForRecording(dialIn, '.wav')])
                    .then(results => {
                        let url = ((results[0] || {}).url || (results[1] || {}).url);
                        if (url) {
                            $scope.newVoice.url = url;
                            $scope.newVoice.uploaded = true;
                            $scope.$apply();
                            $timeout(function(){
                                $scope.saveNewRecording();
                            });
                        }
                    })
                    .catch(err => {});
            }
        };
        
        $scope.__uploadFile = function(file, policyType) {
            var policy = $scope.policy[policyType];
            if (!policy) {
                swal("Cancelled", "Unable to upload. Please try again later.", "error");
                $scope.loading.uploading = false;
                $scope.$apply();
            }
            else {
                $.ajax({
                    type: 'PUT',
                    url: policy.url,
                    contentType: policy.content_type,
                    processData: false,
                    data: file
                })
                .done(function(data, textStatus, jqXHR) {
                    $scope.getRecordURL($scope.newVoice.voice_id, $scope.newVoice.ext, function(data) {
                        $scope.newVoice.url = data.url;
                        
                        $scope.loading.uploading = false;
                        $scope.newVoice.uploaded = true;
                        $scope.$apply();
                        $timeout(function(){
                            $scope.saveNewRecording();
                        });
                    });
                })
                .fail(function(jqXHR, textStatus, errorThrown) {
                    $scope.loading.uploading = false;
                    $scope.$apply();
                    swal("Cancelled", "File NOT uploaded", "error");
                });
            }
        };
        
        $scope.formatDialInPhone = function(value, forceRegion) {
            const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
            var phoneLocale = '';
            try {
                var region; // intentionally undefined
                if (!forceRegion) {
                    region = 'US';
                }
                var numberFormat = (region === 'US' || region === 'CA') ? libphonenumber.PhoneNumberFormat.NATIONAL : libphonenumber.PhoneNumberFormat.INTERNATIONAL;

                var parsed = phoneUtil.parse(value || '', region);
                if (parsed) {
                    phoneLocale = phoneUtil.format(parsed, numberFormat);
                }
            }
            catch(e) {
                console.log(e);
            }
            return phoneLocale || value;
        };

        $scope.hasPermissions = function(entity, action) {
            return $rootScope.hasPermissions(entity, action);
        };
        
        $scope.setPhoneNumber = function(phoneNumber) {
            $scope.dialIn.phone = phoneNumber;
            $scope.dialIn.formatted_phone = $scope.formatDialInPhone(phoneNumber, 'ZZ');
        };
        
        $scope.formatDialInPin = function(value) {
            var pin = value || '';
            return pin.substr(0, 3) + '-' + pin.substr(3, 3) + '-' + pin.substr(6);
        };
        
        $scope.loadPolicy = function(teamId, voiceId) {
            const request = {
                team_id: teamId,
                user_id: authService.getUserId(),
                voice_id: voiceId
            };

            voiceService.getPolicy(request)
                .then(results => {
                    $scope.policy = results;
                    $scope.loading.media = false;
                    $scope.$apply();
                })
                .catch(err => {
                    console.error(err);
                    $scope.loading.media = false;
                    $scope.$apply();
                });
        };
        
        $scope.createVoice = function(option) {
            $scope.loading.media = true;

            const request = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                name: null,
                gender: null,
                generate_pin: (option === 'dial_in_by_phone') ? true : false
            };

            voiceService.createVoice(request)
                .then(results => {
                    $scope.newVoice = angular.merge($scope.newVoice, results);
            
                    switch(option) {
                        case 'upload_a_file':
                            $scope.loadPolicy(request.team_id, results.voice_id);
                            break;
                        case 'dial_in_by_phone':
                            $scope.dialIn = {
                                voice_id: results.voice_id,
                                phone_list: (results.phone || '').split(','),
                                pin: results.pin,
                                formatted_pin: $scope.formatDialInPin(results.pin)
                            };
                            $scope.dialIn.phone = $scope.dialIn.phone_list[0];
                            $scope.dialIn.formatted_phone = $scope.formatDialInPhone($scope.dialIn.phone, 'ZZ');
                            $scope.loadPolicy(request.team_id, results.voice_id);
                            $scope.stopTimeDialIn = $interval(function() {
                                $scope.watchForRecording(); 
                            }, 5000);
                            break;
                    }
                })
                .catch(err => {
                    console.error(err);
                    $scope.loading.media = false;
                    $scope.$apply();
                });
        };
        
        $scope.cleanModal = function() {
            angular.element('.dropify-clear').triggerHandler('click');
            $scope.dialIn = {
                voice_id: null,
                phone: '',
                pin: ''
            };
            $scope.newVoice = {};
            $scope.policy = {};
            $scope.loading.uploading = false;
        };
        
        $scope._setUploadOption = function(option, name, gender) {
            $scope.newVoice.option = option;
            $scope.newVoice.name = name;
            $scope.newVoice.gender = gender;
            $scope.$apply();
            $scope.createVoice(option);
        };
        
        $scope.setUploadOption = function(value) {
            $scope.loading.media = true;
            if($scope.newVoice.voice_id) {
                let name = $scope.newVoice.name;
                let gender = $scope.newVoice.gender;
                if($scope.stopTimeDialIn) {
                    $interval.cancel($scope.stopTimeDialIn);
                }
                $scope.confirmDelete($scope.newVoice, function(res) {
                    $scope.cleanModal();
                    $scope.$apply();
                    
                    $scope._setUploadOption(value, name, gender);
                });
            } else {
                $scope.newVoice = {};
                $scope._setUploadOption(value, 'My Voice', 'male');
            } 
        };
        
        $scope.initDropify = function() {
            $('.dropify-mp3').dropify({
                messages: {
                'default': 'Drag and drop a wav or mp3 file here or click',
                'replace': 'Drag and drop or click to replace',
                'remove':  'Remove',
                'error':   'The file is not allowed (mp3, wav only).'
            }});
        
            $('.dropify-mp3').change(function(event) {
                var target = event.currentTarget || {};
                if (target && target.files && target.files.length) {
                    $scope.loading.uploading = true;
                    $scope.$apply();

                    var file = target.files[0];
                    var parts = (file.name || '').split('.');
                    var ext = (parts[parts.length - 1] || '').toLowerCase();
                    if (ext === 'mp3' || ext === 'wav') {
                        $scope.newVoice.ext = '.' + ext;
                        $scope.__uploadFile(file, ext);
                    }
                }
            });
            
            $('.dropify-mp3').dropify().on('dropify.beforeClear', function(event, element){
                $scope.newVoice.uploaded = false;
                $scope.newVoice.url = undefined;
                $scope.$apply();
            });
        };
        
        $(document).on("show.bs.modal", "#addRecordingModal", function() {
            $scope.setUploadOption('upload_a_file');
        });
        
        ///////////////////////////////////////////////////////

        // player start
        $scope.currentOpenedPlayerId = -1;
        $scope.$on('currentOpenedRecordChanged', function(event, newCurrentOpenedPlayerId, recordType) {
            $scope.currentOpenedPlayerId = newCurrentOpenedPlayerId;
        });
        $scope.$on('audioObjectChanged', function(event, audioObject, index, recordType) {
            $scope.voices[index].audio = audioObject;
        });
        // player end

        $scope.checkRecordingName = function(name) {
            if (name.length === 0) {
                return "Recording Name must have at least 1 character.";
            }
        };

        $scope.updateRecordingName = function(voice) {

            var request = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                voice_id: voice.voice_id,
                name: voice.name,
                ext: $scope.newVoice.ext || '.wav',
                media_exists: true
            };
            voiceService.updateVoice(request)
                .then(results => {})
                .catch(err => {
                    console.log(err);
                    swal("Cancelled", "Unable to update Voice", "error");
                });
        };
        
        $scope.newNameValue = '';
        $scope.editNameField = function(voice) {
            if($scope.newNameValue !== voice.name) {
                $scope.newNameValue = voice.name;
                $scope.updateRecordingName(voice);
            }
        };

        $scope.confirmDelete = function(voice, callback) {
            
            var req = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                voice_id: voice.voice_id
            };

            voiceService.deleteVoice(req)
                .then(results => {
                    callback(null);
                })
                .catch(err => {
                    console.log(err);
                    callback(err);
                });
        };
        
        $scope.deleteRecording = function(e, voice) {
            if (e) {
                e.preventDefault();
            }
            
            swal({   
                title: "Confirm",   
                text: "Are you sure you want to delete this voice?",
                buttons: ["No", "Yes"],
                dangerMode: true,
                closeOnClickOutside: false
            }).then(isConfirm =>{
                if (isConfirm) { 
                    $scope.confirmDelete(voice, function(res) {
                        if(res === null) {
                            swal("Deleted!", "This Voice has been deleted!", "success"); 
                            $scope.loadVoices(false);
                            $scope.$apply();
                        } else swal("Cancelled", "Unable to delete Voice", "error");
                    });
                } 
            });
        };
        
        $scope.getRecordURL = function(voiceId, ext, callback) {

            const request = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                voice_id: voiceId,
                ext: ext
            };
            
            voiceService.getUrl(request)
                .then(results => {
                    callback(results);
                })
                .catch(err => {
                    console.error(err);
                    callback(null);
                });
        };

        $scope.setPage = function (pageNo) {
            $scope.currentPage = pageNo;
        };

        $scope.setItemsPerPage = function(num) {
            $scope.itemsPerPage = num;
            $scope.currentPage = 1;
        };

        $scope.loadMoreRecordings = function() {
            $scope.loadVoices(false, ($scope.currentPage - 1) * $scope.itemsPerPage, 'voices');
        };
        
        $scope.loadVoices = function(isAddedNew, skip, loader) {
            $scope.loading[loader] = true;
            $window.scrollTo(0, 0);

            const request = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                sort: 'desc',
                include_urls: true,
                media_exists: true,
                skip: skip,
                limit: $scope.itemsPerPage
            };
            
            voiceService.listVoiceUrls(request)
                .then(results => {
                    $scope.voices = results.voices;
                    $scope.totalItems = results.total;
                    $scope.loading[loader] = false;
                    
                    if($scope.isGetStarted && $scope.voices.length === 1 && isAddedNew) {
                        $location.search({});
                        $location.path('dash');
                    }
                    $scope.$apply();
                })
                .catch(err => {
                    console.error(err);
                    $scope.loading[loader] = false;
                    $scope.$apply();
                });
        };
        
        $scope.onInit = function() {
            $scope.isGetStarted = ($location.search().type === 'get_started') ? true : false;
            $scope.loadVoices(false, 0, 'voicesFirstLoad');
            
            $scope.$watch('newVoice.option', function(newValue, oldValue) {
                switch(newValue) {
                    case 'upload_a_file':
                        $timeout(function(){
                            $scope.initDropify();
                        });
                        break;
                }
            });
        };
        
        $scope.$on('$destroy', function() {
            if($scope.stopTimeDialIn)
                $interval.cancel($scope.stopTimeDialIn);
        });

        if (authService.hasAccount()) {
            $scope.onInit();
        }
        else {
            $scope.$on('auth_complete', function(event, args) {
                $scope.onInit();
            });
        }
    }
    module.exports = VoicesController;
})();
