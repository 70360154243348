(function () {
    'use strict';

    function BucketsController($window, authService, $rootScope, $scope, contactService,
       tagsService, userService, notificationToastService, $location, boardsService) {

        let vm = this;
        vm.authService = authService;

        $rootScope.viewData = $rootScope.viewData || {};
        $rootScope.viewData.breadcrumbs = $rootScope.viewData.breadcrumbs || [];
        $rootScope.viewData.breadcrumbs = [{
            title: 'Buckets',
            link_name: 'Buckets',
            link_url: '#/buckets',
            help_url: $rootScope.help_url
        }];

        $scope.staticContent = {
            title: 'Buckets',
            subtitle: 'Visualize your contact lists...',
            bucketsMenu: {
                main: ['New Bucket Board', 'Delete Bucket Board'],
                visibility: {
                    title: 'Set Visibility',
                    menu: ['All', 'Just Me']
                }
            },
            errors: {
                listName: 'Please enter a name for your contact list',
                valid: 'Please fill all required fields',
            },
            searchBuckets: {
                title: 'Available Boards',
                noBoards: 'No Boards'
            },
            searchLists: {
                title: 'Add Existing List',
                noLists: 'No Lists'
            },
            newList: {
                title: 'Create New List'
            },
            empty: {
                icon: 'fa-light fa-objects-align-top',
                message: 'No Buckets'
            },
            newListModal: {
                title: {
                    str1: 'Create a New List',
                },
                listNameBlock: {
                    title: 'List Name',
                    subtitle: 'Enter a name for your list...'
                },
                submitBtn: 'Create'
            },
            loading: 'Loading',
            maxColumns: 16
        };

        $scope.loading = {
            buckets: false,
            selectedBucket: false,
            newList: false
        };

        $scope.data = {
            buckets: [],
            selectedBucket: null,
            tags: [],
            teams: [],
            lists: [],
            search: {
                term: '',
                submitted: false
            },
            listDragStart: null,
            list: {
                list_id: null,
                list_name: ''
            },
            valid: false,
            errors: {
                fields: {
                    listName: undefined
                }
            }
        };

        $scope.validateStep = function() {
            $scope.data.errors.fields.listName = !!($scope.data.list.list_name);
            $scope.data.valid = !!($scope.data.list.list_name);
        };

        $scope.updateBoard = function(visibility) {
            $scope.loading.selectedBucket = true;

            let request = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                board_id: $scope.data.selectedBucket.board_id,
                lists: $scope.data.selectedBucket.lists,
                name: $scope.data.selectedBucket.name,
            };

            if(visibility) {
                request.visible_for_user_id = visibility.visible_for_user_id;
                request.visible_for_user_group_id = visibility.visible_for_user_group_id;
            }

            return boardsService.updateBoard(request);
        };

        const insert = (arr, index, newItem) => [
            ...arr.slice(0, index), newItem, ...arr.slice(index)
        ];

        $scope.insertListToPosition = function(list, insertToIndex) {
            $scope.data.selectedBucket.lists = insert($scope.data.selectedBucket.lists, insertToIndex, list);
        };

        $scope.removeListFromPosition = function(list) {
            let removeFromIndex = _.findIndex($scope.data.selectedBucket.lists, function(l) { return l.list_id === list.list_id && l.dropped; });
            $scope.data.selectedBucket.lists.splice(removeFromIndex, 1);
        };

        $scope.getListIndex = function(list) {
            return _.findIndex($scope.data.selectedBucket.lists, function(l) { return l.list_id === list.list_id; });
        };

        $scope.getNewIndexDropList = function(oldIndex, index) {
            let newIndex = index;

            if(index > oldIndex) {
                let dropIndex = index - 1;
                newIndex = (dropIndex < oldIndex) ? oldIndex : dropIndex;
            }
            return newIndex;
        };

        $scope.dragOverList = function(index, external, type) {
            let oldIndex = $scope.getListIndex($scope.data.listDragStart);
            let newIndex = $scope.getNewIndexDropList(oldIndex, index);

            if(oldIndex !== newIndex) {
                return true;
            }

            return false;
        };

        $scope.dropList = function(index, item, external, type) {
            let oldIndex = $scope.getListIndex(item);
            let newIndex = $scope.getNewIndexDropList(oldIndex, index);

            if(oldIndex !== newIndex) {
                $scope.data.selectedBucket.lists[$scope.getListIndex(item)].dropped = true;
                $scope.insertListToPosition(item, index);
                return true;
            }

            return false;
        };

        $scope.$on('list.drag.start.bucket', function (event, list) {
            $scope.data.listDragStart = list;
        });

        $scope.$on('remove.from.bucket', function (event, list) {
            $scope.removeListFromPosition(list);
            $scope.updateBoard()
                .then(() => {})
                .catch(e => { console.log(e); })
                .then(() => {
                    $scope.loading.selectedBucket = false;
                });
        });

        $scope.$on('dragend.bucket', function (event, item, type) {
            $scope.data.listDragStart = null;
        });

        $scope.onOpenCloseAddList = function(isOpen) {
            let listColScrollWidth = $('.list-col-scroll')[0].scrollWidth;
            let scrollBucket = $('.scroll-bucket');

            if(isOpen) {
                scrollBucket.stop();
                scrollBucket.animate({scrollLeft: listColScrollWidth}, 350, 'linear');
            }
        };

        $scope.sortBoardsBySelection = function(buckets) {
            $scope.data.buckets = _.sortBy(buckets, [function(b) { return $scope.data.selectedBucket.board_id !== b.board_id; }]);
        };

        $scope.sortListsBySelection = function(lists) {
            $scope.data.lists = _.sortBy(lists, [function(l) { return $scope.isListInBucket(l) === -1; }]);
        };

        $scope._selectBucket = function() {
            if($scope.data.selectedBucket && $scope.data.selectedBucket !== null) {
                $window.localStorage['selected_bucket_id'] = $scope.data.selectedBucket.board_id || null;
            }

            $scope.sortBoardsBySelection($scope.data.buckets);
            $scope.sortListsBySelection($scope.data.lists);
        };

        $scope.selectBucket = function() {
            $('.scroll-bucket').scrollLeft(0);
            $scope._selectBucket();
            if ($scope.data.selectedBucket.lists) {
                const knownListDictionary = $scope.data.lists.reduce((acc, obj) => {
                    acc[obj.list_id] = obj;
                    return acc;
                }, {});
                const promises = [];
                for (let i = 0; i < $scope.data.selectedBucket.lists.length; i++) {
                    if (!knownListDictionary[$scope.data.selectedBucket.lists[i].list_id]) {
                        promises.push($scope.loadListById($scope.data.selectedBucket.lists[i].list_id));
                    }
                }
                return Promise.all(promises)
                    .catch(err => console.log(err))
                    .finally(() => $scope.$apply());
            }
        };

        $scope.getVisibilitySelectedTeamName = function() {
            let teamIndex = _.findIndex($scope.data.teams, function(t) { return t.user_group_id === $scope.data.selectedBucket.visible_for_user_group_id; });
            return (teamIndex !== -1 ) ? $scope.data.teams[teamIndex].name : '';
        };

        $scope.setVisibility = function(visibility) {
            $scope.closeActivePopovers();
            let visibleForUserId, visibleForUserGroupId;

            switch (visibility) {
                case 'all':
                    visibleForUserId = null;
                    visibleForUserGroupId = null;
                    break;
                case 'justMe':
                    visibleForUserId = authService.getUserId();
                    visibleForUserGroupId = null;
                    break;
                default:
                    visibleForUserId = null;
                    visibleForUserGroupId = visibility;
                    break;
            }

            $scope.updateBoard({ visible_for_user_id: visibleForUserId, visible_for_user_group_id: visibleForUserGroupId })
                .then(() => {
                    $scope.data.selectedBucket.visible_for_user_id = visibleForUserId;
                    $scope.data.selectedBucket.visible_for_user_group_id = visibleForUserGroupId;

                    notificationToastService.showSuccessToast('fa-light fa-circle-check', 'Visibility has been changed!');
                })
                .catch(e => {
                    console.log(e);
                    notificationToastService.showErrorToast('fa-light fa-triangle-exclamation', 'We were unable to change visibility. Try again later.');
                })
                .then(() => {
                    $scope.loading.selectedBucket = false;
                    $scope.$apply();
                });
        };

        $scope.newBucketNameValue = '';
        $scope.editBucketName = function() {
            if($scope.newBucketNameValue !== $scope.data.selectedBucket.name) {
                $scope.newBucketNameValue = $scope.data.selectedBucket.name;

                $scope.updateBoard()
                    .then(() => {})
                    .catch(e => { console.log(e); })
                    .then(() => {
                        $scope.loading.selectedBucket = false;
                    });
            }
        };

        $scope.checkBucketName = function(bucket) {
            if (bucket.length === 0) {
                return 'Bucket Board Name must have at least 1 character.';
            }
        };

        $scope.isListInBucket = function(list) {
            if(!$scope.data.selectedBucket) {
                return -1;
            }
            return _.findIndex($scope.data.selectedBucket.lists, function(l) { return l.list_id === list.list_id; });
        };

        $scope.addListBucket = function() {
            $scope.loading.selectedBucket = true;

            let listMaxOrder = (_.maxBy($scope.data.selectedBucket.lists, function(list) { return list.order; }) || {}).order;
            let checkedMaxOrder = (listMaxOrder >= 0 ) ? listMaxOrder : -1;

            let addList = {
                list_id: $scope.data.selectedBucket.addList.list_id,
                sort: 'newestFirst',
                order: ++checkedMaxOrder
            };

            let request = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                board_id: $scope.data.selectedBucket.board_id,
                list: addList
            };

            boardsService.addListToBoard(request)
                .then(() => {
                    addList.firstLoading = true;
                    $scope.data.selectedBucket.lists.push(addList);

                    $scope.data.selectedBucket.addList = null;
                    $scope.sortListsBySelection($scope.data.lists);
                })
                .catch(e => { console.log(e); })
                .then(() => {
                    $scope.loading.selectedBucket = false;
                    $scope.$apply();
                });
        };

        $scope.hideListBucket = function(list) {
            $scope.loading.selectedBucket = true;

            let request = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                board_id: $scope.data.selectedBucket.board_id,
                list_id: list.list_id,
            };

            boardsService.deleteListFromBoard(request)
                .then(() => {
                    let index = _.findIndex($scope.data.selectedBucket.lists, function(l) { return l.list_id === list.list_id; });
                    if(index !== -1) {
                        $scope.data.selectedBucket.lists.splice(index, 1);
                    }

                    $scope.sortListsBySelection($scope.data.lists);
                })
                .catch(e => { console.log(e); })
                .then(() => {
                    $scope.loading.selectedBucket = false;
                    $scope.$apply();
                });
        };

        $scope.$on('hide.list.bucket', function (event, list) {
            $scope.hideListBucket(list);
        });

        $scope.closePopoversEventListeners = function() {
            $('body').on('click', function (e) {
                let popoverBtn = $('.popover-menu-btn.active');
                let popovers = $('.buckets-popover-menu');

                if(!popoverBtn.is(e.target) && popoverBtn.has(e.target).length === 0 && !popovers.is(e.target) && popovers.has(e.target).length === 0) {
                    popoverBtn.popover('hide');
                    if(popovers.hasClass('show')) {
                        popovers.remove();
                    }
                }
            });
            $(document).on('click', '.popover-menu-btn', function () {
                $('.popover-menu-btn.active').not(this).popover('hide');
            });
        };

        $scope.closeActivePopovers = function() {
            $('.popover-menu-btn.active').popover('hide');
        };

        $scope.initPopover = function(popoverId, contentId, popoverDivId, offset) {
            let templateStr = '<div class="popover buckets-popover-menu" id="' + popoverDivId + '" role="tooltip"><div class="popover-body"></div></div>';
            $('#' + popoverId).popover({
                container: 'body',
                placement: 'bottom',
                html: true,
                content: $('#' + contentId),
                template: templateStr,
                trigger: 'click',
                offset: offset
            });
        };

        $scope.bucketsPopoverInitListeners = function() {
            let popoverId = 'buckets-menu-dropdown';
            let contentId = 'buckets-menu-popover';
            let popoverDivId = 'buckets-item-popover';
            let parentId = 'buckets-main-menu';

            $scope.initPopover(popoverId, contentId, popoverDivId, '-79px, 0');

            $('#' + popoverId).on('hide.bs.popover', function() {
                $('#' + parentId).removeClass('show');
                $('#' + popoverId).removeClass('active');
            });
            $('#' + popoverId).on('show.bs.popover', function() {
                $('#' + parentId).addClass('show');
                $('#' + popoverId).addClass('active');
            });
        };

        $scope._deleteBoard = function() {
            $scope.loading.selectedBucket = true;

            let request = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                board_id: $scope.data.selectedBucket.board_id
            };

            boardsService.deleteBoard(request)
                .then(() => {
                    let index = _.findIndex($scope.data.buckets, function(item) { return item.board_id === $scope.data.selectedBucket.board_id; });
                    if(index !== -1) {
                        $scope.data.buckets.splice(index, 1);
                    }

                    $scope.data.selectedBucket = $scope.data.buckets[0] || null;
                    $scope.selectBucket();

                    notificationToastService.showSuccessToast('fa-light fa-trash-check', 'Bucket board has been deleted!');
                })
                .catch(e => {
                    console.log(e);
                    notificationToastService.showErrorToast('fa-light fa-triangle-exclamation', 'We were unable to delete bucket board. Try again later.');
                })
                .then(() => {
                    $scope.loading.selectedBucket = false;
                    $scope.$apply();
                });
        };

        $scope.deleteBoard = function() {
            $scope.closeActivePopovers();

            swal({
                title: 'Delete Bucket Board',
                text: 'Do you want to delete ' + $scope.data.selectedBucket.name + ' ' + '?',
                buttons: ['No', 'Yes'],
                dangerMode: true,
                closeOnClickOutside: false
            }).then((confirm) => {
                if (!confirm) { return; }

                $scope._deleteBoard();
            });
        };

        $scope.createBoard = function() {
            $scope.closeActivePopovers();
            $scope.loading.selectedBucket = true;

            let newBoard = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                visible_for_user_group_id: null,
                visible_for_user_id: null,
                name: 'New Bucket Board',
                lists: []
            };

            boardsService.createBoard(newBoard)
                .then(newBoard => {
                    $scope.clearSearch();
                    $scope.data.buckets.push(newBoard);
                    $scope.data.selectedBucket = newBoard;
                    $scope.selectBucket();
                })
                .catch(e => {
                    console.log(e);
                    notificationToastService.showErrorToast('fa-light fa-triangle-exclamation', 'We were unable to create bucket board. Try again later.');
                })
                .then(() => {
                    $scope.loading.selectedBucket = false;
                    $scope.$apply();
                });
        };

        $scope.loadLists = function() {
            const request = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                get_total: true
            };

            return contactService.getLists(request)
                .then(results => {
                    $scope.data.lists = results.lists;
                });
        };

        $scope.loadListById = function(list_id) {

            const request = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                list_id: list_id
            };

            return contactService.getList(request)
                .then(results => {
                    $scope.data.lists.push(results)
                })
                .catch(err => {
                    console.error(err);
                })
                .then(() => {
                    $scope.$apply();
                });
        };

        $scope.loadTags = function() {
            const request = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId()
            };

            return tagsService.getTags(request)
                .then(result => {
                    $scope.data.tags = result;
                });
        };

        $scope.loadUserGroups = function() {
            const request = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                sort_by: 'created_at',
                sort: 'desc',
                for_current_user: true
            };

            return userService.listUserGroups(request)
                .then(results => {
                    $scope.data.teams = ((results || {}).teams || []);
                });
        };

        $scope.loadBuckets = function() {
            const request = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
            };

            return boardsService.getBoards(request)
                .then(res => {
                    $scope.data.buckets = res;
                });
        };

        $scope.clearSearch = function() {
            $scope.data.search.term = '';
            if($scope.data.search.submitted) {
                $scope.data.search.submitted = false;

                if($scope.data.selectedBucket !== null) {
                    document.getElementById('bucket-search-input').blur();
                    document.getElementById('bucket-search').reset();
                    if(!$scope.loading.selectedBucket) { $rootScope.$broadcast('bucket.search.contacts', $scope.data.search.term); }
                }
            }
        };

        $scope.updateSearch = function() {
            $scope.data.search.term.trim();
            if($scope.data.search.term.length === 0) {
                $scope.clearSearch();
            }
        };

        $scope.submitSearch = function() {
            if(!$scope.data.search.term || $scope.data.search.term.length === 0) {
                return;
            }

            $scope.data.search.submitted = true;
            $rootScope.$broadcast('bucket.search.contacts', $scope.data.search.term);
        };

        $scope.createList = function() {

            if (!$scope.data.valid) {
                swal("Error", $scope.staticContent.errors.valid, "error");
                return;
            }

            $scope.loading.newList = true;

            const team_id = authService.getTeamId();
            const user_id = authService.getUserId();

            let request = {
                team_id: team_id,
                user_id: user_id,
                list_name: $scope.data.list.list_name,
                callable: true,
                compliance: true
            };

            contactService.createList(request)
                .then(results => {
                    $scope.data.lists.push(results);

                    let addList = {
                        list_id: results.list_id,
                        sort: 'newestFirst',
                        order: -1
                    };
                    let req = {
                        team_id: team_id,
                        user_id: user_id,
                        board_id: $scope.data.selectedBucket.board_id,
                        list: addList
                    };

                    boardsService.addListToBoard(req)
                        .then(() => {
                            addList.firstLoading = true;
                            $scope.data.selectedBucket.lists.push(addList);

                            $scope.data.selectedBucket.addList = null;
                            $scope.sortListsBySelection($scope.data.lists);

                            $scope.loading.newList = false;
                            $('#newListModal').modal('hide');
                            $scope.$apply();
                        });
                })
                .catch(err => {
                    $scope.loading.newList = false;
                    swal("Server Error", "Our server failed to respond. Please try again.", "error");
                })
                .then(() => {
                    $scope.$apply();
                });
        };

        $scope.contentBucketScrollEventListener = function(event) {
            $rootScope.$broadcast('close.all.ui.select.menu');
        };
        document.getElementById('scroll-bucket-content').addEventListener('scroll', $scope.contentBucketScrollEventListener);

        $scope.$on('$stateChangeStart', function() {
            document.getElementById('scroll-bucket-content').removeEventListener('scroll', $scope.contentBucketScrollEventListener);
        });

        $scope.isListVisibleForUser = function(bucketList) {
            return typeof $scope.data.lists.find(list => list.list_id === bucketList.list_id) !== 'undefined';
        };

        $scope.showCreateNewList = function() {
            angular.element('.dropify-clear').triggerHandler('click');
            $('#newListModal').modal('show');
        };

        $scope.onInit = function() {
            $scope.loading.buckets = true;
            let selectedBucketId = $window.localStorage['selected_bucket_id'];
            Promise.all([
                $scope.loadTags(),
                $scope.loadUserGroups(),
                $scope.loadBuckets()
            ])
            .then(() => {
                let initBucket = $scope.data.buckets[0] || null;
                let bucket = _.find($scope.data.buckets, function(b) { return b.board_id === selectedBucketId; });
                $scope.data.selectedBucket = (!selectedBucketId || selectedBucketId === null) ? initBucket : (bucket || initBucket);
                $scope._selectBucket();
            })
            .then(()=> {
                $scope.loading.buckets = false;
                $scope.$apply();

                $scope.bucketsPopoverInitListeners();
                $scope.closePopoversEventListeners();
            })
            .then(()=> {
                const promises = [];
                for (let i = 0; i < $scope.data.selectedBucket.lists.length; i++) {
                    promises.push($scope.loadListById($scope.data.selectedBucket.lists[i].list_id))
                }

                return Promise.all(promises);
            })
            .catch(err => { console.log(err); })
            .finally(()=> {
                $scope.$apply();
                $scope.loadLists();
            });

            $scope.$watch('data.selectedBucket.lists', function(newVal) {
                $rootScope.$broadcast('bucket.lists.changed', newVal);
            });
        };

        if (authService.hasAccount()) {
            $scope.onInit();
        }
        else {
            $scope.$on('auth_complete', function(event, args) {
                $scope.onInit();
            });
        }
    }
    module.exports = BucketsController;
})();
