(function () {
    'use strict';
    
    function AuthService($rootScope, $state, $timeout, $location, $window, $cookies, accountService, userService, intercomService, authLocalService) {

        function setAuthToken(authToken) {
            // Store the auth token in local storage
            localStorage.setItem('auth_token', authToken.token);
            localStorage.setItem('auth_expiry', authToken.expiry);
            localStorage.setItem('auth_user_id', authToken.user_id);
            localStorage.setItem('auth_impersonated_by', authToken.impersonated_by);
            localStorage.setItem('auth_team_id', authToken.team_id);
        }

        function isAuthenticated() {
            // Check whether the current time is past the
            // access token's expiry time
            let expiry = localStorage.getItem('auth_expiry') || 0;
            return (Date.now() < expiry);
        }

        function getTeamId() {
            return $rootScope.team_id || localStorage.getItem('auth_team_id');
        }

        function getUserId() {
            return $rootScope.user_id || localStorage.getItem('auth_user_id');
        }

        function getUser() {
            if (!$rootScope.user && isAuthenticated()) {
                $rootScope.user = $rootScope.secureLS.get('user');
                $rootScope.team_id = getTeamId();
                $rootScope.user_id = getUserId();
            }
            return $rootScope.user;
        }

        function setAblyChannel() {

            const team = getUser().team;
            const userId = getUser().user_id;
            $rootScope.ably.teamChannel = $rootScope.ably.client.channels.get('t_' + team.team_id + '_' + team.ably_secret);
            $rootScope.ably.teamChannel.subscribe($rootScope.ably.onEvent);
            $rootScope.ably.userChannel = $rootScope.ably.client.channels.get('u_' + userId + '_' + team.ably_secret);
            $rootScope.ably.userChannel.subscribe($rootScope.ably.onEvent);

            // TODO: Subscribe to all group channels that the user belongs to (once we have user groups)
        }

        function hasAccount() {
            return $rootScope.authenticated;
        }

        function handleSuccesfulSigin(authInfo) {

            setAuthToken(authInfo);
            if (isAuthenticated()) {

                $rootScope.authenticated = true;
                $rootScope.team_id = authInfo.team_id;
                $rootScope.user_id = authInfo.user_id;
                $rootScope.user = authInfo.user;
                $rootScope.secureLS.set('user', authInfo.user);
                setAblyChannel();
                $rootScope.loadNumbers();

                if (typeof authInfo.team.trial_until === 'number' && authInfo.team.trial_until > 0) {
                    $rootScope.checkTrial(authInfo.team.trial_until, authInfo.server_time_now);
                }

                // Redirect to the original path
                let initialState = (authInfo.redirectPath || $rootScope.initialState || '').toLowerCase();

                if (initialState === 'empty-wrapper.signup' || initialState === 'signup' ||
                    initialState === 'empty-wrapper.verify' || initialState === 'verify') {
                    $state.go('main-wrapper.my-dashboard');
                } else if (initialState === 'empty-wrapper.signin' || initialState === 'signin') {
                    $state.go('main-wrapper.my-dashboard');
                }
                else if (initialState === 'empty-wrapper.passwordreset' || initialState === 'passwordreset') {
                    $state.go('main-wrapper.my-dashboard');
                }
                else if (initialState === 'empty-wrapper.admin' || initialState === 'admin') {
                    $state.go('main-wrapper.my-dashboard');
                }
                else if (initialState === 'checklist') {
                    $state.go('main-wrapper.checklist');
                }
                else {
                    $window.location.hash = '#' + ($rootScope.initialPath || '/checklist');
                }

                $rootScope.initialPath = '/checklist';
                $rootScope.initialState = $rootScope.initialPath.substr(1);

                intercomService.startIntercom(getUser());

                $rootScope.$broadcast('auth_complete');
            }
            else {
                swal(
                    'Unable to Sign In!',
                    'Please double check your username and password.',
                    'error'
                );
            }
        }

        function login(request) {

            return new Promise((resolve, reject) => {

                accountService.signIn(request)
                    .then(results => {
                        return handleSuccesfulSigin(results);
                    })
                    .then(results => resolve())
                    .catch((err) => reject(err));
            });
        }

        function handleAuthentication() {

            // If the user isn't authenticated, redirect them to the login page
            if(!isAuthenticated()) {

                let initialState = ($rootScope.initialState || '').toLowerCase();

                if (initialState === 'empty-wrapper.signup' || initialState === 'signup') {
                    $state.go('empty-wrapper.signup');
                } else {
                    $state.go('empty-wrapper.signin');
                }

                return false;
            }
            getUser();
            setAblyChannel();

            intercomService.startIntercom(getUser());

            return true;
        }

        function setUserActivity() {
            var teamId = getTeamId();
            var userId = getUserId();

            if (teamId && userId && $rootScope.authenticated) {
                return userService.setUserActivity({ team_id: teamId, user_id: userId });
            }

            return null;
        }

        function logout(redirectToSignIn = true) {
            $('.preloader').fadeIn();

            $rootScope.resetUserAuthVars();

            Promise.all([
                setUserActivity(),
                accountService.signOut(getTeamId(), getUserId())
            ]).then(() => {
                authLocalService.logoutClear(()=> {
                    if (redirectToSignIn) {
                        $state.go('empty-wrapper.signin');
                    }
                });
            });
        }

        function logoutOrlocalLogout(redirectToSignIn = true) {
            ($rootScope.authenticated) ? logout(redirectToSignIn) : authLocalService.localLogout(redirectToSignIn);
        }

        function getUserNumbers(withLocation) {

            let numbers = {};

            if (!$rootScope.user) {
                $rootScope.user = $rootScope.secureLS.get('user');
            }

            if($rootScope.user.office_phone) {
                numbers['Office'] = $rootScope.user.office_phone;
            }

            if($rootScope.user.mobile_phone) {
                numbers['Mobile'] = $rootScope.user.mobile_phone;
            }

            if($rootScope.user.home_phone) {
                numbers['Home'] = $rootScope.user.home_phone;
            }

            return withLocation ? numbers : Object.values(numbers);
        }

        function setUserDefaultLocation(device) {

            let request = {
                team_id: getTeamId(),
                user_id: getUserId(),
                routing_location: device,
                updated_user_id: getUserId()
            };

            userService.updateUser(request)
                .then(() => {
                    $rootScope.user.routing_location = device;
                    $rootScope.secureLS.set('user', $rootScope.user);
                    $rootScope.$broadcast('deviceChanged', device);
                })
                .catch(err => {
                    console.error(err);
                });
        }

        function getUserDefaultLocation() {

            const numberFields = {
                office: 'office_phone',
                mobile: 'mobile_phone',
                home: 'home_phone',
                computer: 'computer',
            };

            // we use cookie here in case if user don't set option yet
            const defaultLocation = $rootScope.user.routing_location;

            if (defaultLocation && ($rootScope.user[numberFields[defaultLocation.toLowerCase()]] || defaultLocation.toLowerCase() === numberFields.computer)) {
                return defaultLocation;
            }

            if($rootScope.user.office_phone) {
                return 'Office';
            }

            if($rootScope.user.mobile_phone) {
                return 'Mobile';
            }

            if($rootScope.user.home_phone) {
                return 'Home';
            }

            return '';
        }

        function setDoNotDisturbEnabled(doNotDisturb) {
            let request = {
                team_id: getTeamId(),
                user_id: getUserId(),
                do_not_disturb: doNotDisturb,
                updated_user_id: getUserId()
            };

            userService.updateUser(request)
                .then(result => {
                    $rootScope.user.do_not_disturb = doNotDisturb;
                })
                .catch(err => {
                    console.error(err);
                });
        }

        function getDoNotDisturbEnabled(callback) {
            const doNotDisturbEnabled = $rootScope.user.do_not_disturb;
            callback(doNotDisturbEnabled);
        }

        function getMembershipLevel() {
            const user = getUser();
            return user.plan_id;
        }

        function hasPermission(entity, action) {
            const permissions = ((getUser() || {}).role || {}).permissions;
            if (!action) { action = 'can_view'; }
            return !!(permissions && permissions[entity] && permissions[entity][action]);
        }

        function checkPermission(entity, action) {
            const permission = hasPermission(entity, action);
            if (!permission) {
                swal('Not authorized', 'Your are not authorized to perform this action', 'error');
            }
            return permission;
        }

        $rootScope.$on('$stateChangeStart',
            function(event, toState, toParams, fromState, fromParams, options) {

                if ( toState.authenticate && !isAuthenticated() ) {
                    event.preventDefault();
                    //swal('Log In', 'Please log in to view this page', 'error');

                    logoutOrlocalLogout();
                    return;
                }

                if ( toState.rolePermission && !hasPermission(toState.rolePermission, toState.roleAction) ) {
                    swal('Not authorized', 'Your are not authorized to view this page', 'error');
                    event.preventDefault();
                    return;
                }
            }
        );

        return {
            handleAuthentication: handleAuthentication,
            handleSuccesfulSigin: handleSuccesfulSigin,
            logout: logout,
            logoutOrlocalLogout: logoutOrlocalLogout,
            login: login,
            getTeamId: getTeamId,
            getUserId: getUserId,
            hasAccount: hasAccount,
            isAuthenticated: isAuthenticated,
            getUser: getUser,
            getMembershipLevel: getMembershipLevel,
            getUserNumbers: getUserNumbers,
            setUserDefaultLocation: setUserDefaultLocation,
            getUserDefaultLocation: getUserDefaultLocation,
            hasPermission: hasPermission,
            checkPermission: checkPermission,
            setDoNotDisturbEnabled: setDoNotDisturbEnabled,
            getDoNotDisturbEnabled: getDoNotDisturbEnabled
        };
    }
    module.exports = AuthService;
})();
