(function () {

    'use strict';
    function TasksService (authService, apiService, $rootScope) {

        return {
            getTasks: function (request) {
                const req = Object.assign({}, {
                    team_id: authService.getTeamId(),
                    user_id: authService.getUserId(),
                }, request);
                return apiService.get($rootScope.settings.API_TASK_URL + `/tasks/withPreview`, req);
            },
            getUnreadTasksCount: function (request) {
                const req = Object.assign({}, {
                    team_id: authService.getTeamId(),
                    user_id: authService.getUserId(),
                }, request);
                return apiService.get($rootScope.settings.API_TASK_URL + `/tasks/unread/count`, req);
            },
            hasUnreadInCategory: function (request) {
                const req = Object.assign({}, {
                    team_id: authService.getTeamId(),
                    user_id: authService.getUserId(),
                }, request);
                return apiService.get($rootScope.settings.API_TASK_URL + `/tasks/has-unread-in-category`, req);
            },
            getTasksCounts: function (request) {
                const req = Object.assign({}, {
                    team_id: authService.getTeamId(),
                    user_id: authService.getUserId(),
                }, request);
                return apiService.get($rootScope.settings.API_TASK_URL + `/tasks/counts`, req);
            },
            getTask: function (request) {
                const req = Object.assign({}, {
                    team_id: authService.getTeamId(),
                    user_id: authService.getUserId(),
                }, request);
                return apiService.get($rootScope.settings.API_TASK_URL + `/tasks/single`, req);
            },
            getTaskTimeline: function (request) {
                const req = Object.assign({}, {
                    team_id: authService.getTeamId(),
                    user_id: authService.getUserId(),
                }, request);
                return apiService.get($rootScope.settings.API_TASK_URL + `/tasks/${request.task_id}/timeline`, req);
            },
            getTaskTimelineItem: function (request) {
                const req = Object.assign({}, {
                    team_id: authService.getTeamId(),
                    user_id: authService.getUserId(),
                }, request);
                return apiService.get($rootScope.settings.API_TASK_URL + `/tasks/${request.task_id}/timeline/${request.entry_id}`, req);
            },
            getTaskAttachmentUrl: function (req) {
                return apiService.get($rootScope.settings.API_TASK_URL + `/tasks/${req.task_id}/entry/${req.entry_id}/attachment/${req.attachment_id}/url`, req);
            },
            updateTask: function (request) {
                const req = Object.assign({}, request, {
                    team_id: authService.getTeamId(),
                    user_id: authService.getUserId(),
                });
                return apiService.put($rootScope.settings.API_TASK_URL + `/tasks/${request.task_id}`, req);
            },
            updateTaskStatus: function (request) {
                const req = Object.assign({}, request, {
                    team_id: authService.getTeamId(),
                    user_id: authService.getUserId(),
                });
                return apiService.put($rootScope.settings.API_TASK_URL + `/tasks/${request.task_id}/status`, req);
            },
            updateTasksStatus: function (request) {
                const req = Object.assign({}, request, {
                    team_id: authService.getTeamId(),
                    user_id: authService.getUserId(),
                });
                return apiService.put($rootScope.settings.API_TASK_URL + `/tasks/status/bulk`, req);
            },
            updateTaskUrgentStatus: function (request) {
                const req = Object.assign({}, request, {
                    team_id: authService.getTeamId(),
                    user_id: authService.getUserId(),
                });
                return apiService.put($rootScope.settings.API_TASK_URL + `/tasks/${request.task_id}/urgent`, req);
            },
            pinUnpinNote: function (request) {
                const req = Object.assign({}, request, {
                    team_id: authService.getTeamId(),
                    user_id: authService.getUserId(),
                });
                return apiService.put($rootScope.settings.API_TASK_URL + `/tasks/${request.task_id}/note/${request.note_id}/${request.action}`, req);
            },
            deleteNote: function (request) {
                const req = Object.assign({}, request, {
                    team_id: authService.getTeamId(),
                    user_id: authService.getUserId(),
                });
                return apiService.del($rootScope.settings.API_TASK_URL + `/tasks/${request.task_id}/note/${request.note_id}`, req);
            }
        }
    }

    module.exports = TasksService;
})();




