(function() {
    'use strict';

    function TrialEndedModalController($scope, $location) {

        $scope.staticData = {
            title: 'Your Trial Ended',
            secondTitle: 'Keep Your',
            secondTitle2: 'Subscription Going!',
            titleDescription: 'Everything you need to boost sales and communicate with your customers - Subscribe Now!',
            bodyTitle: 'Headline',
            bodyText: "Everything you need to continue growing your business and engage with your customers.",
            buttons: [
                { text: 'Downgrade to free forever plan' },
                { text: 'Subscribe Now' },
            ],
        };

        $scope.subscribeFreeForever = function() {
            $('#trial-ended-modal').modal('hide');
        }

        $scope.subscribeNow = function() {
            $('#trial-ended-modal').modal('hide');
            setTimeout(() => $location.url( "/subscriptions?pending=true&trial_over=true" ));
        }
    }

    module.exports = TrialEndedModalController;
})();
