(function() {
    'use strict';

    function AdminController($rootScope, $scope, authService, adminService, accountService) {

        $rootScope.viewData = $rootScope.viewData || {};
        $rootScope.viewData.breadcrumbs = $rootScope.viewData.breadcrumbs || [];
        $rootScope.viewData.breadcrumbs = [{
            title: 'Admin',
            link_name: 'Admin',
            link_url: '#/admin',
            help_url: $rootScope.help_url
        }];

        $scope.data = {
            impersonate_user_id: "",
            updated_team_id: "",
            team: null,
        };

        $scope.loading = {
            impersonate: false,
            freeze: false,
            team: false,
        };

        $scope.impersonate = function () {

            $scope.loading.impersonate = true;

            const impersonateReq = {
                impersonate_user_id: $scope.data.impersonate_user_id,
                user_id: authService.getUserId(),
                team_id: authService.getTeamId()
            };

            adminService.impersonate(impersonateReq)
                .then(results => {
                    $scope.loading.impersonate = false;
                    return authService.handleSuccesfulSigin(results);
                })
                .catch(err => {
                    swal('Failed to impersonate', 'Make sure you don\'t have a typo in the user_id', 'error');
                    $scope.loading.freeze = false;
                    $scope.$apply();
                });
        };

        $scope.freeze = function () {

            $scope.loading.freeze = true;

            const freezeRequest = {
                user_id: authService.getUserId(),
                team_id: authService.getTeamId(),
                updated_team_id: $scope.data.updated_team_id,
                freeze: true
            };

            adminService.updateTeam(freezeRequest)
                .then(results => {
                    swal("Account freezed", 'Successfylly freezed account ' + $scope.data.updated_team_id, "success");
                    $scope.data.updated_team_id = "";
                    $scope.loading.freeze = false;
                    $scope.$apply();
                })
                .catch(err => {
                    swal('Failed to freeze account', 'Make sure you don\'t have a typo in the team_id', 'error');
                    $scope.loading.freeze = false;
                    $scope.$apply();
                });
        };
    }
    module.exports = AdminController;
})();
