(function () {

    'use strict';

    function CreateCampaignController(authService, $rootScope, $scope, $window, $interval, campaignService, contactService, $location, voiceService, mediaService, phoneService, accountService, longJobService, generalHelperService) {
        const vm = this;
        vm.authService = authService;

        $rootScope.viewData = $rootScope.viewData || {};
        $rootScope.viewData.breadcrumbs = $rootScope.viewData.breadcrumbs || [];
        $rootScope.viewData.breadcrumbs = [{
            title: 'RVM Campaigns',
            link_name: 'RVM Campaigns',
            link_url: '#/bulk-campaigns',
            help_url: $rootScope.help_url
        }, {
            title: 'Campaign',
            link_name: 'Campaign',
            link_url: '#/voicemail-campaign',
            help_url: $rootScope.help_url
        }];

        $scope.audioSource = 'recording'; // 'recording' | 'voice-clone'
        $scope.existing_campaign = null;

        $scope.staticData = {
            balanceInvalidMessage: 'Please fund your account before creating a campaign.',
            notVerifiedMessage: 'Please verify your identity before creating a campaign.',
            noOptInMessage: 'You have elected to no client opt-in during registration. This prevents you from using our RVM features until you register a brand with a client opt-in.',
            optinBlock: {
                title: 'Trusted Brand Registration:',
                list: [
                    "All service providers, including " + $rootScope.settings.APP_NAME + ", are now required to gather additional details about your brand prior to transmitting your campaign to consumer phones.  Please register your brand in the trust center. "
                ]
            },
            infoBlock: {
                title: 'Attention new customers:',
                list: [
                    "Phone carriers use spam filtering technology that analyses the content of your message, among other things, and may block your voicemail from reaching your contact.  In these cases, your contact will most likely receive a missed call notification but no voicemail.",
                    "It's common to have people call back immediately and ask 'I missed a call from this number' without listening to your message. We recommend being prepared ahead of time with a response to these types of calls so you're not thrown off guard. If you don't plan on answering calls, we recommend setting up call routing to play your voicemail when a contact returns your missed call. This way the contact will hear your message when they return your missed call."
                ]
            },
            name: {
                title: 'Campaign Name',
                subtitle: 'This will appear in your reports',
                inputPlaceholder: 'My Campaign Name'
            },
            brandList: {
                title: 'Trusted Brand',
                enforceDate: '(Please register your brand in the trust center)',
                subtitle: 'The trusted brand you are sending from',
                createBtn: {
                    text: 'Register a Trusted Brand',
                    href: '#/trust-center'
                },
                dropDownBtn: 'Please choose...',
                loader: 'Trusted Brands are Loading'
            },
            contactList: {
                title: 'Contact List',
                subtitle: 'The list of contacts to send your message to',
                createBtn: {
                    text: 'Create a Contact List',
                    href: '#/lists'
                },
                dropDownBtn: 'Please choose...',
                loader: 'Contact Lists is Loading'
            },
            audioSource: {
                title: "Audio Source",
                subtitle: "Audio Source for the voicemail",
                audioSourceRadio1: {
                    title: "Pre-Recorded Audio",
                    subtitle: "The recording to leave on your contact's voicemail"
                },
                audioSourceRadio2: {
                    title: "Personalized Voicemail Messages using Mimic AI™ Voice Cloning",
                    subtitle: "Effortlessly send personalized voicemails to each of your contacts using our Mimic AI™ to replicate your own voice. Our system seamlessly incorporates dynamic word insertion from uploaded data columns, ensuring each message is uniquely tailored to your recipients and Mimic AI™ ensures the audio sounds just like you!",
                    badge: "New"
                }
            },
            recordingsList: {
                createBtn: {
                    text: 'Create a Recording',
                    href: '#/recordings'
                },
                dropDownBtn: 'Please choose...',
                loader: 'Recording List is Loading'
            },
            voicesList: {
                createBtn: {
                    text: 'Create a Voice',
                    href: '#/voices'
                },
                dropDownBtn: 'Please choose...',
                loader: 'Voice List is Loading'
            },
            ttsEditor: {
                desc: "Type the text that you want Mimic AI™ to read to your contacts using your voice. Some US states require you to disclose that you're using an AI generated message.",
                personaBtn: 'Personalization',
                placeholder: "Compose your text to speech message here.  Use the 'Personalization' menu to incorporate dynamic word insertion from uploaded data columns. For example: Hello {{first_name}}, this is...",
            },
            callRouting: {
                title: 'Caller ID / Return Call Handling',
                subtitle: "Select a call route to use for handling return calls and texts. Your message will be sent from one of the route's phone numbers with the closest geographic proximity to your contact.",
                createBtn: {
                    text: 'Create Call Route',
                    href: '#/phone-numbers'
                },
                options: [
                    {
                        title: 'Call Route',
                        options: [
                            {
                                title: 'Return Call Routing',
                                placeholder: 'Please Select'
                            }
                        ]
                    }
                ]
            },
            voiceIvr: {
                title: 'Voice Calls',
                subtitle: 'Where should we send calls and texts that you receive in response to your campaign',
                dropDownBtn: 'Please choose...',
                loader: 'IVRs are Loading',
                viewBtns: {
                    text: 'Configure an IVR',
                    href: '#/phone-IVRs'
                },
                defaultIVR: 'Default IVR',
            },
            smsIvr: {
                title: 'Text Messages',
                subtitle: 'Text messages will be routed to the SMS Autoresponder specified',
                dropDownBtn: 'Please choose...',
                loader: 'SMS Autoresponders are Loading',
                viewBtns: {
                    text: 'Configure an SMS Autoresponder',
                    href: '#/sms-autoresponders'
                },
                defaultIVR: 'Default Autoresponder'
            },
            scheduleDelivery: {
                title: 'Schedule Delivery',
                subtitle: 'Tell us when and how fast to deliver your messages',
                deliverNow: 'Deliver my messages immediately',
                advanced: 'Advanced delivery options',
                dripdeliveryTab: {
                    tabBtn: 'Drip Delivery',
                    title: 'Drip Schedule',
                    subtitle: 'We will deliver your messages at a rate that you specify',
                    drip: {
                        title: 'Delivery Speed',
                        subtitle: '( as slow as 60 or as fast as ' + $rootScope.limits.rvm + ' messages per hour )'
                    },
                    deliveryWindow: {
                        title: 'Delivery Window',
                        subtitle: 'We will deliver your messages between the hours you specify',
                        messageText1: 'Starting from',
                        messageText2: 'to',
                        startTimeText: 'start time',
                        startDateText: 'start date',
                        durationText: 'duration ( hrs. )',
                        checkboxText: 'include weekends'
                    },
                    timezone: {
                        title: 'Timezone',
                        subtitle: 'The timezone for your delivery window'
                    }
                },
                scheduledeliveryTab: {
                    tabBtn: 'Schedule Delivery',
                    title: 'Scheduled Date',
                    subtitle: 'We will deliver your voicemails on the date and time you specify',
                    dateText: 'Date',
                    timezoneText: 'Timezone'
                },
                deliveritnow: {
                    tabBtn: 'Deliver it now',
                    message: 'Your campaign will be delivered to your entire list immediately. Please consider drip delivery if you need the ability to slow down or speed up the campaign.'
                }
            },
            termsOfUse: {
                title: 'Terms of Use',
                subtitle: 'We need your confirmation. Please check the boxes to confirm',
                complianceCheckboxA: 'The content of my message and use of this service are compliant with all applicable rules, regulations, and laws.',
                complianceCheckboxC: 'I am sending this message in compliance with all applicable rules, regulations, and laws, including the Telephone Consumer Protection Act and the Telemarketing Sales Rule.',
                complianceCheckboxB1: 'I accept the',
                complianceCheckboxBLink: {
                    href: APP_TERMS_LINK,
                    text: 'Terms of Use'
                },
                complianceCheckboxB2: 'governing this service.',
                complianceCheckboxV: 'I have legal rights to use the voice I selected for this campaign.',
                complianceCheckboxV1: 'Mimic AI™ will debit my account balance for every character of text-to-speech, per delivery attempt, at the current TTS rates (character counts will vary based on any dynamic field data for each contact).'
            },
            actions: {
                createBtn: 'Send Campaign',
                updateBtn: 'Update Campaign'
            },
            loader: {
                updating: 'Updating',
                creating: 'Creating',
                loading: 'Loading'
            },
            modal: {
                title: "Preview AI Voice",
            }
        };

        $scope.loading = {
            contacts: true,
            recordings: true,
            voices: true,
            campaign: false,
            ivrs: true,
            all: true,
            preview: false
        };

        $scope.saving = {
            campaign: false
        };

        $scope.updating = {
            campaign: false
        };

        $scope.iti = undefined;

        $scope.isCampaignsListEmptyBool = undefined;
        $scope.isFormSubmitted = false;

        $scope.data = {
            opt_in_date: 1673596800000,
            opt_in_required: true,
            show_brand_selection: true,
            show_mobile_landine_select: false,
            isValidBalance: false,
            noOptIn: false,
            default_timezone: moment.tz.guess() || 'America/New_York',
            campaign_id: null,
            campaign: {},
            contact_lists: [],
            recordings: [],
            voices: [],
            proVoices: [],
            selected_number: null,
            selected_list: null,
            brand_selected: null,
            recording_selected: null,
            voice_selected: null,
            isAllowNumberPurchase: false,
            picker: {
                selected_date: null,
                min_date: moment(),
                max_date: moment().add('3', 'months'),
                timezone: ''
            },
            delivery_window: {
                start_time: new Date(1970, 0, 1, 9, 0),
                duration: 8,
                durationArray: null,
                include_weekend: undefined,
                range_text: "every day"
            },
            short_codes: [],
            tts_job_id: null,
            errors: {
                fields: {
                    campaign_name: undefined,
                    list_valid: undefined,
                    recording_valid: undefined,
                    voice_valid: undefined,
                    tts_editor_valid: undefined,
                    sentences_valid: undefined,
                    sentence_length_valid: undefined,
                    brand_valid: undefined,
                    compliance: undefined,
                    call_routing: undefined
                },
                messages: {
                    required: "Field is required.",
                    sentences: "Must have less than 7 sentences",
                    sentence_length: "Must have less than 500 characters per sentence.",
                    termsOfUseRequired: "Terms of Use are required.",
                    phone_number: {
                        display: "Field is required.",
                        default: "Field is required.",
                        error_codes: [
                            {
                                code: 0,
                                name: "IS_POSSIBLE",
                                message: "Phone number is not valid."
                            },
                            {
                                code: 1,
                                name: "INVALID_COUNTRY_CODE",
                                message: "Phone number is not valid."
                            },
                            {
                                code: 2,
                                name: "TOO_SHORT",
                                message: "Phone number is not valid."
                            },
                            {
                                code: 3,
                                name: "TOO_LONG",
                                message: "Phone number is not valid."
                            },
                            {
                                code: 4,
                                name: "NOT_A_NUMBER",
                                message: "Phone number is not valid."
                            }
                        ]
                    }
                }
            }
        };

        $scope.fieldsValid = false;

        $scope.quill = new Quill('#editor-container', {
            modules: {
              syntax: true,
              toolbar: '#toolbar-container'
            },
            placeholder: $scope.staticData.ttsEditor.placeholder,
            theme: 'snow'
        });

        $scope.voiceIVRdropdownTitle = function() {
            const campaign = $scope.data.campaign || {};
            return _.find($scope.data.ivrs, function(o) { return campaign.call_routing.voice_ivr_id === o.ivr_id; });
        };

        $scope.voiceIVRdropdownPhoneCount = function() {
            const campaign = $scope.data.campaign || {};
            let selectedIvr = _.find($scope.data.ivrs, function(o) { return campaign.call_routing.voice_ivr_id === o.ivr_id; });
            return (((selectedIvr || {}).purchased_numbers || []).length || 0);
        };

        $scope.voiceIVRValidation = function() {
            const campaign = $scope.data.campaign || {};
            let selectedIvr = _.find($scope.data.ivrs, function(o) { return campaign.call_routing.voice_ivr_id === o.ivr_id; });
            return (((selectedIvr || {}).purchased_numbers || []).length > 0);
        };

        $scope.setVoiceIVR = function(voice) {
            $scope.dispatcher.ivr.voice = voice.ivr_id;
        };

        $scope.validateFields = function() {

            const campaign = $scope.data.campaign || {};
            const compliance = campaign.compliance || {};
            const listValid = (campaign.list_ids && campaign.list_ids.length);
            const recordingValid = (campaign.media_id && campaign.media_id.length);
            const voiceValid = (campaign.voice_id && campaign.voice_id.length);
            const brandRequired = !$scope.data.campaign_id && $scope.data.show_brand_selection && ($scope.data.opt_in_required || ($scope.data.brands || []).length);
            const brandValid = !brandRequired || campaign.brand_id;
            const voiceIVRValid = (($scope.data.selected_voice_ivr || {}).purchased_numbers || []).length;
            const smsIVRValid = (($scope.data.selected_sms_ivr || {}).purchased_numbers || []).length;
            const callRoutingValid = ((campaign.call_routing.sms_ivr_id && smsIVRValid) || (campaign.call_routing.voice_ivr_id && voiceIVRValid));

            var editorText = (($scope.getStringFromEditor() || '').trim()).substr(0, 3000);

            if ($scope.data.campaign && !$scope.existing_campaign) {
                $scope.data.campaign.tts_body = editorText;
            }

            $scope.data.messageLength = Math.max(0, editorText.length);
            var sentences = $scope.__splitSentences(editorText || '');
            var maxSentenceLength = 0;
            for (let i = 0; i < sentences.length; i++) {
                let sentence = (sentences[i] || '');
                maxSentenceLength = Math.max(maxSentenceLength, sentence.length);
            }

            $scope.data.sentenceCount = sentences.length;
            $scope.data.maxSentenceLength = maxSentenceLength;
            var sentencesValid = (sentences.length <= 6);
            var sentenceLengthValid = (maxSentenceLength < 350);

            var editorValid = ($scope.data.messageLength > 0);

            const balance = Number($rootScope.total_funds || 0);
            $scope.data.isValidBalance = (balance > 0);

            if ($scope.isFormSubmitted && !$scope.fieldsValid) {
                $scope._validateFields(campaign.name, compliance, listValid, recordingValid, voiceValid, editorValid, brandValid, callRoutingValid, sentencesValid, sentenceLengthValid);
            }

            const isDefaultValid = (
                campaign.name
                && listValid
                && callRoutingValid
                && brandValid
                && compliance.tcpa_tsr
                && compliance.rules_regs_laws
                && compliance.terms_of_service
                && $scope.data.isValidBalance
            );
            var isSourceValid = $scope.audioSource === 'voice-clone' ? (voiceValid && editorValid && sentencesValid && sentenceLengthValid && !!(compliance.legal_voice && compliance.voice_clone_price)) : recordingValid;
            $scope.fieldsValid = !!(isDefaultValid && isSourceValid);
        };

        $scope.__splitSentences = function(input) {

            var sentences = [];

            // Clean up the input
            var textInput = input
               .replace(/[\r\n]+/g, ' ')
               .replace(/\s+/g, ' ')
               .replace(/[“”]/g, '"')
               .replace(/…/g, '.');

            var punctuationRegex = /[.!?:…\r\n]/m;

            var punctuationMatch = textInput.match(punctuationRegex);
            while (punctuationMatch && punctuationMatch.index !== undefined) {
                var sentence= textInput.substring(0, punctuationMatch.index + 1);
                textInput = textInput.substring(punctuationMatch.index + 1);

                var trimmedSentence = sentence.trim();
                if (!(trimmedSentence.length === 0 || (trimmedSentence.length === 1 && punctuationRegex.test(trimmedSentence[0] || '')))) {
                    sentences.push(trimmedSentence);
                }

                punctuationMatch = textInput.match(punctuationRegex);
            }

            if (textInput) {
                const trimmedSentence = textInput.trim();
                if (!(trimmedSentence.length === 0 || (trimmedSentence.length === 1 && punctuationRegex.test(trimmedSentence[0] || '')))) {
                    sentences.push(trimmedSentence);
                }
            }

            return sentences;
        }

        $scope._validateFields = function(campaignName, compliance, listValid, recordingValid, voiceValid, editorValid, brandValid, callRoutingValid, sentencesValid, sentenceLengthValid) {
            $scope.data.errors.fields.campaign_name = !!(campaignName);
            $scope.data.errors.fields.compliance = !!(compliance.tcpa_tsr && compliance.rules_regs_laws && compliance.terms_of_service);
            if ($scope.audioSource === 'voice-clone') {
                $scope.data.errors.fields.compliance = $scope.data.errors.fields.compliance && !!(compliance.legal_voice && compliance.voice_clone_price);
            }
            $scope.data.errors.fields.list_valid = !!(listValid);
            $scope.data.errors.fields.recording_valid = !!(recordingValid);
            $scope.data.errors.fields.voice_valid = !!(voiceValid);
            $scope.data.errors.fields.tts_editor_valid = !!(editorValid);
            $scope.data.errors.fields.brand_valid = !!(brandValid);
            $scope.data.errors.fields.call_routing = !!(callRoutingValid);
            $scope.data.errors.fields.sentences_valid = !!(sentencesValid);
            $scope.data.errors.fields.sentence_length_valid = !!(sentenceLengthValid);
        };

        $scope.validateFormAfterSubmit = function() {
            _.forEach($scope.data.errors.fields, function(value, key) {
                if(!value && document.getElementById(key)) {
                    document.getElementById(key).scrollIntoView({block: "center", behavior: "smooth"});
                    return false;
                }
            });
        };

        $scope.loadBrands = function() {

            let req = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId()
            };

            accountService.getBrands(req)
                .then(results => {
                    let brands = results || [];
                    $scope.data.brands = brands;
                    $scope.loading.brands = false;
                    $scope.data.noOptIn = _.filter(brands, function(brand) { return _.includes(brand.opt_in_flow, 'I don\'t have opt-in from my contacts'); }).length > 0;
                    $scope.data.registeredBrandCount = 0;

                    for (let i = 0; i < brands.length; i++) {
                        let brand = brands[i];
                        let score = brand.trust_score || 0;
                        let status = brand.identity_status;
                        if (brand.registered && (score > 0 || status === 'VERIFIED' || status === 'VETTED_VERIFIED' || status === 'SELF_DECLARED')) {
                            brand.verified = true;
                        }

                        if($scope.data.campaign.brand_id === brand.brand_id) {
                            $scope.setBrand(brand);
                        }

                        $scope.data.registeredBrandCount++;
                    }

                    $scope.$apply();
                })
                .catch(err => {
                    console.log(err);
                });
        }

        $scope.setBrand = function(value) {

            if (value) {
                $scope.data.campaign = $scope.data.campaign || {};
                $scope.data.brand_selected = value;
                $scope.data.campaign.brand_id = value.brand_id;
            }
            $scope.validateFields();
        };

        $scope.loadContactLists = function() {
            $scope.loading.contacts = true;

            const request = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId()
            };

            contactService.getLists(request)
                .then(results => {
                    const lists = results || [];
                    $scope.data.contact_lists = lists;

                    if ($scope.data.campaign.list_ids) {
                        const index = _.findIndex($scope.data.contact_lists, ['list_id', $scope.data.campaign.list_ids[0]]);
                        if (index > -1) {
                            $scope.setContactList($scope.data.contact_lists[index]);
                        }
                    }

                    $scope.loading.contacts = false;
                    $scope._checkLoadingStatus();
                    $scope.$apply();
                })
                .catch(err => {
                    console.error(err);
                    $scope.loading.contacts = false;
                    $scope._checkLoadingStatus();
                    $scope.$apply();
                });
        };

        $scope.setContactList = function(value) {
            if (value) {
                $scope.data.selected_list = value;

                $scope.data.campaign = $scope.data.campaign || {};
                $scope.data.campaign.list_ids = [value.list_id];
            }
            $scope.validateFields();
        };

        $scope.setAudioSource = function(value) {
          $scope.audioSource = value;
        };

        $scope.loadRecordings = function() {
            $scope.loading.recordings = true;

            const request = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                include_urls: true
            };

            mediaService.listMediaUrls(request)
                .then(results => {
                    const lists = (_.sortBy(results.medias, 'created_at').reverse()) || [];
                    $scope.data.recordings = lists;

                    if($scope.data.campaign.media_id) {
                        const index = _.findIndex($scope.data.recordings, ['media_id', $scope.data.campaign.media_id]);
                        if(index > -1) {
                            $scope.setRecording($scope.data.recordings[index]);
                        }
                    }

                    $scope.loading.recordings = false;
                    $scope._checkLoadingStatus();
                    $scope.$apply();
                })
                .catch(err => {
                    console.error(err);
                    $scope.loading.recordings = false;
                    $scope._checkLoadingStatus();
                    $scope.$apply();
                });
        };

        $scope.setRecording = function(value) {
            if (value) {
                $scope.data.recording_selected = value;

                $scope.data.campaign = $scope.data.campaign || {};
                $scope.data.campaign.media_id = value.media_id;
            }
            $scope.validateFields();
        };
        
        $scope.loadVoices = function() {
            $scope.loading.voices = true;

            const request = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                include_urls: true,
                include_pro_voices: true,
                exclude_pending: true
            };

            voiceService.listVoiceUrls(request)
                .then(results => {
                    let allVoices = (results || {}).voices || [];
                    $scope.data.voices = _.sortBy(_.filter(allVoices, voice => !voice.pro_voice), 'created_at').reverse() || [];
                    $scope.data.proVoices = _.filter(allVoices, 'pro_voice') || [];

                    if($scope.data.campaign.voice_id) {

                        $scope.audioSource = 'voice-clone';
                        $scope.setEditorText($scope.data.campaign.tts_body || '');
                        $scope.quill.enable(false);

                        $scope.disable_voice_selection = true;

                        for (let i = 0; i < allVoices.length; i++) {
                            let voice = allVoices[i];
                            if (voice.voice_id === $scope.data.campaign.voice_id) {
                                $scope.setVoice(voice);
                                break;
                            }
                        }
                    }
                    
                    $scope.loading.voices = false;
                    $scope.$apply();
                })
                .catch(err => {
                    console.error(err);
                    $scope.loading.voices = false;
                    $scope.$apply();
                });
        };
        
        $scope.setVoice = function(value) {

            if (value) {
                $scope.data.campaign = $scope.data.campaign || {};
                $scope.data.voice_selected = value;
                $scope.data.campaign.voice_id = value.voice_id;
            }
            $scope.validateFields();
        };

        $scope.getStringFromEditor = function() {
            return $scope.quill.getText();
        };

        $scope.setEditorText = function(value, source) {
            $scope.quill.setText(value, source || 'api');
        };

        $scope.quill.on('text-change', function() {

            if ($scope.quill.getLength() > 3000) {
                $scope.quill.deleteText(3000, $scope.quill.getLength());
            }

            $scope.fieldsValid = false;
            $scope.$evalAsync();
            $scope.validateFields();
        });
        
        $scope.insertShortCodeToEditor = function(value) {
            const index = $scope.quill.getSelection(true).index;
            const text = `{{${value}}}`;
            $scope.quill.insertText(index, text);
        };

        $scope.generateTTSBody = function() {
            let tts_body = $scope.data.campaign.tts_body;
            $scope.data.short_codes.forEach(code => {
                tts_body = tts_body.replaceAll(`{{${code.value}}}`, code.title);
            })
            return tts_body;
        }
        
        $scope.previewVoice = function() {
            if($scope.data.campaign.voice_id && $scope.data.campaign.tts_body && !$scope.loading.preview) {
                $scope.loading.preview = true;
                
                let ttsBody = $scope.generateTTSBody();
                let request = {
                    team_id: authService.getTeamId(),
                    user_id: authService.getUserId(),
                    voice_id: $scope.data.campaign.voice_id,
                    tts_body: ttsBody
                };

                voiceService.textToSpeech(request)
                    .then(results => {
                        $scope.__waitForJobComplete(results, (job) => {

                            $scope.loading.preview = false;

                            if (job && job.audio_url) {
                                var audioElement = document.getElementById('player-preview');
                                audioElement.src = job.audio_url;
                                var textElement = document.getElementById('text-preview');
                                textElement.innerHTML = ttsBody;

                                $('#playPreviewVoiceModal').modal('show');
                            }
                            else {
                                swal("Cancelled", "We're sorry.  We're unable to preview your text-to-speech.", "error");
                            }

                            $scope.$apply();
                        });
                    })
                    .catch(err => {
                        console.log(err)
                        swal("Cancelled", "We're sorry.  We're unable to preview your text-to-speech.", "error");
                        $scope.loading.preview = false;
                        $scope.$apply();
                    });
            }
        };

        $scope.__waitForJobComplete = function(ttsJob, callback) {

            if (!ttsJob || !ttsJob.long_job_id) {
                swal("Cancelled", "We're sorry.  We're unable to preview your text-to-speech.", "error");
                callback();
                return;
            }

            $scope.data.poll_job_id = ttsJob.long_job_id;
            if ($scope.data.poll_job_id) {
                $scope.jobPolling = $interval(() => { $scope.__pollJob(callback); }, 3000);
            }
        };

        $scope.__pollJob = function(callback) {

            if (!$scope.job_polling) {

                $scope.job_polling = true;

                const request = {
                    team_id: authService.getTeamId(),
                    user_id: authService.getUserId(),
                    job_id: $scope.data.poll_job_id
                };

                longJobService.getJob(request)
                    .then(results => {
                        $scope.job_polling = false;

                        if (results && (results.failed_at || results.completed_at)) {

                            let resultData = (results || {}).result_data || {};
                            callback(resultData);

                            $interval.cancel($scope.jobPolling);
                        }
                    })
                    .catch(err => {
                        $scope.job_polling = false;
                    });
            }
        }

        $scope.closeModal = function() {
            $('#playPreviewVoiceModal').modal('hide');
        };

        $scope.calcDeliveryTimeRange = function(startDate) {
            // The start time is stored in the TZ local to the current user's system clock.
            // Convert the start time to the TZ specified by the user.
            const start = $scope.__tzDate(startDate, $scope.data.campaign.drip.timezone);
            const startIso = start.toISOString();
            const endIso = start.add($scope.data.delivery_window.duration, 'hours').toISOString();
            return { 'startIso': startIso, 'endIso': endIso }
        };

        $scope.setDeliverySchedule = function() {
            let timeRange = $scope.calcDeliveryTimeRange(
                ($scope.data.picker.selected_date === null) ? new Date(1970, 0, 1, 8, 0) : $scope.data.picker.selected_date);

            _.mapKeys($scope.data.campaign.drip, function(value, key) {
                if(key !== 'rate' && key !== 'timezone') {
                    $scope.data.campaign.drip[key] = {
                        enabled: true,
                        start_time: timeRange.startIso,
                        end_time: timeRange.endIso
                    };
                }
            });
        };

        $scope.setDeliveryWindow = function() {
            let timeRange = $scope.calcDeliveryTimeRange($scope.data.picker.selected_date);

            _.mapKeys($scope.data.campaign.drip, function(value, key) {
                if(key !== 'rate' && key !== 'timezone') {
                    $scope.data.campaign.drip[key].start_time = timeRange.startIso;
                    $scope.data.campaign.drip[key].end_time = timeRange.endIso;
                }
            });

            $scope.validateFields();
        };

        $scope.calcHoursToMidnight = function(startTime) {
            let midnight = moment(startTime).add(1, 'day').set({ hour: 0, minute: 0, second: 0 });
            return midnight.diff(startTime, 'hours');
        };

        $scope.deliveryDateTimeValidation = function() {
            let diff = $scope.calcHoursToMidnight(moment($scope.data.picker.selected_date));

            if(diff < $scope.data.delivery_window.duration) {
                $scope.data.delivery_window.duration = diff;
            }
            $scope.setDeliveryWindow();
        };

        $scope.checkDeliveryDefaultTime = function(callback) {
            let currentTime = moment();
            let defaultTime = moment(currentTime).set({ hour: 9, minute: 0, second: 0 });
            let initDateTime = (currentTime.isBefore(defaultTime, 'hours')) ? moment(defaultTime) : moment(defaultTime).add(1, 'day');

            $scope.data.picker.selected_date = ($scope.data.picker.selected_date === null) ? initDateTime : $scope.data.picker.selected_date;

            $scope.deliveryDateTimeValidation();
            callback();
        };

        $scope.isAvailableDuration = function(hours) {
            return ($scope.calcHoursToMidnight(moment($scope.data.picker.selected_date)) >= hours);
        };

        $scope.setDurationArray = function() {
            let durationRange = _.range(1, 25);
            $scope.data.delivery_window.durationArray = _.map(durationRange, function(hours) {
                return {
                    'duration': hours,
                    'isAvailable': $scope.isAvailableDuration(hours)
                };
            });
        };

        $scope.deliveryDatePickerInit = function() {
            generalHelperService.waitForElement('#delivery-window-date')
                .then((elem) => {
                    let daterangepickerOptions = {
                        singleDatePicker: true,
                        timePicker: true,
                        parentEl: '#date-input-div',
                        startDate: $scope.data.picker.selected_date,
                        locale: {
                            format: 'MMMM DD, YYYY, hh:mm A'
                        },
                        showDropdowns: true,
                        autoApply: false
                    };

                    if(!$scope.data.campaign_id && $scope.data.campaign.status !== 'complete') {
                        daterangepickerOptions.minDate = $scope.data.picker.min_date;
                        daterangepickerOptions.maxDate = $scope.data.picker.max_date;
                    }

                    $(elem).daterangepicker(daterangepickerOptions, function(start, end, label) {
                        $scope.data.picker.selected_date = moment(start);

                        $scope.deliveryDateTimeValidation();
                        $scope.setDurationArray();

                        $scope.$apply();
                    });
                });
        };

        $scope.setDeliveryMethod = function(value) {
            $scope.data.campaign = $scope.data.campaign || {};
            $scope.data.campaign.method = value;

            switch(value) {
                case 'drip':
                    if ($scope.data.campaign.drip.rate === undefined || $scope.data.campaign.drip.rate === null) {
                        $scope.data.campaign.drip.rate = 60;
                        $scope.data.campaign.drip.timezone = $scope.data.default_timezone;
                    }

                    $scope.checkDeliveryDefaultTime(()=> {
                        $scope.setDurationArray();
                        $scope.deliveryDatePickerInit();
                        $scope.setDeliveryWindow();
                    });
                    break;
            }

            $scope.validateFields();
        };

        $scope.checkDripRate = function() {
            const max = $rootScope.limits.rvm || 60;
            const min = 0;

            let currentDripRate = $scope.data.campaign.drip.rate;

            if(currentDripRate !== null) {
                if(currentDripRate === undefined || typeof currentDripRate !== 'number' || currentDripRate > max) {
                    $scope.data.campaign.drip.rate = max;
                } else if(currentDripRate < min) {
                    $scope.data.campaign.drip.rate = min;
                }
            }

            $scope.validateFields();
        };

        $scope.dripRateLostFocus = function() {
            if ($scope.data.campaign.drip.rate === undefined || $scope.data.campaign.drip.rate === null) {
                $scope.data.campaign.drip.rate = 60;
            }
        };

        $scope.dripDeliveryStartTime = function(time) {
            return moment(time).format("hh:mm a");
        };

        $scope.dripDeliveryCalcEndTime = function(start, duration) {
            const startTime = moment(start);
            const endTime = moment(startTime).add(duration, 'hours');
            return endTime.format("hh:mm a");
        };

        $scope.checkDripIncludeWeekend = function() {
            $scope.data.delivery_window.range_text = ($scope.data.delivery_window.include_weekend ? 'every day' : 'weekdays only');
            $scope.data.campaign.drip.saturday.enabled = $scope.data.delivery_window.include_weekend;
            $scope.data.campaign.drip.sunday.enabled = $scope.data.delivery_window.include_weekend;
        };

        $scope.checkDripDuration = function(hours) {
            $scope.data.delivery_window.duration = hours;

            $scope.deliveryDateTimeValidation();
        };

        $scope.__tzStart = function(startDate, value, tz) {
            let start = startDate.clone();
            let timeDate = moment(value).tz(tz);
            let tzDate = start.startOf('day').hour(timeDate.hour()).minute(timeDate.minute());
            return tzDate;
        };

        $scope.__tzDate = function(value, tz) {
            let date = moment(value);
            let timeDate = [date.year(), date.month(), date.date(), date.hour(), date.minute()];
            let tzDate = moment.tz(timeDate, tz);
            return tzDate;
        };

        $scope.__bestFitDrip = function(request) {

            let selectedDate = $scope.data.picker.selected_date;
            let bestFit = Number.MAX_SAFE_INTEGER;

            for (let i = 1; i <= 7; i++) {

                let clonedDate = selectedDate.clone().add(i, 'days');
                let dayOfWeek = clonedDate.format('dddd').toLowerCase();
                let dayData = $scope.data.campaign.drip[dayOfWeek];

                let startTime = moment.tz(dayData.start_time, $scope.data.campaign.drip.timezone).year(clonedDate.year()).dayOfYear(clonedDate.dayOfYear());

                let start = startTime.valueOf();
                if (dayData.enabled && start < bestFit) {
                    bestFit = start;
                }
            }

            return bestFit;
        };

        $scope.calcDripMethod = function(request) {

            let selectedDate = $scope.data.picker.selected_date;

            let dayOfWeek = selectedDate.format('dddd').toLowerCase();
            let startData = $scope.data.campaign.drip[dayOfWeek];

            let startTime = moment.tz(startData.start_time, $scope.data.campaign.drip.timezone).year(selectedDate.year()).dayOfYear(selectedDate.dayOfYear());
            let endTime = moment.tz(startData.end_time, $scope.data.campaign.drip.timezone).year(selectedDate.year()).dayOfYear(selectedDate.dayOfYear());

            if (endTime.hour() === 0 && endTime.minute() === 0) {
                endTime.subtract('1', 's').year(selectedDate.year()).dayOfYear(selectedDate.dayOfYear());
            }

            let nextDrip = moment().tz($scope.data.campaign.drip.timezone);

            let sTs = startTime.valueOf();
            let eTs = endTime.valueOf();
            let nTs = nextDrip.valueOf();

            if (startData.enabled && sTs > nTs) {
                request.campaign_data.drip_at = sTs;
            } else if (startData.enabled && sTs <= nTs && nTs < eTs) {
                request.campaign_data.drip_at = nTs;
            } else {
                request.campaign_data.drip_at = $scope.__bestFitDrip(request);
            }
        };

        $scope.calcRequest = function(request) {

            request.campaign_data.contact_count = (($scope.data.selected_list || {}).import || {}).accepted_count || 0;

            // Calculate the time to start dripping
            if (request.campaign_data.method === 'drip') {
                $scope.calcDripMethod(request);
                request.deliver_at = request.campaign_data.drip_at;
            }
            else if (request.campaign_data.method === 'future') {
                request.campaign_data.drip_at_timezone = $scope.data.picker.timezone;
                request.campaign_data.drip_at = $scope.__tzDate($scope.data.picker.selected_date, $scope.data.picker.timezone).valueOf();
                request.deliver_at = request.campaign_data.drip_at;
            }
        };

        $scope.__createCampaign = function() {

            const request = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                campaign_data: $scope.data.campaign
            };

            if ($scope.audioSource === 'recording') {
                delete request.campaign_data.voice_id;
                delete request.campaign_data.tts_body;
            } else {
                delete request.campaign_data.media_id;
            }

            $scope.calcRequest(request);

            return campaignService.createCampaign(request);
        };

        $scope.__updateCampaign = function() {

            const request = {
                campaign_id: $scope.data.campaign_id,
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                campaign_data: $scope.data.campaign
            };

            $scope.calcRequest(request);

            return campaignService.updateCampaign(request);
        };

        $scope.startCampaign = function() {

            return new Promise((resolve, reject) => {

                const request = {
                    team_id: authService.getTeamId(),
                    user_id: authService.getUserId(),
                    campaign_id: $scope.data.campaign_id
                };

                // Write the campaign
                campaignService.startCampaign(request)
                    .then(results => swal("Success!", "Your campaign will start on the schedule you specified. If you scheduled immediate delivery, be patient, results will start appearing within several minutes.", "success"))
                    .then(results => resolve(results))
                    .catch(err => reject(err));
            });
        };

        $scope.promptForStart = function() {
            return swal("Success!", "Your campaign will start on the schedule you specified. If you scheduled immediate delivery, be patient, results will start appearing within several minutes.", "success");
        };

        $scope.alertWriteFailure = function(err) {
            return swal("Fail", "Failed to write campaign", "error");
        };

        $scope.createCampaign = function($event) {
            $scope.isFormSubmitted = true;
            $scope.validateFields();
            if ($scope.fieldsValid && !$scope.saving.campaign) {
                if (!$scope.team.verified) {
                    return;
                }

                $scope.saving.campaign = true;

                $scope.__createCampaign()
                    .then(results => {
                        $scope.data.campaign_id = results.campaign_id;
                        $scope.saving.campaign = false;

                        // Check risk score here
                        if (!results.approved) {
                            swal("Approval Required", "We placed a temporary hold on your campaign.\n\nA human needs to review your account before the campaign can proceed.  This normally takes less than an hour.  Once approved, your campaign will proceed as scheduled.  For more information, feel free to email " + $rootScope.settings.APP_INFO_EMAIL + " or chat with us.", "warning");
                            return Promise.resolve();
                        } else {
                            const availableFunds = Number($rootScope.total_funds || 0);
                            if (availableFunds && $scope.data.campaign.method !== 'drip' && $scope.data.campaign.method !== 'future') {
                                // Ready to start
                                return $scope.startCampaign();
                            } else {
                                return $scope.promptForStart();
                            }
                        }
                    })
                    .then(() => {
                        $scope.saving.campaign = false;
                        $location.search({});
                        $location.path('/bulk-campaigns');
                        $scope.$apply();
                    })
                    .catch(err => {
                        console.log(err);
                        $scope.saving.campaign = false;
                        $scope.alertWriteFailure(err);
                        $scope.$apply();
                    });
            }
        };

        $scope.updateCampaign = function() {
            $scope.isFormSubmitted = true;
            $scope.validateFields();
            if ($scope.fieldsValid && !$scope.updating.campaign) {
                $scope.updating.campaign = true;
                $scope.__updateCampaign()
                    .then(results => {
                        $scope.updating.campaign = false;
                        $location.search({});
                        $location.path('/bulk-campaigns');
                        $scope.$apply();
                    })
                    .catch(err => {
                        console.log(err);
                        $scope.updating.campaign = false;
                        $scope.alertWriteFailure(err);
                        $scope.$apply();
                    });
            }
        };

        $scope.redirectToAddFunds = function() {
            $location.path('payments');
        };

        $scope.validateFunds = function() {

            if (!$rootScope.isBalance()) {
                // swal('Warning', 'You may not have enough funds in your account to send to a list of this size. Please load additional funds or setup the auto-recharge feature.', 'error');

                //modal mode: 'default',
                //active tab: 'ringless_voicemail'
                $rootScope.showUpsellModal('default', 'ringless_voicemail', false);
                return;
            } else if ($scope.data.campaign_id) {
                $scope.updateCampaign();
            } else {
                swal({
                    title: 'Create Campaign',
                    text: 'Your campaign will send to ' + $scope.data.campaign.list_ids.length + ' contacts starting '
                        + ($scope.data.campaign.method === 'immediate' ? 'immediately' : 'on the drip schedule you defined') + '.',
                    buttons: ['Cancel', 'Create Campaign'],
                    dangerMode: false,
                    closeOnClickOutside: false
                }).then((confirm) => {
                    if (confirm) {
                        $scope.createCampaign();
                    }
                });
            }
        };

        $scope.create = function ($event) {
            if ($event) {
                $event.preventDefault();
            }

            $scope.setDeliveryWindow();
            $scope.isFormSubmitted = true;

            $scope.validateFields();
            $scope.validateFormAfterSubmit();
            if ($scope.fieldsValid && !$scope.saving.campaign) {
                $scope.validateFunds();
            }
        };

        $scope.update = function ($event) {
            if ($event) {
                $event.preventDefault();
            }

            $scope.setDeliveryWindow();
            $scope.isFormSubmitted = true;

            $scope.validateFields();
            $scope.validateFormAfterSubmit();
            if ($scope.fieldsValid && !$scope.updating.campaign) {
                $scope.validateFunds();
            }
        };

        $scope.setDripForExistingCampaign = function(dripObj, callback) {
            let start, end;
            _.map(dripObj, function(d) {
                if(d.start_time && d.end_time) {
                    start = moment.tz(d.start_time, dripObj.timezone);
                    d.start_time = start.toDate();
                    end = moment.tz(d.end_time, dripObj.timezone);
                    d.end_time = end.toDate();
                }
            });

            $scope.data.delivery_window.start_time = moment(new Date(1970, 0, 1, 8, 0)).hour(start.hour()).minute(start.minute());

            $scope.data.delivery_window.duration = end.diff(start, 'hours');

            $scope.data.delivery_window.include_weekend = dripObj.saturday.enabled;
            $scope.data.delivery_window.range_text = ($scope.data.delivery_window.include_weekend ? 'every day' : 'weekdays only');

            callback(dripObj);
        };

        $scope.checkDeliveryMethod = function(campaign, callback) {
            const campaignData = (campaign.campaign_data || {});

            switch(campaignData.method) {
                case 'drip':
                    $scope.setDripForExistingCampaign(campaignData.drip, function(res) {
                        $scope.data.campaign = campaignData;
                        $scope.data.campaign.drip = res;

                        let tz = campaignData.drip_at_timezone || campaignData.drip.timezone || $scope.data.default_timezone;
                        $scope.data.picker.selected_date = moment.tz(campaignData.drip_at, tz)
                            .hour($scope.data.delivery_window.start_time.hour()).minute($scope.data.delivery_window.start_time.minute());
                        $scope.data.picker.timezone = tz;
                        callback();
                    });
                    break;
                case 'future':
                    if(campaignData.drip_at_timezone) {
                        $scope.data.picker.selected_date = moment.tz(campaignData.drip_at, campaignData.drip_at_timezone);
                        $scope.data.picker.timezone = campaignData.drip_at_timezone;
                    } else {
                        $scope.data.picker.selected_date = moment.tz(campaignData.drip_at, $scope.data.default_timezone);
                        $scope.data.picker.timezone = $scope.data.default_timezone;
                    }
                    $scope.data.campaign = campaignData;
                    callback();
                    break;
                default:
                    $scope.data.campaign = campaignData;
                    callback();
            }
        };

        $scope.loadCampaign = function(campaign_id, callback) {
            $scope.loading.campaign = true;
            let req = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                campaign_id: campaign_id
            };

            campaignService.getCampaign(req)
                .then(results => {
                    $scope.checkDeliveryMethod(results, function() {
                        $scope.setDurationArray();
                        $scope.deliveryDatePickerInit();
                        $scope.setDeliveryWindow();

                        $scope.loading.campaign = false;
                        $scope._checkLoadingStatus();
                        $scope.$apply();
                        callback(results);
                    });
                })
                .catch(err => {
                    console.log(err);
                    swal("Failed", "Failed to get campaign.  Please try again later.", "error");
                    $scope.loading.campaign = false;
                    $scope._checkLoadingStatus();
                    $scope.$apply();
                    callback(null);
                });
        };

        $scope.initData = function() {
            $scope.data.delivery_window.include_weekend = true;
            $scope.data.campaign =  {
                name: "My Campaign",
                brand_id: null,
                list_ids: null,
                media_id: null,
                voice_id: null,
                method: 'immediate',
                call_routing: {
                    action: 'ivr',
                    voice_ivr_id: null,
                    sms_ivr_id: null
                },
                drip: {
                    rate: null,
                    timezone: null,
                    monday: null,
                    tuesday: null,
                    wednesday: null,
                    thursday: null,
                    friday: null,
                    saturday: null,
                    sunday: null
                },
                compliance: {
                    rules_regs_laws: false,
                    terms_of_service: false,
                    tcpa_tsr: false,
                    legal_voice: false,
                    voice_clone_price: false,
                },
                region: 'US'
            };
            $scope.data.membership_level = authService.getMembershipLevel();
        };

        $scope.checkCampaignId = function(callback) {
            $scope.data.campaign_id = $location.search().campaign_id;

            if ($scope.data.campaign_id) {
                $scope.existing_campaign = $scope.data.campaign_id;
                $scope.loadCampaign($scope.data.campaign_id, function(res) {
                    callback(res);
                });
            } else {
                callback(null);
            }
        };

        $scope.isCampaignsListEmpty = function() {

            const req = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId()
            };

            campaignService.getCampaigns(req)
                .then(results => {
                    $scope.isCampaignsListEmptyBool = (results.length > 0 ? false : true);
                    $scope.$apply();
                })
                .catch(err => {
                    console.log(err);
                    $scope.isCampaignsListEmptyBool = true;
                    $scope.$apply();
                });
        };

        $scope.setSelectedIVRs = function() {

            let data = $scope.data.campaign.call_routing || {};

            let ivrs = $scope.data.all_ivrs || [];
            for (let i = 0; i < ivrs.length; i++) {
                let ivr = ivrs[i];
                if (ivr.ivr_id) {
                    if (ivr.ivr_id === data.voice_ivr_id) {
                        $scope.data.selected_voice_ivr = ivr;
                    }

                    if (ivr.ivr_id === data.sms_ivr_id) {
                        $scope.data.selected_sms_ivr = ivr;
                    }
                }
            }
        };

        $scope.setVoiceIVR = function(ivr) {
            $scope.data.selected_voice_ivr = ivr;
            $scope.data.campaign.call_routing.voice_ivr_id = ivr.ivr_id;
        };

        $scope.setSmsIVR = function(ivr) {
            $scope.data.selected_sms_ivr = ivr;
            $scope.data.campaign.call_routing.sms_ivr_id = ivr.ivr_id;
        };

        $scope.getNumbersUsingIVR = function(teamId, userId) {
            const ivrs = $scope.data.ivrs || [];

            async.each(ivrs, function(ivr, callback) {
                var req = {
                    ivr_id: ivr.ivr_id,
                    team_id: teamId,
                    user_id: userId
                };

                phoneService.getNumbersUsingIvr(req)
                    .then(results => {
                        ivr.phone_numbers = results;

                        let purchasedNumbers = [];
                        let phoneNumbers = results || [];
                        for (let i = 0; i < phoneNumbers.length; i++) {
                            let number = phoneNumbers[i];
                            if (!number.pending_purchase_at) {
                                purchasedNumbers.push(number);
                            }
                        }

                        ivr.purchased_numbers = purchasedNumbers;
                        callback();
                    }).catch(err => {
                    console.log(err);
                    $scope.loading.ivrs = false;
                    $scope._checkLoadingStatus();
                    $scope.$apply();
                });
            }, function() {
                $scope.loading.ivrs = false;
                $scope._checkLoadingStatus();
                $scope.$apply();
            });
        };

        $scope.loadVoiceIVRs = function() {

            let req = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId()
            };

            $scope.loading.ivrs = true;

            phoneService.getIvrs(req)
                .then(results => {
                    $scope.loading.ivrs = false;
                    $scope.data.ivrs = _.sortBy(results || [], 'name') || [];
                    $scope.getNumbersUsingIVR(req.team_id, req.user_id);
                    $scope.$apply();
                })
                .catch(err => {
                    console.log(err);
                    alert('Error: Failed to get call routes.');
                    $scope.loading.ivrs = false;
                    $scope._checkLoadingStatus();
                    $scope.$apply();
                });
        };

        $scope._checkLoadingStatus = function() {
            if (
                !$scope.loading.campaign &&
                !$scope.loading.contacts &&
                !$scope.loading.recordings &&
                !$scope.loading.ivrs
            ) {
                $scope.loading.all = false;
            } else {
                $scope.loading.all = true;
            }
        }

        $scope.setIsAllowNumberPurchase = function() {
            let team = $rootScope.user.team;
            if (team.identity) {
                $scope.data.isAllowNumberPurchase = (team.identity.country !== "US");
            }
        };

        $scope.$on('$destroy', function() {
            $interval.cancel($scope.jobPolling);
        });

        $scope.onInit = function() {
            $window.scrollTo(0, 0);
            $scope.team = $rootScope.user.team;
            $scope.data.short_codes = $rootScope.shortCodes;
            $scope.isCampaignsListEmpty();
            $scope.checkCampaignId(function(res) {
                if (res === null) {
                    $scope.initData();
                    $scope.setDeliveryMethod('immediate');
                }
                $scope.setDeliverySchedule();

                $scope.loadRecordings();
                $scope.loadVoices();
                $scope.loadContactLists();
                $scope.loadBrands();
                $scope.loadVoiceIVRs();
                $scope.onBalanceChange();
            });
        };

        $scope.onBalanceChange = function() {
            $scope.data.balance = $rootScope.balance;
            $scope.data.isValidBalance = ($rootScope.total_funds > 0);
        }

        $scope.$on('balance_change', function(event, args) {
            $scope.onBalanceChange();
        });

        if (authService.hasAccount()) {
            $scope.onInit();
        } else {
            $scope.$on('auth_complete', function(event, args) {
                $scope.onInit();
            });
        }
    }
    module.exports = CreateCampaignController;
})();
