(function () {
    'use strict';

    function SubscriptionController($rootScope, $scope, authService, accountService, integrationService, $location, campaignService, $window, $cookies) {
        var vm = this;
        vm.authService = authService;

        $rootScope.viewData = $rootScope.viewData || {};
        $rootScope.viewData.breadcrumbs = $rootScope.viewData.breadcrumbs || [];
        $rootScope.viewData.breadcrumbs = [{
            title: 'Subscriptions',
            link_name: 'Subscriptions',
            link_url: '#/subscriptions',
            help_url: $rootScope.help_url
        }];

        $scope.staticText = {
            loading: 'Loading',

            title: 'Subscriptions',
            subtitle: 'You can manage your subscriptions below...',
            submitBtn: 'Proceed to Checkout',
            usersTitle: 'Users',
            usersSubTitle: 'Review your user license selections. Change your user subscriptions <a href="#/team">here</a>.',
            usersColumnsHead: ['Per User', 'Users', 'Subtotal'],

            monthlyRecurringSubtotalTitle: 'Monthly Recurring Subtotal',
            taxesFeesTitle: 'Taxes & Regulatory Fees',
            monthlyRecurringTotalTitle: 'Monthly Recurring Total',

            nextBillingDateTitle: '(Next Billing Date)',

            proratedSubtotalTitle: 'Prorated Subtotal',
            proratedTaxesFeesTitle: 'Prorated Taxes & Regulatory Fees',
            amountDueTodayTitle: 'Amount Due Today',
            proratedAmountDueTodayTitle: 'Prorated Amount Due Today',

            featuresTitle: 'Usage Based Features',
            featuresSubTitle: 'Select a plan for your features that are billed as you use them...',
            featuresColumnsHead: ['Plan Type', 'Subtotal'],

            phoneNumbersTitle: 'Phone Numbers',
            phoneNumbersSubTitle: 'Review your phone number selections. Change your phone subscriptions <a href="#/phone-numbers">here</a>.',
            phoneNumbersColumnsHead: ['Per Number', 'Numbers', 'Subtotal'],

            rechargeTitle: 'Automatic Balance Recharge',
            rechargeColumnsHead: ['Threshold', 'Recharge Amount'],

            changeQuantityWarning: 'To change your user license quantity, please add or remove users from the My Account menu.',
            changeQuantityPhoneNumbersWarning: 'To change your phone number quantity, please visit the Phone Numbers menu to purchase or release numbers in your account.',

            pendingChangeAlert: 'You have pending subscription changes.  Please review and proceed to checkout.',
            processingChargebee: 'Please wait, this may take a minute',

            cancelSubscription: [
                'Need to',
                'cancel your subscription?'
            ]
        };

        $scope.data = {
            loading: {
                init: false,
                checkout: false,
                chargebee: false
            },
            displayErrorAlert: false,
            errorAlertMessage: '',
            subscriptionsData: null,
            balance: null,
            invoiceEstimate: null,
            pendingUsageAddonId: null,
            phoneNumbers: [],
            rechargeThreshold: [
                50,
                100,
                150,
                200,
                250,
                500,
                750,
                1000
            ],
            rechargeAmount: [
                0,
                50,
                100,
                250,
                500,
                750,
                1000,
                1500,
                2000,
                2500,
                3000
            ],
            integrations: {}
        };

        $scope.subscriptionObj = {
            isPending: false,
            userPlans: [],
            usagePlan: null,
            rechargeThreshold: null,
            rechargeAmount: null,
            phoneNumbers: [],
            totals: {
                monthlyRecurringSubtotal: 0,
                taxesFees: 0,
                monthlyRecurringTotal: 0
            },
            proratedTotals:{
                monthlyRecurringSubtotal: 0,
                taxesFees: 0,
                monthlyRecurringTotal: 0,
            }
        };

        $scope.submit = function() {
            if (!$scope.data.loading.checkout) {
                $scope.data.loading.checkout = true;
                //$rootScope.showCartModal($scope.data.cartData);

                const shopifyIntegration = $scope.data.integrations['shopify'];

                if (shopifyIntegration && shopifyIntegration.active) {
                    const request = {
                        team_id: authService.getTeamId(),
                        user_id: authService.getUserId(),
                        usage_plan: $scope.subscriptionObj.usagePlan,
                        shopify_token: $rootScope.shopifyToken || $cookies.get('shopifyToken'),
                        shopify_store: (shopifyIntegration || {}).integration_foreign_id || null
                    };

                    integrationService.shopifyCreateSubscription(request)
                        .then(results => {
                            if (results.appSubscriptionCreate && results.appSubscriptionCreate.confirmationUrl) {
                                window.location = results.appSubscriptionCreate.confirmationUrl;
                            }
                            $scope.data.loading.checkout = false;

                            $scope.$apply();
                        })
                        .catch(err => {
                            $scope.data.loading.checkout = false;
                            $scope.$apply();
                            swal("Server Error", "Our server failed to respond.", "error");
                        });
                } else {
                    $rootScope.collectPayment('update_subscription', {}, (hostedPageId) => {
                        $scope.data.loading.checkout = false;
                        if (hostedPageId) {
                            $scope.data.loading.chargebee = true;
                        }
                        $scope.$apply();
                    });
                }
            }
        };

        $scope.formatMoney = function(value, currency, isShowCurrency) {
            return $rootScope.formatMoney(value, currency, isShowCurrency);
        };

        $scope.pickUsagePlan = function() {
            //modal mode: 'usage-plans',
            //active tab: 'ringless_voicemail'
            $rootScope.showUpsellModal('usage-plans', 'ringless_voicemail', false);
        };

        $scope.setUsagePlan = function(plan, isReload) {
            $scope.subscriptionObj.usagePlan = plan;
            if (isReload) {
                $scope.subscriptionObj.isPending = true;
                $location.path('/subscriptions').search({pending: 1});
            }
        };

        $scope.setRechargeThreshold = function(value, isSave) {
            $scope.subscriptionObj.rechargeThreshold = value;
            if (isSave) {
                $scope.updateTeamTriggers({
                    recharge_threshold: value
                });
            }
        };

        $scope.setRechargeAmount = function(value, isSave) {
            $scope.subscriptionObj.rechargeAmount = value;
            if (isSave) {
                $scope.updateTeamTriggers({
                    recharge_amount: value
                });
            }
        };

        $scope.updateTeamTriggers = function(data) {
            data.team_id = authService.getTeamId();
            data.user_id = authService.getUserId();
            campaignService.updateTeamTriggers(data);
        };

        $scope.quantityChanged = function(item, oldQuantity) {
            // show notification and revert quantity
            swal({
                text: $scope.staticText.changeQuantityWarning,
                button: 'Edit Users',
                icon: 'warning'
            }).then((confirm) => {
                if (!confirm) {
                    item.quantity = Number(oldQuantity)
                    return;
                }

                $location.path('/team');
            });
        };

        $scope.quantityNumbersChanged = function(item, oldQuantity) {
            // show notification and revert quantity
            swal({
                text: $scope.staticText.changeQuantityPhoneNumbersWarning,
                button: 'Edit Phone Numbers',
                icon: 'warning'
            }).then((confirm) => {
                if (!confirm) {
                    item.quantity = Number(oldQuantity)
                    return;
                }

                $location.path('/phone-numbers');
            });
        };

        $scope.fromCentsToDollars = function(value) {
            if (!value) return 0;

           return value*1 / 100;
        };

        $scope.hasPermissions = function(entity, action) {
            return $rootScope.hasPermissions(entity, action);
        };

        $scope.initData = function() {

            let subData = $scope.data.subscriptionsData || {};

            let prorationEstimate = subData.proration_estimate || {};
            let prorationInvoiceEstimate = prorationEstimate.invoice_estimate; // This invoice contains the prorated amounts for a partial month of service.
            let nextRenewalTimestamp = ((prorationEstimate.subscription_estimate || {}).next_billing_at || 0) * 1000; // The timestap of when the next full month invoice will be billed.

            let estimate = subData.estimate || {};
            let invoiceEstimate = estimate.invoice_estimate; // This invoice contains the invoice for the next full month of service (which will be billed on nextRenewalTimestamp).

            if (prorationInvoiceEstimate) {
                $scope.subscriptionObj.proratedTotals.monthlyRecurringSubtotal = $scope.fromCentsToDollars(prorationInvoiceEstimate.sub_total);
                $scope.subscriptionObj.proratedTotals.monthlyRecurringTotal = $scope.fromCentsToDollars(prorationInvoiceEstimate.total);
                $scope.subscriptionObj.proratedTotals.taxesFees = $scope.fromCentsToDollars(prorationInvoiceEstimate.tax_amount);
            }

            let totalAmountRenewal = (invoiceEstimate || {}).total || 0;
            let totalAmountToday = (prorationInvoiceEstimate || {}).total || 0;
            let submitLabel = "Pay " + $scope.formatMoney($scope.fromCentsToDollars(totalAmountToday), 'USD', false) + " & Subscribe";

            $scope.data.cartData = {
                billing_address: subData.billing_address || null,
                primary_payment: subData.primary_payment || null,
                total_amount_today: (prorationInvoiceEstimate || {}).total || 0,
                total_amount_recurring: (invoiceEstimate || {}).total || 0,
                renewal_timestamp: nextRenewalTimestamp,
                has_recurring_charges: true,
                submit_label: submitLabel
            };

            if (invoiceEstimate) {

                $scope.data.invoiceEstimate = invoiceEstimate;

                // set totals and taxes
                $scope.subscriptionObj.totals.monthlyRecurringSubtotal = $scope.fromCentsToDollars(invoiceEstimate.sub_total);
                $scope.subscriptionObj.totals.monthlyRecurringTotal = $scope.fromCentsToDollars(invoiceEstimate.total);
                $scope.subscriptionObj.totals.taxesFees = $scope.fromCentsToDollars(invoiceEstimate.tax_amount);

                // set values to userPlans according to plan_id
                const plansDefaults = $rootScope.pricing[$scope.data.subscriptionsData.plan_id]['packages'];

                const subscriptionChanges = $scope.data.subscriptionsData.subscription_changes || {};

                const activeShopifyIntegration = $scope.data.integrations['shopify'] && $scope.data.integrations['shopify'].active;

                Object.keys(plansDefaults)
                    .forEach(function(key) {
                        let planDefault = plansDefaults[key];
                        if (planDefault.isUserLicense
                            && planDefault.id !== 'inactive-monthly'
                            && ((activeShopifyIntegration && planDefault.id === 'shopify-monthly') || (!activeShopifyIntegration && planDefault.id !== 'shopify-monthly'))
                        ) {
                            // find plan in response and set values
                            let userPlanFromResponse = invoiceEstimate.line_items.find(line_item => line_item.entity_id === planDefault.id);
                            $scope.subscriptionObj.userPlans.push({
                                id: planDefault.id,
                                name: planDefault.name,
                                description: planDefault.desc,
                                unit_amount: $scope.fromCentsToDollars(userPlanFromResponse ? userPlanFromResponse.unit_amount : planDefault.unit_cost * 100),
                                quantity: userPlanFromResponse ? userPlanFromResponse.quantity : 0,
                                amount: userPlanFromResponse ? $scope.fromCentsToDollars(userPlanFromResponse.amount) : 0,
                                has_changes: (subscriptionChanges[planDefault.id] && (subscriptionChanges[planDefault.id] > 0))
                            });
                        } else if (planDefault.id.includes('usage-plan-')) {

                            let usageChargeId = planDefault.id.slice();//.replace('usage-plan-', 'usage-charge-');

                            // find plan in response and set values
                            let usageCharge = invoiceEstimate.line_items.find(line_item => line_item.entity_id === usageChargeId);
                            if (usageCharge) {
                                const usagePlan = {
                                    id: planDefault.id,
                                    name: planDefault.name,
                                    description: planDefault.desc,
                                    unit_cost: $scope.fromCentsToDollars(usageCharge.unit_amount || 0)
                                };
                                $scope.setUsagePlan(usagePlan, false);
                            }
                        }
                        // add phone numbers
                        else if (planDefault.id.includes('tn-local-')) {
                            let tnLocalPlanFromResponse = invoiceEstimate.line_items.find(line_item => line_item.entity_id === planDefault.id);
                            $scope.subscriptionObj.phoneNumbers.push({
                                id: planDefault.id,
                                name: planDefault.name,
                                description: planDefault.desc,
                                unit_amount: $scope.fromCentsToDollars(tnLocalPlanFromResponse ?  tnLocalPlanFromResponse.unit_amount : planDefault.unit_cost * 100),
                                quantity: tnLocalPlanFromResponse ? tnLocalPlanFromResponse.quantity : 0,
                                amount: tnLocalPlanFromResponse ? $scope.fromCentsToDollars(tnLocalPlanFromResponse.amount) : 0,
                                has_changes: (subscriptionChanges['local'] && (subscriptionChanges['local'] > 0))
                            });
                        } else if (planDefault.id.includes('tn-tollfree-')) {
                            let tnTFPlanFromResponse = invoiceEstimate.line_items.find(line_item => line_item.entity_id === planDefault.id);
                            $scope.subscriptionObj.phoneNumbers.push({
                                id: planDefault.id,
                                name: planDefault.name,
                                description: planDefault.desc,
                                unit_amount: $scope.fromCentsToDollars(tnTFPlanFromResponse ?  tnTFPlanFromResponse.unit_amount : planDefault.unit_cost * 100),
                                quantity: tnTFPlanFromResponse ? tnTFPlanFromResponse.quantity : 0,
                                amount: tnTFPlanFromResponse ? $scope.fromCentsToDollars(tnTFPlanFromResponse.amount) : 0,
                                has_changes: (subscriptionChanges['toll_free'] && (subscriptionChanges['toll_free'] > 0))
                            });
                        }
                    });
            }

            if ($scope.data.balance) {
                if ($scope.data.balance.recharge_amount) {
                    $scope.setRechargeAmount($scope.data.balance.recharge_amount, false);
                }
                if ($scope.data.balance.recharge_threshold) {
                    $scope.setRechargeThreshold($scope.data.balance.recharge_threshold, false);
                }
            }
        };

        $scope.getIntegrations = () => {
            return integrationService.list()
                .then(res => {
                    integrationService.set(res);

                    const integrations = integrationService.get();
                    $scope.data.integrations = integrations;

                    if (integrations['shopify'] && integrations['shopify'].active) {
                        $scope.pickUsagePlan();
                    }
                })
        }

        $scope.initPage = function(showPending) {

            $scope.subscriptionObj = {
                isPending: false,
                userPlans: [],
                usagePlan: null,
                rechargeThreshold: null,
                rechargeAmount: null,
                phoneNumbers: [],
                totals: {
                    monthlyRecurringSubtotal: 0,
                    taxesFees: 0,
                    monthlyRecurringTotal: 0,
                },
                proratedTotals:{
                    monthlyRecurringSubtotal: 0,
                    taxesFees: 0,
                    monthlyRecurringTotal: 0,
                }
            };

            if (showPending) {
                $scope.subscriptionObj.isPending = true;
            }

            $scope.data.loading.init = true;

            const req = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                preview: showPending
            };

            Promise.all([
                accountService.getSubscriptions(req),
                campaignService.balance({
                    user_id: authService.getUserId(),
                    team_id: authService.getTeamId()
                }),
                $scope.getIntegrations()
            ])
                .then(results => {
                    let subData = results[0] || {};
                    $scope.data.subscriptionsData = subData;
                    $scope.data.balance = results[1] || {};
                    $scope.subscriptionObj.isPending = (subData.pending_change_count > 0);
                    $scope.initData();
                    $scope.data.loading.init = false;
                    $scope.$apply();
                })
                .catch(err => {
                    console.log(err);
                    $scope.data.loading.init = false;
                    $scope.$apply();
                });
        };

        $scope.cancelSubscription = function() {
            $rootScope.showCancelModal();
        };

        $scope.$on('subscription.invoiced', function(e, ablyEvent) {
            console.log('Got ' + ablyEvent.event);
            //const params = $location.search();
            // let pending = true;//params.pending || $scope.subscriptionObj.isPending;
            // $scope.initPage(pending);
        });

        $scope.$on('subscription.changed', function(e, ablyEvent) {
            $scope.data.loading.chargebee = false;

            swal('Subscription changed.', '', 'success')
               .then(() => $location.path('dash'));
        });

        $scope.$on('cancellingSubscription', function(e, ablyEvent) {
            $scope.data.loading.checkout = true;
            $scope.data.loading.chargebee = true;
            $scope.$apply();
        });

        $scope.$on('cartModalClosedEvent', function(e, ablyEvent) {
            $scope.data.loading.checkout = false;
        });

        $scope.$on('cartModalSuccessClosedEvent', function(e, ablyEvent) {
            $scope.data.loading.checkout = false;
            $scope.subscriptionObj.isPending = false;
            $scope.data.loading.chargebee = true;
        });

        $scope.enableDisableFullScreenView = function(action) {
            $('#subscription-container')[action]('full-screen');
        };

        $scope.resizeSubscriptionContainer = function() {
            var action = (window.innerWidth < 1426 || (!$window.localStorage['right_side_panel'] || $window.localStorage['right_side_panel'] === 'show')) ? 'removeClass' : 'addClass';
            $scope.enableDisableFullScreenView(action);
        };

        $scope.$on('hiddenRightSideBar', function () {
            if(window.innerWidth > 1425) {
                $scope.enableDisableFullScreenView('addClass');
            }
        });
        $scope.$on('shownRightSideBar', function () {
            $scope.enableDisableFullScreenView('removeClass');
        });

        window.onresize = $scope.resizeSubscriptionContainer;

        $scope.onInit = function() {
            $scope.resizeSubscriptionContainer();
            //const params = $location.search();
            let pending = true;//params.pending || $scope.subscriptionObj.isPending;
            $scope.initPage(pending);
        };

        if (authService.hasAccount()) {
            $scope.onInit();
        }
        else {
            $scope.$on('auth_complete', function(event, args) {
                $scope.onInit();
            });
        }
    }
    module.exports = SubscriptionController;
})();
