(function() {
    'use strict';

    function PhoneTreeIVRcontroller($rootScope, $scope, $location, $window, authService, phoneService, notificationToastService) {
        var vm = this;
        vm.authService = authService;

        $rootScope.viewData = $rootScope.viewData || {};
        $rootScope.viewData.breadcrumbs = $rootScope.viewData.breadcrumbs || [];
        $rootScope.viewData.breadcrumbs = [{
            title: 'Call Routing',
            link_name: 'Call Routing',
            link_url: '#/call-routing',
            help_url: $rootScope.help_url
        }];
    
        $scope.data = {
            ivrs: []
        };
        $scope.loading = true;

        $scope.staticText = {
            title: "Call Routing",
            subtitle: "Configure rules to handle inbound phone calls...",
            newIVRbtn: {
                title: "Add Call Routing",
                href: "#/ivr-config"
            },
            table: {
                header: ["Name", "Used By", "Created At"],
                actions: ["Delete Call Routing", "View Rules", "Set Default"],
                defaultLabel: "DEFAULT",
                noRes: "You don't have any call routing. ",
                loading: "Loading",
                popoverHtml: "phone-numbers-popover.html"
            }
        };

        $scope.confirmDelete = function(ivr, callback) {

            var req = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                ivr_id: ivr.ivr_id
            };

            phoneService.deleteIvr(req)
                .then(results => {
                    callback(null);
                })
                .catch(err => {
                    console.log(err);
                    callback(err);
                });
        };

        $scope.deleteIVR = function(e, ivr) {
            if (e) {
                e.preventDefault();
            }

            swal({
                title: "Confirm",
                text: "Are you sure you want to delete this call routing?",
                buttons: ["No", "Yes"],
                dangerMode: true,
                closeOnClickOutside: false
            }).then(isConfirm => {
                if (isConfirm) {
                    $scope.confirmDelete(ivr, function(res) {
                        if(res === null) {
                            swal("Deleted!", "This call routing has been deleted!", "success");
                            $scope.loadVoiceIVRs();
                            $scope.$apply();
                        } else swal("Cancelled", "Unable to delete call routing", "error");
                    });

                }
            });
        };

        $scope.setDefaultIvr = function(ivr) {

            if(!ivr.is_default) {

                const ivrReq = {
                    team_id: authService.getTeamId(),
                    user_id: authService.getUserId(),
                    is_default: true,
                    ivr_id: ivr.ivr_id,
                    type: ivr.type
                };
                phoneService.setDefaultIvr(ivrReq)
                    .then(results => {
                        $scope.loadVoiceIVRs();
                        $scope.$apply();
                    })
                    .catch(err => {
                        console.log(err);
                        swal("Cancelled", "Unable to set default call routing", "error");
                    });
            }
        };

        $scope.newNameValue = {};
        $scope.editNameField = function(ivr) {
            if($scope.newNameValue[ivr.ivr_id] !== ivr.name) {
                $scope.newNameValue[ivr.ivr_id] = ivr.name;
                $scope.updateIvrName(ivr);
            }
        };

        $scope.hasPermissions = function(entity, action) {
            return $rootScope.hasPermissions(entity, action);
        };

        $scope.updateIvrName = function(ivr) {

            const ivrReq = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                ivr_id: ivr.ivr_id,
                type: ivr.type,
                name: ivr.name,
                rules: ivr.rules
            };

            phoneService.updateIvr(ivrReq)
                .then(results => {
                    $scope.$apply();
                    notificationToastService.showSuccessToast('fa-light fa-check', 'Call routing name has been updated!');
                })
                .catch(err => {
                    console.error(err);
                    $scope.$apply();
                    notificationToastService.showErrorToast('fa-light fa-triangle-exclamation', 'Unable to update this call routing. Try again later.');
                });
        };

        $scope.getNumbersUsingIVR = function(teamId, userId) {
            const ivrs = $scope.data.ivrs || [];

            async.each(ivrs, function(ivr, callback) {
                var req = {
                    ivr_id: ivr.ivr_id,
                    team_id: teamId,
                    user_id: userId
                };

                phoneService.getNumbersUsingIvr(req)
                    .then(results => {
                        ivr.phone_numbers = results;
                        callback();
                    }).catch(err => {
                    console.log(err);
                    $scope.loading = false;
                    $scope.$apply();
                });
            }, function() {
                $scope.loading = false;
                $scope.$apply();
            });
        };

        $scope.loadVoiceIVRs = function() {

            let req = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId()
            };

            $scope.loading = true;

            phoneService.getIvrs(req)
                .then(results => {
                    var temp = _.filter(results, ['type', 'voice']);
                    $scope.data.ivrs = _.sortBy(temp, ['is_default', 'created_at']).reverse();
                    $scope.getNumbersUsingIVR(req.team_id, req.user_id);
                    if($scope.data.ivrs.length === 1)
                        $scope.setDefaultIvr($scope.data.ivrs[0]);
                    $scope.$apply();
                })
                .catch(err => {
                    console.log(err);
                    alert('Error: Failed to get call routings.');
                    $scope.loading = false;
                    $scope.$apply();
                });
        };

        $scope.viewRules = function(ivr) {
            $location.path('ivr-config').search('id=' + ivr.ivr_id);
        };

        $scope.onInit = function() {
            $window.scrollTo(0, 0);
            $scope.loadVoiceIVRs();
        };

        if (authService.hasAccount()) {
            $scope.onInit();
        }
        else {
            $scope.$on('auth_complete', function(event, args) {
                $scope.onInit();
            });
        }
    }
    module.exports = PhoneTreeIVRcontroller;
})();
