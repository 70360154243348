(function () {
    'use strict';

    function PhoneNumberPurchaseController($rootScope, $scope, $window, $location, $cookies, authService, phoneService, accountService, userService) {
        var vm = this;
        vm.authService = authService;

        $rootScope.viewData = $rootScope.viewData || {};
        $rootScope.viewData.breadcrumbs = $rootScope.viewData.breadcrumbs || [];
        $rootScope.viewData.breadcrumbs = [{
            title: 'Phone Numbers',
            link_name: 'Phone Numbers',
            link_url: '#/phone-numbers',
            help_url: $rootScope.help_url
        }, {
            title: 'Phone Number Purchase',
            link_name: 'Phone Number Purchase',
            link_url: '#/phone-number-purchase'
        }];

        $scope.data = {
            numbers: [],
            totalLicenses: 0,
            rent_number: null,
            number_type: 'Local',
            area_code: '',
            iso_country_code: 'US',
            first_rent: {
                user_phone_type: 'office_phone'
            },
            isoToCountry: {
                //'AU': 'Australia',
                //'BE': 'Belgium',
                'CA': 'Canada',
                //'FI': 'Finland',
                //'DE': 'Germany',
                //'NL': 'Netherlands',
                //'NO': 'Norway',
                //'SE': 'Sweden',
                //'CH': 'Switzerland',
                //'GB': 'United Kingdom',
                'US': 'United States'
            },
            purchase_plan: '',
            team_config: 'noEmployees',
            info: {
                vouchNeeded: {
                    display: undefined,
                    info: 'Verify your identity to enable calling, texting, and purchase of phone numbers.',
                    text: 'Verify Now.'
                },
                vouchSent: {
                    display: undefined,
                    href: $rootScope.verify_learn_url,
                    info: 'Identity verification sent. Please check your email or text messages.',
                    text: 'Learn more.'
                },
                vouchReceived: {
                    display: undefined,
                    href: $rootScope.verify_learn_url,
                    info: 'Identity verification received, waiting for review (this may take several hours).',
                    text: 'Learn more.'
                }
            },
            trial_limit: 3,
            trial_limit_desc: 'You are limited to purchasing three phone numbers during your free trial.  To purchase additional phone numbers, please end your free trial by visiting \'My Account->My Subscription\' and complete the checkout process.'
        };

        $scope.loading = {
            numbers: false,
            rent: false
        };
        
        $scope.staticData = {
            findBlock: {
                title: "Find available numbers",
                subtitle: "Select a country and search by area code...",
                numberType: ["Local", "Toll Free"],
                searchInputPlaceholder: "Prefix / Area Code",
                searchBtn: "Search",
                optionMessage: "* MMS messaging not available for toll-free numbers"
            },
            resultBlock: {
                title: "Available numbers",
                subtitle: "These numbers are available to rent...",
                loader: "Loading",
                table: {
                    header: ["Phone Number"],
                    optionTd: {
                        mainText: "Premium Feature",
                        smallText: "Membership Required"
                    },
                    actionsTd: {
                        upgradeBtn: "Become a Member",
                        rentBtn: "Rent Number"
                    },
                    noRes: "No numbers match your search criteria. Change your criteria and try again."
                }
            }
        };

        $scope.showVerificationModal = function(e) {
            e.preventDefault();
            $rootScope.showVerificationModal();
        };

        $scope.formatPhone = function(value) {
            return $rootScope.libPhoneFormatPhone(value);
        };

        $scope.setIsoCountry = function(value) {
            $scope.data.iso_country_code = value;
        };

        $scope.createDefaultIvrs = function() {

            const smsIvrReq = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                user_number_type: $scope.data.first_rent.user_phone_type,
                default: true,
                type: 'sms'
            };

            const phoneSmsReq = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                default: true,
                user_number_type: $scope.data.first_rent.user_phone_type,
                type: 'voice'
            };

            Promise.all([phoneService.createIvr(smsIvrReq), phoneService.createIvr(phoneSmsReq)])
                .then(results => {
                    $("#firstRentModal").modal('hide');
                    $scope.showDialerInstructionsModal();
                    $scope.$apply();
                })
                .catch(err => {
                    $("#firstRentModal").modal('hide');
                    $scope.$apply();
                });
        };

        $scope.searchNumbers = function(event, phoneNumber) {

            if(event){
                event.preventDefault();
            }

            $scope.loading.numbers = true;

            let req = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                country_iso: $scope.data.iso_country_code,
                pattern: phoneNumber || $scope.data.area_code
            };

            if ($scope.data.iso_country_code === 'US' && $scope.data.number_type === 'Toll Free') {
                req.type = 'tollfree';
            }

            phoneService.searchAvailable(req)
                .then(results => {
                    $scope.data.numbers = results || [];
                    $scope.loading.numbers = false;
                    $cookies.remove('checkoutPhoneNumber');
                    $scope.$apply();
                })
                .catch(err => {
                    console.log(err);
                    $scope.loading.numbers = false;
                    $cookies.remove('checkoutPhoneNumber');
                    $scope.$apply();
                });
        };

        $scope.tryRent = function(number) {
            $scope.rent(number);
        };

        $scope.rent = function(value) {

            let existingNumbers = $rootScope.team_phone_numbers || [];
            if (existingNumbers.length >= $scope.data.trial_limit && $rootScope.isFreeTrial()) {
                swal({
                    title: "Free Trial Limit",
                    text: $scope.data.trial_limit_desc,
                    buttons: ['OK', 'View Subscriptions'],
                    closeOnClickOutside: false,
                    icon: 'info'
                }).then( confirm => {
                    if (confirm) {
                        $rootScope.openLocation('subscriptions');
                    }
                });

                return;
            }

            if (!$scope.user.team.verified) {
                if (!$scope.user.team.vouched_invite_id && !$scope.user.team.vouched_completed) {
                    swal('Identity Verification Required', $scope.data.info.vouchNeeded.info, 'info');
                }
                else if ($scope.user.team.vouched_invite_id && !$scope.user.team.vouched_completed) {
                    swal('Identity Verification Required', $scope.data.info.vouchSent.info, 'info');
                }
                else {
                    swal('Identity Verification Required', $scope.data.info.vouchReceived.info, 'info');
                }

                return;
            }

            if(!$scope.loading.rent) {
                $scope.loading.rent = true;
                $scope.data.rent_number = value;
                let req = {
                    team_id: authService.getTeamId(),
                    user_id: authService.getUserId(),
                    numbers: [value]
                };

                phoneService.rentNumber(req)
                    .then(results => {
                        if (!results.numbers) {
                            swal('Error', 'We were unable to rent the selected number. Please try with a different one.', 'error');
                        } else {

                            $rootScope.loadNumbers();

                            // Give the user a dial-in number also so that they can access the dialer remotely
                            if (!existingNumbers.length) {
                                $scope.__rentDialIn();
                            }

                            if ($rootScope.isFreeTrial()) {
                                $scope.searchNumbers();
                            }
                            else {
                                swal({
                                    title: 'Apply Changes?',
                                    text: "Your phone number was added to your shopping cart. Continue adding numbers or proceed to checkout?",
                                    buttons: ['Keep Adding', 'Checkout'],
                                    dangerMode: true,
                                    closeOnClickOutside: false
                                }).then((confirm) => {
                                    if (!confirm) {
                                        $scope.searchNumbers();
                                    }
                                    else {
                                        $location.path( "/subscriptions" );
                                    }
                                });
                            }
                        }
                    })
                    .catch(err => {
                        swal('Error', 'We were unable to rent the selected number. Please try with a different one.', 'error');
                    })
                    .then(() => {
                        $location.search({});
                        $scope.data.rent_number = null;
                        $scope.loading.rent = false;
                        $scope.$apply();
                    });
            }
        };

        $scope.__rentDialIn = function() {

            let dialInReq = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId()
            };

            phoneService.rentDialIn(dialInReq)
                .then(results => {
                    $rootScope.$broadcast('dialIn.changed');
                });
        }

        $scope.loadUsers = function(skip, limit) {
            $scope.loading.users = true;

            var teamId = authService.getTeamId();
            var userId = authService.getUserId();

            //We're only interested in licenses
            const request = {
                team_id: teamId,
                user_id: userId,
                skip: 0,
                limit: 1
            };

            userService.listUsers(request)
                .then(results => {

                    $scope.data.utilization = results.utilization || {};

                    for (var addonId in $scope.data.utilization) {
                        $scope.data.totalLicenses += $scope.data.utilization[addonId].purchased;
                    }

                    $scope.$apply();
                })
                .catch(err => {
                    swal("Server Error", "We were unable to retrieve users for your account.  Try again later.", "error");
                    console.error(err);
                    $scope.loading.users = false;
                    $scope.$apply();
                });
        };

        $scope.showFirstRentModal = function() {
            $("#firstRentModal").modal('show');
        };

        $scope.showDialerInstructionsModal = function() {
            if($rootScope.isDialerPlan()){
                $("#dialerInstructionsModal").modal('show');
            }
        };

        $scope.saveTeamInfo = function() {

            const updateTeamReq = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                dialin: $scope.getDialinFlags()
            };

            accountService.updateTeam(updateTeamReq)
                .then(results => {
                    $("#dialerInstructionsModal").modal('hide');
                    $scope.$apply();
                })
                .catch(err => {
                    $("#dialerInstructionsModal").modal('hide');
                    $scope.$apply();
                });
        };

        $scope.getDialinFlags = function() {

            let hasEmployees;
            let requirePin;

            switch($scope.data.team_config) {
                case 'noEmployees':
                    hasEmployees = false;
                    requirePin = false;
                    break;
                case 'noSharedPhone':
                    hasEmployees = true;
                    requirePin = false;
                    break;
                case 'pinRequired':
                    hasEmployees = true;
                    requirePin = true;
                    break;
            }

            return {
                has_employees: hasEmployees,
                require_pin: requirePin
            }
        };
        
        $scope.onInit = function() {

            $scope.loadUsers();
            $scope.searchNumbers(null, $cookies.get('checkoutPhoneNumber'));

            $scope.user = $rootScope.user;

            /*
            const number = $cookies.get('selected_phone_number');
            const setupNeeded = $location.search().setup;

            if (number) {
                $cookies.remove('selected_phone_number');
                $scope.data.area_code = number;
                $scope.searchNumbers();
            }

            if(setupNeeded){
                $scope.showFirstRentModal();
            }*/
        };

        if (authService.hasAccount()) {
            $scope.onInit();
        }
        else {
            $scope.$on('auth_complete', function(event, args) {
                $scope.onInit();
            });
        }

    }
    module.exports = PhoneNumberPurchaseController;
})();
