(function () {
    'use strict';
 
let callCowboyApp = angular.module('app', [
    'ui.router', 
    'ngCookies',
    'vcRecaptcha',
    'angular-google-analytics',
    'ngSanitize',
    'angular-peity',
    'ngPasswordStrength',
    'ngAudio',
    // 'daterangepicker',
    'ui.bootstrap',
    'ui.select',
    'xeditable',
    'btorfs.multiselect',
    'cowboymultiselect',
    'datatables',
    'angular-timezone-selector',
    'infinite-scroll-cowboy',
    'ngMessages',
    'dndLists',
    'angularjs-dropdown-multiselect'
]);

//services
let IntercomService = require('./app/services/intercom/intercom.service.js');
    callCowboyApp.factory('intercomService', ['$window', '$rootScope', IntercomService]);
let AuthService = require('./app/services/auth/auth.service.js');
    callCowboyApp.factory('authService', ['$rootScope', '$state', '$timeout', '$location', '$window', '$cookies', 'accountService', 'userService', 'intercomService', 'authLocalService', AuthService]);
let AuthLocalService = require('./app/services/auth/auth.local.service.js');
    callCowboyApp.factory('authLocalService', ['$rootScope', '$state', '$cookies', 'intercomService', AuthLocalService]);

let AccountService = require('./app/services/api/account.service.js');
    callCowboyApp.factory('accountService', ['apiService', '$rootScope', AccountService]);
let AdminService = require('./app/services/api/admin.service.js');
    callCowboyApp.factory('adminService', ['apiService', '$rootScope', AdminService]);
let ApiService = require('./app/services/api/api.service.js');
    callCowboyApp.factory('apiService', ['$rootScope', 'authLocalService', ApiService]);
let ContactService = require('./app/services/api/contact.service.js');
    callCowboyApp.factory('contactService', ['apiService', '$rootScope', ContactService]);
let DispatcherService = require('./app/services/api/dispatcher.service.js');
    callCowboyApp.factory('dispatcherService', ['apiService', '$rootScope', DispatcherService]);
let DncService = require('./app/services/api/dnc.service.js');
    callCowboyApp.factory('dncService', ['apiService', '$rootScope', DncService]);
let ImportService = require('./app/services/api/import.service.js');
    callCowboyApp.factory('importService', ['apiService', '$rootScope', ImportService]);
let MediaService = require('./app/services/api/media.service.js');
    callCowboyApp.factory('mediaService', ['apiService', '$rootScope', MediaService]);
let VoiceService = require('./app/services/api/voice.service.js');
    callCowboyApp.factory('voiceService', ['apiService', '$rootScope', VoiceService]);
let TagsService = require('./app/services/api/tags.service.js');
    callCowboyApp.factory('tagsService', ['authService', 'apiService', '$rootScope', TagsService]);
let PhoneService = require('./app/services/api/phone.service.js');
    callCowboyApp.factory('phoneService', ['apiService', '$rootScope', PhoneService]);
let UserService = require('./app/services/api/user.service.js');
    callCowboyApp.factory('userService', ['apiService', '$rootScope', UserService]);
let DropCowboyApiService = require('./app/services/api/dropcowboy.api.service.js');
    callCowboyApp.factory('dropCowboyApiService', ['$rootScope', DropCowboyApiService]);
let DropCowboyContactService = require('./app/services/api/dropcowboy.contact.service.js');
    callCowboyApp.factory('dropCowboyContactService', ['dropCowboyApiService', '$rootScope', DropCowboyContactService]);
let IntegrationService = require('./app/services/api/integration.service.js');
    callCowboyApp.factory('integrationService', ['authService', 'apiService', '$rootScope', IntegrationService]);
let TasksService = require('./app/services/api/tasks.service.js');
    callCowboyApp.factory('tasksService', ['authService', 'apiService', '$rootScope', TasksService]);
let AutomationService = require('./app/services/api/automation.service.js');
    callCowboyApp.factory('automationService', ['authService', 'apiService', '$rootScope', AutomationService]);
let DropdownmenuPositionService = require('./app/services/helpers/dropdownmenu.position.service.js');
    callCowboyApp.factory('dropdownmenuPositionService', [DropdownmenuPositionService]);
let NotificationToastService = require('./app/services/helpers/notification.toast.service.js');
    callCowboyApp.factory('notificationToastService', [NotificationToastService]);
let StoreSelectedContactService = require('./app/services/helpers/store.selected.contact.service.js');
    callCowboyApp.factory('storeSelectedContactService', ['$rootScope', StoreSelectedContactService]);
let StoreDragDropContactBucketService = require('./app/services/helpers/store.drag.drop.contact.bucket.service.js');
    callCowboyApp.factory('storeDragDropContactBucketService', ['$rootScope', StoreDragDropContactBucketService]);
let StoreSelectedTaskService = require('./app/services/helpers/store.selected.task.service.js');
    callCowboyApp.factory('storeSelectedTaskService', [StoreSelectedTaskService]);
let FaxService = require('./app/services/api/fax.service.js');
    callCowboyApp.factory('faxService', ['apiService', '$rootScope', FaxService]);
let RtcSettingsService = require('./app/services/webRTC/settings.service.js');
    callCowboyApp.factory('rtcSettingsService', ['$rootScope', RtcSettingsService]);
let RtcSipService = require('./app/services/webRTC/jsSIP.service.js');
    callCowboyApp.factory('rtcSipService', ['$window', '$rootScope', 'rtcSettingsService', RtcSipService]);
let CallService = require('./app/services/webRTC/call.service.js');
    callCowboyApp.factory('callService', ['$rootScope', 'rtcSipService', CallService]);
let DomainService = require('./app/services/api/domain.service.js');
    callCowboyApp.factory('domainService', ['apiService', '$rootScope', DomainService])
let CampaignService = require('./app/services/api/campaign.service');
    callCowboyApp.factory('campaignService', ['apiService', '$rootScope', CampaignService]);
let EmailService = require('./app/services/api/email.service');
    callCowboyApp.factory('emailService', ['authService', 'apiService', '$rootScope', EmailService]);
let BoardsService = require('./app/services/api/boards.service');
    callCowboyApp.factory('boardsService', ['authService', 'apiService', '$rootScope', BoardsService]);
let TemplateService = require('./app/services/api/template.service');
    callCowboyApp.factory('templateService', ['apiService', '$rootScope', TemplateService]);
let MailboxesService = require('./app/services/api/mailboxes.service');
    callCowboyApp.factory('mailboxesService', ['apiService', '$rootScope', MailboxesService]);
let LongJobService = require('./app/services/api/longjob.service.js');
    callCowboyApp.factory('longJobService', ['apiService', '$rootScope', LongJobService]);
let GeneralHelperService = require('./app/services/helpers/general.helper.service.js');
    callCowboyApp.factory('generalHelperService', [GeneralHelperService]);

//components
let HeaderController = require('./app/views/_components/header/header.js');
    callCowboyApp.controller('headerController', ['authService', '$rootScope', '$scope', '$window', '$location', 'userService', HeaderController]);
    callCowboyApp.component('headerComponent', {templateUrl: './views/_components/header/header.html', controller: 'headerController'});
let AuthHeaderController = require('./app/views/_components/auth-header/auth-header.js');
    callCowboyApp.controller('authHeaderController', ['$rootScope', '$scope', '$window', '$state', AuthHeaderController]);
    callCowboyApp.component('authHeaderComponent', {templateUrl: './views/_components/auth-header/auth-header.html', controller: 'authHeaderController'});
let LeftSidebarController = require('./app/views/_components/left-sidebar/left-sidebar.js');
    callCowboyApp.controller('leftSidebarController', ['authService', 'phoneService', 'userService', '$rootScope', '$scope', '$window', '$location', 'tasksService', LeftSidebarController]);
    callCowboyApp.component('leftSidebarComponent', {templateUrl: './views/_components/left-sidebar/left-sidebar.html', controller: 'leftSidebarController'});
let RightSidebarController = require('./app/views/_components/right-sidebar/right-sidebar.js');
    callCowboyApp.controller('rightSidebarController', ['authService', '$rootScope', '$scope', '$state', '$window', 'intercomService', RightSidebarController]);
    callCowboyApp.component('rightSidebarComponent', {templateUrl: './views/_components/right-sidebar/right-sidebar.html', controller: 'rightSidebarController'});
let BreadCrumbController = require('./app/views/_components/bread-crumb/bread-crumb.js');
    callCowboyApp.controller('breadCrumbController', ['authService', '$rootScope', '$scope', '$location', 'generalHelperService', BreadCrumbController]);
    callCowboyApp.component('breadCrumbComponent', {templateUrl: './views/_components/bread-crumb/bread-crumb.html', controller: 'breadCrumbController'});
let FooterController = require('./app/views/_components/footer/footer.js');
    callCowboyApp.controller('footerController', ['authService', '$rootScope', '$scope', '$window', FooterController]);
    callCowboyApp.component('footerComponent', {templateUrl: './views/_components/footer/footer.html', controller: 'footerController'});
let ViewComponentController = require('./app/views/_components/view-component/view-component.js');
    callCowboyApp.controller('viewComponentController', ['authService', '$rootScope', '$scope', '$window', ViewComponentController]);
    callCowboyApp.component('viewComponent', {templateUrl: './views/_components/view-component/view-component.html', controller: 'viewComponentController'});
let ModalController = require('./app/views/_components/modal/modal.js');
    callCowboyApp.controller('modalController', ['authService', 'callService', '$rootScope', '$scope', '$window', ModalController]);
    callCowboyApp.component('modalComponent', {templateUrl: './views/_components/modal/modal.html', controller: 'modalController'});
let DialingListModalController = require('./app/views/_components/modal/dialingListModal.js');
    callCowboyApp.controller('dialingListModalController', ['authService', 'phoneService', '$rootScope', '$scope', '$window', DialingListModalController]);
    callCowboyApp.component('dialingListModalComponent', {templateUrl: './views/_components/modal/dialingListModal.html', controller: 'dialingListModalController'});
let AdHocCallModalController = require('./app/views/_components/modal/adHocCallModal.js');
    callCowboyApp.controller('adHocCallModalController', ['authService', 'phoneService', '$rootScope', '$scope', '$window', AdHocCallModalController]);
    callCowboyApp.component('adhocCallModalComponent', {templateUrl: './views/_components/modal/adHocCallModal.html', controller: 'adHocCallModalController'});
let UserNumbersModalController = require('./app/views/_components/modal/userNumbersModal.js');
    callCowboyApp.controller('userNumbersModalController', ['authService', 'userService', '$rootScope', '$scope', '$location', '$window', UserNumbersModalController]);
    callCowboyApp.component('userNumbersModalComponent', {templateUrl: './views/_components/modal/userNumbersModal.html', controller: 'userNumbersModalController'});
let PlanUpdateModalController = require('./app/views/_components/modal/planUpdateModal.js');
    callCowboyApp.controller('planUpdateModalController', ['authService', 'accountService', 'userService', '$rootScope', '$scope', '$location', '$window', PlanUpdateModalController]);
    callCowboyApp.component('planUpdateModalComponent', {templateUrl: './views/_components/modal/planUpdateModal.html', controller: 'planUpdateModalController'});
let WelcomeModalController = require('./app/views/_components/modal/welcomeModal.js');
    callCowboyApp.controller('welcomeModalController', ['authService', 'userService', 'accountService', 'phoneService', 'dispatcherService', '$rootScope', '$scope', '$location', '$interval', '$window', WelcomeModalController]);
    callCowboyApp.component('welcomeModalComponent', {templateUrl: './views/_components/modal/welcomeModal.html', controller: 'welcomeModalController'});
let ConnectSmtpModalController = require('./app/views/_components/modal/connectSmtpModal.js');
    callCowboyApp.controller('connectSmtpModalController', ['$rootScope', '$scope', 'integrationService' , ConnectSmtpModalController]);
    callCowboyApp.component('connectSmtpModalComponent', {templateUrl: './views/_components/modal/connectSmtpModal.html', controller: 'connectSmtpModalController'});
let ConnectDropCowboyModalController = require('./app/views/_components/modal/connectDropCowboyModal.js');
    callCowboyApp.controller('connectDropCowboyModalController', ['$rootScope', '$scope', 'integrationService' , ConnectDropCowboyModalController]);
    callCowboyApp.component('connectDropCowboyModalComponent', {templateUrl: './views/_components/modal/connectDropCowboyModal.html', controller: 'connectDropCowboyModalController'});
let CreateWebhookModalController = require('./app/views/_components/modal/createWebhookModal.js');
    callCowboyApp.controller('createWebhookModalController', ['$rootScope', '$scope', 'integrationService', 'automationService' , CreateWebhookModalController]);
    callCowboyApp.component('createWebhookModalComponent', {templateUrl: './views/_components/modal/createWebhookModal.html', controller: 'createWebhookModalController'});
let SendRvmModalController = require('./app/views/_components/modal/sendRvmModal.js');
    callCowboyApp.controller('sendRvmModalController', ['$rootScope', '$scope', 'integrationService', 'phoneService', 'mediaService', 'authService', SendRvmModalController]);
    callCowboyApp.component('sendRvmModalComponent', {templateUrl: './views/_components/modal/sendRvmModal.html', controller: 'sendRvmModalController'});
let SyncContactsModalController = require('./app/views/_components/modal/syncContactsModal.js');
    callCowboyApp.controller('syncContactsModalController', ['$rootScope', '$scope', 'integrationService', SyncContactsModalController]);
    callCowboyApp.component('syncContactsModalComponent', {templateUrl: './views/_components/modal/syncContactsModal.html', controller: 'syncContactsModalController'});
let IncomingOutgoingCallsController = require('./app/views/_components/incoming-outgoing-calls/incoming-outgoing-calls.js');
    callCowboyApp.controller('incomingOutgoingCallsController', ['authService', 'contactService', 'dncService', 'phoneService', 'callService', 'dispatcherService', 'notificationToastService', '$scope', '$rootScope', '$location', '$window', '$cookies', '$interval', '$state', 'tagsService', 'generalHelperService', IncomingOutgoingCallsController]);
    callCowboyApp.component('incomingOutgoingCallsComponent', {templateUrl: './views/_components/incoming-outgoing-calls/incoming-outgoing-calls.html', controller: 'incomingOutgoingCallsController'});
let CancelModalController = require('./app/views/_components/modal/cancelModal.js');
    callCowboyApp.controller('cancelModalController', ['authService', 'accountService', '$rootScope', '$scope', '$location', '$window', CancelModalController]);
    callCowboyApp.component('cancelModalComponent', {templateUrl: './views/_components/modal/cancelModal.html', controller: 'cancelModalController'});
let AddEditContactModalController = require('./app/views/_components/modal/addEditContactModal.js');
    callCowboyApp.controller('addEditContactModalController', ['$rootScope', '$scope', 'authService', 'contactService', '$http', 'generalHelperService', AddEditContactModalController]);
    callCowboyApp.component('addEditContactModalComponent', {templateUrl: './views/_components/modal/addEditContactModal.html', controller: 'addEditContactModalController'});
let AddEditUserModalController = require('./app/views/_components/modal/addEditUserModal.js');
    callCowboyApp.controller('addEditUserModalController', ['$rootScope', '$scope', 'authService', 'userService', 'contactService', 'integrationService', '$http', AddEditUserModalController]);
    callCowboyApp.component('addEditUserModalComponent', {templateUrl: './views/_components/modal/addEditUserModal.html', controller: 'addEditUserModalController'});
let AddEditTeamModalController = require('./app/views/_components/modal/addEditTeamModal.js');
    callCowboyApp.controller('addEditTeamModalController', ['$rootScope', '$scope', 'authService', 'userService', AddEditTeamModalController]);
    callCowboyApp.component('addEditTeamModalComponent', {templateUrl: './views/_components/modal/addEditTeamModal.html', controller: 'addEditTeamModalController'});
let CustomShoppingCartModalController = require('./app/views/_components/modal/customShoppingCartModal.js');
    callCowboyApp.controller('customShoppingCartModalController', ['$rootScope', '$scope', 'authService', 'userService', 'contactService', '$http', CustomShoppingCartModalController]);
    callCowboyApp.component('customShoppingCartModalComponent', {templateUrl: './views/_components/modal/customShoppingCartModal.html', controller: 'customShoppingCartModalController'});
let AudioPlayerController = require('./app/views/_components/audio-player/audio-player.js');
    callCowboyApp.controller('audioPlayerController', ['authService', '$rootScope', '$scope', AudioPlayerController]);
    callCowboyApp.component('audioPlayerComponent', {templateUrl: './views/_components/audio-player/audio-player.html',
        bindings: { recordId: '<', currentOpenedPlayerId: '<', recordIndex: '<', audio: '<', recordSrc: '<', pref: '@', recordType: '@' }, controllerAs: 'vm', controller: 'audioPlayerController'});
let FaxModalController = require('./app/views/_components/modal/faxModal.js');
    callCowboyApp.controller('faxModalController', ['authService', 'contactService', 'faxService', '$rootScope', '$scope', '$window', FaxModalController]);
    callCowboyApp.component('faxModalComponent', {templateUrl: './views/_components/modal/faxModal.html', controller: 'faxModalController'});
let WebRtcSettingsModalController = require('./app/views/_components/modal/webRtcSettingsModal.js');
    callCowboyApp.controller('webRtcSettingsModalController', ['authService', 'accountService', '$rootScope', '$scope', '$window', 'rtcSettingsService', 'userService', WebRtcSettingsModalController]);
    callCowboyApp.component('webRtcSettingsModalComponent', {templateUrl: './views/_components/modal/webRtcSettingsModal.html', controller: 'webRtcSettingsModalController'});
let CreateDomainModalController = require('./app/views/_components/modal/createDomainModal.js');
    callCowboyApp.controller('createDomainModalController', ['authService', 'domainService', '$rootScope', '$scope', '$http', CreateDomainModalController])
    callCowboyApp.component('createDomainModalComponent', {templateUrl: './views/_components/modal/createDomainModal.html', controller: 'createDomainModalController'})
let TasksLeftSidebarController = require('./app/views/inbox/tasks-left-sidebar.js');
    callCowboyApp.controller('tasksLeftSidebarController', ['$rootScope', '$scope', '$location', '$window', 'tasksService', 'authService', '$state', 'callService', 'phoneService', 'notificationToastService', 'storeSelectedTaskService', 'generalHelperService', 'contactService', TasksLeftSidebarController]);
    callCowboyApp.component('tasksLeftSidebarComponent', {templateUrl: './views/inbox/tasks-left-sidebar.html', controller: 'tasksLeftSidebarController'});
let ConversationController = require('./app/views/inbox/conversation.js');
    callCowboyApp.controller('conversationController', ['$q','$rootScope', '$scope', '$location', '$window', '$attrs', 'tasksService', 'authService', 'contactService', 'userService', 'phoneService', 'callService', 'dispatcherService', 'notificationToastService', 'emailService', 'faxService', 'templateService', 'storeSelectedContactService', ConversationController]);
    callCowboyApp.component('conversationComponent', {templateUrl: './views/inbox/conversation.html', controller: 'conversationController'});
let TextAreaController = require('./app/views/inbox/text-area.js');
    callCowboyApp.controller('textAreaController', ['$q','$rootScope', '$scope', '$location', '$window', '$attrs', 'tasksService', 'authService', 'contactService', 'userService', 'phoneService', 'callService', 'dispatcherService', 'notificationToastService', 'emailService', 'faxService', 'templateService', 'mailboxesService', 'accountService', TextAreaController]);
    callCowboyApp.component('textAreaComponent', {templateUrl: './views/inbox/text-area.html', bindings: { contactId: '<', task: '<', conversationPage: '<', timelineItemToAnswerEmail: '<', timelineItemToAnswerSms: '<', tabMode: '<'}, controller: 'textAreaController'});
let InboxRightSidebarController = require('./app/views/inbox/inbox-right-sidebar.js');
    callCowboyApp.controller('inboxRightSidebarController', ['$rootScope', '$scope', '$location', 'authService', 'tagsService', 'contactService', 'storeSelectedTaskService', InboxRightSidebarController]);
    callCowboyApp.component('inboxRightSidebarComponent', {templateUrl: './views/inbox/inbox-right-sidebar.html', controller: 'inboxRightSidebarController'});
let UpsellModalController = require('./app/views/_components/modal/upsellModal.js');
    callCowboyApp.controller('upsellModalController', ['authService', 'userService', 'accountService', '$rootScope', '$scope', '$location', '$window', '$state', UpsellModalController]);
    callCowboyApp.component('upsellModalComponent', {templateUrl: './views/_components/modal/upsellModal.html', controller: 'upsellModalController'});
let TrialRemainingModalController = require('./app/views/_components/modal/trialRemainingModal.js');
    callCowboyApp.controller('trialRemainingModalController', ['authService', '$rootScope', '$scope', '$location', TrialRemainingModalController]);
    callCowboyApp.component('trialRemainingModalComponent', {templateUrl: './views/_components/modal/trialRemainingModal.html', controller: 'trialRemainingModalController'});
let TrialEndedModalController = require('./app/views/_components/modal/trialEndedModal.js');
    callCowboyApp.controller('trialEndedModalController', ['$scope', '$location', TrialEndedModalController]);
    callCowboyApp.component('trialEndedModalComponent', {templateUrl: './views/_components/modal/trialEndedModal.html', controller: 'trialEndedModalController'});
let ContactDetailsProfileController = require('./app/views/contacts/contact-details-profile.js');
    callCowboyApp.controller('contactDetailsProfileController', ['$rootScope', '$scope', 'authService', 'contactService', 'phoneService', 'dispatcherService', '$window', '$location', '$cookies', 'integrationService', 'faxService', 'tagsService', 'tasksService', 'notificationToastService', 'storeSelectedContactService', 'generalHelperService', ContactDetailsProfileController]);
    callCowboyApp.component('contactDetailsProfileComponent', {templateUrl: './views/contacts/contact-details-profile.html', controller: 'contactDetailsProfileController'});
let CartModalController = require('./app/views/_components/modal/cartModal.js');
    callCowboyApp.controller('cartModalController', ['$rootScope', '$scope', 'authService', 'accountService', '$location', CartModalController]);
    callCowboyApp.component('cartModalComponent', {templateUrl: './views/_components/modal/cartModal.html', controller: 'cartModalController'});
let AddFundsModalController = require('./app/views/_components/modal/addFunds.js');
    callCowboyApp.controller('addFundsModalController', ['$rootScope', '$scope', 'authService', 'accountService', 'integrationService', '$cookies', AddFundsModalController]);
    callCowboyApp.component('addFundsModalComponent', {templateUrl: './views/_components/modal/addFunds.html', controller: 'addFundsModalController'});
let FreeTrialAddFundsModalController = require('./app/views/_components/modal/freeTrialAddFunds.js');
    callCowboyApp.controller('freeTrialAddFundsModalController', ['$rootScope', '$scope', 'authService', 'accountService', FreeTrialAddFundsModalController]);
    callCowboyApp.component('freeTrialAddFundsModalComponent', {templateUrl: './views/_components/modal/freeTrialAddFunds.html', controller: 'freeTrialAddFundsModalController'});
let AddNumbersModalController = require('./app/views/_components/modal/addNumbersModal.js');
    callCowboyApp.controller('addNumbersModalController', ['$rootScope', '$scope', '$window', '$location', '$cookies', 'authService', 'phoneService', 'accountService', 'userService', AddNumbersModalController]);
    callCowboyApp.component('addNumbersModalComponent', {templateUrl: './views/_components/modal/addNumbersModal.html', controller: 'addNumbersModalController'});
let UpdateShopifyPolicyModalController = require('./app/views/_components/modal/updateShopifyPolicyModal.js');
    callCowboyApp.controller('updateShopifyPolicyModalController', ['$rootScope', '$scope', '$window', '$cookies', 'authService', 'integrationService', UpdateShopifyPolicyModalController]);
    callCowboyApp.component('updateShopifyPolicyModalComponent', {templateUrl: './views/_components/modal/updateShopifyPolicyModal.html', controller: 'updateShopifyPolicyModalController'});
let TimeAnalysisChartController = require('./app/views/_components/time-analysis-chart/time-analysis-chart.js');
    callCowboyApp.controller('timeAnalysisChartController', ['$scope', TimeAnalysisChartController]);
    callCowboyApp.component('timeAnalysisChartComponent', {templateUrl: './views/_components/time-analysis-chart/time-analysis-chart.html',
        bindings: { name: '@', type: '<', chartData: '<', loading: '<' }, controllerAs: 'vm', controller: 'timeAnalysisChartController'});
let MailboxesController = require('./app/views/_components/mailboxes/mailboxes.js');
    callCowboyApp.controller('mailboxesController', ['$rootScope', '$scope', 'authService', 'domainService', 'mailboxesService', MailboxesController]);
    callCowboyApp.component('mailboxesComponent', {templateUrl: './views/_components/mailboxes/mailboxes.html', bindings: { type: '<', userId: '<', userGroupId: '<', hideLabel: '<'}, controller: 'mailboxesController'});
let BucketListController = require('./app/views/buckets/bucket-list.js');
    callCowboyApp.controller('bucketListController', ['$rootScope', '$scope', '$location', 'authService', 'contactService', 'notificationToastService', 'boardsService', 'storeDragDropContactBucketService', BucketListController]);
    callCowboyApp.component('bucketListComponent', {templateUrl: './views/buckets/bucket-list.html', bindings: { list: '<', tags: '<', lists: '<', allLists: '<', search: '<', board: '<' }, controller: 'bucketListController'});

//placeholders
let TimelineVoicemailPlaceholderListItemController = require('./app/views/_components/placeholders/timeline-type-placeholder-list-item/timeline-voicemail-placeholder-list-item.js');
    callCowboyApp.controller('timelineVoicemailPlaceholderListItemController', ['authService', TimelineVoicemailPlaceholderListItemController]);
    callCowboyApp.component('timelineVoicemailPlaceholderListItemComponent', {templateUrl: './views/_components/placeholders/timeline-type-placeholder-list-item/timeline-voicemail-placeholder-list-item.html', controller: 'timelineVoicemailPlaceholderListItemController'});
let TimelineCallPlaceholderListItemController = require('./app/views/_components/placeholders/timeline-type-placeholder-list-item/timeline-call-placeholder-list-item.js');
    callCowboyApp.controller('timelineCallPlaceholderListItemController', ['authService', TimelineCallPlaceholderListItemController]);
    callCowboyApp.component('timelineCallPlaceholderListItemComponent', {templateUrl: './views/_components/placeholders/timeline-type-placeholder-list-item/timeline-call-placeholder-list-item.html', controller: 'timelineCallPlaceholderListItemController'});
let TimelineTextPlaceholderListItemController = require('./app/views/_components/placeholders/timeline-type-placeholder-list-item/timeline-text-placeholder-list-item.js');
    callCowboyApp.controller('timelineTextPlaceholderListItemController', ['authService', TimelineTextPlaceholderListItemController]);
    callCowboyApp.component('timelineTextPlaceholderListItemComponent', {templateUrl: './views/_components/placeholders/timeline-type-placeholder-list-item/timeline-text-placeholder-list-item.html', controller: 'timelineTextPlaceholderListItemController'});
let CampaignsTypePlaceholderListItemController = require('./app/views/_components/placeholders/campaigns-type-placeholder-list-item/campaigns-type-placeholder-list-item.js');
    callCowboyApp.controller('campaignsTypePlaceholderListItemController', ['authService', CampaignsTypePlaceholderListItemController]);
    callCowboyApp.component('campaignsTypePlaceholderListItemComponent', {templateUrl: './views/_components/placeholders/campaigns-type-placeholder-list-item/campaigns-type-placeholder-list-item.html', controller: 'campaignsTypePlaceholderListItemController'});
let RecentSmsTypePlaceholderListItemController = require('./app/views/_components/placeholders/recent-sms-type-placeholder-list-item/recent-sms-type-placeholder-list-item.js');
    callCowboyApp.controller('recentSmsTypePlaceholderListItemController', ['authService', RecentSmsTypePlaceholderListItemController]);
    callCowboyApp.component('recentSmsTypePlaceholderListItemComponent', {templateUrl: './views/_components/placeholders/recent-sms-type-placeholder-list-item/recent-sms-type-placeholder-list-item.html', controller: 'recentSmsTypePlaceholderListItemController'});
let RulesTypePlaceholderListItemController = require('./app/views/_components/placeholders/rules-type-placeholder-list-item/rules-type-placeholder-list-item.js');
    callCowboyApp.controller('rulesTypePlaceholderListItemController', ['authService', RulesTypePlaceholderListItemController]);
    callCowboyApp.component('rulesTypePlaceholderListItemComponent', {templateUrl: './views/_components/placeholders/rules-type-placeholder-list-item/rules-type-placeholder-list-item.html', controller: 'rulesTypePlaceholderListItemController'});
let VoicemailTypePlaceholderListItemController = require('./app/views/_components/placeholders/voicemail-type-placeholder-list-item/voicemail-type-placeholder-list-item.js');
    callCowboyApp.controller('voicemailTypePlaceholderListItemController', ['authService', VoicemailTypePlaceholderListItemController]);
    callCowboyApp.component('voicemailTypePlaceholderListItemComponent', {templateUrl: './views/_components/placeholders/voicemail-type-placeholder-list-item/voicemail-type-placeholder-list-item.html', controller: 'voicemailTypePlaceholderListItemController'});
let ListsTypePlaceholderListItemController = require('./app/views/_components/placeholders/lists-type-placeholder-list-item/lists-type-placeholder-list-item.js');
    callCowboyApp.controller('listsTypePlaceholderListItemController', ['authService', ListsTypePlaceholderListItemController]);
    callCowboyApp.component('listsTypePlaceholderListItemComponent', {templateUrl: './views/_components/placeholders/lists-type-placeholder-list-item/lists-type-placeholder-list-item.html', controller: 'listsTypePlaceholderListItemController'});
let WebhookPlaceholderListItemController = require('./app/views/_components/placeholders/webhook-placeholder-list-item/webhook-placeholder-list-item.js');
    callCowboyApp.controller('webhookPlaceholderListItemController', ['authService', WebhookPlaceholderListItemController]);
    callCowboyApp.component('webhookPlaceholderListItemComponent', {templateUrl: './views/_components/placeholders/webhook-placeholder-list-item/webhook-placeholder-list-item.html', controller: 'webhookPlaceholderListItemController'});
let ContactTimelineTypePlaceholderListItemController = require('./app/views/_components/placeholders/contact-timeline-type-placeholder-list-item/contact-timeline-type-placeholder-list-item.js');
    callCowboyApp.controller('contactTimelineTypePlaceholderListItemController', ['authService', ContactTimelineTypePlaceholderListItemController]);
    callCowboyApp.component('contactTimelineTypePlaceholderListItemComponent', {templateUrl: './views/_components/placeholders/contact-timeline-type-placeholder-list-item/contact-timeline-type-placeholder-list-item.html', controller: 'contactTimelineTypePlaceholderListItemController'});
let NoteTypePlaceholderListItemController = require('./app/views/_components/placeholders/note-type-placeholder-list-item/note-type-placeholder-list-item.js');
    callCowboyApp.controller('noteTypePlaceholderListItemController', ['authService', NoteTypePlaceholderListItemController]);
    callCowboyApp.component('noteTypePlaceholderListItemComponent', {templateUrl: './views/_components/placeholders/note-type-placeholder-list-item/note-type-placeholder-list-item.html', controller: 'noteTypePlaceholderListItemController'});
let DocumentTypePlaceholderListItemController = require('./app/views/_components/placeholders/document-type-placeholder-list-item/document-type-placeholder-list-item.js');
    callCowboyApp.controller('documentTypePlaceholderListItemController', ['authService', DocumentTypePlaceholderListItemController]);
    callCowboyApp.component('documentTypePlaceholderListItemComponent', {templateUrl: './views/_components/placeholders/document-type-placeholder-list-item/document-type-placeholder-list-item.html', controller: 'documentTypePlaceholderListItemController'});
let WebformTypePlaceholderListItemController = require('./app/views/_components/placeholders/webform-type-placeholder-list-item/webform-type-placeholder-list-item.js');
    callCowboyApp.controller('webformTypePlaceholderListItemController', ['authService', WebformTypePlaceholderListItemController]);
    callCowboyApp.component('webformTypePlaceholderListItemComponent', {templateUrl: './views/_components/placeholders/webform-type-placeholder-list-item/webform-type-placeholder-list-item.html', controller: 'webformTypePlaceholderListItemController'});
let CustomFieldsTypePlaceholderListItemController = require('./app/views/_components/placeholders/custom-fields-type-placeholder-list-item/custom-fields-type-placeholder-list-item.js');
    callCowboyApp.controller('customFieldsTypePlaceholderListItemController', ['authService', CustomFieldsTypePlaceholderListItemController]);
    callCowboyApp.component('customFieldsTypePlaceholderListItemComponent', {templateUrl: './views/_components/placeholders/custom-fields-type-placeholder-list-item/custom-fields-type-placeholder-list-item.html', controller: 'customFieldsTypePlaceholderListItemController'});
let CreateWebformTypePlaceholderListItemController = require('./app/views/_components/placeholders/create-webform-type-placeholder-list-item/create-webform-type-placeholder-list-item.js');
    callCowboyApp.controller('createWebformTypePlaceholderListItemController', ['authService', CreateWebformTypePlaceholderListItemController]);
    callCowboyApp.component('createWebformTypePlaceholderListItemComponent', {templateUrl: './views/_components/placeholders/create-webform-type-placeholder-list-item/create-webform-type-placeholder-list-item.html', controller: 'createWebformTypePlaceholderListItemController'});
let FaxesTypePlaceholderListItemController = require('./app/views/_components/placeholders/faxes-type-placeholder-list-item/faxes-type-placeholder-list-item.js');
    callCowboyApp.controller('faxesTypePlaceholderListItemController', ['authService', FaxesTypePlaceholderListItemController]);
    callCowboyApp.component('faxesTypePlaceholderListItemComponent', {templateUrl: './views/_components/placeholders/faxes-type-placeholder-list-item/faxes-type-placeholder-list-item.html', controller: 'faxesTypePlaceholderListItemController'});
let AutomationTypePlaceholderListItemController = require('./app/views/_components/placeholders/automation-type-placeholder-list-item/automation-type-placeholder-list-item.js');
    callCowboyApp.controller('automationTypePlaceholderListItemController', ['authService', AutomationTypePlaceholderListItemController]);
    callCowboyApp.component('automationTypePlaceholderListItemComponent', {templateUrl: './views/_components/placeholders/automation-type-placeholder-list-item/automation-type-placeholder-list-item.html', controller: 'automationTypePlaceholderListItemController'});
let AutomationConfigTypePlaceholderListItemController = require('./app/views/_components/placeholders/automation-config-type-placeholder-list-item/automation-config-type-placeholder-list-item.js');
    callCowboyApp.controller('automationConfigTypePlaceholderListItemController', ['authService', AutomationConfigTypePlaceholderListItemController]);
    callCowboyApp.component('automationConfigTypePlaceholderListItemComponent', {templateUrl: './views/_components/placeholders/automation-config-type-placeholder-list-item/automation-config-type-placeholder-list-item.html', controller: 'automationConfigTypePlaceholderListItemController'});

//controllers
let MainWrapperController = require('./app/views/_components/main-wrapper/main-wrapper.js');
    callCowboyApp.controller('mainWrapperController', ['authService', '$rootScope', '$scope', '$window', '$state', MainWrapperController]);
let EmptyWrapperController = require('./app/views/_components/empty-wrapper/empty-wrapper.js');
    callCowboyApp.controller('emptyWrapperController', ['authService', '$rootScope', '$scope', '$state', EmptyWrapperController]);
let SigninController = require('./app/views/auth/signin.js');
    callCowboyApp.controller('signinController', ['$rootScope', '$scope', '$location', 'authService', 'accountService', '$cookies', '$interval', '$state', SigninController]);
let PasswordResetController = require('./app/views/auth/forgot.js');
    callCowboyApp.controller('passwordResetController', ['$rootScope', '$state', '$scope', '$location', 'authService', 'accountService', PasswordResetController]);
let SignupStepsController = require('./app/views/auth/new/signup-step.js');
    callCowboyApp.controller('signupStepsController', ['$rootScope', '$state', '$scope', '$location', '$interval', '$cookies', '$http', 'authService', 'accountService', '$window', 'generalHelperService', SignupStepsController]);
let DashController = require('./app/views/dash/dash.js');
    callCowboyApp.controller('dashController', ['$rootScope', '$scope', '$location', '$interval', 'authService', 'phoneService', '$window', DashController]);
let MyDashboardController = require('./app/views/my-dashboard/my-dashboard.js');
    callCowboyApp.controller('myDashboardController', ['$rootScope', '$scope', '$location', '$interval', 'authService', 'userService', 'tasksService', 'generalHelperService', 'contactService', 'phoneService', 'campaignService', 'dncService', 'boardsService', 'mediaService', 'voiceService', 'templateService', 'domainService', 'automationService', 'tagsService', '$window', MyDashboardController]);
    let ActivityDashController = require('./app/views/activity/activity.js');
    callCowboyApp.controller('activityDashController', ['$rootScope', '$scope', '$location', '$interval', '$window', 'authService', 'phoneService', 'userService', 'contactService', ActivityDashController]);
let CallTrackingDashController = require('./app/views/tracking/tracking.js');
    callCowboyApp.controller('callTrackingDashController', ['$rootScope', '$scope', '$location', '$interval', 'authService', 'phoneService', CallTrackingDashController]);
let CampaignsController = require('./app/views/campaigns/campaigns.js');
    callCowboyApp.controller('campaignsController', ['authService', '$rootScope', '$scope', '$window', 'campaignService', '$location', 'dropdownmenuPositionService', CampaignsController]);
let CreateCampaignController = require('./app/views/campaigns/create-campaign.js');
    callCowboyApp.controller('createCampaignController', ['authService', '$rootScope', '$scope', '$window', '$interval', 'campaignService', 'contactService', '$location', 'voiceService', 'mediaService', 'phoneService', 'accountService', 'longJobService', 'generalHelperService', CreateCampaignController]);
let CampaignResultsController = require('./app/views/campaigns/campaign-results');
    callCowboyApp.controller('campaignResultsController', ['$rootScope', '$scope', '$location', 'authService', 'campaignService', '$window', '$interval', CampaignResultsController]);
let AdminController = require('./app/views/admin/admin.js');
    callCowboyApp.controller('adminController', ['$rootScope', '$scope', 'authService', 'adminService', 'accountService', AdminController]);
let HowItWorksController = require('./app/views/how-it-works/how-it-works.js');
    callCowboyApp.controller('howItWorksController', ['authService', '$rootScope', '$scope', HowItWorksController]);
let APIController = require('./app/views/api/api.js');
    callCowboyApp.controller('apiController', ['$scope', 'authService', 'accountService', '$rootScope', '$window', 'integrationService', APIController]);
let CampaignWizardController = require('./app/views/campaign-wizard/campaign-wizard.js');
    callCowboyApp.controller('campaignWizardController', ['authService', '$rootScope', '$scope', CampaignWizardController]);
let OutcomesController = require('./app/views/outcomes/outcomes.js');
    callCowboyApp.controller('outcomesController', ['authService', '$rootScope', '$scope', '$window', OutcomesController]);
let ContactsController = require('./app/views/contacts/contacts.js');
    callCowboyApp.controller('contactsController', ['$rootScope', '$scope', 'authService', 'contactService', 'dncService', 'phoneService', 'accountService', 'dispatcherService', 'mediaService', 'dropCowboyContactService', 'notificationToastService', '$window', '$location', '$interval', '$cookies', '$http', 'integrationService', '$uibModal', 'tagsService', 'generalHelperService', ContactsController]);
let ListsController = require('./app/views/contacts/lists.js');
    callCowboyApp.controller('listsController', ['authService', '$scope', '$rootScope', 'contactService', 'dispatcherService', 'importService', 'phoneService', '$window', '$interval', '$location', '$http', '$cookies', 'integrationService', 'userService', 'notificationToastService', 'generalHelperService', ListsController]);
let ContactDetailsController = require('./app/views/contacts/contact-details.js');
    callCowboyApp.controller('contactDetailsController', ['$rootScope', '$scope', 'callService', 'authService', 'contactService', 'phoneService', 'dispatcherService',   '$window', '$location', '$cookies', 'integrationService', 'faxService', 'tagsService', 'tasksService', 'dropdownmenuPositionService', 'storeSelectedContactService', 'notificationToastService', ContactDetailsController]);
let WebformsController = require('./app/views/webforms/webforms.js');
    callCowboyApp.controller('webformsController', ['authService', '$scope', '$rootScope', 'contactService', '$window', '$location', WebformsController]);
let CustomFieldsController = require('./app/views/custom-fields/custom-fields');
    callCowboyApp.controller('customFieldsController', ['authService', '$scope', '$rootScope', 'contactService', '$window', CustomFieldsController]);
let WebformDetailsController = require('./app/views/webforms/webform-details.js');
    callCowboyApp.controller('webformDetailsController', ['$rootScope', '$scope', 'authService', 'contactService', '$location', '$sce', '$window', WebformDetailsController]);
let WebformEditController = require('./app/views/webforms/webform-edit.js');
    callCowboyApp.controller('webformEditController', ['$rootScope', '$scope', 'authService', 'contactService', '$location', WebformEditController]);
let DncController = require('./app/views/dnc/dnc.js');
    callCowboyApp.controller('dncController', ['$rootScope', '$scope', 'authService', 'importService', 'dncService', 'notificationToastService', '$window', '$interval', '$http', DncController]);
let PhoneNumbersController = require('./app/views/phone-numbers/phone-numbers.js');
    callCowboyApp.controller('phoneNumbersController', ['$rootScope', '$scope', '$location', 'authService', 'phoneService', 'accountService', '$window', 'generalHelperService', PhoneNumbersController]);
let PhoneNumberPurchaseController = require('./app/views/phone-numbers/phone-number-purchase.js');
    callCowboyApp.controller('phoneNumberPurchaseController', ['$rootScope', '$scope', '$window', '$location', '$cookies', 'authService', 'phoneService', 'accountService', 'userService', PhoneNumberPurchaseController]);
let PhoneTreeIVRcontroller = require('./app/views/phone-tree-ivr/phone-tree-ivr.js');
    callCowboyApp.controller('phoneTreeIVRcontroller', ['$rootScope', '$scope', '$location', '$window', 'authService', 'phoneService', 'notificationToastService', PhoneTreeIVRcontroller]);
let AutomationController = require('./app/views/automation/ipaas-automation.js');
    callCowboyApp.controller('automationController', ['$rootScope', '$scope', '$location', '$window', 'authService', 'automationService', AutomationController]);
let AppMarketplaceController = require('./app/views/automation/app-marketplace.js');
    callCowboyApp.controller('appMarketplaceController', ['$rootScope', '$scope', '$location', '$window', 'authService', 'automationService', AppMarketplaceController]);
let WorkflowController = require('./app/views/automation/workflow.js');
    callCowboyApp.controller('workflowController', ['$rootScope', '$scope', '$location', '$window', 'authService', 'automationService', '$sce', WorkflowController]);
let RuleConfigController = require('./app/views/automation/rule-config.js');
    callCowboyApp.controller('ruleConfigController', ['authService', '$rootScope', '$scope', '$location', 'automationService', 'contactService', 'integrationService', 'phoneService', 'dispatcherService', 'tagsService', RuleConfigController]);
let IVRconfigController = require('./app/views/phone-tree-ivr/ivr-config.js');
    callCowboyApp.controller('ivrConfigController', ['$rootScope', '$state', '$scope', '$location', 'authService', 'phoneService', 'mediaService', 'userService', 'notificationToastService', 'generalHelperService', IVRconfigController]);
let ListConfigController = require('./app/views/contacts/list-config.js');
    callCowboyApp.controller('listConfigController', ['$rootScope', '$scope', '$location', 'userService', 'authService', 'phoneService', 'mediaService', 'contactService', 'dispatcherService', ListConfigController]);
let RecentCallsController = require('./app/views/recent-calls/recent-calls.js');
    callCowboyApp.controller('recentCallsController', ['authService', 'phoneService', '$scope', '$location', '$rootScope', '$window', '$interval', '$cookies', 'notificationToastService', RecentCallsController]);
let SMSAutorespondersController = require('./app/views/sms-autoresponders/sms-autoresponders.js');
    callCowboyApp.controller('smsAutorespondersController', ['$rootScope', '$scope', '$location', 'authService', 'phoneService', SMSAutorespondersController]);
let SMSconfigController = require('./app/views/sms-autoresponders/sms-config.js');
    callCowboyApp.controller('smsConfigController', ['$rootScope', '$scope', '$location', 'authService', 'phoneService', SMSconfigController]);
let TeamController = require('./app/views/team/team.js');
    callCowboyApp.controller('teamController', ['$rootScope', '$scope', 'authService', 'userService', 'accountService', 'integrationService', '$window', '$location', 'DTDefaultOptions', 'DTOptionsBuilder', TeamController]);
let TeamsController = require('./app/views/teams/teams.js');
    callCowboyApp.controller('teamsController', ['$rootScope', '$scope', 'authService', 'userService', 'accountService', '$window', TeamsController]);
let UserAddController = require('./app/views/team/user-add.js');
    callCowboyApp.controller('userAddController', ['$rootScope', '$scope', 'authService', 'userService', '$location', UserAddController]);
let TextMessagesController = require('./app/views/text-messages/text-messages.js');
    callCowboyApp.controller('textMessagesController', ['authService', 'phoneService', '$rootScope', '$scope', '$location', '$window', '$interval', '$cookies', TextMessagesController]);
let VoicemailController = require('./app/views/voicemail/voicemail.js');
    callCowboyApp.controller('voicemailController', ['authService', 'phoneService', '$rootScope', '$scope', 'ngAudio', '$window', '$location', VoicemailController]);
let InboxController = require('./app/views/inbox/inbox.js');
    callCowboyApp.controller('inboxController', ['$q','$rootScope', '$scope', '$location', '$window', 'authService', 'userService', InboxController]);
let RecordingsController = require('./app/views/recordings/recordings.js');
    callCowboyApp.controller('recordingsController', ['$rootScope', '$scope', '$location', 'authService', 'mediaService', '$interval', '$window', '$timeout', RecordingsController]);
let VoicesController = require('./app/views/voices/voices.js');
    callCowboyApp.controller('voicesController', ['$rootScope', '$scope', '$location', 'authService', 'voiceService', '$interval', '$window', '$timeout', VoicesController]);
let TagsController = require('./app/views/tags/tags.js');
    callCowboyApp.controller('tagsController', ['$rootScope', '$scope', '$location', 'authService', 'mediaService', '$interval', '$window', 'tagsService', TagsController]);
let DialingRulesController = require('./app/views/contacts/dialing-rules.js');
    callCowboyApp.controller('dialingRulesController', ['$rootScope', '$scope', '$location', 'userService', 'authService', 'phoneService', 'mediaService', 'contactService', 'dispatcherService', 'notificationToastService', DialingRulesController]);
let FaxesController = require('./app/views/faxes/faxes.js');
    callCowboyApp.controller('faxesController', ['authService', 'faxService', '$rootScope', '$scope', '$window', '$location', FaxesController]);
let IntegrationsController = require('./app/views/integrations/integrations');
    callCowboyApp.controller('integrationsController', ['$scope', 'integrationService', '$rootScope', IntegrationsController]);
let TrustCenterController = require('./app/views/trust-center/trust-center.js');
    callCowboyApp.controller('trustCenterController', ['$rootScope', '$scope', '$location', '$interval', 'authService', 'accountService',  'DTOptionsBuilder', 'DTDefaultOptions', '$http', TrustCenterController]);
let NumberPoolController = require('./app/views/number-pools/number-pools.js');
    callCowboyApp.controller('numberPoolController', ['$rootScope', '$scope', '$location', '$interval', 'authService', 'accountService', 'tcrService', 'numberService', 'DTOptionsBuilder', 'DTDefaultOptions', '$http', NumberPoolController]);
let ByocController = require('./app/views/byoc/byoc.js');
    callCowboyApp.controller('byocController', ['authService', '$rootScope', '$scope', '$http', '$location', '$window', 'generalHelperService', ByocController]);
let ScriptController = require('./app/views/script/script.js');
    callCowboyApp.controller('scriptController', ['authService', 'domainService', 'templateService', '$rootScope', '$scope', '$http', 'notificationToastService', ScriptController]);
let EmailController = require('./app/views/email/email');
    callCowboyApp.controller('emailController', ['authService', 'domainService', 'templateService', '$rootScope', '$scope', '$http', 'notificationToastService', EmailController]);
let WelcomeController = require('./app/views/welcome/new-welcome');
    callCowboyApp.controller('welcomeController', ['authService', '$rootScope', '$scope', '$window', '$location', WelcomeController]);
let SubscriptionController = require('./app/views/subscription/subscription');
    callCowboyApp.controller('subscriptionController', ['$rootScope', '$scope', 'authService', 'accountService', 'integrationService', '$location', 'campaignService', '$window', '$cookies', SubscriptionController]);
var BucketsController = require('./app/views/buckets/buckets.js');
    callCowboyApp.controller('bucketsController', ['$window', 'authService', '$rootScope', '$scope', 'contactService', 'tagsService', 'userService', 'notificationToastService', '$location', 'boardsService', BucketsController]);
let ChecklistController = require('./app/views/checklist/checklist.js');
    callCowboyApp.controller('checklistController', ['authService', '$rootScope', '$scope', '$window', '$location', 'campaignService', 'phoneService', 'contactService', 'userService', 'mediaService', 'integrationService', ChecklistController]);

//all states you can find here
let ConfigFunction = require('./app/config/config.js');
    callCowboyApp.config(['$stateProvider', '$locationProvider', '$urlRouterProvider', '$qProvider', ConfigFunction]);

callCowboyApp.directive('uiCustomCloseOnSelect', ['$parse', '$timeout', function ($parse, $timeout) {
    return {
        restrict: 'A',
        require: 'uiSelect',
        link: function (scope, element, attrs, $select) {
            $select.uiCustomCloseOnSelectCallback = $parse(attrs.uiCustomCloseOnSelect);

            scope.$watch('$select.selected', function (selectedItem) {
                $timeout(function () {
                    let isClose = $select.uiCustomCloseOnSelectCallback(scope, {
                        selectedItem: selectedItem
                    });
                    if(isClose) { $select.close(); }
                    if($select.searchEnabled) { $select.focusSearchInput(); }
                }, 50);
            });

            scope.$on('close.all.ui.select.menu', function (event) {
                $select.close();
            });
        }
    };
}]);

callCowboyApp.directive('passwordConfirm', ['$parse', function ($parse) {
    return {
        restrict: 'A',
        scope: {
            matchTarget: '=',
        },
        require: 'ngModel',
        link: function link(scope, elem, attrs, ctrl) {
            var validator = function (value) {
                ctrl.$setValidity('match', value === scope.matchTarget);
                return value;
            }

            ctrl.$parsers.unshift(validator);
            ctrl.$formatters.push(validator);

            // This is to force validator when the original password gets changed
            scope.$watch('matchTarget', function(newval, oldval) {
                validator(ctrl.$viewValue);
            });
        }
    };
}]);

callCowboyApp.directive('matchPasswords', function (){ //Old version 'passwordConfirm'
    return {
        restrict: 'A',
        require: '?ngModel',
        link: (scope, element, attrs, ngModel) => {
            var passwordName = attrs.validatorPasswordName || '';
            var passwordAgainName = attrs.validatorPasswordAgainName || '';
            var formName = attrs.validatorFormName || '';

            if (!ngModel || !formName.length || !passwordName.length || !passwordAgainName.length) { return; }

            scope.$watch(attrs.ngModel, function() {
                validate();
            });

            var validate = function() {
                var passwordElem = scope[formName][passwordName];
                var passwordAgainElem = scope[formName][passwordAgainName];

                if(passwordElem.$error.required || passwordAgainElem.$error.required ||
                    passwordElem.$error.minlength || passwordAgainElem.$error.minlength ||
                    passwordElem.$error.pattern || passwordAgainElem.$error.pattern) {
                    return;
                }

                var password = passwordElem.$viewValue;
                var passwordAgain = passwordAgainElem.$viewValue;

                var isValid = (password === passwordAgain) ? true : false;
                passwordElem.$setValidity('matchPasswords', isValid);
                passwordAgainElem.$setValidity('matchPasswords', isValid);
            };
        }
    };
});

callCowboyApp.directive('customInputValidator', function (){
    return {
        restrict: 'A',
        require: 'ngModel',
        scope: {
            valFunc: '&customInputValidator'
        },
        link: (scope, element, attrs, controller) => {
            const normalizedFunc = (modelValue, viewValue) => {
                if (controller.$isEmpty(modelValue)) {
                    // consider empty models to be valid
                    return true;
                }

                return scope.valFunc({
                    '$value': viewValue
                });
            };

            controller.$validators.customInputValidator = normalizedFunc;
        }
    };
});

callCowboyApp.directive('monthYearInputValidator', function (){
    return {
        restrict: 'A',
        require: '?ngModel',
        link: (scope, element, attrs, ngModel) => {
            var monthName = attrs.validatorMonthName || '';
            var yearName = attrs.validatorYearName || '';
            var formName = attrs.validatorFormName || '';

            if (!ngModel || !formName.length || !yearName.length || !monthName.length) { return; }

            scope.$watch(attrs.ngModel, function() {
                validate();
            });

            var validate = function() {
                var monthElem = scope[formName][monthName];
                var yearElem = scope[formName][yearName];

                if(monthElem.$error.required || yearElem.$error.required ||
                   monthElem.$error.pattern || yearElem.$error.pattern ||
                   yearElem.$error.customInputValidator) {
                    return;
                }

                var currentYear = new Date().getFullYear();
                var currentMonth = new Date().getMonth() + 1;
                var month = Number(monthElem.$viewValue);
                var year = Number(yearElem.$viewValue) + 2000;

                var isValid = (currentYear === year && month < currentMonth) ? false : true;
                monthElem.$setValidity('monthYearInputValidator', isValid);
                yearElem.$setValidity('monthYearInputValidator', isValid);
            };
        }
    };
});

callCowboyApp.directive('elementResize', function () {
    return {
        restrict: 'A',
        scope: {
            callback: '&onResize'
        },
        link: function( scope, elem, attrs ) {
            scope.$watch( function() {
                var size = {
                    height: elem.height(),
                    width: elem.width(),
                    innerHeight: elem.innerHeight(),
                    innerWidth: elem.innerWidth()
                };
                if (scope.callback) {
                    scope.callback({$size: size });
                }
            });
        }
    };
});

callCowboyApp.directive('onFinishRender', function ($timeout) {
    return {
        restrict: 'A',
        link: function (scope, element, attr) {
            if (scope.$last === true) {
                $timeout(function () {
                    scope.$emit(attr.onFinishRender);
                });
            }
        }
    };
});

let SecureLS = require('secure-ls');


callCowboyApp.run(['$rootScope', '$location', '$cookies', "$interval", "$window", 'authService', 'accountService', "phoneService", '$state', 'editableOptions', '$anchorScroll', 'integrationService', 'contactService', 'callService', 'userService', 'campaignService',
    function($rootScope, $location, $cookies, $interval, $window, authService, accountService, phoneService, $state, editableOptions, $anchorScroll, integrationService, contactService, callService, userService, campaignService) {

        $rootScope.initialPath = $location.path();
        $rootScope.initialState = $rootScope.initialPath.substring(1);

        $rootScope.settings = {
            RUNTIME_ENV: RUNTIME_ENV,
            API_AUTH_URL: API_AUTH_URL,
            API_ADMIN_URL: API_ADMIN_URL,
            API_CONTACT_URL: API_CONTACT_URL,
            API_DNC_URL: API_DNC_URL,
            API_IMPORT_URL: API_IMPORT_URL,
            API_PHONE_URL: API_PHONE_URL,
            API_REGISTER_URL: API_REGISTER_URL,
            API_PUBLIC_URL: API_PUBLIC_URL,
            API_USER_URL: API_USER_URL,
            API_DISPATCHER_URL: API_DISPATCHER_URL,
            API_MEDIA_URL: API_MEDIA_URL,
            API_VOICE_URL: API_VOICE_URL,
            API_FAX_URL: API_FAX_URL,
            API_EMAIL_URL: API_EMAIL_URL,
            API_BOARDS_URL: API_BOARDS_URL,
            API_INTEGRATION_URL: API_INTEGRATION_URL,
            API_TASK_URL: API_TASK_URL,
            API_TAGS_URL: API_TAGS_URL,
            API_AUTOMATION_URL: API_AUTOMATION_URL,
            API_DOMAIN_URL: API_DOMAIN_URL,
            API_CAMPAIGN_URL: API_CAMPAIGN_URL,
            API_TEMPLATE_URL: API_TEMPLATE_URL,
            API_LONG_JOB_URL: API_LONG_JOB_URL,
            API_SHOPIFY_URL: API_SHOPIFY_URL,
            DROP_COWBOY_APP_URL: DROP_COWBOY_APP_URL,
            DROP_COWBOY_API_URL: DROP_COWBOY_API_URL,
            DROP_COWBOY_MAIN_URL: DROP_COWBOY_MAIN_URL,
            DROP_COWBOY_RVM_WEBHOOKS_HANDLER_URL: DROP_COWBOY_RVM_WEBHOOKS_HANDLER_URL,
            NO_AVATAR_IMG: NO_AVATAR_IMG,
            APP_NAME: APP_NAME,
            APP_LOGO: APP_LOGO,
            APP_ICON: APP_ICON,
            APP_PRIVACY_LINK: APP_PRIVACY_LINK,
            APP_TERMS_LINK: APP_TERMS_LINK,
            APP_INFO_EMAIL: APP_INFO_EMAIL,
            APP_INTERCOM_ACCOUNT: APP_INTERCOM_ACCOUNT,
            LOQATE_KEY: LOQATE_KEY,
            ACCEPT_CLIENT_KEY: ACCEPT_CLIENT_KEY,
            ACCEPT_API_LOGIN_ID: ACCEPT_API_LOGIN_ID,
            WEB_FORM_JS_SOURCE_URL: WEB_FORM_JS_SOURCE_URL,
            WEB_FORM_CSS_SOURCE_URL: WEB_FORM_CSS_SOURCE_URL,
            USER_EMAIL_REGEX: USER_EMAIL_REGEX,
            USER_FIRST_LAST_NAME_REGEX: USER_FIRST_LAST_NAME_REGEX,
            NOT_ALLOWED_EXTENSIONS_TO_UPLOAD: NOT_ALLOWED_EXTENSIONS_TO_UPLOAD,
            ADMIN_ROLE_ID: ADMIN_ROLE_ID,
            MANAGER_ROLE_ID: MANAGER_ROLE_ID,
            ENABLE_GA_TRACKING: ENABLE_GA_TRACKING,
            PARENT_COMPANY: PARENT_COMPANY,

            // settings migrated from drop cowboy
            disable_spoof_date: 1596240000000, // aug 1, 2020
        };

        $rootScope.usaProductCode = US_PRODUCT_CODE;
        $rootScope.globalProductCode = GLOBAL_PRODUCT_CODE;
        $rootScope.productCode = US_PRODUCT_CODE;
        $rootScope.pricing = PRODUCT_PRICING;
        $rootScope.help_url = HELP_URL;
        $rootScope.walkthrough_urls = WALKTHROUGH_URLS;
        $rootScope.appVersion = APP_VERSION;
        $rootScope.publicPoolId = PUBLIC_POOL_ID;
        $rootScope.verify_learn_url = APP_VERIFICATION_LINK;

        $rootScope.root_loading = {
            phone_numbers: true
        };

        $rootScope.root_data = {
            default_caller_id: null,
            purchased_default_number: false
        };

        $rootScope.inboundCalls = [];

        $rootScope.available_dispositions = {};

        $rootScope.dispatcher_template = {
            caller_id: {
                rental: {
                    phone_number: null
                },
                pool: {
                    pool_id: $rootScope.publicPoolId
                },
                source: 'pool'
            },
            ivr: {
                voice: null, // Default Voice IVR
                sms: null // Default SMS Autoresponder
            },
            callable_hours: {
                start_time: 8.0,
                end_time: 21.0
            },
            max_line_count: 3,
            max_call_count: 10,
            minimum_time_between_dials: 12000,
            announce: ['first_name', 'last_name'],
            abandon_ids: [],
            voicemail_ids: [],
            prompt_for_notes: false,
            record_calls: false,
            supervise_calls: false,
            fields_to_dial: ['main_phone'],
            dispositions: {
                0: {
                    id: 'redial', // Redial the contact
                    friendly: 'Redial',
                    label: 'REDIAL',
                    sentiment: 'neutral'
                },
                1: {
                    id: 'good', // Contact is interested, leave the contact in the dial rotation to try again later
                    friendly: 'Good',
                    label: 'GOOD',
                    sentiment: 'good'
                },
                2: {
                    id: 'sold', // Contact was sold, remove the contact from the dial rotation
                    friendly: 'Sold',
                    label: 'SOLD',
                    sentiment: 'good'
                },
                3: {
                    id: 'follow_up', // Contact is interested but wants a follow-up call later (in a week)
                    friendly: 'Follow Up',
                    label: 'FOLLOW UP',
                    sentiment: 'neutral'
                },
                4: {
                    id: 'no_contact',  // Contact wasn't available, leave the contact in the call rotation
                    friendly: 'No Contact',
                    label: 'NO CONTACT',
                    sentiment: 'neutral'
                },
                5: {
                    id: 'no_interest',  // Contact wasn't interested, remove the contact from the dial rotation
                    friendly: 'No Interest',
                    label: 'NO INTRST',
                    sentiment: 'bad'
                },
                6: {
                    id: 'bad_lead',  // The lead data for the contact is bad, remove the contact from the dial rotation
                    friendly: 'Bad Lead',
                    label: 'BAD LEAD',
                    sentiment: 'bad'
                },
                7: {
                    id: 'dnc',  // Add the contact to the DNC and remove them from the call rotation
                    friendly: 'DNC',
                    label: 'DNC',
                    sentiment: 'bad'
                },
                8: {
                    id: 'custom_1',  // Custom disposition (user defined) / Sale Confirmed (for QA workflows)
                    friendly: 'Custom 1',
                    label: '',
                    sentiment: null,
                    custom: true
                },
                9: {
                    id: 'custom_2',  // Custom disposition (user defined) / Sale Rejected (for QA workflows)
                    friendly: 'Custom 2',
                    label: '',
                    sentiment: null,
                    custom: true
                }
            }
        };

        // TODO: recheck it with @Pete
        $rootScope.limits = {
            sms: 200,
            sms_pool: 10000,
            rvm: 50000
        };

        $rootScope.agentState = null;

        $rootScope.authService = authService;
        $rootScope.integrations_enabled = INTEGRATIONS_ENABLED;

        $rootScope.verifications = {
            sms: {}
        };
        $rootScope.team_brands = [];
        $rootScope.balance = {};

        $rootScope.isAuthenticated = function() {
            return $rootScope.authenticated;
        };
        
        $rootScope.setRegion = function(region) {
            if (region === 'us') {
                $rootScope.productCode = US_PRODUCT_CODE;
            }
            else {
                $rootScope.productCode = GLOBAL_PRODUCT_CODE;
                //alert('Global delivery is currently in private beta.  If you would like access, please contact us.');
            }
        };

        $rootScope.__setPhoneMask = function(elem, mask, placeholder) {
            elem.mask(mask, {
                placeholder: placeholder
            });
        };

        $rootScope.getClientCountryCode = function() {
            try {
                // Using the browser's locale
                const locale = navigator.language || navigator.userLanguage;
                // Extract country code from locale
                return locale.split('-')[1] || 'US';

            } catch (e) {
                console.error('Error detecting client country code:', e);
                // Fallback to a default country code
                return 'US';
            }
        }

        $rootScope.libPhoneFormatPhone = function(value) {
            let phoneLocale = value;

            const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
            // Fetch the client's system locale country code
            const localeCountryCode = $rootScope.getClientCountryCode();

            try {
                var firstSymbol = (value.charAt(0) === '+') ? '' : '+';
                var parsed = phoneUtil.parse(firstSymbol + value, '');
                var phoneCountryCode = parsed.getCountryCode();

                // Determine the number format based on the detected country code
                const numberFormat = (localeCountryCode === phoneUtil.getRegionCodeForCountryCode(phoneCountryCode))
                    ? libphonenumber.PhoneNumberFormat.NATIONAL
                    : libphonenumber.PhoneNumberFormat.INTERNATIONAL;

                if (parsed) {
                    phoneLocale = phoneUtil.format(parsed, numberFormat);
                }
            } catch (e) {
                console.error('Error formatting phone number:', e);
            }

            return phoneLocale;
        };

        $rootScope.hasPermissions = function(entity, action) {
            return authService.hasPermission(entity, action)
        };

        $rootScope.openLocation = function(location) {
            $location.path('/' + location);
        };

        $rootScope.trackEvent = function(event) {
            if ($rootScope.settings.ENABLE_GA_TRACKING) {

                let e = event || {};
                e.event = e.event || 'app_event';

                let dataLayer = window.dataLayer || [];
                dataLayer.push(e);
            }
        }

        $rootScope.$on('$stateChangeStart', function(e, toState, toParams, fromState, fromParams) {
            $('.modal').modal('hide');
            $('body').removeClass('modal-open');
            $('.modal-backdrop').remove();
        });

        $rootScope.$on('$stateChangeSuccess', (toState, toParams, fromState, fromParams, options, $transition$) => {
            let url = $location.absUrl().replace('/#/', '/app/');
            let title = (toParams || {}).controller || $location.path();
            $rootScope.trackEvent({event: 'page_view', page_location: url, page_title: title});
        });

        $('#subModal').on('hidden.bs.modal', () => $rootScope.$broadcast('billing_update_canceled'));

        $rootScope.mainModal = {
            id: null,
            title: '',
            body: '',
            iconClasses: '',
            visible: false,
            initialized: false,
            silence: false,
            silenceUntil: 0,
            silenceAction: function() {
                $rootScope.mainModal.silenceUntil =
                    !$rootScope.mainModal.silence ? 0 : Date.now() + 5*60*1000;
            },
            buttonAction: function() {},
            buttonText: ''
        };

        $rootScope.dialingListModal = {};
        $rootScope.adHocCallModal = {};

        $rootScope.showModal = function(params) {

            if ($rootScope.mainModal.silenceUntil > Date.now() || $rootScope.mainModal.visible || $rootScope.mainModal.id === params.id) {
                return;
            }

            $rootScope.mainModal.id = params.id;
            $rootScope.mainModal.title = params.title || '';
            $rootScope.mainModal.avatar_url = 'https://secure.gravatar.com/avatar/' +  params.gravatar_id + '?d=mp';
            $rootScope.mainModal.body = params.body || '';
            $rootScope.mainModal.iconClasses = params.iconClasses || '';
            $rootScope.mainModal.buttonAction = params.buttonAction || function() {};
            $rootScope.mainModal.buttonText = params.buttonText || '';
            $rootScope.mainModal.silence = false;
            $rootScope.mainModal.visible = true;
            $rootScope.$apply();

            if (!$rootScope.mainModal.initialized) {
                $rootScope.mainModal.initialized = true;
                $('#main-modal').on('hidden.bs.modal', function (e) {
                    $rootScope.mainModal.visible = false;
                    $rootScope.mainModal.id = null;
                });
            }

            $('#main-modal').modal('show');
        };

        $rootScope.hideModal = function(params) {
            if (!params || params.id === $rootScope.mainModal.id) {
                $('#main-modal').modal('hide');
            }
        };

        $rootScope.showCancelModal = function(params) {
            $('#cancel-modal').modal('show');
        };

        $rootScope.hideWebRtcSettingsModal = function() {
            $('#webRtcSettingsModal').modal('hide');
        };
        $rootScope.showWebRtcSettingsModal = function() {
            $('#webRtcSettingsModal').modal('show');
        };

        $rootScope.showDialingListModal = function(params) {
            $rootScope.dialingListModal.selectedPlan = params.selectedPlan;
            $rootScope.dialingListModal.predictiveAllowed = params.predictiveAllowed;
            $rootScope.dialingListModal.callId = params.callId;
            $rootScope.$apply();
            $('#dialing-list-modal').modal('show');
        };

        $rootScope.hideDialingListModal = function() {
            $('#dialing-list-modal').modal('hide');
        };

        $rootScope.showAdHocModal = function() {
            $('#adhoc-call-modal').modal('show');
        };

        $rootScope.faxModalParams = {};
        $rootScope.showFaxModal = function(params) {
            if(!$rootScope.isBalance() && !$rootScope.isSubscribedOutboundCommunicationPlan()) {
                $rootScope.showUpsellModalOutboundCommunicationSwal();
                return;
            }
            params = params || {};
            $rootScope.faxModalParams.contact = params.contact || null;
            $rootScope.faxModalParams.document = params.document || null;
            $rootScope.faxModalParams.fax = params.fax || null;
            $rootScope.faxModalParams.callback = params.callback || null;
            $('#fax-modal').modal('show');
        };

        $rootScope.showRvmModal = function(contact) {
            if(!$rootScope.isBalance() && $rootScope.isSubscribedUsagePaygoPlan()) {
                $rootScope.showUpsellModalUsagePlansSwal('ringless_voicemail');
                return;
            }
            $rootScope.$broadcast('showSendRvmModal', contact.contact_id, contact.main_phone);
        };

        $rootScope.openTextArea = function(contact) {
            if(!$rootScope.isBalance() && !$rootScope.isSubscribedOutboundCommunicationPlan()) {
                $rootScope.showUpsellModalOutboundCommunicationSwal();
                return;
            }
            $location.path('/contacts/detail').search({'id': contact.contact_id, 'mode': 'text'});
        };

        $rootScope.openEmailArea = function(contact) {
            if(!$rootScope.isBalance() && !$rootScope.isSubscribedOutboundCommunicationPlan()) {
                $rootScope.showUpsellModalOutboundCommunicationSwal();
                return;
            }
            $location.path('/contacts/detail').search({'id': contact.contact_id, 'mode': 'email'});
        };

        $rootScope.ably = {
            client: new Ably.Realtime(ABLY_CLIENT_KEY),
            onEvent: function(msg) {

                console.log('GOT ABLY');
                console.log(JSON.stringify(msg));
                console.log(msg);

                if (msg.name === 'events') {

                    let payload = msg.data || {};

                    // Iterate over the events
                    const events = payload.events || [];
                    for (let i = 0; i < events.length; i++) {
                        let e = events[i];
                        e.occurred_at = payload.occurred_at;
                        $rootScope.$broadcast(e.event, e);
                    }
                }
                else {
                    console.log('Got unknown ably');
                }
            }
        };

        $rootScope.$on('export.success', function(event, args) {
            if (args && args.data.user_id === authService.getUserId()) {
                // Alex: such construction is to prevent parallel downloads the same export results
                // when user have several opened tabs and each tab receives event;
                const cookiesCampaignResultsToPrint = $cookies.get('campaign_results_to_print')
                const campaignResultsToPrint = cookiesCampaignResultsToPrint ? JSON.parse(cookiesCampaignResultsToPrint) : {};
                if (!campaignResultsToPrint || typeof campaignResultsToPrint[args.occurred_at] === 'undefined') {
                    campaignResultsToPrint[args.occurred_at] = args.data.file_url;
                    $cookies.put('campaign_results_to_print', JSON.stringify(campaignResultsToPrint));
                    $window.open(args.data.file_url, '_blank');
                }
            }
        });

        $rootScope.$on('subscription.invoiced', function(e, ablyEvent) {
            $rootScope.getBalance();
        });

        $rootScope.__askDialingInfo = function(msg) {
            $rootScope.dialingListModal.rented_numbers = $rootScope.team_phone_numbers.map(number => {return number.phone_number});
            $rootScope.dialingListModal.phoneSelected = $rootScope.dialingListModal.rented_numbers[0];
            const userPlan = authService.getUser().plan_id;

            const dialerList = {
                selectedPlan: userPlan,
                predictiveAllowed: userPlan.indexOf('predictive-dialer') !== -1,
                callId: msg.data.call_id
            };

            $rootScope.showDialingListModal(dialerList);
        };

        $rootScope.getBalance = function() {
            let userId = authService.getUserId();
            let teamId = authService.getTeamId();

            if(userId && userId !== null && teamId && teamId !== null) {
                let balanceReq = {
                    user_id: userId,
                    team_id: teamId
                };

                campaignService.balance(balanceReq)
                    .then(results => {
                        let balance = results || {};
                        $rootScope.balance = Object.assign($rootScope.balance, balance);
                        $rootScope.total_funds = Number(balance.balance || 0);

                        // $rootScope.available_funds = Number(balance.available || 0);
                        // $rootScope.reserved_funds = Number(balance.reserved || 0);
                        // $rootScope.recharge_amount = Number(balance.recharge_amount || 0);
                        // $rootScope.recharge_threshold = Number(balance.recharge_threshold || 25);
                        // $rootScope.setMembershipLevel(balance.membership_level || 'free');
                        // $rootScope.setVerified(balance.verified);
                        // $rootScope.setFrozen(balance.frozen);
                        // $rootScope.is_admin = balance.is_admin;

                        $rootScope.$broadcast('balance_change');
                        $rootScope.$apply();
                    })
                    .catch(err => {
                        console.error(err);
                    });
            }
        };

        $rootScope.loadNumbers = function(retryEmpty, retryCount) {

            if($rootScope.authenticated) {

                let req = {
                    team_id: authService.getTeamId(),
                    user_id: authService.getUserId()
                };

                phoneService.getNumbers(req)
                    .then(results => {
                        $rootScope.team_phone_numbers = results.numbers || [];
                        $rootScope.number_limit = results.limits;
                        $rootScope.number_names = results.numbers.reduce( (obj, number) => {
                            obj[number.phone_number] = number.name || number.phone_number;
                            return obj;
                        }, {});

                        if (retryEmpty && !$rootScope.team_phone_numbers.length && retryCount > 0) {
                            setTimeout(() => {
                                $rootScope.loadNumbers(true, retryCount - 1);
                            }, 5000);
                        }

                        $rootScope.setDefaultCallerId((results.numbers || [])[0]);
                        $rootScope.root_loading.phone_numbers = false;
                        $rootScope.$broadcast('teamNumbersLoaded');
                    })
                    .catch(err => {
                        $rootScope.root_loading.phone_numbers = false;
                        $rootScope.$apply();
                    });
            }
        };

        $rootScope.loadBrands = function () {
            let req = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId()
            };

            accountService.getBrands(req)
                .then(results => {
                    let brands = results || [];
                    $rootScope.team_brands = brands;
                    $rootScope.no_client_opt_in = brands.filter(brand => brand.opt_in_flow && brand.opt_in_flow.includes('I don\'t have opt-in from my contacts')).length > 0;
                });
        }

        $rootScope.loadCustomFields = function() {
            let req = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId()
            };

            contactService.getCustomFields(req)
                .then(results => {
                    $rootScope.customFields = results;
                })
        }

        $rootScope.validateUser = function(user) {
            if (!user || user.status === "DISABLED" || user.addon_id === 'inactive-monthly') {
                swal("Informing", "Your account has been disabled or inactivated.", "info");
                authService.logout();
                return false;
            }
            return true;
        }

        $rootScope.$on('team_custom_fields_changed', (event, newCustomFieldsChanged) => {
            $rootScope.customFields = newCustomFieldsChanged;
        });

        $rootScope.$on('callModeRightSideBar', function (event, mode) {
            $rootScope.agentState = mode;
        });

        $rootScope.playSound = function(url) {
            if($rootScope.agentState === 'idle') {
                let audio = new Audio(url);
                let audioPromise = audio.play();

                if (audioPromise !== undefined) {
                    audioPromise.then(_ => {
                        console.log('Audio autoplay started!');
                    }).catch(error => {
                        console.log('Audio autoplay was prevented.');
                    });
                }
            }
        };

        $rootScope.playInboundCallNotification = function() {
            $rootScope.playSound('assets/audio/alert.mp3');
        };

        $rootScope.playTaskReceivedNotification = function() {
            $rootScope.playSound('assets/audio/notification.mp3');
        };

        $rootScope.playTaskOpenedNotification = function() {
            $rootScope.playSound('assets/audio/notification2.mp3');
        };

        $rootScope.$on('task.opened', (event, newCustomFieldsChanged) => {
            $rootScope.playTaskOpenedNotification();
        });

        $rootScope.$on('user.changed', function(event, args) {
            if (authService.getUserId() === args.data.user_id) {
                const userReq = {
                    target_user_id: authService.getUserId(),
                    team_id: authService.getTeamId(),
                    user_id: authService.getUserId()
                };
                userService.getUser(userReq)
                    .then(user => {
                        // fire an event as middleware to prevent multi get user request
                        $rootScope.$broadcast('client.user.changed', user);
                        $rootScope.validateUser(user)
                    })
                    .catch(err => {
                        console.error(err);
                    });
            }
        });

        $rootScope.$on('subscription.changed', function(event, args) {

            if (!authService.isAuthenticated()) {
                return
            }
            const userReq = {
                target_user_id: authService.getUserId(),
                team_id: authService.getTeamId(),
                user_id: authService.getUserId()
            };
            userService.getUser(userReq)
                .then(user => {

                    let userCookie = $rootScope.secureLS.get('user');
                    userCookie.addon_id = user.addon_id;
                    
                    if (args.data.usage_addon_id) {
                        userCookie.team.chargebee.usage_addon_id = args.data.usage_addon_id;
                    }
                    $rootScope.secureLS.set('user', userCookie);
                    $rootScope.user = $rootScope.secureLS.get('user');
                    
                    $rootScope.validateUser(user)
                })
                .catch(err => {
                    console.error(err);
                });
        });

        $rootScope.$on('subscription.paused', function(event, args) {
            swal({
                text: 'Your subscription was paused.  Please contact support to resume services.',
                closeOnClickOutside: false,
                icon: 'warning'
            }).then((confirm) => {
                authService.logout();
            });
        });

        $rootScope.$on('subscription.cancelled', function(event, args) {
            swal({
                text: 'Your subscription was canceled.  Please contact support to resume services.',
                closeOnClickOutside: false,
                icon: 'warning'
            }).then((confirm) => {
                authService.logout();
            });
        });

        $rootScope.hasFocus = true;
        $(window).on('focus', function () {
            $rootScope.hasFocus = true;
            $rootScope.__keepAliveSubscriber();
        });

        $(window).on('blur', function () {
            $rootScope.hasFocus = false;
            $rootScope.__keepAliveSubscriber();
        });

        $rootScope.setSipSubscriber = function(subscriber) {
            $rootScope.sipSubscriber = subscriber;
            callService.setSipSubscriber(subscriber);
        };

        $rootScope.setAccessNumber = function(number) {
            $rootScope.access_number = number;
            $rootScope.$broadcast('accessNumberChanged');
        };

        $rootScope.__keepAliveSubscriber = function () {
            if ($rootScope.authenticated && $rootScope.fingerprintVisitorId) {

                const sipRequest = {
                    team_id: authService.getTeamId(),
                    user_id: authService.getUserId(),
                    webrtc: true,
                    allow_sip_register: false,
                    has_focus: $rootScope.hasFocus,
                    fingerprint: $rootScope.fingerprintVisitorId
                };

                if ($rootScope.sipSubscriber) {
                    const keepAliveRequest = $rootScope.sipSubscriber;
                    keepAliveRequest.team_id = authService.getTeamId();
                    keepAliveRequest.user_id = authService.getUserId();
                    keepAliveRequest.has_focus = $rootScope.has_focus || false;

                    phoneService.keepAliveSubscriber(keepAliveRequest)
                        .then(results => {
                            if (results && !results.subscriber_id && !results.expire_at) {
                                phoneService.createSubscriber(sipRequest)
                                    .then(sipSubscriber => {
                                        $rootScope.setSipSubscriber(sipSubscriber);
                                    });
                            }
                        });
                } else if (sipRequest.team_id && sipRequest.user_id) {
                    phoneService.createSubscriber(sipRequest)
                        .then(sipSubscriber => {
                            $rootScope.setSipSubscriber(sipSubscriber);
                        });
                }
            }
        }

        $rootScope.resetUserAuthVars = function() {
            $rootScope.fingerprintRequestId = undefined;
            $rootScope.fingerprintVisitorId = undefined;
            $rootScope.sipSubscriber = undefined;
            $rootScope.keepAliveSubscriberIntervalId = undefined;
        }

        $rootScope.resetUserAuthVars();

        $rootScope.loadFingerprintData = function() {

            // Initialize the agent
            const fpPromise = import(FINGERPRINTJS_URL).then(FingerprintJS => FingerprintJS.load({
                endpoint: FINGERPRINTJS_ENDPOINT
            }));

            // Get the visitor identifier
            fpPromise
               .then(fp => fp.get())
               .then(result => {

                    $rootScope.fingerprintRequestId = result.requestId;
                    $rootScope.fingerprintVisitorId = result.visitorId;

                    $(window).on('unload', function () {
                        const delRequest = {
                            team_id: authService.getTeamId(),
                            user_id: authService.getUserId(),
                            username: $rootScope.sipSubscriber.username,
                            password: $rootScope.sipSubscriber.password
                        };
                        phoneService.deleteSubscriberBySendBeacon($rootScope.sipSubscriber.subscriber_id, delRequest);
                    });

                    $rootScope.__keepAliveSubscriber();
                    $rootScope.keep_alive_poll = setInterval(() => {
                        $rootScope.__keepAliveSubscriber();
                    }, 300000);// run every 5 min
               });
        }

        $rootScope.pollVerified = function() {

            let req = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId()
            };

            accountService.getTeam(req)
                .then(team => {
                    if(team.verified && !$rootScope.user.team.verified) {
                        $rootScope.user.team.verified = true;
                        $rootScope.secureLS.set('user', $rootScope.user);
                        $rootScope.$broadcast('teamVerified');
                        $rootScope.$apply();
                    }
                    if(team.frozen) {
                        $rootScope.user.team.frozen = true;
                        $rootScope.secureLS.set('user', $rootScope.user);
                        authService.logout();
                    }
                    if(team.canceled) {
                        $rootScope.user.team.canceled = true;
                        authService.logout();
                    }
                    if(team.paused) {
                        $rootScope.user.team.paused = true;
                        authService.logout();
                    }
                })
                .catch(err => {
                    console.log(err);
                });

            let team = (($rootScope.user || {}).team || {});
            if(!team.verified && !team.vouched_completed) {

                accountService.getInvites(req)
                   .then(invite => {
                       if(invite.status === 'active' || invite.status === 'completed') {
                           $rootScope.user.team.vouched_completed = true;
                           $rootScope.secureLS.set('user', $rootScope.user);
                           $rootScope.$apply();
                       }
                   })
                   .catch(err => {
                       console.log(err);
                   });
            }
        };

        $rootScope.showWalkthrough = function() {

            const user = authService.getUser();
            var urls = $rootScope.walkthrough_urls || {};
            var tourId = null;

            // Reload the page with correct walkthrough based on the user's roles / plan
            if($rootScope.hasAutoDialerPlan() || $rootScope.hasPredictiveDialerPlan()) {
                let role = ((user.role || {}).name || '').toLowerCase();
                tourId = urls['dialer-' + role];
            }
            else {
                tourId = (user.owner) ? urls['business-phone-owner'] : urls['business-phone-agent'];
            }

            $location.search('product_tour_id', tourId);
            location.href = $location.absUrl();
            location.reload();
        }

        /*
        $rootScope.showWalkthrough = function() {
            var width = (window.innerWidth > 0) ? window.innerWidth : this.screen.width;

            let introJS = introJs();

            let steps = [
                {
                    element: document.querySelector('#phoneNumbersLeftSideBar'),
                    intro: "You can manage your phone numbers here.",
                    position: 'right'
                },
                {
                    element: document.querySelector('#callRoutingLeftSideBar'),
                    intro: "You can setup call routing rules and IVRs to handle the inbound calls you receive.",
                    position: 'right'
                },
                {
                    element: document.querySelector('#autorespondersLeftSideBar'),
                    intro: "You can configure automatic replies for the text messages you receive.",
                    position: 'right'
                }
            ];

            if(width < 1425 || !$('#rightPanel').hasClass('shw-rside')) {
                steps.push({
                    element: document.querySelector('#rightSideToggle'),
                    intro: "You can place calls, send text messages, and view recent call history here.",
                    position: 'left'
                });
            } else {
                steps.push({
                    element: document.querySelector('#rightPanel'),
                    intro: "You can place calls, send text messages, and view recent call history here.",
                    position: 'left'
                });
            }


            if(true || $rootScope.isDialerPlan()) {

                steps.push({
                    element: document.querySelector('#contactsLeftSideBar'),
                    intro: "You can import contacts and manage your call lists here.",
                    position: 'right'
                });

                steps.push({
                    element: document.querySelector('#accesNumber'),
                    intro: "This is your access number.  Call this number to place calls or start dialing your contact lists.",
                    position: 'right'
                });

            }

            introJS.setOptions({
                scrollToElement: false,
                overlayOpacity : 0,
                steps: steps,
                nextLabel: 'Next &rsaquo;',
                prevLabel: '&lsaquo; Back'
            });

            introJS.onbeforechange(function(targetElement) {
                $anchorScroll(targetElement.id);
            });

            introJS.start();
        };
         */

        $rootScope.setDefaultCallerId = function(value) {

            // TODO get/set cookie
            $rootScope.root_data.default_caller_id = value;
        };

        $rootScope.purchaseDefaultNumber = function() {

            const user = authService.getUser();

            let existingNumbers = $rootScope.team_phone_numbers || [];
            if (user.owner && !existingNumbers.length && !$rootScope.root_data.purchased_default_number) {

                const search = $location.search();
                let numberToBuy = search.buy || $cookies.get('checkoutPhoneNumber');
                if (numberToBuy && !numberToBuy.startsWith('+')) {
                    numberToBuy = '+' + numberToBuy;
                }

                let alternateNumber = numberToBuy || user.mobile_phone || user.office_phone || user.home_phone;
                if (alternateNumber) {

                    $rootScope.root_data.purchased_default_number = true;

                    let teamId = authService.getTeamId();
                    let userId = authService.getUserId();

                    let pattern = alternateNumber.substr(1, 4);

                    if (alternateNumber.startsWith('+1')) {
                        pattern = alternateNumber.substr(2, 3);
                    }

                    let promises = [
                        phoneService.searchAvailable({
                           team_id: teamId,
                           user_id: userId,
                           pattern: pattern
                        }),
                        phoneService.searchAvailable({
                           team_id: teamId,
                           user_id: userId
                        })
                    ];

                    if (numberToBuy) {

                        if (numberToBuy.startsWith('+1')) {
                            numberToBuy = numberToBuy.replace('+1', '');
                        }

                        promises.push(phoneService.searchAvailable({
                            team_id: teamId,
                            user_id: userId,
                            pattern: numberToBuy
                        }))
                    }

                    Promise.all(promises)
                        .then(results => {
                            let preferred = results[2] || [];
                            let randomNumber = results[1] || [];
                            let alternate = results[0] || [];

                            let number = preferred[0] || alternate[0] || randomNumber[0];
                            if (number) {

                                let req = {
                                    team_id: teamId,
                                    user_id: userId,
                                    numbers: [number.phone_number]
                                };

                                return phoneService.rentNumber(req);
                            } else {
                                return Promise.resolve();
                            }
                        })
                        .then(results => {

                            // TODO: This should be triggered by an Ably event
                            setTimeout(() => {
                                $rootScope.loadNumbers(true, 10);
                            }, 1000);
                        })
                        .catch(err => {
                            console.log(err);
                        });
                }
            }
        };

        // initialize chargebee
        $rootScope.chargebee = Chargebee.init({
            site: CHARGEBEE_SITE
        });

        // initialize cobalt
        $rootScope.cobalt = new Cobalt();

        $rootScope.secureLS = new SecureLS({encodingType: 'aes', encryptionSecret:  btoa(ABLY_CLIENT_KEY)});

        $rootScope.bannedKeywords = [
            "loan", "finance", "financing", "lend", "fund", "covid", "corona", "virus", "pandemic", "capital", "ppp", "sba"
        ];
        $rootScope.shortCodes = [
            {title: "First Name",value: "first_name"},
            {title: "Last Name",value: "last_name"},
            {title: "Company",value: "company"},
            {title: "Email",value: "email"},
            {title: "Address 1",value: "address1"},
            {title: "Address 2",value: "address2"},
            {title: "City",value: "city"},
            {title: "State",value: "state"},
            {title: "Postal Code",value: "postal"},
            {title: "Country",value: "country"},
            {title: "Custom 1",value: "custom1"},
            {title: "Custom 2",value: "custom2"},
            {title: "Custom 3",value: "custom3"},
            {title: "Custom 4",value: "custom4"},
            {title: "Custom 5",value: "custom5"},
            {title: "Record ID",value: "record_id"}
        ];

        $rootScope.playWalkthrough = function(value) {

            // Broadcast the value
            $rootScope.$broadcast('walkthrough', value);
        };

        $rootScope.collectPayment = function(pageName, pageData, callback) {

            var req = {
                user_id: authService.getUserId(),
                team_id: authService.getTeamId(),
                page: (pageName || 'manage_payment_sources'),
                page_data: (pageData || {})
            };

            let closed = false;

            $rootScope.chargebee.openCheckout({
                hostedPage: function () {
                    return new Promise(function (resolve, reject) {
                        accountService.getHostedPage(req)
                            .then(res => {
                                console.log(res)
                                resolve(res);
                            })
                    });
                },
                loaded: function () {
                },
                error: function () {
                },
                close: function () {
                    $rootScope.$broadcast('check_payment_source');
                    $rootScope.$broadcast('check_balance');
                    if (!closed && typeof callback === 'function') {
                        closed = true;
                        callback();
                    }
                },
                success: function (hostedPageId) {
                    if (!closed && typeof callback === 'function') {
                        closed = true;
                        callback(hostedPageId);
                    }
                },
                step: function (value) {
                }
            });
        };

        $rootScope.formatMoney = function(value, currency = 'USD', isShowCurrency = true) {

            const formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: currency,
            });

            return (formatter.format(value || 0) + (isShowCurrency ? ' ' + currency : '') );
        }

        $rootScope.showVerificationModal = function() {
            if(!$rootScope.user.team.verified && !$rootScope.user.team.vouched_completed) {
                // $rootScope.$broadcast('show_verification_modal');
                $window.location.href = '#/trust-center';
                $rootScope.$broadcast('complete_brand_registration');
            }
        };

        $rootScope.onLogout = function() {
            if ($rootScope.verified_poll) {
                $interval.cancel($rootScope.verified_poll);
                $rootScope.verified_poll = null;
            }

            if ($rootScope.balance_poll) {
                $interval.cancel($rootScope.balance_poll);
                $rootScope.balance_poll = null;
            }

            if ($rootScope.root_data.keep_alive_poll) {
                $interval.cancel($rootScope.root_data.keep_alive_poll);
            }
        }

        $rootScope.getCurrentUsageAddOnId = function() {
            return $rootScope.user.team.chargebee.usage_addon_id;
        };

        $rootScope.getUsageAddon = function(usageAddonId) {
            var user = authService.getUser() || {};
            var chargebee = ((user.team || {}).chargebee || {});
            return ((PRODUCT_PRICING[chargebee.plan_id] || {}).packages || {})[usageAddonId] || {};
        };

        $rootScope.isDialerPlan = function() {
            var user = authService.getUser() || {};
            var userPlan = $rootScope.getUserActivePlan();
            var chargebee = ((user.team || {}).chargebee || {});
            var plan = ((PRODUCT_PRICING[chargebee.plan_id] || {}).packages || {})[userPlan] || {};
            var dialing = plan.dialing || {};
            return (dialing.auto || dialing.predictive);
        };

        $rootScope.hasConversationDialerPlan = function() {
            var user = authService.getUser() || {};
            var chargebee = ((user.team || {}).chargebee || {});
            var plan = ((PRODUCT_PRICING[chargebee.plan_id] || {}).packages || {})[user.addon_id] || {};
            var dialing = plan.dialing || {};
            return dialing.conversational;
        };

        $rootScope.hasAutoDialerPlan = function() {
            var user = authService.getUser() || {};
            var chargebee = ((user.team || {}).chargebee || {});
            var plan = ((PRODUCT_PRICING[chargebee.plan_id] || {}).packages || {})[user.addon_id] || {};
            var dialing = plan.dialing || {};
            return dialing.auto;
        };

        $rootScope.hasPredictiveDialerPlan = function() {
            var user = authService.getUser() || {};
            var chargebee = ((user.team || {}).chargebee || {});
            var plan = ((PRODUCT_PRICING[chargebee.plan_id] || {}).packages || {})[user.addon_id] || {};
            var dialing = plan.dialing || {};
            return dialing.predictive;
        };

        $rootScope.hasTextPlan = function() {
            var user = authService.getUser() || {};
            var chargebee = ((user.team || {}).chargebee || {});
            var plan = ((PRODUCT_PRICING[chargebee.plan_id] || {}).packages || {})[user.addon_id] || {};
            var inbox = plan.inbox || {};
            return inbox.texting;
        };

        $rootScope.hasEmailPlan = function() {
            var user = authService.getUser() || {};
            var chargebee = ((user.team || {}).chargebee || {});
            var plan = ((PRODUCT_PRICING[chargebee.plan_id] || {}).packages || {})[user.addon_id] || {};
            var inbox = plan.inbox || {};
            return inbox.email;
        };

        $rootScope.hasChatPlan = function() {
            var user = authService.getUser() || {};
            var chargebee = ((user.team || {}).chargebee || {});
            var plan = ((PRODUCT_PRICING[chargebee.plan_id] || {}).packages || {})[user.addon_id] || {};
            var inbox = plan.inbox || {};
            return inbox.chat;
        };

        $rootScope.isAdvancedTrackingPlan = function(planId) {

            return PRODUCT_PRICING[planId].advancedTracking;
        };

        // Authenticate the user
        $rootScope.authenticated = authService.handleAuthentication();
        if($rootScope.authenticated) {
            $rootScope.$broadcast("auth_complete");
        }

        $rootScope.showDomainModal = function() {
            $('#add-domain-modal').modal('show');
        };

        // No Opt-In Modal
        $rootScope.noOptIn = function() {
            return ($rootScope.no_client_opt_in || false);
        };

        // Upsell Modal
        $rootScope.getUserActivePlan = function() {
            return $rootScope.isFreeTrial() ? ($rootScope.user.pending_addon_id || $rootScope.user.addon_id) : $rootScope.user.addon_id;
        };
        $rootScope.isBalance = function() {
            return ($rootScope.total_funds || 0) > 0;
        };
        $rootScope.isSubscribedOutboundCallingPlan = function() {
            var outboundCallingPlans = ['auto-dialer-monthly', 'predictive-dialer-monthly'];
            var userPlan = $rootScope.getUserActivePlan();

            return _.includes(outboundCallingPlans, userPlan);
        };
        $rootScope.isSubscribedOutboundCommunicationPlan = function() {
            var outboundCommunicationPlans = ['front-office-monthly', 'auto-dialer-monthly', 'predictive-dialer-monthly'];
            var userPlan = $rootScope.getUserActivePlan();

            return _.includes(outboundCommunicationPlans, userPlan);
        };

        $rootScope.isSubscribedUsagePaygoPlan = function() {
            var paygoPlan = 'usage-plan-paygo-monthly';
            return $rootScope.user.team.chargebee.usage_addon_id === paygoPlan;
        };

        $rootScope.showNoBulkModal = function(activeTab) {
            $window.location.href = `/#/${activeTab}`;
        };
        $rootScope.showUpsellModal = function(modalMode, activeTab, apply) {
            $rootScope.$broadcast('showUpsellModal', modalMode, activeTab, apply);
        };
        $rootScope.showUpsellModalNoBalanceSwal = function(mode, activeTab) {
            swal({
                text: 'This feature requires a usage plan and available funds in your account balance.',
                buttons: ['Continue Anyway', 'View Plans'],
                closeOnClickOutside: false,
                icon: 'warning'
            }).then((confirm) => {
                if (!confirm) { return; }

                $rootScope.showUpsellModal(mode, activeTab, false);
            });
        };
        $rootScope.showUpsellModalFeatureRequiresSubscriptionSwal = function(mode, activeTab) {
            swal({
                text: 'This feature requires a subscription.',
                buttons: ['Continue Anyway', 'View Plans'],
                closeOnClickOutside: false,
                icon: 'warning'
            }).then((confirm) => {
                if (!confirm) { return; }

                $rootScope.showUpsellModal(mode, activeTab, false);
            });
        };
        $rootScope.showUpsellModalOutboundCommunicationSwal = function() {
            swal({
                text: 'Your subscription plan doesn\'t include outbound communication, please subscribe to a plan that includes outbound communication.',
                buttons: ['Cancel', 'View Plans'],
                closeOnClickOutside: false,
                icon: 'warning'
            }).then((confirm) => {
                if (!confirm) { return; }

                $rootScope.showUpsellModal('default', 'contact_center', false);
            });
        };
        $rootScope.showNoBulkModalSwal = function(activeTab) {
            swal({
                text: 'You have elected to no client opt-in during registration. This prevents you from using our bulk send features until you register a brand with a client opt-in.',
                buttons: ['Cancel', 'Register Now'],
                closeOnClickOutside: false,
                icon: 'warning'
            }).then((confirm) => {
                if (!confirm) { return; }
                $rootScope.showNoBulkModal('trust-center');
            });
        };
        $rootScope.showUpsellModalUsagePlansSwal = function(activeTab) {
            swal({
                text: 'This feature requires a usage plan and available funds in your account balance.',
                buttons: {
                    cancel: 'Cancel',
                    addFunds: { text: 'Add Funds', value: 'addFunds', className: 'swal-middle-btn' },
                    confirm: 'View Usage Plans'
                },
                closeOnClickOutside: false,
                icon: 'warning'
            }).then((confirm) => {
                switch(confirm) {
                    case true: $rootScope.showUpsellModal('usage-plans', activeTab, false); break;
                    case null: return;
                    case 'addFunds': $rootScope.showAddFundsModal(); break;
                }
            });
        };

        $rootScope.checkTrial = function(trial_until, server_time_now) {
            if (trial_until < server_time_now) {
                $rootScope.showTrialEndedModal();
            } else {
                $rootScope.trialLeftMiliseconds = trial_until - server_time_now;
                $rootScope.showTrialRemainingModal();
                setTimeout(() => $rootScope.showTrialEndedModal(), trial_until - server_time_now);
            }
        };

        $rootScope.isFreeTrial = function() {
            const authUser = authService.getUser();
            const team = authUser.team;
            $rootScope.isFreeTrialUser = team ? (team.trial_until > 0 && team.trial_until > Date.now()) : false;
            return $rootScope.isFreeTrialUser;
        };

        $rootScope.showTrialRemainingModal = function() {
            $(window).on('load', () => $('#trial-remaining-modal').modal('show'));
        };

        $rootScope.showTrialEndedModal = function() {
            $(window).on('load', () => $('#trial-ended-modal').modal('show'));
        };

        $rootScope.cart_data = {};
        $rootScope.setCartData = function(cartDataReq, callback) {
            let data = cartDataReq || {};

            let defaultLabel = data.has_recurring_charges ? 'Pay & Subscribe' : 'Pay Now';

            let cartData = {
                billing_address: data.billing_address || null,
                primary_payment: data.primary_payment || null,
                total_amount_today: data.total_amount_today || 0,
                total_amount_recurring: data.total_amount_recurring || 0,
                renewal_timestamp: data.renewal_timestamp || 0,
                has_recurring_charges: data.has_recurring_charges || false,
                submit_label: data.submit_label || defaultLabel
            };

            $rootScope.cart_data = cartData;
            callback();
        }

        $rootScope.showCartModal = function(cartData) {
            $rootScope.setCartData(cartData, ()=> {
                $('#cart-modal').modal('show');
            });
        }

        $rootScope.showAddFundsModal = function() {
            $('#add-funds-modal').modal('show');
        }

        $rootScope.showFreeTrialAddFundsModal = function(fundAmount) {
            if (fundAmount) {
                $rootScope.$broadcast('setFreeTrialFundAmount', fundAmount);
            }
            $('#free-trial-add-funds-modal').modal('show');
        }

        $rootScope.showAddNumbersModal = function() {
            $('#add-numbers-modal').modal('show');
        }

        $rootScope.showUpdateShopifyPolicyModal = function() {
            $('#update-shopify-policy-modal').modal('show');
        }
        
        $rootScope.viewData = $rootScope.viewData || {};
        $rootScope.viewData.breadcrumbs = $rootScope.viewData.breadcrumbs || [];

        $rootScope.$watch('viewData.breadcrumbs', function(val) {
            if (val && val.length > 0 && val[0].title) {
                document.title = `${$rootScope.settings.APP_NAME} | ${val[0].title}`;
            } else {
                document.title = $rootScope.settings.APP_NAME;
            }
            const team_id = authService.getTeamId();
            const user_id = authService.getUserId();

            if (val && val.length && team_id && user_id && $rootScope.authenticated) {
                let request = {
                    team_id,
                    user_id,
                    page_title: val.map(v => v.title).filter(v => v).join('/') || val.map(v => v.link_name).filter(v => v).join('/'),
                    page_url: $location.url(),
                    page_data: Object.assign({}, ...val.map(v => v.page_data).filter(v => v)),
                };

                userService.setUserActivity(request);
            }
        });

        $rootScope.verifications = {
            sms: {
                pin: '',
                attemps: 0
            },
            email: {}
        };

        $rootScope.currentYear = new Date().getFullYear();

        $rootScope.getIntegrations = () => {
            integrationService.list()
                .then(res => {
                    integrationService.set(res);

                    const integrations = integrationService.get();
                    const shownUpdatePolicyModalToday = $cookies.get('shownUpdatePolicyModalToday');
                    if (integrations['shopify']
                        && integrations['shopify'].active
                        && !shownUpdatePolicyModalToday
                        && (integrations['shopify'].public_data
                            && (!integrations['shopify'].public_data.valid_privacy_policy || !integrations['shopify'].public_data.marketing_consent_enabled)
                        )
                    ) {
                        $rootScope.showUpdateShopifyPolicyModal();

                        const now = new Date();
                        const endOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 999);
                        $cookies.put('shownUpdatePolicyModalToday', true, {
                            expires: endOfDay
                        });
                    }

                    $rootScope.$broadcast('integrations_seeded');
                });
        };
        
        editableOptions.theme = 'bs4';
        
        $('.preloader').fadeOut();

        $rootScope.onInit = function() {
            $rootScope.loadNumbers();
            $rootScope.loadBrands();
            $rootScope.loadCustomFields();
            $rootScope.loadFingerprintData();
            $rootScope.getBalance();
            $rootScope.getIntegrations();

            const user = authService.getUser();
            if ($rootScope.validateUser(user)) {
                $rootScope.user = user;
            }

            $rootScope.verified_poll = $interval(function() {
                const teamID = authService.getTeamId();
                if (teamID) $rootScope.pollVerified();
            }, 45000);
            $rootScope.balance_poll = $interval($rootScope.getBalance, 30000);


        };

        // ShoppingCart Placeholders start - OLD VERSION
        var subscriptionSummary = [
            {
                id: 'predictive-dialer',
                name: 'Predictive Dialer',
                count: 1,
                price: 199.95,
                type: 'subscription'
            },
            {
                id: 'business-phone',
                name: 'Business Phone System',
                count: 1,
                price: 19.95,
                type: 'subscription'
            }
        ];
        var oneTimeCharges = [
            {
                id: 0,
                name: 'Desktop Phone System',
                count: 1,
                price: 199.95,
                type: 'oneTimeCharge'
            },
            {
                id: 1,
                name: 'Wifi Grandstream W2-60IPW',
                count: 1,
                price: 19.95,
                type: 'oneTimeCharge'
            }
        ];
        var recommendedAddOnsArray = [
            {
                id: 'business-phone-premium-1',
                name: 'Business Phone System 1 w/ Premium',
                type: 'subscription', // 'subscription' or 'oneTimeCharge'
                price: 99.95,
                count: 1
            },
            {
                id: 'business-phone-premium-2',
                name: 'Business Phone System 2 w/ Premium',
                type: 'oneTimeCharge', // 'subscription' or 'oneTimeCharge'
                price: 90.95,
                count: 1
            },
            {
                id: 'business-phone-premium-3',
                name: 'Business Phone System 3 w/ Premium',
                type: 'oneTimeCharge', // 'subscription' or 'oneTimeCharge'
                price: 199.95,
                count: 1
            },
            {
                id: 'business-phone-premium-4',
                name: 'Business Phone System 4 w/ Premium',
                type: 'subscription', // 'subscription' or 'oneTimeCharge'
                price: 19.95,
                count: 1
            },
            {
                id: 'business-phone-premium-5',
                name: 'Business Phone System 5 w/ Premium',
                type: 'subscription', // 'subscription' or 'oneTimeCharge'
                price: 299.95,
                count: 1
            }
        ];

        // ShoppingCart Placeholders end
        $rootScope.cshowShoppingCartModal = function(data) {
            $rootScope.$broadcast('cshowShoppingCartEvent', 'monthly-plan', subscriptionSummary, oneTimeCharges, recommendedAddOnsArray);
        };
        //OLD VERSION
}]);

})();
