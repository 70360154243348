(function () {

    'use strict';

    function AutomationService(authService, apiService, $rootScope) {
        return {
            getAccessToken: () => {
                return Promise.resolve({token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY3YWQzMzQ4ODExNDUwNTIwY2RjNDdjNCIsInNjb3BlIjpbInVzZXIiXSwiaWF0IjoxNzM5NDA0MTA0LCJleHAiOjE3NDE5OTYxMDR9.hYy-fmr5qHk15FoSmsYQqbOEW8V7ZhZFdVtZqpYLygk"});
            },
            getEvents: () => {
                return availableEvents.filter(event => event.isSupported);
            },
            getEventsNames: () => {
                return availableEvents.filter(event => event.isSupported).map(event => event.event);
            },
            getActions: () => {
                return availableActions;
            },
            getActionsAsArray: () => {
                return Object.keys(availableActions).map(actionName => availableActions[actionName]);
            },
            getRules: () => {
                const req = {
                    team_id: authService.getTeamId(),
                    user_id: authService.getUserId(),
                };
                return apiService.get($rootScope.settings.API_AUTOMATION_URL + '/automation', req);
            },
            getRule: (ruleId) => {
                const req = {
                    team_id: authService.getTeamId(),
                    user_id: authService.getUserId(),
                };
                return apiService.get($rootScope.settings.API_AUTOMATION_URL + `/automation/${ruleId}`, req);
            },
            createRule: (request) => {
                const req = Object.assign({}, {
                    team_id: authService.getTeamId(),
                    user_id: authService.getUserId(),
                }, request);
                return apiService.post($rootScope.settings.API_AUTOMATION_URL + '/automation', req);
            },
            updateRule: (ruleId, request) => {
                const req = Object.assign({}, {
                    team_id: authService.getTeamId(),
                    user_id: authService.getUserId(),
                }, request);
                return apiService.put($rootScope.settings.API_AUTOMATION_URL + `/automation/${ruleId}`, req);
            },
            deleteRule(ruleId) {
                const req = {
                    team_id: authService.getTeamId(),
                    user_id: authService.getUserId(),
                };
                return apiService.del($rootScope.settings.API_AUTOMATION_URL + `/automation/${ruleId}`, req);
            },

            // iPaaS routes
            getIpassSessionToken() {
                const req = {
                    team_id: authService.getTeamId(),
                    user_id: authService.getUserId()
                };
                return apiService.post($rootScope.settings.API_AUTOMATION_URL + '/automation/session', req);
            },
            createWorkflow(session) {
                const req = {
                    team_id: authService.getTeamId(),
                    user_id: authService.getUserId(),
                    session: session
                };
                return apiService.post($rootScope.settings.API_AUTOMATION_URL + '/automation/workflow', req);
            },
            getIpassApplications() {
                const req = {
                    team_id: authService.getTeamId(),
                    user_id: authService.getUserId()
                };
                return apiService.get($rootScope.settings.API_AUTOMATION_URL + '/automation/workflow/applications', req);
            },
            getIpassWorkflows() {
                const req = {
                    team_id: authService.getTeamId(),
                    user_id: authService.getUserId()
                };
                return apiService.get($rootScope.settings.API_AUTOMATION_URL + '/automation/public/workflows', req);
            },
            createPublicWorkflow(request) {
                const req = {
                    team_id: authService.getTeamId(),
                    user_id: authService.getUserId(),
                    session_token: request.session_token
                };
                return apiService.post($rootScope.settings.API_AUTOMATION_URL + '/automation/workflow', req);
            }
        }
    }

    const availableActions = {
        CONTACT_LIST_ADD: {
            name: 'contact.list.add',
            label: 'Add Contact to List'
        },
        CONTACT_LIST_REMOVE: {
            name: 'contact.list.remove',
            label: 'Remove Contact from List'
        },
        CONTACT_TAG_ADD: {
            name: 'contact.tag.add',
            label: 'Add Tag to Contact'
        },
        CONTACT_TAG_REMOVE: {
            name: 'contact.tag.remove',
            label: 'Remove Tag from Contact'
        },
        CONTACT_LIST_MOVE: {
            name: 'contact.list.move',
            label: 'Move Contact from List to List'
        },
        CONTACT_RVM_SEND: {
            name: 'contact.rvm.send',
            label: 'Send Rvm to Contact'
        },
    }

    const availableEvents = [
        {
            event: 'contact.created',
            label: 'Contact: Created',
            isSupported: true,
            availableActions: [
                availableActions.CONTACT_LIST_ADD,
                availableActions.CONTACT_TAG_ADD,
                availableActions.CONTACT_RVM_SEND
            ],
            availableFilterEntities: [
                'user',
                'team',
                'contact',
            ],
        },
        {
            event: 'contact.updated',
            label: 'Contact: Updated',
            isSupported: true,
            availableActions: [
                availableActions.CONTACT_LIST_ADD,
                availableActions.CONTACT_LIST_REMOVE,
                availableActions.CONTACT_TAG_ADD,
                availableActions.CONTACT_TAG_REMOVE,
                availableActions.CONTACT_LIST_MOVE,
                availableActions.CONTACT_RVM_SEND
            ],
            availableFilterEntities: [
                'user',
                'team',
                'contact',
            ],
        },
        {
            event: 'contact.deleted',
            label: 'Contact: Deleted',
            isSupported: true,
            availableActions: [
                availableActions.CONTACT_LIST_REMOVE,
                availableActions.CONTACT_LIST_MOVE,
                availableActions.CONTACT_TAG_REMOVE,
                availableActions.CONTACT_RVM_SEND
            ],
            availableFilterEntities: [
                'user',
                'team',
                'contact',
            ],
        },
        {
            event: 'contact.snoozed',
            label: 'Contact: Snoozed',
            isSupported: false,
            availableActions: [
                availableActions.CONTACT_LIST_ADD,
                availableActions.CONTACT_LIST_REMOVE,
                availableActions.CONTACT_LIST_MOVE,
                availableActions.CONTACT_TAG_ADD,
                availableActions.CONTACT_TAG_REMOVE,
                availableActions.CONTACT_RVM_SEND
            ],
            availableFilterEntities: [
                'user',
                'team',
                'contact',
            ],
        },
        {
            event: 'contact.awoke',
            label: 'Contact: Awoke',
            isSupported: false,
            availableActions: [
                availableActions.CONTACT_LIST_ADD,
                availableActions.CONTACT_LIST_REMOVE,
                availableActions.CONTACT_LIST_MOVE,
                availableActions.CONTACT_TAG_ADD,
                availableActions.CONTACT_TAG_REMOVE,
                availableActions.CONTACT_RVM_SEND
            ],
            availableFilterEntities: [
                'user',
                'team',
                'contact',
            ],
        },
        {
            event: 'contact.assigned',
            label: 'Contact: Assigned',
            isSupported: false,
            availableActions: [
                availableActions.CONTACT_LIST_ADD,
                availableActions.CONTACT_LIST_REMOVE,
                availableActions.CONTACT_LIST_MOVE,
                availableActions.CONTACT_TAG_ADD,
                availableActions.CONTACT_TAG_REMOVE,
                availableActions.CONTACT_RVM_SEND
            ],
            availableFilterEntities: [
                'user',
                'team',
                'contact',
            ],
        },
        {
            event: 'contact.disposition',
            label: 'Contact: Disposition',
            isSupported: false,
            availableActions: [
                availableActions.CONTACT_LIST_ADD,
                availableActions.CONTACT_LIST_REMOVE,
                availableActions.CONTACT_LIST_MOVE,
                availableActions.CONTACT_TAG_ADD,
                availableActions.CONTACT_TAG_REMOVE,
                availableActions.CONTACT_RVM_SEND
            ],
            availableFilterEntities: [
                'user',
                'team',
                'contact',
            ],
        },
        {
            event: 'contact.reminder.created',
            label: 'Contact Reminder: Created',
            isSupported: false,
            availableActions: [
                availableActions.CONTACT_LIST_ADD,
                availableActions.CONTACT_LIST_REMOVE,
                availableActions.CONTACT_LIST_MOVE,
                availableActions.CONTACT_TAG_ADD,
                availableActions.CONTACT_TAG_REMOVE,
                availableActions.CONTACT_RVM_SEND
            ],
            availableFilterEntities: [
                'user',
                'team',
                'contact',
            ],
        },
        {
            event: 'contact.reminder.updated',
            label: 'Contact Reminder: Updated',
            isSupported: false,
            availableActions: [
                availableActions.CONTACT_LIST_ADD,
                availableActions.CONTACT_LIST_REMOVE,
                availableActions.CONTACT_LIST_MOVE,
                availableActions.CONTACT_TAG_ADD,
                availableActions.CONTACT_TAG_REMOVE,
                availableActions.CONTACT_RVM_SEND
            ],
            availableFilterEntities: [
                'user',
                'team',
                'contact',
            ],
        },
        {
            event: 'contact.reminder.deleted',
            label: 'Contact Reminder: Deleted',
            isSupported: false,
            availableActions: [
                availableActions.CONTACT_LIST_ADD,
                availableActions.CONTACT_LIST_REMOVE,
                availableActions.CONTACT_LIST_MOVE,
                availableActions.CONTACT_TAG_ADD,
                availableActions.CONTACT_TAG_REMOVE,
                availableActions.CONTACT_RVM_SEND
            ],
            availableFilterEntities: [
                'user',
                'team',
                'contact',
            ],
        },
        {
            event: 'contact.reminder.complete',
            label: 'Contact Reminder: Complete',
            isSupported: false,
            availableActions: [
                availableActions.CONTACT_LIST_ADD,
                availableActions.CONTACT_LIST_REMOVE,
                availableActions.CONTACT_LIST_MOVE,
                availableActions.CONTACT_TAG_ADD,
                availableActions.CONTACT_TAG_REMOVE,
                availableActions.CONTACT_RVM_SEND
            ],
            availableFilterEntities: [
                'user',
                'team',
                'contact',
            ],
        },
        {
            event: 'contact.call.ringing',
            label: 'Contact Call: Ringing',
            isSupported: true,
            availableActions: [
                availableActions.CONTACT_LIST_ADD,
                availableActions.CONTACT_LIST_REMOVE,
                availableActions.CONTACT_LIST_MOVE,
                availableActions.CONTACT_TAG_ADD,
                availableActions.CONTACT_TAG_REMOVE,
                availableActions.CONTACT_RVM_SEND
            ],
            availableFilterEntities: [
                'user',
                'team',
                'contact',
            ],
        },
        {
            event: 'contact.call.answered',
            label: 'Contact Call: Answered',
            isSupported: true,
            availableActions: [
                availableActions.CONTACT_LIST_ADD,
                availableActions.CONTACT_LIST_REMOVE,
                availableActions.CONTACT_LIST_MOVE,
                availableActions.CONTACT_TAG_ADD,
                availableActions.CONTACT_TAG_REMOVE,
                availableActions.CONTACT_RVM_SEND
            ],
            availableFilterEntities: [
                'user',
                'team',
                'contact',
            ],
        },
        {
            event: 'contact.call.hangup',
            label: 'Contact Call: Hangup',
            isSupported: true,
            availableActions: [
                availableActions.CONTACT_LIST_ADD,
                availableActions.CONTACT_LIST_REMOVE,
                availableActions.CONTACT_LIST_MOVE,
                availableActions.CONTACT_TAG_ADD,
                availableActions.CONTACT_TAG_REMOVE,
                availableActions.CONTACT_RVM_SEND
            ],
            availableFilterEntities: [
                'user',
                'team',
                'contact',
            ],
        },
        {
            event: 'contact.call.missed',
            label: 'Contact Call: Missed',
            isSupported: true,
            availableActions: [
                availableActions.CONTACT_LIST_ADD,
                availableActions.CONTACT_LIST_REMOVE,
                availableActions.CONTACT_LIST_MOVE,
                availableActions.CONTACT_TAG_ADD,
                availableActions.CONTACT_TAG_REMOVE,
                availableActions.CONTACT_RVM_SEND
            ],
            availableFilterEntities: [
                'user',
                'team',
                'contact',
            ],
        },
        {
            event: 'contact.call.abandoned',
            label: 'Contact Call: Abandoned',
            isSupported: true,
            availableActions: [
                availableActions.CONTACT_LIST_ADD,
                availableActions.CONTACT_LIST_REMOVE,
                availableActions.CONTACT_LIST_MOVE,
                availableActions.CONTACT_TAG_ADD,
                availableActions.CONTACT_TAG_REMOVE,
                availableActions.CONTACT_RVM_SEND
            ],
            availableFilterEntities: [
                'user',
                'team',
                'contact',
            ],
        },
        {
            event: 'contact.call.transcribed',
            label: 'Contact Call: Transcribed',
            isSupported: false,
            availableActions: [
                availableActions.CONTACT_LIST_ADD,
                availableActions.CONTACT_LIST_REMOVE,
                availableActions.CONTACT_LIST_MOVE,
                availableActions.CONTACT_TAG_ADD,
                availableActions.CONTACT_TAG_REMOVE,
                availableActions.CONTACT_RVM_SEND
            ],
            availableFilterEntities: [
                'user',
                'team',
                'contact',
            ],
        },
        {
            event: 'contact.call.recorded',
            label: 'Contact Call: Recorded',
            isSupported: false,
            availableActions: [
                availableActions.CONTACT_LIST_ADD,
                availableActions.CONTACT_LIST_REMOVE,
                availableActions.CONTACT_LIST_MOVE,
                availableActions.CONTACT_TAG_ADD,
                availableActions.CONTACT_TAG_REMOVE,
                availableActions.CONTACT_RVM_SEND
            ],
            availableFilterEntities: [
                'user',
                'team',
                'contact',
            ],
        },
        {
            event: 'contact.call.read',
            label: 'Contact Call: Read',
            isSupported: true,
            availableActions: [
                availableActions.CONTACT_LIST_ADD,
                availableActions.CONTACT_LIST_REMOVE,
                availableActions.CONTACT_LIST_MOVE,
                availableActions.CONTACT_TAG_ADD,
                availableActions.CONTACT_TAG_REMOVE,
                availableActions.CONTACT_RVM_SEND
            ],
            availableFilterEntities: [
                'user',
                'team',
                'call',
            ],
        },
        {
            event: 'contact.call.unread',
            label: 'Contact Call: Unread',
            isSupported: true,
            availableActions: [
                availableActions.CONTACT_LIST_ADD,
                availableActions.CONTACT_LIST_REMOVE,
                availableActions.CONTACT_LIST_MOVE,
                availableActions.CONTACT_TAG_ADD,
                availableActions.CONTACT_TAG_REMOVE,
                availableActions.CONTACT_RVM_SEND
            ],
            availableFilterEntities: [
                'user',
                'team',
                'call',
            ],
        },
        {
            event: 'contact.call.deleted',
            label: 'Contact Call: Deleted',
            isSupported: false,
            availableActions: [
                availableActions.CONTACT_LIST_ADD,
                availableActions.CONTACT_LIST_REMOVE,
                availableActions.CONTACT_LIST_MOVE,
                availableActions.CONTACT_TAG_ADD,
                availableActions.CONTACT_TAG_REMOVE,
                availableActions.CONTACT_RVM_SEND
            ],
            availableFilterEntities: [
                'user',
                'team',
                'contact',
            ],
        },
        {
            event: 'contact.call.disposition',
            label: 'Contact Call: Disposition',
            isSupported: true,
            availableActions: [
                availableActions.CONTACT_LIST_ADD,
                availableActions.CONTACT_LIST_REMOVE,
                availableActions.CONTACT_LIST_MOVE,
                availableActions.CONTACT_TAG_ADD,
                availableActions.CONTACT_TAG_REMOVE,
                availableActions.CONTACT_RVM_SEND
            ],
            availableFilterEntities: [
                'user',
                'team',
                'contact',
                'call',
            ],
        },
        {
            event: 'contact.call.disposition.changed',
            label: 'Contact Call: Disposition Changed',
            isSupported: true,
            availableActions: [
                availableActions.CONTACT_LIST_ADD,
                availableActions.CONTACT_LIST_REMOVE,
                availableActions.CONTACT_LIST_MOVE,
                availableActions.CONTACT_TAG_ADD,
                availableActions.CONTACT_TAG_REMOVE,
                availableActions.CONTACT_RVM_SEND
            ],
            availableFilterEntities: [
                'user',
                'team',
                'contact',
                'call',
            ],
        },
        {
            event: 'contact.msg.sent',
            label: 'Contact Message: Sent',
            isSupported: true,
            availableActions: [
                availableActions.CONTACT_LIST_ADD,
                availableActions.CONTACT_LIST_REMOVE,
                availableActions.CONTACT_LIST_MOVE,
                availableActions.CONTACT_TAG_ADD,
                availableActions.CONTACT_TAG_REMOVE,
                availableActions.CONTACT_RVM_SEND
            ],
            availableFilterEntities: [
                'user',
                'team',
                'contact',
            ],
        },
        {
            event: 'contact.msg.received',
            label: 'Contact Message: Received',
            isSupported: true,
            availableActions: [
                availableActions.CONTACT_LIST_ADD,
                availableActions.CONTACT_LIST_REMOVE,
                availableActions.CONTACT_LIST_MOVE,
                availableActions.CONTACT_TAG_ADD,
                availableActions.CONTACT_TAG_REMOVE,
                availableActions.CONTACT_RVM_SEND
            ],
            availableFilterEntities: [
                'user',
                'team',
                'contact',
            ],
        },
        {
            event: 'contact.msg.read',
            label: 'Contact Message: Read',
            isSupported: false,
            availableActions: [
                availableActions.CONTACT_LIST_ADD,
                availableActions.CONTACT_LIST_REMOVE,
                availableActions.CONTACT_LIST_MOVE,
                availableActions.CONTACT_TAG_ADD,
                availableActions.CONTACT_TAG_REMOVE,
                availableActions.CONTACT_RVM_SEND
            ],
            availableFilterEntities: [
                'user',
                'team',
                'contact',
            ],
        },
        {
            event: 'contact.msg.unread',
            label: 'Contact Message: Unread',
            isSupported: false,
            availableActions: [
                availableActions.CONTACT_LIST_ADD,
                availableActions.CONTACT_LIST_REMOVE,
                availableActions.CONTACT_LIST_MOVE,
                availableActions.CONTACT_TAG_ADD,
                availableActions.CONTACT_TAG_REMOVE,
                availableActions.CONTACT_RVM_SEND
            ],
            availableFilterEntities: [
                'user',
                'team',
                'contact',
            ],
        },
        {
            event: 'contact.msg.disposition.changed',
            label: 'Contact Message: Disposition Changed',
            isSupported: true,
            availableActions: [
                availableActions.CONTACT_LIST_ADD,
                availableActions.CONTACT_LIST_REMOVE,
                availableActions.CONTACT_LIST_MOVE,
                availableActions.CONTACT_TAG_ADD,
                availableActions.CONTACT_TAG_REMOVE,
                availableActions.CONTACT_RVM_SEND
            ],
            availableFilterEntities: [
                'user',
                'team',
                'contact',
            ],
        },
        {
            event: 'contact.msg.deleted',
            label: 'Contact Message: Deleted',
            isSupported: false,
            availableActions: [
                availableActions.CONTACT_LIST_ADD,
                availableActions.CONTACT_LIST_REMOVE,
                availableActions.CONTACT_LIST_MOVE,
                availableActions.CONTACT_TAG_ADD,
                availableActions.CONTACT_TAG_REMOVE,
                availableActions.CONTACT_RVM_SEND
            ],
            availableFilterEntities: [
                'user',
                'team',
                'contact',
            ],
        },
        {
            event: 'contact.email.sent',
            label: 'Contact Email: Sent',
            isSupported: true,
            availableActions: [
                availableActions.CONTACT_LIST_ADD,
                availableActions.CONTACT_LIST_REMOVE,
                availableActions.CONTACT_LIST_MOVE,
                availableActions.CONTACT_TAG_ADD,
                availableActions.CONTACT_TAG_REMOVE,
                availableActions.CONTACT_RVM_SEND
            ],
            availableFilterEntities: [
                'user',
                'team',
                'contact',
            ],
        },
        {
            event: 'contact.email.received',
            label: 'Contact Email: Received',
            isSupported: true,
            availableActions: [
                availableActions.CONTACT_LIST_ADD,
                availableActions.CONTACT_LIST_REMOVE,
                availableActions.CONTACT_LIST_MOVE,
                availableActions.CONTACT_TAG_ADD,
                availableActions.CONTACT_TAG_REMOVE,
                availableActions.CONTACT_RVM_SEND
            ],
            availableFilterEntities: [
                'user',
                'team',
                'contact',
            ],
        },
        {
            event: 'contact.email.read',
            label: 'Contact Email: Read',
            isSupported: false,
            availableActions: [
                availableActions.CONTACT_LIST_ADD,
                availableActions.CONTACT_LIST_REMOVE,
                availableActions.CONTACT_LIST_MOVE,
                availableActions.CONTACT_TAG_ADD,
                availableActions.CONTACT_TAG_REMOVE,
                availableActions.CONTACT_RVM_SEND
            ],
            availableFilterEntities: [
                'user',
                'team',
                'contact',
            ],
        },
        {
            event: 'contact.email.unread',
            label: 'Contact Email: Unread',
            isSupported: false,
            availableActions: [
                availableActions.CONTACT_LIST_ADD,
                availableActions.CONTACT_LIST_REMOVE,
                availableActions.CONTACT_LIST_MOVE,
                availableActions.CONTACT_TAG_ADD,
                availableActions.CONTACT_TAG_REMOVE,
                availableActions.CONTACT_RVM_SEND
            ],
            availableFilterEntities: [
                'user',
                'team',
                'contact',
            ],
        },
        {
            event: 'contact.email.deleted',
            label: 'Contact Email: Deleted',
            isSupported: false,
            availableActions: [
                availableActions.CONTACT_LIST_ADD,
                availableActions.CONTACT_LIST_REMOVE,
                availableActions.CONTACT_LIST_MOVE,
                availableActions.CONTACT_TAG_ADD,
                availableActions.CONTACT_TAG_REMOVE,
                availableActions.CONTACT_RVM_SEND
            ],
            availableFilterEntities: [
                'user',
                'team',
                'contact',
            ],
        },
        {
            event: 'contact.voicemail.received',
            label: 'Contact Voicemail: Received',
            isSupported: false,
            availableActions: [
                availableActions.CONTACT_LIST_ADD,
                availableActions.CONTACT_LIST_REMOVE,
                availableActions.CONTACT_LIST_MOVE,
                availableActions.CONTACT_TAG_ADD,
                availableActions.CONTACT_TAG_REMOVE,
                availableActions.CONTACT_RVM_SEND
            ],
            availableFilterEntities: [
                'user',
                'team',
                'contact',
            ],
        },
        {
            event: 'contact.voicemail.read',
            label: 'Contact Voicemail: Read',
            isSupported: true,
            availableActions: [
                availableActions.CONTACT_LIST_ADD,
                availableActions.CONTACT_LIST_REMOVE,
                availableActions.CONTACT_LIST_MOVE,
                availableActions.CONTACT_TAG_ADD,
                availableActions.CONTACT_TAG_REMOVE,
                availableActions.CONTACT_RVM_SEND
            ],
            availableFilterEntities: [
                'user',
                'team',
                'call_recording',
            ],
        },
        {
            event: 'contact.voicemail.unread',
            label: 'Contact Voicemail: Unread',
            isSupported: true,
            availableActions: [
                availableActions.CONTACT_LIST_ADD,
                availableActions.CONTACT_LIST_REMOVE,
                availableActions.CONTACT_LIST_MOVE,
                availableActions.CONTACT_TAG_ADD,
                availableActions.CONTACT_TAG_REMOVE,
                availableActions.CONTACT_RVM_SEND
            ],
            availableFilterEntities: [
                'user',
                'team',
                'call_recording',
            ],
        },
        {
            event: 'contact.voicemail.deleted',
            label: 'Contact Voicemail: Deleted',
            isSupported: false,
            availableActions: [
                availableActions.CONTACT_LIST_ADD,
                availableActions.CONTACT_LIST_REMOVE,
                availableActions.CONTACT_LIST_MOVE,
                availableActions.CONTACT_TAG_ADD,
                availableActions.CONTACT_TAG_REMOVE,
                availableActions.CONTACT_RVM_SEND
            ],
            availableFilterEntities: [
                'user',
                'team',
                'contact',
            ],
        },
        {
            event: 'contact.rvm.sent',
            label: 'Contact Rvm: Sent',
            isSupported: true,
            availableActions: [
                availableActions.CONTACT_LIST_ADD,
                availableActions.CONTACT_LIST_REMOVE,
                availableActions.CONTACT_LIST_MOVE,
                availableActions.CONTACT_TAG_ADD,
                availableActions.CONTACT_TAG_REMOVE,
                availableActions.CONTACT_RVM_SEND
            ],
            availableFilterEntities: [
                'user',
                'team',
                'integration',
                'call_recording',
            ],
        },
        {
            event: 'contact.rvm.status',
            label: 'Contact Rvm: Status',
            isSupported: true,
            availableActions: [
                availableActions.CONTACT_LIST_ADD,
                availableActions.CONTACT_LIST_REMOVE,
                availableActions.CONTACT_LIST_MOVE,
                availableActions.CONTACT_TAG_ADD,
                availableActions.CONTACT_TAG_REMOVE,
                availableActions.CONTACT_RVM_SEND
            ],
            availableFilterEntities: [
                'call_recording',
            ],
        },
        {
            event: 'contact.fax.sent',
            label: 'Contact Fax: Sent',
            isSupported: true,
            availableActions: [
                availableActions.CONTACT_LIST_ADD,
                availableActions.CONTACT_LIST_REMOVE,
                availableActions.CONTACT_LIST_MOVE,
                availableActions.CONTACT_TAG_ADD,
                availableActions.CONTACT_TAG_REMOVE,
                availableActions.CONTACT_RVM_SEND
            ],
            availableFilterEntities: [
                'user',
                'team',
                'contact',
            ],
        },
        {
            event: 'contact.fax.received',
            label: 'Contact Fax: Received',
            isSupported: true,
            availableActions: [
                availableActions.CONTACT_LIST_ADD,
                availableActions.CONTACT_LIST_REMOVE,
                availableActions.CONTACT_LIST_MOVE,
                availableActions.CONTACT_TAG_ADD,
                availableActions.CONTACT_TAG_REMOVE,
                availableActions.CONTACT_RVM_SEND
            ],
            availableFilterEntities: [
                'user',
                'team',
                'contact',
            ],
        },
        {
            event: 'contact.fax.read',
            label: 'Contact Fax: Read',
            isSupported: false,
            availableActions: [
                availableActions.CONTACT_LIST_ADD,
                availableActions.CONTACT_LIST_REMOVE,
                availableActions.CONTACT_LIST_MOVE,
                availableActions.CONTACT_TAG_ADD,
                availableActions.CONTACT_TAG_REMOVE,
                availableActions.CONTACT_RVM_SEND
            ],
            availableFilterEntities: [
                'user',
                'team',
                'contact',
            ],
        },
        {
            event: 'contact.fax.unread',
            label: 'Contact Fax: Unread',
            isSupported: false,
            availableActions: [
                availableActions.CONTACT_LIST_ADD,
                availableActions.CONTACT_LIST_REMOVE,
                availableActions.CONTACT_LIST_MOVE,
                availableActions.CONTACT_TAG_ADD,
                availableActions.CONTACT_TAG_REMOVE,
                availableActions.CONTACT_RVM_SEND
            ],
            availableFilterEntities: [
                'user',
                'team',
                'contact',
            ],
        },
        {
            event: 'contact.fax.deleted',
            label: 'Contact Fax: Deleted',
            isSupported: false,
            availableActions: [
                availableActions.CONTACT_LIST_ADD,
                availableActions.CONTACT_LIST_REMOVE,
                availableActions.CONTACT_LIST_MOVE,
                availableActions.CONTACT_TAG_ADD,
                availableActions.CONTACT_TAG_REMOVE,
                availableActions.CONTACT_RVM_SEND
            ],
            availableFilterEntities: [
                'user',
                'team',
                'contact',
            ],
        },
        {
            event: 'contact.chat.sent',
            label: 'Contact Chat: Sent',
            isSupported: false,
            availableActions: [
                availableActions.CONTACT_LIST_ADD,
                availableActions.CONTACT_LIST_REMOVE,
                availableActions.CONTACT_LIST_MOVE,
                availableActions.CONTACT_TAG_ADD,
                availableActions.CONTACT_TAG_REMOVE,
                availableActions.CONTACT_RVM_SEND
            ],
            availableFilterEntities: [
                'user',
                'team',
                'contact',
            ],
        },
        {
            event: 'contact.chat.received',
            label: 'Contact Chat: Received',
            isSupported: false,
            availableActions: [
                availableActions.CONTACT_LIST_ADD,
                availableActions.CONTACT_LIST_REMOVE,
                availableActions.CONTACT_LIST_MOVE,
                availableActions.CONTACT_TAG_ADD,
                availableActions.CONTACT_TAG_REMOVE,
                availableActions.CONTACT_RVM_SEND
            ],
            availableFilterEntities: [
                'user',
                'team',
                'contact',
            ],
        },
        {
            event: 'contact.chat.read',
            label: 'Contact Chat: Read',
            isSupported: false,
            availableActions: [
                availableActions.CONTACT_LIST_ADD,
                availableActions.CONTACT_LIST_REMOVE,
                availableActions.CONTACT_LIST_MOVE,
                availableActions.CONTACT_TAG_ADD,
                availableActions.CONTACT_TAG_REMOVE,
                availableActions.CONTACT_RVM_SEND
            ],
            availableFilterEntities: [
                'user',
                'team',
                'contact',
            ],
        },
        {
            event: 'contact.chat.unread',
            label: 'Contact Chat: Unread',
            isSupported: false,
            availableActions: [
                availableActions.CONTACT_LIST_ADD,
                availableActions.CONTACT_LIST_REMOVE,
                availableActions.CONTACT_LIST_MOVE,
                availableActions.CONTACT_TAG_ADD,
                availableActions.CONTACT_TAG_REMOVE,
                availableActions.CONTACT_RVM_SEND
            ],
            availableFilterEntities: [
                'user',
                'team',
                'contact',
            ],
        },
        {
            event: 'contact.document.attached',
            label: 'Contact Document: Attached',
            isSupported: true,
            availableActions: [
                availableActions.CONTACT_LIST_ADD,
                availableActions.CONTACT_LIST_REMOVE,
                availableActions.CONTACT_LIST_MOVE,
                availableActions.CONTACT_TAG_ADD,
                availableActions.CONTACT_TAG_REMOVE,
                availableActions.CONTACT_RVM_SEND
            ],
            availableFilterEntities: [
                'user',
                'team',
                'contact',
            ],
        },
        {
            event: 'contact.document.deleted',
            label: 'Contact Document: Deleted',
            isSupported: true,
            availableActions: [
                availableActions.CONTACT_LIST_ADD,
                availableActions.CONTACT_LIST_REMOVE,
                availableActions.CONTACT_LIST_MOVE,
                availableActions.CONTACT_TAG_ADD,
                availableActions.CONTACT_TAG_REMOVE,
                availableActions.CONTACT_RVM_SEND
            ],
            availableFilterEntities: [
                'user',
                'team',
                'contact',
            ],
        },
        {
            event: 'contact.document.assigned',
            label: 'Contact Document: Assigned',
            isSupported: false,
            availableActions: [
                availableActions.CONTACT_LIST_ADD,
                availableActions.CONTACT_LIST_REMOVE,
                availableActions.CONTACT_LIST_MOVE,
                availableActions.CONTACT_TAG_ADD,
                availableActions.CONTACT_TAG_REMOVE,
                availableActions.CONTACT_RVM_SEND
            ],
            availableFilterEntities: [
                'user',
                'team',
                'contact',
            ],
        },
        {
            event: 'contact.document.status',
            label: 'Contact Document: Status',
            isSupported: true,
            availableActions: [
                availableActions.CONTACT_LIST_ADD,
                availableActions.CONTACT_LIST_REMOVE,
                availableActions.CONTACT_LIST_MOVE,
                availableActions.CONTACT_TAG_ADD,
                availableActions.CONTACT_TAG_REMOVE,
                availableActions.CONTACT_RVM_SEND
            ],
            availableFilterEntities: [
                'user',
                'team',
                'contact',
            ],
        },
        {
            event: 'contact.note.created',
            label: 'Contact Note: Created',
            isSupported: true,
            availableActions: [
                availableActions.CONTACT_LIST_ADD,
                availableActions.CONTACT_LIST_REMOVE,
                availableActions.CONTACT_LIST_MOVE,
                availableActions.CONTACT_TAG_ADD,
                availableActions.CONTACT_TAG_REMOVE,
                availableActions.CONTACT_RVM_SEND
            ],
            availableFilterEntities: [
                'user',
                'team',
                'contact',
                'note',
            ],
        },
        {
            event: 'contact.note.updated',
            label: 'Contact Note: Updated',
            isSupported: true,
            availableActions: [
                availableActions.CONTACT_LIST_ADD,
                availableActions.CONTACT_LIST_REMOVE,
                availableActions.CONTACT_LIST_MOVE,
                availableActions.CONTACT_TAG_ADD,
                availableActions.CONTACT_TAG_REMOVE,
                availableActions.CONTACT_RVM_SEND
            ],
            availableFilterEntities: [
                'user',
                'team',
                'contact',
                'note',
            ],
        },
        {
            event: 'contact.note.deleted',
            label: 'Contact Note: Deleted',
            isSupported: true,
            availableActions: [
                availableActions.CONTACT_LIST_ADD,
                availableActions.CONTACT_LIST_REMOVE,
                availableActions.CONTACT_LIST_MOVE,
                availableActions.CONTACT_TAG_ADD,
                availableActions.CONTACT_TAG_REMOVE,
                availableActions.CONTACT_RVM_SEND
            ],
            availableFilterEntities: [
                'user',
                'team',
                'contact',
                'note',
            ],
        },
        {
            event: 'contact.list.added',
            label: 'Contact List: Added',
            isSupported: true,
            availableActions: [
                availableActions.CONTACT_LIST_ADD,
                availableActions.CONTACT_LIST_REMOVE,
                availableActions.CONTACT_LIST_MOVE,
                availableActions.CONTACT_TAG_ADD,
                availableActions.CONTACT_TAG_REMOVE,
                availableActions.CONTACT_RVM_SEND
            ],
            availableFilterEntities: [
                'user',
                'team',
                'contact',
                'list',
            ],
        },
        {
            event: 'contact.list.removed',
            label: 'Contact List: Removed',
            isSupported: true,
            availableActions: [
                availableActions.CONTACT_LIST_ADD,
                availableActions.CONTACT_LIST_REMOVE,
                availableActions.CONTACT_LIST_MOVE,
                availableActions.CONTACT_TAG_ADD,
                availableActions.CONTACT_TAG_REMOVE,
                availableActions.CONTACT_RVM_SEND
            ],
            availableFilterEntities: [
                'user',
                'team',
                'contact',
                'list',
            ],
        },
        {
            event: 'contact.timeline.entry',
            label: 'Contact Timeline Entry',
            isSupported: true,
            availableActions: [
                availableActions.CONTACT_LIST_ADD,
                availableActions.CONTACT_LIST_REMOVE,
                availableActions.CONTACT_LIST_MOVE,
                availableActions.CONTACT_TAG_ADD,
                availableActions.CONTACT_TAG_REMOVE,
                availableActions.CONTACT_RVM_SEND
            ],
            availableFilterEntities: [
                'user',
                'team',
                'contact',
            ],
        },
        {
            event: 'contact.export.complete',
            label: 'Contact List Export: Complete',
            isSupported: true,
            availableActions: [
                availableActions.CONTACT_LIST_ADD,
                availableActions.CONTACT_LIST_REMOVE,
                availableActions.CONTACT_LIST_MOVE,
                availableActions.CONTACT_TAG_ADD,
                availableActions.CONTACT_TAG_REMOVE,
                availableActions.CONTACT_RVM_SEND
            ],
            availableFilterEntities: [
                'user',
                'team',
                'export_contacts',
            ],
        },
        {
            event: 'contact.import.complete',
            label: 'Contact List Import: Complete',
            isSupported: true,
            availableActions: [
                availableActions.CONTACT_LIST_ADD,
                availableActions.CONTACT_LIST_REMOVE,
                availableActions.CONTACT_LIST_MOVE,
                availableActions.CONTACT_TAG_ADD,
                availableActions.CONTACT_TAG_REMOVE,
                availableActions.CONTACT_RVM_SEND
            ],
            availableFilterEntities: [
                'user',
                'team',
                'import_contacts',
            ],
        },
        {
            event: 'list.created',
            label: 'List: Created',
            isSupported: true,
            availableActions: [
                availableActions.CONTACT_RVM_SEND
            ],
            availableFilterEntities: [
                'user',
                'team',
                'list',
            ],
        },
        {
            event: 'list.updated',
            label: 'List: Updated',
            isSupported: true,
            availableActions: [
                availableActions.CONTACT_RVM_SEND
            ],
            availableFilterEntities: [
                'user',
                'team',
                'list',
            ],
        },
        {
            event: 'list.deleted',
            label: 'List: Deleted',
            isSupported: true,
            availableActions: [
                availableActions.CONTACT_RVM_SEND
            ],
            availableFilterEntities: [
                'user',
                'team',
                'list',
            ],
        },
        {
            event: 'list.activated',
            label: 'List: Activated',
            isSupported: false,
            availableActions: [
                availableActions.CONTACT_RVM_SEND
            ],
            availableFilterEntities: [
                'user',
                'team',
                'list',
            ],
        },
        {
            event: 'list.deactivated',
            label: 'List: Deactivated',
            isSupported: false,
            availableActions: [
                availableActions.CONTACT_RVM_SEND
            ],
            availableFilterEntities: [
                'user',
                'team',
                'list',
            ],
        },
        {
            event: 'user.login',
            label: 'User: Login',
            isSupported: true,
            availableActions: [
                availableActions.CONTACT_RVM_SEND
            ],
            availableFilterEntities: [
                'user',
                'team',
            ],
        },
        {
            event: 'user.logout',
            label: 'User: Logout',
            isSupported: true,
            availableActions: [
                availableActions.CONTACT_RVM_SEND
            ],
            availableFilterEntities: [
                'user',
                'team',
            ],
        },
        {
            event: 'user.created',
            label: 'User: Created',
            isSupported: true,
            availableActions: [
                availableActions.CONTACT_RVM_SEND
            ],
            availableFilterEntities: [
                'user',
                'team',
            ],
        },
        {
            event: 'user.updated',
            label: 'User: Updated',
            isSupported: true,
            availableActions: [
                availableActions.CONTACT_RVM_SEND
            ],
            availableFilterEntities: [
                'user',
                'team',
            ],
        },
        {
            event: 'user.deleted',
            label: 'User: Deleted',
            isSupported: true,
            availableActions: [
                availableActions.CONTACT_RVM_SEND
            ],
            availableFilterEntities: [
                'user',
                'team',
            ],
        }
    ];

    const filters = {

    }

    module.exports = AutomationService;
})();
