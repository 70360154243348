(function() {
    'use strict';

    function TrialRemainingModalController(authService, $rootScope, $scope, $location) {

        $scope.data = {
            trialLeftDays: 0,
        };

        $scope.staticData = {
            title: 'Free Trial Ending Soon',
            secondTitle: ['You Have', 'Days Remaining'],
            titleDescription: 'Everything you need to boost sales and communicate with your customers - Subscribe Now!',
            bodyTitle: 'Headline',
            bodyText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut at mi sed elit interdum pharetra eu nec tortor. Proin ac nunc at ipsum hendrerit venenatis.',
            buttons: [
                { text: 'Continue Free Trial' },
                { text: 'Subscribe Now' },
            ],
        };

        $scope.continueTrial = function () {
            $('#trial-remaining-modal').modal('hide');
        };

        $scope.subscribeNow = function() {
            $('#trial-remaining-modal').modal('hide');
            setTimeout(() => $location.url( "/subscriptions?pending=true&trial_over=true" ));
        };

        $scope.onInit = function() {
            $scope.data.trialLeftDays = Math.floor($rootScope.trialLeftMiliseconds / (1000 * 60 * 60 * 24));
        }

        if (authService.hasAccount()) {
            $scope.onInit();
        }
        else {
            $scope.$on('auth_complete', function(event, args) {
                $scope.onInit();
            });
        }
    }

    module.exports = TrialRemainingModalController;
})();
