(function () {
    'use strict';

    function RtcSettingsService($rootScope) {
        let th = this;
        th.remoteAudio = undefined;
        th.currentStream = null;
        th.audioInput = undefined;
        th.audioOutput = undefined;

        function changeAudioSink(input) {
            th.audioInput = input;
        }

        // Attach audio output device element using device/sink ID.
        function attachSinkId(output, callback) {
            var jumpFirstOutputDevice = false;
            var status;
            var message = '';

            th.audioOutput = output;
            var sinkId = th.audioOutput.value;

            if (typeof th.remoteAudio.sinkId !== 'undefined') {
                th.remoteAudio.setSinkId(sinkId)
                    .then(() => {
                        jumpFirstOutputDevice = false;
                        status = 'success';
                        message = ` audio output device attached: ${sinkId}`;
                        callback(status, jumpFirstOutputDevice, message);
                    })
                    .catch(error => {
                        if (error.name === 'SecurityError') {
                            message = `You need to use HTTPS for selecting audio output device: ${error}`;
                        } else { message = error; }
                        jumpFirstOutputDevice = true; // Jump back to first output device in the list as it's the default.
                        status = 'error';
                        callback(status, jumpFirstOutputDevice, message);
                    });
            } else {
                message = 'Browser does not support output device selection.';
                status = 'error';
                jumpFirstOutputDevice = false;
                callback(status, jumpFirstOutputDevice, message);
            }
        }

        function getDevicesHandleError(error) {
            if(error) {
                console.log('navigator.MediaDevices.getUserMedia error: ', error.message, error.name);
            }
        }

        function getDevices(callback) {
            navigator.mediaDevices.enumerateDevices()
                .then(function(devices) {
                    callback(devices);
                }).catch(function(error) {
                    getDevicesHandleError(error);
                    callback(undefined);
                });
        }

        function cleanRemoteAudioStream() {
            if(th.remoteAudio) {
                th.remoteAudio.srcObject = undefined;
            }
        }

        function setRemoteAudioStream(stream) {
            th.remoteAudio.srcObject = stream;
            $rootScope.$emit('audio-stream-playing');
        }

        function getRemoteAudioStream() {
            return th.remoteAudio.srcObject;
        }

        function stopAudio() {
            if(th.currentStream !== null) {
                th.currentStream.getTracks()[0].stop();
            }

            th.currentStream = null;
            cleanRemoteAudioStream();
        }

        function playAudio() {
            stopAudio();

            let audioSource = th.audioInput.value;
            let constraints = {
                audio: {deviceId: audioSource ? {exact: audioSource} : undefined},
                video: false
            };

            navigator.mediaDevices.getUserMedia(constraints)
                .then(function(stream) {
                    window.stream = stream;
                    th.currentStream = stream;
                    setRemoteAudioStream(stream);
                }).catch(function(error) {
                console.log(error);
            });
        }

        function subscribeDeviceChange(scope, callback) {
            let handler = $rootScope.$on('device-change', callback);
            scope.$on('$destroy', handler);
        }

        function subscribeAudioStreamPlaying(scope, callback) {
            let handler = $rootScope.$on('audio-stream-playing', callback);
            scope.$on('$destroy', handler);
        }

        function init() {
            th.remoteAudio = new Audio();
            th.remoteAudio.autoplay = true;
            th.remoteAudio.crossOrigin = 'anonymous';

            navigator.mediaDevices.addEventListener('devicechange', event => {
                $rootScope.$emit('device-change');
            });
        }

        return {
            init: init,
            getDevices: getDevices,
            setRemoteAudioStream: setRemoteAudioStream,
            getRemoteAudioStream: getRemoteAudioStream,
            cleanRemoteAudioStream: cleanRemoteAudioStream,
            playAudio: playAudio,
            stopAudio: stopAudio,
            changeAudioInput: changeAudioSink,
            changeAudioOutput: attachSinkId,
            subscribeDeviceChange: subscribeDeviceChange,
            subscribeAudioStreamPlaying: subscribeAudioStreamPlaying
        };
    }
    module.exports = RtcSettingsService;
})();


