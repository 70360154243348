(function() {
    'use strict';

    function TeamController($rootScope, $scope, authService, userService, accountService, integrationService, $window, $location, DTDefaultOptions, DTOptionsBuilder) {
        var vm = this;
        vm.authService = authService;

        $rootScope.viewData = $rootScope.viewData || {};
        $rootScope.viewData.breadcrumbs = $rootScope.viewData.breadcrumbs || [];
        $rootScope.viewData.breadcrumbs = [{
            title: 'Users',
            link_name: 'Users',
            link_url: '#/team',
            help_url: $rootScope.help_url
        }];

        $scope.staticText = {
            title: "Users",
            subtitle: "You can see the users in your team below...",
            uploading: "Uploading",
            saving: "Saving",
            table: {
                header: ["First Name", "Last Name", "Email", "Plan", "Role"],
                noRes: "No Results",
                loading: "Loading",
                updating: 'Updating'
            },
            statusMessages: {
                default: 'User not Setup.',
                disabled: 'All Portal and Phone Access Disabled for this User.'
            },
            planModal: {
                header: "Change User Plan",
                body1: "Please select the new plan for ",
                body2: ". You can review plan features and pricing ",
                body3: "here",
                body4: "Please bear in mind that if you downgrade your user plan, you'll still be able to use your current plan features until the end of the billing term.",
                pricingUrl:  WEBPACK_URL + "pricing",
                confirm: "Confirm",
                cancel: "Cancel",
                plans: {}
            },
            roles: {
                Admin: 'full access',
                Manager: 'full access minus ability to change billing',
                Agent: 'read/write access to contact data, full phone privileges',
                Telemarketer: 'read only access to contact they are actively engaged with, full phone access',
                Analyst: 'read only access to the portal'
            },
            pendingPurchaseLink: {
                href: '#/subscriptions',
                info: 'You have pending subscription changes.',
                text: 'Proceed to Checkout.'
            }
        };
        
        $scope.loading = {
            usersFirstLoad: true,
            users: false
        };

        $scope.data = {
            users: [],
            selectedUserId: '',
            total_users: 0,
            team_roles: null,
            plans: [],
            plan_id: 'monthly-plan',
            pricing: PRODUCT_PRICING,
            annual: false,
            team: {},
            integrations: {}
        };

        $scope.user = $rootScope.user;

        $scope.defaultEmailValue = '--please add--';

        $scope.search = {
            term: '',
            submit: false
        };
        
        $scope.currentPage = 1;
        $scope.itemsPerPage = 13;
        $scope.maxSize = 3;
        $scope.maxSize = 3;

        $scope.isOwner = function(user) {
            return $scope.user.user_id === user.user_id
        };

        $scope.hasAction = function(user) {
            if($scope.isOwner(user) || $scope.user.role.permissions.users.can_edit ||
                ($scope.user.role.permissions.users.can_edit && user.identity.email && user.identity.email !== $scope.defaultEmailValue)) {
                return true;
            }
            return false;
        };

        $scope.hasPermissions = function(entity, action) {
            return $rootScope.hasPermissions(entity, action)
        };

        $scope.setPage = function (pageNo) {
            $scope.currentPage = pageNo;
        };

        $scope.pageChanged = function() {
            $window.scrollTo(0, 0);
            $scope.loadUsers((($scope.currentPage - 1) * $scope.itemsPerPage), $scope.itemsPerPage, 'users');
        };

        $scope.setItemsPerPage = function(num) {
            $scope.itemsPerPage = num;
            $scope.currentPage = 1; //reset to first page
        };

        $scope.dtOptions = DTOptionsBuilder.newOptions()
            .withLanguage({
                'sEmptyTable':  ''
            });
        $scope.dtOptions = DTDefaultOptions.setOption('searching', false);
        $scope.dtOptions = DTDefaultOptions.setOption('info', false);
        $scope.dtOptions = DTDefaultOptions.setOption('paging', false);
        $scope.dtOptions = DTDefaultOptions.setOption('processing', false);
        $scope.dtOptions = DTDefaultOptions.setOption('autoWidth', false);
        $scope.dtOptions = DTDefaultOptions.setOption('order', [ 2, 'asc' ]);
        $scope.dtOptions = DTDefaultOptions.setOption('columnDefs', [ { 'targets': [0, 6], sortable: false } ]);
        $scope.dtOptions = DTDefaultOptions.setDisplayLength(1);
        $scope.dtOptions = DTDefaultOptions.setLoadingTemplate('');

        $scope.setPlan = function(plan, item) {
            item.updating = true;

            // Set the pending plan change on the user
            let req = {
                user_id: authService.getUserId(),
                team_id: authService.getTeamId(),
                updated_user_id: item.user_id,
                pending_addon_id: plan.id
            };

            userService.updateUser(req)
                .then(user => {
                    item.pending_plan = plan.name;
                    item.pending_addon_id = plan.id;
                    item.updating = false;
                    $scope.$apply();

                    const forceUpdate = plan.id === 'inactive-monthly';
                    $scope.showContinueOrCheckoutSwal(forceUpdate);
                })
                .catch(err => {
                    swal("Server Error", "We were unable to update the user.  Try again later.", "error");
                    console.error(err);
                    item.updating = false;
                    $scope.$apply();
                });
        };

        $scope.showContinueOrCheckoutSwal = function (forceUpdate) {
            swal({
                title: forceUpdate ? 'Warning!' : 'Apply Changes?',
                text: forceUpdate
                    ? "You deactivated a user. This change will not take effect until you confirm your subscription changes and checkout."
                    : "You have pending subscription changes. Continue making changes or proceed to checkout?",
                buttons: ['Keep Editing', 'Checkout'],
                dangerMode: true,
                closeOnClickOutside: false,
                icon: 'warning'
            }).then((confirm) => {
                if (!confirm) { return; }

                $location.path( "/subscriptions" );
            });
        }

        $scope.setRole = function(role, item) {
            if ($scope.isRoleDisabledToSelect(role)) {
                return;
            }
            item.role_id = role.role_id;
            item.role_name = role.name;
            $scope.saveUser(item);
        };

        $scope.reloadUsers = function() {
            $scope.data.users = [];
            $scope.data.total_users = 0;
            $scope.loadUsers(0, $scope.itemsPerPage, 'usersFirstLoad');
        };

        $scope.clearSearchUsers = function() {
            $scope.search.submit = false;
            $scope.search.term = '';
            $scope.reloadUsers();
        };

        $scope.searchUsers = function() {
            $scope.search.term = $.trim($scope.search.term);
            if($scope.search.term !== '') {
                $scope.search.submit = true;
                $scope.reloadUsers();
            } else if($scope.search.submit) {
                $scope.reloadUsers();
            }
        };
        
        $scope.loadUsers = function(skip, limit, loader) {
            $scope.loading[loader] = true;

            var teamId = authService.getTeamId();
            var userId = authService.getUserId();

            const request = {
                team_id: teamId,
                user_id: userId,
                skip: skip || 0,
                limit: limit || $scope.itemsPerPage
            };

            if($scope.search.term) {
                request.search_term = $scope.search.term;
            }

            userService.listUsers(request)
                .then(results => {

                    let annual = false;

                    let user = authService.getUser() || {};
                    let chargebee = ((user.team || {}).chargebee || {});

                    let pendingCount = 0;

                    let users = ((results || {}).users || []);
                    for (let i = 0; i < users.length; i++) {

                        let user = users[i];
                        user.role_name = $scope.getRoleName(user.role_id);
                        user.plan = (PRODUCT_PRICING[chargebee.plan_id].packages[user.addon_id] || {}).name;
                        if (user.pending_addon_id) {
                            user.pending_plan = (PRODUCT_PRICING[chargebee.plan_id].packages[user.pending_addon_id] || {}).name;
                            pendingCount++;
                        }

                        annual = (chargebee.plan_id.indexOf('annual') >= 0);
                    }

                    $scope.data.annual = annual;
                    $scope.data.pending_user_count = pendingCount;
                    $scope.data.users = users;
                    $scope.data.total_users = (results || {}).total_users || 0;
                    $scope.loading[loader] = false;
                    $scope.$apply();
                })
                .catch(err => {
                    swal("Server Error", "We were unable to retrieve users for your account.  Try again later.", "error");
                    console.error(err);
                    $scope.loading[loader] = false;
                    $scope.$apply();
                });
        };

        $scope.checkUserName = function(fieldName, fieldValue) {
            let fieldNameFriendly = '';
            switch (fieldName) {
                case 'first_name':
                    fieldNameFriendly = 'First Name'
                    break;
                case 'last_name':
                    fieldNameFriendly = 'Last Name'
                    break;
            }
            if (fieldValue.length === 0) {
                return `${fieldNameFriendly} must have at least 1 character.`;
            }
        };
        
        $scope.saveUser = function(user) {
            user.updating = true;

            let req = {
                updated_user_id: user.user_id,
                role_id: user.role_id,
                username: user.username,
                email: user.identity.email,
                first_name: user.identity.first_name,
                last_name: user.identity.last_name,
                pin: user.auth.pin,
                status: user.status,
                user_id: authService.getUserId(),
                team_id: authService.getTeamId()
            };

            userService.updateUser(req)
                .then(results => {
                    user.role_name = $scope.getRoleName(user.role_id);
                    user.updating = false;
                    $scope.$apply();
                })
                .catch(err => {
                    swal("Server Error", "We were unable to update the user.  Try again later.", "error");
                    console.error(err);
                    user.updating = false;
                    $scope.$apply();
                });
        };

        $scope.updateBillingPlan = function() {

            let req = {
                cart: $scope.data.cart,
                user_id: authService.getUserId(),
                team_id: authService.getTeamId()
            };

            accountService.updateBilling(req)
                .then(results => {
                    $scope.pageChanged();
                })
                .catch(err => {
                    swal("Server Error", "We were unable to update the billing.  Try again later.", "error");
                    console.error(err);
                    $scope.$apply();
                });
        };

        $scope.changeDialerAccess = function(requirePin) {

            const updateTeamReq = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                dialin: {
                    require_pin: requirePin,
                    has_employees: $scope.data.total_users > 1
                }
            };

            accountService.updateTeam(updateTeamReq)
                .then(results => {
                    if(results && results.success) {
                        $scope.data.team.dialin.require_pin = requirePin;
                        swal("Success", "Dialer access changed", "success");
                    } else {
                        swal("Server Error", "We were unable to update the dialer access.  Try again later.", "error");
                    }
                    $scope.$apply();
                });
        };

        $scope.changeUserPlan = function(user) {

            /*
            let req = {
                updated_user_id: $scope.data.selectedUser.user_id,
                selected_plan: $scope.data.selectedPlan,
                user_id: authService.getUserId(),
                team_id: authService.getTeamId()
            };

            userService.updateUser(req)
                .then(user => {
                    swal("Updated", "Plan for user " + $scope.data.selectedUser.identity.first_name + " " + $scope.data.selectedUser.identity.last_name +" has been updated", "success");
                    $scope.loading.users = false;
                    $scope.updateUserPlan(user);
                    $scope.updateUserCookie(user);
                    $scope.hideUserPlanModal();
                    $scope.$apply();
                })
                .catch(err => {
                    swal("Server Error", "We were unable to update the user.  Try again later.", "error");
                    console.error(err);
                    $scope.loading.users = false;
                    $scope.$apply();
                });
             */
        };

        $scope.updateUserCookie = function(user) {

            let userCookie = $rootScope.secureLS.get('user');
            if(userCookie.user_id === user.user_id){
                userCookie.plan_id = user.plan_id;
                $rootScope.secureLS.set('user', userCookie);
            }
        };
        
        $scope.getRoleName = function(roleId) {
            const roles = $scope.data.team_roles || [];
            for (let i = 0; i < roles.length; i++) {
                if (roles[i].role_id == roleId) { return roles[i].name; }
            }
            return 'Undefined';
        };

        $scope.__getAgentRole = function() {
            const roles = $scope.data.team_roles || [];
            for (let i = 0; i < roles.length; i++) {
                if (roles[i].name == 'Agent') { return roles[i]; }
            }
            return null;
        };

        $scope.updateUserPlan = function(user) {

            for (var i=0; i<$scope.data.users.length; i++){
                if($scope.data.users[i].user_id === user.user_id){
                    $scope.data.users[i].plan = PRODUCT_PRICING[user.plan_id].name
                }
            }
        };

        // $scope.enableUser = function(user, enable) {
        //
        //     if(enable && !(($scope.data.utilization[user.addon_id] || {}).available > 0) ){
        //         swal("No Available Licenses", "Please purchase additional licenses before enabling this user.\r\n\r\nTo purchase licenses, press the 'Manage Subscriptions' button.", "error");
        //         return;
        //     }
        //
        //     user.status = ( enable ? 'ENABLED' : 'DISABLED' );
        //     $scope.saveUser(user);
        //
        //     let change = enable ? -1 : 1;
        //     $scope.data.utilization[user.addon_id].available = $scope.data.utilization[user.addon_id].available + change;
        // };

        $scope.sendInvite = function(user) {

            let req = {
                updated_user_id: user.user_id,
                user_id: authService.getUserId(),
                team_id: authService.getTeamId()
            };

            userService.sendInvite(req)
                .then(res => {
                    if (res.email_status === 200) {
                        swal("Success", "The invitation has been sent", "success");
                    } else {
                        swal("Error", res.email_err, "error");
                    }

                    $scope.loading.users = false;
                    $scope.$apply();
                })
                .catch(err => {
                    swal("Server Error", "We were unable to send invite.  Try again later.", "error");
                    console.error(err);
                    $scope.loading.users = false;
                    $scope.$apply();
                });
        };

        $scope.bulkAddUser = function() {
            if ( $scope.loading.users || $scope.loading.usersFirstLoad ) { return; }

            let agentRole = $scope.__getAgentRole() || {};

            let req = {
                role_id: agentRole.role_id,
                status: 'DISABLED',
                addon_id: 'back-office-monthly',
                pending_addon_id: 'back-office-monthly',
            };

            $rootScope.$broadcast('addUserEvent', req);
        };

        $scope.editUser = function(user) {

            let localUser = authService.getUser();
            let chargebee = (localUser.team || {}).chargebee || {};
            chargebee.utilization = $scope.data.utilization || {};
            chargebee.plans = $scope.data.plans || [];

            $rootScope.$broadcast('editUserEvent', user.user_id);
        };

        $scope.$on('addEditUserEventDone', function(event, mode, isSuccess, showContinueOrCheckoutSwal) {
            if(isSuccess) {
                $scope.loadUsers((($scope.currentPage - 1) * $scope.itemsPerPage), $scope.itemsPerPage, 'users');
            }
            if (showContinueOrCheckoutSwal) {
                $scope.showContinueOrCheckoutSwal();
            }
        });

        $scope.showUserPlanModal = function(user) {
            $scope.data.selectedPlan = user.addon_id;
            $scope.data.selectedUser = user;
            $("#planModal").modal('show');
        };

        $scope.hideUserPlanModal = function() {

            $("#planModal").modal('hide');
        };

        $scope.openCheckout = function() {

            $rootScope.$broadcast('openCheckout', $scope.data.utilization);
        };

        $scope.getTeamDetails = function() {
            let req = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
            };

            accountService.getTeam(req)
                .then((team)=> {
                    $scope.data.team = team || {};
                    $scope.$apply();
                });
        };

        $scope.isRoleDropdownDisabled = function (user) {
            return $scope.user.user_id === user.user_id ||
                (
                    $scope.user.role.role_id === $rootScope.settings.MANAGER_ROLE_ID &&
                    user.role_id === $rootScope.settings.ADMIN_ROLE_ID
                ) ||
                !(
                    $scope.user.role.role_id === $rootScope.settings.ADMIN_ROLE_ID ||
                    $scope.user.role.role_id === $rootScope.settings.MANAGER_ROLE_ID
                )
        }

        $scope.isRoleDisabledToSelect = function (role) {
            return $scope.user.role.role_id === $rootScope.settings.MANAGER_ROLE_ID && role.role_id === $rootScope.settings.ADMIN_ROLE_ID
        }

        $scope.checkUserEmail = function(email) {
            const regEmail = $rootScope.settings.USER_EMAIL_REGEX;
            if (!regEmail.test(email)) {
                return 'The Email is not valid.';
            }
        };

        $scope.planIsVisable = (plan) => {
            if ($scope.data.integrations['shopify'] && $scope.data.integrations['shopify'].active) {
                return plan.integrationType === 'shopify';
            }

            return !plan.integrationType;
        };

        $scope.getWebhooks = () => {
            return integrationService.list()
                .then(res => {
                    integrationService.set(res);

                    $scope.data.integrations = integrationService.get();
                    $scope.$apply();
                })
        }

        $scope.$on('subscription.changed', function(e, ablyEvent) {
            $scope.pageChanged();
        });

        $scope.onInit = function() {
            $window.scrollTo(0, 0);
            $scope.data.team_roles = authService.getUser().team.roles;
            $scope.loadUsers(0, $scope.itemsPerPage, 'usersFirstLoad');

            $scope.getTeamDetails();
            $scope.getWebhooks();
        };

        if (authService.hasAccount()) {
            $scope.onInit();
        }
        else {
            $scope.$on('auth_complete', function(event, args) {
                $scope.onInit();
            });
        }
    }
    module.exports = TeamController;
})();
