(function () {
    'use strict';

    function ListConfigController($rootScope, $scope, $location, userService, authService, phoneService, mediaService, contactService, dispatcherService) {
        var vm = this;
        vm.authService = authService;

        $rootScope.viewData = $rootScope.viewData || {};
        $rootScope.viewData.breadcrumbs = $rootScope.viewData.breadcrumbs || [];
        $rootScope.viewData.breadcrumbs = [{
            title: 'Lists',
            link_name: 'Lists',
            link_url: '#/lists',
            help_url: $rootScope.help_url
        }, {
            title: 'List Configuration',
            link_name: 'List Configuration',
            link_url: '#/list-config'
        }];

        $scope.data = {
            mediaTypes: [
                {
                    type: "abandon_ids",
                    useCase: "Contact call has been adandoned"
                },
                {
                    type: "voicemail_ids",
                    useCase: "Contact call is answered with a voicemail"
                }
            ],
            selectedListId: ($location.search().listId || ''),
            assigned_recordings: {},
            recordings: {},
            users: [],
            user_groups: [],
            selected_roles: {},
            selected_users: {},
            selected_user_group_id: null,
            selected_visibility: 'Everyone'
        };

        $scope.loading = {
            recordings: false,
            list: false,
            user_groups: false
        };

        $scope.saving = {
            list: false
        };

        $scope.staticData = {
            mediaTypeBlock: {
                title: "Recordings",
                subtitle: "Define which of your recordings you want to use for these different scenarios",
                table: {
                    titleTd: {
                        whenText: "When",
                    },
                    optionDropdownTd: {
                        thenText: "Then",
                        options: ["Play"]
                    },
                    optionTd: {
                        voicemail: {
                            placeholder: "Please leave your message after the tone"
                        }
                    },
                    recordings: {
                        createBtn: {
                            text: 'Create a Recording',
                            href: '#/recordings'
                        },
                        dropDownBtn: 'Please choose recording...',
                    }
                },
                actions: {
                    saveBtn: "Save"
                },
                loader: "Loading",
                saving: "Saving"
            },
            visibilityBlock: {
                title: "Visiblity",
                subtitle: "Define who's able to view and dial this contact list",
                table: {
                    visibleBy: {
                        text: "Visible By",
                        options: ["Everyone", "Roles", "Users", "Teams"]
                    }
                }
            }
        };
        
        $scope.loadRecordings = function() {
            $scope.loading.recordings = true;

            const request = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                include_urls: true,
                media_exists: true,
            };

            mediaService.listMediaUrls(request)
                .then(results => {
                    var lists = (_.sortBy(results, 'created_at').reverse()) || [];
                    $scope.data.recordings = lists;
                    $scope.loading.recordings = false;
                    $scope.$apply();
                })
                .catch(err => {
                    console.error(err);
                    $scope.loading.recordings = false;
                    $scope.$apply();
                });
        };

        $scope.loadData = function() {

            $scope.loading.list = true;

            const teamId = authService.getTeamId();
            const userId = authService.getUserId();

            const requestList = {
                team_id: teamId,
                user_id: userId,
                list_id: $location.search().listId
            };
            // load list
            contactService.getList(requestList)
                .then(list => {
                    $scope.loading.list = false;
                    $scope.data.list = list;
                    if($scope.data.list.recordings){
                        $scope.data.assigned_recordings = {};
                        for (let type in $scope.data.list.recordings){
                            $scope.data.assigned_recordings[type] = { play_media_id: $scope.data.list.recordings[type][0]};
                        }
                    }
                    $scope.$apply();

                    // load users
                    const requestUsers = {
                        team_id: teamId,
                        user_id: userId
                    };
                    $scope.loading.users = true;
                    return userService.listUsers(requestUsers);
                })
                .then(results => {
                    $scope.loading.users = false;
                    $scope.data.users = results.users || [];
                    $scope.$apply();

                    // load user groups (teams)
                    const requestUserGroups = {
                        team_id: teamId,
                        user_id: userId,
                        sort: 'asc'
                    };
                    $scope.loading.user_groups = true;
                    return userService.listUserGroups(requestUserGroups);
                })
                .then(results => {
                    $scope.data.user_groups = results.teams;
                    $scope.loading.user_groups = false;
                    $scope.formatVisibilityOnForm();
                    $scope.$apply();
                })
                .catch(err => {
                    console.error(err);
                    $scope.loading.list = false;
                    $scope.loading.users = false;
                    $scope.loading.user_groups = false;
                    $scope.$apply();
                });
        };

        $scope.loadRecordings = function() {
            $scope.loading.recordings = true;

            const request = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                media_exists: true,
                list_id: $location.search().listId
            };

            mediaService.listMediaUrls(request)
                .then(results => {
                    var lists = (_.sortBy(results, 'created_at').reverse()) || [];
                    $scope.data.recordings = lists;
                    $scope.loading.recordings = false;
                    $scope.$apply();
                })
                .catch(err => {
                    console.error(err);
                    $scope.loading.recordings = false;
                    $scope.$apply();
                });
        };

        $scope.setRecording = function(type, value) {
            if (value) {
                if(!$scope.data.assigned_recordings[type]) {
                    $scope.data.assigned_recordings[type] = {};
                }
                $scope.data.assigned_recordings[type] = value.media_id;
            }
        };

        $scope.setVisibilityType = function(type) {
            $scope.data.selected_visibility = type;
            // $scope.$apply();
        };
        
        $scope.getRecordingName = function(media_id) {
            var index = _.findIndex($scope.data.recordings, ['media_id', media_id]);
            if(index > -1)
                return $scope.data.recordings[index].name;
            return $scope.staticData.mediaTypeBlock.table.recordings.dropDownBtn;
        };

        $scope.formatVisibility = function(media_id) {

            let visibility = {
                type: $scope.data.selected_visibility.toLowerCase(),
            };

            switch(visibility.type){

                case 'roles':
                    let roleIds = [];

                    for(var roleId in $scope.data.selected_roles){
                        if($scope.data.selected_roles[roleId]) {
                            roleIds.push(roleId)
                        }
                    }
                    visibility['role_ids'] = roleIds;
                    break;

                case 'users':
                    let userIds = [];

                    for(var userId in $scope.data.selected_users){
                        if($scope.data.selected_users[userId]) {
                            userIds.push(userId)
                        }
                    }
                    visibility['user_ids'] = userIds;
                break;

                case 'teams':
                    visibility['user_group_id'] = $scope.data.selected_user_group_id;
                    break;

            }

            return visibility;
        };

        $scope.saveList = function() {

            let updatePromises = [];

            const updateListReq = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                list_id: $location.search().listId,
                visibility: $scope.formatVisibility()
            };

            updatePromises.push(contactService.updateList(updateListReq));

            if($scope.data.assigned_recordings.abandon_ids || $scope.data.assigned_recordings.voicemail_ids) {

                const updateDispatcherReq = {
                    team_id: authService.getTeamId(),
                    user_id: authService.getUserId(),
                    list_id: $location.search().listId,
                    abandon_ids: $scope.data.assigned_recordings.abandon_ids,
                    voicemail_ids: $scope.data.assigned_recordings.voicemail_ids
                };

                updatePromises.push(dispatcherService.updateDispatcher(updateDispatcherReq));
            }

            $scope.loading.list = true;

            Promise.all(updatePromises)
                .then(results => {
                    $scope.loading.list = false;
                    $scope.$apply();
                })
                .catch(err => {
                    console.error(err);
                    $scope.loading.list = false;
                    $scope.$apply();
                });
        };

        $scope.formatVisibilityOnForm = function() {

            switch($scope.data.list.visibility.type){

                case 'roles':
                    for (const index in $scope.data.list.visibility.role_ids){
                        $scope.data.selected_roles[$scope.data.list.visibility.role_ids[index]] = true;
                    }
                    break;

                case 'users':
                    for (const index in $scope.data.list.visibility.user_ids){
                        $scope.data.selected_users[$scope.data.list.visibility.user_ids[index]] = true;
                    }
                    break;

                case 'teams':
                    $scope.data.selected_user_group_id = $scope.data.list.visibility.user_group_id;
                    break;

            }
            $scope.data.selected_visibility = $scope.data.list.visibility.type.charAt(0).toUpperCase() + $scope.data.list.visibility.type.slice(1);
        };
        
        $scope.onInit = function() {
            $scope.data.team_roles = authService.getUser().team.roles;
            $scope.loadRecordings();
            $scope.loadData();
        };

        if (authService.hasAccount()) {
            $scope.onInit();
        }
        else {
            $scope.$on('auth_complete', function(event, args) {
                $scope.onInit();
            });
        }

    }
    module.exports = ListConfigController;
})();
