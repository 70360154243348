(function() {
    'use strict';

    function RightSidebarController(authService, $rootScope, $scope, $state, $window, intercomService) {
        var vm = this;
        vm.authService = authService;

        $scope.callMode = '';
        $scope.rightPanelView = 'default';
        $scope.pageWidthLimit = 1425;
        $scope.rightSideOpen = false;

        $scope.showRightSideToggleBtn = function(isVisible, callback) {
            $scope.rightSideOpen = !isVisible;
            var leftPosition = (isVisible) ? '-70px' :'-60px';
            $('.min-max-tab').css('left', leftPosition);
            callback();
        };

        $scope.rightPanelTransitionEnd = function() {
            var shownOrHidden = ($('#rightPanel').hasClass('shw-rside')) ? 'shown' : 'hidden';
            $rootScope.$broadcast(shownOrHidden + 'RightSideBar');

            intercomService.changeIntercomHorizontalPadding((shownOrHidden === 'shown') ? true : false);

            if (shownOrHidden === 'shown') {
                $("button[part='acsb-trigger']").css('right', '428px');
            }
            else {
                $("button[part='acsb-trigger']").css('right', '68px');
            }

            document.querySelector('#rightPanel').removeEventListener('transitionend', $scope.rightPanelTransitionEnd);
        };

        $scope.showRightSidePanel = function() {
            if(document.querySelector('#rightPanel')) {
                document.querySelector('#rightPanel').addEventListener('transitionend', $scope.rightPanelTransitionEnd);
            }

            var width = (window.innerWidth > 0) ? window.innerWidth : this.screen.width;

            if (width > $scope.pageWidthLimit) {
                $('.page-wrapper').addClass('with-right-sidebar');
                $('.footer').addClass('with-right-sidebar');
            }
            $('#rightPanel').addClass('shw-rside');

            $window.localStorage['right_side_panel'] = 'show';
            $scope.showRightSideToggleBtn(false, ()=>{});
        };

        $scope.hideRightSidePanel = function() {
            if(document.querySelector('#rightPanel')) {
                document.querySelector('#rightPanel').addEventListener('transitionend', $scope.rightPanelTransitionEnd);
            }

            $('.page-wrapper').removeClass('with-right-sidebar');
            $('.footer').removeClass('with-right-sidebar');
            $('#rightPanel').removeClass('shw-rside');

            $window.localStorage['right_side_panel'] = 'hide';
            $scope.showRightSideToggleBtn(true, ()=>{});
        };

        $scope.toggleRightSideBar = function() {
            $('#rightPanel').hasClass('shw-rside') ? $scope.hideRightSidePanel() : $scope.showRightSidePanel();
        };

        $scope.$on('hideRightSideBar', function () {
            $scope.hideRightSidePanel();
        });

        $scope.$on('showRightSideBar', function () {
            $scope.showRightSidePanel();
        });

        $scope.$on('toggleRightSideBar', function () {
            $scope.toggleRightSideBar();
        });

        $scope.$on('callModeRightSideBar', function (event, mode) {
            $scope.callMode = mode;
        });

        $scope.visibilityRightSidePanelLoadOrResize = function() {
            var width = (window.innerWidth > 0) ? window.innerWidth : this.screen.width;
            var addRemoveAnimation = 'addClass';

            if (width < $scope.pageWidthLimit) {
                $scope.hideRightSidePanel();
                addRemoveAnimation = 'removeClass';
            }

            $('.page-wrapper')[addRemoveAnimation]('toogle-right-sidebar');
            $('.footer')[addRemoveAnimation]('toogle-right-sidebar');
        };

        $scope.setView = function(view, hidePanel) {
            var width = (window.innerWidth > 0) ? window.innerWidth : this.screen.width;
            $scope.rightPanelView = view;
            if (width < $scope.pageWidthLimit || ($window.localStorage['right_side_panel'] === 'hide')) {
                hidePanel = true;
            }
            (hidePanel) ? $scope.hideRightSidePanel() : $scope.showRightSidePanel();
            intercomService.changeIntercomHorizontalPadding((hidePanel) ? false : true);
        };

        $scope.checkRightPanelView = function(currentStateUrl) {
            switch(currentStateUrl) {
                case '/inbox':
                    $scope.pageWidthLimit = 1750;
                    $('#rightPanel').addClass('box-shadow-1750');
                    if($scope.callMode !== 'dialing' && $scope.callMode !== 'talking') {
                        $scope.setView('inbox', true);
                        break;
                    }
                    $scope.setView('default', false);
                    break;
                case '/buckets':
                    $scope.pageWidthLimit = 1750;
                    $('#rightPanel').addClass('box-shadow-1750');
                    if($scope.callMode !== 'dialing' && $scope.callMode !== 'talking') {
                        $scope.setView('buckets', true);
                        break;
                    }
                    $scope.setView('default', false);
                    break;
                case '/welcome':
                case '/checklist':
                    $scope.pageWidthLimit = 1750;
                    $('#rightPanel').addClass('box-shadow-1750');
                    $scope.setView('default', true);
                    break;
                default:
                    $scope.pageWidthLimit = 1425;
                    $('#rightPanel').removeClass('box-shadow-1750');
                    $scope.setView('default', false);
            }
        };

        $rootScope.$on('$stateChangeStart', function(e, toState, toParams, fromState, fromParams) {
            $scope.checkRightPanelView(toState.url);
        });
        
        $scope.onInit = function() {
            $scope.checkRightPanelView($state.current.url);
            $scope.visibilityRightSidePanelLoadOrResize();

            angular.element($window).bind('resize', function(){
                $scope.visibilityRightSidePanelLoadOrResize();
            });
        };

        if (authService.hasAccount()) {
            $scope.onInit();
        }
        else {
            $scope.$on('auth_complete', function(event, args) {
                $scope.onInit();
            });
        }
    }
    module.exports = RightSidebarController;
})();
