(function() {
    'use strict';

    function EmptyWrapperController(authService, $rootScope, $scope, $state) {
        var vm = this;
        vm.authService = authService;

        $scope.currentPage = undefined;

        $scope.checkView = function(currentStateUrl) {
            switch(currentStateUrl) {
                case '/signin':
                    $scope.currentPage = 'signin';
                    break;
                case '/signup':
                    $scope.currentPage = 'signup';
                    break;
            }
        };

        $rootScope.$on('$stateChangeStart', function(e, toState, toParams, fromState, fromParams) {
            $scope.checkView(toState.url);
        });

        $scope.onInit = function() {
            $scope.checkView($state.current.url);
        };
        $scope.onInit();
    }
    module.exports = EmptyWrapperController;
})();
