(function() {
    'use strict';

    function AuthHeaderController($rootScope, $scope, $window, $state) {

        $scope.staticData = {
            signupBtn: {
                label: 'Don\'t have an account?',
                title: 'Sign Up',
                href: '#/signup'
            },
            signinBtn: {
                label: 'Already have an account?',
                title: 'Log In',
                href: '#/signin'
            }
        };

        $scope.currentPage = undefined;
        $scope.appIcon = $rootScope.settings.APP_ICON;
        $scope.appLogo = $rootScope.settings.APP_LOGO;
        
        $scope.headerInit = function() {
            var width = (window.innerWidth > 0) ? window.innerWidth : this.screen.width;
            var topOffset = 70;
            if (width < 1024) {
                $('body').addClass('mini-sidebar');
                $('.full-logo').hide();
                $('.icon-logo').show();
            }
            else {
                $('body').removeClass('mini-sidebar');
                $('.icon-logo').hide();
                $('.full-logo').show();
            }
            var height = ((window.innerHeight > 0) ? window.innerHeight : this.screen.height) - 1;
            if (height < 1) height = 1;
            if (height > topOffset) {
                $('.page-wrapper').css('min-height', (height) + 'px');
            }
        };

        angular.element($window).bind('resize', function(){
            $scope.headerInit();
        });

        $scope.checkView = function(currentStateUrl) {
            $scope.currentPage = (currentStateUrl === '/signup') ? 'signup' : 'signin';
        };

        $rootScope.$on('$stateChangeStart', function(e, toState, toParams, fromState, fromParams) {
            $scope.checkView(toState.url);
        });
        
        $scope.onInit = function() {
            $scope.checkView($state.current.url);
            $scope.headerInit();
            
            $('.fix-header .topbar').stick_in_parent({bottoming: false});
        };

        $scope.onInit();
    }
    module.exports = AuthHeaderController;
})();
