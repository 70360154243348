(function () {
    'use strict';

    function RecordingsController($rootScope, $scope, $location, authService, mediaService, $interval, $window, $timeout) {
        var vm = this;
        vm.authService = authService;

        $rootScope.viewData = $rootScope.viewData || {};
        $rootScope.viewData.breadcrumbs = $rootScope.viewData.breadcrumbs || [];
        $rootScope.viewData.breadcrumbs = [{
            title: 'Recordings',
            link_name: 'Recordings',
            link_url: '#/recordings',
            help_url: $rootScope.help_url
        }];
    
        $scope.staticData = {
            title: 'Recordings',
            subtitle: 'You can manage your recordings below...',
            addBtn: {
                title: 'Add Recording'
            },
            table: {
                header: ['Name', 'Date Uploaded'],
                actions: [
                    {
                        title: 'Delete Recording'
                    }
                ],
                noRes: 'You don\'t have any recordings.'
            },
            loading: 'Loading',
            uploading: 'Uploading',
            saving: 'Saving',
            modal: {
                title: 'Add Recording',
                nameBlock: {
                    title: 'Recording Name',
                    desc: 'Please, enter name for your recording'
                },
                optionsBlock: {
                    title: 'Option',
                    subtitle: 'Please, select option',
                    option1: {
                        title: 'Upload a File',
                        desc: 'Upload your File. Drop a MP3 or WAV audio file below.'
                    },
                    option2: {
                        title: 'Dial in by Phone',
                        desc: 'Call the phone number below to record your message.',
                        ul: {
                            text1: 'Call',
                            text2: 'from your phone.',
                            text3: 'Enter your pin:',
                            text4: 'Record your message when prompted.'
                        },
                        descAfter: 'This page will automatically updated once you record your message.'
                    }
                },
                fileUploadedMessage: 'Success! File uploaded!',
                submitBtn: 'Save'
            }
        };
    
        $scope.recordings = [];
        $scope.isGetStarted = false;
        
        $scope.newRecording = {};
        
        $scope.loading = {
            recordingsFirstLoad: true,
            recordings: false,
            media: false,
            uploading: false,
            saving: false
        };
        
        $scope.dropify = {
            options: {},
            events: {}
        };
        
        $scope.dialIn = {
            media_id: null,
            phone: '',
            pin: ''
        };
        
        $scope.policy = {};
        $scope.stopTimeDialIn = undefined;

        $scope.totalItems = 0;
        $scope.currentPage = 1;
        $scope.itemsPerPage = 12;
        $scope.maxSize = 3;

        $(document).on('hide.bs.modal', '#addRecordingModal', function() {
            if($scope.newRecording.saved) {
                $scope.loading.media = false;
                $scope.cleanModal();
            } else {
                $scope.confirmDelete($scope.newRecording, function(res) {
                    $scope.loading.media = false;
                    $scope.cleanModal();
                    $scope.$apply();
                });
            }
            
        });
        
        $scope.saveNewRecording = function() {
            if($scope.newRecording.name && $scope.newRecording.uploaded) {
                $scope.loading.saving = true;
                
                if($scope.newRecording.option === 'dial_in_by_phone')
                    $interval.cancel($scope.stopTimeDialIn);

                let request = {
                    team_id: authService.getTeamId(),
                    user_id: authService.getUserId(),
                    media_id: $scope.newRecording.media_id,
                    name: $scope.newRecording.name,
                    media_exists: true
                };

                mediaService.updateMedia(request)
                    .then(results => {
                        $scope.newRecording.saved = true;
                        $scope.loading.saving = false;
                        $scope.$apply();
                        $('#addRecordingModal').modal('hide');
                        swal("Success!", "New Recording is now available", "success"); 
                        $scope.loadRecordings(true, 0, 'recordings');
                    })
                    .catch(err => {
                        console.log(err);
                        swal("Cancelled", "Unable to update Recording", "error");
                        $scope.loading.saving = false;
                        $scope.$apply();
                    });
            }
        };
        
        $scope.validation = function() {
            if($scope.newRecording.name && $scope.newRecording.uploaded)
                return true;
            return false;
        };
        
        $scope.__watchForRecording = function(dialIn, ext) {
            return new Promise((resolve, reject) => {

                const request = {
                    team_id: authService.getTeamId(),
                    user_id: authService.getUserId(),
                    media_id: dialIn.media_id,
                    ext: ext
                };

                mediaService.getUrl(request)
                    .then(results => resolve(results))
                    .catch(err => resolve());
            });
        };
        
        $scope.watchForRecording = function() {
            var dialIn = $scope.dialIn || {};
            if (dialIn.media_id) {
                Promise.all([$scope.__watchForRecording(dialIn, '.mp3'), $scope.__watchForRecording(dialIn, '.wav')])
                    .then(results => {
                        let url = ((results[0] || {}).url || (results[1] || {}).url);
                        if (url) {
                            $scope.newRecording.url = url;
                            $scope.newRecording.uploaded = true;
                            $scope.$apply();
                            $timeout(function(){
                                $scope.saveNewRecording();
                            });
                        }
                    })
                    .catch(err => {});
            }
        };
        
        $scope.__uploadFile = function(file, policyType) {

            file = $scope.newRecording.file;
            policyType = $scope.newRecording.ext;

            var policy = $scope.policy[policyType];
            if (!policy) {
                swal("Cancelled", "Unable to upload. Please try again later.", "error");
            }
            else {
                $scope.loading.uploading = true;
                $.ajax({
                    type: 'PUT',
                    url: policy.url,
                    contentType: policy.content_type,
                    processData: false,
                    data: file
                })
                .done(function(data, textStatus, jqXHR) {
                    $scope.getRecordURL($scope.newRecording.media_id, ('.' + $scope.newRecording.ext), function(data) {
                        $scope.newRecording.url = data.url;
                        
                        $scope.loading.uploading = false;
                        $scope.newRecording.uploaded = true;
                        $scope.$apply();
                        $timeout(function(){
                            $scope.saveNewRecording();
                        });
                    });
                })
                .fail(function(jqXHR, textStatus, errorThrown) {
                    $scope.loading.uploading = false;
                    $scope.$apply();
                    swal("Cancelled", "File NOT uploaded", "error");
                });
            }
        };
        
        $scope.formatDialInPhone = function(value, forceRegion) {
            const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
            var phoneLocale = '';
            try {
                var region; // intentionally undefined
                if (!forceRegion) {
                    region = 'US';
                }
                var numberFormat = (region === 'US' || region === 'CA') ? libphonenumber.PhoneNumberFormat.NATIONAL : libphonenumber.PhoneNumberFormat.INTERNATIONAL;

                var parsed = phoneUtil.parse(value || '', region);
                if (parsed) {
                    phoneLocale = phoneUtil.format(parsed, numberFormat);
                }
            }
            catch(e) {
                console.log(e);
            }
            return phoneLocale || value;
        };

        $scope.hasPermissions = function(entity, action) {
            return $rootScope.hasPermissions(entity, action);
        };
        
        $scope.setPhoneNumber = function(phoneNumber) {
            $scope.dialIn.phone = phoneNumber;
            $scope.dialIn.formatted_phone = $scope.formatDialInPhone(phoneNumber, 'ZZ');
        };
        
        $scope.formatDialInPin = function(value) {
            var pin = value || '';
            return pin.substr(0, 3) + '-' + pin.substr(3, 3) + '-' + pin.substr(6);
        };
        
        $scope.loadPolicy = function(teamId, mediaId) {
            const request = {
                team_id: teamId,
                user_id: authService.getUserId(),
                media_id: mediaId
            };

            mediaService.getPolicy(request)
                .then(results => {
                    $scope.policy = results;
                    $scope.loading.media = false;
                    $scope.$apply();
                })
                .catch(err => {
                    console.error(err);
                    $scope.loading.media = false;
                    $scope.$apply();
                });
        };
        
        $scope.createMedia = function(option) {
            $scope.loading.media = true;

            const request = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                name: null,
                generate_pin: (option === 'dial_in_by_phone') ? true : false
            };

            mediaService.createMedia(request)
                .then(results => {
                    $scope.newRecording = angular.merge($scope.newRecording, results);
            
                    switch(option) {
                        case 'upload_a_file':
                            $scope.loadPolicy(request.team_id, results.media_id);
                            break;
                        case 'dial_in_by_phone':
                            $scope.dialIn = {
                                media_id: results.media_id,
                                phone_list: (results.phone || '').split(','),
                                pin: results.pin,
                                formatted_pin: $scope.formatDialInPin(results.pin)
                            };
                            $scope.dialIn.phone = $scope.dialIn.phone_list[0];
                            $scope.dialIn.formatted_phone = $scope.formatDialInPhone($scope.dialIn.phone, 'ZZ');
                            $scope.loadPolicy(request.team_id, results.media_id);
                            $scope.stopTimeDialIn = $interval(function() {
                                $scope.watchForRecording(); 
                            }, 5000);
                            break;
                    }
                })
                .catch(err => {
                    console.error(err);
                    $scope.loading.media = false;
                    $scope.$apply();
                });
        };
        
        $scope.cleanModal = function() {
            angular.element('.dropify-clear').triggerHandler('click');
            $scope.dialIn = {
                media_id: null,
                phone: '',
                pin: ''
            };
            $scope.newRecording = {};
            $scope.policy = {};
            $scope.loading.uploading = false;
        };
        
        $scope._setUploadOption = function(option, name) {
            $scope.newRecording.option = option;
            $scope.newRecording.name = name;
            $scope.$apply();
            $scope.createMedia(option);
        };
        
        $scope.setUploadOption = function(value) {
            $scope.loading.media = true;
            if($scope.newRecording.media_id) {
                let name = $scope.newRecording.name;
                if($scope.stopTimeDialIn)
                    $interval.cancel($scope.stopTimeDialIn);
                $scope.confirmDelete($scope.newRecording, function(res) {
                    $scope.cleanModal();
                    $scope.$apply();
                    
                    $scope._setUploadOption(value, name);
                });
            } else {
                $scope.newRecording = {};
                $scope._setUploadOption(value, 'My Recording');
            } 
        };
        
        $scope.initDropify = function() {
            $('.dropify-mp3').dropify({
                messages: {
                'default': 'Drag and drop a wav or mp3 file here or click',
                'replace': 'Drag and drop or click to replace',
                'remove':  'Remove',
                'error':   'The file is not allowed (mp3, wav only).'
            }});
        
            $('.dropify-mp3').change(function(event) {
                var target = event.currentTarget || {};
                if (target && target.files && target.files.length) {
                    $scope.loading.uploading = true;
                    $scope.$apply();

                    var file = target.files[0];
                    var parts = (file.name || '').split('.');
                    var ext = (parts[parts.length - 1] || '').toLowerCase();
                    if (ext === 'mp3' || ext === 'wav') {
                        $scope.newRecording.ext = ext;
                        $scope.newRecording.file = file;
                    }
                    $scope.loading.uploading = false;
                }
            });
            
            $('.dropify-mp3').dropify().on('dropify.beforeClear', function(event, element){
                $scope.newRecording.uploaded = false;
                $scope.newRecording.url = undefined;
                $scope.$apply();
            });
        };

        $scope.saveRecord = function() {
            if (!$scope.newRecording.file) {
                swal("Error", "Upload your File. Drop a MP3 or WAV audio file below.", "error");
                return;
            }
            $scope.__uploadFile();
        }
        
        $(document).on("show.bs.modal", "#addRecordingModal", function() {
            $scope.setUploadOption('upload_a_file');
        });
        
        ///////////////////////////////////////////////////////

        $scope.updateRecordingName = function(recording) {

            var request = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                media_id: recording.media_id,
                name: recording.name
            };
            mediaService.updateMedia(request)
                .then(results => {})
                .catch(err => {
                    console.log(err);
                    swal("Cancelled", "Unable to update Recording", "error");
                });
        };
        
        $scope.newNameValue = '';
        $scope.editNameField = function(recording) {
            if($scope.newNameValue !== recording.name) {
                $scope.newNameValue = recording.name;
                $scope.updateRecordingName(recording);
            }
        };

        $scope.confirmDelete = function(recording, callback) {
            
            var req = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                media_id: recording.media_id
            };

            mediaService.deleteMedia(req)
                .then(results => {
                    callback(null);
                })
                .catch(err => {
                    console.log(err);
                    callback(err);
                });
        };
        
        $scope.deleteRecording = function(e, recording) {
            if (e) {
                e.preventDefault();
            }
            
            swal({   
                title: "Confirm",   
                text: "Are you sure you want to delete this recording?",
                buttons: ["No", "Yes"],
                dangerMode: true,
                closeOnClickOutside: false
            }).then(isConfirm =>{
                if (isConfirm) { 
                    $scope.confirmDelete(recording, function(res) {
                        if(res === null) {
                            swal("Deleted!", "This Recording has been deleted!", "success"); 
                            $scope.loadRecordings(false);
                            $scope.$apply();
                        } else swal("Cancelled", "Unable to delete Recording", "error");
                    });
                } 
            });
        };
        
        $scope.getRecordURL = function(mediaId, ext, callback) {

            const request = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                media_id: mediaId,
                ext: ext
            };
            
            mediaService.getUrl(request)
            .then(results => {
                callback(results);
            })
            .catch(err => {
                console.error(err);
                callback(null);
            });
        };

        $scope.checkRecordingName = function(name) {
            if (name.length === 0) {
                return "Recording Name must have at least 1 character.";
            }
        };

        // player start
        $scope.currentOpenedPlayerId = -1;
        $scope.$on('currentOpenedRecordChanged', function(event, newCurrentOpenedPlayerId, recordType) {
            $scope.currentOpenedPlayerId = newCurrentOpenedPlayerId;
        });
        $scope.$on('audioObjectChanged', function(event, audioObject, index, recordType) {
            $scope.recordings[index].audio = audioObject;
        });
        // player end

        $scope.setPage = function (pageNo) {
            $scope.currentPage = pageNo;
        };

        $scope.setItemsPerPage = function(num) {
            $scope.itemsPerPage = num;
            $scope.currentPage = 1;
        };

        $scope.loadMoreRecordings = function() {
            $scope.loadRecordings(false, ($scope.currentPage - 1) * $scope.itemsPerPage, 'recordings');
        };
        
        $scope.loadRecordings = function(isAddedNew, skip, loader) {
            $scope.loading[loader] = true;
            $window.scrollTo(0, 0);

            const request = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                sort: 'desc',
                include_urls: true,
                media_exists: true,
                skip: skip,
                limit: $scope.itemsPerPage
            };
            
            mediaService.listMediaUrls(request)
                .then(results => {
                    $scope.recordings = results.medias;
                    $scope.totalItems = results.total;
                    $scope.loading[loader] = false;
                    
                    if($scope.isGetStarted && $scope.recordings.length === 1 && isAddedNew) {
                        $location.search({});
                        $location.path('dash');
                    }
                    $scope.$apply();
                })
                .catch(err => {
                    console.error(err);
                    $scope.loading[loader] = false;
                    $scope.$apply();
                });
        };
        
        $scope.onInit = function() {
            $scope.isGetStarted = ($location.search().type === 'get_started') ? true : false;
            $scope.loadRecordings(false, 0, 'recordingsFirstLoad');
            
            $scope.$watch('newRecording.option', function(newValue, oldValue) {
                switch(newValue) {
                    case 'upload_a_file':
                        $timeout(function(){
                            $scope.initDropify();
                        });
                        break;
                }
            });
        };
        
        $scope.$on('$destroy', function() {
            if($scope.stopTimeDialIn)
                $interval.cancel($scope.stopTimeDialIn);
        });

        if (authService.hasAccount()) {
            $scope.onInit();
        }
        else {
            $scope.$on('auth_complete', function(event, args) {
                $scope.onInit();
            });
        }
    }
    module.exports = RecordingsController;
})();
