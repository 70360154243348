(function () {
    "use strict";

    function PasswordResetController($rootScope, $state, $scope, $location, authService, accountService) {
        var vm = this;
        vm.authService = authService;

        $rootScope.viewData = $rootScope.viewData || {};
        $rootScope.viewData.breadcrumbs = $rootScope.viewData.breadcrumbs || [];
        $rootScope.viewData.breadcrumbs = [{
            name: "Password Reset",
            href: "#!passwordreset"
        }];

        $scope.appLogo = $rootScope.settings.APP_LOGO;

        $scope.data = {
            form : {
                password: '',
                password_again: ''
            },
        };

        $scope.loading = {
            submit: false
        };

        $scope.passwordPattern = /(?=.*[A-Z])(?=.*[^.a-zA-Z\d])/;

        $scope.staticContent = {
            labels: [
                "New password",
                "Please repeat your password",
            ],
            actionSubmit: "Submit",
            errorMessages: {
                password: {
                    errorMessageRequired: 'Password is required',
                    errorMessageMinLength: 'Password must be at least 8 characters long',
                    errorMessagePattern: 'Password must contain at least one capital letter and one character',
                },
                password_again: {
                    errorMessage: 'Passwords do not match',
                },
            },
            loading: 'Loading'
        };

        $scope.setPassword = function(e) {
            e.preventDefault();

            if (!$scope.resetPasswordForm.$valid){
                return;
            }

            $scope.loading.submit = true;

            const setPasswordReq = {
                reset_id: $scope.data.reset_id,
                password: $scope.data.form.password,
            };

            accountService.setPassword(setPasswordReq)
                .then(results => {

                    swal("New password set", "You can now login with your new password.", "success")
                        .then(() => {
                            $scope.loading.submit = false;
                            $location.path('/signin').search('');
                            $scope.$apply();
                        });
                })
                .catch(err => {
                    console.log(err);
                    $scope.loading.submit = false;
                    swal(
                        'Unable to set password',
                        'Internal error. Please contact administrator.',
                        'error'
                    );
                    $scope.$apply();
                });
        };

        $scope.onInit = function() {
            if (authService.isAuthenticated()) {
                $state.go('main-wrapper.dash');
            }
            if (!authService.isAuthenticated()) {
                authService.logoutOrlocalLogout(false);
            }

            var params = $location.search();
            $scope.data.reset_id = params.id;

            const checkPasswordReq = {
                reset_id: $scope.data.reset_id
            };

            accountService.checkPasswordReset(checkPasswordReq)
                .then(results => {
                    if (!results.success) {
                        $location.path('/signin').search('');
                        $scope.$apply();
                    }
                })
                .catch(err => {
                    console.log(err);
                    swal(
                        'Error',
                        'Internal error. Please contact administrator.',
                        'error'
                    );
                });
        };

        $scope.onInit();
    }

    module.exports = PasswordResetController;
})();
