(function() {
    'use strict';

    function TeamsController($rootScope, $scope, authService, userService, accountService, $window) {
        var vm = this;
        vm.authService = authService;

        $rootScope.viewData = $rootScope.viewData || {};
        $rootScope.viewData.breadcrumbs = $rootScope.viewData.breadcrumbs || [];
        $rootScope.viewData.breadcrumbs = [{
            title: 'Teams',
            link_name: 'Teams',
            link_url: '#/teams',
            help_url: $rootScope.help_url
        }];

        $scope.staticContent = {
            title: 'Teams',
            subtitle: 'Manage team membership for your users...',
            addBtn: 'Add Team',
            group: {
                actions: [ 'View Users', 'Hide Users', 'Delete Team' ]
            },
            users: {
                addBtn: 'Add Users',
                table: {
                    header: [ 'First Name', 'Last Name', 'Username', 'Email' ],
                    actions: [ 'Remove User' ]
                }
            },
            noRes: 'No Teams',
            noUsers: 'No Users',
            loading: 'Loading'
        };
        
        $scope.loading = {
            teamsFirstLoad: true,
            teams: false
        };

        $scope.data = {
            teams: [],
            totalTeams: 0,
            allUsers: [],
            selectedUser: null
        };

        $scope.currentPage = 1;
        $scope.itemsPerPage = 10;
        $scope.maxSize = 3;

        $scope.setPage = function (pageNo) {
            $scope.currentPage = pageNo;
        };

        $scope.loadPage = function() {
            $window.scrollTo(0, 0);
            $scope.loadTeams((($scope.currentPage - 1) * $scope.itemsPerPage), $scope.itemsPerPage, 'teams');
        };

        $scope.setItemsPerPage = function(num) {
            $scope.itemsPerPage = num;
            $scope.currentPage = 1; //reset to first page
        };

        $scope.showToast = function(type, icon, title, text, position, hideAfter) { //type: 'success', 'error', 'warning'
            var content = '<div class="row ml-0 mr-0 toast-notification">' +
                '<div class="col-3 toast-icon-col ' + type + '">' +
                    '<i class="' + icon + '"></i>' +
                '</div>' +
                '<div class="col-9 toast-content-col">' +
                    '<h5 class="card-title mb-2">' + title + '</h5>' +
                    '<h6 class="card-subtitle mt-0">' + text + '</h6>' +
                '</div>';

            $.toast({
                text: content,
                position: position,
                hideAfter : hideAfter,
                loaderBg: '#54667a',
                stack: false,
                showHideTransition: 'slide',
                loader: true,
                allowToastClose : false
            });
        };

        $scope.successToast = function(icon, message) {
            $scope.showToast('success', icon, 'Success!', message, 'top-center', 5000);
        };

        $scope.errorToast = function(icon, message) {
            $scope.showToast('error', icon, 'Error!', message, 'top-center', 5000);
        };

        $scope.hideDetailsArea = function(detailsTd, team) {
            detailsTd.classList.remove('show');
            team.usersVisible = !team.usersVisible;
            detailsTd.querySelector('.dataTables_wrapper').style.display = 'none';
        };

        $scope.showDetailsArea = function(detailsTd, team) {
            detailsTd.querySelector('.dataTables_wrapper').style.display = 'block';
            detailsTd.classList.add('show');
            team.usersVisible = true;
        };

        $scope.dropdownMenuToggle = function(event, mainParentSelector, dropdownTopVariable, offset) {
            var dropdownContainer = event.currentTarget;
            var dropdownOffset = $(dropdownContainer).offset().top;
            var mainParentOffset = $(dropdownContainer.closest(mainParentSelector)).offset().top;

            var positionTop = (dropdownOffset - mainParentOffset) + offset;

            const root = document.documentElement;
            root.style.setProperty(dropdownTopVariable, positionTop + 'px');
        };

        $scope.isUserInTeam = function(team, user) {
            return _.indexOf(team.user_ids, user.user_id);
        };

        $scope.getUsersByTeam = function(team) {
            var users = _.intersectionWith($scope.data.allUsers, team.user_ids, (o, id) => o.user_id === id);
            return users;
        };

        $scope.isAddUsersArrayEmpty = function(team) {
            var freeUsers = _.differenceWith($scope.data.allUsers, team.user_ids, (o, id) => o.user_id === id);
            return (freeUsers.length === 0);
        };

        $scope.noUsers = function(team) {
            var users = $scope.getUsersByTeam(team);
            return (users.length === 0);
        };

        $scope.updateTeam = function(team) {
            var teamId = authService.getTeamId();
            var userId = authService.getUserId();

            const request = {
                team_id: teamId,
                user_id: userId,
                name: team.name,
                user_ids: team.user_ids,
                user_group_id: team.user_group_id
            };

            return userService.updateUserGroup(request);
        };

        $scope.removeUserFromTeam = function(team, user, row) {
            if($scope.isUserInTeam(team, user) !== -1) {
                team.usersLoading = true;
                team.user_ids = _.remove(team.user_ids, function(id) {
                    return id !== user.user_id;
                });

                $scope.updateTeam(team)
                    .then(result => {
                        team.usersTable.row($(row)).remove().draw();
                        team.usersLoading = false;
                        $scope.$apply();
                        $scope.successToast('fa-light fa-trash-check', 'User has been removed from the team!');
                    })
                    .catch(err => {
                        console.error(err);
                        team.usersLoading = false;
                        $scope.$apply();
                        $scope.errorToast('fa-light fa-triangle-exclamation', 'We were unable to update team. Try again later.');
                    });
            }
        };

        $scope.addUserToTeam = function(team) {
            if($scope.isUserInTeam(team, $scope.data.selectedUser) === -1) {
                team.usersLoading = true;
                team.user_ids.unshift($scope.data.selectedUser.user_id);

                $scope.updateTeam(team)
                    .then(result => {
                        team.usersTable.row.add($scope.data.selectedUser).draw(false);
                        $scope.data.selectedUser = null;
                        team.usersLoading = false;
                        $scope.$apply();
                        $scope.successToast('fa-light fa-user-check', 'User has been added to the team!');
                    })
                    .catch(err => {
                        console.error(err);
                        $scope.data.selectedUser = null;
                        team.usersLoading = false;
                        $scope.$apply();
                        $scope.errorToast('fa-light fa-triangle-exclamation', 'We were unable to update team. Try again later.');
                    });
            }
        };

        $scope.getUsersTableColumns = function() {
            return [
                { data: 'identity.first_name', className: 'w-17 user-td' },
                { data: 'identity.last_name', className: 'w-17 user-td' },
                { data: 'username', className: 'w-25 user-td' },
                { data: 'identity.email', className: 'w-25 user-td' },
                {
                    data: 'user_id',
                    className: 'w-16 actions-td',
                    render: function (data) {
                        return '<div class="dropdown user-actions">' +
                            '<button type="button" class="btn btn-info dropdown-toggle users-activity-item db mr-2" ' +
                                'data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" id="user-actions-' + data + '">' +
                                    '<i class="icon-options"></i>' +
                            '</button>' +
                            '<div class="dropdown-menu dropdown-menu-users animated fadeIn animate-faster">' +
                                '<button class="dropdown-item remove-user-from-team-btn">' +
                                    '<span>' + $scope.staticContent.users.table.actions[0] + '</span>' +
                                '</button>' +
                            '</div>' +
                        '</div>';
                    }
                }
            ];
        };

        $scope.initTable = function(team) {
            team.usersTable = $('#teamDetailsTable' + team.user_group_id).DataTable({
                ajax: function (dataSent, callback) {
                    var users = $scope.getUsersByTeam(team);
                    callback({ data: users });
                },
                initComplete: function(settings) {
                    var details = $('#' + settings.nTable.id + 'Div');
                    $(details).find('.dataTables_scrollBody').scroll(function() {
                        $('.user-actions.show .dropdown-toggle').dropdown('toggle');
                    });
                },
                createdRow: function (row, data) {
                    var currentRow = $(row);
                    currentRow.addClass('content-row');
                    currentRow.attr('id', 'row-' + data.user_id);

                    var dropdownToggle = currentRow.find('.users-activity-item');
                    var removeUserBtn = currentRow.find('.remove-user-from-team-btn');

                    dropdownToggle.click(function(event) {
                        event.preventDefault();
                        $scope.dropdownMenuToggle(event, '.dataTables_scrollBody', '--dropdown-menu-users-top', 104);
                    });
                    removeUserBtn.click(function(event) {
                        event.preventDefault();
                        $scope.removeUserFromTeam(team, data, row);
                    });
                },
                searching: false,
                info: false,
                paging: false,
                processing: false,
                autoWidth: false,
                scrollY: '153px',
                scrollX: '0px',
                scrollCollapse: true,
                order: [[0, 'asc']],
                columnDefs: [ { 'targets': [4], sortable: false } ],
                columns: $scope.getUsersTableColumns()
            });
        };

        $scope.toogleDetailsArea = function(team) {
            var detailsTd = document.getElementById('teamDetailsTable' + team.user_group_id + 'Div');
            if(team.usersVisible && detailsTd.classList.contains('show')) {
                $scope.hideDetailsArea(detailsTd, team);
            } else {
                if(!team.usersTable) {
                    $scope.initTable(team);
                }
                $scope.showDetailsArea(detailsTd, team);
            }
        };

        $scope.loadTeams = function(skip, limit, loadingVariable) {
            $scope.loading[loadingVariable || 'teams'] = true;

            var teamId = authService.getTeamId();
            var userId = authService.getUserId();

            const request = {
                team_id: teamId,
                user_id: userId,
                skip: skip || 0,
                limit: limit || $scope.itemsPerPage,
                sort_by: 'created_at',
                sort: 'desc'
            };

            userService.listUserGroups(request)
                .then(results => {
                    $scope.data.teams = ((results || {}).teams || []);
                    $scope.data.totalTeams = (results || {}).total_teams || 0;

                    $scope.loading[loadingVariable || 'teams'] = false;
                    $scope.$apply();
                })
                .catch(err => {
                    swal('Server Error', 'We were unable to retrieve teams for your account. \nTry again later.', 'error');
                    console.error(err);
                    $scope.loading[loadingVariable || 'teams'] = false;
                    $scope.$apply();
                });
        };

        $scope.loadAllUsers = function() {
            var teamId = authService.getTeamId();
            var userId = authService.getUserId();

            const request = {
                team_id: teamId,
                user_id: userId
            };

            userService.listUsers(request)
                .then(results => {
                    $scope.data.allUsers = results.users;
                    _.forEach($scope.data.allUsers, function(user) {
                        user.identity.name = user.identity.first_name + ' ' + user.identity.last_name;
                    });
                    $scope.$apply();
                })
                .catch(err => {
                    console.error(err);
                    swal("Server Error", "Our server failed to respond. You may have too many columns in your spreadsheet.", "error");
                });
        };

        $scope.newNameValue = '';
        $scope.editTeamName = function(team) {
            if($scope.newNameValue !== team.name) {
                $scope.newNameValue = team.name;

                $scope.updateTeam(team)
                    .then(result => {
                        $scope.successToast('fa-light fa-circle-check', 'Team name has been updated!');
                    })
                    .catch(err => {
                        console.error(err);
                        $scope.errorToast('fa-light fa-triangle-exclamation', 'We were unable to update team. Try again later.');
                    });
            }
        };

        $scope._createTeam = function() {
            var teamId = authService.getTeamId();
            var userId = authService.getUserId();

            const request = {
                team_id: teamId,
                user_id: userId,
                name: '-User Group-'
            };

            return userService.createUserGroup(request);
        };

        $scope.createTeam = function() {
            $rootScope.$broadcast('addTeamEvent');
        };

        $scope._deleteTeam = function(team) {
            var teamId = authService.getTeamId();
            var userId = authService.getUserId();

            const request = {
                team_id: teamId,
                user_id: userId,
                name: team.name,
                user_ids: team.user_ids,
                user_group_id: team.user_group_id
            };

            return userService.deleteUserGroup(request);
        };

        $scope.checkTeamName = function(tag) {
            if (tag.length === 0) {
                return "Team Name must have at least 1 character.";
            }
        };

        $scope.deleteTeam = function(team) {
            swal({
                title: 'Delete Team',
                text: 'Do you want to delete ' + team.name + ' ' + '?',
                buttons: ['No', 'Yes'],
                dangerMode: true,
                closeOnClickOutside: false
            }).then((confirm) => {
                    if (!confirm) { return; }

                    $scope.loading.teams = true;

                    $scope._deleteTeam(team)
                        .then(results => {
                            $scope.loadPage();
                            $scope.loading.teams = false;
                            $scope.$apply();

                            $scope.successToast('fa-light fa-trash-check', 'Team has been deleted!');
                        })
                        .catch(err => {
                            console.error(err);
                            $scope.loading.teams = false;
                            $scope.$apply();

                            $scope.errorToast('fa-light fa-triangle-exclamation', 'We were unable to delete team. Try again later.');
                        });
                });
        };

        $scope.$on('teamCreated', function(event, args) {
            $scope.loadTeams(0, $scope.itemsPerPage, 'teamsFirstLoad');
        });

        $scope.onInit = function() {
            $scope.loadTeams(0, $scope.itemsPerPage, 'teamsFirstLoad');
            $scope.loadAllUsers();
        };

        if (authService.hasAccount()) {
            $scope.onInit();
        }
        else {
            $scope.$on('auth_complete', function(event, args) {
                $scope.onInit();
            });
        }
    }
    module.exports = TeamsController;
})();
