(function () {
    'use strict';

    function EmailController(authService, domainService, templateService, $rootScope, $scope, $http, notificationToastService) {

        var vm = this;
        vm.authService = authService;

        $rootScope.viewData = $rootScope.viewData || {};
        $rootScope.viewData.breadcrumbs = $rootScope.viewData.breadcrumbs || [];
        $rootScope.viewData.breadcrumbs = [{
            title: 'Email',
            link_name: 'Email',
            link_url: '#/email',
            help_url: $rootScope.help_url
        }];
        $scope.appName = $rootScope.settings.APP_NAME;

        $scope.staticContent = {
            title: 'Email Domains',
            subtitle: 'Manage your email subdomain here.',
            addDomainButton: 'Add Domain',
            loading: 'Loading',
            saving: 'Saving',
            noRes: 'No Results',
            actions: ['Delete Domain'],
            templates: {
                title: 'Email Templates',
                subtitle: 'Manage your email templates here.',
                addButton: 'Add Template',
                actions: ['Delete Template', 'Edit', 'Save'],
                noRes: 'Click the \'Add Template\' to get started.'
            }
        };

        $scope.loading = {
            domains: true,
            templatesFirstLoad: true,
            templates: false
        };
        $scope.data = {
            domains: [],
            templates: []
        };

        $scope.paginationTemplates = {
            itemsPerPage: 15,
            currentPage: 1,
            maxSize: 3,
            totalItems: 0
        };

        $scope.contactAttributes = [
            '{{ contact.main_phone }}',
            '{{ contact.secondary_phone }}',
            '{{ contact.first_name }}',
            '{{ contact.last_name }}',
            '{{ contact.company }}',
            '{{ contact.email }}',
            '{{ contact.fax_number }}',
            '{{ contact.address }}',
            '{{ contact.suite/apt }}',
            '{{ contact.city }}',
            '{{ contact.state }}',
            '{{ contact.country }}',
            '{{ contact.zip_code }}',
            '{{ contact.reason }}'
        ];

        $scope.userAttributes = [
            '{{ user.username }}',
            '{{ user.first_name }}',
            '{{ user.last_name }}',
            '{{ user.email }}',
            '{{ user.home_phone }}',
            '{{ user.office_phone }}',
            '{{ user.mobile_phone }}'
        ];

        $scope.loadDomains = function() {
            $scope.loading.domains = true;
            const teamId = authService.getTeamId();
            const userId = authService.getUserId();

            const request = {
                team_id: teamId,
                user_id: userId,
            };

            domainService.getLists(request)
                .then(results => {
                    $scope.data.domains = results || [];
                    $scope.loading.domains = false;
                    $scope.$apply();
                })
                .catch(err => {
                    console.error(err);
                    $scope.loading.domains = false;
                    $scope.$apply();
                });
        };

        $scope.createDomain = function() {
            $rootScope.showDomainModal();
        };

        $scope.$on('addDomainEventDone', function(event, isSuccess) {
            if (isSuccess) {
                return $scope.loadDomains();
            }
        });

        $scope.deleteDomain = function(item) {
            swal("Delete Domain", "Do you want to delete the domain "+ item.full_domain +"?", { buttons: true, dangerMode: true })
                .then(confirm => {
                    if (!confirm) { return; }

                    $scope.loading.domains = true;
                    const request = {
                        team_id: authService.getTeamId(),
                        user_id: authService.getUserId(),
                        domain_id: item.domain_id,
                    };

                    domainService.deleteOne(request)
                        .then(results => {
                            var items = $scope.data.domains || [];
                            for (var i = 0; i < items.length; i++) {
                                if (items[i].domain_id === item.domain_id) {

                                    items.splice(i, 1);
                                    break;
                                }
                            }
                        })
                        .catch(err => {
                            swal("Server Error", "Our server failed to respond.  Please try again.", "error");
                        })
                        .then(() => {
                            $scope.loading.domains = false;
                            $scope.$apply();
                        });
                });
        };

        $scope.addNewTemplateAnimation = function(template) {
            var emailTemplateItem = document.querySelector('#email-list-item-' + template.template_id);
            var emailTemplateItemParent = document.querySelector('#email-list-item-' + template.template_id).parentNode;

            emailTemplateItem.classList.add('show-open-edit');
            emailTemplateItemParent.classList.add('show-open-edit');

            var emailTemplateItemTransitionEnd = function(e) {
                if(e.propertyName === 'transform') {
                    emailTemplateItemParent.style.overflow = 'inherit';
                    emailTemplateItem.removeEventListener('transitionend', emailTemplateItemTransitionEnd);
                    $scope.$apply();
                }
            };

            emailTemplateItem.addEventListener('transitionend', emailTemplateItemTransitionEnd);
        };

        $scope.createTemplate = function() {

            const teamId = authService.getTeamId();
            const userId = authService.getUserId();

            const newTemplate = {
                template_id: $scope.uuidv4(),
                team_id: teamId,
                user_id: userId,
                name: 'My Email Template',
                description: '',
                html_text: '',
                is_new: true,
                editing: true
            };
            $scope.data.templates.unshift(newTemplate);

            setTimeout(function() {
                $scope.toggleFieldOptions(newTemplate);
                $scope.addNewTemplateAnimation(newTemplate);
            });
        };

        $scope.uuidv4 = function() {
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
                let r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
                return v.toString(16);
            });
        };

        $scope.saveTemplate = function(item) {
            item.savingTemplate = true;

            const teamId = authService.getTeamId();
            const userId = authService.getUserId();
            const html_text = item.quill.root.innerHTML;

            const request = {
                team_id: teamId,
                user_id: userId,
                template_id: item.template_id,
                html_text: html_text,
                name: item.name,
                type: 'email'
            };

            templateService[(item.is_new) ? 'createTemplate' : 'updateTemplate'](request)
                .then(function(result) {
                    $scope.data.templates = $scope.data.templates.map(template => {
                        if (template.template_id === item.template_id) {
                            template.html_text = html_text;
                            template.template_id = result.template_id;
                        }
                        return template;
                    });
                    item.template_id = result.template_id;
                    item.savingTemplate = false;
                    // we need a short delay before hide edit mode div
                    setTimeout(function(){
                        $scope.disableEditMode(item);
                    }, 100);
                    $scope.$apply();
                    // not really needed
                    // notificationToastService.showSuccessToast('fa-light fa-clipboard-check', (item.is_new) ? 'Template has been created!' : 'Template has been updated!');
                }, function(err) {
                    console.error(err);
                    item.savingTemplate = false;
                    var message = (item.is_new) ? 'We were unable to create template. Try again later.' : 'We were unable to update template. Try again later.';
                    $scope.$apply();
                    notificationToastService.showErrorToast('fa-light fa-triangle-exclamation', message);
                });
        };

        $scope._deleteTemplate = function(item) {
            const teamId = authService.getTeamId();
            const userId = authService.getUserId();
            const request = {
                team_id: teamId,
                user_id: userId,
                template_id: item.template_id
            };

            return templateService.deleteTemplate(request);
        };

        $scope.deleteTemplate = function(template) {
            swal({
                title: 'Delete Template',
                text: 'Do you want to delete ' + template.name + ' ' + '?',
                buttons: ['No', 'Yes'],
                dangerMode: true,
                closeOnClickOutside: false
            }).then((confirm) => {
                if (!confirm) { return; }

                $scope.loading.templates = true;

                $scope._deleteTemplate(template)
                    .then(results => {
                        $scope.loadTemplatePage(false);
                        $scope.$apply();
                        // not really needed
                        // notificationToastService.showSuccessToast('fa-light fa-trash-check', 'Template has been deleted!');
                    })
                    .catch(err => {
                        console.error(err);
                        $scope.loading.templates = false;
                        $scope.$apply();

                        notificationToastService.showErrorToast('fa-light fa-triangle-exclamation', 'We were unable to delete template. Try again later.');
                    });
            });
        };

        $scope.editNameField = function(template) {

            const teamId = authService.getTeamId();
            const userId = authService.getUserId();

            const request = {
                team_id: teamId,
                user_id: userId,
                template_id: template.template_id,
                name: template.name
            };

            templateService.updateTemplate(request)
                .then(function() {
                    $scope.$apply();
                    // not really needed
                    // notificationToastService.showSuccessToast('fa-light fa-clipboard-check', 'Template name has been updated!');
                }, function(err) {
                    console.error(err);
                    $scope.$apply();
                    notificationToastService.showErrorToast('fa-light fa-triangle-exclamation', 'We were unable to update template. Try again later.');
                });
        };

        $scope.disableEditMode = function(template) {
            var detailsDiv = document.getElementById('templateDetails' + template.template_id + 'Div');
            var toolbar = detailsDiv.querySelector('.details-body .ql-toolbar.ql-snow');

            template.editing = false;
            $(toolbar).removeClass('show');
            template.quill.enable(false);
            template.quill.root.dataset.placeholder = 'Empty Template';
        };

        $scope.enableEditMode = function(template) {
            var detailsDiv = document.getElementById('templateDetails' + template.template_id + 'Div');
            var toolbar = detailsDiv.querySelector('.details-body .ql-toolbar.ql-snow');

            template.editing = true;
            template.quill.root.dataset.placeholder = 'Compose your html text...';
            template.quill.enable(true);
            $(toolbar).addClass('show');
        };

        $scope.editOrSave = function(template) {
            var detailsDiv = document.getElementById('templateDetails' + template.template_id + 'Div');
            detailsDiv.querySelector('.action-btn').blur();

            $scope[(template.editing) ? 'saveTemplate' : 'enableEditMode'](template);
        };

        $scope.quillAttributesHandler = function(value) {
            if (value) {
                const cursorPosition = this.quill.getSelection().index;
                this.quill.insertText(cursorPosition, value);
                this.quill.setSelection(cursorPosition + value.length);
            }
        };

        $scope.quillUpdateContent = function(template) {
            const delta = template.quill.clipboard.convert(template.html_text);
            template.quill.setContents(delta, 'silent');
        };

        $scope.initQuill = function(template) {
            if (!template.quill) {
                template.quill = new Quill('#template_' + template.template_id, {
                    placeholder: 'Empty Template',
                    theme: 'snow',
                    modules: {
                        toolbar: {
                            container: [
                                [
                                    { 'header': [1, 2, 3, 4, 5, 6, false] },
                                    { 'size': ['small', false, 'large', 'huge'] },
                                    'bold', 'italic', 'underline', 'link',
                                    { 'list': 'ordered' }, { 'list': 'bullet' }, { 'align': [] }
                                ],
                                [
                                    { 'contactAttributes': $scope.contactAttributes },
                                    { 'userAttributes': $scope.userAttributes }
                                ]
                            ],
                            handlers: {
                                "contactAttributes": $scope.quillAttributesHandler,
                                "userAttributes": $scope.quillAttributesHandler
                            }
                        }
                    }
                });
                $scope.quillUpdateContent(template);

                if(template.editing) {
                    $scope.enableEditMode(template);
                } else {
                    template.quill.enable(false);
                }
            }
        };

        $scope.hideDetailsArea = function(detailsDiv, template) {
            detailsDiv.classList.remove('show');
            template.detailsVisible = !template.detailsVisible;
            detailsDiv.querySelector('.details-body #template_' + template.template_id).style.display = 'none';
            detailsDiv.querySelector('.details-body').style.display = 'none';
        };

        $scope.showDetailsArea = function(detailsDiv, template) {
            detailsDiv.querySelector('.details-body').style.display = 'block';
            detailsDiv.querySelector('.details-body #template_' + template.template_id).style.display = 'block';
            detailsDiv.classList.add('show');
            template.detailsVisible = true;
        };

        $scope.toggleFieldOptions = function (template) {
            var detailsDiv = document.getElementById('templateDetails' + template.template_id + 'Div');
            if(template.detailsVisible && detailsDiv.classList.contains('show')) {
                $scope.hideDetailsArea(detailsDiv, template);
            } else {
                if(!template.detailsVisible) {
                    $scope.initQuill(template);
                }
                $scope.showDetailsArea(detailsDiv, template);
            }
        };

        $scope.loadTemplates = function(skip, limit, loadingVariable, callback) {
            $scope.loading[loadingVariable || 'templates'] = true;

            const teamId = authService.getTeamId();
            const userId = authService.getUserId();

            const request = {
                team_id: teamId,
                user_id: userId,
                type: 'email',
                skip: skip || 0,
                limit: limit || $scope.paginationTemplates.itemsPerPage
            };

            templateService.getTemplates(request)
                .then(results => {
                    $scope.data.templates = results.templates || [];
                    $scope.paginationTemplates.totalItems = results.totalItems || 0;

                    $scope.loading[loadingVariable || 'templates'] = false;
                    $scope.$apply();
                    callback();
                })
                .catch(err => {
                    console.error(err);
                    $scope.loading[loadingVariable || 'templates'] = false;
                    $scope.$apply();
                    callback();
                    notificationToastService.showErrorToast('fa-light fa-triangle-exclamation', 'We were unable to load templates. Try again later.');
                });
        };

        $scope.setPageTemplates = function (pageNo) {
            $scope.paginationTemplates.currentPage = pageNo;
        };

        $scope.setItemsPerPageTemplates = function(num) {
            $scope.paginationTemplates.itemsPerPage = num;
            $scope.paginationTemplates.currentPage = 1; //reset to first page
        };

        $scope.loadTemplatePage = function(isAnimated) {
            var skip = (($scope.paginationTemplates.currentPage - 1) * $scope.paginationTemplates.itemsPerPage);
            $scope.loadTemplates(skip, $scope.paginationTemplates.itemsPerPage, 'templates', function() {
                if(isAnimated) {
                    $([document.documentElement, document.body]).animate({
                        scrollTop: $("#emailTemplatesSection").offset().top - 80
                    }, 350);
                }
            });
        };

        $scope.checkTemplateName = function(template) {
            if (template.length === 0) {
                return "Name must have at least 1 character.";
            }
        };

        $scope.onInit = function() {
            $scope.loadDomains();
            $scope.loadTemplates(0, $scope.paginationTemplates.itemsPerPage, 'templatesFirstLoad', function() {});
        };

        if (authService.hasAccount()) {
            $scope.onInit();
        }
        else {
            $scope.$on('auth_complete', function(event, args) {
                $scope.onInit();
            });
        }
    }
    module.exports = EmailController;
})();
