(function () {
    'use strict';
    function VoiceService(apiService, $rootScope) { 
        return {
            listVoiceUrls: function (request) {
                return apiService.get($rootScope.settings.API_VOICE_URL + '/voice', request);
            },

            createVoice: function (request) {
                return apiService.post($rootScope.settings.API_VOICE_URL + '/voice', request);
            },

            getMedia: function (request) {
                return apiService.get($rootScope.settings.API_VOICE_URL + '/voice/' + request.voice_id, request);
            },

            cloneVoice: function (request) {
                return apiService.post($rootScope.settings.API_VOICE_URL + '/voice/' + request.voice_id + '/clone', request);
            },

            updateVoice: function (request) {
                return apiService.post($rootScope.settings.API_VOICE_URL + '/voice/' + request.voice_id, request);
            },
                
            textToSpeech: function (request) {
                return apiService.post($rootScope.settings.API_VOICE_URL + '/voice/' + request.voice_id + '/tts', request);
            },

            deleteVoice: function (request) {
                return apiService.del($rootScope.settings.API_VOICE_URL + '/voice/'+ request.voice_id, request);
            },

            getPolicy: function (request) {
                return apiService.get($rootScope.settings.API_VOICE_URL + '/voice/'+ request.voice_id + '/policy', request);
            },

            getUrl: function (request) {
                return apiService.get($rootScope.settings.API_VOICE_URL + '/voice/' + request.voice_id + '/url/' + request.ext, request);
            },

            pullUrl: function (request) {
                return apiService.post($rootScope.settings.API_VOICE_URL + '/voice/' + request.voice_id + '/url', request);
            }
        };
    }
    module.exports = VoiceService;
})();