(function() {
    'use strict';

    function WorkflowController($rootScope, $scope, $location, $window, authService, automationService, $sce) {
        var vm = this;
        vm.authService = authService;

        $rootScope.viewData = $rootScope.viewData || {};
        $rootScope.viewData.breadcrumbs = $rootScope.viewData.breadcrumbs || [];
        $rootScope.viewData.breadcrumbs = [{
            title: 'Automation',
            link_name: 'Automation',
            link_url: '#/automation',
            help_url: $rootScope.help_url
        }, {
            title: 'Workflow',
            link_name: 'Workflow',
            link_url: '#/workflow',
        }];

        $scope.data = {
            workflowId: null,
            publicWorkflowHref: null,
            sessionToken: $rootScope.cobalt.token || null,
        };
        $scope.loading = false;

        $scope.staticText = {};

        $scope.getWorkflowFromParams = () => {
            let path = $location.path();
            let parts = path.split('/');
            const id = parts[parts.length - 1];

            $scope.data.workflowId = id;
            return id;
        };

        $scope.initializeSession = () => {
            return automationService.getIpassSessionToken()
                .then(data => {

                    $rootScope.cobalt.token = data.token;
                    $scope.data.sessionToken = data.token;

                    $scope.loading = false;

                    $scope.$apply();

                    return data;
                })
                .catch(err => {
                    console.log(err);

                    $scope.loading = false;
                    $scope.$apply();
                });
        };

        $scope.onInit = () => {
            const workflowId = $scope.getWorkflowFromParams();
            let url;

            if (workflowId) {

                if ($rootScope.cobalt.token) {

                    url = 'https://app.gocobalt.io/workflows/' + workflowId + '?token=' + $rootScope.cobalt.token;
                    $scope.data.publicWorkflowHref = $sce.trustAsResourceUrl(url);
                } else {

                    $scope.initializeSession()
                        .then(result => {
                            url = 'https://app.gocobalt.io/workflows/' + workflowId + '?token=' + result.token;
                            $scope.data.publicWorkflowHref = $sce.trustAsResourceUrl(url);

                            $scope.$apply();
                        })
                }
            }
        }

        if (authService.hasAccount()) {
            $scope.onInit();
        }
        else {
            $scope.$on('auth_complete', function(event, args) {
                $scope.onInit();
            });
        }
    }
    module.exports = WorkflowController;
})();
