(function() {
    'use strict';

    function DialingListModalController(authService, phoneService, $rootScope, $scope, $window) {
        var vm = this;
        vm.authService = authService;

        $scope.updateCall = function() {

            let req = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                call_id: $scope.dialingListModal.callId,
                dialer_type: PRODUCT_PRICING[$scope.dialingListModal.selectedPlan] ? PRODUCT_PRICING[$scope.dialingListModal.selectedPlan].isDialer : $scope.dialingListModal.selectedPlan,
                call_contact_from: $scope.dialingListModal.phoneSelected
            };

            phoneService.updateCall(req)
                .then(results => {
                    $rootScope.hideDialingListModal();
                })
                .catch(err => {
                    $rootScope.hideDialingListModal();
                });
        };

        $scope.setSelectedPhone = function(number) {

            $scope.dialingListModal.phoneSelected = number;
            $scope.$apply();
        };

        $scope.onInit = function() {
            $scope.dialingListModal = $rootScope.dialingListModal || {};
        };

        if (authService.hasAccount()) {
            $scope.onInit();
        }
        else {
            $scope.$on('auth_complete', function(event, args) {
                $scope.onInit();
            });
        }
    }
    module.exports = DialingListModalController;
})();
