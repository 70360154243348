(function() {
    'use strict';

    function VoicemailController(authService, phoneService, $rootScope, $scope, ngAudio, $window, $location) {
        var vm = this;
        vm.authService = authService;

        $rootScope.viewData = $rootScope.viewData || {};
        $rootScope.viewData.breadcrumbs = $rootScope.viewData.breadcrumbs || [];
        $rootScope.viewData.breadcrumbs = [{
            title: 'Voicemail',
            link_name: 'Voicemail',
            link_url: '#/voicemails',
            help_url: $rootScope.help_url
        }];

        $scope.staticContent = {
            title: 'Voicemail',
            subtitle: 'Check out your voicemail',
            transcription_header: 'From: :from To: :to Start Time: :start_time Duration: :duration \n\n',
            searchInputPlaceholder: 'Search...',
            addRecording: {
                title: 'Add Recording',
                href: '#/recordings'
            },
            phoneNumberDropdown: {
                title: 'PHONE NUMBERS',
                all: 'All'
            },
            loading: 'Loading',
            noRes: 'No Results',
            vmMenu: ['Hide', 'Unhide', 'Call', 'Text', 'View Contact', 'Show Full Transcript'],
            views: ['Default', 'New', 'Hidden', 'All']

        };

        $scope.vms = [];
        $scope.numbers = [];
        $scope.view = 'Default';

        $scope.search = {
            number: '',
            term: '',
            submit: false
        };

        $scope.loading = {
            vms: true,
            numbers: true,
            loadRecord: false
        };

        $scope.currentPage = 1;
        $scope.itemsPerPage = 8;
        $scope.maxSize = 3;
        $scope.totalItems = 0;

        $('#voicemailDateRange').on('apply.daterangepicker', function(ev, picker) {
            $scope.minTs = picker.startDate._d.getTime();
            $scope.maxTs = picker.endDate._d.getTime();
            $('#voicemailDateRange span').html(picker.startDate.format('MMM D, YYYY') + ' - ' + picker.endDate.format('MMM D, YYYY'));
            $scope.loadVms();
            // console.log(picker.startDate.format("MMMM DD, YYYY"));
            // console.log(picker.endDate.format("MMMM DD, YYYY"));
        });

        // player start
        $scope.currentOpenedPlayerId = -1;
        $scope.$on('currentOpenedRecordChanged', function(event, newCurrentOpenedPlayerId, recordType) {
            $scope.currentOpenedPlayerId = newCurrentOpenedPlayerId;
        });
        $scope.$on('audioObjectChanged', function(event, audioObject, index, recordType) {
            $scope.vms[index].audio = audioObject;
        });
        // player end

        $scope.__getViewParams = function () {

            switch($scope.view) {
                case 'New':
                    return {
                        only_new: true
                    };
                case 'Hidden':
                    return {
                        hidden: true
                    };
                case 'All':
                    return {
                        all: true
                    };
                case 'Default':
                default:
                    return {};
            }
        };

        $scope.hasPermissions = function(entity, action) {
            return $rootScope.hasPermissions(entity, action)
        };

        $scope.formatSecondsToMinutes = function (seconds = 0) {
            var minutes = Math.floor(seconds / 60);
            var seconds = (seconds % 60).toFixed(0);
            return minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
        };

        $scope.setPage = function (pageNo) {
            $scope.currentPage = pageNo;
        };

        $scope.pageChanged = function() {
            $window.scrollTo(0, 0);
        };

        $scope.setItemsPerPage = function(num) {
            $scope.itemsPerPage = num;
            $scope.currentPage = 1; //reset to first page
        };

        $scope.clearSearchVms = function() {
            $scope.search.submit = false;
            $scope.search.term = '';
            $scope.loadVms();
        };

        $scope.searchVms = function() {
            $scope.search.term = $.trim($scope.search.term);
            if($scope.search.term !== '') {
                $scope.search.submit = true;
                $scope.loadVms();
            } else if($scope.search.submit) {
                $scope.clearSearchCalls();
            }
        };

        $scope.filterVms = function(view) {
            if(view !== $scope.view) {
                $scope.view = view;
                $scope.loadVms();
            }
        };

        $scope.filterByNumber = function(number) {
            $scope.search.number = (number === 'all') ? '': number;
            $scope.loadVms();
        };

        $scope.viewContactDetails = function(vms) {
            $location.path('/contacts/detail').search('id=' + vms.contact_id);
        };

        $scope.textContact = function(vms) {
            $rootScope.openTextArea(vms);
        };

        $scope.callContact = function(vms) {
            $rootScope.$broadcast('openCallTab', vms);
        };

        $scope.loadVms = function() {
            $scope.loading.vms = true;
            $window.scrollTo(0, 0);
            var teamId = authService.getTeamId();
            var userId = authService.getUserId();

            const request = {
                team_id: teamId,
                user_id: userId,
                recording_type: 'voicemail',
                include_total: true,
                names: true,
                minTs: $scope.minTs,
                maxTs: $scope.maxTs,
                skip: ($scope.currentPage - 1) * $scope.itemsPerPage,
                limit: $scope.itemsPerPage
            };

            if($scope.search.number){
                request.phone_number = $scope.search.number.phone_number;
            }

            if($scope.view) {
                $.extend(request, $scope.__getViewParams());
            }

            if($scope.search.term){
                request.search_term =  $scope.search.term;
            }

            phoneService.getVms(request)
                .then(vms => {
                    $scope.vms = vms.vms;
                    $scope.totalItems = vms.total;

                    $scope.vms.map(vms => {
                        vms.contact_number = vms.call_direction === 'outbound' ? vms.to : vms.from;
                        vms.contact_name = (vms.first_name || vms.last_name) ? ((vms.first_name || '') + ' ' + (vms.last_name || '')).trim() : null;
                    });

                    $rootScope.$broadcast('vms_seen');
                    $scope.loading.vms = false;
                    $scope.$apply();
                })
                .catch(err => {
                    console.error(err);
                    $scope.loading.vms = false;
                    $scope.$apply();
                });
        };

        $scope.getFullTranscription = function(vm, index) {

            $scope.loading.transcript = true;
            $('#transcriptModal').modal('show');

            let req = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                recording_id: vm.call_recording_id,
            };

            phoneService.getTranscription(req)
                .then(transcription => {
                    $scope.transcript = {
                        conversation: transcription,
                        details: $scope.vms[index]
                    };
                    $scope.loading.transcript = false;
                    $scope.$apply();
                })
                .catch(err => {
                    console.log(err);
                });
        };

        $scope.hideTranscriptionModal = function() {
            $('#transcriptModal').modal('hide');
        };

        $scope.setHidden = function(vm, index) {

            let req = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                recording_type: 'voicemail',
                call_recording_id: vm.call_recording_id,
                hidden: !vm.hidden
            };

            $scope.loading.vms = true;

            phoneService.updateVoicemail(req)
                .then(results => {
                    //swal("Success", "Voicemail " + (!vm.hidden ? 'hidden' : 'unhidden'), "success");
                    $scope.vms.splice(index, 1);
                    $scope.loading.vms = false;
                    $scope.$apply();
                })
                .catch(err => {
                    swal("Cancelled", "Unable to " + (!vm.hidden ? 'hide voicemail' : 'unhide voicemail'), "error");
                    $scope.loading.vms = false;
                });
        };

        $scope.downloadTranscript = function() {
            let element = document.createElement('a');
            let text = $scope.buildTranscriptExport();

            element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
            element.setAttribute('download', 'transcript_' + $scope.transcript.details.call_recording_id + '.txt');

            element.style.display = 'none';
            document.body.appendChild(element);

            setTimeout(function() {
                element.click();
                document.body.removeChild(element);
            });
        };

        $scope.buildTranscriptExport = function() {

            let fullText =  $scope.staticContent.transcription_header
                .replace(':from', $scope.transcript.details.from)
                .replace(':to', $scope.transcript.details.to)
                .replace(':start_time', moment($scope.transcript.details.started_at))
                .replace(':duration', $scope.formatSecondsToMinutes($scope.transcript.details.duration));

            for (var sentence of  $scope.transcript.conversation) {
                let speaker = sentence.label === 'ch_0' ? 'Contact' : 'Agent';
                fullText += '[' + speaker + ']: ' + sentence.sentence + '\n';
            }

            return fullText;
        };

        $scope.loadPhones = function() {
            $scope.loading.numbers = true;
            var teamId = authService.getTeamId();
            var userId = authService.getUserId();

            const request = {
                team_id: teamId,
                user_id: userId
            };

            phoneService.getNumbers(request)
                .then(numbers => {
                    $scope.numbers = numbers.numbers;
                    $scope.loading.numbers = false;
                    $scope.$apply();
                })
                .catch(err => {
                    console.error(err);
                    $scope.loading.numbers = false;
                    $scope.$apply();
                });
        };

        $scope.initDateRange = function() {
            var start = moment().subtract(7, 'days');
            var end = moment();

            function cb() {
                $('#voicemailDateRange span').html(start.format('MMM D, YYYY') + ' - ' + end.format('MMM D, YYYY'));
                $scope.minTs = start.valueOf();
                $scope.maxTs = end.valueOf();
            }

            $('#voicemailDateRange').daterangepicker({
                startDate: start,
                endDate: end,
                opens: 'left',
                ranges: {
                    'Today': [moment().startOf('day'), moment().endOf('day')],
                    'Yesterday': [moment().subtract(1, 'days').startOf('day'), moment().startOf('day')-1],
                    'Last 7 Days': [moment().subtract(6, 'days').startOf('day'), moment().endOf('day')]
                }
            }, cb);

            cb();
        };

        $scope.onInit = function() {
            $scope.initDateRange();
            $scope.loadVms();
            $scope.loadPhones();
        };

        if (authService.hasAccount()) {
            $scope.onInit();
        }
        else {
            $scope.$on('auth_complete', function(event, args) {
                $scope.onInit();
            });
        }
    }
    module.exports = VoicemailController;
})();
