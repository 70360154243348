(function() {
    'use strict';

    function ConnectSmtpModalController($rootScope, $scope, integrationService) {
        var vm = this;

        $scope.data = {
            saving: false,
            isFormSubmitted: false,
        }

        $scope.forms = {
            mail_server_uri: {
                value: '',
                is_valid: true
            },
            port: {
                value: '',
                is_valid: true
            },
            username: {
                value: '',
                is_valid: true
            },
            password: {
                value: '',
                is_valid: true
            },
            use_tls: {
                value: false,
                is_valid: true
            },
            _id: {
                value: undefined,
            },
        };

        $scope.onInit = () => {
            $('#connectToSmtp').on('hide.bs.modal', function (e) {
                $scope.resetForm();
            });
        }

        $scope.onInit();

        $scope.resetForm = () => {
            $scope.forms = {
                mail_server_uri: {
                    value: '',
                    is_valid: true
                },
                port: {
                    value: '',
                    is_valid: true
                },
                username: {
                    value: '',
                    is_valid: true
                },
                password: {
                    value: '',
                    is_valid: true
                },
                use_tls: {
                    value: false,
                    is_valid: true
                },
                _id: {
                    value: undefined,
                },
            };
        }

        $scope.validateControl = (type) => {
            if ($scope.data.isFormSubmitted) {
                $scope.forms[type].is_valid = !!$scope.forms[type].value;
            }
        }

        $scope.connectSMTP = () => {
            $scope.data.isFormSubmitted = true;

            $scope.validateControl('mail_server_uri');
            $scope.validateControl('port');
            $scope.validateControl('username');
            $scope.validateControl('password');

            if (!$scope.forms.mail_server_uri.is_valid || !$scope.forms.port.is_valid || !$scope.forms.username.is_valid || !$scope.forms.password.is_valid) {
                return;
            }

            $scope.data.saving = true;
            const req = Object.keys($scope.forms).reduce((reqObj, key) => {
                reqObj[key] = $scope.forms[key].value;
                return reqObj;
            }, {});
            req.integration_type = 'smtp';
            integrationService.connect(req)
                .then(() => {
                    $scope.data.saving = false;
                    $('#connectToSmtp').modal('hide');
                });
        }
    }
    module.exports = ConnectSmtpModalController;
})();
