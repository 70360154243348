(function() {
    'use strict';

    function AccountService(apiService, $rootScope) { 
        return {
            signIn: function (request) {
                return apiService.post($rootScope.settings.API_AUTH_URL + '/auth/signin', request);
            },

            signOut: function (teamId, userId) {
                const request = {
                    team_id: teamId,
                    user_id: userId
                }
                return apiService.post($rootScope.settings.API_AUTH_URL + '/auth/signout', request, {logout_on_unauthorized: true});
            },

            signUp: function (request) {
                return apiService.post($rootScope.settings.API_PUBLIC_URL + '/public/account/signup', request);
            },

            vet: function (request) {
                return apiService.post($rootScope.settings.API_PUBLIC_URL + '/public/account/vet', request);
            },

            trialSignUp: function (request) {
                return apiService.post($rootScope.settings.API_PUBLIC_URL + '/public/account/trial/signup', request);
            },

            trialVet: function (request) {
                return apiService.post($rootScope.settings.API_PUBLIC_URL + '/public/account/trial/vet', request);
            },
            twilioSendVerification: function (request) {
                return apiService.post($rootScope.settings.API_PHONE_URL + '/phone/sendVerification', request);
            },
            twilioVerifyCode: function (request) {
                return apiService.post($rootScope.settings.API_PHONE_URL + '/phone/verifyCode', request);
            },

            // outdated
            renew: function (request) {
                return apiService.get($rootScope.settings.API_AUTH_URL + '/auth/renew', request);
            },

            changePassword: function (request) {
                return apiService.post($rootScope.settings.API_AUTH_URL + '/auth/password');
            },

            getTeam: function (request) {
                return apiService.get($rootScope.settings.API_REGISTER_URL + '/register/team', request);
            },

            updateTeam: function (request) {
                return apiService.post($rootScope.settings.API_REGISTER_URL + '/register/team/' + request.team_id, request);
            },

            getBilling: function (request) {
                return apiService.get($rootScope.settings.API_REGISTER_URL + '/register/team/' + request.team_id +'/billing', request);
            },

            getSubscriptions: function (request) {
                return apiService.get($rootScope.settings.API_REGISTER_URL + '/register/team/' + request.team_id +'/subscriptions', request);
            },

            commitSubscriptionChanges: function (request) {
                return apiService.post($rootScope.settings.API_REGISTER_URL + '/register/team/' + request.team_id +'/subscriptions/commit', request);
            },

            changeUsagePlan: function (request) {
                return apiService.post($rootScope.settings.API_REGISTER_URL + '/register/team/' + request.team_id +'/usage', request);
            },

            manualCharge: function (request) {
                return apiService.post($rootScope.settings.API_REGISTER_URL + '/register/team/' + request.team_id +'/charge/manual', request);
            },

            updateCardPayment: function (request) {
                return apiService.post($rootScope.settings.API_REGISTER_URL + '/register/team/' + request.team_id +'/billing/card', request);
            },

            updateBillingAddress: function (request) {
                return apiService.post($rootScope.settings.API_REGISTER_URL + '/register/team/' + request.team_id +'/billing/address', request);
            },

            updateBilling: function (request) {
                return apiService.post($rootScope.settings.API_REGISTER_URL + '/register/team/' + request.team_id +'/billing', request);
            },

            calculateTaxes: function (request) {
                return apiService.post($rootScope.settings.API_REGISTER_URL + '/register/team/' + request.team_id +'/billing/taxes', request);
            },

            saveConsent: function (request) {
                return apiService.post($rootScope.settings.API_REGISTER_URL + '/register/team/' + request.team_id + '/consent', request);
            },

            registerBrand: function (request) {
                return apiService.post($rootScope.settings.API_REGISTER_URL + '/register/team/' + request.team_id + '/brand', request);
            },

            getBrands: function (request) {
                return apiService.get($rootScope.settings.API_REGISTER_URL + '/register/team/' + request.team_id + '/brand', request);
            },

            registerCampaign: function (request) {
                return apiService.post($rootScope.settings.API_REGISTER_URL + '/register/team/' + request.team_id + '/campaign', request);
            },

            getCampaigns: function (request) {
                return apiService.get($rootScope.settings.API_REGISTER_URL + '/register/team/' + request.team_id + '/campaign', request);
            },

            addCampaignToNumber: function (request) {
                return apiService.post($rootScope.settings.API_REGISTER_URL + '/register/team/' + request.team_id + '/campaign/' + request.campaign_id + '/phone/' + request.phone_number, request);
            },

            removeCampaignToNumber: function (request) {
                return apiService.del($rootScope.settings.API_REGISTER_URL + '/register/team/' + request.team_id + '/campaign/' + request.campaign_id + '/phone/' + request.phone_number, request);
            },

            cancelTeam: function (request) {
                return apiService.del($rootScope.settings.API_REGISTER_URL + '/register/team/' + request.team_id, request);
            },

            getUser: function (request) {
                return apiService.get($rootScope.settings.API_REGISTER_URL + '/register/user', request);
            },

            resendCode: function (request) {
                return apiService.post($rootScope.settings.API_REGISTER_URL + '/register/resend', request);
            },

            getInvites: function (request) {
                return apiService.get($rootScope.settings.API_REGISTER_URL + '/register/invites', request);
            },

            resetPassword: function (request) {
                return apiService.post($rootScope.settings.API_REGISTER_URL + '/register/password/reset', request);
            },

            checkPasswordReset: function (request) {
                return apiService.post($rootScope.settings.API_REGISTER_URL + '/register/password/reset/check', request);
            },

            setPassword: function (request) {
                return apiService.post($rootScope.settings.API_REGISTER_URL + '/register/password/set', request);
            },

            getPublicHostedPage: function (request) {
                return apiService.get($rootScope.settings.API_PUBLIC_URL + '/public/chargebee/hosted_page/' + request.page_id, request);
            },

            getPublicTeam: function (request) {
                return apiService.get($rootScope.settings.API_PUBLIC_URL + '/public/account/' + request.team_id, request);
            },

            createUser: function (request) {
                return apiService.post($rootScope.settings.API_REGISTER_URL + '/register/createuser', request);
            },

            checkName: function (request) {
                return apiService.post($rootScope.settings.API_REGISTER_URL + '/register/checkname', request);
            },

            sendVerifications: function (request) {
                return apiService.post($rootScope.settings.API_REGISTER_URL + '/register/send_verifications', request);
            },

            verifyCode: function (request) {
                return apiService.get($rootScope.settings.API_REGISTER_URL + '/register/verify/' + request.pin, request);
            },

            getVerifications: function (request) {
                return apiService.get($rootScope.settings.API_REGISTER_URL + '/register/verify/' + request.type);
            },

            getHostedPage: function (request) {
                return apiService.post($rootScope.settings.API_REGISTER_URL + '/register/page/' + request.page, request);
            },

            getApiKeys: function (request) {
                return apiService.get($rootScope.settings.API_REGISTER_URL + '/team/' + request.team_id + '/api', request);
            },

            updateApiKeys: function (request) {
                return apiService.post($rootScope.settings.API_REGISTER_URL + '/team/' + request.team_id + '/api/' + request.type, request);
            },

            generateApiKeys: function (request) {
                return apiService.post($rootScope.settings.API_REGISTER_URL + '/team/' + request.team_id + '/api', request);
            }
        };
    }
    module.exports = AccountService;
})();

