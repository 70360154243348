(function() {
    'use strict';

    function CreateDomainModalController(authService, domainService, $rootScope, $scope, $http) {

        var vm = this;
        vm.authService = authService;

        $scope.staticText = {
            loading: 'Loading',
            creating: 'Creating',
            editDomain: {
                labels: [ 'Domain']
            }
        };

        $scope.data = {
            createDomain: {
                sub_domain: '',
                parent_domain: 'cc-mail.io',
                formSubmitted: false,
                creatingDomain: false
            },
        };

        $scope.createModalSubmitForm = function () {
            $scope.data.createDomain.formSubmitted = true;
            $scope.data.createDomain.sub_domain = $scope.data.createDomain.sub_domain.trim();

            if ($scope.addDomainForm.$valid && !!$scope.data.createDomain.sub_domain.length && $scope.checkSymbolsInSubdomain()) {
                $scope.data.createDomain.creatingDomain = true;

                let request = {
                    team_id: authService.getTeamId(),
                    user_id: authService.getUserId(),
                    sub_domain: $scope.data.createDomain.sub_domain,
                    parent_domain: $scope.data.createDomain.parent_domain,
                    full_domain : `${$scope.data.createDomain.sub_domain}.${$scope.data.createDomain.parent_domain}`,
                };

                domainService.createOne(request)
                    .then(domain => {
                        const alertMessage = {
                            title: 'Created',
                            body: 'Domain successfully created.'
                        };
                        $scope.editModalSubmitSuccess(alertMessage);
                    })
                    .catch(err => {
                        console.error(err);
                        $scope.editModalSubmitError(err.message);
                    });
            }
        }

        $scope.checkSymbolsInSubdomain = function () {
            return !(new RegExp('[@|.]').test($scope.data.createDomain.sub_domain));
        }

        $scope.editModalSubmitSuccess = function(alertMessage) {
            swal(alertMessage.title, alertMessage.body, "success");
            $scope.data.createDomain.creatingDomain = false;
            $scope.data.createDomain.formSubmitted = false;
            $scope.data.createDomain.sub_domain = '';
            $('#add-domain-modal').modal('hide');
            $rootScope.$broadcast('addDomainEventDone', true);
            $scope.$apply();
        };

        $scope.editModalSubmitError = function(message) {
            swal("Error", message, "error");
            $scope.data.createDomain.creatingDomain = false;
            $scope.data.createDomain.formSubmitted = false;
            $scope.data.createDomain.sub_domain = '';
            $('#add-domain-modal').modal('hide');
            $scope.$apply();
        };
    }
    module.exports = CreateDomainModalController;
})();
