(function () {
    'use strict';

    function NumberPoolController($rootScope, $scope, $location, $interval, authService, accountService, tcrService, numberService, DTOptionsBuilder, DTDefaultOptions, $http) {

        var vm = this;
        vm.authService = authService;

        $rootScope.viewData = $rootScope.viewData || {};
        $rootScope.viewData.breadcrumbs = $rootScope.viewData.breadcrumbs || [];
        $rootScope.viewData.breadcrumbs = [{
            title: 'Number Pools',
            link_name: 'Number Pools',
            link_url: '#/number-pools',
            help_url: $rootScope.help_url
        }];

        $scope.staticText = {
            findBlock: {
                title: "Add Pool Numbers",
                numberType: ["Local", "Toll Free"],
                searchInputPlaceholder: "Prefix / Area Code",
                searchBtn: "Search",
                optionMessage: "* MMS messaging not available for toll-free numbers",
                block1: {
                    title: "Search Numbers",
                    subtitle: "Select a toll-free prefix or search by area code..."
                }
            },
            resultBlock: {
                title: "Available numbers",
                subtitle: "These numbers are available for your pool...",
                loader: "Loading",
                table: {
                    header: ["Phone Number", "Select"],
                    optionTd: {
                        mainText: "Premium Feature",
                        smallText: "Membership Required"
                    },
                    actionsTd: {
                        upgradeBtn: "Become a Member",
                        rentBtn: "Rent Number"
                    },
                    noRes: "No numbers match your search criteria. Change your criteria and try again."
                },
                submitBtn: "Add to Pool"
            },
            ageGate: '(18+ or 21+ material)',
            title: 'Number Pools',
            subtitle: 'Manage your private number pools...',
            addRvmBtn: {
                title: 'Add Numbers'
            },
            addSmsBtn: {
                title: 'Add Numbers'
            },
            addNumberPoolBtn: {
                title: 'Add Number Pool'
            },
            deleteNumberBtn: {
                title: 'Delete'
            },
            table: {
                header: [
                    'Service',
                    'Utilization',
                    'Status'
                ],
                details: [
                    'Phone Number',
                    'Status',
                    '',
                ],
                actions: [
                    'Edit Pool',
                    'Delete Pool',
                    'Add Number',
                    'Upload Numbers',
                    'Edit Trunks',
                    'Import Numbers',
                    'Remove All Numbers',
                    'Remove Number',
                    'View Pool ID'
                ]
            },
            loading: 'Loading',
            noResults: 'No Results',
            unlimited: 'Unlimited',
            suspended: 'Carrier Suspended',
            approved: 'Approved',
            selectNumber: 'Select Numbers',
            awaitingReview: 'Under Review',
            activeNumber: 'Active',
            addNumerPoolModal: {
                title: 'Number Pool Registration',
                submitBtn: 'Subscribe',
                instructions: {
                    title: 'Instructions',
                    description: '\n' + 'Number pools allow you to pre-register your traffic with Carriers and will likely improve your delivery rates. Carriers will use the information you supply here as a factor in determining to allow or block your messages on their network. Number pools incur recurring fees based on your use case.'
                },
                pricing: {
                    description: 'Registering a number pool additional fees (see Fee Schedule). Fees are non-refundable and automatically renew every month until canceled.',
                    descriptionWeekly: 'Registering a number pool additional fees (see Fee Schedule). Fees are non-refundable and automatically renew every week until canceled.',
                    fees: 'Fee Schedule (recurring subscription billed monthly)',
                    feesWeekly: 'Fee Schedule (recurring subscription billed weekly)',
                },
                block1: {
                    title: 'Brand',
                    subtitle: 'Select the brand that this number pool belongs to.  Brands will appear here once they are verified.',
                    brandPlaceholder: 'Select Brand'
                },
                block2: {
                    title: 'SIP Trunk',
                    description: "Enter your SIP Trunk credentials. You may need to whitelist our IP address with your SIP provider.",
                    connection_type: 'SIP Connection Type',
                    auth_type: ['IP Whitelist', 'Credentials'],
                    port: 'Port',
                    port_default: '5060',
                    trunk1: 'Trunk 1',
                    trunk2: 'Trunk 2',
                    username: 'Username',
                    password: 'Password',
                },
                block3: {
                    title: 'Use Case',
                    subtitle: 'Select your intended use of this pool.  Please be careful not to misrepresent your usage or the carriers may suspend your pool or your brand entirely. No refunds will be offered.',
                    useCasePlaceholder: 'Use Case',
                    checkbox1: 'This pool is associated with affiliate marketing',
                    checkbox2: 'This pool is associated with direct lending',
                    checkbox3: 'My text messages may contain a phone number',
                    checkbox4: 'My text messages may contain a link to a website URL',
                    checkbox5: 'My text messages may contain age-gated content'
                },
                block4: {
                    title: 'Sample Text Message',
                    subtitle: 'Provide a sample text message that you intend to send to your contacts. Carriers use this sample when deciding to approve your number pool on their network.',
                    title2: 'Second Sample Text Message',
                    subtitle2: 'Provide another sample text message that you intend to send to your contacts. Carriers use these samples when deciding to approve your number pool on their network.',
                    optOutText: "Reply STOP to opt-out"
                },
                block5: {
                    title: 'Help Message',
                    subtitle: "Provide instructions to reply to 'HELP' keywords.  Instructions must contain a toll-free phone number or a support email address. Additional forms of help are allowed, but either of the above is required at minimum.",
                    placeholder: 'Compose your help message. You must include a toll-free phone number or a support email address.'
                },
                block6: {
                    title: 'Pool Name',
                    subtitle: "Please name your pool.",
                    placeholder: 'Pool description'
                },
                block7: {
                    title: 'Privacy Policy',
                    subtitle: "Please provide instructions to reply to 'PRIVACY' keywords.  Instructions must contain a website URL, email, or other instructions for your contacts to view your brand's privacy policy.",
                    placeholder: 'Link or another way for contacts to view your Privacy Policy'
                },
                block8: {
                    title: 'Terms & Conditions',
                    subtitle: "Please provide instructions to reply to 'TERMS' keywords.  Instructions must contain a website URL, email, or other instructions for your contacts to view your brand's terms & conditions.",
                    placeholder: 'Link or another way for contacts to view your Terms & Conditions'
                },
                block9: {
                    title: 'Program Name / Product Description / Reason for Texting',
                    subtitle: "Please provide the description of your brand's program name or product description or reason your are texting your contacts.  For example: 'information you requested about buying a new vehicle' or 'the Facebook form you filled out regarding the sale of your property'.",
                    placeholder: 'Program name, product description, or reason for texting',
                    msg_count: 'Approximate number of messages a contact will receive per month'
                },
                block10: {
                    title: 'Message Frequency',
                    subtitle: "Approximate number of messages a contact will receive per month.",
                },
                sipBlock: {
                    host_placeholder: 'Host (sip.example.com)',
                    port_placeholder: 'Port',
                    user_placeholder: 'Username',
                    pwd_placeholder: 'Password',
                    cps_placeholder: 'CPS',
                    concurrency_placeholder: 'Concurrency'
                },
                billing: 'I acknowledge this is a non-refundable, recurring subscription, billed to my credit card and will auto-renew monthly until canceled.',
                billingWeekly: 'I acknowledge this is a non-refundable, recurring subscription, billed to my credit card and will auto-renew weekly until canceled.',
                agree: "I agree to use this number pool only for the selected use case and acknowledge that this pool may be suspended by a carrier for any reason at the carrier's discretion.",
                optin: "I obtained opt-in from my contacts using an approved method defined in the CTIA Guidelines.",
                rented: "I confirm that my opted-in contact lists are my own and I will not use contact lists that I have rented, purchased, or are shared by a 3rd party with me.",
            },
            requiredMarkerInfo: 'Field is required.',
            requiredError: 'This field is required.',
            phoneNumberError: 'Phone number is not valid.',
            smsEditorIncludeBannedKeywordsWarning: "The message contains banned content.",
            smsEditorIncludeStopKeywordsWarning: "The message must contain STOP opt-out.",
            taxIdFormatError: 'Invalid Tax ID format.',
            notRecommended: 'not recommended',
            creating: 'Registering',
            importDID: {
                title: "Import Phone Numbers",
                importFileBlock: {
                    title: "Import Phone Numbers",
                    subtitle: "Drop a CSV, TSV, or TAB file containing your numbers below",
                    desc: "(You may be able to export csv from your sip trunk provider)"
                },
                checkFileBlock: {
                    title: "Describe Your Spreadsheet",
                    clearBtn: "Undo Upload",
                    subtitle: "We need to know which column contains the phone numbers to add to your number pool...",
                    steps: [
                        "For each column in your spreadsheet...",
                        "Select a label that best describes the column."
                    ],
                    checkboxLabel: "The first row of my data is a header row",
                    country: {
                        title: "Choose a country",
                        subtitle: "What country are your phone numbers in..."
                    }
                },
                submitBtn: "Submit"
            }
        };

        $scope.data = {
            byoc_ip_address: null,
            trust_score_registered: true,
            desiredCapacity: ['0','1','2','3','4','5'],
            poolSize: [
                {
                    id: 'single_state',
                    title: 'Single State'
                },
                {
                    id: 'multi_state',
                    title: 'Multi-State'
                },
                {
                    id: 'national',
                    title: 'National'
                },
                {
                    id: 'BYOC',
                    title: 'BYOC'
                }
            ],
            states: [],
            numberPools: [],
            selectedNumbers: [],
            selectedImportPool: null,
            brands: [],
            brand_lookup: {},
            pollBrands: true,
            innerTableDetails: {
                marginTop: 22,  // set in number-pools.css .dataTables_wrapper
                marginBottom: 37,   // set in number-pools.css .dataTables_wrapper
                scrollDivMinHeight: 205 // set in number-pools.css .details-td.show
            },
            addNumberPoolForm: {
                formSubmitted: false,
                brand_id: null,
                force_brand: null,
                poolName: null,
                isSmsEditorIncludeBannedKeywords: undefined,
                isSmsEditorIncludeStopKeywords: undefined,
                isSmsEditorIncludeBannedKeywords2: undefined,
                isSmsEditorIncludeStopKeywords2: undefined,
                isHelpMessageValid: undefined,
                isCTAMessageValid: undefined,
                isPrivacyMessageValid: undefined,
                isTermsMessageValid: undefined,
                campaign: {
                    useCase: null,
                    embeddedUrl: false,
                    embeddedPhone: false,
                    directLending: false,
                    affiliateMarketing: false,
                    ageGate: false,
                    sampleMessage: null,
                    helpMessage: null,
                    termsMessage: null,
                    privacyMessage: null,
                    ctaMessage: null,
                    frequency: 1,
                    messageLength: 0,
                    messageLength2: 0,
                    serviceType: 'sms'
                },
                agree: false,
                billing: false
            },
            taxIDRegex: '/\\d/',
            numbers: [],
            rent_number: null,
            number_type: 'Local',
            area_code: '',
            iso_country_code: 'US',
            isoToCountry: {
                //'AU': 'Australia',
                //'BE': 'Belgium',
                'CA': 'Canada',
                //'FI': 'Finland',
                //'DE': 'Germany',
                //'NL': 'Netherlands',
                //'NO': 'Norway',
                //'SE': 'Sweden',
                //'CH': 'Switzerland',
                //'GB': 'United Kingdom',
                'US': 'United States'
            },
            serviceTypes: ['sms', 'rvm'],
            weeklyPlan: false,
            ctaMessageErrorMessage: '',
            helpMessageErrorMessage: '',
            termsMessageErrorMessage: '',
            privacyMessageErrorMessage: '',
            import_did: {
                file: null,
                csv: null,
                csv_preview: null,
                csv_headers: null,
                csv_has_header_row: false,
                is_valid: false,
                country: {
                    selected: {
                        code: 'US',
                        name: "United States"
                    }
                },
                countries: []
            }
        };

        $scope.bannedKeywords = [];

        $scope.verticals = [
            {
                "id": "PROFESSIONAL",
                "industryId": "professional-services",
                "displayName": "Professional Services",
                "description": "Professional services."
            },
            {
                "id": "HUMAN_RESOURCES",
                "industryId": "human-resources-staffing-recruitment",
                "displayName": "HR, Staffing or Recruitment",
                "description": "Human resources, staffing, or recruitment."
            },
            {
                "id": "TRANSPORTATION",
                "industryId": "transportation-logistics",
                "displayName": "Transportation or Logistics",
                "description": "Transportation or logistics services."
            },
            {
                "id": "POSTAL",
                "industryId": "postal-delivery",
                "displayName": "Postal and Delivery",
                "description": "Postal and delivery services."
            },
            {
                "id": "LEGAL",
                "industryId": "legal",
                "displayName": "Legal",
                "description": "Legal services and law firms."
            },
            {
                "id": "REAL_ESTATE",
                "industryId": "real-estate",
                "displayName": "Real Estate",
                "description": "Buying and Selling of residential and commercial properties."
            },
            {
                "id": "HEALTHCARE",
                "industryId": "healthcare-and-lifesciences",
                "displayName": "Healthcare",
                "description": "Healthcare services, including hospital, health insurances."
            },
            {
                "id": "ENERGY",
                "industryId": "energy-and-utilities",
                "displayName": "Energy and Utilities",
                "description": "Energy and Utilities."
            },
            {
                "id": "ENTERTAINMENT",
                "industryId": "entertainment",
                "displayName": "Entertainment",
                "description": "Entertainment, including gaming and dating services."
            },
            {
                "id": "RETAIL",
                "industryId": "retail-and-consumer-products",
                "displayName": "Retail",
                "description": "Consumer products and services, including Retails and eTails."
            },
            {
                "id": "AGRICULTURE",
                "industryId": "agriculture",
                "displayName": "Agriculture",
                "description": "Agriculture businesses and services"
            },
            {
                "id": "INSURANCE",
                "industryId": "insurance",
                "displayName": "Insurance",
                "description": "Insurance products and services."
            },
            {
                "id": "EDUCATION",
                "industryId": "education",
                "displayName": "Education",
                "description": "Educational institutions providing education to students in educational settings."
            },
            {
                "id": "HOSPITALITY",
                "industryId": "hospitality",
                "displayName": "Hospitality and Travel",
                "description": "Travel, hospitality and transportation."
            },
            {
                "id": "FINANCIAL",
                "industryId": "financial-services",
                "displayName": "Financial",
                "description": "Banking and financial institution."
            },
            {
                "id": "GAMBLING",
                "industryId": "gambling-and-lottery",
                "displayName": "Gambling Lottery",
                "description": "Gambling and Lotteries."
            },
            {
                "id": "CONSTRUCTION",
                "industryId": "construction-and-materials",
                "displayName": "Construction and Materials",
                "description": "Construction and Materials services"
            },
            {
                "id": "NGO",
                "industryId": "non-profit-organization",
                "displayName": "Non Government Organization",
                "description": "Non-profit organization with valid tax-exempted status."
            },
            {
                "id": "MANUFACTURING",
                "industryId": "manufacturing",
                "displayName": "Manufacturing",
                "description": "Goods producing industries."
            },
            {
                "id": "GOVERNMENT",
                "industryId": "public-sector",
                "displayName": "Government",
                "description": "Local, state or federal agencies, utilities."
            },
            {
                "id": "TECHNOLOGY",
                "industryId": "information-technology-services",
                "displayName": "Information Technology",
                "description": "Information technology and information services."
            },
            {
                "id": "COMMUNICATION",
                "industryId": "mass-media-and-communication",
                "displayName": "Communication and Mass Media",
                "description": "Communication and mass media."
            }
        ];

        $scope.useCases = [
            {
                "id": "ACCOUNT_NOTIFICATION",
                "classification": "STANDARD",
                "description": "All reminders, alerts, and notifications. (Examples include: flight delayed, hotel booked, appointment reminders.)",
                "displayName": "Account Notification",
                "maxSubUsecases": 0,
                "minSubUsecases": 0,
                "validSubUsecase": true,
                "is10DLC": true,
                "serviceType": "sms"
            },
            {
                "id": "CHARITY",
                "classification": "SPECIAL",
                "description": "Includes: 5013C Charity. Does not include: Religious organizations.",
                "displayName": "Charity",
                "maxSubUsecases": 5,
                "minSubUsecases": 0,
                "validSubUsecase": false,
                "is10DLC": true,
                "serviceType": "sms"
            },
            {
                "id": "CUSTOMER_CARE",
                "classification": "STANDARD",
                "description": "All customer care messaging, including account management and support",
                "displayName": "Customer Care",
                "maxSubUsecases": 0,
                "minSubUsecases": 0,
                "validSubUsecase": true,
                "is10DLC": true,
                "serviceType": "sms"
            },
            {
                "id": "HIGHER_EDUCATION",
                "classification": "STANDARD",
                "description": "For campaigns created on behalf of Colleges or Universities and will also include School Districts etc that fall outside of any \"free to the consumer\" messaging model",
                "displayName": "Higher Education",
                "maxSubUsecases": 0,
                "minSubUsecases": 0,
                "validSubUsecase": true,
                "is10DLC": true,
                "serviceType": "sms"
            },
            {
                "id": "MARKETING",
                "classification": "STANDARD",
                "description": "Any communication with marketing and/or promotional content",
                "displayName": "Marketing",
                "maxSubUsecases": 0,
                "minSubUsecases": 0,
                "validSubUsecase": true,
                "is10DLC": true,
                "serviceType": "sms"
            },
            {
                "id": "POLITICAL",
                "classification": "SPECIAL",
                "description": "Part of organized effort to influence decision making of specific group. All campaigns to be verified",
                "displayName": "Political",
                "maxSubUsecases": 5,
                "minSubUsecases": 0,
                "validSubUsecase": false,
                "is10DLC": true,
                "serviceType": "sms"
            },
            {
                "id": "POLLING_VOTING",
                "classification": "STANDARD",
                "description": "Polling and voting",
                "displayName": "Polling and voting",
                "maxSubUsecases": 0,
                "minSubUsecases": 0,
                "validSubUsecase": true,
                "is10DLC": true,
                "serviceType": "sms"
            },
            {
                "id": "PUBLIC_SERVICE_ANNOUNCEMENT",
                "classification": "STANDARD",
                "description": "An informational message that is meant to raise the audience's awareness about an important issue",
                "displayName": "Public Service Announcement",
                "maxSubUsecases": 0,
                "minSubUsecases": 0,
                "validSubUsecase": true,
                "is10DLC": true,
                "serviceType": "sms"
            }
        ];

        $scope.rvmUseCases = [
            {
                "id": "LOW VOLUME",
                "classification": "LOW VOLUME",
                "description": "Low volume ringless voicemail campaigns (less than 5,000 total messages per month).",
                "displayName": "Ringless Voicemail (Low Volume)",
                "maxSubUsecases": 0,
                "minSubUsecases": 0,
                "validSubUsecase": true,
                "is10DLC": false,
                "serviceType": "rvm"
            },
            {
                "id": "STANDARD",
                "classification": "STANDARD",
                "description": "Ringless voicemail campaigns (less than 15,000 total messages per month).",
                "displayName": "Ringless Voicemail (Standard)",
                "maxSubUsecases": 0,
                "minSubUsecases": 0,
                "validSubUsecase": true,
                "is10DLC": false,
                "serviceType": "rvm"
            },
            {
                "id": "HIGH VOLUME",
                "classification": "HIGH VOLUME",
                "description": "High volume ringless voicemail campaigns (more than 15,000 total messages per month).",
                "displayName": "Ringless Voicemail (High Volume)",
                "maxSubUsecases": 0,
                "minSubUsecases": 0,
                "validSubUsecase": true,
                "is10DLC": false,
                "serviceType": "rvm"
            }
        ];

        $scope.soleProprietorUseCases = [
            {
                "id": "ACCOUNT_NOTIFICATION",
                "classification": "STARTER",
                "description": "All reminders, alerts, and notifications. (Examples include: flight delayed, hotel booked, appointment reminders.)",
                "displayName": "Account Notification",
                "maxSubUsecases": 0,
                "minSubUsecases": 0,
                "validSubUsecase": true,
                "is10DLC": true,
                "serviceType": "sms"
            },
            {
                "id": "CUSTOMER_CARE",
                "classification": "STARTER",
                "description": "All customer care messaging, including account management and support",
                "displayName": "Customer Care",
                "maxSubUsecases": 0,
                "minSubUsecases": 0,
                "validSubUsecase": true,
                "is10DLC": true,
                "serviceType": "sms"
            },
            {
                "id": "HIGHER_EDUCATION",
                "classification": "STARTER",
                "description": "For campaigns created on behalf of Colleges or Universities and will also include School Districts etc that fall outside of any \"free to the consumer\" messaging model",
                "displayName": "Higher Education",
                "maxSubUsecases": 0,
                "minSubUsecases": 0,
                "validSubUsecase": true,
                "is10DLC": true,
                "serviceType": "sms"
            },
            {
                "id": "MARKETING",
                "classification": "STARTER",
                "description": "Any communication with marketing and/or promotional content",
                "displayName": "Marketing",
                "maxSubUsecases": 0,
                "minSubUsecases": 0,
                "validSubUsecase": true,
                "is10DLC": true,
                "serviceType": "sms"
            },
            {
                "id": "POLLING_VOTING",
                "classification": "STARTER",
                "description": "Polling and voting",
                "displayName": "Polling and voting",
                "maxSubUsecases": 0,
                "minSubUsecases": 0,
                "validSubUsecase": true,
                "is10DLC": true,
                "serviceType": "sms"
            },
            {
                "id": "PUBLIC_SERVICE_ANNOUNCEMENT",
                "classification": "STARTER",
                "description": "An informational message that is meant to raise the audience's awareness about an important issue",
                "displayName": "Public Service Announcement",
                "maxSubUsecases": 0,
                "minSubUsecases": 0,
                "validSubUsecase": true,
                "is10DLC": true,
                "serviceType": "sms"
            }
        ];

        $scope.byocUseCases = [
           {
                "id": "BYOC",
                "classification": "BYOC",
                "description": "Provide your own DIDs and SIP Trunks.",
                "displayName": "Bring Your Own Carrier",
                "maxSubUsecases": 0,
                "minSubUsecases": 0,
                "validSubUsecase": true,
                "is10DLC": false,
                "serviceType": "rvm"
            }
        ];

        $scope.stockExchanges = [
            "NONE",
            "NASDAQ",
            "NYSE",
            "AMEX",
            "AMX",
            "ASX",
            "B3",
            "BME",
            "BSE",
            "FRA",
            "ICEX",
            "JPX",
            "JSE",
            "KRX",
            "LON",
            "NSE",
            "OMX",
            "SEHK",
            "SSE",
            "STO",
            "SWX",
            "SZSE",
            "TSX",
            "TWSE",
            "VSE"
        ];

        $scope.entityTypes = [
            { 'label':'Private Company', 'value': 'PRIVATE_PROFIT'},
            { 'label':'Publicly Traded Company', 'value': 'PUBLIC_PROFIT'},
            { 'label':'Non-Profit', 'value': 'NON_PROFIT'},
            { 'label':'Government', 'value': 'GOVERNMENT'},
            { 'label':'Sole Proprietor', 'value': 'SOLE_PROPRIETOR'}
        ];

        $scope.altIDTypes = [
            { 'label':'No Business ID', 'value': 'NONE'},
            { 'label':'DUNS', 'value': 'DUNS'},
            { 'label':'GIIN', 'value': 'GIIN'},
            { 'label':'LEI', 'value': 'LEI'}
        ];

        $scope.loading = {
            pools: true,
            addPoolNumber: false,
            uploading: false
        };

        $scope.fieldTypeMapping = {
            'Phone Number': 'phone'
        };

        $scope.dropify = {
            options: {},
            events: {}
        };

        // Basic
        $('.dropify-csv').dropify({
            messages: {
                'default': 'Drag and drop a csv file here or click',
                'replace': 'Drag and drop or click to replace',
                'remove':  'Remove',
                'error':   'Ooops, something went wrong.  Try again.'
            }});

        var drEvent = $('#import-file-events').dropify();

        drEvent.on('dropify.beforeClear', function(event, element) {
            return confirm("Do you really want to delete \"" + element.file.name + "\" ?");
        });

        drEvent.on('dropify.afterClear', function(event, element) {
            alert('File deleted');
        });

        drEvent.on('dropify.errors', function(event, element) {
            console.log('Has Errors');
        });

        $('.dropify-csv').change(function(event) {
            console.log('File ready');

            var target = event.currentTarget || {};
            if (target && target.files && target.files.length) {

                var file = target.files[0];
                $scope.data.import_did.file = file;

                var parts = (file.name || '').split('.');
                var ext = (parts[parts.length - 1] || '').toLowerCase();
                if (ext === 'csv' || ext === 'tab' || ext === 'tsv') {

                    var rows = [];
                    Papa.parse(file, {
                        config: {
                            worker: true
                        },
                        step: function(results, parser) {
                            rows.push(results.data[0]);
                            if (rows.length > 4) {
                                parser.abort();
                            }
                        },
                        complete: function(results) {
                            $scope.data.import_did.csv = rows;
                            $scope.data.import_did.csv_preview = rows.slice(0, 4);

                            var headers = [];
                            var colHeaders = rows[0] || [];
                            for (var i = 0; i < colHeaders.length; i++) {
                                headers.push(null);
                            }
                            $scope.data.import_did.csv_headers = headers;

                            $scope.$apply();
                        }
                    });
                }
            }
        });

        $scope.showImport = function(pool) {
            $scope.data.selectedImportPool = pool;

            $scope.clearImport();
            $scope.data.import_did.country = {
                selected: {
                    code: 'US',
                    name: "United States"
                }
            };
            $scope.validateStep();

            angular.element('.dropify-clear').triggerHandler('click');
            $('#importDidModal').modal('show');
        };

        $scope.cancelImport = function() {
            $scope.data.selectedImportPool = null;
            $scope.clearImport();
            $('#importDidModal').modal('hide');
        };

        $scope.clearUpload = function ($event) {
            $event.preventDefault();
            $scope.clearImport();

            $scope.validateStep();
            angular.element('.dropify-clear').triggerHandler('click');
        };

        $scope.clearImport = function () {
            $scope.data.import_did.csv = null;
            $scope.data.import_did.csv_preview = null;
            $scope.data.import_did.csv_headers = null;
            $scope.data.import_did.csv_has_header_row = false;
            $scope.data.import_did.file = null;
        };

        $scope.mapHeader = function(index, value) {
            $scope.data.import_did.csv_headers[index] = value;
            $scope.validateStep();
        };

        $scope.__phoneMapped = function() {
            var headers = $scope.data.import_did.csv_headers || [];
            for (var i = 0; i < headers.length; i++) {
                if (headers[i] == 'Phone Number') {
                    return true;
                }
            }
            return false;
        };

        $scope.__reasonMapped = function() {
            var headers = $scope.data.import_did.csv_headers || [];
            for (var i = 0; i < headers.length; i++) {
                if (headers[i] == 'Reason') {
                    return true;
                }
            }
            return false;
        };

        $scope.__csvHasAtLeastOneRow = function() {
            var minCount = $scope.data.import_did.csv_has_header_row ? 1 : 0;
            return (($scope.data.import_did.csv || []).length > minCount);
        };

        $scope.validateStep = function() {
            var csvValid = ($scope.__csvHasAtLeastOneRow() && $scope.__phoneMapped());
            $scope.data.import_did.is_valid = !!(csvValid);
        };

        $scope.__toColLetters = function(value) {
            var colName = '';
            var dividend = Math.floor(Math.abs(value));
            var rest;

            while (dividend > 0) {
                rest = (dividend - 1) % 26;
                colName = String.fromCharCode(65 + rest) + colName;
                dividend = parseInt((dividend - rest) / 26);
            }
            return colName;
        };

        $scope.__buildFieldMeta = function() {
            let results = [];

            const headerRow = ($scope.data.import_did.csv || [])[0] || [];

            const mappings = $scope.data.import_did.csv_headers || [];
            for (let i = 0; i < mappings.length; i++) {

                const mapping = mappings[i] || 'Other';
                const typeMapping = $scope.fieldTypeMapping[mapping];

                const fieldName = ((($scope.data.import_did.csv_has_header_row) ? headerRow[i] : 'Column ' + $scope.__toColLetters(i + 1)) || '').trim();
                const displayName = typeMapping ? mapping : fieldName;

                results.push({
                    type: typeMapping,
                    field_name: fieldName,
                    display_name: displayName
                });
            }

            return results;
        };

        $scope.createImport = function() {

            if (!$scope.data.import_did.is_valid || $scope.loading.uploading || !$scope.data.selectedImportPool) {
                return;
            }

            $scope.loading.uploading = true;

            let team = (($rootScope.account || [])[0] || {});

            const request = {
                team_id: team.team_id,
                pool_id: $scope.data.selectedImportPool.pool_id,
                start_row: $scope.data.import_did.csv_has_header_row ? 1 : 0,
                filename: $scope.data.import_did.file.name,
                field_map: $scope.__buildFieldMeta(),
                country_iso: $scope.data.import_did.country.selected.code
            };

            numberService.createImport(request)
               .then(results => {
                   $scope.__uploadFile($scope.data.import_did.file, results.policy, results.import_id);
               })
               .catch(err => {
                   $scope.loading.uploading = false;
                   $scope.$apply();
                   swal("Server Error", "Our server failed to respond. You may have too many columns in your spreadsheet.", "error");
                   $scope.cancelImport();
               });
        };

        $scope.__uploadFile = function(file, policy, importId) {
            $.ajax({
                type: 'PUT',
                url: policy.url,
                contentType: policy.content_type,
                processData: false,
                data: file
            })
               .done(function(data, textStatus, jqXHR) {
                   $scope.__startImport(importId);
               })
               .fail(function(jqXHR, textStatus, errorThrown) {
                   swal("Failure", "Your file failed to upload.  Please try again.", "error");
                   console.log(arguments);

                   $scope.loading.uploading = false;
                   $scope.$apply();
                   $scope.cancelImport();
               });
        };

        $scope.__startImport = function(importId) {
            let team = (($rootScope.account || [])[0] || {});

            const request = {
                team_id: team.team_id,
                import_id: importId
            };

            numberService.startImport(request)
               .then(results => {
                   $scope.loading.uploading = false;
                   $scope.$apply();
                   $('#importDidModal').modal('hide');
               })
               .catch(err => {
                   $scope.loading.uploading = false;
                   $scope.$apply();
                   swal("Server Error", "Our server failed to respond. You may have too many columns in your spreadsheet.", "error");
                   $scope.cancelImport();
               });
        };

        $scope.deleteAllNumbers = function(pool) {
            let team = (($rootScope.account || [])[0] || {});

            const request = {
                team_id: team.team_id,
                pool_id: pool.pool_id
            };

            numberService.deletePoolNumbers(request)
               .then(results => {
                   $scope.loadNumberPoolDetails(pool, function() {
                       pool.loadingPoolDetails = false;
                       $scope.$apply();
                   });
                   $scope.$apply();
               })
               .catch(err => {
                   $scope.$apply();
                   swal("Server Error", "Our server failed to respond. Please try again.", "error");
               });
        };

        $scope.deleteNumber = function(num, pool) {
            let team = (($rootScope.account || [])[0] || {});

            const request = {
                team_id: team.team_id,
                pool_id: num.pool_id,
                phone_number: num.phone_number
            };

            numberService.deletePoolNumber(request)
               .then(results => {
                   $scope.loadNumberPoolDetails(pool, function() {
                       pool.loadingPoolDetails = false;
                       $scope.$apply();
                   });
                   $scope.$apply();
               })
               .catch(err => {
                   $scope.$apply();
                   swal("Server Error", "Our server failed to respond. Please try again.", "error");
               });
        };

        $(document).on('show.bs.collapse', '#accordion-trust-center-faq .collapse', function () {
            var icon = $(this).hasClass('accordion-arrows') ? 'ti-angle-up' : 'ti-minus';
            var id = $(this).attr('id');
            $('button[data-target="#' + id + '"]').closest('.card-header').addClass('active-accordion');
            $('button[data-target="#' + id + '"] .btn-title span').html('<i class="' + icon + '"></i>');
        });
        $(document).on('hide.bs.collapse', '#accordion-trust-center-faq .collapse', function () {
            var icon = $(this).hasClass('accordion-arrows') ? 'ti-angle-down' : 'ti-plus';
            var id = $(this).attr('id');
            $('button[data-target="#' + id + '"]').closest('.card-header').removeClass('active-accordion');
            $('button[data-target="#' + id + '"] .btn-title span').html('<i class="' + icon + '"></i>');
        });

        $scope.dtOptions = DTOptionsBuilder.newOptions()
            .withLanguage({
                'sEmptyTable':  ''
            });
        $scope.dtOptions = DTDefaultOptions.setOption('searching', false);
        $scope.dtOptions = DTDefaultOptions.setOption('info', false);
        $scope.dtOptions = DTDefaultOptions.setOption('scrollY', '243px');
        $scope.dtOptions = DTDefaultOptions.setOption('scrollX', '0px');
        $scope.dtOptions = DTDefaultOptions.setOption('scrollCollapse', true);
        $scope.dtOptions = DTDefaultOptions.setOption('paging', false);
        $scope.dtOptions = DTDefaultOptions.setOption('processing', false);
        $scope.dtOptions = DTDefaultOptions.setOption('autoWidth', false);
        $scope.dtOptions = DTDefaultOptions.setOption('order', [ 1, 'asc' ]);
        $scope.dtOptions = DTDefaultOptions.setOption('columnDefs', [ { 'targets': [0, 2, 3, 4, 5], sortable: false } ]);
        $scope.dtOptions = DTDefaultOptions.setDisplayLength(1);
        $scope.dtOptions = DTDefaultOptions.setLoadingTemplate('');
        $scope.dtOptions = DTDefaultOptions.setOption('initComplete', function(settings) {
            var pollDetailsTd = document.getElementById(settings.nTable.id + 'Td');
            var height = settings.aanFeatures.t[0].clientHeight;
            if(height > $scope.data.innerTableDetails.scrollDivMinHeight) {
                pollDetailsTd.style.height = (height + $scope.data.innerTableDetails.marginTop + $scope.data.innerTableDetails.marginBottom).toString() + 'px';
                $scope.$apply();
            }
        });

        $scope.hideDetailsArea = function(pollDetailsTd, pool) {
            pollDetailsTd.classList.remove('show');
            pollDetailsTd.style.height = '0';
            pool.details_area_visible = !pool.details_area_visible;
            pollDetailsTd.querySelector('.dataTables_wrapper').style.display = 'none';
        };

        $scope.showDetailsArea = function(pollDetailsTd, pool) {
            pollDetailsTd.classList.add('show');
            pool.details_area_visible = true;
        };

        $scope.tooglePoolDetailsArea = function(pool) {
            var pollDetailsTd = document.getElementById('poolDetailsTable' + pool.pool_id + 'Td');
            if(pool.details_area_visible && pollDetailsTd.classList.contains('show')) {
                $scope.hideDetailsArea(pollDetailsTd, pool);
            } else {
                pollDetailsTd.querySelector('.dataTables_wrapper').style.display = 'block';
                if(!pool.details) {
                    $scope.loadNumberPoolDetails(pool, function() {
                        pool.loadingPoolDetails = false;
                        $scope.$apply();
                    });
                    $scope.showDetailsArea(pollDetailsTd, pool);
                } else {
                    var height = pollDetailsTd.querySelector('.dataTables_wrapper').scrollHeight;
                    pollDetailsTd.style.height = (height + $scope.data.innerTableDetails.marginTop + $scope.data.innerTableDetails.marginBottom).toString() + 'px';
                    $scope.showDetailsArea(pollDetailsTd, pool);
                }
            }
        };

        $scope.editPool = function(pool) {
            console.log('editPool');
        };

        $scope.viewPoolId = function($event, pool) {
            swal("Number Pool ID", "The ID for this number pool is\n" + pool.pool_id + "\nYou may pass this value for pool_id in any calls that you make to the " + APP_COMPANY_NAME + " api.", "info");
        }

        $scope.confirmDelete = function(pool) {

            if (!$scope.loading.addPoolNumber) {
                $scope.loading.addPoolNumber = true;

                let team = (($rootScope.account || [])[0] || {});

                let req = {
                    team_id: team.team_id,
                    pool_id: pool.pool_id
                };

                numberService.deletePool(req)
                   .then(results => {
                       $scope.data.pollBrands = true;
                       $scope.loading.addPoolNumber = false;
                       $scope.loadNumberPools();
                       swal('Deleted!', 'This Number Pool has been deleted!', 'success');
                       $scope.$apply();
                   })
                   .catch(err => {
                       console.log(err);
                       $scope.loading.addPoolNumber = false;
                       swal("Fail", "Failed to delete your pool.  Please try again later.", "error");
                       $scope.$apply();
                   });
            }
        };

        $scope.deletePool = function($event, pool) {
            if ($event) {
                $event.preventDefault();
            }

            swal({
                title: 'Confirm',
                text: 'Are you sure you want to delete this number pool?',
                buttons: ['No', 'Yes'],
                dangerMode: true,
                closeOnClickOutside: false
            }, function(isConfirm){
                if (isConfirm) {
                    $scope.confirmDelete(pool);
                }
            });
        };

        $scope.canPickNumbers = function(pool) {

            let isSuspended = pool.suspended_mnos.length;
            let full = ((pool.number_count || 0) >= (pool.pricing.max_size || 0));
            if (!pool.is_10DLC) {
                return (!full && !isSuspended);
            }

            let selectNumber = (pool.registered && !pool.registered_10DLC && !pool.number_count);

            return (!full && !isSuspended && selectNumber);
        }

        // Add Number Pool Modal start
        $scope.setPhoneMask = function(elem, mask, placeholder) {
            elem.mask(mask, {
                placeholder: placeholder
            });
        };

        $scope.quill = new Quill('#editor-container', {
            modules: {
                syntax: true,
                toolbar: '#toolbar-container'
            },
            placeholder: 'Compose your text message. You must include "STOP" opt-out instructions on every message (click the "opt-out text" button above to insert it)...',
            theme: 'snow'
        });

        $scope.quill2 = new Quill('#editor-container2', {
            modules: {
                syntax: true,
                toolbar: '#toolbar-container2'
            },
            placeholder: 'Compose your text message. You must include "STOP" opt-out instructions on every message (click the "opt-out text" button above to insert it)...',
            theme: 'snow'
        });

        $scope.addPoolModalFindBannedKeywords = function(str) {
            var messageIncludeBannedKeywords = false;
            var lowerStr = str.toLowerCase();
            _.forEach($scope.bannedKeywords, function(keyword) {
                if(messageIncludeBannedKeywords) return;
                messageIncludeBannedKeywords = _.includes(lowerStr, keyword.toLowerCase());
            });
            return messageIncludeBannedKeywords;
        };

        $scope.addPoolModalFindStopKeywords = function(str) {
            return _.includes(str.toLowerCase(), 'stop');
        };

        $scope.taxIdValidation = function() {
            // Valid tax IDs for the US are in the format of either 12-1234567 or 123-12-1234
            var isEmptyTaxId = !$scope.data.addBrandForm.taxID && $scope.data.addBrandForm.taxID !== null;
            if($scope.data.addBrandForm.taxIDCountry === 'US') {
                if(!isEmptyTaxId) {
                    var match = $scope.data.addBrandForm.taxID.match(/^(\d{2}\-\d{7})+$|^(\d{3}\-\d{2}\-\d{4})+$/);
                    $scope.data.addBrandForm.isTaxIdValid = Boolean(match);
                }
            } else $scope.data.addBrandForm.isTaxIdValid = true;
        };

        $scope.isValidSelect = function(field, parent) {
            return (parent) ? ($scope.data.addNumberPoolForm[parent][field] !== null) : ($scope.data.addNumberPoolForm[field] !== null);
        };

        $scope.selectChangeColor = function(id) {
            $('#' + id).removeClass('placeholder');
        };

        $scope.addPoolModalInsertTextToEditor = function(text) {
            $scope.quill.insertText($scope.quill.getSelection(true).index, text);
        };

        $scope.addPoolModalInsertTextToEditor2 = function(text) {
            $scope.quill2.insertText($scope.quill2.getSelection(true).index, text);
        };

        $scope.addPoolModalGetStringFromEditor = function() {
            return $scope.quill.getText();
        };

        $scope.addPoolModalGetStringFromEditor2 = function() {
            return $scope.quill2.getText();
        };

        $scope.addPoolModalSetEditorText = function(value, source) {
            $scope.quill.setText(value, source || 'api');
        };

        $scope.addPoolModalSetEditorText2 = function(value, source) {
            $scope.quill2.setText(value, source || 'api');
        };

        $scope.quill.on('text-change', function() {
            $scope.$evalAsync();
            $scope._isSmsEditorValid();
        });

        $scope.quill2.on('text-change', function() {
            $scope.$evalAsync();
            $scope._isSmsEditorValid2();
        });

        $scope._isSmsEditorValid = function() {
            var editorText = ($scope.addPoolModalGetStringFromEditor() || '').trim();
            $scope.data.addNumberPoolForm.isSmsEditorIncludeBannedKeywords = $scope.addPoolModalFindBannedKeywords(editorText);
            $scope.data.addNumberPoolForm.isSmsEditorIncludeStopKeywords = $scope.addPoolModalFindStopKeywords(editorText);
            $scope.data.addNumberPoolForm.campaign.sampleMessage = editorText;
            $scope.data.addNumberPoolForm.campaign.messageLength = Math.max(0, editorText.length);
        };

        $scope._isSmsEditorValid2 = function() {
            var editorText = ($scope.addPoolModalGetStringFromEditor2() || '').trim();
            $scope.data.addNumberPoolForm.isSmsEditorIncludeBannedKeywords2 = $scope.addPoolModalFindBannedKeywords(editorText);
            $scope.data.addNumberPoolForm.isSmsEditorIncludeStopKeywords2 = $scope.addPoolModalFindStopKeywords(editorText);
            $scope.data.addNumberPoolForm.campaign.sampleMessage2 = editorText;
            $scope.data.addNumberPoolForm.campaign.messageLength2 = Math.max(0, editorText.length);
        };

        $scope.isSmsEditorValid = function() {
            $scope._isSmsEditorValid();
            return ($scope.data.addNumberPoolForm.campaign.messageLength > 0 &&
                !$scope.data.addNumberPoolForm.isSmsEditorIncludeBannedKeywords &&
                $scope.data.addNumberPoolForm.isSmsEditorIncludeStopKeywords);
        };

        $scope.isSmsEditorValid2 = function() {
            $scope._isSmsEditorValid2();
            return ($scope.data.addNumberPoolForm.campaign.messageLength2 > 0 &&
               !$scope.data.addNumberPoolForm.isSmsEditorIncludeBannedKeywords2 &&
               $scope.data.addNumberPoolForm.isSmsEditorIncludeStopKeywords2);
        };

        $scope.changedUseCase = function() {
            if($scope.data.addNumberPoolForm.formSubmitted) {
                $scope.validatePoolForm();
            }
        };

        $scope.changedHelpMessage = function() {
            $scope.data.addNumberPoolForm.isHelpMessageValid = undefined;
            $scope.data.helpMessageErrorMessage = '';
        };

        $scope.changedCTAMessage = function() {
            $scope.data.addNumberPoolForm.isCTAMessageValid = undefined;
            $scope.data.ctaMessageErrorMessage = '';
        };

        $scope.changedPrivacyMessage = function() {
            $scope.data.addNumberPoolForm.isPrivacyMessageValid = undefined;
            $scope.data.privacyMessageErrorMessage = '';
        };

        $scope.changedTermsMessage = function() {
            $scope.data.addNumberPoolForm.isTermsMessageValid = undefined;
            $scope.data.termsMessageErrorMessage = '';
        };



        $scope.validateHelpMessage = function(type, helpMessage) {
            if(type === 'sms') {
                let helpMessageLength = (!helpMessage || helpMessage === null || helpMessage === '') ? 0 : helpMessage.length;
                if(helpMessageLength === 0) {
                    $scope.data.helpMessageErrorMessage = "This field is required.";
                } else if(helpMessageLength <= 5) {
                    $scope.data.helpMessageErrorMessage = "Message length can't be less than 5 characters";
                }
                return (helpMessageLength === 0 || helpMessageLength <= 5) ? false : true;
            }
        };

        $scope.validateCTAMessage = function(type, ctaMessage) {
            if(type === 'sms') {
                let ctaMessageLength = (!ctaMessage || ctaMessage === null || ctaMessage === '') ? 0 : ctaMessage.length;
                if(ctaMessageLength === 0) {
                    $scope.data.ctaMessageErrorMessage = "This field is required.";
                } else if(ctaMessageLength <= 5) {
                    $scope.data.ctaMessageErrorMessage = "Message length can't be less than 5 characters";
                }
                return (ctaMessageLength === 0 || ctaMessageLength <= 5) ? false : true;
            }
        };

        $scope.validatePrivacyMessage = function(type, privacyMessage) {
            if(type === 'sms') {
                let privacyMessageLength = (!privacyMessage || privacyMessage === null || privacyMessage === '') ? 0 : privacyMessage.length;
                if(privacyMessageLength === 0) {
                    $scope.data.privacyMessageErrorMessage = "This field is required.";
                } else if(privacyMessageLength <= 5) {
                    $scope.data.privacyMessageErrorMessage = "Message length can't be less than 5 characters";
                }
                return (privacyMessageLength === 0 || privacyMessageLength <= 5) ? false : true;
            }
        };

        $scope.validateTermsMessage = function(type, termsMessage) {
            if(type === 'sms') {
                let termsMessageLength = (!termsMessage || termsMessage === null || termsMessage === '') ? 0 : termsMessage.length;
                if(termsMessageLength === 0) {
                    $scope.data.termsMessageErrorMessage = "This field is required.";
                } else if(termsMessageLength <= 5) {
                    $scope.data.termsMessageErrorMessage = "Message length can't be less than 5 characters";
                }
                return (termsMessageLength === 0 || termsMessageLength <= 5) ? false : true;
            }
        };

        $scope.validatePoolForm = function() {

            let form = $scope.data.addNumberPoolForm || {};

            let useCase = form.campaign.useCase || {};

            form.isHelpMessageValid = $scope.validateHelpMessage(useCase.serviceType, form.campaign.helpMessage);
            form.isTermsMessageValid = $scope.validateTermsMessage(useCase.serviceType, form.campaign.termsMessage);
            form.isPrivacyMessageValid = $scope.validatePrivacyMessage(useCase.serviceType, form.campaign.privacyMessage);
            form.isCTAMessageValid = $scope.validateCTAMessage(useCase.serviceType, form.campaign.ctaMessage);
            let messagesValidSmsType = $scope.isSmsEditorValid() && $scope.isSmsEditorValid2() && form.isHelpMessageValid && form.isTermsMessageValid && form.isPrivacyMessageValid && form.isCTAMessageValid;
            let messagesValid = (useCase.serviceType !== 'sms') ? ($scope.isSmsEditorValid() && $scope.isSmsEditorValid2()) : messagesValidSmsType;

            let checkboxes = useCase.id === 'BYOC' || (form.agree && form.billing && form.rented && form.optin);
            let brand = $scope.isValidSelect('brand_id');
            let valid10DLC = (!useCase.is10DLC || messagesValid) && brand;

            return (valid10DLC && useCase.id && checkboxes);
        };

        $scope.addPoolModalSubmitSuccess = function(alertMessage) {
            swal(alertMessage.title, alertMessage.body, "success");
            $scope.loading.addPoolNumber = false;
            $scope.data.addNumberPoolForm.formSubmitted = false;
            $('#addNumberPoolModal').modal('hide');
            // $scope.loadNumberPools();
            $scope.$apply();
        };

        $scope.addPoolModalSubmitError = function(alertMessage) {
            swal(alertMessage.title, alertMessage.body, "error");
            $scope.loading.addPoolNumber = false;
            $scope.data.addNumberPoolForm.formSubmitted = false;
            $('#addNumberPoolModal').modal('hide');
            $scope.$apply();
        };

        $scope.addNumberPoolModalSubmitForm = function() {
            $scope.data.addNumberPoolForm.formSubmitted = true;

            console.log($scope.data.addNumberPoolForm);

            if($scope.validatePoolForm()) {
                $scope.loading.addPoolNumber = true;

                let team = (($rootScope.account || [])[0] || {});

                let form = $scope.data.addNumberPoolForm;
                let useCase = form.campaign.useCase || {};
                let messages = useCase.is10DLC ? [form.campaign.sampleMessage, form.campaign.sampleMessage2] : null;
                let addOnId = $rootScope.poolPricing[useCase.serviceType][useCase.classification].add_on_id;

                if ($scope.data.weeklyPlan) {
                    addOnId += '-weekly';
                }

                let req = {
                    team_id: team.team_id,
                    brand_id: form.brand_id,

                    name: form.poolName || 'My Pool',

                    use_case: useCase.id,
                    is_10DLC: useCase.is10DLC,
                    service_type: useCase.serviceType,
                    add_on_id: addOnId,

                    messages: messages,
                    help_message: form.campaign.helpMessage,
                    cta_message: form.campaign.ctaMessage,
                    terms_message: form.campaign.termsMessage,
                    privacy_message: form.campaign.privacyMessage,
                    embedded_url: form.campaign.embeddedUrl,
                    embedded_phone: form.campaign.embeddedPhone,
                    direct_lending: form.campaign.directLending,
                    affiliate_marketing: form.campaign.affiliateMarketing,
                    age_gate: form.campaign.ageGate,
                    frequency: form.campaign.frequency
                };

                let hostedPageReq = {
                    team_id: team.team_id,
                    page: 'number_pool',
                    page_data: {
                        add_on_id: addOnId
                    }
                };

                console.log(req);

                if (useCase.id === 'BYOC') {
                    tcrService.registerCampaign(req)
                       .then(results => {
                           $scope.data.pollBrands = true;
                           $scope.loading.addPoolNumber = false;
                           $scope.loadNumberPools();
                           $scope.addPoolModalSubmitSuccess({title: 'Success!', body: "We registered your pool!  You may now add sip trunks and assign phone numbers to it."});
                       })
                       .catch(err => {
                           console.log(err);
                           $scope.loading.addPoolNumber = false;
                           swal("Fail", "Failed to register your pool.  Please double check your info and try again.", "error");
                           $scope.$apply();
                       });
                }
                else {
                    let success = false;

                    $rootScope.chargebee.openCheckout({
                        hostedPage: function() {
                            return new Promise(function(resolve, reject){
                                resolve(accountService.getHostedPage(hostedPageReq));
                            });
                        },
                        loaded: function() {
                            //console.log("checkout opened");
                        },
                        error: function() {
                        },
                        close: function() {
                            //console.log("checkout closed");

                            if (success) {
                                tcrService.registerCampaign(req)
                                   .then(results => {
                                       $scope.data.pollBrands = true;
                                       $scope.loading.addPoolNumber = false;
                                       $scope.loadNumberPools();
                                       if (useCase.is10DLC) {
                                           $scope.addPoolModalSubmitSuccess({title: 'Success!', body: "We registered your pool!  We will notify you once the carriers approve it for use in your campaigns."});
                                       }
                                       else {
                                           $scope.addPoolModalSubmitSuccess({title: 'Success!', body: "We registered your pool!  You may now assign phone numbers to it."});
                                       }
                                   })
                                   .catch(err => {
                                       console.log(err);
                                       $scope.loading.addPoolNumber = false;
                                       swal("Fail", "Failed to register your pool.  Please double check your info and try again.", "error");
                                       $scope.$apply();
                                   });
                            }
                            else {
                                $scope.loading.addPoolNumber = false;
                                $scope.$apply();
                            }
                        },
                        success: function(hostedPageId) {
                            //console.log(hostedPageId);
                            success = true;
                        },
                        step: function(value) {
                            // value -> which step in checkout
                            //console.log(value);
                        }
                    });
                }
            }
        };

        $scope.addNumberPool = function(brand) {
            $scope.addPoolModalClear();
            $scope.bannedKeywords = $rootScope.bannedKeywords;
            if (brand) {
                $scope.data.addNumberPoolForm.force_brand = brand;
                $scope.data.addNumberPoolForm.brand_id = brand.brand_id;
                $scope.data.addNumberPoolForm.brand = brand;
            }

            $('#addNumberPoolModal').modal('show');
            $("#state .selectize-input > input").attr('autocomplete', 'new-state-select');
            $("#useCase .selectize-input > input").attr('autocomplete', 'new-use-case');
            $("#vertical .selectize-input > input").attr('autocomplete', 'new-vertical');
            $("#incorporationState .selectize-input > input").attr('autocomplete', 'new-incorporation-state');

            $('#addNumberPoolModal').on('hidden.bs.modal', function () {
                $scope.addPoolModalClear();
            });
        };

        $scope.pickNumbers = function(pool) {

            if ($scope.canPickNumbers(pool)) {

                $scope.data.selectedNumbers = [];
                $scope.data.numbers = [];
                $scope.data.area_code = '';
                $scope.data.picker_pool_id = pool.pool_id;
                $scope.data.picker_pool = pool;

                if (pool.use_case === 'BYOC') {
                    $scope.showImport(pool);
                }
                else {
                    $('#numberPickerModal').modal('show');
                }
            }
        }

        $scope.addPoolModalClearForm = function() {

            $scope.data.addNumberPoolForm = {
                formSubmitted: false,
                brand_id: null,
                force_brand: null,
                poolName: null,
                isSmsEditorIncludeBannedKeywords: undefined,
                isSmsEditorIncludeStopKeywords: undefined,
                isSmsEditorIncludeBannedKeywords2: undefined,
                isSmsEditorIncludeStopKeywords2: undefined,
                isCTAMessageValid: undefined,
                isHelpMessageValid: undefined,
                isTermsMessageValid: undefined,
                isPrivacyMessageValid: undefined,
                campaign: {
                    useCase: null,
                    embeddedUrl: false,
                    embeddedPhone: false,
                    directLending: false,
                    affiliateMarketing: false,
                    ageGate: false,
                    sampleMessage: null,
                    helpMessage: null,
                    ctaMessage: null,
                    termsMessage: null,
                    privacyMessage: null,
                    frequency: 1,
                    messageLength: 0,
                    messageLength2: 0,
                    serviceType: 'sms'
                },
                agree: false,
                billing: false
            };
        };

        $scope.addPoolModalClear = function() {
            $("#addNumberPoolModalBody").stop().animate({scrollTop:0}, 500, 'swing');

            if($scope.iti) {
                $scope.clearIti();
            }
            $scope.addPoolModalClearForm();

            $scope.loading.addPoolNumber = false;
            $('#entityType').addClass('placeholder');
            $('#incorporationState').addClass('placeholder');
            $('#stockExchange').addClass('placeholder');
            $scope.addNumberPoolForm.$setPristine(true);
        };

        $scope.formatPhone = function(value) {
            return $rootScope.libPhoneFormatPhone(value);
        };

        $scope.setIsoCountry = function(value) {
            $scope.data.iso_country_code = value;
        };

        $scope.searchNumbers = function(event) {

            event.preventDefault();

            $scope.loading.numbers = true;
            $scope.data.selectedNumbers = [];

            let pool = $scope.data.picker_pool || {};
            let count = pool.number_count || 0;
            let maxCount = (pool.pricing || {}).max_size || 1;
            let rawLimit = maxCount - count;

            let limit = Math.min(10, Math.max(rawLimit, 0));

            let team = (($rootScope.account || [])[0] || {});
            let req = {
                team_id: team.team_id,
                is_10DLC: $scope.data.picker_pool.is_10DLC,
                country_iso: $scope.data.iso_country_code,
                pattern: $scope.data.area_code,
                limit: limit
            };

            if ($scope.data.iso_country_code === 'US' && $scope.data.number_type === 'Toll Free') {
                req.type = 'tollfree';
            }

            numberService.search(req)
               .then(results => {
                   $scope.data.numbers = results || [];
                   for (let i = 0; i < $scope.data.numbers.length; i++) {
                       $scope.data.numbers[i].selected = false;
                   }
                   $scope.loading.numbers = false;
                   $scope.$apply();
               })
               .catch(err => {
                   console.log(err);
                   $scope.loading.numbers = false;
                   $scope.$apply();
               });
        };

        $scope.selectNumber = function(num) {
            let nums = [];
            for (let i = 0; i < $scope.data.numbers.length; i++) {
                let otherNum = $scope.data.numbers[i];
                if (otherNum.selected) {
                    nums.push(otherNum.phone_number);
                }
            }
            $scope.data.selectedNumbers = nums;
        }

        $scope.rentNumbers = function() {

            let nums = $scope.data.selectedNumbers || [];
            if (nums) {
                console.log($scope.data.selectedNumbers );
                $scope.__rent(nums);
            }
        };

        $scope.__rent = function(numbers) {
            if(!$scope.loading.rent) {

                $scope.loading.rent = true;
                let team = (($rootScope.account || [])[0] || {});
                let req = {
                    team_id: team.team_id,
                    pool_id: $scope.data.picker_pool_id,
                    numbers: numbers
                };

                numberService.rentPoolNumbers(req)
                   .then(results => {

                       $scope.data.rent_number = null;
                       $scope.loading.rent = false;
                       $scope.data.picker_pool.number_count += numbers.length;
                       $scope.data.selectedNumbers = [];
                       swal('Success!', 'The Number Pool has been updated!', 'success');
                       $('#numberPickerModal').modal('hide');
                       $scope.$apply();
                   })
                   .catch(err => {
                       console.log(err);
                       $scope.data.rent_number = null;
                       $scope.loading.rent = false;
                       swal("Failed", "Failed to register phone number.  Please try again later.", "error");
                       $scope.$apply();
                   });
            }
        };

        $scope.validateByocForm = function() {
            return ($scope.data.sip_host_1 && $scope.data.sip_user_1 && $scope.data.sip_pwd_1);
        };

        $scope.editByoc = function(pool) {
            $scope.data.editByocPool = pool;

            let trunks = pool.sip_trunks || [];

            let trunk1 = $scope.decodeTrunk(trunks[0]);
            $scope.data.sip_host_1 = trunk1.host;
            $scope.data.sip_port_1 = trunk1.port;
            $scope.data.sip_user_1 = trunk1.user;
            $scope.data.sip_pwd_1 = trunk1.pwd;

            let trunk2 = $scope.decodeTrunk(trunks[1]);
            $scope.data.sip_host_2 = trunk2.host;
            $scope.data.sip_port_2 = trunk2.port;
            $scope.data.sip_user_2 = trunk2.user;
            $scope.data.sip_pwd_2 = trunk2.pwd;

            $('#byocModal').modal('show');
        };

        $scope.saveByocForm = function() {

            if($scope.validateByocForm()) {

                $scope.loading.byoc = true;

                let team = (($rootScope.account || [])[0] || {});
                let pool = $scope.data.editByocPool;

                let trunks = [
                    $scope.encodeTrunk({
                       host: $scope.data.sip_host_1,
                       port: $scope.data.sip_port_1,
                       user: $scope.data.sip_user_1,
                       pwd: $scope.data.sip_pwd_1,
                       type: 'basic',
                       provider: null
                    }),
                    $scope.encodeTrunk({
                        host: $scope.data.sip_host_2,
                        port: $scope.data.sip_port_2,
                        user: $scope.data.sip_user_2,
                        pwd: $scope.data.sip_pwd_2,
                        type: 'basic',
                        provider: null
                   })
                ];

                let req = {
                    team_id: team.team_id,
                    pool_id: pool.pool_id,
                    sip_trunks: trunks
                };

                numberService.updatePool(req)
                   .then(results => {
                       $scope.loading.byoc = false;
                       $('#byocModal').modal('hide');
                       $scope.$apply();
                   })
                   .catch(err => {
                       console.log(err);
                       $scope.loading.byoc = false;
                       swal("Fail", "Failed to save your pool.  Please try again later.", "error");
                       $scope.$apply();
                   });
            }
        };

        $scope.encodeTrunk = function(trunk) {
            return btoa(JSON.stringify(trunk));
        }

        $scope.decodeTrunk = function(encodedTrunk) {
            if (encodedTrunk) {
                return JSON.parse(atob(encodedTrunk));
            }

            return {};
        }

        $scope.loadBrands = function() {

            if ($scope.data.pollBrands) {
                var team = (($rootScope.account || [])[0] || {});

                let req = {
                    team_id: team.team_id
                };

                tcrService.getBrands(req)
                   .then(results => {

                       $scope.loading.brands = false;
                       $scope.data.pollBrands = false;

                       let brandsById = {};
                       let approvedBrands = [];
                       let brands = results || [];
                       for (let i = 0; i < brands.length; i++) {
                           let brand = brands[i];
                           let score = brand.trust_score || 0;
                           let status = brand.identity_status;
                           if (brand.registered && (score > 0 || status === 'VERIFIED' || status === 'VETTED_VERIFIED' || status === 'SELF_DECLARED')) {
                               brand.verified = true;
                               approvedBrands.push(brand);
                           }

                           brandsById[brand.brand_id] = brand;
                       }

                       $scope.data.brands = approvedBrands;
                       $scope.data.brand_lookup = brandsById;

                       if (!approvedBrands.length) {
                           $rootScope.showTrustModal();
                       }

                       $scope.$apply();
                   })
                   .catch(err => {
                       console.log(err);
                   });
            }
        }

        $scope.loadNumberPoolDetails = function(pool, callback) {
            pool.loadingPoolDetails = true;

            var team = (($rootScope.account || [])[0] || {});

            let req = {
                team_id: team.team_id,
                pool_id: pool.pool_id
            };

            numberService.getPoolNumbers(req)
               .then(results => {
                   pool.loadingPoolDetails = false;
                   pool.numbers = results || [];
                   $scope.$apply();
               })
               .catch(err => {
                   pool.loadingPoolDetails = false;
                   console.log(err);
               });
        };

        $scope.loadNumberPools = function() {

            if ($scope.data.pollBrands) {

                var team = (($rootScope.account || [])[0] || {});

                let req = {
                    team_id: team.team_id
                };

                numberService.getPools(req)
                   .then(results => {
                       let pools = results || [];
                       $scope.data.numberPools = pools;
                       $scope.loading.pools = false;
                       $scope.data.pollPools = false;
                       $scope.data.registeredPoolCount = 0;
                       $scope.data.weeklyPlan = ($rootScope.weeklyPlans || []).includes($rootScope.membership_level);

                       let priceMap = {};
                       for (let serviceType of $scope.data.serviceTypes) {
                           let servicePrices = $rootScope.poolPricing[serviceType];
                           for (let key in servicePrices) {
                               let price = servicePrices[key];
                               price.category = key;
                               priceMap[price.add_on_id] = price;
                               priceMap[price.add_on_id + '-weekly'] = price;
                           }
                       }

                       for (let i = 0; i < pools.length; i++) {
                           let pool = pools[i];
                           let servicePricing = $rootScope.poolPricing[pool.service_type] || {};
                           pool.pricing = priceMap[pool.add_on_id] || {};
                           //pool.number_count = 1;

                           if (!pool.approved || (pool.is_10DLC && !pool.registered_10DLC)) {
                               $scope.data.pollPools = true;
                           }
                           else {
                               $scope.data.registeredPoolCount++;
                           }
                       }

                       $scope.$apply();
                   })
                   .catch(err => {
                       console.log(err);
                   });
            }
        };

        $scope.loadProxy = function(proxy_id) {

            var team = (($rootScope.account || [])[0] || {});

            let req = {
                team_id: team.team_id,
                proxy_id: proxy_id
            };

            numberService.getProxy(req)
               .then(proxy => {
                   $scope.data.byoc_ip_address = (proxy || {}).ip_address;
                   $scope.$apply();
               })
               .catch(err => {
                   console.log(err);
               });
        };

        $scope.initPricing = function() {
            $.getJSON("assets/content/pricing.json", function(json) {
                $rootScope.affiliateOffers = json.offers || {};
                $rootScope.poolPricing = json.pools || {};
                $rootScope.weeklyPlans = json.weekly || [];
                $rootScope.DC_PRICING = json.rvm;

                let team = (($rootScope.account || [])[0] || {});
                let voip = team.voip || {};
                if (!voip.force_byoc) {
                    $scope.useCases = $scope.useCases.concat($scope.rvmUseCases);
                    $scope.soleProprietorUseCases = $scope.soleProprietorUseCases.concat($scope.rvmUseCases);
                }

                if (voip.allow_byoc) {
                    $scope.useCases = $scope.useCases.concat($scope.byocUseCases);
                    $scope.soleProprietorUseCases = $scope.soleProprietorUseCases.concat($scope.byocUseCases);
                }

                $scope.data.byoc_trunk_id = voip.trunk_id;
                $scope.data.byoc_proxy_id = voip.proxy_id;

                if (voip.proxy_id) {
                    $scope.loadProxy(voip.proxy_id);
                }

                $scope.$apply();
            });
        };

        $scope.$on('$destroy', function() {
            $interval.cancel($scope.brandPolling);
        });

        $scope.onInit = function() {
            $scope.initPricing();
            $scope.loadNumberPools();
            $scope.loadBrands();

            $http.get('components/models/countries.json')
               .then(function(res){
                   $scope.data.import_did.countries = res.data;
               });

            $scope.brandPolling = $interval($scope.loadBrands, 15000);
        };

        if (authService.hasAccount()) {
            $scope.onInit();
        }
        else {
            $scope.$on('auth_complete', function(event, args) {
                $scope.onInit();
            });
        }
    }
    module.exports = NumberPoolController;
})();
