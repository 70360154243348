(function () {
    'use strict';
    
    function DncService(apiService, $rootScope) { 
        return {
            getLists: function (request) {
                return apiService.get($rootScope.settings.API_DNC_URL + '/dnc', request);
            },

            createOne: function (request) {
                return apiService.post($rootScope.settings.API_DNC_URL + '/dnc', request);
            },

            deleteOne: function (request) {
                return apiService.del($rootScope.settings.API_DNC_URL + '/dnc', request);
            }
        };
    }
    module.exports = DncService;
})();