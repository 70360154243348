(function() {
    'use strict';

    function UpsellModalController(authService, userService, accountService, $rootScope, $scope, $location, $window, $state) {
        var vm = this;
        vm.authService = authService;

        $scope.staticData = {
            title: 'Plans and Pricing',
            subtitle1: 'Select Your Plan',
            subtitle2: 'Find The Best Plan For Your Business Today',
            tabs: {
                'contact_center': { name: 'Contact Center & Dialing Solutions' },
                'ringless_voicemail': { name: 'Ringless Voicemail & Bulk SMS' },
                'call_routing': { name: 'Call Routing & Tracking' },
                'call_recording': { name: 'Call Recording & Transcription' }
            },
            memberShipSwitch: {
                disableText: 'Monthly',
                enableText: 'Annual',
                generalText: 'Subscription'
            },
            buttons: {
                buyNowBtn: 'Buy Now',
                subscribeBtn: 'Subscribe',
                start7DaysBtn: 'Start Free Trial',
                startNowBtn: 'Continue Free Trial',
                callUsBtn: 'Call Us',
                currentPlanBtn: 'Current Plan'
            },
            popular: 'Popular',
            currentPlan: 'Current Plan',
            plans: {
                'contact_center': {
                    'auto-dialer-monthly': {
                        subtitle: 'Unlimited Single Line <br>Dialer',
                        info: [ 'and so much more!' ],
                        popular: true
                    },
                    'back-office-monthly': {
                        subtitle: 'Grow Your Business <br>On Demand Features Available',
                        info: [ 'and so much more!' ],
                        popular: false
                    },
                    'front-office-monthly': {
                        subtitle: 'Unlimited<br>Outbound Communication',
                        info: [ 'and so much more!' ],
                        popular: false
                    },
                    'predictive-dialer-monthly': {
                        subtitle: 'Unlimited<br>Triple Line Dialer',
                        info: [ 'and so much more!' ],
                        popular: false
                    }
                },
                'ringless_voicemail': {
                    'usage-plan-elite-monthly': {
                        subtitle: 'Ringless Voicemail & <br>Bulk SMS',
                        info: [
                            'Gives You',
                            ' Messages',
                            'Per Month',
                            '*'
                        ],
                        warningMessage: 'funds are added to your account balance for use across all usage based platform features.',
                        popular: false
                    },
                    'usage-plan-enterprise-monthly': {
                        subtitle: 'Ringless Voicemail & <br>Bulk SMS',
                        info: [
                            'Gives You',
                            ' Messages',
                            'Per Month',
                            '*'
                        ],
                        warningMessage: 'funds are added to your account balance for use across all usage based platform features.',
                        popular: false
                    },
                    'usage-plan-extralarge-monthly': {
                        subtitle: 'Ringless Voicemail & <br>Bulk SMS',
                        info: [
                            'Gives You',
                            ' Messages',
                            'Per Month',
                            '*'
                        ],
                        warningMessage: 'funds are added to your account balance for use across all usage based platform features.',
                        popular: false
                    },
                    'usage-plan-jumbo-monthly': {
                        subtitle: 'Ringless Voicemail & <br>Bulk SMS',
                        info: [
                            'Gives You',
                            ' Messages',
                            'Per Month',
                            '*'
                        ],
                        warningMessage: 'funds are added to your account balance for use across all usage based platform features.',
                        popular: false
                    },
                    'usage-plan-large-monthly': {
                        subtitle: 'Ringless Voicemail & <br>Bulk SMS',
                        info: [
                            'Gives You',
                            ' Messages',
                            'Per Month',
                            '*'
                        ],
                        warningMessage: 'funds are added to your account balance for use across all usage based platform features.',
                        popular: false
                    },
                    'usage-plan-paygo-monthly': {
                        subtitle: 'Ringless Voicemail & <br>Bulk SMS',
                        info: [
                            'Gives You',
                            ' Messages',
                            'Per Month',
                            '*'
                        ],
                        warningMessage: 'funds are added to your account balance for use across all usage based platform features.',
                        popular: false
                    },
                    'usage-plan-prime-monthly': {
                        subtitle: 'Ringless Voicemail & <br>Bulk SMS',
                        info: [
                            'Gives You',
                            ' Messages',
                            'Per Month',
                            '*'
                        ],
                        warningMessage: 'funds are added to your account balance for use across all usage based platform features.',
                        popular: false
                    },
                    'usage-plan-ultra-monthly': {
                        subtitle: 'Ringless Voicemail & <br>Bulk SMS',
                        info: [
                            'Gives You',
                            ' Messages',
                            'Per Month',
                            '*'
                        ],
                        warningMessage: 'funds are added to your account balance for use across all usage based platform features.',
                        popular: false
                    },
                    'usage-plan-wholesale-monthly': {
                        subtitle: 'Ringless Voicemail & <br>Bulk SMS',
                        info: [
                            'Gives You',
                            'Custom Amount',
                            'Per Month',
                            '*'
                        ],
                        warningMessage: 'funds are added to your account balance for use across all usage based platform features.',
                        popular: false
                    }
                },
                'call_routing': {
                    'usage-plan-elite-monthly': {
                        subtitle: 'Call Tracking & <br>Routing',
                        info: [
                            'Gives You',
                            ' Minutes',
                            'Per Month',
                            '*'
                        ],
                        warningMessage: 'funds are added to your account balance for use across all usage based platform features.',
                        popular: false
                    },
                    'usage-plan-enterprise-monthly': {
                        subtitle: 'Call Tracking & <br>Routing',
                        info: [
                            'Gives You',
                            ' Minutes',
                            'Per Month',
                            '*'
                        ],
                        warningMessage: 'funds are added to your account balance for use across all usage based platform features.',
                        popular: false
                    },
                    'usage-plan-extralarge-monthly': {
                        subtitle: 'Call Tracking & <br>Routing',
                        info: [
                            'Gives You',
                            ' Minutes',
                            'Per Month',
                            '*'
                        ],
                        warningMessage: 'funds are added to your account balance for use across all usage based platform features.',
                        popular: false
                    },
                    'usage-plan-jumbo-monthly': {
                        subtitle: 'Call Tracking & <br>Routing',
                        info: [
                            'Gives You',
                            ' Minutes',
                            'Per Month',
                            '*'
                        ],
                        warningMessage: 'funds are added to your account balance for use across all usage based platform features.',
                        popular: false
                    },
                    'usage-plan-large-monthly': {
                        subtitle: 'Call Tracking & <br>Routing',
                        info: [
                            'Gives You',
                            ' Minutes',
                            'Per Month',
                            '*'
                        ],
                        warningMessage: 'funds are added to your account balance for use across all usage based platform features.',
                        popular: false
                    },
                    'usage-plan-paygo-monthly': {
                        subtitle: 'Call Tracking & <br>Routing',
                        info: [
                            'Gives You',
                            ' Minutes',
                            'Per Month',
                            '*'
                        ],
                        warningMessage: 'funds are added to your account balance for use across all usage based platform features.',
                        popular: false
                    },
                    'usage-plan-prime-monthly': {
                        subtitle: 'Call Tracking & <br>Routing',
                        info: [
                            'Gives You',
                            ' Minutes',
                            'Per Month',
                            '*'
                        ],
                        warningMessage: 'funds are added to your account balance for use across all usage based platform features.',
                        popular: false
                    },
                    'usage-plan-ultra-monthly': {
                        subtitle: 'Call Tracking & <br>Routing',
                        info: [
                            'Gives You',
                            ' Minutes',
                            'Per Month',
                            '*'
                        ],
                        warningMessage: 'funds are added to your account balance for use across all usage based platform features.',
                        popular: false
                    },
                    'usage-plan-wholesale-monthly': {
                        subtitle: 'Call Tracking & <br>Routing',
                        info: [
                            'Gives You',
                            'Custom Amount',
                            'Per Month',
                            '*'
                        ],
                        warningMessage: 'funds are added to your account balance for use across all usage based platform features.',
                        popular: false
                    }
                },
                'call_recording': {
                    'usage-plan-elite-monthly': {
                        subtitle: 'Call Recording & <br>Transcription',
                        info: [
                            'Gives You',
                            ' Minutes',
                            'Per Month',
                            '*'
                        ],
                        subscribeBtn: 'Subscribe',
                        start7DaysBtn: 'Start Free Trial',
                        warningMessage: 'funds are added to your account balance for use across all usage based platform features.',
                        popular: false
                    },
                    'usage-plan-enterprise-monthly': {
                        subtitle: 'Call Recording & <br>Transcription',
                        info: [
                            'Gives You',
                            ' Minutes',
                            'Per Month',
                            '*'
                        ],
                        subscribeBtn: 'Subscribe',
                        start7DaysBtn: 'Start Free Trial',
                        warningMessage: 'funds are added to your account balance for use across all usage based platform features.',
                        popular: false
                    },
                    'usage-plan-extralarge-monthly': {
                        subtitle: 'Call Recording & <br>Transcription',
                        info: [
                            'Gives You',
                            ' Minutes',
                            'Per Month',
                            '*'
                        ],
                        subscribeBtn: 'Subscribe',
                        start7DaysBtn: 'Start Free Trial',
                        warningMessage: 'funds are added to your account balance for use across all usage based platform features.',
                        popular: false
                    },
                    'usage-plan-jumbo-monthly': {
                        subtitle: 'Call Recording & <br>Transcription',
                        info: [
                            'Gives You',
                            ' Minutes',
                            'Per Month',
                            '*'
                        ],
                        subscribeBtn: 'Subscribe',
                        start7DaysBtn: 'Start Free Trial',
                        warningMessage: 'funds are added to your account balance for use across all usage based platform features.',
                        popular: false
                    },
                    'usage-plan-large-monthly': {
                        subtitle: 'Call Recording & <br>Transcription',
                        info: [
                            'Gives You',
                            ' Minutes',
                            'Per Month',
                            '*'
                        ],
                        subscribeBtn: 'Subscribe',
                        start7DaysBtn: 'Start Free Trial',
                        warningMessage: 'funds are added to your account balance for use across all usage based platform features.',
                        popular: false
                    },
                    'usage-plan-paygo-monthly': {
                        subtitle: 'Call Recording & <br>Transcription',
                        info: [
                            'Gives You',
                            ' Minutes',
                            'Per Month',
                            '*'
                        ],
                        subscribeBtn: 'Buy Now',
                        start7DaysBtn: 'Start Free Trial',
                        warningMessage: 'funds are added to your account balance for use across all usage based platform features.',
                        popular: false
                    },
                    'usage-plan-prime-monthly': {
                        subtitle: 'Call Recording & <br>Transcription',
                        info: [
                            'Gives You',
                            ' Minutes',
                            'Per Month',
                            '*'
                        ],
                        subscribeBtn: 'Subscribe',
                        start7DaysBtn: 'Start Free Trial',
                        warningMessage: 'funds are added to your account balance for use across all usage based platform features.',
                        popular: false
                    },
                    'usage-plan-ultra-monthly': {
                        subtitle: 'Call Recording & <br>Transcription',
                        info: [
                            'Gives You',
                            ' Minutes',
                            'Per Month',
                            '*'
                        ],
                        subscribeBtn: 'Subscribe',
                        start7DaysBtn: 'Start Free Trial',
                        warningMessage: 'funds are added to your account balance for use across all usage based platform features.',
                        popular: false
                    },
                    'usage-plan-wholesale-monthly': {
                        subtitle: 'Call Recording & <br>Transcription',
                        info: [
                            'Gives You',
                            'Custom Amount',
                            'Per Month',
                            '*'
                        ],
                        subscribeBtn: 'Call Us',
                        start7DaysBtn: 'Start Free Trial',
                        warningMessage: 'funds are added to your account balance for use across all usage based platform features.',
                        popular: false
                    }
                }
            },
            footerText: ' Plus applicable taxes. SMS campaigns require registration with The Campaign Registry. Compliance fees of $0.0031 per bulk RVM or SMS message apply. Mimic AI™ voice cloned text to speech costs $0.005 per 100 characters in addition to your plan\'s bulk messaging rates. Contacts responding back to RVM incur a connected call fee of $0.15 per call. Toll-Free calls are not inclusive to your plan and are billed $0.08 per minute.  Local calls exceeding your plan\'s aggregate talk time limit are billed at $0.05 per minute. Call recording is billed at $0.04 per minute.  RVM and SMS overages are billed at your plan\'s per message rate.  Account owners must complete know your customer verification.',
            processing: 'Processing'
        };

        $scope.data = {
            user: null,
            userPlan: '',
            teamPlan: '',
            pricing: PRODUCT_PRICING,
            team_chargebee_plan: '',
            userBalance: {},

            plans: {
                'monthly-plan': {},
                'annual-plan': {}
            },

            mode: 'default', //'default', 'usage-plans'
            isTabsRendered: false,
            membershipSwitch: false,
            useTrial: false,
            currentTab: null,
            initActiveTabTemp: null,
            submit: false,
            showModalOnInit: true,
            downgrade_plan_id: null
        };

        $scope.downgradeResult = function() {
            $('#upsell-modal').modal('hide');
            $scope.data.submit = false;
            $scope.$apply();

            $state.go('main-wrapper.reloadSubscription');
        };

        $scope.setPlanSuccessResult = function() {
            $('#upsell-modal').modal('hide');
            $scope.data.submit = false;
            $scope.$apply();

            if($location.path() !== '/subscriptions') {
                swal({
                    title: 'Apply Changes?',
                    text: "You have pending subscription changes. Continue making changes or proceed to checkout?",
                    buttons: ['Keep Editing', 'Checkout'],
                    dangerMode: true,
                    closeOnClickOutside: false
                }).then((confirm) => {
                    if (!confirm) { return; }

                    $state.go('main-wrapper.reloadSubscription');
                });
            } else { $state.go('main-wrapper.reloadSubscription'); }
        };

        $scope.setPlanErrorResult = function(error, errorAlert) {
            swal(errorAlert.title, errorAlert.message, 'error');
            console.error(error);
            $scope.data.submit = false;
            $scope.$apply();
        };

        $scope.start7DayTrial = function(plan) {
            $scope.data.submit = true;

            let isUsagePlan = (plan.id || '').startsWith('usage-plan-');
            if (isUsagePlan) {
                $scope.setTeamPlan(plan, true);
            }
            else {
                $scope.setUserPlan(plan, true);
            }
        };

        $scope.setUserPlan = function(plan, trial) {
            // Set the pending plan change on the user
            let req = {
                user_id: authService.getUserId(),
                team_id: authService.getTeamId(),
                updated_user_id: authService.getUserId(),
                pending_addon_id: plan.id
            };

            userService.updateUser(req)
                .then(user => { $scope.setPlanSuccessResult(); })
                .catch(err => {
                    $scope.setPlanErrorResult(err, { title: 'Server Error', message: 'We were unable to update the user. Try again later.'});
                });
        };

        $scope.setTeamPlan = function(plan, trial) {
            // Set the pending plan change on the user

            let usagePlan = $rootScope.getUsageAddon(plan.id) || {};
            let usagePlanCost = usagePlan.unit_cost || 0;

            let currentPlan = $rootScope.getUsageAddon($rootScope.getCurrentUsageAddOnId()) || {};
            let currentPlanCost = currentPlan.unit_cost || 0;

            // If downgrading...
            if (usagePlanCost < currentPlanCost) {
                $scope.data.downgrade_plan_id = plan.id;

                swal({
                    title: 'Please Confirm',
                    text: 'Are you sure you want to downgrade your usage plan?  This change will become effective immediately.',
                    buttons: ['Keep Current Plan', 'Yes, Downgrade'],
                    icon: 'success',
                    closeOnClickOutside: false
                }).then((confirm) => {
                    if (!confirm) {
                        $scope.data.submit = false;
                        $scope.$apply();
                        return;
                    }

                    let req = {
                        user_id: authService.getUserId(),
                        team_id: authService.getTeamId(),
                        usage_addon_id: plan.id
                    };

                    accountService.changeUsagePlan(req)
                       .then(results => {
                           $scope.downgradeResult();
                       })
                       .catch(err => {
                           $scope.setPlanErrorResult(err, { title: 'Server Error', message: 'We were unable to update your account. Try again later.'});
                       });
                });
            }
            else {

                let req = {
                    user_id: authService.getUserId(),
                    team_id: authService.getTeamId(),
                    updated_team_id: authService.getTeamId(),
                    pending_usage_id: plan.id
                };

                if (trial) {
                    req.start_trial = true;
                }

                accountService.updateTeam(req)
                   .then(team => { $scope.setPlanSuccessResult(); })
                   .catch(err => {
                       $scope.setPlanErrorResult(err, { title: 'Server Error', message: 'We were unable to update the team. Try again later.'});
                   });
            }
        };

        $scope.scrollTopModal = function() {
            $('#upsell-modal').stop();
            $('#upsell-modal').animate({scrollTop: 0}, 350);
        };

        $scope.startNow = function() {
            $('#upsell-modal').modal('hide');
            swal({
                title: 'Congratulations!',
                text: 'Want to invite additional team members?',
                buttons: ['Continue Free Trial', 'Invite More Users'],
                icon: 'success',
                closeOnClickOutside: false
            }).then((confirm) => {
                if (!confirm) { return; }

                $location.path('/team');
            });
        };

        $scope.callUs = function() {
            window.Intercom('show');
        };

        $scope.subscribePlan = function(plan) {
            if (!$scope.data.user.owner && !$scope.data.user.role.permissions.payments.can_edit) {
                swal({
                    title: 'Not enough permissions',
                    text: "You don't have permission to make subscription changes. Please contact your manager or the account owner.",
                    button: 'OK'
                });
                return;
            }

            if(plan.id === 'usage-plan-wholesale-monthly') {
                $scope.callUs();
                return;
            }

            $scope.data.submit = true;
            $scope.scrollTopModal();

            var functionName = ($scope.data.currentTab === 'contact_center' && $scope.data.mode === 'default') ? 'setUserPlan' : 'setTeamPlan';
            $scope[functionName](plan);
        };

        $scope.calcMessagesOrMinutesPerMonth = function(plan, ) {
            var unitCost = (plan.unit_cost > 0) ? plan.unit_cost : plan.one_time_cost;

            switch($scope.data.currentTab) {
                case 'ringless_voicemail': return (unitCost/(plan.rates.rvm.ea + plan.rates.compliance_fee.ea)).toFixed(0);
                case 'call_routing': return (unitCost/plan.rates.voice.ea).toFixed(0);
                case 'call_recording': return (unitCost/plan.rates.recording.ea).toFixed(0);
            }
        };

        $scope.changeTab = function(tabKey) {
            $scope.data.currentTab = tabKey;
        };

        $scope.initUpsellModalTabs = function() {
            $scope.$watch('data.currentTab', function(newVal, oldVal) {
                if(newVal !== null) {
                    $('#' + newVal + '-tab').tab('show');

                    $('#' + newVal + '-tab').on('shown.bs.tab', function () {
                        $('#upsell-modal').modal('handleUpdate');
                    });
                }
            }, true);
        };

        $scope.toggleMembership = function() {
            $scope.data.team_chargebee_plan = $scope.data.membershipSwitch ? 'annual-plan' : 'monthly-plan';
        };

        $scope.initMembershipSwitch = function() {
            $scope.data.membershipSwitch = $scope.data.team_chargebee_plan.includes('annual');
        };

        $scope.getUserInfo = function() {
            accountService.getTeam({
                team_id: authService.getTeamId(),
                user_id: authService.getUserId()
            })
                .then(team => {
                    $scope.data.user = authService.getUser();
                    $scope.data.useTrial = $rootScope.isFreeTrialUser;
                    $scope.data.team_chargebee_plan = $scope.data.user.team && $scope.data.user.team.chargebee && $scope.data.user.team.chargebee.plan_id || 'monthly-plan';
                    $scope.data.userPlan = $rootScope.getUserActivePlan();
                    $scope.data.teamPlan = team.chargebee.usage_addon_id;
                })
        };

        $scope.getDefaultTab = function() {
            return ($scope.data.mode === 'usage-plans') ? 'ringless_voicemail' : 'contact_center';
        };

        $scope.setInitActiveTab = function(activeTab) {
            $scope.changeTab($scope.staticData.tabs[activeTab] ? activeTab : $scope.getDefaultTab());
        };

        $scope.$on('finishRenderTabs', function() {
            $scope.setInitActiveTab($scope.data.initActiveTabTemp);
            $scope.data.isTabsRendered = true;
        });

        $scope.showModal = function(modalMode, activeTab, apply) {
            $scope.data.mode = (modalMode === 'usage-plans') ? modalMode : 'default';
            if($scope.data.isTabsRendered) {
                $scope.setInitActiveTab(activeTab);
            } else { $scope.data.initActiveTabTemp = activeTab; }

            $scope.getUserInfo();
            $scope.initMembershipSwitch();
            if(apply) { $scope.$apply(); }

            $('#upsell-modal').modal('show');
        };

        $scope.$on('showUpsellModal', function (event, modalMode, activeTab, apply) {
            $scope.showModal(modalMode, activeTab, apply);
        });

        $scope.resetModal = function() {
            $scope.data.user = null;
            $scope.data.userPlan = '';
            $scope.data.teamPlan = '';
            $scope.data.team_chargebee_plan = '';
            $scope.data.mode = 'default';
            $scope.data.membershipSwitch = false;
            $scope.data.useTrial = false;
            $scope.data.currentTab = null;
            $scope.data.initActiveTabTemp = null;
            $scope.data.submit = false;
            $scope.data.showModalOnInit = false;
        };

        $scope.setUsageBasedServicesPlans = function(period) {
            var packages = $scope.data.pricing[period].packages;
            var plans = [];
            plans.push(
                packages['usage-plan-elite-monthly'],
                packages['usage-plan-enterprise-monthly'],
                packages['usage-plan-extralarge-monthly'],
                packages['usage-plan-jumbo-monthly'],
                packages['usage-plan-large-monthly'],
                packages['usage-plan-paygo-monthly'],
                packages['usage-plan-prime-monthly'],
                packages['usage-plan-ultra-monthly'],
                packages['usage-plan-wholesale-monthly']
            );
            return plans;
        };

        $scope.setOutboundCommunicationsPlans = function(period) {
            var packages = $scope.data.pricing[period].packages;
            var plans = [];
            plans.push(
                packages['auto-dialer-monthly'],
                packages['back-office-monthly'],
                packages['front-office-monthly'],
                packages['predictive-dialer-monthly']
            );
            return plans;
        };

        $scope.initPlans = function() {
            $scope.data.plans['monthly-plan'].contact_center = $scope.setOutboundCommunicationsPlans('monthly-plan');
            $scope.data.plans['monthly-plan'].ringless_voicemail = $scope.setUsageBasedServicesPlans('monthly-plan');
            $scope.data.plans['monthly-plan'].call_routing = $scope.setUsageBasedServicesPlans('monthly-plan');
            $scope.data.plans['monthly-plan'].call_recording = $scope.setUsageBasedServicesPlans('monthly-plan');

            $scope.data.plans['annual-plan'].contact_center = $scope.setOutboundCommunicationsPlans('annual-plan');
            $scope.data.plans['annual-plan'].ringless_voicemail = $scope.setUsageBasedServicesPlans('annual-plan');
            $scope.data.plans['annual-plan'].call_routing = $scope.setUsageBasedServicesPlans('annual-plan');
            $scope.data.plans['annual-plan'].call_recording = $scope.setUsageBasedServicesPlans('annual-plan');
        };

        $scope.checkCurrentPage = function(currentUrl) {

            if(authService.isAuthenticated()) {
                switch(currentUrl) {
                    case '/checklist':
                        if ($window.localStorage['first_signin']) {
                            // This feels a bit aggressive.  We can comment this back in if we need it to display during the welcome process.
                            //$scope.showModal('default', 'contact_center', false);
                            $window.localStorage.removeItem('first_signin');
                        }
                        break;
                    // case '/bulk-campaigns':
                    //     if (!$rootScope.isBalance()) {
                    //         $rootScope.showUpsellModalNoBalanceSwal('default', 'ringless_voicemail');
                    //     }
                    //     break;
                    // case '/dash':
                    //     if (!$rootScope.isSubscribedOutboundCallingPlan()) {
                    //         $rootScope.showUpsellModalFeatureRequiresSubscriptionSwal('default', 'contact_center');
                    //     }
                    //     break;
                    // case '/tracking':
                    //     if (!$rootScope.isBalance()) {
                    //         $rootScope.showUpsellModalNoBalanceSwal('default', 'call_routing');
                    //     }
                    //     break;
                }
            }
        };

        $scope.showModalOnLoaded = function() {
            if(!_.isEmpty($scope.data.userBalance)) {
                $scope.checkCurrentPage($location.path());
            } else {
                $scope.$on('balance_change', function () {
                    $scope.data.userBalance = $rootScope.balance;

                    if($scope.data.showModalOnInit) {
                        $scope.checkCurrentPage($location.path());
                        $scope.data.showModalOnInit = false;
                    }
                });
            }
        };

        $rootScope.$on('$stateChangeStart', function(e, toState, toParams, fromState, fromParams) {
            $scope.checkCurrentPage(toState.url);
        });

        $scope.onInit = function() {
            $('#upsell-modal').on('shown.bs.modal', function () {
                if ($window.localStorage['first_signin']) {
                    $window.localStorage.removeItem('first_signin');
                }
            });
            $('#upsell-modal').on('hidden.bs.modal', function () {
                $scope.resetModal();
            });

            Promise.all([
                accountService.getTeam({
                    team_id: authService.getTeamId(),
                    user_id: authService.getUserId()
                }),
                $scope.initPlans()
            ]).then(([team]) => {
                $rootScope.isFreeTrial();
                $scope.data.userBalance = $rootScope.balance;
                $scope.$apply();

                $scope.showModalOnLoaded();
            });
        };

        if (authService.hasAccount()) {
            $scope.onInit();
        }
        else {
            $scope.$on('auth_complete', function(event, args) {
                $scope.onInit();
            });
        }
    }

    module.exports = UpsellModalController;
})();
