(function() {
    'use strict';

    function AutomationTypePlaceholderListItemController(authService) {
        var vm = this;
        vm.authService = authService;

    }
    module.exports = AutomationTypePlaceholderListItemController;
})();
