(function () {

    'use strict';

    function IntegrationsController($scope, integrationService, $rootScope) {

        var vm = this;

        $rootScope.viewData = $rootScope.viewData || {};
        $rootScope.viewData.breadcrumbs = $rootScope.viewData.breadcrumbs || [];
        $rootScope.viewData.breadcrumbs = [{
            title: 'Integrations',
            link_name: 'Integrations',
            link_url: '#/integrations',
            help_url: $rootScope.help_url
        }];
    
        $scope.staticContent = {
            leftArea: {
                title: "Integrations",
                text: "Send ringless voicemail and text messages directly from the apps you use most",
                integrations: [
                    {
                        id: "zapier",
                        title: "Zapier",
                        desc: "Integrate ringless voicemail and text messaging into more than 1500 applications including your CRM, marketing automation tools, lead capture forms, and anything else you can think of via Zapier.  Click the 'Connect via Zapier' button below to get started.",
                        btn: {
                            title: "Connect via Zapier",
                            href: "https://zapier.com/apps/drop-cowboy/integrations"
                        },
                        learnBtn: {
                            title: "Learn More",
                            href: "https://www.dropcowboy.com/integrations/zapier-ringless-voicemail"
                        }
                    },
                    {
                        id: "shopify",
                        title: "Shopify",
                        desc: "Seamlessly integrate ringless voicemail and text messaging into your Shopify store to enhance customer engagement, recover abandoned carts, and streamline order notifications. Connect effortlessly through Shopify and automate your outreach.",
                        instruction: "Click the 'Install on Shopify' button below to get started.",
                        installBtn: {
                            title: "Install on Shopify",
                            href: "https://apps.shopify.com/drop-cowboy"
                        },
                        deactivateBtn: {
                            title: "Deactivate Shopify Integration",
                            href: "https://apps.shopify.com/drop-cowboy"
                        },
                        complianceBtn: {
                            title: "How to Get Compliant"
                        }
                    },
                    // {
                    //     id: "dropCowboy",
                    //     title: "Drop Cowboy",
                    //     desc: `Drop Cowboy provides the ability to send ringless voicemail to your contacts in bulk. Send individual voicemails directly from within ${$rootScope.settings.APP_NAME} or synchronize your contact lists to send voicemails in bulk from within Drop Cowboy.`,
                    //     btn: {
                    //         title: "Connect to Drop Cowboy",
                    //         href: "https://zapier.com/apps/drop-cowboy/integrations"
                    //     },
                    //     learnBtn: {
                    //         title: "Learn More",
                    //         href: "https://help.callcowboy.com"
                    //     },
                    //     disconnect_btn: {
                    //         title: "Disconnect Drop Cowboy",
                    //     },
                    // },
                    // disabled for now
                    // {
                    //     id: "email",
                    //     title: "Email",
                    //     desc: `Send emails to your contacts directly from within ${$rootScope.settings.APP_NAME}. All you need are SMPT credentials to your favorite email marketing platform such as Send Grid, Mail Chimp, Mail Gun and many more.`,
                    //     btn: {
                    //         title: "Connect SMTP",
                    //         href: "https://zapier.com/apps/drop-cowboy/integrations"
                    //     },
                    //     learnBtn: {
                    //         title: "Learn More",
                    //         href: "https://help.callcowboy.com"
                    //     },
                    //     disconnect_btn: {
                    //         title: "Disconnect SMTP",
                    //     },
                    // }
                ]
            },
            rightArea: {
                title: "All Integrations",
                block_1: {
                    title: "CRMs",
                    list: [
                        {
                            title: "Hubspot",
                            href: "javascript:void(0)"
                        },
                        {
                            title: "Pipedrive",
                            href: "javascript:void(0)"
                        }
                    ]
                },
                block_2: {
                    title: "Call Tracking",
                    list: [
                        {
                            title: $rootScope.settings.APP_NAME,
                            href: "javascript:void(0)"
                        }
                    ]
                }
            }
        };

        $scope.data = {
            integrations: {},
            showFuture: false
        };

        $scope.loading = {
            integrations: false
        };

        $scope.openComplianceInstructions = () => {
            $rootScope.showUpdateShopifyPolicyModal();
        };

        $scope.deactiveIntegration = (integrationType) => {
            switch (integrationType) {
                case ('shopify'):
                    integrationService.deactivateShopify()
                        .then(res => {
                            $scope.getIntegrations();
                        });
                    break;
            }
        };

        $scope.getIntegrations = () => {
            $scope.loading.integrations = true;
            integrationService.list()
                .then(res => {
                    integrationService.set(res);
                    $scope.data.integrations = integrationService.get();

                    $scope.loading.integrations = false;

                    $scope.$apply();
                });
        };

        $scope.onInit = () => {
            $scope.getIntegrations();

            $('#connectToSmtp').on('hide.bs.modal', function (e) {
                $scope.getIntegrations();
            });

            // $rootScope.$on('dropCowboyConnected', function (e) {
            //     $scope.getIntegrations();
            // });
        };

        $scope.onInit();

        $scope.disconnectIntegration = (type) => {
            const req = {
                integration_type: type,
            };
            integrationService.disconnect(req)
                .then(() => {
                    $scope.getIntegrations();
                });
        };
    }
    module.exports = IntegrationsController;
})();
