(function () {
    'use strict';

    function CustomFieldsController(authService, $scope, $rootScope, contactService, $window) {

        $rootScope.viewData = $rootScope.viewData || {};
        $rootScope.viewData.breadcrumbs = $rootScope.viewData.breadcrumbs || [];
        $rootScope.viewData.breadcrumbs = [{
            title: 'Custom Fields',
            link_name: 'Custom Fields',
            link_url: '#/custom-fields',
            help_url: $rootScope.help_url
        }];

        $scope.staticData = {
            title: 'Custom Fields',
            subtitle: 'Define custom fields of data, unique to your business, that you want to collect and store in your contact data...',
            addBtnTitle: 'Add Custom Field',
            saveBtnTitle: 'Save Changes',
            addItemBtnTitle: 'Add Item',
            titles: ['Field Name', 'Field Type'],
            actions: ['Delete Field'],
            dropdownActions: [
                'Add Field Above',
                'Add Field Below',
                'Move Field Up',
                'Move Field Down',
                'Delete Field'
            ],
            noRes: 'No Results',
            loading: 'Loading',
            items: {
                labelTitle: 'Label',
                valueTitle: 'Mapped Value',
                dropdownActions: [
                    'Add Item Above',
                    'Add Item Below',
                    'Move Item Up',
                    'Move Item Down',
                    'Delete Item'
                ]
            }
        };

        $scope.loading = {
            customFields: true
        };

        $scope.data = {
            customFields: [],
            isChanged: false,
        };

        $scope.AVAILABLE_TYPES = {
            string: 'string',
            text: 'text',
            list: 'list'
        };

        $scope.initialState = {
            customFields: []
        };

        $scope.totalItems = 0;
        $scope.currentPage = 1;
        $scope.itemsPerPage = 999;
        $scope.maxSize = 3;

        $scope.changeType = (newType, customField) => {
            customField.type = newType;
            switch (newType) {
                case $scope.AVAILABLE_TYPES.string:
                case $scope.AVAILABLE_TYPES.text:
                    delete customField.list_items;
                    break;
                case $scope.AVAILABLE_TYPES.list:
                    customField.list_items = [];
                    break;
                default:
                    throw new Error('Unexpected type of custom field');
            }
        };

        $scope.getFieldTypes = function () {
            return Object.keys($scope.AVAILABLE_TYPES).map(typeKey => $scope.AVAILABLE_TYPES[typeKey]);
        };

        $scope.getFieldType = function (fieldType) {
            return Object.keys($scope.AVAILABLE_TYPES).find(typeKey => $scope.AVAILABLE_TYPES[typeKey] === fieldType);
        };

        $scope.getCustomFieldItems = function (customField) {
            return customField.list_items.filter(item => !item.deleted_at);
        };

        $scope.resortFields = () => {
            $scope.data.customFields = _.sortBy($scope.data.customFields, 'sort_index');
            $scope.data.customFields.forEach((customField, index) => {
                customField.sort_index = index;
            });
        };

        $scope.loadCustomFields = function () {
            $scope.loading.customFields = true;
            $window.scrollTo(0, 0);

            const request = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                skip: ($scope.currentPage - 1) * $scope.itemsPerPage,
                limit: $scope.itemsPerPage,
                get_total: true
            };

            contactService.getCustomFields(request)
                .then(results => {
                    $scope.data.customFields = angular.copy(results.custom_fields);
                    $scope.initialState.customFields = angular.copy(results.custom_fields);
                    $scope.resortFields();
                    $scope.totalItems = results.total_custom_fields;
                    $scope.$emit('team_custom_fields_changed', $scope.data.customFields);
                })
                .catch(err => {
                    console.error(err);
                })
                .then(() => {
                    $scope.loading.customFields = false;
                    $scope.$apply();
                });
        };

        $scope._toggleFieldOptions = function (detailsBlock, item, showDetails) {
            let detailsBlockContent = detailsBlock.children('.details-content');

            const observer = new MutationObserver((nodes) => {
                for (const node of nodes) {
                    if (node.type === 'childList' && detailsBlock.outerHeight(true) !== detailsBlockContent.outerHeight(true)) {
                        detailsBlock.css('overflow', 'hidden');
                        detailsBlock.height(item.showFieldOptions ? detailsBlockContent.outerHeight(true) : 0);
                    }
                }
            });
            observer.observe(detailsBlockContent[0], { childList: true });

            if(item.showFieldOptions) {
                detailsBlock.css('overflow', 'hidden');
                detailsBlock.height(detailsBlockContent.outerHeight(true));
            }

            detailsBlock.on('transitionend', function (event) {
                if(event.target === this) {
                    if(!item.showFieldOptions) { observer.disconnect(); }

                    detailsBlock.css('overflow', (item.showFieldOptions) ? 'unset' : 'hidden');
                }
            });

            detailsBlock.height(!item.showFieldOptions ? detailsBlockContent.outerHeight(true) : 0);
            item.showFieldOptions = (showDetails !== null) ? showDetails : !item.showFieldOptions;
        };

        $scope.toggleFieldOptions = function (showDetails, item, event) {
            let field = $(event.target).closest('.custom-fields-list-item');
            let detailsBlock = field.children('.details-info');

            $scope._toggleFieldOptions(detailsBlock, item, showDetails);
        };

        $scope.resortItems = (customField) => {
            customField.list_items = _.sortBy(customField.list_items, 'sort_index');
            return customField;
        };

        $scope.moveItemUp = function (customField, listItem) {
            let index = _.findIndex(customField.list_items, function(item) { return item.sort_index === listItem.sort_index; });
            let prevItem = customField.list_items[index - 1];
            let sortIndex = listItem.sort_index;
            let prevSortIndex = prevItem.sort_index;

            listItem.sort_index = prevSortIndex;
            prevItem.sort_index = sortIndex;

            $scope.resortItems(customField);
        };

        $scope.moveItemDown = function (customField, listItem) {
            let index = _.findIndex(customField.list_items, function(item) { return item.sort_index === listItem.sort_index; });
            let nextItem = customField.list_items[index + 1];
            let sortIndex = listItem.sort_index;
            let nextSortIndex = nextItem.sort_index;

            listItem.sort_index = nextSortIndex;
            nextItem.sort_index = sortIndex;

            $scope.resortItems(customField);
        };

        $scope.deleteItem = function (customField, currentListItem) {
            let removeFromPosition = _.findIndex(customField.list_items, function(item) { return item.sort_index === currentListItem.sort_index; });
            let itemToDelete = customField.list_items[removeFromPosition];

            if (!itemToDelete.item_id) {
                customField.list_items.splice(removeFromPosition, 1);
            } else {
                itemToDelete.deleted_at = (new Date()).getTime() * 1000;
                itemToDelete.deleted_by = authService.getUserId();
                itemToDelete.sort_index = -1;
            }

            customField.list_items.slice(removeFromPosition + 1).map(item => {
                item.sort_index--;
                return item;
            });
            $scope.resortItems(customField);

            if(customField.list_items.length === 0 && customField.showFieldOptions) {
                $scope.toggleFieldOptions(false, customField, event);
            }
        };

        $scope.addItem = function(event, customField, currentListItem, addToPosition) {
            let currentFieldMaxSortValue = (customField.list_items.length) ? Math.max(...customField.list_items.map(item => item.sort_index)) : 0;
            let currentListItemSortValue = currentListItem ? currentListItem.sort_index : 0;
            let currentFieldSortValue = (typeof addToPosition !== 'undefined') ? currentListItemSortValue : currentFieldMaxSortValue;
            let currentFieldSortIndex = _.findIndex(customField.list_items, function(item) { return item.sort_index === currentFieldSortValue; });

            let index = (addToPosition === 'above') ? currentFieldSortIndex : currentFieldSortIndex + 1;
            let sortValue = (addToPosition === 'above') ? currentFieldSortValue : currentFieldSortValue + 1;

            const newItem = {
                display_name: 'List Item',
                value: null,
                sort_index: sortValue,
                delim: ','
            };

            customField.list_items.slice(index).map(item => {
                item.sort_index++;
                return item;
            });

            customField.list_items.push(newItem);
            $scope.resortItems(customField);

            if(!customField.showFieldOptions) {
                $scope.toggleFieldOptions(true, customField, event);
            }
        };

        $scope.moveFieldDown = function (index, customField) {
            let nextField = $scope.data.customFields[index + 1];
            customField.sort_index = index + 1;
            nextField.sort_index = index;
            $scope.resortFields();
        };

        $scope.moveFieldUp = function (index, customField) {
            let prevField = $scope.data.customFields[index - 1];
            customField.sort_index = index - 1;
            prevField.sort_index = index;
            $scope.resortFields();
        };

        $scope.deleteCustomField = function (removeFromPosition, customField) {
            $scope.data.customFields.splice(removeFromPosition, 1);
            $scope.data.customFields.slice(removeFromPosition).map(field => {
                field.sort_index--;
                return field;
            });
        };

        $scope.createCustomField = function (addToPosition) {
            let index = 0;
            if (typeof addToPosition !== 'undefined') {
                index = addToPosition;
            }

            const newField = {
                team_id: authService.getTeamId(),
                type: $scope.AVAILABLE_TYPES.string,
                sort_index: index,
                display_name: 'Custom Field'
            };

            $scope.data.customFields.slice(index).map(field => {
                field.sort_index++;
                return field;
            });

            $scope.data.customFields.push(newField);
            $scope.resortFields();
        };

        $scope.saveChanges = function() {
            $scope.loading.customFields = true;

            const request = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                custom_fields: $scope.data.customFields
            };

            contactService.bulkUpdateCustomFields(request)
                .then(() => {
                    $scope.resortFields();
                    $scope.initialState.customFields = angular.copy($scope.data.customFields);
                    $scope.data.isChanged = false;
                    $scope.$emit('team_custom_fields_changed', $scope.data.customFields);
                })
                .catch(err => {
                    console.error(err);
                })
                .then(() => {
                    $scope.loading.customFields = false;
                    $scope.$apply();
                });
        }

        $scope.checkName = function(name) {
            if (name.length === 0) {
                return "Custom field name must have at least 1 character.";
            }
        };

        $scope.onInit = function () {
            $scope.loadCustomFields();
        };

        const isArrayEqual = function(x, y) {
            return _(x).xorWith(y, _.isEqual).isEmpty();
        };

        $scope.$watch('data.customFields', (newFieldsState) => {
            $scope.data.isChanged = !isArrayEqual(angular.copy(newFieldsState), $scope.initialState.customFields);
        }, true);

        if (authService.hasAccount()) {
            $scope.onInit();
        } else {
            $scope.$on('auth_complete', function (event, args) {
                $scope.onInit();
            });
        }

    }

    module.exports = CustomFieldsController;
})();
