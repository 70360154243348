(function () {
    'use strict';
    
    function ImportService(apiService, $rootScope) { 
        return {
            createImport: function (request) {
                return apiService.post($rootScope.settings.API_IMPORT_URL + '/import/create', request);
            },

            startImport: function (request) {
                return apiService.post($rootScope.settings.API_IMPORT_URL + '/import/start', request);
            },

            getImports: function (request) {
                return apiService.get($rootScope.settings.API_IMPORT_URL + '/import' , request);
            },

            getImport: function (request) {
                return apiService.get($rootScope.settings.API_IMPORT_URL + '/import/' + request.import_id, request);
            }
        };
    }
    module.exports = ImportService;
})();




