(function() {
    'use strict';

    function DashController($rootScope, $scope, $location, $interval, authService, phoneService, $window) {
        var vm = this;

        vm.authService = authService;

        $rootScope.viewData = $rootScope.viewData || {};
        $rootScope.viewData.breadcrumbs = $rootScope.viewData.breadcrumbs || [];
        $rootScope.viewData.breadcrumbs = [{
            title: 'Outbound Calling',
            link_name: 'Outbound Calling',
            link_url: '#!dash',
            help_url: $rootScope.help_url
        }];
    
        $scope.textDashboard = {
            statistic: ['Messages', 'Calls', 'Talk Time', 'Conversions'],
            callsChart: {
                noDataMessage: 'No Data',
                noSubMessage: 'No Advanced Tracking Subscription'
            },
            recentCalls: {
                title: 'Recent Calls',
                subtitle: 'Check out your recent calls',
                viewAll: {
                    title: 'View All',
                    href: '#/recent-calls?viewAll'
                },
                labels: {
                    isMissed: 'Missed Call',
                }
            },
            listStatistics: {
                title: 'Callable Lists',
                subtitle: 'Recent dialer activity by list',
                edit: {
                    title: 'Edit Lists'
                },
                dial: {
                    title: 'Dial All'
                },
                labels: [
                    'Calls:',
                    'Messages:',
                    'Talk Time:',
                    'Abandonment:',
                    'Abandonment: 0.00%',
                    'Last Call:',
                    'Last Message:',
                    'Conversions:',
                    'Contacts:',
                    'Next contact callable:',
                ]
            },
            callOutcomes: {
                title: 'Call Outcomes',
                subtitle: 'Outcomes of your contact calls',
                noDataMessage: 'No Data',
                noSubMessage: 'No Advanced Tracking Subscription'
            },
            recentConversions: {
                title: 'Recent Conversions',
                subtitle: 'Recently converted contacts',
                terms: [
                    'Today',
                    'Week',
                    'Month'
                ],
                labels: ['Agent:', 'Converted at:'],
            },
            loading: 'Loading'
        };

        $scope.placeholders = {
            callOutcomesChart: 'assets/images/graphs/calloutcomesgraph.svg',
        };

        $scope.charts = {};
        
        $scope.dataDashboard = {
            //hasAccess: $rootScope.isAdvancedTrackingPlan(authService.getUser().plan_id) || authService.getUser().team.trial_month,
            hasAccess: true,
            statistic: {},
            listStatistics: [],
            recentCalls: [],
            recentConversions: [],
            timeAnalysis: {
              data: {}
            },
            emptyGraph: {
                callOutcomesChart: undefined
            }
        };

        $scope.loading = {
            globalStatistics: true,
            recentCalls: true,
            listStatistics: true,
            callOutcomesChart: true,
            recentConversions: true,
        };

        $scope.itemsPerWidget = {
            calls: 10,
            conversions: 8
        };

        $scope.defaultAvatar = process.env.NO_AVATAR_IMG;

        $scope.enableDisableFullScreenView = function(action) {
            $('#dash-row-container')[action]('full-screen');
        };

        $scope.resizeDashContainer = function() {
            var action = (window.innerWidth < 1426 || (!$window.localStorage['right_side_panel'] || $window.localStorage['right_side_panel'] === 'show')) ? 'removeClass' : 'addClass';
            $scope.enableDisableFullScreenView(action);
        };

        $scope.$on('hiddenRightSideBar', function () {
            if(window.innerWidth > 1425) {
                $scope.enableDisableFullScreenView('addClass');
            }
        });
        $scope.$on('shownRightSideBar', function () {
            $scope.enableDisableFullScreenView('removeClass');
        });

        window.onresize = $scope.resizeDashContainer;

        $scope.formatPhone = function(value) {
            return $rootScope.libPhoneFormatPhone(value);
        };

        $scope.formatSeconds = function (totalSeconds) {

            if(totalSeconds < 0){
                return 'now';
            }

            totalSeconds = parseInt(totalSeconds || 0);
            let hours   = Math.floor(totalSeconds / 3600);
            let minutes = Math.floor((totalSeconds - (hours * 3600)) / 60);
            let seconds = totalSeconds - (hours * 3600) - (minutes * 60);

            if (hours   < 10 && hours > 0) {hours   = "0"+hours;}
            if (minutes < 10) {minutes = "0"+minutes;}
            if (seconds < 10) {seconds = "0"+seconds;}

            let total = minutes+':'+seconds;
            if(hours > 0){
                total = hours+':'+total;
            }

            return total;
        };

        $scope.__buildFunnelChart = function(dispositions) {

            // prevent creating chart if html is not ready
            if ($('#call-outcomes-chart').length === 0) {
                return;
            }

            am4core.useTheme(am4themes_animated);

            $scope.charts.funnerlChart = am4core.create('call-outcomes-chart', am4charts.SlicedChart);

            let funnelSeries = $scope.charts.funnerlChart.series.push(new am4charts.FunnelSeries());
            funnelSeries.colors.step = 2;
            funnelSeries.dataFields.value = 'count';
            funnelSeries.dataFields.category = '_id';
            funnelSeries.alignLabels = true;
            funnelSeries.fontSize = 15;

            funnelSeries.labelsContainer.paddingLeft = 15;
            funnelSeries.labelsContainer.width = 150;

            //series.orientation = "horizontal";
            //series.bottomRatio = 1;

            funnelSeries.legend = new am4charts.Legend();
            funnelSeries.legend.position = 'left';
            funnelSeries.legend.valign = 'bottom';
            funnelSeries.legend.margin(5,5,20,5);

            $scope.charts.funnerlChart.data = dispositions;
        };

        $scope.loadDispositions = function() {
            var teamId = authService.getTeamId();
            var userId = authService.getUserId();

            const request = {
                team_id: teamId,
                user_id: userId
            };

            phoneService.getDispositionStatistics(request)
                .then(dispositions => {
                    $scope.dataDashboard.emptyGraph.callOutcomesChart = (dispositions.total.length === 0) || !$scope.dataDashboard.hasAccess ? true : false;
                    $scope.__buildFunnelChart(dispositions.total);
                    $scope.dataDashboard.timeAnalysis.data.dispo = dispositions.time;
                    $scope.loading.callOutcomesChart = false;
                    $scope.$apply();
                })
                .catch(err => {
                    console.error(err);
                    $scope.loading.callOutcomesChart = false;
                    $scope.$apply();
                });
        };

        $scope.loadGlobalStatistics = function() {
            var teamId = authService.getTeamId();
            var userId = authService.getUserId();

            const request = {
                team_id: teamId,
                user_id: userId
            };

            phoneService.getGlobalStatistics(request)
                .then(statistics => {
                    $scope.dataDashboard.statistic = statistics;
                    $scope.loading.globalStatistics = false;
                    $scope.$apply();
                })
                .catch(err => {
                    console.error(err);
                    $scope.loading.globalStatistics = false;
                    $scope.$apply();
                });
        };

        $scope.isCorrectDate = function(list, type) {
            switch (type) {
                case 'call':
                    if(list.last_call_date && list.last_call_date > 0 && moment(list.last_call_date).isValid()) {
                        return true;
                    }
                    return false;
                case 'sms':
                    if(list.last_message_date && list.last_message_date > 0 && moment(list.last_message_date).isValid()) {
                        return true;
                    }
                    return false;
            }
        };

        $scope.loadListStatistics = function() {
            var teamId = authService.getTeamId();
            var userId = authService.getUserId();

            const request = {
                team_id: teamId,
                user_id: userId
            };

            phoneService.getListStatistics(request)
                .then(statistics => {

                    statistics.map(list => {
                        list.duration = $scope.formatSeconds(list.duration);
                        list.callable_in = $scope.formatSeconds(list.callable_in);
                        return list;
                    });

                    $scope.dataDashboard.listStatistics = statistics;
                    $scope.loading.listStatistics = false;
                    $scope.$apply();
                })
                .catch(err => {
                    console.error(err);
                    $scope.loading.listStatistics = false;
                    $scope.$apply();
                });
        };

        $scope.loadRecentConversions = function() {
            var teamId = authService.getTeamId();
            var userId = authService.getUserId();

            const request = {
                team_id: teamId,
                user_id: userId,
                limit: $scope.itemsPerWidget.conversions
            };

            phoneService.getRecentConversions(request)
                .then(conversions => {
                    $scope.dataDashboard.recentConversions = conversions;

                    $scope.loading.recentConversions = false;
                    $scope.$apply();
                })
                .catch(err => {
                    console.error(err);
                    $scope.loading.recentConversions = false;
                    $scope.$apply();
                });
        };

        $scope.__formatCalls = function(calls) {

            $scope.dataDashboard.recentCalls = [];

            _.forEach(calls, function(call) {
                const callFormatted = {
                    contact_name: (call.first_name || call.last_name) ? ((call.first_name || '') + ' ' + (call.last_name || '')).trim() : null,
                    contact_number: call.call_direction === 'outbound' ? call.to : call.from,
                    to: call.contact_name || call.to,
                    from: call.user_name,
                    duration: $scope.formatSeconds(call.duration),
                    is_missed: !call.duration,
                    type: call.call_direction === 'outbound' ? 'out' : 'in',
                    date: call.started_at,
                    contact_id: call.contact_id,
                    record_call: call.record_call,
                    call_recording: call.call_recording
                };

                $scope.dataDashboard.recentCalls.push(callFormatted);
            });
        };

        // player start
        $scope.currentOpenedPlayerIdCall = -1;
        $scope.$on('currentOpenedRecordChanged', function(event, newCurrentOpenedPlayerId, recordType) {
            switch(recordType) {
                case 'call':
                    $scope.currentOpenedPlayerIdCall = newCurrentOpenedPlayerId;
                    break;
            }
        });
        $scope.$on('audioObjectChanged', function(event, audioObject, index, recordType) {
            switch(recordType) {
                case 'call':
                    $scope.dataDashboard.recentCalls[index].audio = audioObject;
                    break;
            }
        });
        // player end

        $scope.loadCalls = function() {
            var teamId = authService.getTeamId();
            var userId = authService.getUserId();

            const request = {
                team_id: teamId,
                user_id: userId,
                names: true,
                omit_missed: true,
                call_type: "contact,external",
                limit: $scope.itemsPerWidget.calls,
                with_recording: true,
            };

            phoneService.getCalls(request)
                .then(calls => {
                    $scope.__formatCalls(calls.calls);
                    $scope.loading.recentCalls = false;
                    $scope.$apply();
                })
                .catch(err => {
                    console.error(err);
                    $scope.loading.recentCalls = false;
                    $scope.$apply();
                });
        };

        $scope.disposeCharts = function() {
            for (const char in $scope.charts){
                $scope.charts[char].dispose();
            }
        };

        $scope.textContact = function(contact) {
            $rootScope.openTextArea(contact);
        };

        $scope.callContact = function(item) {
            $rootScope.$broadcast('openCallTab', item);
        };

        $scope.startDialing = function(list) {
            $rootScope.$broadcast('startDialing:broadcast', list);
        };

        $scope.viewContactDetails = function(contact_id) {
            if(contact_id && contact_id !== null) {
                $location.path('/contacts/detail').search('id=' + contact_id);
            }
        };

        $scope.loadDashboard = function(){
            $scope.disposeCharts();
            $scope.loadCalls();
            $scope.loadListStatistics();
            $scope.loadGlobalStatistics();
            $scope.loadDispositions();
            $scope.loadRecentConversions();
        };

        $scope.onInit = function() {
            $window.scrollTo(0, 0);
            $scope.resizeDashContainer();
            $scope.loadDashboard();

            //$scope.dashRefresh = $interval($scope.loadDashboard, 30000);
        };

        $scope.$on("$destroy", function () {
            $scope.disposeCharts();
            // $interval.cancel($scope.dashRefresh);
        });

        $scope.$on("agent.hangup", function () {
            $scope.loadRecentConversions();
        });

        $scope.$on("call.hangup", function () {
            $scope.loadRecentConversions();
        });

        if (authService.hasAccount()) {
            $scope.onInit();
        }
        else {
            $scope.$on('auth_complete', function(event, args) {
                $scope.onInit();
            });
        }

    }
    module.exports = DashController;
})();
