(function () {
    'use strict';

    function InboxController($q, $rootScope, $scope, $location, $window, authService, userService) {

        $rootScope.viewData.breadcrumbs = [{
            title: 'Inbox',
            link_name: 'Inbox',
            link_url: '#/inbox',
            help_url: $rootScope.help_url
        }];

        $scope.tasksListPanelMode = 'view'; //'view', 'bulkSelect'
        $scope.staticData = {
            bulkSelect: {
                icon: 'fa-light fa-inbox-full',
                message1: 'You\'ve selected',
                message2: 'task',
                message3: 'tasks',
                clearBtn: 'Clear Selection'
            }
        };
        $scope.data = {
            bulkTasksSelectCount: 0
        };

        $scope.unselectAllTasks = function () {
            $rootScope.$broadcast('bulkTasksUnselectAll');
        };

        $scope.$on('bulkTasksSelect', function (event, count) {
            $scope.data.bulkTasksSelectCount = count;
            $scope.tasksListPanelMode = (count > 0) ? 'bulkSelect' : 'view';
        });

        $scope.$on('setSelectedTask', function (event, task) {
            if (task !== null) {
                // send actual conversation data to allow option 'open conversation' on Agent Activity page
                const userReq = {
                    user_id: authService.getUserId(),
                    team_id: authService.getTeamId(),
                    page_title: 'Inbox',
                    page_url: $location.url(),
                    page_data: {
                        contact_id: task.contact_id,
                        task_id: task.task_id,
                    },
                };
                userService.setUserActivity(userReq).then();

                $rootScope.viewData.breadcrumbs[0].page_data = {
                    contact_id: task.contact_id,
                    task_id: task.task_id
                };
            }
        });

        $scope.onInit = function () {

        };

        if (authService.hasAccount()) {
            $scope.onInit();
        } else {
            $scope.$on('auth_complete', function (event, args) {
                $scope.onInit();
            });
        }
    }

    module.exports = InboxController;
})();
