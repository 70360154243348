(function() {
    'use strict';

    function SyncContactsModalController($rootScope, $scope, integrationService, searchTerm) {
        var vm = this;

        $scope.data = {
            sending: false,
            isFormSubmitted: false,
            modalElementId: '#syncContacts',
            contactsSearch: null
        };

        $scope.forms = {
            contacts_list_name: {
                value: null,
                is_valid: true
            },
        }

        $scope.resetForm = () => {
            $scope.forms = {
                contacts_list_name: {
                    value: null,
                    is_valid: true
                }
            }
        }

        $scope.validateControl = (type) => {
            if ($scope.data.isFormSubmitted) {
                $scope.forms[type].is_valid = !!$scope.forms[type].value;
            }
        }

        $scope.onInit = () => {
            $($scope.data.modalElementId).on('show.bs.modal', (event) => {
                $scope.resetForm();
                const listId = $(event.relatedTarget).data('list-id');
                if (listId) {
                    $scope.data.contactsSearch = {list_id: listId}
                } else {
                    $scope.data.contactsSearch = $rootScope.contactsSearch;
                }
            });
        }

        $scope.onInit();

        $scope.syncContacts = () => {
            $scope.data.isFormSubmitted = true;
            $scope.validateControl('contacts_list_name');

            if (!$scope.forms.contacts_list_name.is_valid) {
                return;
            }

            $scope.data.sending = true;
            const request = Object.assign({
                contacts_list_name: $scope.forms.contacts_list_name.value
            },
                {search_contacts: $scope.data.contactsSearch}
            );
            integrationService.syncContacts(integrationService.get()['drop_cowboy'].integration_id, request)
                .then(() => {
                    $($scope.data.modalElementId).modal('hide');
                    $scope.data.sending = false;
                    $scope.resetForm();
                })
        }
    }
    module.exports = SyncContactsModalController;
})();

