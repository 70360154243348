(function () {
    'use strict';

    function TrustCenterController($rootScope, $scope, $location, $interval, authService, accountService, DTOptionsBuilder, DTDefaultOptions, $http) {

        var vm = this;
        vm.authService = authService;

        $rootScope.viewData = $rootScope.viewData || {};
        $rootScope.viewData.breadcrumbs = $rootScope.viewData.breadcrumbs || [];
        $rootScope.viewData.breadcrumbs = [{
            title: 'Trust Center',
            link_name: 'Trust Center',
            link_url: '#/trust-center',
            help_url: $rootScope.help_url
        }];

        $scope.staticText = {
            findBlock: {
                title: "Add Pool Numbers",
                numberType: ["Local", "Toll Free"],
                searchInputPlaceholder: "Prefix / Area Code",
                searchBtn: "Search",
                optionMessage: "* MMS messaging not available for toll-free numbers",
                block1: {
                    title: "Search Numbers",
                    subtitle: "Select a toll-free prefix or search by area code..."
                }
            },
            resultBlock: {
                title: "Available numbers",
                subtitle: "These numbers are available for your pool...",
                loader: "Loading",
                table: {
                    header: ["Phone Number", "Select"],
                    optionTd: {
                        mainText: "Premium Feature",
                        smallText: "Membership Required"
                    },
                    actionsTd: {
                        upgradeBtn: "Become a Member",
                        rentBtn: "Rent Number"
                    },
                    noRes: "No numbers match your search criteria. Change your criteria and try again."
                },
                submitBtn: "Add to Pool"
            },
            ageGate: '(18+ or 21+ material)',
            brand: {
                title: 'Brands',
                subtitle: 'Manage your brand registration with SMS carriers...',
                addBrandBtn: {
                    title: 'Add Brand'
                },
                table: {
                    header: [
                        'Brand Status',
                        'Conversational Texting',
                        'Bulk Texting'
                    ],
                },
                approved: 'Active',
                review: 'Under Review',
                actions: ['Request Conversational Texting', 'Request Bulk Texting', 'Delete Conversational Texting', 'Delete Bulk Texting', 'Delete Brand']
            },
            trustCenter: {
                topIcon: 'assets/images/trust-center/trust.svg',
                title: 'Trust Center',
                text: `Your ${$rootScope.settings.APP_NAME} account is now eligible to receive brand verification from the major carriers. Your account’s verification is a major factor used by the carriers to decide if your calls and messages should be delivered or filtered as spam!`,
                actionBtn: {
                    title: 'Register Now',
                    href: ''
                },
                faq: {
                    title: 'FAQ',
                    list: [
                        {
                            q: 'How Does Brand Verification Work?',
                            a1: 'Carriers verify your business registration, tax documents, and public information to\n' +
                                'verify your brand identity.'
                        },
                        {
                            q: 'How Do I Get Verified?',
                            a1: 'Getting verified is easy and we’ll hold you hand through the registration process. You’ll fill out a\n' +
                                'simple questionnaire about your organization, industry vertical, and provide a sample message. ' + $rootScope.settings.APP_NAME + '\n' +
                                'will then work on your behalf to register your organization and assist in the verification process.'
                        },
                        {
                            q: 'How Long Does It Take?',
                            a1: 'Standard verification usually occurs within a few hours during normal business hours but may take up to 6 weeks for SMS access to be approved by the carriers.'
                        },
                        {
                            q: 'How Much Does It Cost?',
                            a1: 'The cost of registering your brand is included in your ' + $rootScope.settings.APP_NAME + ' subscription.',
                        },
                        {
                            q: 'What If I Fail Verification?',
                            a1: 'Depending on your ' + $rootScope.settings.APP_NAME + ' subscription plan, you may also be eligible to undergo extended\n' +
                                'verification by an independent 3rd Party. Extended verification can take 2-10 business days but usually\n' +
                                'results in improved trust levels with the carriers.'
                        },
                        {
                            q: 'What Is A2P 10DLC?',
                            a1: 'In order to reduce the number of spam text messages reaching consumers, all major carriers now\n' +
                                'require commercial (non-person to person) text messages to be sent using an A2P 10DLC enabled phone\n' +
                                'number from a verified brand. ' + $rootScope.settings.APP_NAME + ' automatically enables A2P\n' +
                                '10DLC on your phone numbers once you register your brand.'
                        },
                        {
                            q: 'What Is STIR/SHAKEN?',
                            a1: 'In order to combat illegal robocalls, government regulation requires all voice carriers to positively\n' +
                                'identify callers using a technology known as STIR/SHAKEN. Calls not sent with proper credentials are\n' +
                                'tagged and possibly filtered as spam. ' + $rootScope.settings.APP_NAME + ' uses your brand verification along with a “Know Your\n' +
                                'Customer” vetting process to provide the proper STIR/SHAKEN authentication for your calls.'
                        },
                        {
                            q: 'Special Use Cases',
                            a1: 'Carriers require extended verification and may impose additional restrictions for certain use cases\n' +
                                'including political and charity messaging. Political messaging for federal campaigns requires a FEC ID.'
                        }
                    ]
                }
            },
            addNumerPoolModal: {
                title: 'Request Text Messaging',
                submitBtn: 'Request Approval',
                instructions: {
                    title: 'Instructions',
                    description: '\n' + 'Before you can send text messages, you must receive approval from the phone carriers to send to their subscribers.  We will work on your behalf to obtain the necessary approvals.  Ultimate approval is determined by the Campaign Registry (the central regulatory body for obtaining SMS approvals) and is outside of our control.  This process typically takes 3-7 business days.',
                },
                pricing: {
                    description: 'Registering a number pool additional fees (see Fee Schedule). Fees are non-refundable and automatically renew every month until canceled.',
                    descriptionWeekly: 'Registering a number pool additional fees (see Fee Schedule). Fees are non-refundable and automatically renew every week until canceled.',
                    fees: 'Fee Schedule (recurring subscription billed monthly)',
                    feesWeekly: 'Fee Schedule (recurring subscription billed weekly)',
                },
                block1: {
                    title: 'Brand',
                    subtitle: 'Select the brand that this number pool belongs to.  Brands will appear here once they are verified.',
                    brandPlaceholder: 'Select Brand'
                },
                block2: {
                    title: 'SIP Trunk',
                    description: "Enter your SIP Trunk credentials. You may need to whitelist our IP address with your SIP provider.",
                    connection_type: 'SIP Connection Type',
                    auth_type: ['IP Whitelist', 'Credentials'],
                    port: 'Port',
                    port_default: '5060',
                    trunk1: 'Trunk 1',
                    trunk2: 'Trunk 2',
                    username: 'Username',
                    password: 'Password',
                },
                block3: {
                    title: 'Use Case',
                    subtitle: 'Select your intended use of text messaging.  Please be careful not to misrepresent your usage or the carriers may suspend your messaging campaigns or your brand entirely. Use cases marked as "special" require additional brand vetting and approvals from the carriers.',
                    useCasePlaceholder: 'Use Case',
                    checkbox1: 'My text messages are associated with affiliate marketing',
                    checkbox2: 'My text messages are associated with direct lending',
                    checkbox3: 'My text messages may contain a phone number',
                    checkbox4: 'My text messages may contain a link to a website URL',
                    checkbox5: 'My text messages may contain age-gated content'
                },
                block4: {
                    title: 'Sample Text Message',
                    subtitle: 'Provide a sample text message that you intend to send to your contacts. For example, if a contact requests information about your brand or services.',
                    title2: 'Second Sample Text Message',
                    subtitle2: 'Provide another sample text message that you intend to send to your contacts. For example, if a contact requests help resolving an issue about your brand or services.',
                    optOutText: "Reply STOP to opt-out"
                },
                block5: {
                    title: 'Help Message',
                    subtitle: "Provide instructions to reply to 'HELP' keywords.  Instructions must contain a toll-free phone number or a support email address. Additional forms of help are allowed, but either of the above is required at minimum.",
                    placeholder: 'Compose your help message. You must include a toll-free phone number or a support email address.'
                },
                block6: {
                    title: 'Pool Name',
                    subtitle: "Please name your pool.",
                    placeholder: 'Pool description'
                },
                block7: {
                    title: 'Privacy Policy',
                    subtitle: "Please provide instructions to reply to 'PRIVACY' keywords.  Instructions must contain a website URL, email, or other instructions for your contacts to view your brand's privacy policy. Do not copy paste your entire policy here.",
                    placeholder: 'Link or another way for contacts to view your Privacy Policy'
                },
                block8: {
                    title: 'Terms & Conditions',
                    subtitle: "Please provide instructions to reply to 'TERMS' keywords.  Instructions must contain a website URL, email, or other instructions for your contacts to view your brand's terms & conditions. Do not copy paste your entire policy here.",
                    placeholder: 'Link or another way for contacts to view your Terms & Conditions'
                },
                block9: {
                    title: 'Call To Action Reason',
                    subtitle: "Please provide a call to action for us to display to your contacts when we confirm their consent to receive text messages from your brand.  For example: enter 'purchasing your house' if you want your brand's call to action to say 'Example brand would like to text you regarding purchasing your house.",
                    placeholder: '... would like to text you regarding [FILL IN THE BLANK].',
                    msg_count: 'Approximate number of messages a contact will receive per month'
                },
                block10: {
                    title: 'Message Frequency',
                    subtitle: "Approximate number of messages a contact will receive per month.",
                },
                block11: {
                    title: 'Opt-In Flow',
                    subtitle: "Please provide a description of how consumers opt-in to receive text messages from your brand. For example: 'Consumers opt-in by adding their phone number to the form hosted on www.example.com. They then check a box agreeing to receive text messages from Example Brand'.",
                    subtitle_ucass: "Please provide a description of how consumers opt-in to receive text messages from your brand. For example: 'Consumer initiates the conversation by texting Example Brand'.",
                    placeholder: "Describe your brand's opt-in process"
                },
                block12: {
                    title: 'Usage Description',
                    subtitle: "Please describe how your brand will use text messaging for the use case you selected. For example: 'Example brand will send our customers monthly specials for our pool cleaning services.'",
                    subtitle_ucass: "Please describe how your brand will use text messaging for the use case you selected. For example: 'Consumer texts Example Brand first and the business responds quickly with a single message.'",
                    placeholder: "Describe your brand's use of text messaging"
                },
                sipBlock: {
                    host_placeholder: 'Host (sip.example.com)',
                    port_placeholder: 'Port',
                    user_placeholder: 'Username',
                    pwd_placeholder: 'Password',
                    cps_placeholder: 'CPS',
                    concurrency_placeholder: 'Concurrency'
                },
                billing: 'I acknowledge that carrier\'s may limit, at their discretion, the frequency and the speed at which text messages may be delivered to their network.',
                billingWeekly: 'I acknowledge this is a non-refundable, recurring subscription, billed to my credit card and will auto-renew weekly until canceled.',
                agree: "I acknowledge that texting privileges may be suspended by a carrier for any reason at the carrier's discretion.",
                optin: "I obtained opt-in from my contacts using an approved method defined in the CTIA Guidelines.",
                rented: "I confirm that my opted-in contact lists are my own and I will not use contact lists that I have rented, purchased, or are shared by a 3rd party with me.",
            },
            title: 'Number Pools',
            subtitle: 'Manage your private number pools...',
            addRvmBtn: {
                title: 'Add Numbers'
            },
            addSmsBtn: {
                title: 'Add Numbers'
            },
            addNumberPoolBtn: {
                title: 'Add Number Pool'
            },
            deleteNumberBtn: {
                title: 'Delete'
            },
            table: {
                header: [
                    'Service',
                    'Utilization',
                    'Status'
                ],
                details: [
                    'Phone Number',
                    'Status',
                    '',
                ],
                actions: [
                    'Edit',
                    'Delete',
                    'Add Number',
                    'Upload Numbers'
                ]
            },
            loading: 'Loading',
            noResults: 'No Results',
            unlimited: 'Unlimited',
            suspended: 'Carrier Suspended',
            approved: 'Approved',
            selectNumber: 'Select Numbers',
            awaitingReview: 'Under Review',
            activeNumber: 'Active',
            addBrandModal: {
                title: 'Brand Registration',
                submitBtn: 'Register',
                ucassBtn: 'Add Conversational',
                textingBtn: 'Add Bulk',
                instructions: {
                    title: 'Instructions',
                    description: 'Please tell us about your business and the industry you operate in.  It is important for you to fill in the fields as accurately as possible.  Any errors may negatively impact your brand verification and cannot be changed once submitted. If you have questions please chat with one of our support agents.'
                },
                block1: {
                    title: 'Business Details',
                    namePlaceholder: 'Legal Name',
                    dbaPlaceholder: 'DBA Name',
                    entityTypePlaceholder: 'Entity Type',
                    stateOfIncorporationPlaceholder: 'State of Incorporation',
                    industryVerticalPlaceholder: 'Industry Vertical',
                    websiteUrlPlaceholder: 'Website Url (must start with https:\\\\ or http:\\\\)',
                    finTaxIdPlaceholder: 'EIN / Tax ID',
                    taxCountryPlaceholder: 'Tax Country',
                    altIdNumberPlaceholder: 'Business ID Number (DUNS, GIIN, LEI)',
                    altIdTypePlaceholder: 'Business ID Type',
                    stockSymbolPlaceholder: 'Stock Symbol',
                    stockExchangePlaceholder: 'Stock Exchange'
                },
                block2: {
                    title: 'Physical Address',
                    countryPlaceholder: 'Country',
                    streetAddressPlaceholder: 'Street Address',
                    streetAddressLine2Placeholder: 'Street Address Line 2',
                    cityPlaceholder: 'City',
                    statePlaceholder: 'State',
                    postalCodePlaceholder: 'Postal Code'
                },
                block5: {
                    title: 'Authorized Representative',
                    firstNamePlaceholder: 'First Name',
                    lastNamePlaceholder: 'Last Name',
                    emailPlaceholder: 'Email',
                    businessTitlePlaceholder: 'Business Title'
                },
                block6: {
                    title: 'Opt-In Flow',
                    subtitle: `Some of the bulk calling and texting features of ${$rootScope.settings.APP_NAME} require you to obtain consent from your contacts to be contacted. Obtaining consent from your contacts is usually as easy as adding a checkbox and the appropriate legal wording to your lead capture forms, contracts, or other agreements with your customer. If you don't have consent, you can still use this platform to call and text your contacts, but we'll disable the bulk sending features to keep you compliant with regulations.`,
                    placeholder: "Describe your brand's opt-in process. We do not accept purchased, scraped, or skip-traced leads."
                },
                block7: {
                    title: 'Attestations',
                },
                truth: 'I confirm the information provided is true and accurate.',
                confirm: 'I confirm that the authorized representative agrees to be contacted by ' + $rootScope.settings.APP_NAME + ' or designated 3rd party auditor for purposes of vetting this brand.',
                tcr: 'I authorize ' + $rootScope.settings.APP_NAME + ' to register this brand with The Campaign Registry, the official vetting authority and centralized 10DLC registrar for carriers and content service providers. Verification is exclusively performed by The Campaign Registry. ' + $rootScope.settings.APP_NAME + ' offers no guarantees or warranties for the verification of my brand.',
                opt_in_ebr: 'Pre-existing established business relationship',
                opt_in_wet_signature: 'In person (physical form, digital form, or point-of-sale device)',
                opt_in_webform: 'Submit web form (directly on your brand\'s website)',
                opt_in_social_ad: 'Submit embedded ad form (social media ads, Facebook ads, Zillow, ect.)',
                opt_in_sms: 'Text a keyword to a number',
                opt_in_ivr: 'Keypress on phone IVR',
                opt_in_recorded_audio: 'Recorded audio during phone call',
                opt_in_other: 'Other (please describe)',
                opt_in_no_opt_in: "I don't have opt-in from my contacts (you can call and text with your contacts, but some bulk calling and texting features will be disabled to keep you compliant with regulations)"
            },
            requiredMarkerInfo: 'Field is required.',
            requiredError: 'This field is required.',
            phoneNumberError: 'Phone number is not valid.',
            smsEditorIncludeBannedKeywordsWarning: "The message contains banned content.",
            smsEditorIncludeStopKeywordsWarning: "The message must contain STOP opt-out.",
            taxIdFormatError: 'Invalid Tax ID format.',
            notRecommended: 'not recommended',
            creating: 'Registering'
        };

        $scope.data = {
            trust_score_registered: true,
            desiredCapacity: ['0','1','2','3','4','5'],
            poolSize: [
                {
                    id: 'single_state',
                    title: 'Single State'
                },
                {
                    id: 'multi_state',
                    title: 'Multi-State'
                },
                {
                    id: 'national',
                    title: 'National'
                },
                {
                    id: 'BYOC',
                    title: 'BYOC'
                }
            ],
            states: [],
            numberPools: [],
            selectedNumbers: [],
            brands: [],
            registeredBrandCount: 0,
            pollBrands: true,
            innerTableDetails: {
                marginTop: 22,  // set in number-pools.css .dataTables_wrapper
                marginBottom: 37,   // set in number-pools.css .dataTables_wrapper
                scrollDivMinHeight: 205 // set in number-pools.css .details-td.show
            },
            addBrandForm: {
                formSubmitted: false,
                isPhoneNumberValid: undefined,
                isTaxIdValid: undefined,
                isOptInValid: undefined,
                name: null,
                dbaName: null,
                entityType: null,
                incorporationState: null,
                vertical: null,
                websiteUrl: null,
                taxID: null,
                taxIDCountry: 'US',
                altBusinessId: null,
                altBusinessIdType: "NONE",
                stockSymbol: null,
                stockExchange: null,
                physicalAddress: {
                    streetAddress: null,
                    streetAddressLine2: null,
                    city: null,
                    state: null,
                    postalCode: null,
                    country: 'US'
                },
                authorizedRepresentative: {
                    firstName: null,
                    lastName: null,
                    email: null,
                    businessTitle: null,
                    phoneNumber: null
                },
                confirm: false,
                tcr: false,
                opt_in_flow: null,
                opt_in_ebr: false,
                opt_in_wet_signature: false,
                opt_in_webform: false,
                opt_in_social_ad: false,
                opt_in_sms: false,
                opt_in_ivr: false,
                opt_in_recorded_audio: false,
                opt_in_other: false,
                opt_in_no_opt_in: false,
                cannedOptInValid: false
            },
            addNumberPoolForm: {
                formSubmitted: false,
                brand_id: null,
                force_brand: null,
                poolName: null,
                poolDescription: null,
                isSmsEditorIncludeBannedKeywords: undefined,
                isSmsEditorIncludeStopKeywords: undefined,
                isSmsEditorIncludeBannedKeywords2: undefined,
                isSmsEditorIncludeStopKeywords2: undefined,
                isHelpMessageValid: undefined,
                isCTAMessageValid: undefined,
                isPrivacyMessageValid: undefined,
                isTermsMessageValid: undefined,
                isOptInValid:  undefined,
                isDescriptionValid:  undefined,
                campaign: {
                    useCase: null,
                    embeddedUrl: false,
                    embeddedPhone: false,
                    directLending: false,
                    affiliateMarketing: false,
                    ageGate: false,
                    sampleMessage: null,
                    helpMessage: null,
                    termsMessage: null,
                    privacyMessage: null,
                    optInPolicy: null,
                    ctaMessage: null,
                    customCtaMessage: null,
                    frequency: 1,
                    messageLength: 0,
                    messageLength2: 0,
                    serviceType: 'sms'
                },
                agree: false,
                billing: false
            },
            taxIDRegex: '/\\d/',
            numbers: [],
            rent_number: null,
            number_type: 'Toll Free',
            area_code: '',
            iso_country_code: 'US',
            isoToCountry: {
                //'AU': 'Australia',
                //'BE': 'Belgium',
                //'CA': 'Canada',
                //'FI': 'Finland',
                //'DE': 'Germany',
                //'NL': 'Netherlands',
                //'NO': 'Norway',
                //'SE': 'Sweden',
                //'CH': 'Switzerland',
                //'GB': 'United Kingdom',
                'US': 'United States'
            },
            ctaMessageErrorMessage: '',
            optInErrorMessage: '',
            helpMessageErrorMessage: '',
            termsMessageErrorMessage: '',
            privacyMessageErrorMessage: '',
            descriptionErrorMessage: '',
        };

        $scope.bannedKeywords = [];

        $scope.verticals = [
            {
                "id": "PROFESSIONAL",
                "industryId": "professional-services",
                "displayName": "Professional Services",
                "description": "Provider of Professional Services."
            },
            {
                "id": "REAL_ESTATE",
                "industryId": "real-estate",
                "displayName": "Real Estate",
                "description": "Buying and Selling of residential and commercial properties."
            },
            {
                "id": "HEALTHCARE",
                "industryId": "healthcare-and-lifesciences",
                "displayName": "Healthcare",
                "description": "Healthcare services, including hospital, health insurances."
            },
            {
                "id": "HUMAN_RESOURCES",
                "industryId": "human-resources-staffing-recruitment",
                "displayName": "HR, Staffing or Recruitment",
                "description": "Human resources, staffing, or recruitment."
            },
            {
                "id": "ENERGY",
                "industryId": "energy-and-utilities",
                "displayName": "Energy and Utilities",
                "description": "Energy and Utilities."
            },
            {
                "id": "ENTERTAINMENT",
                "industryId": "entertainment",
                "displayName": "Entertainment",
                "description": "Entertainment, including gaming and dating services."
            },
            {
                "id": "RETAIL",
                "industryId": "retail-and-consumer-products",
                "displayName": "Retail",
                "description": "Consumer products and services, including Retails and eTails."
            },
            {
                "id": "TRANSPORTATION",
                "industryId": "transportation-logistics",
                "displayName": "Transportation or Logistics",
                "description": "Transportation or logistics services."
            },
            {
                "id": "AGRICULTURE",
                "industryId": "agriculture",
                "displayName": "Agriculture",
                "description": "Agriculture businesses and services"
            },
            {
                "id": "INSURANCE",
                "industryId": "insurance",
                "displayName": "Insurance",
                "description": "Insurance products and services."
            },
            {
                "id": "POSTAL",
                "industryId": "postal-delivery",
                "displayName": "Postal and Delivery",
                "description": "Postal and delivery services."
            },
            {
                "id": "EDUCATION",
                "industryId": "education",
                "displayName": "Education",
                "description": "Educational institutions providing education to students in educational settings."
            },
            {
                "id": "HOSPITALITY",
                "industryId": "hospitality",
                "displayName": "Hospitality and Travel",
                "description": "Travel, hospitality and transportation."
            },
            {
                "id": "FINANCIAL",
                "industryId": "financial-services",
                "displayName": "Financial",
                "description": "Banking and financial institution."
            },
            {
                "id": "POLITICAL",
                "industryId": "political",
                "displayName": "Political",
                "description": "Political campaigns and services."
            },
            {
                "id": "GAMBLING",
                "industryId": "gambling-and-lottery",
                "displayName": "Gambling Lottery",
                "description": "Gambling and Lotteries."
            },
            {
                "id": "LEGAL",
                "industryId": "legal",
                "displayName": "Legal",
                "description": "Legal services and law firms."
            },
            {
                "id": "CONSTRUCTION",
                "industryId": "construction-and-materials",
                "displayName": "Construction, Materials, and Trade Services",
                "description": "Construction, materials, and trade services."
            },
            {
                "id": "NGO",
                "industryId": "non-profit-organization",
                "displayName": "Non Government Organization",
                "description": "Non-profit organization with valid tax-exempted status."
            },
            {
                "id": "MANUFACTURING",
                "industryId": "manufacturing",
                "displayName": "Manufacturing",
                "description": "Goods producing industries."
            },
            {
                "id": "GOVERNMENT",
                "industryId": "public-sector",
                "displayName": "Government",
                "description": "Local, state or federal agencies, utilities."
            },
            {
                "id": "TECHNOLOGY",
                "industryId": "information-technology-services",
                "displayName": "Information Technology",
                "description": "Information technology and information services."
            },
            {
                "id": "COMMUNICATION",
                "industryId": "mass-media-and-communication",
                "displayName": "Communication and Mass Media",
                "description": "Communication and mass media."
            }
        ];

        $scope.ucassUseCases = [{
            "id": "UCAAS_LOW",
            "classification": "STANDARD",
            //"description": "UCaaS companies provide cloud delivered communication services for businesses. Each number assigned to a UCaaS campaign is typically assigned to a different employee of that business and the use cases are varied. This use case is not for any API/automated driven communication. This use case is only available to approved UCaaS businesses.",
            "description": "Conversational business texting for one-on-one communication with customers.  Each phone number enabled for conversational text messaging is typically assigned to a different employee of that business. Do not use for bulk texting campaigns.",
            "displayName": "Conversational Texting",
            "maxSubUsecases": 0,
            "minSubUsecases": 0,
            "validSubUsecase": false,
            "is10DLC": true,
            "isUcaas": true,
            "serviceType": "sms"
        }];

        $scope.ucassUseCase = {
            "id": "UCAAS_LOW",
            "classification": "STANDARD",
            //"description": "UCaaS companies provide cloud delivered communication services for businesses. Each number assigned to a UCaaS campaign is typically assigned to a different employee of that business and the use cases are varied. This use case is not for any API/automated driven communication. This use case is only available to approved UCaaS businesses.",
            "description": "Conversational business texting for one-on-one communication with customers.  Each phone number enabled for conversational text messaging is typically assigned to a different employee of that business. Do not use for bulk texting campaigns.",
            "displayName": "Conversational Texting",
            "maxSubUsecases": 0,
            "minSubUsecases": 0,
            "validSubUsecase": false,
            "is10DLC": true,
            "isUcaas": true,
            "serviceType": "sms"
        };

        $scope.spUseCase = {
            "id": "CUSTOMER_CARE",
            "classification": "STANDARD",
            "description": "All customer care messaging, including account management and support",
            "displayName": "Customer Care",
            "maxSubUsecases": 0,
            "minSubUsecases": 0,
            "validSubUsecase": true,
            "is10DLC": true,
            "serviceType": "sms"
        };

        $scope.mixedUseCase = {
            "id": "MIXED_CUSTOMER_CARE_MARKETING",
            "sub_ids": ["CUSTOMER_CARE", "MARKETING"],
            "classification": "STANDARD",
            "description": "Mixed use to include all customer care messaging, including account management, support and marketing and/or promotional content.",
            "displayName": "Mixed: Customer Care & Marketing",
            "maxSubUsecases": 0,
            "minSubUsecases": 0,
            "validSubUsecase": true,
            "is10DLC": true,
            "isMixed": true,
        };

        $scope.bulkUseCases = [
            {
                "id": "ACCOUNT_NOTIFICATION",
                "sub_ids": null,
                "classification": "STANDARD",
                "description": "All reminders, alerts, and notifications. (Examples include: flight delayed, hotel booked, appointment reminders.)",
                "displayName": "Account Notification",
                "maxSubUsecases": 0,
                "minSubUsecases": 0,
                "validSubUsecase": true,
                "is10DLC": true,
                "isMixed": false
            },
            {
                "id": "CHARITY",
                "sub_ids": null,
                "classification": "SPECIAL",
                "description": "Includes: 5013C Charity. Does not include: Religious organizations.",
                "displayName": "Charity",
                "maxSubUsecases": 5,
                "minSubUsecases": 0,
                "validSubUsecase": false,
                "is10DLC": true,
                "isMixed": false
            },
            {
                "id": "CUSTOMER_CARE",
                "sub_ids": null,
                "classification": "STANDARD",
                "description": "All customer care messaging, including account management and support",
                "displayName": "Customer Care",
                "maxSubUsecases": 0,
                "minSubUsecases": 0,
                "validSubUsecase": true,
                "is10DLC": true,
                "isMixed": false
            },
            {
                "id": "HIGHER_EDUCATION",
                "sub_ids": null,
                "classification": "STANDARD",
                "description": "For campaigns created on behalf of Colleges or Universities and will also include School Districts etc that fall outside of any \"free to the consumer\" messaging model",
                "displayName": "Higher Education",
                "maxSubUsecases": 0,
                "minSubUsecases": 0,
                "validSubUsecase": true,
                "is10DLC": true,
                "isMixed": false
            },
            {
                "id": "MARKETING",
                "sub_ids": null,
                "classification": "STANDARD",
                "description": "Any communication with marketing and/or promotional content",
                "displayName": "Marketing",
                "maxSubUsecases": 0,
                "minSubUsecases": 0,
                "validSubUsecase": true,
                "is10DLC": true,
                "isMixed": false
            },
            {
                "id": "MIXED_CUSTOMER_CARE_MARKETING",
                "sub_ids": ["CUSTOMER_CARE", "MARKETING"],
                "classification": "STANDARD",
                "description": "Mixed use to include all customer care messaging, including account management, support and marketing and/or promotional content.",
                "displayName": "Mixed: Customer Care & Marketing",
                "maxSubUsecases": 0,
                "minSubUsecases": 0,
                "validSubUsecase": true,
                "is10DLC": true,
                "isMixed": true,
            },
            {
                "id": "POLITICAL",
                "sub_ids": null,
                "classification": "SPECIAL",
                "description": "Part of organized effort to influence decision making of specific group. All campaigns to be verified",
                "displayName": "Political",
                "maxSubUsecases": 5,
                "minSubUsecases": 0,
                "validSubUsecase": false,
                "is10DLC": true,
                "isMixed": false
            },
            {
                "id": "POLLING_VOTING",
                "sub_ids": null,
                "classification": "STANDARD",
                "description": "Polling and voting",
                "displayName": "Polling and voting",
                "maxSubUsecases": 0,
                "minSubUsecases": 0,
                "validSubUsecase": true,
                "is10DLC": true,
                "isMixed": false
            },
            {
                "id": "PUBLIC_SERVICE_ANNOUNCEMENT",
                "sub_ids": null,
                "classification": "STANDARD",
                "description": "An informational message that is meant to raise the audience's awareness about an important issue",
                "displayName": "Public Service Announcement",
                "maxSubUsecases": 0,
                "minSubUsecases": 0,
                "validSubUsecase": true,
                "is10DLC": true,
                "isMixed": false
            }
        ];

        $scope.byocUseCases = [
           {
               "id": "BYOC",
               "classification": "BYOC",
               "description": "Provide your own DIDs and SIP Trunks.",
               "displayName": "Bring Your Own Carrier",
               "maxSubUsecases": 0,
               "minSubUsecases": 0,
               "validSubUsecase": true,
               "is10DLC": false
           },
            {
                "id": "VOICE_ONLY",
                "classification": "VOICE ONLY",
                "description": "A lower cost option for customers that don't want to send text messages.",
                "displayName": "Voice Calls Only",
                "maxSubUsecases": 0,
                "minSubUsecases": 0,
                "validSubUsecase": true,
                "is10DLC": false
            }
        ];

        $scope.useCases = $scope.bulkUseCases;

        $scope.stockExchanges = [
            "NONE",
            "NASDAQ",
            "NYSE",
            "AMEX",
            "AMX",
            "ASX",
            "B3",
            "BME",
            "BSE",
            "FRA",
            "ICEX",
            "JPX",
            "JSE",
            "KRX",
            "LON",
            "NSE",
            "OMX",
            "SEHK",
            "SGX",
            "SSE",
            "STO",
            "SWX",
            "SZSE",
            "TSX",
            "TWSE",
            "VSE"
        ];

        $scope.entityTypes = [
            { 'label':'Sole Proprietor (company doesn\'t have an EIN)', 'value': 'SOLE_PROPRIETOR'},
            { 'label':'Private Company (company has an EIN)', 'value': 'PRIVATE_PROFIT'},
            { 'label':'Publicly Traded Company', 'value': 'PUBLIC_PROFIT'},
            { 'label':'Non-Profit', 'value': 'NON_PROFIT'},
            { 'label':'Government', 'value': 'GOVERNMENT'}
        ];

        $scope.altIDTypes = [
            { 'label':'No Business ID', 'value': 'NONE'},
            { 'label':'DUNS', 'value': 'DUNS'},
            { 'label':'GIIN', 'value': 'GIIN'},
            { 'label':'LEI', 'value': 'LEI'}
        ];

        $scope.loading = {
            brands: true
        };

        $(document).on('show.bs.collapse', '#accordion-trust-center-faq .collapse', function () {
            var icon = $(this).hasClass('accordion-arrows') ? 'ti-angle-up' : 'ti-minus';
            var id = $(this).attr('id');
            $('button[data-target="#' + id + '"]').closest('.card-header').addClass('active-accordion');
            $('button[data-target="#' + id + '"] .btn-title span').html('<i class="' + icon + '"></i>');
        });
        $(document).on('hide.bs.collapse', '#accordion-trust-center-faq .collapse', function () {
            var icon = $(this).hasClass('accordion-arrows') ? 'ti-angle-down' : 'ti-plus';
            var id = $(this).attr('id');
            $('button[data-target="#' + id + '"]').closest('.card-header').removeClass('active-accordion');
            $('button[data-target="#' + id + '"] .btn-title span').html('<i class="' + icon + '"></i>');
        });

        $scope.dtOptions = DTOptionsBuilder.newOptions()
            .withLanguage({
                'sEmptyTable':  ''
            });
        $scope.dtOptions = DTDefaultOptions.setOption('searching', false);
        $scope.dtOptions = DTDefaultOptions.setOption('info', false);
        $scope.dtOptions = DTDefaultOptions.setOption('scrollY', '243px');
        $scope.dtOptions = DTDefaultOptions.setOption('scrollX', '0px');
        $scope.dtOptions = DTDefaultOptions.setOption('scrollCollapse', true);
        $scope.dtOptions = DTDefaultOptions.setOption('paging', false);
        $scope.dtOptions = DTDefaultOptions.setOption('processing', false);
        $scope.dtOptions = DTDefaultOptions.setOption('autoWidth', false);
        $scope.dtOptions = DTDefaultOptions.setOption('order', [ 1, 'asc' ]);
        $scope.dtOptions = DTDefaultOptions.setOption('columnDefs', [ { 'targets': [0, 2, 3, 4, 5], sortable: false } ]);
        $scope.dtOptions = DTDefaultOptions.setDisplayLength(1);
        $scope.dtOptions = DTDefaultOptions.setLoadingTemplate('');
        $scope.dtOptions = DTDefaultOptions.setOption('initComplete', function(settings) {
            var pollDetailsTd = document.getElementById(settings.nTable.id + 'Td');
            var height = settings.aanFeatures.t[0].clientHeight;
            if(height > $scope.data.innerTableDetails.scrollDivMinHeight) {
                pollDetailsTd.style.height = (height + $scope.data.innerTableDetails.marginTop + $scope.data.innerTableDetails.marginBottom).toString() + 'px';
                $scope.$apply();
            }
        });

        // Add Number Pool Modal start
        $scope.setPhoneMask = function(elem, mask, placeholder) {
            elem.mask(mask, {
                placeholder: placeholder
            });
        };

        $scope.addBrandModalPhoneNumberMask = function(number) {
            var phoneNumberId = $('#' + 'addPoolPhoneNumberInput');
            var phoneNumberIdInput = document.querySelector('#' + 'addPoolPhoneNumberInput');

            if(phoneNumberId && !$scope.iti) {
                $scope.iti = window.intlTelInput(phoneNumberIdInput, {
                    nationalMode: 'false',
                    autoPlaceholder: 'aggressive',
                    separateDialCode: 'true',
                    validationNumberTypes: [
                        'FIXED_LINE',
                        'MOBILE',
                        'FIXED_LINE_OR_MOBILE',
                        'TOLL_FREE',
                        'PREMIUM_RATE',
                        'SHARED_COST',
                        'VOIP',
                        'PERSONAL_NUMBER',
                        'PAGER',
                        'UAN',
                        'VOICEMAIL',
                        'UNKNOWN'
                    ],
                    initialCountry: 'us',
                    // intl-tel-input is up to date as of dec 2024
                    loadUtils: () => import("https://cdn.jsdelivr.net/npm/intl-tel-input@25.2.0/build/js/utils.js"),
                    customPlaceholder: function(selectedCountryPlaceholder, selectedCountryData) {
                        var pl = selectedCountryPlaceholder.replace(/[0-9]/g, '0');
                        phoneNumberId.attr('maxlength', pl.length);
                        $scope.setPhoneMask(phoneNumberId, pl, pl);
                        return pl;
                    }
                });

                if(number){
                    $scope.iti.setNumber(number);
                }

                phoneNumberId.on('countrychange', function(e) {
                    $scope.iti.setNumber('');
                    phoneNumberId.unmask();
                    $scope.data.addBrandForm.isPhoneNumberValid = undefined;

                    var mask = e.target.placeholder.replace(/[0-9]/g, '0');
                    phoneNumberId.attr('maxlength', mask.length);
                    $scope.setPhoneMask(phoneNumberId, mask, e.target.placeholder);
                    $scope.$apply();
                });
            }
        };

        $scope.taxIdValidation = function() {
            // Valid tax IDs for the US are in the format of either 12-1234567
            var isEmptyTaxId = !$scope.data.addBrandForm.taxID && $scope.data.addBrandForm.taxID !== null;
            if($scope.data.addBrandForm.taxIDCountry === 'US') {
                if(!isEmptyTaxId) {
                    // var match = $scope.data.addBrandForm.taxID.match(/^(\d{2}\-\d{7})+$|^(\d{3}\-\d{2}\-\d{4})+$/);   //12-1234567 or 123-12-1234
                    var match = $scope.data.addBrandForm.taxID.match(/^(\d{2}\-\d{7})+$/);
                    $scope.data.addBrandForm.isTaxIdValid = Boolean(match);
                }
            } else $scope.data.addBrandForm.isTaxIdValid = true;
        };

        $scope.isValidSelect = function(field, parent) {
            return (parent) ? ($scope.data.addNumberPoolForm[parent][field] !== null) : ($scope.data.addNumberPoolForm[field] !== null);
        };

        $scope.isValidBrandSelect = function(field, parent) {
            return (parent) ? ($scope.data.addBrandForm[parent][field] !== null) : ($scope.data.addBrandForm[field] !== null);
        };

        $scope.selectChangeColor = function(id) {
            $('#' + id).removeClass('placeholder');
        };

        $scope.addPoolModalInsertTextToEditor = function(text) {
            $scope.quill.insertText($scope.quill.getSelection(true).index, text);
        };

        $scope.addPoolModalInsertTextToEditor2 = function(text) {
            $scope.quill2.insertText($scope.quill2.getSelection(true).index, text);
        };

        $scope.addPoolModalGetStringFromEditor = function() {
            return $scope.quill.getText();
        };

        $scope.addPoolModalGetStringFromEditor2 = function() {
            return $scope.quill2.getText();
        };

        $scope.addPoolModalSetEditorText = function(value, source) {
            $scope.quill.setText(value, source || 'api');
        };

        $scope.addPoolModalSetEditorText2 = function(value, source) {
            $scope.quill2.setText(value, source || 'api');
        };

        $scope.quill = new Quill('#editor-container', {
            modules: {
                syntax: true,
                toolbar: '#toolbar-container'
            },
            placeholder: 'Compose your text message. You must include "STOP" opt-out instructions on every message (click the "opt-out text" button above to insert it)...',
            theme: 'snow'
        });

        $scope.quill2 = new Quill('#editor-container2', {
            modules: {
                syntax: true,
                toolbar: '#toolbar-container2'
            },
            placeholder: 'Compose your text message. You must include "STOP" opt-out instructions on every message (click the "opt-out text" button above to insert it)...',
            theme: 'snow'
        });

        $scope.quill.on('text-change', function() {
            $scope.$evalAsync();
            $scope._isSmsEditorValid();
        });

        $scope.quill2.on('text-change', function() {
            $scope.$evalAsync();
            $scope._isSmsEditorValid2();
        });

        $scope.addPoolModalFindBannedKeywords = function(str) {
            var messageIncludeBannedKeywords = false;
            var lowerStr = str.toLowerCase();
            _.forEach($scope.bannedKeywords, function(keyword) {
                if(messageIncludeBannedKeywords) return;
                messageIncludeBannedKeywords = _.includes(lowerStr, keyword.toLowerCase());
            });
            return messageIncludeBannedKeywords;
        };

        $scope.addPoolModalFindStopKeywords = function(str) {
            return _.includes(str.toLowerCase(), 'stop');
        };

        $scope._isSmsEditorValid = function() {
            var editorText = ($scope.addPoolModalGetStringFromEditor() || '').trim();
            $scope.data.addNumberPoolForm.isSmsEditorIncludeBannedKeywords = $scope.addPoolModalFindBannedKeywords(editorText);
            $scope.data.addNumberPoolForm.isSmsEditorIncludeStopKeywords = $scope.addPoolModalFindStopKeywords(editorText);
            $scope.data.addNumberPoolForm.campaign.sampleMessage = editorText;
            $scope.data.addNumberPoolForm.campaign.messageLength = Math.max(0, editorText.length);
        };

        $scope._isSmsEditorValid2 = function() {
            var editorText = ($scope.addPoolModalGetStringFromEditor2() || '').trim();
            $scope.data.addNumberPoolForm.isSmsEditorIncludeBannedKeywords2 = $scope.addPoolModalFindBannedKeywords(editorText);
            $scope.data.addNumberPoolForm.isSmsEditorIncludeStopKeywords2 = $scope.addPoolModalFindStopKeywords(editorText);
            $scope.data.addNumberPoolForm.campaign.sampleMessage2 = editorText;
            $scope.data.addNumberPoolForm.campaign.messageLength2 = Math.max(0, editorText.length);
        };

        $scope.isSmsEditorValid = function() {
            $scope._isSmsEditorValid();
            return ($scope.data.addNumberPoolForm.campaign.messageLength > 0 &&
               !$scope.data.addNumberPoolForm.isSmsEditorIncludeBannedKeywords &&
               $scope.data.addNumberPoolForm.isSmsEditorIncludeStopKeywords);
        };

        $scope.isSmsEditorValid2 = function() {
            $scope._isSmsEditorValid2();
            return ($scope.data.addNumberPoolForm.campaign.messageLength2 > 0 &&
               !$scope.data.addNumberPoolForm.isSmsEditorIncludeBannedKeywords2 &&
               $scope.data.addNumberPoolForm.isSmsEditorIncludeStopKeywords2);
        };

        $scope.changedHelpMessage = function() {
            $scope.data.addNumberPoolForm.isHelpMessageValid = undefined;
            $scope.data.helpMessageErrorMessage = '';
        };

        $scope.changedCTAMessage = function() {
            $scope.data.addNumberPoolForm.isCTAMessageValid = undefined;
            $scope.data.ctaMessageErrorMessage = '';
        };

        $scope.changedPrivacyMessage = function() {
            $scope.data.addNumberPoolForm.isPrivacyMessageValid = undefined;
            $scope.data.privacyMessageErrorMessage = '';
        };

        $scope.changedTermsMessage = function() {
            $scope.data.addNumberPoolForm.isTermsMessageValid = undefined;
            $scope.data.termsMessageErrorMessage = '';
        };

        $scope.changedDescription = function() {
            $scope.data.addNumberPoolForm.isDescriptionValid = undefined;
            $scope.data.descriptionErrorMessage = '';
        };

        $scope.changedOptInPolicy = function() {
            $scope.data.addNumberPoolForm.isOptInValid = undefined;
            $scope.data.optInErrorMessage = '';
        };

        $scope.validateHelpMessage = function(type, helpMessage) {
            if(type === 'sms') {
                let helpMessageLength = (!helpMessage || helpMessage === null || helpMessage === '') ? 0 : helpMessage.length;
                if(helpMessageLength === 0) {
                    $scope.data.helpMessageErrorMessage = "This field is required.";
                } else if(helpMessageLength <= 5) {
                    $scope.data.helpMessageErrorMessage = "Message length can't be less than 5 characters";
                }
                return (helpMessageLength === 0 || helpMessageLength <= 5) ? false : true;
            }
        };

        $scope.validateOptInPolicy = function(type, optInPolicy) {
            if(type === 'sms') {
                let optInPolicyLength = (!optInPolicy || optInPolicy === null || optInPolicy === '') ? 0 : optInPolicy.length;
                if(optInPolicyLength === 0) {
                    $scope.data.optInErrorMessage = "This field is required.";
                } else if(optInPolicyLength < 40) {
                    $scope.data.optInErrorMessage = "Opt-In flow length can't be less than 40 characters";
                }
                return (optInPolicyLength === 0 || optInPolicyLength < 40) ? false : true;
            }
        };

        $scope.changedBrandOptInPolicy = function() {
            $scope.data.addBrandForm.isOptInValid = undefined;
            $scope.data.brandOptInErrorMessage = '';

            let cannedOptInValid = $scope.data.addBrandForm.opt_in_ebr ||
                $scope.data.addBrandForm.opt_in_wet_signature ||
                $scope.data.addBrandForm.opt_in_webform ||
                $scope.data.addBrandForm.opt_in_social_ad ||
                $scope.data.addBrandForm.opt_in_sms ||
                $scope.data.addBrandForm.opt_in_ivr ||
                $scope.data.addBrandForm.opt_in_recorded_audio ||
                $scope.data.addBrandForm.opt_in_no_opt_in;

            let optInValid = $scope.data.addBrandForm.opt_in_other ? $scope.validateBrandOptInPolicy($scope.data.addBrandForm.opt_in_flow) : false;

            $scope.data.addBrandForm.cannedOptInValid = !!(optInValid || cannedOptInValid);
        };

        $scope.validateBrandOptInPolicy = function(optInPolicy) {
            let optInPolicyLength = (!optInPolicy || optInPolicy === null || optInPolicy === '') ? 0 : optInPolicy.length;
            if(optInPolicyLength === 0) {
                $scope.data.brandOptInErrorMessage = "This field is required.";
            } else if(optInPolicyLength < 40) {
                $scope.data.brandOptInErrorMessage = "Opt-In flow length can't be less than 40 characters";
            }
            return (optInPolicyLength === 0 || optInPolicyLength < 40) ? false : true;
        };

        $scope.validateDescription = function(type, description) {
            if(type === 'sms') {
                let descriptionLength = (!description || description === null || description === '') ? 0 : description.length;
                if(descriptionLength === 0) {
                    $scope.data.descriptionErrorMessage = "This field is required.";
                } else if(descriptionLength < 40) {
                    $scope.data.descriptionErrorMessage = "Description length can't be less than 40 characters";
                }
                return (descriptionLength === 0 || descriptionLength < 40) ? false : true;
            }
        };

        $scope.validateCTAMessage = function(type, ctaMessage) {
            if(type === 'sms') {
                let ctaMessageLength = (!ctaMessage || ctaMessage === null || ctaMessage === '') ? 0 : ctaMessage.length;
                if(ctaMessageLength === 0) {
                    $scope.data.ctaMessageErrorMessage = "This field is required.";
                } else if(ctaMessageLength <= 5) {
                    $scope.data.ctaMessageErrorMessage = "Message length can't be less than 5 characters";
                }
                return (ctaMessageLength === 0 || ctaMessageLength <= 5) ? false : true;
            }
        };

        $scope.validatePrivacyMessage = function(type, privacyMessage) {
            if(type === 'sms') {
                let privacyMessageLength = (!privacyMessage || privacyMessage === null || privacyMessage === '') ? 0 : privacyMessage.length;
                if(privacyMessageLength === 0) {
                    $scope.data.privacyMessageErrorMessage = "This field is required.";
                } else if(privacyMessageLength <= 5) {
                    $scope.data.privacyMessageErrorMessage = "Message length can't be less than 5 characters";
                }
                return (privacyMessageLength === 0 || privacyMessageLength <= 5) ? false : true;
            }
        };

        $scope.validateTermsMessage = function(type, termsMessage) {
            if(type === 'sms') {
                let termsMessageLength = (!termsMessage || termsMessage === null || termsMessage === '') ? 0 : termsMessage.length;
                if(termsMessageLength === 0) {
                    $scope.data.termsMessageErrorMessage = "This field is required.";
                } else if(termsMessageLength <= 5) {
                    $scope.data.termsMessageErrorMessage = "Message length can't be less than 5 characters";
                }
                return (termsMessageLength === 0 || termsMessageLength <= 5) ? false : true;
            }
        };

        $scope.validatePoolForm = function() {

            let form = $scope.data.addNumberPoolForm || {};

            let useCase = form.campaign.useCase || {};

            form.isHelpMessageValid = $scope.validateHelpMessage(useCase.serviceType, form.campaign.helpMessage);
            form.isTermsMessageValid = $scope.validateTermsMessage(useCase.serviceType, form.campaign.termsMessage);
            form.isPrivacyMessageValid = $scope.validatePrivacyMessage(useCase.serviceType, form.campaign.privacyMessage);
            form.isCTAMessageValid = $scope.validateCTAMessage(useCase.serviceType, form.campaign.ctaMessage);
            form.isOptInValid = $scope.validateOptInPolicy(useCase.serviceType, form.campaign.optInPolicy);
            form.isDescriptionValid = $scope.validateDescription(useCase.serviceType, form.poolDescription);

            let messagesValidSmsType = $scope.isSmsEditorValid() && $scope.isSmsEditorValid2() && form.isHelpMessageValid && form.isTermsMessageValid && form.isPrivacyMessageValid && form.isCTAMessageValid && form.isOptInValid && form.isDescriptionValid;
            let messagesValid = (useCase.serviceType !== 'sms') ? ($scope.isSmsEditorValid() && $scope.isSmsEditorValid2()) : messagesValidSmsType;

            let checkboxes = useCase.id === 'BYOC' || (form.agree && form.billing && form.rented && form.optin);
            let brand = $scope.isValidSelect('brand_id');
            let valid10DLC = (!useCase.is10DLC || messagesValid) && brand;

            return (valid10DLC && useCase.id && checkboxes);
        };


        $scope.addPoolModalSubmitSuccess = function(alertMessage) {
            swal(alertMessage.title, alertMessage.body, "success");
            $scope.loading.addPoolNumber = false;
            $scope.data.addNumberPoolForm.formSubmitted = false;
            $('#addNumberPoolModal').modal('hide');
            // $scope.loadNumberPools();
            $scope.$apply();
        };

        $scope.addPoolModalSubmitError = function(alertMessage) {
            swal(alertMessage.title, alertMessage.body, "error");
            $scope.loading.addPoolNumber = false;
            $scope.data.addNumberPoolForm.formSubmitted = false;
            $('#addNumberPoolModal').modal('hide');
            $scope.$apply();
        };

        $scope.addNumberPoolModalSubmitForm = function() {
            $scope.data.addNumberPoolForm.formSubmitted = true;

            console.log($scope.data.addNumberPoolForm);

            if($scope.validatePoolForm()) {
                $scope.loading.addPoolNumber = true;

                let form = $scope.data.addNumberPoolForm;
                let useCase = form.campaign.useCase || {};
                let messages = useCase.is10DLC ? [form.campaign.sampleMessage, form.campaign.sampleMessage2] : null;

                let req = {
                    team_id: authService.getTeamId(),
                    user_id: authService.getUserId(),

                    brand_id: form.brand_id,

                    name: form.poolName || 'Conversational Texting',
                    description: form.poolDescription || null,

                    use_case: useCase.id,
                    is_10DLC: useCase.is10DLC,
                    service_type: useCase.serviceType,

                    messages: messages,
                    help_message: form.campaign.helpMessage,
                    cta_message: form.campaign.ctaMessage,
                    custom_cta_message: null,
                    terms_message: form.campaign.termsMessage,
                    privacy_message: form.campaign.privacyMessage,
                    opt_in_flow: form.campaign.optInPolicy,
                    embedded_url: form.campaign.embeddedUrl,
                    embedded_phone: form.campaign.embeddedPhone,
                    direct_lending: form.campaign.directLending,
                    affiliate_marketing: form.campaign.affiliateMarketing,
                    age_gate: form.campaign.ageGate,
                    frequency: form.campaign.frequency
                };

                accountService.registerCampaign(req)
                   .then(results => {
                       $scope.data.pollBrands = true;
                       $scope.loading.addPoolNumber = false;
                       $scope.addPoolModalSubmitSuccess({title: 'Success!', body: "We requested carrier approval.  Approvals typically take 3-7 business days but may take longer depending on factors outside of our control."});
                   })
                   .catch(err => {
                       console.log(err);
                       $scope.loading.addPoolNumber = false;
                       swal("Fail", "Failed to request approval.  Please double check your info and try again.", "error");
                       $scope.$apply();
                   });
            }
        };

        $scope.addBrandModalIsValidPhoneNumber = function() {
            $scope.data.addBrandForm.isPhoneNumberValid = $scope.iti.isValidNumber();
        };

        $scope.addBrandModalFormatPhone = function() {
            if($scope.iti) {
                $scope.addBrandModalIsValidPhoneNumber();
                $scope.data.addBrandForm.authorizedRepresentative.phoneNumber = $scope.data.addBrandForm.isPhoneNumberValid ? $scope.iti.getNumber(window.intlTelInput.utils.numberFormat.E164) : '';
            }
        };

        $scope.validateBrandForm = function() {

            let spValid = ($scope.data.addBrandForm.entityType === 'SOLE_PROPRIETOR' || ($scope.data.addBrandForm.isTaxIdValid && ($scope.data.addBrandForm.incorporationState !== null)));

            let cannedOptInValid = $scope.data.addBrandForm.opt_in_ebr ||
                $scope.data.addBrandForm.opt_in_wet_signature ||
                $scope.data.addBrandForm.opt_in_webform ||
                $scope.data.addBrandForm.opt_in_social_ad ||
                $scope.data.addBrandForm.opt_in_sms ||
                $scope.data.addBrandForm.opt_in_ivr ||
                $scope.data.addBrandForm.opt_in_recorded_audio ||
                $scope.data.addBrandForm.opt_in_no_opt_in;

            let optInValid = $scope.data.addBrandForm.opt_in_other ? $scope.validateBrandOptInPolicy($scope.data.addBrandForm.opt_in_flow) : false;

            $scope.data.addBrandForm.cannedOptInValid = !!(optInValid || cannedOptInValid);

            return ($scope.addBrandForm.$valid &&
                spValid &&
                (optInValid || cannedOptInValid) &&
                ($scope.data.addBrandForm.vertical !== null) &&
                ($scope.data.addBrandForm.entityType !== null) &&
                $scope.data.addBrandForm.isPhoneNumberValid &&
                $scope.data.addBrandForm.confirm &&
                $scope.data.addBrandForm.truth &&
                $scope.data.addBrandForm.tcr);
        };

        $scope.addBrandModalSubmitSuccess = function(alertMessage) {
            swal(alertMessage.title, alertMessage.body, "success");
            $scope.loading.addPoolNumber = false;
            $scope.data.addBrandForm.formSubmitted = false;
            $('#addBrandModal').modal('hide');
            $scope.$apply();
        };

        $scope.addBrandModalSubmitError = function(alertMessage) {
            swal(alertMessage.title, alertMessage.body, "error");
            $scope.loading.addBrand = false;
            $scope.data.addBrandForm.formSubmitted = false;
            $('#addBrandModal').modal('hide');
            $scope.$apply();
        };

        $scope.sendVerifications = async function(form) {
            var req = {
                user_id: authService.getUserId(),
                team_id: authService.getTeamId(),
                first_name: form.authorizedRepresentative.firstName,
                last_name: form.authorizedRepresentative.lastName,
                email: form.authorizedRepresentative.email,
                phone: form.authorizedRepresentative.phoneNumber,
            };

            return accountService.sendVerifications(req)
                .then(results => {
                    if (results && results.success) {
                        $rootScope.user.team.vouched_invite_id = results.vouched_invite_id;
                        $rootScope.secureLS.set('user', $rootScope.user);

                        return { success: true };
                    } else {
                        return results;
                    }
                })
            .catch(err => {
                console.log(err);
                $scope.$apply();
                swal('Error', 'Unable to verify code at this time. Please try later', 'error');
            });
        };

        $scope.addBrandSubmitForm = function() {
            $scope.data.addBrandForm.formSubmitted = true;
            $scope.addBrandModalFormatPhone();

            if ($scope.validateBrandForm()) {
                $scope.sendVerifications($scope.data.addBrandForm)
                .then(res => {
                    if (res && (res.success || res.error === 'invites already sent')) {
                        $scope.loading.addPoolNumber = true;
                        let form= $scope.data.addBrandForm;
                        let optInFlow= "";
                        if (form.opt_in_ebr) {
                            optInFlow += $scope.staticText.addBrandModal.opt_in_ebr + '; ';
                        }
                        if (form.opt_in_wet_signature) {
                            optInFlow += $scope.staticText.addBrandModal.opt_in_wet_signature + '; ';
                        }
                        if (form.opt_in_webform) {
                            optInFlow += $scope.staticText.addBrandModal.opt_in_webform + '; ';
                        }
                        if (form.opt_in_social_ad) {
                            optInFlow += $scope.staticText.addBrandModal.opt_in_social_ad + '; ';
                        }
                        if (form.opt_in_sms) {
                            optInFlow += $scope.staticText.addBrandModal.opt_in_sms + '; ';
                        }
                        if (form.opt_in_ivr) {
                            optInFlow += $scope.staticText.addBrandModal.opt_in_ivr + '; ';
                        }
                        if (form.opt_in_recorded_audio) {
                            optInFlow += $scope.staticText.addBrandModal.opt_in_recorded_audio + '; ';
                        }
                        if (form.opt_in_no_opt_in) {
                            optInFlow += $scope.staticText.addBrandModal.opt_in_no_opt_in + '; ';
                        }
                        if (form.opt_in_other) {
                            optInFlow += form.opt_in_flow;
                        }
                        let req = {
                            team_id: authService.getTeamId(),
                            user_id: authService.getUserId(),
                            entity_type: form.entityType,
                            vertical: (form.vertical || {}).id,
                            company_name: form.name,
                            dba_name: form.dbaName,
                            website: form.websiteUrl,
                            tax_id: form.taxID,
                            tax_id_country: form.taxIDCountry,
                            alt_business_id: form.altBusinessId,
                            alt_business_id_type: form.altBusinessIdType,
                            stock_symbol: form.stockSymbol,
                            stock_exchange: form.stockExchange,
                            incorporation_state: (form.incorporationState || {}).code,
                            address1: form.physicalAddress.streetAddress,
                            address2: form.physicalAddress.streetAddressLine2,
                            city: form.physicalAddress.city,
                            state: (form.physicalAddress.state || {}).code,
                            postal_code: form.physicalAddress.postalCode,
                            country: form.physicalAddress.country,
                            first_name: form.authorizedRepresentative.firstName,
                            last_name: form.authorizedRepresentative.lastName,
                            business_title: form.authorizedRepresentative.businessTitle,
                            email: form.authorizedRepresentative.email,
                            phone_number: form.authorizedRepresentative.phoneNumber,
                            opt_in_flow: optInFlow
                        };
                        accountService.registerBrand(req)
                        .then(results => {
                            $scope.data.pollBrands = true;
                            $scope.addBrandModalSubmitSuccess({title: 'Success!', body: "We submitted your brand registration.\n\nWe will notify you once your verification is complete.\n(usually less than 24 hours)"});
                        })
                        .catch(err => {
                            console.log(err);
                            $scope.loading.addPoolNumber = false;
                            swal("Fail", "Failed to register your brand.  Please double check your info and try again.", "error");
                            $scope.$apply();
                        });
                    } else {
                        $scope.loading.addPoolNumber = false;
                        swal("Fail", "Failed to register your brand.  Please double check your info and try again.", "error");
                        $scope.$apply();
                    }
                })
                .catch(err => {
                    console.log(err);
                    $scope.loading.addPoolNumber = false;
                    swal("Fail", "Failed to register your brand.  Please double check your info and try again.", "error");
                    $scope.$apply();
                });
            }
        };

        $scope.addBrandModalInitCountrySelects = function() {
            $.getJSON("https://common-pricing.s3.us-east-1.amazonaws.com/messageplans.json", function(json) {
                var countryCodes = Object.keys(json).map(function(x){ return x.toLowerCase(); });

                $("#country").countrySelect({
                    defaultCountry: $scope.data.addBrandForm.physicalAddress.country.toLowerCase(),
                    onlyCountries: countryCodes,
                    preferredCountries: ['us'],
                    responsiveDropdown: true
                });
                $("#taxIdCountry").countrySelect({
                    defaultCountry: $scope.data.addBrandForm.taxIDCountry.toLowerCase(),
                    onlyCountries: countryCodes,
                    preferredCountries: ['us'],
                    responsiveDropdown: true
                });
                $scope.$apply();
            });

            $('#country').on('change', function() {
                $scope.data.addBrandForm.physicalAddress.country = $("#country").countrySelect("getSelectedCountryData").iso2.toUpperCase();
                $scope.$apply();
            });
            $('#taxIdCountry').on('change', function() {
                $scope.data.addBrandForm.taxIDCountry = $("#taxIdCountry").countrySelect("getSelectedCountryData").iso2.toUpperCase();
                $scope.taxIdValidation();
                $scope.$apply();
            });
        };

        $scope.addBrand = function() {
            $scope.addBrandModalClearForm();
            $scope.addBrandModalInitCountrySelects();
            $scope.addBrandModalPhoneNumberMask();
            if ($scope.addBrandForm && typeof $scope.addBrandForm.$setPristine === 'function') {
                $scope.addBrandForm.$setPristine();
            }
            $('#addBrandModal').modal('show');
            $("#state .selectize-input > input").attr('autocomplete', 'new-state-select');
            $("#useCase .selectize-input > input").attr('autocomplete', 'new-use-case');
            $("#vertical .selectize-input > input").attr('autocomplete', 'new-vertical');

            if($scope.data.states.length === 0) {
                $http.get('assets/content/states.json')
                   .then(function(res){
                       $scope.data.states = res.data;
                   });
            }

            $('#addBrandModal').on('hidden.bs.modal', function () {
                $scope.addBrandModalClearForm();
                $scope.loadBrands();
            });
        };

        $scope.addUcassCampaign = function(brand) {
            $scope.addPoolModalClearForm();
            $scope.data.addNumberPoolForm.brand_id = (brand || {}).brand_id;
            $scope.data.addNumberPoolForm.campaign.useCase = null;//$scope.ucassUseCase;
            $scope.useCases = $scope.ucassUseCases;
            $('#addNumberPoolModal').modal('show');
        };

        $scope.addBulkCampaign = function(brand) {
            $scope.addPoolModalClearForm();
            $scope.data.addNumberPoolForm.brand_id = (brand || {}).brand_id;
            $scope.data.addNumberPoolForm.campaign.useCase = null;//$scope.mixedUseCase;
            $scope.useCases = $scope.bulkUseCases;
            $('#addNumberPoolModal').modal('show');
        };

        $scope.addPoolModalClearForm = function() {

            $scope.data.addNumberPoolForm = {
                formSubmitted: false,
                brand_id: null,
                force_brand: null,
                poolName: null,
                poolDescription: null,
                isSmsEditorIncludeBannedKeywords: undefined,
                isSmsEditorIncludeStopKeywords: undefined,
                isSmsEditorIncludeBannedKeywords2: undefined,
                isSmsEditorIncludeStopKeywords2: undefined,
                isCTAMessageValid: undefined,
                isHelpMessageValid: undefined,
                isTermsMessageValid: undefined,
                isPrivacyMessageValid: undefined,
                isOptInValid:  undefined,
                isDescriptionValid:  undefined,
                campaign: {
                    useCases: [],
                    embeddedUrl: false,
                    embeddedPhone: false,
                    directLending: false,
                    affiliateMarketing: false,
                    ageGate: false,
                    sampleMessage: null,
                    helpMessage: null,
                    optInPolicy: null,
                    ctaMessage: null,
                    customCtaMessage: null,
                    termsMessage: null,
                    privacyMessage: null,
                    frequency: 1,
                    messageLength: 0,
                    messageLength2: 0,
                    serviceType: 'sms'
                },
                agree: false,
                billing: false
            };
        };

        $scope.pickNumbers = function(pool) {

            $scope.data.selectedNumbers = [];
            $scope.data.numbers = [];
            $scope.data.area_code = '';
            $scope.data.picker_pool_id = pool.pool_id;

            if (pool.pricing.byoc) {
                $('#numberUploadModal').modal('show');
            }
            else {
                $('#numberPickerModal').modal('show');
            }
        }

        $scope.clearIti = function() {
            $scope.iti.setNumber('');
            $scope.data.addBrandForm.isPhoneNumberValid = undefined;
        };

        $scope.addBrandModalClearForm = function()  {

            $scope.data.addBrandForm = {
                formSubmitted: false,
                isPhoneNumberValid: undefined,
                isTaxIdValid: undefined,
                isOptInValid: undefined,
                name: null,
                dbaName: null,
                entityType: null,
                incorporationState: null,
                vertical: null,
                websiteUrl: null,
                taxID: null,
                taxIDCountry: 'US',
                altBusinessId: null,
                altBusinessIdType: "NONE",
                stockSymbol: null,
                stockExchange: null,
                physicalAddress: {
                    streetAddress: null,
                    streetAddressLine2: null,
                    city: null,
                    state: null,
                    postalCode: null,
                    country: 'US'
                },
                authorizedRepresentative: {
                    firstName: null,
                    lastName: null,
                    email: null,
                    businessTitle: null,
                    phoneNumber: null
                },
                confirm: false,
                tcr: false,
                truth: false,
                opt_in_flow: null,
                opt_in_ebr: false,
                opt_in_wet_signature: false,
                opt_in_webform: false,
                opt_in_social_ad: false,
                opt_in_sms: false,
                opt_in_ivr: false,
                opt_in_recorded_audio: false,
                opt_in_other: false,
                opt_in_no_opt_in: false,
                cannedOptInValid: false
            };
        };

        $scope.formatPhone = function(value) {
            const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
            var numberFormat = ($scope.data.iso_country_code === 'US' || $scope.data.iso_country_code === 'CA') ? libphonenumber.PhoneNumberFormat.NATIONAL : libphonenumber.PhoneNumberFormat.INTERNATIONAL

            var phoneLocale = value;

            try {
                var firstSymbol = (value.charAt(0) === '+') ? '' : '+';
                var parsed = phoneUtil.parse(firstSymbol + value, $scope.data.iso_country_code);
                if (parsed) {
                    phoneLocale = phoneUtil.format(parsed, numberFormat);
                }
            }
            catch(e) {
                console.log(e);
            }
            return phoneLocale;
        };

        $scope.setIsoCountry = function(value) {
            $scope.data.iso_country_code = value;
        };

        $scope.loadBrands = function() {

            if ($scope.data.pollBrands) {

                let req = {
                    team_id: authService.getTeamId(),
                    user_id: authService.getUserId()
                };

                accountService.getBrands(req)
                   .then(results => {
                       let brands = results || [];
                       $rootScope.no_client_opt_in = brands.filter(brand => brand.opt_in_flow && brand.opt_in_flow.includes('I don\'t have opt-in from my contacts')).length > 0;
                       $scope.data.brands = brands;
                       $scope.loading.brands = false;
                       $scope.data.pollBrands = false;

                       let previousCount = $scope.data.registeredBrandCount || 0;
                       $scope.data.registeredBrandCount = 0;

                       for (let i = 0; i < brands.length; i++) {
                           let brand = brands[i];
                           let status = brand.identity_status;
                           if (brand.registered && (status === 'VERIFIED' || status === 'VETTED_VERIFIED' || status === 'SELF_DECLARED')) {
                               brand.verified = true;
                           }

                           if (brand.verified) {
                               $scope.data.registeredBrandCount++;
                           }
                       }

                       if (previousCount !== $scope.data.registeredBrandCount) {
                           $rootScope.loadBrands();
                       }

                       $scope.$apply();
                   })
                   .catch(err => {
                       console.log(err);
                   });
            }
        }

        $scope.$on('$destroy', function() {
            $interval.cancel($scope.brandPolling);
        });
        $scope.$on('complete_brand_registration', function(event, args) {
            $scope.addBrand();
        });

        $scope.onInit = function() {
            $scope.loadBrands();

            $scope.brandPolling = $interval($scope.loadBrands, 15000);
            $scope.bannedKeywords = $rootScope.bannedKeywords;
        };

        if (authService.hasAccount()) {
            $scope.onInit();
        }
        else {
            $scope.$on('auth_complete', function(event, args) {
                $scope.onInit();
            });
        }
    }
    module.exports = TrustCenterController;
})();
