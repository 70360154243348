(function() {
    'use strict';

    function LeftSidebarController(authService, phoneService, userService, $rootScope, $scope, $window, $location, tasksService) {
        var vm = this;
        vm.authService = authService;

        $scope.notifications = {
            newTask: {type: 'task', icon: 'fa-regular fa-envelope'},
            inboundCall: { type: 'call', icon: 'fa-regular fa-phone shake-animation' }
        };

        $scope.staticData = {
            navItems: [
                { title: 'SERVICE DASHBOARDS'},
                { key: 'inbox', href: 'inbox', icon: 'fa-inbox-in', title: 'Inbox' },
                { key: 'buckets', href: 'buckets', icon: 'fa-objects-align-top', title: 'Buckets' },
                { key: 'outboundCalling', href: 'dash', icon: 'fa-phone-arrow-up-right', title: 'Outbound Calling' },
                { key: 'rvmCampaigns', href: 'bulk-campaigns', icon: 'fa-voicemail', title: 'RVM Campaigns' },
                { key: 'callTracking', href: 'tracking', icon: 'fa-chart-network', title: 'Call Tracking' },
                { key: 'agentActivity', href: 'activity', icon: 'fa-chart-user', title: 'Agent Activity' },
                { title: 'MY DATA'},
                { key: 'contacts', href: 'contacts', icon: 'fa-people-group', title: 'Contacts' },
                { key: 'dnc', href: 'dnc', icon: 'fa-ban', title: 'DNC' },
                { title: 'MANAGE'},
                { key: 'lists', href: 'lists', icon: 'fa-images-user', title: 'Lists' },
                { key: 'recordings', href: 'recordings', icon: 'fa-microphone', title: 'Recordings' },
                { key: 'voices', href: 'voices', icon: 'fa-volume-up', title: 'Voices' },
                { key: 'phoneNumbers', href: 'phone-numbers', icon: 'fa-phone-office', title: 'Phone Numbers' },
                { key: 'callRouting', href: 'call-routing', icon: 'fa-route', title: 'Call Routing' },
                { key: 'scripts', href: 'scripts', icon: 'fa-scroll-old', title: 'Scripts' },
                { key: 'macros', href: 'sms-macros', icon: 'fa-message-lines', title: 'Macros', futureFeatures: true },
                { key: 'chatBot', href: 'sms-autoresponders', icon: 'fa-message-bot', title: 'Chat Bot', futureFeatures: true },
                { key: 'email', href: 'email', icon: 'fa-envelope', title: 'Email' },
                { key: 'automation', href: 'automation', title: 'Automation' },
                { key: 'tags', href: 'tags', icon: 'fa-tags', title: 'Tags' },
                { key: 'webForms', href: 'webforms', icon: 'fa-pen-field', title: 'Web Forms' },
                { key: 'byoc', href: 'byoc', icon: 'fa-inbox', title: 'BYOC' }
            ]
        };

        $scope.data = {
            access_number: '',
            user_pin: '',
            iso_country_code: 'US',
            user_routing_location: '',
            inboxNotification: null,
            currentLocationHash: '',
            activeNav: $scope.staticData.navItems,
            inactiveNav: [],
            bottomNavOpen: false,
            expandButtonText: 'Try new features!'
        };
        $scope.loading = {
            accessNumber: true,
            userPin: true
        };

        $scope.global = $rootScope;

        $scope.unseen = {
            inboundCalls: 0,
            calls: 0,
            sms: 0,
            vms: 0,
            fax: 0,
            email: 0,
            note: 0,
            total: 0
        };

        $scope.showFutureFeatures = ($rootScope.settings.RUNTIME_ENV === 'local');

        $scope.fractionCents = function(value, precision) {
            if (precision > 2) {
                var money = $scope.formatMoney(value, precision);
                return money.substr(-(precision - 2));
            }
            return '';
        };
        
        $scope.formatMoney = function(value, precision) {
           return (value || 0).toFixed(precision || 2);
        };

        $scope.formatPhone = function(value) {
            return $rootScope.libPhoneFormatPhone(value);
        };
        
        $scope.cents = function(value, precision) {
            var money = $scope.formatMoney(value, precision);
            if (precision > 2) {
                return money.substr(0, money.length - (precision - 2));
            }
            return money;
        };

        $scope.isDialerPlan = function() {
            return $rootScope.isDialerPlan();
        };
        
        $scope.dollarsToCents = function(value, precision) {
            return (value * 100).toFixed(precision || 1);
        };
        
        $scope.openLocation = function(location) {
            $location.path('/' + location);
        };

        $scope.toggleBottomNav = function() {
            if ($scope.data.bottomNavOpen) {
                    $scope.data.expandButtonText = 'Try new features!';
                } else {
                    $scope.data.expandButtonText = 'Too many cool features';
                }
            $scope.data.bottomNavOpen = !$scope.data.bottomNavOpen;
        };

        $scope.getDialInPhone = function() {

            const request = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                dial_in: true
            };

            phoneService.getNumbers(request)
                .then(results => {

                    var accessNumber = (results.numbers || [])[0];
                    if(accessNumber){
                        $scope.data.access_number = $scope.formatPhone(accessNumber.phone_number);
                        $rootScope.setAccessNumber(accessNumber.phone_number);
                    }

                    $scope.loading.accessNumber = false;
                    $scope.$apply();
                })
                .catch(err => {
                    console.error(err);
                    $scope.$apply();
                });
        };

        $scope.pinRequired = function() {
            const user = authService.getUser();
            const dialin = ((user || {}).team || {}).dialin || {};
            return dialin.require_pin;
        };

        $scope.getUserPin = function() {

            const userReq = {
                target_user_id: authService.getUserId(),
                team_id: authService.getTeamId(),
                user_id: authService.getUserId()
            };

            userService.getUser(userReq)
                .then(user => {

                    $scope.data.user_pin = user.auth.pin;
                    $scope.data.user_routing_location = user.routing_location;
                    $scope.loading.userPin = false;
                    $scope.$apply();
                })
                .catch(err => {
                    console.error(err);
                    $scope.$apply();
                });
        };

        $scope.hasPermissions = function(entity, action) {
            return $rootScope.hasPermissions(entity, action);
        };

        $scope.setInboxNotification = function(notification) {
            $scope.data.inboxNotification = notification;
            $rootScope.$broadcast('show.nav.notification.badge', (notification === null || $scope.data.currentLocationHash === '#/inbox') ? false : true);
        };

        $scope.showInboxNotificationBadge = function(type) {
            let showNotification = (type === 'inboundCall') ? $scope.notifications.inboundCall : $scope.notifications.newTask;
            $scope.setInboxNotification(showNotification);
        };

        $scope.callNotificationEventHandler = function() {
            $scope.setInboxNotification(null);
            $scope.getUnseenTasks();
            $scope.$apply();
        };

        $scope.taskNotificationEventHandler = function(type) {
            $scope.showInboxNotificationBadge(type);
            $scope.getUnseenTasks();

            let audioFunc = (type === 'inboundCall') ? 'playInboundCallNotification' : 'playTaskReceivedNotification';
            $rootScope[audioFunc]();
            $scope.$apply();
        };

        $scope.$on('sms.received', function(e, ablyEvent) {
            $scope.taskNotificationEventHandler('sms');
        });

        $scope.$on('voicemail.received', function (e, ablyEvent) {
            $scope.taskNotificationEventHandler('voicemail');
        });

        $scope.$on('fax.received', function(e, ablyEvent) {
            $scope.taskNotificationEventHandler('fax');
        });

        $scope.$on('email.received', function (e, ablyEvent) {
            $scope.taskNotificationEventHandler('email');
        });

        $scope.$on('note.created', function (e, ablyEvent) {
            $scope.taskNotificationEventHandler('note');
        });

        $scope.$on('ivr.queued', function (e, ablyEvent) {
            $scope.taskNotificationEventHandler('inboundCall');
        });

        $scope.$on('task.call.answer', function(e, ablyEvent) {
            $scope.callNotificationEventHandler();
        });
        $scope.$on('task.call.hangup', function(e, ablyEvent) {
            $scope.callNotificationEventHandler();
        });

        $scope.$on('call.inbound.missed', function(e) {
            $scope.taskNotificationEventHandler('missedCall');
        });

        $scope.$on('task.created', function (e, ablyEvent) {
            if (ablyEvent.data.task_type === 'inbound_call') {
                $scope.taskNotificationEventHandler('task');
            }
        });

        $scope.setMenuItemActive = function(pageUrl) {
            const cutPageUrl = pageUrl.split('/').slice(0, 2).join('/');
            $('ul#sidebarnav a.main-menu-item').removeClass('active').parent().removeClass('active');
            $('ul#sidebarnav a.main-menu-item[href="' + cutPageUrl + '"]').addClass('active').parent().addClass('active');
        };

        $scope.isDefaultCallerIdPhoneNumber = function(phoneNumber) {
            return $scope.global.root_data.default_caller_id.phone_number === phoneNumber;
        };

        $rootScope.$on('$stateChangeStart', function(e, toState, toParams, fromState, fromParams) {
            $scope.data.currentLocationHash = '#' + toState.url;
            $scope.setMenuItemActive($scope.data.currentLocationHash);
            $scope.getUnseenTasks();
        });

        $rootScope.$on('dialIn.changed', function(e, toState, toParams, fromState, fromParams) {
            $scope.getDialInPhone();
        });

        $scope.updateInboxNotificationBadge = function() {
            if($scope.unseen.inboundCalls > 0) {
                $scope.showInboxNotificationBadge('inboundCall');
            } else if($scope.unseen.total > 0) {
                $scope.showInboxNotificationBadge('message');
            } else {
                $scope.setInboxNotification(null);
            }
        };

        $scope.getUnseenTasks = function() {
            if(!_.isEmpty(authService.getUser())) {
                Promise.all([
                    tasksService.getUnreadTasksCount(),
                    phoneService.getNumberOfCallsInQueue({team_id: authService.getTeamId(), user_id: authService.getUserId()}),
                ])
                    .then(([tasksCounts, callsCount]) => {
                        // Possible tasks types
                        // "email",
                        // "fax",
                        // "inbound_call",
                        // "missed_call",
                        // "note",
                        // "sms",
                        // "voicemail"

                        // $scope.unseen.inboundCalls = callsCount.total || 0;
                        $scope.unseen.calls = (tasksCounts.inbound_call || 0) + (tasksCounts.missed_call || 0);
                        $scope.unseen.email = tasksCounts.email || 0;
                        $scope.unseen.fax = tasksCounts.fax || 0;
                        $scope.unseen.note = tasksCounts.note || 0;
                        $scope.unseen.sms = tasksCounts.sms || 0;
                        $scope.unseen.vms = tasksCounts.voicemail || 0;
                        $scope.unseen.total = tasksCounts.total || 0;

                        $scope.updateInboxNotificationBadge();
                        $scope.$apply();
                    });
            }
        };

        $scope.splitNav = function(newNav) {
            let nav = newNav;
            if (!nav) {
                const { user_preferences } = $rootScope.secureLS.get('user');
                if (user_preferences) {
                    nav = user_preferences.nav;
                }
            }

            if (nav) {
                $scope.data.activeNav = $scope.staticData.navItems.filter(function(navItem) {
                    return !!(!navItem.key || nav[navItem.key]);
                });
                $scope.data.inactiveNav = $scope.staticData.navItems.filter(function(navItem) {
                    return !navItem.key || !nav[navItem.key];
                });
            }
        };

        $scope.$on('mark.as.read.notification.badge', function (e, task) {
            if(!task.unread) {
                $scope.getUnseenTasks();
            }
        });

        $scope.$on('inbox-reload', function (e, task) {
            $scope.getUnseenTasks();
        });

        $scope.$on('my-dashboard:removeFromNav', function (e, newNav) {
            $scope.splitNav(newNav);
        });

        $scope.$on('my-dashboard:addToNav', function (e, newNav) {
            $scope.splitNav(newNav);
        });
        
        $scope.onInit = function() {
            $(".right-side-toggle").click(function () {
                $(".right-sidebar").slideDown(50);
                $(".right-sidebar").toggleClass("shw-rside");
            });
            $scope.data.currentLocationHash = window.location.hash;
            $scope.setMenuItemActive($scope.data.currentLocationHash);

            $scope.getDialInPhone();
            $scope.getUserPin();
            $scope.getUnseenTasks();
            $scope.splitNav();

            setTimeout(function(){
                $('.scroll-sidebar').slimScroll({
                    position: 'left',
                    size: '5px',
                    height: '100%',
                    color: '#dcdcdc'
                });
            }, 0);

            // check if user_routing_location was changed
            $scope.$on('client.user.changed', function(event, userData) {
                $scope.data.user_routing_location = userData.routing_location;
                $scope.$apply();
            });
        };

        if (authService.hasAccount()) {
            $scope.onInit();
        }
        else {
            $scope.$on('auth_complete', function(event, args) {
                $scope.onInit();
            });
        }
    }
    module.exports = LeftSidebarController;
})();
