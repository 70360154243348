(function () {
    'use strict';
    
    function TemplateService(apiService, $rootScope) {
        return {
            getTemplates: function (request) {
                return apiService.get($rootScope.settings.API_TEMPLATE_URL + '/template', request);
            },

            createTemplate: function (request) {
                return apiService.post($rootScope.settings.API_TEMPLATE_URL + '/template', request);
            },

            updateTemplate: function (request) {
                return apiService.post($rootScope.settings.API_TEMPLATE_URL + '/template/' + request.template_id, request);
            },

            deleteTemplate: function (request) {
                return apiService.del($rootScope.settings.API_TEMPLATE_URL + '/template/' + request.template_id, request);
            },

            getTemplate: function (request) {
                return apiService.get($rootScope.settings.API_TEMPLATE_URL + '/template/' + request.template_id, request);
            },

            merge: function (request) {
                return apiService.get($rootScope.settings.API_TEMPLATE_URL + '/template/' + request.template_id + '/merge', request);
            },
        };
    }
    module.exports = TemplateService;
})();




