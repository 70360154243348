(function() {
    'use strict';

    function TimelineTextPlaceholderListItemController(authService) {
        var vm = this;
        vm.authService = authService;

    }
    module.exports = TimelineTextPlaceholderListItemController;
})();
