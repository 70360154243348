(function() {
    'use strict';

    function UserNumbersModalController(authService, userService, $rootScope, $scope, $location, $window) {
        var vm = this;
        vm.authService = authService;

        $scope.forms = {
            office: {
                number: '',
                submited: false,
                is_valid_phone_number: undefined,
            },
            mobile: {
                number: '',
                submited: false,
                is_valid_phone_number: undefined,
            },
            home: {
                number: '',
                submited: false,
                is_valid_phone_number: undefined,
            }
        };

        $scope.saveUserNumbers = function() {

            let req = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                updated_user_id: authService.getUserId(),
                office_phone: $scope.forms.office.number,
                mobile_phone: $scope.forms.mobile.number,
                home_phone: $scope.forms.home.number
            };

            userService.updateUser(req)
                .then(user => {
                    $scope.updateUserCookie(user);
                    $("#user-numbers-modal").modal('hide');
                    $location.path('/phone-number-purchase').search({setup: true});
                    $scope.$apply();
                })
                .catch(err => {
                    $("#user-numbers-modal").modal('hide');
                });
        };

        $scope.updateUserCookie = function(user) {

            let userCookie = $rootScope.secureLS.get('user');
            if(userCookie.user_id === user.user_id){
                userCookie.needs_setup = false;
                $rootScope.secureLS.set('user', userCookie);
            }
        };

        $scope.modalPhoneNumberMask = function(number, type) {
            var phoneNumberId = $('#' + type);
            var phoneNumberIdInput = document.querySelector('#' + type);

            if(phoneNumberId) {
                $scope.forms[type].iti = window.intlTelInput(phoneNumberIdInput, {
                    nationalMode: 'false',
                    autoPlaceholder: 'aggressive',
                    separateDialCode: 'true',
                    validationNumberTypes: [
                        'FIXED_LINE',
                        'MOBILE',
                        'FIXED_LINE_OR_MOBILE',
                        'TOLL_FREE',
                        'PREMIUM_RATE',
                        'SHARED_COST',
                        'VOIP',
                        'PERSONAL_NUMBER',
                        'PAGER',
                        'UAN',
                        'VOICEMAIL',
                        'UNKNOWN'
                    ],
                    initialCountry: 'us',
                    // intl-tel-input is up to date as of dec 2024
                    loadUtils: () => import("https://cdn.jsdelivr.net/npm/intl-tel-input@25.2.0/build/js/utils.js"),
                    customPlaceholder: function (selectedCountryPlaceholder, selectedCountryData) {
                        const pl = selectedCountryPlaceholder.replace(/[0-9]/g, '0');
                        phoneNumberId.attr('maxlength', pl.length);
                        $scope.setPhoneMask(phoneNumberId, pl, pl);
                        return pl;
                    }
                });

                if(number){
                    $scope.forms[type].iti.setNumber(number);
                }

                phoneNumberId.on('countrychange', function(e) {
                    $scope.forms[type].iti.setNumber('');
                    phoneNumberId.unmask();

                    var mask = e.target.placeholder.replace(/[0-9]/g, '0');
                    phoneNumberId.attr('maxlength', mask.length);
                    $scope.setPhoneMask(phoneNumberId, mask, e.target.placeholder);
                });
            }
        };

        $scope.formatPhone = function(type) {
            if($scope.forms[type].iti) {
                $scope.forms[type].is_valid_phone_number = $scope.forms[type].iti.isValidNumber();
                $scope.forms[type].number = $scope.forms[type].is_valid_phone_number ? $scope.forms[type].iti.getNumber(window.intlTelInput.utils.numberFormat.E164) : '';
                $scope.validateFields(type);
            }
        };

        $scope.validateFields = function(type) {
            $scope.forms[type].is_valid = !!($scope.forms[type].to_is_valid_phone_number && $scope.forms[type].from && $scope.forms[type].compliance);
        };

        $scope.clearIti = function(type) {
            $scope.forms[type].is_valid_phone_number = undefined;
            $scope.forms[type].iti.setNumber('');
            $('#' + type).unmask();
            $scope[typeIti].destroy();
        };

        $scope.setSelectedPhone = function(number) {

            $scope.adHocCallModal.phoneSelected = number;
            $scope.$apply();
        };

        $scope.setPhoneMask = function(elem, mask, placeholder) {
            elem.mask(mask, {
                placeholder: placeholder
            });
        };

        $scope.onInit = function() {

            $( "#user-numbers-modal" ).on('shown.bs.modal', function() {
                setTimeout(function () {
                    for (var type in $scope.forms){
                        $scope.modalPhoneNumberMask('', type);
                    }
                }, 300);
            });
        };

        if (authService.hasAccount()) {
            $scope.onInit();
        }
        else {
            $scope.$on('auth_complete', function(event, args) {
                $scope.onInit();
            });
        }
    }
    module.exports = UserNumbersModalController;
})();
