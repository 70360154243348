(function() {
    'use strict';

    function PhoneNumbersController($rootScope, $scope, $location, authService, phoneService, accountService, $window, generalHelperService) {
        let vm = this;
        vm.authService = authService;

        $rootScope.viewData = $rootScope.viewData || {};
        $rootScope.viewData.breadcrumbs = $rootScope.viewData.breadcrumbs || [];
        $rootScope.viewData.breadcrumbs = [{
            title: 'Phone Numbers',
            link_name: 'Phone Numbers',
            link_url: '#/phone-numbers',
            help_url: $rootScope.help_url
        }];

        $scope.staticContent = {
            KYCVerification: {
                subtitle: 'toll-free and local phone numbers',
                title: 'Get started with voice calling and text messaging',
                description: `Become a verified sender and gain access to toll-free or local phone numbers within the ${$rootScope.settings.APP_NAME} app. Verification is now a mandatory industry wide process required before your account can send/receive calls or text messages.`,
                description2: 'Start the process now - it\'s easy and only takes a few minutes to get your account verified.',
                btn: {
                    title: 'Get Verified Now',
                    href: '#/trust-center'
                }
            },
            BYOC: {
                subtitle: 'Already Have a Provider?',
                title: 'Bring Your Own Carrier',
                description: `Connect your existing Twilio, Commio, or Telnyx accounts and enjoy calling and texting from directly within the ${$rootScope.settings.APP_NAME} app. Contact us today to learn more about this seemless integration.`,
                btn: {
                    title: 'Coming Soon',
                    href: '#/byoc'
                }
            },
            logos: {
                callCowboy: $rootScope.settings.APP_LOGO,
                commio: 'assets/logos/commio.webp',
                twilio: 'assets/logos/twilio.png',
                telnyx: 'assets/logos/telnyx.png'
            },
            loading: 'Loading',
            phoneNumbers: {
                title: 'Phone Numbers',
                subtitle: 'Enhance your business with a toll-free number or increase conversions with a local phone number',
                newPhoneNumberbtn: {
                    title: 'Add Phone Number',
                    href: '#/phone-number-purchase'
                },
                table: {
                    header: ['Phone Number', 'Name', 'Call Route', 'SMS Use Case', 'SMS Autoresponder'],
                    actions: ['Edit Call Routing', 'Edit SMS Responder', 'Delete Number'],
                    noRes: ['You don\'t have any phone numbers.',  'Click the ', '\'Add Phone Number\'', ' button to get one.'],
                    loading: 'Loading'
                },
                selectPlaceholder: 'Please select...'
            }
        };

        $scope.data = {
            numbers: [],
            brands: [],
            campaign_map: {},
            limits: {
                used: 0,
                team_limit: 0
            },
            remaning_numbers_limit: 3,
            sms_ivrs: {},
            voice_ivrs: {},
            phone_features: {
                voice_only: {
                    label: 'Voice Only',
                    id: 'voice_only',
                },
                voice_and_sms: {
                    label: 'Voice + SMS',
                    id: 'voice_and_sms',
                },
                fax_only: {
                    label: 'Fax Only',
                    id: 'fax_only',
                }
            },
            info: {
                display: undefined,
                message: {
                    part1: 'You don’t have any ',
                    part2: ' and ',
                    part3: ' setup yet. Do you want to add one?'
                },
                // sms: {
                //     part1: 'To enable SMS for phone numbers you need to register a brand in the trust center.'
                // },
                soleProprietorLimit: {
                    part1: 'You can enable SMS for 5 phone numbers only.'
                },
                limits: {
                    part1: 'You currently have purchased  ',
                    part2: ' of your ',
                    part3: 'phone number limit. In order to increase your limit, please add more users.'
                },
                // voiceIvrsLink: {
                //     display: undefined,
                //     href: '#/call-routing',
                //     text: 'Call Routing'
                // },
                // smsIvrsLink: {
                //     display: undefined,
                //     href: '#/sms-autoresponders',
                //     text: 'SMS Autoresponders'
                // },
                pendingPurchaseLink: {
                    display: undefined,
                    href: '#/subscriptions',
                    info: 'You have pending subscription changes.',
                    text: 'Proceed to Checkout.'
                },
                // vouchNeeded: {
                //     display: undefined,
                //     info: 'Verify your identity to enable calling, texting, and purchase of phone numbers.',
                //     text: 'Verify Now.'
                // },
                // vouchSent: {
                //     display: undefined,
                //     href: $rootScope.verify_learn_url,
                //     info: 'Identity verification sent. Please check your email or text messages.',
                //     text: 'Learn more.'
                // },
                // vouchReceived: {
                //     display: undefined,
                //     href: $rootScope.verify_learn_url,
                //     info: 'Identity verification received, waiting for review (this may take several hours).',
                //     text: 'Learn more.'
                // }
            },
            iso_country_code: 'US'
        };
        
        $scope.loading = {
            checkingView: false,
            numbers: true,
            ivrs: true,
            features: false,
            voiceIVRs: false,
            smsIVRs: false,
            enableCampaign: false
        };

        $scope.confirmDelete = function(num, callback) {
            let req = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                number: num.phone_number
            };

            phoneService.unrentNumber(req)
                .then(results => {
                    $rootScope.team_phone_numbers = _.remove($rootScope.team_phone_numbers, function (n) {
                        return n.phone_number !== num.phone_number;
                    });
                    callback(null);
                })
                .catch(err => {
                    console.log(err);
                    callback(err);
                });
        };

        $scope.deleteNumber = function($event, num) {
            if ($event) {
                $event.preventDefault();
            }

            swal({
                title: 'Confirm',
                text: 'Are you sure you want to delete this Phone Number?',
                buttons: ['No', 'Yes'],
                dangerMode: true,
                closeOnClickOutside: false
            }).then( confirm => {
                if (confirm) {
                    $scope.loading.numbers = true;

                    $scope.confirmDelete(num, function(res) {
                        if(res === null) {
                            $scope.loadNumbers();
                            swal('Deleted!', 'This Phone Number has been deleted!', 'success');
                        } else { swal('Cancelled', 'Unable to delete Phone Number', 'error'); }

                        $scope.loading.numbers = false;
                        $scope.$apply();
                    });
                }
            });
        };

        $scope.confirmEnableCampaign = function(num, brand, campaign) {
            $scope.loading.enableCampaign = true;

            let req = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                phone_number: num.phone_number,
                campaign_id: campaign.campaign_id
            };

            accountService.addCampaignToNumber(req)
               .then(results => {
                   $scope.loading.enableCampaign = false;

                   let errMsg = (results || {}).err;
                   if (errMsg) {
                       swal('Error', errMsg, 'error');
                   }
                   else {
                       num.campaign_id = campaign.campaign_id;
                       swal('Submitted!', 'We successfully requested that the carriers enable SMS for this phone number.', 'success');
                   }

                   $scope.$apply();
                   $scope.loadBrands();
               })
               .catch(err => {
                   console.log(err);
                   swal('Cancelled', 'Unable to enable SMS for this phone number.', 'error');
                   $scope.loading.enableCampaign = false;
                   $scope.$apply();
               });
        };

        $scope.enableCampaign = function(num, brand, campaign) {

            swal({
                title: 'Please Confirm',
                text: "You are assigning " + campaign.name + " to\n" + $scope.formatPhone(num.phone_number) + ". This is a permanent change.\nCarriers take approximately 5-7 business days to enable your phone number on their network.\nDo you want to continue?",
                buttons: ['No', 'Yes'],
                closeOnClickOutside: false
            }).then( isConfirm => {
                if (isConfirm) {
                    $scope.confirmEnableCampaign(num, brand, campaign);
                }
            });
        };

        $scope.hasPermissions = function(entity, action) {
            return $rootScope.hasPermissions(entity, action);
        };

        // $scope.editSmsIVR = function(num) {
        //     if(num.sms_ivr_id) {
        //         $location.path('sms-config').search('id=' + num.sms_ivr_id);
        //     }
        // };

        $scope.editVoiceIVR = function(num) {
            if(num.voice_ivr_id) {
                $location.path('/ivr-config').search('id=' + num.voice_ivr_id);
            }
        };

        // $scope.showVerificationModal = function(e) {
        //     e.preventDefault();
        //     $rootScope.showVerificationModal();
        // };

        // $scope.setDialIn = function(num) {
        //     let req = {
        //         team_id: authService.getTeamId(),
        //         user_id: authService.getUserId(),
        //         number: num.phone_number
        //     };
        //
        //     phoneService.setDialIn(req)
        //         .then(results => {
        //             swal("Dial-in", "Number " + num.phone_number + " has been set as dial-in number", "success");
        //         })
        //         .catch(err => {
        //             swal("Dial-in", "Failed to set dial-in number", "error");
        //         });
        // };

        $scope.formatPhone = function(value) {
            return $rootScope.libPhoneFormatPhone(value);
        };

        // $scope.getSmsIVRName = function(ivrId) {
        //     let index = _.findIndex($scope.data.sms_ivrs, ['ivr_id', ivrId]);
        //     if(index > -1) {
        //         return $scope.data.sms_ivrs[index].name || 'Default';
        //     }
        //     return 'Default Autoresponder';
        // };

        $scope.getVoiceIVRName = function(num) {
            if (num.fax) {
                return 'Fax';
            }

            let index = _.findIndex($scope.data.voice_ivrs, ['ivr_id', num.voice_ivr_id]);
            if(index > -1) {
                return $scope.data.voice_ivrs[index].name || 'Call Route';
            }
            return 'Default';
        };

        $scope.getCampaignName = function(campaignId) {
            let campaign = $scope.data.campaign_map[campaignId];
            let placeholder = ($scope.hasPermissions('dial', 'can_edit')) ? $scope.staticContent.phoneNumbers.selectPlaceholder : 'None';
            return (campaign || {}).name || placeholder;
        };

        // $scope.getFeatureLabel = function(id) {
        //     if($scope.data.phone_features[id])
        //         return $scope.data.phone_features[id].label || 'None';
        //     return 'None';
        // };

        $scope.updatePhoneNumber = function(req, callback) {
            phoneService.updateNumber(req)
                .then(results => {
                    callback(null);
                })
                .catch(err => {
                    console.log(err);
                    swal('Cancelled', 'Unable to update Phone Number.', 'error');
                    callback(err);
                });
        };

        // $scope.updateFeature = function(num, featureId) {
        //     $scope.loading.features = true;
        //
        //     let req = {
        //         team_id: num.team_id,
        //         user_id: authService.getUserId(),
        //         number: num.phone_number,
        //         features: num.features
        //     };
        //
        //     $scope.updatePhoneNumber(req, function(res) {
        //         if(res === null) {
        //             const prevFeature = num.features;
        //             num.features = featureId;
        //         }
        //         if (featureId === 'voice_and_sms') {
        //             $scope.data.limits.sms_enabled += 1;
        //         }
        //         if (prevFeature === 'voice_and_sms') {
        //             $scope.data.limits.sms_enabled -= 1;
        //         }
        //         $scope.loading.features = false;
        //         $scope.$apply();
        //     });
        // };

        // $scope.updateSmsIVR = function(num, ivr) {
        //     $scope.loading.smsIVRs = true;
        //
        //     let req = {
        //         team_id: num.team_id,
        //         user_id: authService.getUserId(),
        //         number: num.phone_number,
        //         sms_ivr_id: num.sms_ivr_id
        //     };
        //
        //     $scope.updatePhoneNumber(req, function(res) {
        //         if(res === null) {
        //             num.sms_ivr_id = ivr.ivr_id;
        //         }
        //         $scope.loading.smsIVRs = false;
        //         $scope.$apply();
        //     });
        // };

        $scope.updateVoiceIVR = function(num, ivr, fax) {
            $scope.loading.voiceIVRs = true;

            let ivrId = fax ? null : ivr.ivr_id;

            let req = {
                team_id: num.team_id,
                user_id: authService.getUserId(),
                number: num.phone_number,
                voice_ivr_id: ivrId,
                features: fax ? $scope.data.phone_features.fax_only.id : $scope.data.phone_features.voice_and_sms.id,
                fax: fax
            };

            $scope.updatePhoneNumber(req, function(res) {
                if(res === null) {
                    num.fax = fax;
                    num.voice_ivr_id = ivrId;
                }
                $scope.loading.voiceIVRs = false;
                $scope.$apply();
            });
        };

        $scope.updatePhoneNumberName = function(num) {
            let req = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                number: num.phone_number,
                name: num.name
            };
            $scope.updatePhoneNumber(req, function(res) {});
        };

        $scope.newNameValue = '';
        $scope.editNameField = function(num) {
            if($scope.newNameValue !== num.name) {
                $scope.newNameValue = num.name;
                $scope.updatePhoneNumberName(num);
            }
        };

        $scope.checkPhoneNumberName = function(name) {
            if (!name || name.length === 0) {
                return "Phone Number Name must have at least 1 character.";
            }
        };

        $scope.isObjectEmpty = function(obj) {
            return _.isEmpty(obj);
        };

        $scope.smsUseCaseEmpty = function() {
            let isEmpty = _.map($scope.data.brands, (brand)=> {
                return !brand.bulk_campaigns[0] && !brand.ucaas_campaigns[0];
            });
            return !_.includes(isEmpty, false);
        };

        // $scope.checkIvrs = function() {
        //     $scope.data.info.display = ($scope.data.voice_ivrs.length === 0 || $scope.data.sms_ivrs.length === 0) ? true :false;
        //     $scope.data.info.voiceIvrsLink.display = ($scope.data.voice_ivrs.length === 0) ? true : false;
        //     $scope.data.info.smsIvrsLink.display = ($scope.data.sms_ivrs.length === 0) ? true : false;
        // };

        $scope.loadIvrs = function() {

            return new Promise((resolve, reject) => {

                const req = {
                    team_id: authService.getTeamId(),
                    user_id: authService.getUserId()
                };

                phoneService.getIvrs(req)
                   .then(results => {
                       let tempVoice = _.filter(results, ['type', 'voice']);
                       let tempSms = _.filter(results, ['type', 'sms']);
                       $scope.data.voice_ivrs = _.sortBy(tempVoice, 'created_at').reverse();
                       $scope.data.sms_ivrs = _.sortBy(tempSms, 'created_at').reverse();

                       // $scope.checkIvrs();
                       $scope.loading.ivrs = false;
                       $scope.$apply();
                       resolve();
                   })
                   .catch(err => {
                       // eat error
                       console.log(err);
                       resolve();
                   });
            });
        };

        $scope.loadNumbers = function() {
            $scope.loading.numbers = true;

            if($rootScope.team_phone_numbers) {
                $scope.data.numbers = _.sortBy($rootScope.team_phone_numbers, 'created_at').reverse() || [];

                for (let i = 0; i < $scope.data.numbers.length; i++) {
                    let num = $scope.data.numbers[i];
                    if (num && num.pending_purchase_at) {
                        $scope.data.info.pendingPurchaseLink.display = true;
                        break;
                    }
                }

                $scope.loading.numbers = false;
            }

            if($rootScope.number_limit) {
                let limits = $rootScope.number_limit;
                $scope.data.limits.used = limits.used;
                $scope.data.limits.sms_enabled = limits.sms_enabled;
                $scope.data.limits.team_limit = limits.team_limit;
                $scope.data.limits.warning = (limits.team_limit - limits.used) <= $scope.data.remaning_numbers_limit;
            }

            return Promise.resolve();
        };

        $scope.loadBrands = function() {

            return new Promise((resolve, reject) => {
                const request = {
                    team_id: authService.getTeamId(),
                    user_id: authService.getUserId(),
                    only_shared_campaigns: "1"
                };

                return accountService.getBrands(request)
                   .then(results => {
                       let brands = results || [];
                       $rootScope.no_client_opt_in = brands.filter(brand => brand.opt_in_flow && brand.opt_in_flow.includes('I don\'t have opt-in from my contacts')).length > 0;

                       $scope.data.campaign_map = {};
                       for (let i = 0; i < brands.length; i++) {
                           let brand = brands[i];

                           let ucaasCampaigns = brand.ucaas_campaigns || [];
                           for (let u = 0; u < ucaasCampaigns.length; u++) {
                               let ucaasCampaign = ucaasCampaigns[u];
                               $scope.data.campaign_map[ucaasCampaign.campaign_id] = ucaasCampaign;
                           }

                           let bulkCampaigns = brand.bulk_campaigns || [];
                           for (let b = 0; b < bulkCampaigns.length; b++) {
                               let bulkCampaign = bulkCampaigns[b];
                               $scope.data.campaign_map[bulkCampaign.campaign_id] = bulkCampaign;
                           }
                       }
                       $scope.data.brands = brands;
                       $scope.$apply();
                       resolve();
                   })
                   .catch(err => {
                       //eat err
                       console.log(err);
                       resolve();
                   });
            });
        };

        $scope.$on('teamNumbersLoaded', function() {
            $scope.loadNumbers();
        });

        $scope.$on('hiddenRightSideBar', function () {
            $('.phone-numbers-section').removeClass('open-right-sidebar');
        });
        $scope.$on('shownRightSideBar', function () {
            $('.phone-numbers-section').addClass('open-right-sidebar');
        });

        $scope.onInit = function() {
            $window.scrollTo(0, 0);
            $scope.loading.checkingView = true;

            generalHelperService.waitForElement('#rightPanel')
                .then(rightPanel => {
                    if($(rightPanel).hasClass('shw-rside')) {
                        $('.phone-numbers-section').addClass('open-right-sidebar');
                    }
                });

            $scope.user = $rootScope.user;

            Promise.all([
                $scope.loadBrands(),
                $scope.loadNumbers(),
                $scope.loadIvrs()
            ])
                .then(()=> {
                    $scope.loading.checkingView = false;
                    $scope.$apply();
                })
               .catch(err => { console.log(err); });
        };

        if (authService.hasAccount()) {
            $scope.onInit();
        }
        else {
            $scope.$on('auth_complete', function(event, args) {
                $scope.onInit();
            });
        }
    }
    module.exports = PhoneNumbersController;
})();
