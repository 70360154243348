(function() {
    'use strict';

    function ShopifySyncSettingsModalController($rootScope, $scope, $window, $cookies, authService, integrationService) {

        var vm = this;
        vm.authService = authService;

        $scope.data = {
            shopifyData: {
                storeName: null,
            },
            syncPreference: null
        };

        $scope.loading = false;

        $scope.staticData = {
            title: 'Update Your Shopify Sync Settings',
            loader: 'Loading',
            submitButton: 'Update',
            form: {
                instruction: 'Choose how you\'d like to sync your Shopify contacts:',
                options: [
                    {
                        label: 'Two-way sync',
                        icon: 'fa-arrows-alt-h text-info',
                        value: 'two-way',
                        description: 'Sync across both platforms'
                    },
                    {
                        label: 'One-way sync (Import)',
                        icon: 'fa-cloud-arrow-down text-info',
                        value: 'one-way-import',
                        description: 'Import from Shopify'
                    },
                    {
                        label: 'One-way sync (Export)',
                        icon: 'fa-cloud-arrow-up text-info',
                        value: 'one-way-export',
                        description: 'Export to Shopify'
                    },
                    {
                        label: 'No sync',
                        icon: 'fa-ban text-danger',
                        value: 'none',
                        description: 'Manage contacts separately'
                    }
                ]
            }
        };

        $scope.submit = function() {
            $scope.loading.updateLoading = true;

            $scope.loading.updateLoading = false;
            $('#shopify-sync-settings-modal').modal('hide');
        };

        $scope.onInit = function() {
            const shopifyIntegration = integrationService.get()['shopify'];

            const shopDomain = shopifyIntegration.integration_foreign_id;
            $scope.data.shopifyData.storeName = (shopDomain || '').split('.')[0];

            if (shopifyIntegration.public_data) {
                $scope.data.syncPreference = shopifyIntegration.public_data.sync_preference || 'two-way';
            } else {
                $scope.data.syncPreference = 'two-way';
            }
        };

        if (integrationService.get()['shopify']) {
            $scope.onInit();
        }
        else {
            $scope.$on('integrations_seeded', function(event, args) {
                $scope.onInit();
            });
        }
    }

    module.exports = ShopifySyncSettingsModalController;
})();
