(function () {
    'use strict';

    function SMSconfigController($rootScope, $scope, $location, authService, phoneService) {
        var vm = this;
        vm.authService = authService;

        $rootScope.viewData = $rootScope.viewData || {};
        $rootScope.viewData.breadcrumbs = $rootScope.viewData.breadcrumbs || [];
        $rootScope.viewData.breadcrumbs = [{
            title: 'SMS Autoresponders',
            link_name: 'SMS Autoresponders',
            link_url: '#/sms-autoresponders',
            help_url: $rootScope.help_url
        }, {
            title: 'SMS Configuration',
            link_name: 'SMS Configuration',
            link_url: '#/sms-config'
        }];
    
        $scope.data = {
            sms_ivr: {
                ivr_id: null
            }
        };
        
        $scope.loading = {
            sms_rules: true
        };
        
        $scope.saving = {
            sms_rules: false
        };
        
        $scope.staticData = {
            nameBlock: {
                title: "SMS Autoresponder Name",
                subtitle: "Configure automatic replies to text messages...",
                inputPlaceholder: "Enter name"
            },
            rulesBlock: {
                title: "Inbound Text Message Rules",
                subtitle: "Define a set of rules to handle inbound text messages to this phone number",
                addRule: {
                    title: "Add Rule"
                },
                table: {
                    titleTd: {
                        whenText: "When",
                        startRule: "Inbound Text Received",
                        endRule: "No Keywords Match",
                        keywords: "Keywords Match"
                    },
                    optionDropdownTd: {
                        thenText: "Then",
                        options: ["Do Nothing", "DNC", "Forward To", "Say"]
                    },
                    optionTd: {
                        forwardTo: {
                            placeholder: "(555) 555-5555"
                        },
                        say: {
                            placeholder: "What do you want to say?"
                        }
                    },
                    actionsTd: ["Add Rule Before", "Add Rule After", "Delete Rule"]
                },
                actions: {
                    saveBtn: "Save"
                },
                loader: "Loading",
                saving: "Saving"
            }
        };

        $scope.addSmsRule = function(e, index) {
            e.preventDefault();
            $scope.data.sms_ivr.rules.splice(index, 0, {
                start_rule: false,
                action: 'Do Nothing',
                action_data: {}
            });
        };

        $scope.deleteSmsRule = function(e, index) {
            e.preventDefault();
            $scope.data.sms_ivr.rules.splice(index, 1);
        };

        $scope.formatRawPhone = function(rule) {
            const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
            var phone164 = '';
            var phoneLocale = '';
            try {
                var region; // intentionally undefined
                if ($rootScope.productCode == $rootScope.usaProductCode) {
                    region = 'US';
                }
                var numberFormat = (region === 'US' || region === 'CA') ? libphonenumber.PhoneNumberFormat.NATIONAL : libphonenumber.PhoneNumberFormat.INTERNATIONAL;

                var parsed = phoneUtil.parse(rule.action_data.raw_route_to || '', region);
                if (parsed) {
                    phone164 = phoneUtil.format(parsed, libphonenumber.PhoneNumberFormat.E164);
                    phoneLocale = phoneUtil.format(parsed, numberFormat);
                }
            }
            catch(e) {
                console.log(e);
            }

            rule.action_data.raw_route_to = phoneLocale || rule.action_data.raw_route_to;
            rule.action_data.route_to = phone164;
            rule.action_data.route_to_valid = !!phone164;
        };
        
        $scope.createSmsIvr = function(smsReq) {
            phoneService.createIvr(smsReq)
                .then(results => {
                    $scope.navToSMSautoresponders();
                })
                .catch(err => {
                    console.log(err);
                    alert('Error: Failed to save SMS Autoresponder.');
                    $scope.saving.sms_rules = false;
                    $scope.$apply();
                });
        };
        
        $scope.updateSmsIvr = function(smsReq) {
            phoneService.updateIvr(smsReq)
                .then(results => {
                    $scope.navToSMSautoresponders();
                })
                .catch(err => {
                    console.log(err);
                    alert('Error: Failed to save SMS Autoresponder.');
                    $scope.saving.sms_rules = false;
                    $scope.$apply();
                });
        };
        
        $scope.navToSMSautoresponders = function() {
            $location.path('/sms-autoresponders').search({});
            $scope.$apply();
        };

        $scope.save = function(e) {
            e.preventDefault();
            $scope.saving.sms_rules = true;
            
            var smsReq = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                ivr_id: $scope.data.sms_ivr.ivr_id,
                type: $scope.data.sms_ivr.type,
                name: $scope.data.sms_ivr.name,
                rules: $scope.data.sms_ivr.rules
            };
            if($scope.data.sms_ivr.ivr_id === null || $scope.data.sms_ivr.ivr_id === undefined) {
                $scope.createSmsIvr(smsReq);
            } else $scope.updateSmsIvr(smsReq);
        };
        
        $scope.loadSmsIvr = function(ivr_id) {
            let team = (($rootScope.account || [])[0] || {});

            let req = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                ivr_id: ivr_id
            };

            phoneService.getIvr(req)
                .then(results => {
                    if (results) {
                        $scope.data.sms_ivr = results;
                    }
                    $scope.loading.sms_rules = false;
                    $scope.$apply();
                })
                .catch(err => {
                    console.log(err);
                    $scope.loading.sms_rules = false;
                    $scope.$apply();
                });
        };
        
        $scope.initData = function() {
            $scope.data.sms_ivr.type = 'sms';
            $scope.data.sms_ivr.name = null;
            $scope.data.sms_ivr.rules = [
                {
                    start_rule: false,
                    end_rule: true,
                    action: 'Do Nothing',
                    action_data: {}
                }
            ];
            $scope.loading.sms_rules = false;
        };

        $scope.onInit = function() {
            $scope.data.sms_ivr.ivr_id = $location.search().id;
            
            if($scope.data.sms_ivr.ivr_id) {
                $scope.loadSmsIvr($scope.data.sms_ivr.ivr_id);
            } else $scope.initData();
        };

        if (authService.hasAccount()) {
            $scope.onInit();
        }
        else {
            $scope.$on('auth_complete', function(event, args) {
                $scope.onInit();
            });
        }

    }
    module.exports = SMSconfigController;
})();
