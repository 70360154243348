(function() {
    'use strict';

    function FaxModalController(authService, contactService, faxService, $rootScope, $scope, $window) {
        var vm = this;
        vm.authService = authService;

        $scope.staticData = {
            title: 'Send a Fax',
            submitBtn: 'Submit',
            sending: 'Sending',
            loading: 'Loading',
            to: {
                title: 'To',
                customContact: {
                    labels: [ 'Name', 'Company', 'Address', 'Phone Number', 'Fax Number' ]
                },
                existingContact: {
                    labels: [ 'Contact Name' ]
                }
            },
            from: {
                title: 'From',
                labels: [ 'Name', 'Phone Number', 'Fax Number' ]
            },
            upload: {
                title: 'Upload Document',
                subtitle: 'Drop the file that you want to fax below...',
                desc: '(More than 200 file formats are supported)',
                labels: [ 'Subject', 'Message' ],
                undoBtn: 'Undo Upload'
            },
            errors: {
                default: 'Field is required.',
                emptyFile: 'Drop a file.'
            }
        };
        $scope.defaultAvatar = $rootScope.settings.NO_AVATAR_IMG;

        /* Send fax UI */

        $scope.faxModal = {
            file: null,
            document: null,
            subject: '',
            message: '',
            emailStatus: false,
            addCover: false,
            sendFrom: {
                name: '',
                company: '',
                email: '',
                address: '',
                callerId: undefined,
                faxNumber: undefined,
                phoneNumber: undefined
            },
            contactType: 'existing',
            existingContact: {
                searchTerm: '',
                data: null,
                loading: false,
                notFound: false,
                invalid: false
            },
            customContact: {
                name: '',
                company: '',
                address: '',
                faxNumber: undefined
            },
            sending: false,
            formSubmitted: false
        };

        $scope.isFormValid = function() {
            var isFileValid = ($scope.faxModal.file !== null || $scope.faxModal.document !== null);
            var isFromNameValid = ($scope.faxModal.sendFrom.name.length > 0);
            return !!(isFileValid && isFromNameValid && $scope.faxModal.sendFrom.faxNumber && $scope.faxModal.sendFrom.phoneNumber);
        };

        $scope.sendFax = function() {
            // if ($scope.faxModal.sendFrom.callerId === undefined) {
            //   $scope.faxModal.sendFrom.callerId = false;
            //   return;
            // }
            // if ( $scope.faxModal.contactType == 'custom' && !$scope.phoneNumbers.toFax.isValid ) { return; }
            // if ( $scope.faxModal.contactType == 'existing' && (!$scope.faxModal.existingContact.data || !$scope.faxModal.existingContact.data.main_phone) ) {
            //     $scope.faxModal.existingContact.invalid = true;
            //     return;
            // }
            $scope.faxModal.formSubmitted = true;

            if($scope.isFormValid()) {
                $scope.faxModal.sending = true;

                var faxReq = {
                    team_id: authService.getTeamId(),
                    user_id: authService.getUserId(),
                    document_id: null,
                    contact_id: null,
                    subject: $scope.faxModal.subject,
                    message: $scope.faxModal.message,
                    email_status: $scope.faxModal.emailStatus,
                    add_cover: $scope.faxModal.addCover,
                    send_from: {
                        name: $scope.faxModal.sendFrom.name,
                        company: $scope.faxModal.sendFrom.company,
                        email: $scope.faxModal.sendFrom.email,
                        address: $scope.faxModal.sendFrom.address,
                        caller_id: $scope.faxModal.sendFrom.faxNumber,
                        phone_number: $scope.faxModal.sendFrom.phoneNumber,
                        fax_number: $scope.faxModal.sendFrom.faxNumber
                        // caller_id: $scope.faxModal.sendFrom.callerId,
                        // phone_number: $scope.phoneNumbers.fromPhone.number,
                        // fax_number: $scope.phoneNumbers.fromFax.number
                    },
                    send_to: {
                        name: '',
                        company: '',
                        address: '',
                        fax_number: ''
                    }
                };

                var contact;
                if ($scope.faxModal.contactType == 'existing') {
                    contact = $scope.faxModal.existingContact.data || {};
                    faxReq.contact_id = contact.id;
                    faxReq.send_to.name = (contact.first_name || '') +' '+ (contact.last_name || '');
                    faxReq.send_to.company = contact.company || '';
                    var address = contact.address || '';
                    if (contact['suite/apt']) { address += ', '+ contact['suite/apt']; }
                    if (contact.city) { address += ', '+ contact.city; }
                    if (contact.state) { address += ', '+ contact.state; }
                    if (contact.zip_code) { address += ', '+ contact.zip_code; }
                    if (contact.country) { address += ', '+ contact.country; }
                    faxReq.send_to.address = address;
                    faxReq.send_to.fax_number = contact.fax_number || contact.main_phone;
                } else {
                    contact = $scope.faxModal.customContact;
                    faxReq.send_to.name = contact.name;
                    faxReq.send_to.company = contact.company;
                    faxReq.send_to.address = contact.address;
                    faxReq.send_to.fax_number = $scope.phoneNumbers.toFax.number;
                }

                $scope.__getDocumentId()
                    .then(docId => {
                        faxReq.document_id = docId;
                        return faxService.sendFax(faxReq);
                    })
                    .then(results => {
                        $("#fax-modal").modal('hide');
                        $scope.cancelFax();
                        swal("Success", "Your fax is being sent.", "success");
                        if (typeof $scope.faxModal.callback == 'function') {
                            $scope.faxModal.callback();
                        }
                    })
                    .catch(err => {
                        swal("Error", err.message === 'The selected file is not a document.' ? err.message :"Your fax could not be sent. Please try again.", "error");
                        console.error(err);
                    })
                    .then(() => {
                        $scope.faxModal.sending = false;
                        $scope.$apply();
                    });
            }
        };

        $scope.__getDocumentId = function() {
            return new Promise((resolve, reject) => {

                // existing document
                if ($scope.faxModal.document) {
                    resolve($scope.faxModal.document.document_id);
                    return;
                }

                // new document (upload file)
                if (!$scope.faxModal.file) {
                    reject('No file selected');
                    return;
                }
                var docReq = {
                    team_id: authService.getTeamId(),
                    user_id: authService.getUserId(),
                    document_type: 'fax',
                    filename: $scope.faxModal.file.name,
                    content_type: $scope.faxModal.file.type
                };
                faxService.createDocument(docReq)
                    .then(result => {
                        docReq.document_id = result.document_id;
                        return $scope.__uploadFile($scope.faxModal.file, result.policy);
                    })
                    .then(result => {
                        resolve(docReq.document_id);
                    })
                    .catch(err => reject(err));

            });
        };

        $scope.__uploadFile = function(file, policy) {
            return new Promise((resolve, reject) => {
                $.ajax(policy.url, {
                    method: 'PUT',
                    contentType: 'application/octet-stream',
                    processData: false,
                    data: file
                })
                .done(function(data, textStatus, jqXHR) {
                    resolve(true);
                })
                .fail(function(jqXHR, textStatus, errorThrown) {
                    reject('Your file could not be uploaded. Please try again.');
                });
            });
        };

        /* File upload UI (dropify) */

        $scope.initFaxDropify = function() {
            setTimeout(function() {
                $('#fax-modal .dropify-fax').dropify({
                    messages: {
                        'default': 'Drag and drop a file here or click',
                        'replace': 'Drag and drop or click to replace',
                        'remove':  'Remove',
                        'error':   'Ooops, something went wrong. Try again.'
                    },
                    error: {
                        'fileExtension': 'The file format is not supported.'
                    }
                });
            }, 300);
        };

        $('#fax-modal .dropify-fax').change(function(event) {
            var target = event.currentTarget || {};
            if (target && target.files && target.files.length) {
                var file = target.files[0];
                var parts = (file.name || '').split('.');
                var ext = (parts[parts.length - 1] || '').toLowerCase();
                if ( $rootScope.settings.NOT_ALLOWED_EXTENSIONS_TO_UPLOAD.indexOf(ext) === -1 ) {
                    $scope.faxModal.file = file;
                    // $scope.__initPhonesUI();
                    $scope.$apply();
                } else {
                    swal("Error", "The file format is not supported.", "error");
                }
            }
        });

        $scope.clearUpload = function($event) {
            if ($event) { $event.preventDefault(); }
            $scope.faxModal.file = null;
            $scope.faxModal.document = null;
            angular.element('#fax-modal .dropify-clear').triggerHandler('click');
        };

        /* Phone numbers UI */
        $scope.phoneNumbers = {
            rentedNumbers: [],
            userNumbers: []
        };

        $scope.__loadNumbers = function() {
            $scope.phoneNumbers.rentedNumbers = $rootScope.team_phone_numbers.map(number => {return number.phone_number});
            $scope.phoneNumbers.userNumbers = authService.getUserNumbers();
        };

        /* Init component */
        $scope.__loadParams = function() {
            $scope.clearUpload();
            var params = $rootScope.faxModalParams || {};
            if (params.fax) {
                if (params.fax.contact && !params.contact) {
                    params.contact = params.fax.contact;
                }
                if (params.fax.document && !params.document) {
                    params.document = params.fax.document;
                }
                $scope.faxModal.subject = params.fax.subject || '';
                $scope.faxModal.message = params.fax.message || '';
                $scope.faxModal.emailStatus = !!(params.fax.email_status);
                $scope.faxModal.addCover = !!(params.fax.add_cover);
                $scope.faxModal.sendFrom.name = params.fax.send_from.name || '';
                $scope.faxModal.sendFrom.company = params.fax.send_from.company || '';
                $scope.faxModal.sendFrom.email = params.fax.send_from.email || '';
                $scope.faxModal.sendFrom.address = params.fax.send_from.address || '';
                $scope.faxModal.sendFrom.faxNumber = params.fax.send_from.fax_number || undefined;
                $scope.faxModal.sendFrom.phoneNumber = params.fax.send_from.phone_number || undefined;
                // $scope.phoneNumbers.fromPhone.number = params.fax.send_from.phone_number;
                // $scope.phoneNumbers.fromFax.number = params.fax.send_from.fax_number;
                $scope.faxModal.customContact.name = params.fax.send_to.name || '';
                $scope.faxModal.customContact.company = params.fax.send_to.company || '';
                $scope.faxModal.customContact.address = params.fax.send_to.address || '';
                // $scope.phoneNumbers.toFax.number = params.fax.send_to.fax_number || '';
                $scope.faxModal.customContact.faxNumber = params.fax.send_to.fax_number || undefined;
            }
            if (params.contact) {
                params.contact.id = params.contact.id || params.contact.contact_id;
                $scope.faxModal.existingContact.data = params.contact;
                $scope.faxModal.existingContact.searchTerm = params.contact.first_name +' '+ params.contact.last_name;
                $scope.faxModal.contactType = 'existing';
            } else {
                $scope.faxModal.existingContact.data = null;
                $scope.faxModal.existingContact.searchTerm = '';
            }
            $scope.faxModal.document = params.document || null;
            $scope.faxModal.callback = params.callback || null;
            // if ($scope.faxModal.document) {
            //     $scope.__initPhonesUI();
            // }
        };

        $scope.cancelFax = function($event) {
            if ($event) { $event.preventDefault(); }
            if($scope.faxModal.file != null || $scope.faxModal.document != null) {
                $scope.clearUpload();
            }
            $scope.faxModal.subject = '';
            $scope.faxModal.message = '';
            $scope.faxModal.emailStatus = false;
            $scope.faxModal.addCover = false;
            $scope.faxModal.sendFrom.name = '';
            $scope.faxModal.sendFrom.company = '';
            $scope.faxModal.sendFrom.email = '';
            $scope.faxModal.sendFrom.address = '';
            $scope.faxModal.sendFrom.callerId = undefined;
            $scope.faxModal.sendFrom.faxNumber = undefined;
            $scope.faxModal.sendFrom.phoneNumber = undefined;
            $scope.faxModal.contactType = 'existing';
            $scope.faxModal.existingContact.searchTerm = '';
            $scope.faxModal.existingContact.data = null;
            $scope.faxModal.existingContact.loading = false;
            $scope.faxModal.existingContact.notFound = false;
            $scope.faxModal.existingContact.invalid = false;
            $scope.faxModal.customContact.name = '';
            $scope.faxModal.customContact.company = '';
            $scope.faxModal.customContact.address = '';
            $scope.faxModal.customContact.faxNumber = undefined;
            $scope.faxModal.sending = false;
            $scope.faxModal.formSubmitted = false;
        }

        $scope.onInit = function() {
            $scope.initFaxDropify();
            $( "#fax-modal" ).on('shown.bs.modal', function() {
                $scope.__loadNumbers();
                $scope.__loadParams();

                $scope.$apply();
            });
        };

        if (authService.hasAccount()) {
            $scope.onInit();
        }
        else {
            $scope.$on('auth_complete', function(event, args) {
                $scope.onInit();
            });
        }


        // OLD VERSION: search/create contact START
        // $scope.searchContacts = function() {
        //     $scope.faxModal.existingContact.loading = true;
        //
        //     const request = {
        //         team_id: authService.getTeamId(),
        //         user_id: authService.getUserId(),
        //         search_term: $('#fax-modal .search-contact').val(),
        //         skip: 0,
        //         limit: 10
        //     };
        //     console.log(request);
        //
        //     return new Promise((resolve, reject) => {
        //         contactService.getContacts(request)
        //             .then(results => {
        //                 console.log(results);
        //                 resolve(results.contacts || []);
        //             }).catch(err => {
        //             console.error(err);
        //             resolve([]);
        //         }).then(() => {
        //             $scope.faxModal.existingContact.loading = false;
        //             $scope.$apply();
        //         });
        //     });
        // };
        //
        // $scope.contactTypeOnChange = function() {
        //     $scope.faxModal.existingContact.invalid = false;
        //     if ($scope.faxModal.contactType == 'custom') {
        //         $scope.__initPhonesUI();
        //     }
        // };
        //
        // $scope.addCoverOnChange = function() {
        //     if ($scope.faxModal.addCover) {
        //         $scope.__initPhonesUI();
        //     }
        // };
        //
        // $scope.onContactSelected = function($item) {
        //     $scope.faxModal.existingContact.invalid = false;
        //     $scope.faxModal.existingContact.data = $item;
        // };
        //
        /* Phone numbers UI */
        // $scope.phoneNumbers = {
        //     toFax: {
        //         number: '',
        //         isValid: undefined,
        //         iti: null
        //     },
        //     fromPhone: {
        //         number: '',
        //         isValid: undefined,
        //         iti: null
        //     },
        //     fromFax: {
        //         number: '',
        //         isValid: undefined,
        //         iti: null
        //     },
        //     rentedNumbers: [],
        //     userNumbers: []
        // };
        // $scope.__modalPhoneNumberMask = function(number, type) {
        //     var phoneNumberId = $('#fax-modal-'+ type +'-number');
        //
        //     if (phoneNumberId.length) {
        //         $scope.phoneNumbers[type].iti = window.intlTelInput(phoneNumberId[0], {
        //             nationalMode: 'false',
        //             autoPlaceholder: 'aggressive',
        //             separateDialCode: 'true',
        //             initialCountry: 'us',
        //             utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/11.0.11/js/utils.js" ,
        //             customPlaceholder: function(selectedCountryPlaceholder, selectedCountryData) {
        //                 var pl = selectedCountryPlaceholder.replace(/[0-9]/g, '0');
        //                 phoneNumberId.attr('maxlength', pl.length);
        //                 $scope.__setPhoneMask(phoneNumberId, pl, pl);
        //                 return pl;
        //             }
        //         });
        //
        //         if (number) {
        //             $scope.phoneNumbers[type].iti.setNumber(number);
        //         }
        //
        //         phoneNumberId.on('countrychange', function(e) {
        //             $scope.phoneNumbers[type].iti.setNumber('');
        //             phoneNumberId.unmask();
        //
        //             var mask = e.target.placeholder.replace(/[0-9]/g, '0');
        //             phoneNumberId.attr('maxlength', mask.length);
        //             $scope.__setPhoneMask(phoneNumberId, mask, e.target.placeholder);
        //         });
        //     }
        // };
        //
        // $scope.__setPhoneMask = function(elem, mask, placeholder) {
        //     elem.mask(mask, {
        //         placeholder: placeholder
        //     });
        // };
        //
        // $scope.phoneNumberOnKeyUp = function(type) {
        //     if( $scope.phoneNumbers[type].iti ) {
        //         $scope.phoneNumbers[type].isValid = $scope.phoneNumbers[type].iti.isValidNumber();
        //         $scope.phoneNumbers[type].number = ($scope.phoneNumbers[type].isValid) ? $scope.phoneNumbers[type].iti.getNumber(window.intlTelInput.utils.numberFormat.E164) : '';
        //     }
        // };
        //
        // $scope.__initPhonesUI = function() {
        //     if (!$scope.phoneNumbers.toFax.iti && $scope.faxModal.contactType == 'custom') {
        //         setTimeout(function () {
        //             $scope.__modalPhoneNumberMask($scope.phoneNumbers.toFax.number, 'toFax');
        //             $scope.__modalPhoneNumberMask($scope.phoneNumbers.toFax.number, 'toPhone');
        //         }, 300);
        //     }
        //     var coverFieldsVisible = !!($scope.faxModal.file || $scope.faxModal.document) && $scope.faxModal.addCover;
        //     if (coverFieldsVisible) {
        //         if (!$scope.phoneNumbers.fromPhone.iti) {
        //             setTimeout(function () {
        //                 $scope.__modalPhoneNumberMask($scope.phoneNumbers.fromPhone.number, 'fromPhone');
        //             }, 300);
        //         }
        //         if (!$scope.phoneNumbers.fromFax.iti) {
        //             setTimeout(function () {
        //                 $scope.__modalPhoneNumberMask($scope.phoneNumbers.fromFax.number, 'fromFax');
        //             }, 300);
        //         }
        //     }
        // };
        // OLD VERSION: search/create contact END
    }
    module.exports = FaxModalController;
})();
