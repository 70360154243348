(function() {
    'use strict';

    function CancelModalController(authService, accountService, $rootScope, $scope, $location, $window) {
        var vm = this;
        vm.authService = authService;


        $scope.cancelSubscription = function() {

            const cancelReq = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId()
            };

            accountService.cancelTeam(cancelReq)
                .then(results => {
                    $rootScope.$broadcast('cancellingSubscription');
                    $scope.hideCancelModal();
                })
                .catch(err => {
                    $scope.hideCancelModal();
                    swal('Unable to cancel', 'We were not able to cancel your subscription, please reach out to info@callcowboy.com in order to cancel.', 'error');
                });
        };

        $scope.hideCancelModal = function() {

            $("#cancel-modal").modal('hide');
        };


        $scope.onInit = function() {};

        if (authService.hasAccount()) {
            $scope.onInit();
        }
        else {
            $scope.$on('auth_complete', function(event, args) {
                $scope.onInit();
            });
        }
    }
    module.exports = CancelModalController;
})();
