(function () {
    'use strict';

    function BucketListController(
        $rootScope, $scope, $location, authService, contactService, notificationToastService, boardsService, storeDragDropContactBucketService) {
        let vm = this;

        $scope.currentList = null;

        $scope.data = {
            tags: [],
            lists: [],
            searchTerm: null,
            contactsLimit: 10
        };

        $scope.defaultAvatar = $rootScope.settings.NO_AVATAR_IMG;
        $scope.staticContent = {
            updating: 'Updating',
            listMenu: [
                { title: 'Newest First', id: 'newestFirst', withDivider: false, disableIfNoContacts: true },
                { title: 'Oldest First', id: 'oldestFirst', withDivider: false, disableIfNoContacts: true },
                { title: 'By Company Name', id: 'byCompanyName', withDivider: false, disableIfNoContacts: true },
                { title: 'By Last Name', id: 'byLastName', withDivider: false, disableIfNoContacts: true },
                { title: 'Add New Contact', id: 'addNewContact', withDivider: true, disableIfNoContacts: false },
                { title: 'Add Existing Contact', id: 'addExistingContact', withDivider: false, disableIfNoContacts: false },
                { title: 'Dial List', id: 'dialList', withDivider: false, disableIfNoContacts: true },
                { title: 'Hide List', id: 'hideList', withDivider: false, disableIfNoContacts: false }
            ],
            contactMenu: [
                'View Contact',
                'Dial',
                'Text',
                'Email',
                'Move to List',
                'Move Left',
                'Move Right',
                'Copy to List',
                'Remove from List'
            ],
            loading: 'Loading',
            noResults: 'No Results',
            addExistingContactMessage: 'Start typing to search for a contact',
            dragDropIcons: {
                move: '"\\e4e7"',
                copy: '"\\f0c5"'
            },
            dragDropIconsErrorMessage: '\"The contact is already in the list."'
        };

        $scope.listDragStart = function() {
            let listElemHeight = document.getElementById('list-container-' + $scope.currentList.list_id).getBoundingClientRect().height;
            document.documentElement.style.setProperty('--bucket-list-placeholder-height', listElemHeight + 'px');
            $rootScope.$broadcast('list.drag.start.bucket', $scope.currentList);

            $scope.closeActivePopovers();
            $rootScope.$broadcast('close.all.ui.select.menu');

            let contactsShownContactMenu = _.filter($scope.currentList.contacts, function(c) { return c.shownContactMenu; });
            _.forEach(contactsShownContactMenu, function(contact) {
                $scope._hideContactMenu(contact);
            });
        };

        $scope.listMoved = function() {
            $rootScope.$broadcast('remove.from.bucket', $scope.currentList);
        };

        $scope.listDragEnd = function() {
            $rootScope.$broadcast('dragend.bucket', $scope.currentList, 'container');
        };

        $scope.isContactInListById = function(contact, list) {
            let listIndex = _.findIndex(contact.list_ids, function(listId) {
                return listId === list.list_id;
            });

            return (listIndex > -1) ? true : false;
        };

        $scope.getContactIndex = function(contact) {
            return _.findIndex($scope.currentList.contacts, function(c) { return c.contact_id === contact.contact_id; });
        };

        $scope.addContactToListLocal = function(contact, list) {
            list.contacts.unshift(contact);
            list.isNewContactAddedTop = true;
            document.getElementById('list-scroll-' + list.list_id).scrollTop = 0;
        };

        $scope.contactDragStart = function(contact) {
            if(contact.movedOrCopied) { contact.movedOrCopied = false; }
            $scope.currentList.contactDragStart = true;

            storeDragDropContactBucketService.setContact(contact);
            storeDragDropContactBucketService.setSourceList($scope.currentList);

            $scope.closeActivePopovers();
            $scope._hideContactMenu(contact);
        };

        $scope.contactDragEnd = function() {
            $scope.currentList.contactDragStart = false;
        };

        $scope.contactDragover = function(index, external, type, dropEffect) {
            let contact = storeDragDropContactBucketService.getContact();
            let listElemHeight = document.getElementById('list-scroll-' + $scope.currentList.list_id).getBoundingClientRect().height;
            let minListContainerHeight = 97.47;

            let dragIcon = '';
            let errorMessage = '';
            let overlayColor = '#54667a';

            if(contact.sourceList !== $scope.currentList.list_id) {
                dragIcon = (dropEffect === 'move') ? $scope.staticContent.dragDropIcons.move : $scope.staticContent.dragDropIcons.copy;
                if($scope.isContactInListById(contact, $scope.currentList)) {
                    errorMessage = $scope.staticContent.dragDropIconsErrorMessage;
                    overlayColor = 'red';
                }
            }

            document.documentElement.style.setProperty('--bucket-list-contact-list-placeholder-height', ((listElemHeight < minListContainerHeight) ? minListContainerHeight : listElemHeight) + 'px');
            document.documentElement.style.setProperty('--bucket-list-drag-drop-icon', dragIcon);
            document.documentElement.style.setProperty('--bucket-list-drag-drop-message', errorMessage);
            document.documentElement.style.setProperty('--bucket-list-drag-drop-overlay-color', overlayColor);

            return (contact.sourceList !== $scope.currentList.list_id) ? true : false;
        };

        $scope.contactDrop = function(index, item, external, type, dropEffect) {
            storeDragDropContactBucketService.setDestinationList($scope.currentList);
            storeDragDropContactBucketService.setAction(dropEffect);

            if(!$scope.isContactInListById(item, $scope.currentList)) {
                $scope.addRemoveExistingContactList(item, true, $scope.currentList, false);
                $scope.addContactToListLocal(item, $scope.currentList);

                return true;
            }

            return false;
        };

        $scope.contactMoved = function(contact, index) {
            $scope.currentList.contacts.splice(index, 1);
            $scope.addRemoveExistingContactList(contact, false, $scope.currentList, false);
        };

        $scope.contactCopied = function(contact) { };

        $scope.formatPhone = (val) => {
            return $rootScope.libPhoneFormatPhone(val);
        };

        $scope.formatDateFromNow = function (timestamp) {
            moment.updateLocale('en', {
                relativeTime: {
                    future: 'in %s',
                    past: '%s',
                    s: '1s',
                    ss: '%ds',
                    m: '1m',
                    mm: '%dm',
                    h: '1h',
                    hh: '%dh',
                    d: '1d',
                    dd: '%dd',
                    M: '1mth',
                    MM: '%dmth',
                    y: '1y',
                    yy: '%dy'
                }
            });
            return moment(timestamp).fromNow();
        };

        $scope.isContactFullyVisible = function(contactElemId) {
            let scrollContainer = $('#list-scroll-' + $scope.currentList.list_id);
            let scrollContainerHeight = scrollContainer.height();

            let contactTop = $('#' + contactElemId).offset().top - scrollContainer.offset().top;
            let contactBottom = contactTop + $('#' + contactElemId).height();

            return (contactTop >= 0 && contactBottom <= scrollContainerHeight);
        };

        $scope.showContactMenu = function(contact) {
            let isContactFullyVisible = $scope.isContactFullyVisible('contact-' + $scope.currentList.list_id + '-' + contact.contact_id);

            if(!$scope.currentList.firstLoading && !$scope.currentList.loading && !$scope.currentList.contactDragStart && isContactFullyVisible) {
                let listElem = document.getElementById('list-col-' + $scope.currentList.list_id);
                let contactElem = document.getElementById('contact-' + $scope.currentList.list_id + '-' + contact.contact_id);
                let contactContainer = contactElem.querySelector('.contact-container');
                let contactMenu = document.getElementById('contact-menu-' + $scope.currentList.list_id + '-' + contact.contact_id);
                let listPosition = listElem.getBoundingClientRect();
                let contactPosition = contactElem.getBoundingClientRect();
                let xOffset = 3;

                contactContainer.style.borderRightColor = '#019efb';
                contactMenu.style.height = contactPosition.height + 'px';
                contactMenu.style.top = (contactPosition.y - listPosition.y) + 'px';
                contactMenu.style.right = (contactPosition.x - listPosition.x + xOffset) + 'px';
                contactMenu.style.display = 'block';

                contact.shownContactMenu = true;
            }
        };

        $scope._hideContactMenu = function(contact) {
            let contact_id = contact.contact_id;
            let contactElem = document.getElementById('contact-' + $scope.currentList.list_id + '-' + contact_id);
            let contactContainer = contactElem.querySelector('.contact-container');
            let contactMenu = document.getElementById('contact-menu-' + $scope.currentList.list_id + '-' + contact_id);
            contactMenu.style.display = 'none';
            contactMenu.style.top = '';
            contactMenu.style.right = '';
            contactMenu.style.height = '';
            contactContainer.style.borderRightColor = 'transparent';

            contact.shownContactMenu = false;
        };

        $scope.hideContactMenu = function(event, contact) {
            let contactMenu = document.getElementById('contact-menu-' + $scope.currentList.list_id + '-' + contact.contact_id);

            if(!contactMenu.classList.contains('show') && event.toElement !== contactMenu) {
                $scope._hideContactMenu(contact);
            }
        };

        $scope.viewDetails = function(contact) {
            $location.path('/contacts/detail').search('id=' + contact.contact_id);
        };

        $scope.textContact = function(contact) {
            $rootScope.openTextArea(contact);
        };

        $scope.callContact = function(contact) {
            $scope.closeActivePopovers();
            $rootScope.$broadcast('openCallTab', contact);
        };

        $scope.emailContact = function(contact) {
            $rootScope.openEmailArea(contact);
        };

        $scope.closeActivePopovers = function() {
            $('.popover-menu-btn.active').popover('hide');
        };

        $scope.initPopover = function(popoverId, contentId, popoverDivId, offset, placement, customClass) {
            let popoverClasses = 'popover buckets-popover-menu ' + customClass;
            let templateStr = '<div class="' + popoverClasses + '" id="' + popoverDivId + '" role="tooltip"><div class="popover-body"></div></div>';
            $('#' + popoverId).popover({
                container: 'body',
                placement: placement,
                html: true,
                content: $('#' + contentId),
                template: templateStr,
                trigger: 'click',
                offset: offset
            });
        };

        $scope.menuPopoverInitListeners = function(popoverIdStr, contentIdStr, popoverDivIdStr, parentIdStr, listId, contactId, offset, placement, customClass) {
            let contact = (contactId !== null) ? '-' + contactId : '';
            let popoverId = popoverIdStr + listId + contact;
            let contentId = contentIdStr + listId + contact;
            let popoverDivId = popoverDivIdStr + listId + contact;
            let parentId = parentIdStr + listId + contact;

            $scope.initPopover(popoverId, contentId, popoverDivId, offset, placement, customClass);

            $('#' + popoverId).on('hidden.bs.popover', function() {
                let currentContactId = $(this).attr('current-contact-id');
                let contactIndex = $scope.getContactIndex({contact_id: currentContactId});
                if(contactIndex > -1) {
                    $scope._hideContactMenu($scope.currentList.contacts[contactIndex]);
                    $scope.resetContactMenu($scope.currentList.contacts[contactIndex]);
                }
            });
            $('#' + popoverId).on('hide.bs.popover', function() {
                $('#' + parentId).removeClass('show');
                $('#' + popoverId).removeClass('active');
            });
            $('#' + popoverId).on('show.bs.popover', function() {
                $('#' + parentId).addClass('show');
                $('#' + popoverId).addClass('active');
            });
        };

        $scope.addContactPopoverMenu = function(contact) {
            $scope.menuPopoverInitListeners(
                'contact-menu-dropdown-',
                'contact-menu-popover-',
                'contact-item-popover-',
                'contact-menu-',
                $scope.currentList.list_id, contact.contact_id,
                '92px, 0', 'right', 'contact-popover');
        };

        $scope.scrollToCol = function(colId, horizontalAlign, verticalAlign) {
            let col = document.getElementById('list-col-' + colId);
            col.scrollIntoView({ behavior: 'smooth', block: verticalAlign, inline: horizontalAlign });
        };

        $scope.concatContacts = function(dest, src) {
            $scope.currentList.contacts = _.concat( dest, src );
        };

        $scope.contactTags = function(contact) {
            if (contact.tags && contact.tags.length > 0) {
                contact.tags = contact.tags.filter(contactTag => {
                    const tag = $scope.data.tags.find(availableTag => availableTag.tag_id === contactTag.tag_id);
                    if (typeof tag !== 'undefined') {
                        contactTag = Object.assign(contactTag, tag);
                        return true;
                    }
                    return false;
                });
            }
        };

        $scope.loadContacts = function(loader, skip) {
            $scope.currentList[loader] = true;

            const request = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                skip: skip || 0,
                limit: $scope.data.contactsLimit,
                list_id: $scope.currentList.list_id,
                sort: $scope.currentList.sort
            };

            if($scope.data.searchTerm && $scope.data.searchTerm !== null && $scope.data.searchTerm.length > 0) {
                request.search_term = $scope.data.searchTerm;
            }

            return contactService.getContacts(request)
                .then(contacts => {
                    let newContacts = contacts.contacts;

                    for (let c = 0; c < newContacts.length; c++) {
                        const contact = newContacts[c];
                        $scope.contactTags(contact);
                    }

                    $scope.currentList.totalItems = contacts.total_contacts || 0;
                    $scope.concatContacts($scope.currentList.contacts, newContacts);
                })
                .catch(err => { console.error(err); })
                .then(()=> {
                    $scope.currentList[loader] = false;
                    $scope.$apply();
                });
        };

        $scope.loadMoreContacts = function () {
            if(!$scope.currentList.firstLoading && !$scope.currentList.loading) {
                let total = $scope.currentList.totalItems;
                let skip = $scope.currentList.contacts.length;

                if(skip > 0 && skip < total) {
                    $scope.loadContacts('loading', skip);
                }
            }
        };

        $scope.infiniteScrollListener = function(scrollsDown) {
            $scope.closeActivePopovers();
        };

        $scope.isMoveLeftDisabled = function(contact) {
            let currentListIndex = _.findIndex($scope.data.lists, function(l) { return l.list_id === $scope.currentList.list_id; });
            return ((currentListIndex === 0) ||
                (currentListIndex > 0 && $scope.isContactInListById(contact, $scope.data.lists[currentListIndex - 1]))) ? true : false;
        };

        $scope.isMoveRightDisabled = function(contact) {
            let currentListIndex = _.findIndex($scope.data.lists, function(l) { return l.list_id === $scope.currentList.list_id; });
            return ((currentListIndex === ($scope.data.lists.length - 1)) ||
                ((currentListIndex < ($scope.data.lists.length - 1)) && $scope.isContactInListById(contact, $scope.data.lists[currentListIndex + 1]))) ? true : false;
        };

        $scope.storeMoveCopyContact = function(action, contact, toList) {
            storeDragDropContactBucketService.setContact(contact);
            storeDragDropContactBucketService.setSourceList($scope.currentList);
            storeDragDropContactBucketService.setAction(action);
            storeDragDropContactBucketService.setDestinationList(toList);
        };

        $scope._moveContact = function(contact, toList) {
            $scope.storeMoveCopyContact('move', contact, toList);

            $scope.addRemoveExistingContactList(contact, true, toList, true);
            $scope.addRemoveExistingContactList(contact, false, $scope.currentList, true);
        };

        $scope.moveLeftOrRight = function(direction, contact) {
            let currentListIndex = _.findIndex($scope.data.lists, function(l) { return l.list_id === $scope.currentList.list_id; });
            let toLeftList = (direction === 'left' && !$scope.isMoveLeftDisabled(contact)) ? $scope.data.lists[currentListIndex - 1] : null;
            let toRightList = (direction === 'right' && !$scope.isMoveRightDisabled(contact)) ? $scope.data.lists[currentListIndex + 1] : null;
            let toList = (direction === 'left') ? toLeftList : toRightList;

            $scope._moveContact(contact, toList);
        };

        $scope._copyContact = function(contact, toList) {
            $scope.storeMoveCopyContact('copy', contact, toList);
            $scope.addRemoveExistingContactList(contact, true, toList, true);
        };

        $scope.moveOrCopyContact = function(contact, toList) {
            $scope.closeActivePopovers();

            switch(contact.menuAction) {
                case 'move': $scope._moveContact(contact, toList); break;
                case 'copy': $scope._copyContact(contact, toList); break;
            }
        };

        $scope.resetContactMenu = function(contact) {
            contact.menuAction = null;
        };

        $scope.moveToListShow = function(contact) {
            contact.menuAction = 'move';
        };

        $scope.copyToListShow = function(contact) {
            contact.menuAction = 'copy';
        };

        $scope.addListIdToContact = function(contact) {
            contact.list_ids.push($scope.currentList.list_id);
        };

        $scope.removeListIdFromContact = function(contact) {
            let sourceList = storeDragDropContactBucketService.getSourceList();
            let action = storeDragDropContactBucketService.getAction();

            if(action === 'move' || action === 'remove') {
                contact.list_ids = _.remove(contact.list_ids, function (l) {
                    return l !== sourceList.list_id;
                });
            }
        };

        $scope.renderedContactsListener = function() {
            if($scope.currentList.isNewContactAddedTop) {
                $scope.currentList.isNewContactAddedTop = false;

                $scope.currentList.contacts[0].sourceList = $scope.currentList.list_id;
                $scope.addListIdToContact($scope.currentList.contacts[0]);
                $scope.removeListIdFromContact($scope.currentList.contacts[0]);

                storeDragDropContactBucketService.updateContactListIds($scope.currentList.contacts[0]);
                storeDragDropContactBucketService.clearAll();
            }

            _.forEach($scope.currentList.contacts, function(contact) {
                if(!contact.sourceList) {
                    contact.sourceList = $scope.currentList.list_id;
                }

                if(!$('#contact-menu-dropdown-' + $scope.currentList.list_id + '-' + contact.contact_id).data('bs.popover')) {
                    contact.menuAction = null;
                    $scope.addContactPopoverMenu(contact);
                }
            });
        };

        $scope.resetListMenu = function() {
            $scope.currentList.menuAction.menu = $scope.staticContent.listMenu;
            $scope.currentList.menuAction.action = null;
            $scope.currentList.menuAction.showContacts = false;
        };

        $scope.onOpenCloseListMenu = function(isOpen) {
            if(!isOpen && $scope.currentList.menuAction.showContacts) {
                $scope.resetListMenu();
            }
        };

        $scope.addNewContact = function() {
            $rootScope.$broadcast('addContactEvent', $scope.currentList.list_id);
        };

        $scope.$on('addEditContactEventDone', function(event, mode, isSuccess) {
            if(isSuccess && $scope.currentList.menuAction.action !== null && $scope.currentList.menuAction.action.id === 'addNewContact') {
                $scope.currentList.contacts = [];
                $scope.loadContacts('firstLoading');
                $scope.resetListMenu();
            }
        });

        $scope.isContactInList = function(contact) {
            return _.findIndex($scope.currentList.contacts, function(c) { return c.contact_id === contact.contact_id; });
        };

        $scope.refreshContactsListMenu = function(search) {
            if($scope.currentList.menuAction.showContacts) {
                if(search.length === 0) {
                    $scope.currentList.menuAction.menu = [];
                    return;
                }

                const request = {
                    team_id: authService.getTeamId(),
                    user_id: authService.getUserId(),
                    search_term: search
                };

                return contactService.getContacts(request)
                    .then(contacts => {
                        if($scope.currentList.menuAction.showContacts) {
                            $scope.currentList.menuAction.menu = _.sortBy(contacts.contacts, [function(c) { return $scope.isContactInList(c) === -1; }]);
                        }
                    });
            }
        };

        $scope.addExistingContact = function() {
            $scope.currentList.menuAction.menu = [];
            $scope.currentList.menuAction.showContacts = true;
        };

        $scope.dialList = function() {
            if($scope.currentList.info && !$scope.currentList.info.callable) {
                return;
            }
            $rootScope.$broadcast('startDialing:broadcast', $scope.currentList.info);
        };

        $scope.hideList = function() {
            $rootScope.$broadcast('hide.list.bucket', $scope.currentList);
        };

        $scope.listMenuCloseOnSelect = function(selected) {
            return (selected !== null && selected.id === 'addExistingContact') ? false : true;
        };

        $scope.addRemoveExistingContactList = function(contact, add, list, updateContactsList) {
            $scope.closeActivePopovers();
            list.updating = true;

            const request = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                list_id: list.list_id,
                remove_list: !add,
                contact_ids: [contact.contact_id]
            };

            return contactService.addToList(request)
                .then(results => {
                    if(add) {
                        contact.movedOrCopied = true;
                        $scope.contactTags(contact);

                        if(updateContactsList) {
                            $scope.addContactToListLocal(contact, list);
                        }

                        list.totalItems++;
                    } else {
                        if(updateContactsList) {
                            let index = _.findIndex(list.contacts, function(c) { return c.contact_id === contact.contact_id; });
                            if(index !== -1) {
                                list.contacts.splice(index, 1);
                            }
                        }
                        list.totalItems--;
                    }
                })
                .catch(err => { console.error(err); })
                .then(()=> {
                    list.updating = false;
                    $scope.$apply();
                });
        };

        $scope.selectMenuAction = function(item) {
            switch(item.id) {
                case 'newestFirst':
                case 'oldestFirst':
                case 'byCompanyName':
                case 'byLastName':
                    $scope.currentList.sort = item.id;
                    $scope.loadListById(true);
                    break;
                case 'addNewContact': $scope.addNewContact(); break;
                case 'addExistingContact': $scope.addExistingContact(); break;
                case 'dialList': $scope.dialList(); break;
                case 'hideList': $scope.hideList(); break;
                default:
                    if(!$scope.isContactInListById(item, $scope.currentList)) {
                        $scope.addRemoveExistingContactList(item, true, $scope.currentList, true);
                    } else {
                        notificationToastService.showErrorToast('fa-light fa-triangle-exclamation', $scope.staticContent.dragDropIconsErrorMessage);
                    }
            }
        };

        $scope.removeContactFromList = function(contact) {
            $scope.storeMoveCopyContact('remove', contact, $scope.currentList);
            $scope.removeListIdFromContact(contact);
            $scope.addRemoveExistingContactList(contact, false, $scope.currentList, true);

            storeDragDropContactBucketService.updateContactListIds(contact);
            storeDragDropContactBucketService.clearAll();
        };

        $scope.loadListById = function(updateListSort = false) {
            let scroll = $scope.currentList.firstLoading ? true : false;
            $scope.currentList.firstLoading = true;
            $scope.currentList.contacts = [];

            const request = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                list_id: $scope.currentList.list_id
            };

            let promises = [ $scope.loadContacts('firstLoading') ];

            if($scope.currentList && (!$scope.currentList.info || $scope.currentList.info === null)) {
                promises.unshift(contactService.getList(request));
            }

            if (updateListSort) {
                const updateListReq = {
                    team_id: authService.getTeamId(),
                    user_id: authService.getUserId(),
                    board_id: $scope.board.board_id,
                    list_to_update: {
                        list_id: $scope.currentList.list_id,
                        sort: $scope.currentList.sort
                    }
                }
                promises.push(boardsService.updateBoard(updateListReq));
            }

            return Promise.all(promises)
                .then(results => {
                    if($scope.currentList && (!$scope.currentList.info || $scope.currentList.info === null)) {
                        $scope.currentList.info = results[0];
                    }

                    if (scroll) {
                        $scope.scrollToCol($scope.currentList.list_id, 'center', 'start');
                    }

                    $scope.resetListMenu();
                })
                .catch(err => {
                    console.error(err);
                })
                .then(() => {
                    $scope.currentList.firstLoading = false;
                    $scope.$apply();
                });
        };

        $scope.$on('$stateChangeStart', function() {
            $scope.closeActivePopovers();
        });

        $scope.$on('bucket.lists.changed', function (event, lists) {
            $scope.data.lists = lists;
        });

        $scope.$on('bucket.search.contacts', function (event, search) {
            $scope.data.searchTerm = search;
            $scope.currentList.contacts = [];
            $scope.loadContacts('firstLoading');
        });

        $scope.$on('update.contact.bucket', function (event, contact) {
            let contactIndex = $scope.getContactIndex(contact);

            if(contactIndex !== -1) {
                $scope.currentList.contacts[contactIndex].list_ids = contact.list_ids;
            }
        });

        vm.$onInit = function() {
            $scope.board = Object.assign({}, vm.board);
            $scope.currentList = vm.list;
            $scope.currentList.info = vm.allLists.find(list => list.list_id === $scope.currentList.list_id);
            $scope.data.searchTerm = vm.search;
            $scope.data.tags = vm.tags;
            $scope.data.lists = vm.lists;

            $scope.currentList.menuAction = {
                menu: $scope.staticContent.listMenu,
                action: null,
                showContacts: false
            };

            $scope.loadListById();
        };
    }

    module.exports = BucketListController;
})();
