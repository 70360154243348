(function () {
    'use strict';

    function WebformsController(authService, $scope, $rootScope, contactService, $window, $location) {

        var vm = this;
        vm.authService = authService;

        $rootScope.viewData = $rootScope.viewData || {};
        $rootScope.viewData.breadcrumbs = $rootScope.viewData.breadcrumbs || [];
        $rootScope.viewData.breadcrumbs = [{
            title: 'Web Forms',
            link_name: 'Web Forms',
            link_url: '#/webforms',
            help_url: $rootScope.help_url
        }];

        $scope.staticData = {
            title: 'Web Forms',
            subtitle: 'Manage your web forms below...',
            addBtnTitle: 'Add Web Form',
            actions: ['View Web Form', 'Edit Web Form', 'Duplicate Web Form', 'Delete Web Form'],
            noRes: 'No Results',
            loading: 'Loading'
        };

        $scope.loading = {
            webforms: true
        };

        $scope.data = {
            webforms: []
        };

        $scope.totalItems = 0;
        $scope.currentPage = 1;
        $scope.itemsPerPage = 8;
        $scope.maxSize = 3;

        $scope.createWebform = function() {
            $location.path('/webforms/edit');
        };

        $scope.viewWebform = function(webform) {
            $location.path('/webforms/detail').search({webformId: webform.webform_id});
        };

        $scope.editWebform = function(webform) {
            $location.path('/webforms/edit').search({webformId: webform.webform_id});
        };

        $scope.duplicateWebform = function(webform) {
            $location.path('/webforms/edit').search({webformId: webform.webform_id, duplicate: true});
        };

        $scope.deleteWebform = function(webform) {
            swal("Delete Web Form", "Do you want to delete the web form "+ webform.webform_name +"?", { buttons: true, dangerMode: true })
                .then(confirm => {
                    if (!confirm) { return; }

                    $scope.loading.webforms = true;
                    const request = {
                        team_id: authService.getTeamId(),
                        user_id: authService.getUserId(),
                        webform_id: webform.webform_id
                    };

                    contactService.deleteWebform(request)
                        .then(results => {
                            var webforms = $scope.data.webforms || [];
                            for (var i = 0; i < webforms.length; i++) {
                                if (webforms[i].webform_id == webform.webform_id) {

                                    webforms.splice(i, 1);
                                    break;
                                }
                            }
                        })
                        .catch(err => {
                            swal("Server Error", "Our server failed to respond.  Please try again.", "error");
                        })
                        .then(() => {
                            $scope.loading.webforms = false;
                            $scope.$apply();
                        });
                });
        };

        $scope.loadWebforms = function() {
            $scope.loading.webforms = true;
            $window.scrollTo(0, 0);

            var teamId = authService.getTeamId();
            var userId = authService.getUserId();

            const request = {
                team_id: teamId,
                user_id: userId,
                skip: ($scope.currentPage - 1) * $scope.itemsPerPage,
                limit: $scope.itemsPerPage,
                get_total: true
            };

            contactService.getWebforms(request)
                .then(results => {
                    $scope.data.webforms = results.webforms;
                    $scope.totalItems = results.total_webforms;
                })
                .catch(err => {
                    console.error(err);
                })
                .then(() => {
                    $scope.loading.webforms = false;
                    $scope.$apply();
                });
        };

        $scope.setPage = function (pageNo) {
            $scope.currentPage = pageNo;
        };

        $scope.onInit = function() {
            $scope.loadWebforms();
        };

        if (authService.hasAccount()) {
            $scope.onInit();
        }
        else {
            $scope.$on('auth_complete', function(event, args) {
                $scope.onInit();
            });
        }

    }
    module.exports = WebformsController;
})();
