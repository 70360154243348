(function() {
    'use strict';

    function TimelineCallPlaceholderListItemController(authService) {
        var vm = this;
        vm.authService = authService;

    }
    module.exports = TimelineCallPlaceholderListItemController;
})();
