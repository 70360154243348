(function() {
    'use strict';

    function StoreSelectedContactService($rootScope) {
        let self = this;
        self.contactId = null;

        function updateContactNotes(note, action) {
            $rootScope.$broadcast('updateContactNotesEvent', note, action);
        }

        function setContactId(id) {
            self.contactId = id;
        }

        function getContactId() {
            return self.contactId;
        }

        return {
            setContactId: setContactId,
            getContactId: getContactId,
            updateContactNotes: updateContactNotes
        };
    }
    module.exports = StoreSelectedContactService;
})();

