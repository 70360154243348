(function() {
    'use strict';

    function IntercomService($window, $rootScope) {

        function setIntercomUser(user) {
            let team = user.team;
            let email = user.email || '';
            let name = (user.first_name + ' ' + user.last_name).trim() || user.username;
            let role = (user.role || {}).name;
            let createdAt = moment(team.created_at).format('x');
            let isoCreatedAt = moment(team.created_at).format();

            window.intercomSettings.name = name;
            window.intercomSettings.email = email;
            window.intercomSettings.user_id = user.user_id;
            window.intercomSettings.user_hash = user.intercom_hash;
            window.intercomSettings.company = {};
            window.intercomSettings.company.id = team.team_id;
            window.intercomSettings.company.name = team.name;
            window.intercomSettings.created_at = Math.floor(createdAt / 1000);

            window.$fvIdentity = {
                id: user.user_id,                  // Required - string - unique user identifier
                name: name || 'Unknown',           // Required - string - user's name
                email: email,                      // Optional - string - user's email
                disableReplaysForUser: false,      // Optional - boolean - disables replays for the user
                env: $rootScope.settings.RUNTIME_ENV,                   // Optional - string - used for segmentation and environment-based settings
                roles: [role],              // Optional - string[] - used for segmentation and role-based settings
                user_addon_id: user.addon_id,
                usage_addon_id: team.chargebee.usage_addon_id,
                team_id: team.team_id,   // Optional - [key: string]: primitive - used do add custom fields with custom data
                team_name: team.name,   // Optional - [key: string]: primitive - used do add custom fields with custom data
                team_created_at: isoCreatedAt,
            };

            if (user.home_phone) {
                window.$fvIdentity.user_home_phone_number = user.home_phone;
            }

            if (user.mobile_phone) {
                window.$fvIdentity.user_home_phone_number = user.mobile_phone;
            }

            if (user.office_phone) {
                window.$fvIdentity.user_home_phone_number = user.office_phone;
            }
        }

        function loginUserIntercom(user) {
            setIntercomUser(user);

            window.Intercom('boot', window.intercomSettings);
        }

        function initIntercom(user) {
            console.log('Starting intercom');

            window.intercomSettings = {
                app_id: $rootScope.settings.APP_INTERCOM_ACCOUNT
            };

            setIntercomUser(user);

            (function() {
                var w = window;
                var ic = w.Intercom;
                if (typeof ic === 'function') {
                    ic('reattach_activator');
                    ic('update', w.intercomSettings);
                } else {
                    var d = document;
                    var i = function() {
                        i.c(arguments);
                    };
                    i.q = [];
                    i.c = function(args) {
                        i.q.push(args);
                    };
                    w.Intercom = i;
                    var l = function() {
                        var s = d.createElement('script');
                        s.type = 'text/javascript';
                        s.async = true;
                        s.src = 'https://widget.intercom.io/widget/' + $rootScope.settings.APP_INTERCOM_ACCOUNT;
                        var x = d.getElementsByTagName('script')[0];
                        x.parentNode.insertBefore(s, x);

                        console.log('Intercom started');
                    };
                    if (document.readyState === 'complete') {
                        l();
                    } else {
                        document.onreadystatechange = function () {
                            if (document.readyState === 'complete') {
                                l();
                            }
                        };
                    }
                }
            })();
        }

        function startIntercom(user) {
            try {
                if(!$rootScope.settings.APP_INTERCOM_ACCOUNT) {
                    return;
                }

                (!window.intercomSettings) ? initIntercom(user) : loginUserIntercom(user);
            } catch(e) {}
        }

        function shutdownIntercom() {
            if (typeof window.Intercom === 'function') {
                window.intercomSettings = undefined;
                window.Intercom('shutdown');
            }
        }

        function changeIntercomHorizontalPadding(isRightSidePanel) {
            if (typeof window.Intercom === 'function' && window.intercomSettings) {
                let padding = (isRightSidePanel) ? 380 : 20;

                window.intercomSettings.horizontal_padding = padding;
                window.Intercom('update', window.intercomSettings);
            }
        }

        return {
            startIntercom: startIntercom,
            shutdownIntercom: shutdownIntercom,
            changeIntercomHorizontalPadding: changeIntercomHorizontalPadding
        };
    }
    module.exports = IntercomService;
})();

