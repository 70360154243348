(function () {
    'use strict';

    function CallService($rootScope, rtcSipService) {

        let self = this;
        self.subscriber = null;

        self.CONTROL_ACTIONS = {
            DIAL_ADHOC: 'dial_adhoc',
            DIAL_ANY: '1',
            DIAL_LIST: 'dial_list',
            CALL_INTERCEPT: 'call_intercept',
            CALL_EAVESDROP: 'call_eavesdrop'
        };

        self.HangupCause = rtcSipService.HangupCause;
        self.deferredConnectAction = null;

        function setSipSubscriber(subscriber) {
            self.subscriber = subscriber || null;
            if (subscriber) {
                rtcSipService.connect(subscriber, __handleRtcEvent)
            }
            else {
                rtcSipService.disconnect();
            }
        }

        function dial(request) {
            if (self.subscriber && self.subscriber.realm && request.access_number) {

                let clientData = {
                    encoded: {
                        team_id: request.team_id,
                        user_id: request.user_id,
                        initial_action: request.initial_action, // Action that call control should perform once this call connects
                        initial_data: {
                            play_welcome: request.play_welcome || false, // Play audio prompts to user for main menu
                            eavesdrop_cid: request.eavesdrop_cid || null, // Cid of the agent to eavesdrop on (if applicable)
                            intercept_cid: request.intercept_cid || null, // Cid of the (queued) call to intercept (if applicable)
                            race_group: request.race_group || null, // Race group name to intercept (to connect agent to next queued call if not intercepting specific cid) (if applicable)
                            list_id: request.list_id || null, // List to dial (if applicable)
                            call_id: request.call_id || null, // Call to intercept / eavesdrop (if applicable)
                            contact_id: request.contact_id || null, // Contact that we're calling (if applicable)
                            phone_number: request.contact_tn || null, // Contact's phone number to dial (if applicable)
                            caller_id: request.caller_id || null // The caller id to display to the contact (if applicable)
                        }
                    }
                };

                let encodedClientData = Buffer.from(JSON.stringify(clientData)).toString('base64');

                let options = {
                    extra_headers: [
                       'x-client-data:' + encodedClientData
                    ],
                    enable_audio: request.enable_audio || false,
                    enable_video: request.enable_video || false
                };

                // If we're intercepting or eavesdropping on a call, request that our call be co-located on the same telco switch so the calls can be bridged
                let colocateCid = request.intercept_cid || request.eavesdrop_cid;
                if (colocateCid) {
                    options.extra_headers.push('X-Colocate-CID:' + colocateCid);
                }

                // If we're intercepting a parked call, we'll request that our webrtc session be steered to the same telco switch
                let dialStr = request.access_number;
                if (request.call_id) {
                    dialStr = request.call_id;
                }

                let uri = 'sip:' + dialStr + '@' + self.subscriber.realm;
                self.deferredConnectAction = {
                    func: rtcSipService.dial,
                    args: [uri, options]
                };

                // We need to connect to the rtc servers before we can send them SIP traffic
                return rtcSipService.start();
            }

            return false;
        }

        function hangup(hangupCause) {
            return rtcSipService.terminateAll(hangupCause);
        }

        function __handleRtcEvent(event, data) {

            switch(event) {
                case rtcSipService.Events.UA_CONNECTED:
                    if (self.deferredConnectAction && self.deferredConnectAction.func) {
                        self.deferredConnectAction.func.apply(rtcSipService, self.deferredConnectAction.args || []);
                    }
                    self.deferredConnectAction = null;
                    $rootScope.$broadcast('webrtc.ua.connected', data);
                    break;
                case rtcSipService.Events.UA_DISCONNECTED:
                    self.deferredConnectAction = null;
                    $rootScope.$broadcast('webrtc.ua.disconnected', data);
                    break;
                case rtcSipService.Events.SESSION_CREATED:
                    $rootScope.$broadcast('webrtc.session.created', data);
                    break;
                case rtcSipService.Events.SESSION_DIALING:
                    $rootScope.$broadcast('webrtc.session.dialing', data);
                    break;
                case rtcSipService.Events.SESSION_PROGRESS:
                    $rootScope.$broadcast('webrtc.session.progress', data);
                    break;
                case rtcSipService.Events.SESSION_ANSWERED:
                    $rootScope.$broadcast('webrtc.session.answered', data);
                    break;
                case rtcSipService.Events.SESSION_HANGUP:
                    self.deferredConnectAction = null;
                    $rootScope.$broadcast('webrtc.session.hangup', data);
                    break;
                case rtcSipService.Events.SESSION_FAILED:
                    self.deferredConnectAction = null;
                    $rootScope.$broadcast('webrtc.session.failed', data);
                    break;
            }
        }

        return {
            setSipSubscriber: setSipSubscriber,
            dial: dial,
            hangup: hangup,
            CONTROL_ACTIONS: self.CONTROL_ACTIONS,
            HANGUP_CAUSE: rtcSipService.HangupCause
        };
    }
    module.exports = CallService;
})();


