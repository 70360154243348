(function() {
    'use strict';

    function HeaderController(authService, $rootScope, $scope, $window, $location, userService) {
        var vm = this;
        vm.authService = authService;
        
        $scope.appVersion = '';
        $scope.user;
        $scope.devices = [];
        $scope.selectedDevice = undefined;
        $scope.userStatus = '';
        $scope.showNotificationBadge = false;
        $scope.appIcon = $rootScope.settings.APP_ICON;
        $scope.appLogo = $rootScope.settings.APP_LOGO;

        $scope.defaultAvatar = $rootScope.settings.NO_AVATAR_IMG;
        $scope.data = {
            searchTerm: ($location.search().searchTerm || '')
        };
        $scope.verify_learn_url = $rootScope.verify_learn_url;
        $scope.integrations_enabled = $rootScope.integrations_enabled;

        $scope.searchContacts = function() {
            $location.path('/contacts').search({searchTerm: $scope.data.searchTerm});
        };

        $scope.$on('show.nav.notification.badge', function (e, show) {
            $scope.showNotificationBadge = show;
        });
        
        $rootScope.$on('$locationChangeSuccess',
            function(event, newUrl, oldUrl) {
                if ( $location.path() == '/contacts' ) {
                    $scope.data.searchTerm = ($location.search().searchTerm || '');
                }
            }
        );

        $scope.$on('teamVerified', function(e) {
            $scope.user.team.verified = true;
            $scope.$apply();
        });
        
        $scope.logout = function() {
            authService.logout();
        };
        
        $scope.headerInit = function() {
            var width = (window.innerWidth > 0) ? window.innerWidth : this.screen.width;
            var topOffset = 70;
            if (width < 1024) {
                $('body').addClass('mini-sidebar');
                $('.full-logo').hide();
                $('.icon-logo').show();
                // $(".scroll-sidebar, .slimScrollDiv").css("overflow-x", "visible").parent().css("overflow", "visible");
                // $(".sidebartoggler i").addClass("ti-menu");
            }
            else {
                $('body').removeClass('mini-sidebar');
                $('.icon-logo').hide();
                $('.full-logo').show();
                // $(".sidebartoggler i").removeClass("ti-menu");
            }
            var height = ((window.innerHeight > 0) ? window.innerHeight : this.screen.height) - 1;
            // height = height - topOffset;
            if (height < 1) height = 1;
            if (height > topOffset) {
                $('.page-wrapper').css('min-height', (height) + 'px');
            }
            if (!$scope.user.team.verified) {
                $('.page-wrapper').addClass('warning-active');
            }
        };

        $scope.editMyProfile = function() {
            $rootScope.$broadcast('editUserProfileEvent', $scope.user.user_id);
        };
        
        $scope.collectPayment = function() {
            $rootScope.collectPayment();
        };

        $scope.showCancelModal = function() {
            $rootScope.showCancelModal();
        };

        $scope.showWebRtcSettingsModal = function() {
            $rootScope.showWebRtcSettingsModal();
        };

        $scope.showVerificationModal = function() {
            $rootScope.showVerificationModal();
        };

        $scope.showAddFunds = function() {
            $rootScope.showAddFundsModal();
        };

        $scope.loadUserNumbers = function() {
            let userNumbers = authService.getUserNumbers(true);
            $scope.devices = Object.keys(userNumbers);
            $scope.selectedDevice = authService.getUserDefaultLocation();
        };

        $scope.setDevice = function(device) {
            $scope.selectedDevice = device;
            authService.setUserDefaultLocation(device);
        };

        $scope.getUserStatus = function() {

            const user = authService.getUser();

            if (user.do_not_disturb) {
                $scope.userStatus = 'do-not-disturb-user-status';
            } else if (user.agent_state && ['agent.dialing', 'agent.talking', 'agent.wrapup'].indexOf(user.agent_state.event_type) !== -1) {
                $scope.userStatus = 'active-user-status';
            } else {
                $scope.userStatus = '';
            }
        };

        $rootScope.$on('client.user.changed', function(event, userData) {
            $scope.setDevice(userData.routing_location);
            $scope.getUserStatus();
        });

        $scope.$on('teamNumbersLoaded', function() {
            $scope.loadUserNumbers();
        });

        angular.element($window).bind('resize', function(){
            $scope.headerInit();
        });

        $scope.setMenuItemActive = function(pageUrl) {
            const cutPageUrl = pageUrl.split('/').slice(0, 2).join('/');
            $('ul#my-account-dropdown-menu li a').removeClass('active-menu-item');
            $('ul#my-account-dropdown-menu li a[href="' + cutPageUrl + '"]').addClass('active-menu-item');
        };

        $rootScope.$on('$stateChangeStart', function(e, toState, toParams, fromState, fromParams) {
            $scope.setMenuItemActive('#' + toState.url);
        });
        $rootScope.$on('$stateChangeSuccess', function(e, toState, toParams, fromState, fromParams) {
            if($('body').hasClass('show-sidebar')) {
                $rootScope.closeLeftSideMenu();
            }
        });

        $rootScope.openLeftSideMenu = function() {
            var iconElem = $('.nav-toggler i');
            $('body').addClass('show-sidebar');
            iconElem.removeClass('fa-bars');
            iconElem.addClass('fa-xmark');
        };
        $rootScope.closeLeftSideMenu = function() {
            var iconElem = $('.nav-toggler i');
            $('body').removeClass('show-sidebar');
            iconElem.removeClass('fa-xmark');
            iconElem.addClass('fa-bars');
        };
        
        $scope.onInit = function() {
            $scope.user = $rootScope.user;
            $rootScope.onInit();
            $scope.headerInit();
            $scope.appVersion = $rootScope.appVersion || '';
            $scope.getUserStatus();

            $('.nav-toggler').click(function () {
                var funcName = $('body').hasClass('show-sidebar') ? 'closeLeftSideMenu' : 'openLeftSideMenu';
                $rootScope[funcName]();
            });
            // $(".sidebartoggler").on('click', function () {
            //     $(".sidebartoggler i").toggleClass("ti-menu");
            // });
            
            $('.fix-header .topbar').stick_in_parent({bottoming: false});

            setTimeout(()=> {$scope.setMenuItemActive(window.location.hash);});
        };

        if (authService.hasAccount()) {
            $scope.onInit();
        }
        else {
            $scope.$on('auth_complete', function(event, args) {
                $scope.onInit();
            });
        }

    }
    module.exports = HeaderController;
})();
