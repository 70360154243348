(function() {
    'use strict';

    function IpaasAutomationController($rootScope, $scope, $location, $window, authService, automationService, $sce) {
        var vm = this;
        vm.authService = authService;

        $rootScope.viewData = $rootScope.viewData || {};
        $rootScope.viewData.breadcrumbs = $rootScope.viewData.breadcrumbs || [];
        $rootScope.viewData.breadcrumbs = [{
            title: 'Automation',
            link_name: 'Automation',
            link_url: '#/automation',
            help_url: $rootScope.help_url
        }];

        $scope.data = {
            sessionToken: null,
            workflows: [],
            workflow: {
                workflowId: null,
                workflowName: null
            }
        };

        $scope.loading = {
            workflows: true,
            newWorkflow: false
        };

        $scope.staticText = {
            title: 'Automation',
            subtitle: 'Configure workflows to handle events...',
            newWorkflow: {
                title: 'Create Workflow'
            },
            connectedApps: {
                title: 'Manage Connections',
                href: '#/app-marketplace'
            },
            iframe: {
                header: 'Workflow Builder'
            },
            workflowForm: {
                header: 'Workflow Configuration',
                title: 'Workflow Name',
                subtitle: 'Enter a name for your workflow...',
                enterBuilderBtn: 'Enter Workflow Builder',
                closeBtn: 'Close'
            },
            table: {
                header: ['Name', 'Created At', 'Last Triggered', 'Event'],
                actions: ["Delete Workflow", "Edit Workflow"],
                noRes: "You don't have any workflows. ",
                loading: "Loading",
                popoverHtml: "phone-numbers-popover.html"
            }
        };

        $scope.deleteWorkflow = (workflow) => {

        };

        $scope.editWorkflow = (workflow) => {
            $window.location.href = '#/workflow/' + workflow._id;
        };

        $scope.openWorkflowBuilder = () => {
            $scope.loading.newWorkflow = true;

            const req = {
                session_token: $scope.data.sessionToken
            };

            automationService.createPublicWorkflow(req)
                .then(result => {

                    $scope.loading.newWorkflow = false;
                    $scope.editWorkflow(result.workflow._id);

                    $scope.$apply();
                })
                .catch(err => {

                    $scope.loading.newWorkflow = false;
                    $scope.$apply();
                });
        };

        $scope.getWorkflows = () => {
            return automationService.getIpassWorkflows()
                .then(data => {

                    $scope.data.workflows = data.docs || [];
                    $scope.loading.workflows = false;

                    $scope.$apply();
                })
                .catch(err => {
                    console.log(err);

                    $scope.loading.workflows = false;
                    $scope.$apply();
                });
        };

        $scope.initializeSession = () => {
            return automationService.getIpassSessionToken()
                .then(data => {
                    $rootScope.cobalt.token = data.token;
                    $scope.data.sessionToken = data.token;

                    $scope.$apply();
                })
                .catch(err => {
                    console.log(err);
                    $scope.$apply();
                });
        };

        $scope.onInit = () => {
            $scope.initializeSession();
            $scope.getWorkflows();
        }

        if (authService.hasAccount()) {
            $scope.onInit();
        }
        else {
            $scope.$on('auth_complete', function(event, args) {
                $scope.onInit();
            });
        }
    }
    module.exports = IpaasAutomationController;
})();
