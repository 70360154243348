(function() {
    'use strict';

    function MainWrapperController(authService, $rootScope, $scope, $window, $state) {
        var vm = this;
        vm.authService = authService;

        $scope.isInboxView = function(state) {
            return (state.url === '/inbox') ? true : false;
        };
        $scope.inboxPage = $scope.isInboxView($state.current);

        $rootScope.$on('$stateChangeStart', function(e, toState, toParams, fromState, fromParams) {
            $scope.inboxPage = $scope.isInboxView(toState);
        });

        $scope.onInit = function() {

        };

        if (authService.hasAccount()) {
            $scope.onInit();
        }
        else {
            $scope.$on('auth_complete', function(event, args) {
                $scope.onInit();
            });
        }
    }
    module.exports = MainWrapperController;
})();
