(function() {
    'use strict';

    function ConnectDropCowboyModalController($rootScope, $scope, integrationService) {
        var vm = this;

        $scope.data = {
            saving: false,
            isFormSubmitted: false,
        }

        $scope.defaultAvatar = $rootScope.settings.NO_AVATAR_IMG;

        $scope.forms = {
            dropcowboy_team_id: {
                value: '',
                is_valid: true
            },
            secret: {
                value: '',
                is_valid: true
            },
        };

        $scope.onInit = () => {
            $('#connectToDropCowboy').on('hide.bs.modal', function (e) {
                $scope.resetForm();
            });
        }

        $scope.onInit();

        $scope.resetForm = () => {
            $scope.forms = {
                dropcowboy_team_id: {
                    value: '',
                    is_valid: true
                },
                secret: {
                    value: '',
                    is_valid: true
                },
            };
        }

        $scope.validateControl = (type) => {
            if ($scope.data.isFormSubmitted) {
                $scope.forms[type].is_valid = !!$scope.forms[type].value;
            }
        }

        $scope.connectDropCowboy = () => {
            $scope.data.isFormSubmitted = true;

            $scope.validateControl('dropcowboy_team_id');
            $scope.validateControl('secret');

            if (!$scope.forms.dropcowboy_team_id.is_valid || !$scope.forms.secret.is_valid) {
                return;
            }

            $scope.data.saving = true;
            const req = Object.keys($scope.forms).reduce((reqObj, key) => {
                reqObj[key] = $scope.forms[key].value;
                return reqObj;
            }, {});
            req.integration_type = 'drop_cowboy';
            integrationService.connect(req)
                .then(() => {
                    $scope.data.saving = false;

                    $rootScope.$broadcast('dropCowboyConnected');
                    $('#connectToDropCowboy').modal('hide');
                });
        }
    }
    module.exports = ConnectDropCowboyModalController;
})();
