(function () {
        'use strict';

        function ApiService($rootScope, authLocalService) {

            const get = function (url, data, options = {}) {
                return new Promise((resolve, reject) => {
                    let token = localStorage.getItem('auth_token');

                    if (data && Object.keys(data).length) {
                        let queryStr = new URLSearchParams(data).toString();
                        url += '?' + queryStr;
                    }

                    if (!options.headers) {
                        options.headers = {Authorization: 'Bearer ' + token};
                    }
                    $.ajax(url, {dataType: 'json', method: 'GET', headers: options.headers})
                        .done(function (data, textStatus, jqXHR) {
                            resolve(data);
                        })
                        .fail(function (jqXHR, textStatus, errorThrown) {
                            if (jqXHR.status === 401 && $rootScope.authenticated && options.logout_on_unauthorized) {
                                authLocalService.localLogout();
                            }
                            // reject(errorThrown);
                            reject(jqXHR.responseJSON);
                        });
                });
            };

            const post = function (url, data, options = {}) {
                return new Promise((resolve, reject) => {
                    let token = localStorage.getItem('auth_token');

                    $.ajax(url, {
                        data : options.send_as_form_data ? data : JSON.stringify(data),
                        contentType : options.send_as_form_data ? false : 'application/json',
                        processData : !options.send_as_form_data,
                        dataType:  options.send_as_form_data ? undefined : 'json',
                        method: 'POST',
                        headers: {Authorization: 'Bearer ' + token}
                    })
                        .done(function (data, textStatus, jqXHR) {
                            resolve(data);
                        })
                        .fail(function (jqXHR, textStatus, errorThrown) {
                            if (jqXHR.status === 401 && $rootScope.authenticated && options.logout_on_unauthorized) {
                                authLocalService.localLogout();
                            }
                            reject(jqXHR.responseJSON);
                        });
                });
            };

            const put = function (url, data, options = {}) {
                return new Promise((resolve, reject) => {
                    let token = localStorage.getItem('auth_token');

                    $.ajax(url, {
                        data: JSON.stringify(data),
                        contentType: 'application/json',
                        dataType: 'json',
                        method: 'PUT',
                        headers: {Authorization: 'Bearer ' + token}
                    })
                        .done(function (data, textStatus, jqXHR) {
                            resolve(data);
                        })
                        .fail(function (jqXHR, textStatus, errorThrown) {
                            if (jqXHR.status === 401 && $rootScope.authenticated && options.logout_on_unauthorized) {
                                authLocalService.localLogout();
                            }
                            reject(jqXHR.responseJSON);
                        });
                });
            };

            const del = function (url, data, options = {}) {
                return new Promise((resolve, reject) => {
                    let token = localStorage.getItem('auth_token');

                    $.ajax(url, {
                        data: data,
                        dataType: 'json',
                        method: 'DELETE',
                        headers: {Authorization: 'Bearer ' + token}
                    })
                        .done(function (data, textStatus, jqXHR) {
                            resolve(data);
                        })
                        .fail(function (jqXHR, textStatus, errorThrown) {
                            if (jqXHR.status === 401 && $rootScope.authenticated && options.logout_on_unauthorized) {
                                authLocalService.localLogout();
                            }
                            reject(errorThrown);
                        });
                });
            };

            const sendBeacon = function (url, data) {
                if (!navigator.sendBeacon) {
                    return;
                }
                const sendUrl = url + '?' + Object.keys(data).map(key => key + '=' + data[key]).join('&');
                return navigator.sendBeacon(sendUrl);
            };

            return {
                get: get,
                post: post,
                put: put,
                del: del,
                sendBeacon: sendBeacon
            };
        }

        module.exports = ApiService;
    }

)
();


