(function() {
    'use strict';

    function AddEditContactModalController($rootScope, $scope, authService, contactService, $http, generalHelperService) {
        var vm = this;
        vm.authService = authService;

        $scope.staticText = {
            loading: 'Loading',
            saving: 'Saving',
            creating: 'Creating',
            editContact: {
                labels: [
                    'First Name',
                    'Last Name',
                    'Main Number',
                    'Secondary Number',
                    'Email',
                    'Address',
                    'Suite/Apt',
                    'City',
                    'State',
                    'Country',
                    'Zip Code',
                    'Company',
                    'Fax Number'
                ]
            },
            errors: {
                phoneNumberErrors: {
                    'IS_POSSIBLE': 'The number length matches that of valid numbers for this region but the number is formatted incorrectly.',
                    'INVALID_COUNTRY_CODE': 'Invalid country calling code.',
                    'TOO_SHORT': 'The phone number is too short.',
                    'TOO_LONG': 'The phone number is too long.',
                    'NOT_A_NUMBER': 'Phone number is not valid'
                },
                required: 'Field is required.',
                onlyLettersSpaces: 'Field should consist from letters and spaces.',
                maxLength30: 'Field should not be longer then 30 symbols.',
                maxLength20: 'Field should not be longer then 20 symbols.',
                noSpecialCharacters: 'Field should consist from letters, numbers and spaces.',
                email: 'Must enter valid email.'
            },
            secondaryPhoneNumbersLimit: 5
        };
        $scope.defaultAvatar = $rootScope.settings.NO_AVATAR_IMG;

        $scope.namePattern = /^[a-zA-Z ]*$/;
        $scope.emailPattern = $rootScope.settings.USER_EMAIL_REGEX;
        $scope.noSpecialCharactersPattern = /[.a-zA-Z\d]/;

        $scope.data = {
            editContact: {
                mode: '',
                contactDetails : {
                    country: {
                        code: 'US',
                        name: 'United States'
                    }
                },
                fax_number: {},
                phone_numbers: [],
                countries: [],
                contactId: '',
                listId: '',
                updatingContact: false,
                creatingContact: false,
                addingPhoneNumber: false
            },
            teamCustomFields: []
        };

        $('#addEditContactModal').on('hidden.bs.modal', function () {
            $scope.editModalClearContactFields();
            _.forEach( $scope.data.editContact.phone_numbers, function( number, i ){
                $scope.editModalClearIti(i);
            });
            $scope.editModalClearFaxIti();

            $scope.data.editContact.mode = '';
            $scope.data.editContact.fax_number = {};
            $scope.data.editContact.phone_numbers = [];
            $scope.data.editContact.contactId = '';
            $scope.data.editContact.listId = '';

            $scope.data.editContact.contactDetails.first_name = '';
            $scope.data.editContact.contactDetails.last_name = '';
            $scope.data.editContact.contactDetails.main_phone = '';
            $scope.data.editContact.contactDetails.secondary_phone = '';
            $scope.data.editContact.contactDetails.fax_number = '';
            $scope.data.editContact.contactDetails.company = '';
            $scope.data.editContact.contactDetails.email = '';
            $scope.data.editContact.contactDetails.address = '';
            $scope.data.editContact.contactDetails['suite/apt'] = '';
            $scope.data.editContact.contactDetails.city = '';
            $scope.data.editContact.contactDetails.state = '';
            $scope.data.editContact.contactDetails.zip_code = '';
            $scope.data.editContact.contactDetails.country = {
                code: 'US',
                name: 'United States'
            };
            $scope.data.editContact.contactDetails.customFields = [];

            $scope.data.editContact.updatingContact = false;
            $scope.data.editContact.creatingContact = false;
            $scope.data.editContact.addingPhoneNumber = false;

            $scope.data.teamCustomFields.forEach(field => {
                field.value = null;
            });

            $scope.$apply();

            $scope.editContactForm.$setPristine();
            $scope.editContactForm.$setUntouched();
        });

        $scope.editModalEditFieldData = function() {
            _.forEach($scope.data.editContact.contactDetails.field_data, function(field) {
                if(field.type === 'country') {
                    var country = $scope.data.editContact.contactDetails[field.type];
                    field.value = (!country) ? 'US' : country.code;
                } else {
                    field.value = $scope.data.editContact.contactDetails[field.type];
                }
            });
        };

        $scope.editModalSubmitForm = function(e) {
            e.preventDefault();

            if(!$scope.editContactForm.$valid) {
                return;
            }

            var validPhoneNumbers = _.map($scope.data.editContact.phone_numbers, 'number');
            var loader = ($scope.data.editContact.mode === 'edit') ? 'updatingContact' : 'creatingContact';
            $scope.data.editContact[loader] = true;

            var teamId = authService.getTeamId();
            var userId = authService.getUserId();

            $scope.editModalEditFieldData();

            var country = $scope.data.editContact.contactDetails.country;
            $scope.data.editContact.contactDetails.country = (!country) ? 'US' : country.code;

            var mainFunction = ($scope.data.editContact.mode === 'edit') ? 'editModalSaveContact' : 'editModalCreateContact';
            $scope[mainFunction](teamId, userId, validPhoneNumbers);
        };

        $scope.editModalSubmitSuccess = function(alertMessage) {
            swal(alertMessage.title, alertMessage.body, 'success');
            var loader = ($scope.data.editContact.mode === 'edit') ? 'updatingContact' : 'creatingContact';
            $scope.data.editContact[loader] = false;
            $('#addEditContactModal').modal('hide');
            $rootScope.$broadcast('addEditContactEventDone', $scope.data.editContact.mode, true);
            $scope.$apply();
        };

        $scope.editModalSubmitError = function() {
            swal('Error', 'Something went wrong.', 'error');
            var loader = ($scope.data.editContact.mode === 'edit') ? 'updatingContact' : 'creatingContact';
            $scope.data.editContact[loader] = false;
            $('#addEditContactModal').modal('hide');
            $rootScope.$broadcast('addEditContactEventDone', $scope.data.editContact.mode, false);
            $scope.$apply();
        };

        $scope.editModalCreateContact = function(teamId, userId, validPhoneNumbers) {

            let request = {};
            const fd = $scope.data.editContact.contactDetails.field_data;
            for (let i = 0; i < fd.length; i++) { request[ fd[i].type ] = $scope.data.editContact.contactDetails[fd[i].type ]};
            request.team_id = teamId;
            request.user_id = userId;
            request.field_data = $scope.data.editContact.contactDetails.field_data;
            request.phone_numbers = validPhoneNumbers;

            if($scope.data.editContact.list_id) {
                request.list_id = $scope.data.editContact.list_id;
            }

            const nextFreeFieldId = Math.max(...request.field_data.map(field => field.field_id)) + 1;
            request.field_data = request.field_data.concat($scope.data.teamCustomFields.map((customField, index) => {
                return {
                    display_name: customField.display_name,
                    custom_field_id: customField.custom_field_id,
                    field_id: nextFreeFieldId + index,
                    field_name: (customField.display_name.replace(/ /g,"_")).replace(/[^a-zA-Z0-9_]/g, '').toLowerCase(),
                    type: 'custom_field',
                    value: customField.type === 'list' ? customField.value ? customField.value.value : null : customField.value
                };
            }));

            contactService.createContactFields(request)
                .then(contact => {
                    var alertMessage = {
                        title: 'Created',
                        body: 'Contact information successfully created.'
                    };
                    $scope.editModalSubmitSuccess(alertMessage);
                })
                .catch(err => {
                    console.error(err);
                    $scope.editModalSubmitError();
                });
        };

        $scope.editModalSaveContact = function(teamId, userId, validPhoneNumbers) {

            const request = {
                team_id: teamId,
                user_id: userId,
                contact_id: $scope.data.editContact.contactId,
                contact: {
                    field_data: $scope.data.editContact.contactDetails.field_data,
                    phone_numbers: validPhoneNumbers
                }
            };

            const nextFreeFieldId = Math.max(...request.contact.field_data.map(field => field.field_id)) + 1;
            request.contact.field_data = request.contact.field_data.concat($scope.data.teamCustomFields.map((customField, index) => {
                return {
                    display_name: customField.display_name,
                    custom_field_id: customField.custom_field_id,
                    field_id: nextFreeFieldId + index,
                    field_name: (customField.display_name.replace(/ /g,"_")).replace(/[^a-zA-Z0-9_]/g, '').toLowerCase(),
                    type: 'custom_field',
                    value: customField.type === 'list' ? customField.value ? customField.value.value : null : customField.value
                }
            }));

            contactService.updateContact(request)
                .then(contact => {
                    var alertMessage = {
                        title: 'Saved',
                        body: 'Contact information successfully saved.'
                    };
                    $scope.editModalSubmitSuccess(alertMessage);
                })
                .catch(err => {
                    console.error(err);
                    $scope.editModalSubmitError();
                });
        };

        $scope.editModalClearFaxIti = function() {
            $scope.data.editContact.fax_number.error = undefined;
            $scope.data.editContact.fax_number.iti.setNumber("");
            $('#edit-contact-fax-number').unmask();
            $scope.data.editContact.fax_number.iti.destroy();
        };

        $scope.editModalClearIti = function(index) {
            $scope.data.editContact.phone_numbers[index].error = undefined;
            $scope.data.editContact.phone_numbers[index].iti.setNumber("");
            $("#edit-contact-phone-" + index).unmask();
            $scope.data.editContact.phone_numbers[index].iti.destroy();
        };

        $scope.editModalRemovePhoneNumber = function(e, id) {
            e.preventDefault();
            var arrayIndex = _.findIndex($scope.data.editContact.phone_numbers, function(p) { return p.id == id; });

            $scope.editModalClearIti(arrayIndex);
            $scope.data.editContact.phone_numbers = _.reject($scope.data.editContact.phone_numbers, ['id', id]);
        };

        $scope.editModalAddPhoneNumber = function(number) {
            if($scope.data.editContact.phone_numbers.length >= ($scope.staticText.secondaryPhoneNumbersLimit + 1)) {
                return;
            }

            $scope.data.editContact.addingPhoneNumber = true;

            var maxId = _.maxBy($scope.data.editContact.phone_numbers, function(p) { return p.id; });
            var newId = (!maxId) ? 0 : (maxId.id + 1);

            $scope.data.editContact.phone_numbers.push(
                {
                    id: newId,
                    number: (number || ''),
                    ngModelNumber: ''
                }
            );

            return $scope.editModalPhoneNumberMask((number || ''), newId)
                .then(() => {
                    $scope.data.editContact.addingPhoneNumber = false;
                });
        };

        $scope.editModalFormatPhone = function(value, id) {

            var arrayIndex = _.findIndex($scope.data.editContact.phone_numbers, function(p) { return p.id == id; });
            if (!$scope.data.editContact.phone_numbers[arrayIndex].iti) {
                return false;
            }

            var isValid = $scope.data.editContact.phone_numbers[arrayIndex].iti.isValidNumber();
            var validationError = $scope.data.editContact.phone_numbers[arrayIndex].iti.getValidationError();
            var errorKey = _.findKey(window.intlTelInput.utils.validationError, function(error) { return error === validationError; });
            $scope.data.editContact.phone_numbers[arrayIndex].error = (!isValid) ? errorKey : undefined;

            var value = (isValid) ? $scope.data.editContact.phone_numbers[arrayIndex].iti.getNumber(window.intlTelInput.utils.numberFormat.E164) : '';
            $scope.data.editContact.phone_numbers[arrayIndex].number = value;

            if(arrayIndex === 0) {
                $scope.data.editContact.contactDetails.main_phone = $scope.data.editContact.phone_numbers[arrayIndex].number;
            } else {
                $scope.data.editContact.contactDetails.secondary_phone = $scope.data.editContact.phone_numbers[arrayIndex].number;
            }

            return isValid;
        };

        $scope.editModalFormatFax = function() {

            if (!$scope.data.editContact.fax_number.iti) {
                return false;
            }

            var isValid = $scope.data.editContact.fax_number.iti.isValidNumber();
            var validationError = $scope.data.editContact.fax_number.iti.getValidationError();
            var errorKey = _.findKey(window.intlTelInput.utils.validationError, function(error) { return error === validationError; });
            $scope.data.editContact.fax_number.error = (!isValid) ? errorKey : undefined;

            var value = (isValid) ? $scope.data.editContact.fax_number.iti.getNumber(window.intlTelInput.utils.numberFormat.E164) : '';
            $scope.data.editContact.fax_number.number = value;
            $scope.data.editContact.contactDetails.fax_number = $scope.data.editContact.fax_number.number;

            return isValid;
        };

        $scope.editModalSetPhoneMask = function(elem, mask, placeholder) {
            elem.mask(mask, {
                placeholder: placeholder
            });
        };

        $scope.editModalFaxNumberMask = function (number) {
            const fullId = '#edit-contact-fax-number';
            let faxNumberId;
            return generalHelperService.waitForElement(fullId)
                .then(faxNumberIdInput => {
                    faxNumberId = $(fullId);
                    if (faxNumberId) {
                        $scope.data.editContact.fax_number.iti = window.intlTelInput(faxNumberIdInput, {
                            nationalMode: 'false',
                            autoPlaceholder: 'aggressive',
                            separateDialCode: 'true',
                            validationNumberTypes: [
                                'FIXED_LINE',
                                'MOBILE',
                                'FIXED_LINE_OR_MOBILE',
                                'TOLL_FREE',
                                'PREMIUM_RATE',
                                'SHARED_COST',
                                'VOIP',
                                'PERSONAL_NUMBER',
                                'PAGER',
                                'UAN',
                                'VOICEMAIL',
                                'UNKNOWN'
                            ],
                            initialCountry: 'us',
                            // intl-tel-input is up to date as of dec 2024
                            loadUtils: () => import("https://cdn.jsdelivr.net/npm/intl-tel-input@25.2.0/build/js/utils.js"),
                            customPlaceholder: function (selectedCountryPlaceholder, selectedCountryData) {
                                var pl = selectedCountryPlaceholder.replace(/[0-9]/g, '0');
                                faxNumberId.attr('maxlength', pl.length);
                                $scope.editModalSetPhoneMask(faxNumberId, pl, pl);
                                return pl;
                            }
                        });
                        return $scope.data.editContact.fax_number.iti.promise;
                    }
                })
                .then(() => {
                    if (faxNumberId) {
                        if (number) {
                            $scope.data.editContact.fax_number.iti.setNumber(number);
                            $scope.data.editContact.fax_number.number = number;
                            $scope.data.editContact.fax_number.ngModelNumber = faxNumberId.val();
                        }
                        faxNumberId.on("countrychange", function (e) {
                            $scope.data.editContact.fax_number.iti.setNumber("");
                            $scope.data.editContact.fax_number.ngModelNumber = '';
                            faxNumberId.unmask();

                            var mask = e.target.placeholder.replace(/[0-9]/g, '0');
                            faxNumberId.attr('maxlength', mask.length);
                            $scope.editModalSetPhoneMask(faxNumberId, mask, e.target.placeholder);
                        });
                    }
                })
        };

        $scope.editModalPhoneNumberMask = function (number, id) {
            const fullId = `#edit-contact-phone-${id}`;
            let phoneNumberId;
            let arrayIndex = _.findIndex($scope.data.editContact.phone_numbers, function (p) {
                return p.id == id;
            });

            return generalHelperService.waitForElement(fullId)
                .then(phoneNumberIdInput => {
                    phoneNumberId = $(fullId);
                    if (phoneNumberId && arrayIndex !== -1) {
                        $scope.data.editContact.phone_numbers[arrayIndex].iti = window.intlTelInput(phoneNumberIdInput, {
                            nationalMode: 'false',
                            autoPlaceholder: 'aggressive',
                            separateDialCode: 'true',
                            validationNumberTypes: [
                                'FIXED_LINE',
                                'MOBILE',
                                'FIXED_LINE_OR_MOBILE',
                                'TOLL_FREE',
                                'PREMIUM_RATE',
                                'SHARED_COST',
                                'VOIP',
                                'PERSONAL_NUMBER',
                                'PAGER',
                                'UAN',
                                'VOICEMAIL',
                                'UNKNOWN'
                            ],
                            initialCountry: 'us',
                            // intl-tel-input is up to date as of dec 2024
                            loadUtils: () => import("https://cdn.jsdelivr.net/npm/intl-tel-input@25.2.0/build/js/utils.js"),
                            customPlaceholder: function (selectedCountryPlaceholder, selectedCountryData) {
                                let pl = selectedCountryPlaceholder.replace(/[0-9]/g, '0');
                                phoneNumberId.attr('maxlength', pl.length);
                                $scope.editModalSetPhoneMask(phoneNumberId, pl, pl);
                                return pl;
                            }
                        });
                        return $scope.data.editContact.phone_numbers[arrayIndex].iti.promise
                    }
                })
                .then(() => {
                    if (phoneNumberId && arrayIndex !== -1) {
                        if (number) {
                            $scope.data.editContact.phone_numbers[arrayIndex].iti.setNumber(number);
                            $scope.data.editContact.phone_numbers[arrayIndex].number = number;
                            $scope.data.editContact.phone_numbers[arrayIndex].ngModelNumber = phoneNumberId.val();
                        }
                        $scope.data.editContact.phone_numbers[arrayIndex].number
                        phoneNumberId.on("countrychange", function (e) {
                            $scope.data.editContact.phone_numbers[arrayIndex].iti.setNumber("");
                            $scope.data.editContact.phone_numbers[arrayIndex].ngModelNumber = '';
                            phoneNumberId.unmask();
                            let mask = e.target.placeholder.replace(/[0-9]/g, '0');
                            phoneNumberId.attr('maxlength', mask.length);
                            $scope.editModalSetPhoneMask(phoneNumberId, mask, e.target.placeholder);
                        });
                    }
                });
        };

        $scope.editModalSetContactNumbers = function(contact) {

            if(!_.includes(contact.phone_numbers, contact.main_phone)) {
                contact.phone_numbers.push(contact.main_phone);
            }

            var sortedPhoneNumbers = _.sortBy(contact.phone_numbers, function(item) {
                return item === contact.main_phone ? 0 : 1;
            });

            return Promise.all(sortedPhoneNumbers.map(number => $scope.editModalAddPhoneNumber(number)));
        };

        $scope.editModalGetCustomFields = function(contact) {
            var fields = contact.field_data || [];
            var cFields = [], field;
            for (var i = 0; i < fields.length; i++) {
                field = fields[i];
                if ( !field.type || field.type.indexOf('custom_') !== 0) { continue; }
                cFields.push({
                    type: field.type,
                    field_name: field.field_name,
                    display_name: field.display_name
                });
            }
            return cFields;
        };

        $scope.editModalLoadContact = function(id) {
            var teamId = authService.getTeamId();
            var userId = authService.getUserId();

            const request = {
                team_id: teamId,
                user_id: userId,
                contact_id: id,
            };

            return Promise.all([
                $scope.loadFieldMap(),
                contactService.getContactDetails(request)
            ])
                .then(results => {
                    let map = results[0];
                    if (results[1].status !== 200) {
                        swal({
                            title: 'Error',
                            text: result.message
                        });
                        return;
                    }
                    let contact = results[1].contact;
                    const contactFieldData = angular.copy(contact.field_data);
                    $scope.data.editContact.contactDetails = contact;
                    $scope.data.editContact.contactDetails.field_data = $scope.mapFields(map, contact);
                    $scope.data.editContact.contactDetails.country = _.find($scope.data.editContact.countries, function(o) { return o.code === $scope.data.editContact.contactDetails.country; });
                    $scope.data.editContact.contactDetails.customFields = $scope.editModalGetCustomFields(contact);
                    $scope.data.teamCustomFields = _.sortBy($rootScope.customFields, 'sort_index').map(customField => {
                        const contactCustomData = contactFieldData.find(field => field.type === 'custom_field' && field.custom_field_id === customField.custom_field_id);
                        if (typeof contactCustomData !== 'undefined') {
                            if (customField.type === 'list') {
                                const selectedItem = customField.list_items.find(item => item.value === contactCustomData.value);
                                if (typeof selectedItem !== 'undefined') {
                                    customField.value = selectedItem;
                                }
                            } else {
                                customField.value = contactCustomData.value;
                            }
                        } else {
                            customField.value = null;
                        }
                        return customField;
                    });

                    return Promise.all([
                        $scope.editModalFaxNumberMask($scope.data.editContact.contactDetails.fax_number),
                        $scope.editModalSetContactNumbers(contact)
                    ]);
                })
                .then(()=>{
                    $scope.$apply();
                })
                .catch(err => {
                    console.error(err);
                });
        };

        $scope.mapFields = function(map, contact) {
            let contactFields = [];

            for ( const field of  $scope.data.field_map) {
                let added = false;

                for (let i = 0; i < $scope.data.editContact.contactDetails.field_data.length; i++) {
                    if(field.type === $scope.data.editContact.contactDetails.field_data[i].type) {
                        contactFields.push($scope.data.editContact.contactDetails.field_data[i]);
                        added = true;
                        break;
                    }
                }

                if(!added) {
                    contactFields.push(field)
                }
            }

            return contactFields;
        };

        $scope.editModalClearContactFields = function() {
            let fd = $scope.data.editContact.contactDetails.field_data;
            for (let i = 0; i < fd.length; i++) { fd[i].value = ''; }
        };

        $scope.editModalLoadCountries = function() {
            if($scope.data.editContact.countries.length === 0) {
                return $http.get('assets/content/countries.json')
                    .then(function(res){
                        $scope.data.editContact.countries = res.data.filter(country => country.code !== "E.164");
                    });
            }
            return Promise.resolve();
        };

        $scope.editModalCreateMode = function() {
            return $scope.loadFieldMap()
                .then(map => {
                    $scope.data.editContact.contactDetails.field_data = map;
                    $scope.data.teamCustomFields = _.sortBy($rootScope.customFields, 'sort_index');
                    $scope.editModalClearContactFields();
                    return Promise.all([
                        $scope.editModalFaxNumberMask(''),
                        $scope.editModalAddPhoneNumber()
                ]);
                })
                .then(() => {
                    $scope.$apply();
                })
                .catch(err => {
                    console.error(err);
                });
        };

        $scope.loadFieldMap = function() {

            return new Promise((resolve, reject) => {

                if($scope.data.field_map) {
                    resolve($scope.data.field_map);
                    return;
                }

                const req = {
                    team_id: authService.getTeamId(),
                    user_id: authService.getUserId()
                };

                contactService.getFieldMap(req)
                    .then(map => {
                        for (let i = 0; i < map.length; i++) { map[i].field_id = i; }
                        $scope.data.field_map = map;
                        resolve(map)
                    })
                    .catch(err => reject(err));
            });
        };

        $scope.editModalEditMode = function(contactId, listId) {
            $scope.data.editContact.contactId = contactId;
            $scope.data.editContact.listId = listId;
            return $scope.editModalLoadContact(contactId);
        };

        $scope.$on('addContactEvent', function(event, listId) {
            $scope.data.editContact.list_id = listId;
            $scope.data.editContact.mode = 'create';
            Promise.all([
                $scope.editModalLoadCountries(),
                $scope.editModalCreateMode()
            ])
                .then(() => {
                    $("#addEditContactModal").modal('show');
                });
        });

        $scope.$on('editContactEvent', function(event, contactId, listId) {
            $scope.data.editContact.mode = 'edit';
            $scope.editModalLoadCountries();
            $scope.editModalEditMode(contactId, listId);
            $("#addEditContactModal").modal('show');
        });
    }
    module.exports = AddEditContactModalController;
})();
