(function () {
    'use strict';
    
    function AdminService(apiService, $rootScope) { 
       return {
            impersonate: function (request) {
                return apiService.post($rootScope.settings.API_ADMIN_URL + '/admin/impersonate', request);
            },

            search: function (request) {
                return apiService.post($rootScope.settings.API_ADMIN_URL + '/admin/search', request);
            },

            updateTeam: function (request) {
                return apiService.post($rootScope.settings.API_ADMIN_URL + '/admin/team/update', request);
            }
        }; 
    }
    module.exports = AdminService;
})();




