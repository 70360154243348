(function() {
    'use strict';

    function SMSAutorespondersController($rootScope, $scope, $location, authService, phoneService) {
        var vm = this;
        vm.authService = authService;

        $rootScope.viewData = $rootScope.viewData || {};
        $rootScope.viewData.breadcrumbs = $rootScope.viewData.breadcrumbs || [];
        $rootScope.viewData.breadcrumbs = [{
            title: 'SMS Autoresponders',
            link_name: 'SMS Autoresponders',
            link_url: '#/sms-autoresponders',
            help_url: $rootScope.help_url
        }];
    
        $scope.data = {
            sms: []
        };
        $scope.loading = true;

        $scope.staticText = {
            title: "SMS Autoresponders",
            subtitle: "Configure automatic replies to text messages...",
            newSMSbtn: {
                title: "Add SMS Autoresponder",
                href: "#/sms-config"
            },
            table: {
                header: ["Name", "Used By", "Created At"],
                actions: ["Delete SMS Autoresponder", "View Rules", "Set Default"],
                defaultLabel: "DEFAULT",
                noRes: "You don't have any SMS Autoresponders. ",
                loading: "Loading",
                popoverHtml: "phone-numbers-popover.html"
            }
        };

        $scope.confirmDelete = function(ivr, callback) {
            var req = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                ivr_id: ivr.ivr_id
            };

            phoneService.deleteIvr(req)
                .then(results => {
                    callback(null);
                })
                .catch(err => {
                    console.log(err);
                    callback(err);
                });
        };

        $scope.deleteIVR = function(e, ivr) {
            if (e) {
                e.preventDefault();
            }

            swal({
                title: "Confirm",
                text: "Are you sure you want to delete this SMS Autoresponder?",
                buttons: ["No", "Yes"],
                dangerMode: true,
                closeOnClickOutside: false
            }).then( isConfirm => {
                if (isConfirm) {
                    $scope.confirmDelete(ivr, function(res) {
                        if(res === null) {
                            swal("Deleted!", "This SMS Autoresponder has been deleted!", "success");
                            $scope.loadSmsIVRs();
                            $scope.$apply();
                        } else swal("Cancelled", "Unable to delete SMS Autoresponder", "error");
                    });

                }
            });
        };

        $scope.setDefaultIvr = function(s) {

            if(!s.is_default) {

                const ivrReq = {
                    team_id: authService.getTeamId(),
                    user_id: authService.getUserId(),
                    is_default: true,
                    ivr_id: s.ivr_id,
                    type: s.type
                };

                phoneService.setDefaultIvr(ivrReq)
                    .then(results => {
                        $scope.loadSmsIVRs();
                        $scope.$apply();
                    })
                    .catch(err => {
                        console.log(err);
                        swal("Cancelled", "Unable to set default call routings", "error");
                    });
            }
        };

        $scope.newNameValue = '';
        $scope.editNameField = function(s) {
            if($scope.newNameValue !== s.name) {
                $scope.newNameValue = s.name;
                $scope.updateSmsName(s);
            }
        };

        $scope.updateSmsName = function(s) {
            var ivrReq = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                ivr_id: s.ivr_id,
                type: s.type,
                name: s.name,
                rules: s.rules
            };
            phoneService.updateIvr(ivrReq)
                .then(results => {})
                .catch(err => {
                    console.log(err);
                    swal("Cancelled", "Unable to update SMS Autoresponder", "error");
                });
        };

        $scope.getNumbersUsingIVR = function(teamId, userId) {
            const sms = $scope.data.sms || [];

            async.each(sms, function(s, callback) {
                var req = {
                    ivr_id: s.ivr_id,
                    team_id: teamId,
                    user_id: userId
                };

                phoneService.getNumbersUsingIvr(req)
                    .then(results => {
                        s.phone_numbers = results;
                        callback();
                    }).catch(err => {
                    console.log(err);
                    $scope.loading = false;
                    $scope.$apply();
                });
            }, function() {
                $scope.loading = false;
                $scope.$apply();
            });
        };

        $scope.loadSmsIVRs = function() {
            let req = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId()
            };

            $scope.loading = true;

            phoneService.getIvrs(req)
                .then(results => {
                    var temp = _.filter(results, ['type', 'sms']);
                    $scope.data.sms = _.orderBy(temp, ['is_default', 'created_at']).reverse();
                    $scope.getNumbersUsingIVR(req.team_id, req.user_id);
                    if($scope.data.sms.length === 1)
                        $scope.setDefaultIvr($scope.data.sms[0]);
                    $scope.$apply();
                })
                .catch(err => {
                    console.log(err);
                    alert('Error: Failed to get call routings.');
                    $scope.loading = false;
                    $scope.$apply();
                });
        };

        $scope.hasPermissions = function(entity, action) {
            return $rootScope.hasPermissions(entity, action)
        };

        $scope.viewRules = function(s) {
            $location.path('sms-config').search('id=' + s.ivr_id);
        };

        $scope.onInit = function() {
            $scope.loadSmsIVRs();
        };

        if (authService.hasAccount()) {
            $scope.onInit();
        }
        else {
            $scope.$on('auth_complete', function(event, args) {
                $scope.onInit();
            });
        }
    }
    module.exports = SMSAutorespondersController;
})();
