(function () {
    'use strict';

    function TasksLeftSidebarController($rootScope, $scope, $location, $window, tasksService, authService, $state, callService, phoneService, notificationToastService, storeSelectedTaskService, generalHelperService, contactService) {
        $scope.staticData = {
            loading: 'Loading',
            tooltipOpen: 'Reopen',
            tooltipClose: 'Close',
            reopenOverlayText: 'Task status has been changed to OPEN.',
            noTasks: {
                open: 'No Open Tasks',
                snoozed: 'No Snoozed Tasks',
                closed: 'No Closed Tasks'
            },
            inboundCalls: {
                title: 'Inbound Calls:',
                talkingTo: 'Talking to',
                answerBtn: 'Answer',
                hangupBtn: 'Hangup'
            },
            available_task_statuses: {
                open: 'open',
                snoozed: 'snoozed',
                closed: 'closed'
            }
        };

        $scope.userId = authService.getUserId();
        $scope.defaultAvatar = $rootScope.settings.NO_AVATAR_IMG;

        $scope.availableAssignments = [
            {key: 'you', title: 'You', icon: null},
            {key: 'yourteams', title: 'Your Teams', icon: 'fa-light fa-users-rectangle'},
            // {key: 'mentions', title: 'Mentions', icon: 'fa-light fa-at'},
            {key: 'unassigned', title: 'Unassigned', icon: 'fa-light fa-user'},
            {key: 'all', title: 'All', icon: 'fa-light fa-user-group'}
        ];
        $scope.availableStatuses = [
            {key: 'open', title: 'Open', icon: 'fa-light fa-inbox'},
            {key: 'snoozed', title: 'Snoozed', icon: 'fa-light fa-clock'},
            {key: 'closed', title: 'Closed', icon: 'fa-light fa-check'},
        ];
        $scope.availableSorts = [
            {key: 'asc', title: 'Oldest'},
            {key: 'desc', title: 'Newest'},
            {key: 'urgent', title: 'Priority First'}
        ];
        $scope.tasksListPanelMode = 'view'; //'view', 'bulkSelect'

        $scope.tasksTypes = {
            inbound_call: 'Inbound Call',
            missed_call: 'Missed Call',
            fax: 'Fax',
            sms: 'Inbound SMS',
            voicemail: 'Voicemail',
            email: 'Email',
            note: 'Note'
        };

        $scope.loading = {
            tasks: false,
            tasksFirstLoad: true,
            bulkChangeTaskStatus: false
        };

        $scope.data = {
            unreadTasksByCategory: {
                you: false,
                yourteams: false,
                mentions: false,
                unassigned: false,
                all: false,
            },
            assignmentsUnreadCounts: {
                you: 0,
                yourteams: 0,
                mentions: 0,
                unassigned: 0,
                all: 0
            },
            assignmentsCounts: { //using in the 'filter by user' dropdown
                you: 0,
                yourteams: 0,
                mentions: 0,
                unassigned: 0,
                all: 0
            },
            statusCounts: { //using in the 'filter status' dropdown
                open: 0,
                snoozed: 0,
                closed: 0,
            },
            tasks: [],
            filters: {
                assignment: $scope.availableAssignments[3],
                status: $scope.availableStatuses[0],
                sort: $scope.availableSorts[1]
            },
            tasksBulkSelected: [],
            allTasksSelected: false,

            selectedTask: null,
            inboundCalls: {
                list: $rootScope.inboundCalls,
                isCollapsed: false
            },

            isNewTaskAdded: false,
            infiniteScrollDirection: 'down',
            infiniteScrollLimit: 11,
            taskIdUrl: undefined,
            forceLoadMore: false
        };

        $scope.isLeftSidebarHidden = function () {
            return ($('#tasksLeftPanel').hasClass('shw-lside')) ? false : true;
        };

        $scope.showLeftSideToggleBtn = function (isVisible, callback) {
            let visibility = (isVisible) ? 'visible' : 'hidden';
            $('.min-max-tasks-panel').css('visibility', visibility);
            callback();
        };

        $scope.toggleLeftSideBar = function () {
            $('#tasksLeftPanel').toggleClass('shw-lside');
        };

        $scope.showLeftSidebar = function () {
            $('.page-wrapper').addClass('inbox-page-wrapper-with-left-sidebar');
            $('#tasksLeftPanel').addClass('shw-lside');
        };
        $scope.hideLeftSideBar = function () {
            $('.page-wrapper').removeClass('inbox-page-wrapper-with-left-sidebar');
            $('#tasksLeftPanel').removeClass('shw-lside');
        };

        $scope.checkVisibilityLeftSideBar = function () {
            var width = (window.innerWidth > 0) ? window.innerWidth : this.screen.width;
            if (width < 1180) {
                $scope.hideLeftSideBar();
                $scope.showLeftSideToggleBtn(true, () => {
                });
            } else {
                $scope.showLeftSidebar();
                $scope.showLeftSideToggleBtn(false, () => {
                });
            }
        };

        angular.element($window).bind('resize', function () {
            if ($state.current.url === '/inbox') {
                $scope.checkVisibilityLeftSideBar();
            }
        });

        $scope.checkDraft = function(task) {
            const taskCollection = new Fifo({ namespace: 'tasks' });
            let localStorageTask = taskCollection.get(task.task_id);
            if(localStorageTask && (localStorageTask.email != null || localStorageTask.note != null || localStorageTask.sms != null)) {
                return true;
            }
            return false;
        };

        $scope.formatPhone = function(value) {
            return $rootScope.libPhoneFormatPhone(value);
        };

        $scope.formatDateFromNow = function (timestamp) {
            moment.updateLocale('en', {
                relativeTime: {
                    future: 'now',
                    past: '%s',
                    s: 'now',
                    ss: 'now',
                    m: '%dm',
                    mm: '%dm',
                    h: '%dh',
                    hh: '%dh',
                    d: '1d',
                    dd: '%dd',
                    M: '1mth',
                    MM: '%dmth',
                    y: '1y',
                    yy: '%dy'
                }
            });
            return moment(timestamp).fromNow();
        };

        $scope.formatSeconds = function (totalSeconds) {
            if (totalSeconds < 0) {
                return 'now';
            }

            totalSeconds = parseInt(totalSeconds || 0);
            let hours = Math.floor(totalSeconds / 3600);
            let minutes = Math.floor((totalSeconds - (hours * 3600)) / 60);
            let seconds = totalSeconds - (hours * 3600) - (minutes * 60);

            if (hours < 10 && hours > 0) {
                hours = "0" + hours;
            }
            if (minutes < 10) {
                minutes = "0" + minutes;
            }
            if (seconds < 10) {
                seconds = "0" + seconds;
            }

            let total = minutes + ':' + seconds;
            if (hours > 0) {
                total = hours + ':' + total;
            }
            return total;
        };

        $scope.resetTasksList = function() {
            $scope.unselectAllTasks();
            $scope.data.tasks = [];
            $scope.data.selectedTask = null;
            $rootScope.$broadcast('unsetSelectedTask');
            $rootScope.$broadcast('setSelectedTask', $scope.data.selectedTask);

            $scope.data.isNewTaskAdded = false;
            $scope.data.forceLoadMore = false;
            $scope.data.infiniteScrollDirection = 'down';
            $scope.data.taskIdUrl = undefined;
        };

        $scope.changeFilter = function (selectedItem, filterName) {
            if($scope.data.filters[filterName].key !== selectedItem.key) {
                $scope.data.filters[filterName] = selectedItem;

                $scope.resetTasksList();
                $scope.getTasks(0, 'tasksFirstLoad');
            }
        };

        $scope.addToBulkSelected = function (task) {
            $scope.data.tasksBulkSelected.push(task.task_id);
        };

        $scope.removeFromBulkSelected = function (task) {
            $scope.data.tasksBulkSelected = _.remove($scope.data.tasksBulkSelected, function (t) {
                return t !== task.task_id;
            });
        };

        $scope.taskSelectedBulkSelect = function (task) {
            (task.bulkSelected) ? $scope.addToBulkSelected(task) : $scope.removeFromBulkSelected(task);
        };

        $scope.selectAllTasks = function () {
            _.forEach($scope.data.tasks, function (task) {
                if (_.indexOf($scope.data.tasksBulkSelected, task.task_id) === -1) {
                    task.bulkSelected = true;
                    $scope.addToBulkSelected(task);
                }
            });
        };
        $scope.unselectAllTasks = function () {
            _.forEach($scope.data.tasks, function (task) {
                task.bulkSelected = false;
            });

            $scope.data.tasksBulkSelected = [];
            $scope.loading.bulkChangeTaskStatus = false;
        };

        $scope.selectUnselectAllTasks = function () {
            ($scope.data.allTasksSelected) ? $scope.selectAllTasks() : $scope.unselectAllTasks();
        };

        $scope.removeTaskFromListMoveToNext = function(taskId, reason, moveToNextTask) {
            const index = _.findIndex($scope.data.tasks, function (t) {
                return t.task_id === taskId;
            });
            if (index === -1) {
                return;
            }
            $scope.data.tasks[index].showOverlayTasksList = false;
            if(
                (reason === 'statusChanged' && $scope.data.tasks[index].status !== $scope.data.filters.status.key) ||
                reason === 'assigneeChanged'
            ) {
                $scope.data.tasks.splice(index, 1);

                 if($scope.data.selectedTask !== null && $scope.data.selectedTask.task_id === taskId && moveToNextTask) {
                    $scope.setSelectedTask((index >= $scope.data.tasks.length) ? $scope.data.tasks[0] : $scope.data.tasks[index]);
                }
            }
        };

        $scope.updateListTaskAssignmentChanged = function(task) {
            Promise.all([
                $scope.getTasksCounts()
            ])
            .then(() => {
                switch($scope.data.filters.assignment.key) {
                    case 'unassigned':
                        if(task.assigned_type !== null) {
                            $scope.removeTaskFromListMoveToNext(task.task_id, 'assigneeChanged', true);
                        }
                        break;
                    case 'you':
                        if((task.assigned_type !== 'user' && task.assigned_type !== 'current_user') ||
                            task.assigned_to.user_id !== authService.getUserId()) {
                            $scope.removeTaskFromListMoveToNext(task.task_id, 'assigneeChanged', true);
                        }
                        break;
                    case 'yourteams':
                        var userGroupIds = authService.getUser().user_group_ids;
                        if((task.assigned_type !== 'team' && task.assigned_type !== 'user_group') ||
                            _.findIndex(userGroupIds, function(groupId) { return groupId === task.assigned_to.user_group_id; }) === -1) {
                            $scope.removeTaskFromListMoveToNext(task.task_id, 'assigneeChanged', true);
                        }
                        break;
                }
                $scope.forceLoadMoreOrReset();
                $scope.$apply();
            });
        };

        $scope.updateListTaskStatusChanged = function(taskId) {
            Promise.all([
                $scope.getTasksCounts()
            ])
            .then(() => {
                $scope.removeTaskFromListMoveToNext(taskId, 'statusChanged', true);
                $scope.forceLoadMoreOrReset();
                $scope.$apply();
            });
        };

        $scope.getNotificationAndRequestStatus = function(newStatus, isBulkSelect) {
            let result = {
                successNotificationIcon: '',
                successNotificationMessage: '',
                newStatus: ''
            };

            switch (newStatus) {
                case $scope.staticData.available_task_statuses.open:
                    result.newStatus = $scope.staticData.available_task_statuses.open;
                    result.successNotificationIcon = (!isBulkSelect) ? 'fa-light fa-inbox' : 'fa-light fa-circle-info';
                    result.successNotificationMessage = (!isBulkSelect) ? 'Task status has been changed to OPEN!' : 'Task statuses have been changed to OPEN!';
                    break;
                case $scope.staticData.available_task_statuses.snoozed:
                    result.newStatus = $scope.staticData.available_task_statuses.snoozed;
                    result.successNotificationIcon = (!isBulkSelect) ? 'fa-light fa-clock' : 'fa-light fa-circle-info';
                    result.successNotificationMessage = (!isBulkSelect) ? 'Task status has been changed to SNOOZED!' : 'Task statuses have been changed to SNOOZED!';
                    break;
                case $scope.staticData.available_task_statuses.closed:
                    result.newStatus = $scope.staticData.available_task_statuses.closed;
                    result.successNotificationIcon = (!isBulkSelect) ? 'fa-light fa-check' : 'fa-light fa-circle-info';
                    result.successNotificationMessage = (!isBulkSelect) ? 'Task status has been changed to CLOSED!' : 'Task statuses have been changed to CLOSED!';
                    break;
            }
            return result;
        };

        $scope._changeTaskStatus = function(taskId, newStatus) {
            const request = {
                task_id: taskId,
                status: newStatus
            };

            return tasksService.updateTaskStatus(request);
        };

        $scope.changeTaskStatus = function (task, newStatus) {
            $scope.unselectAllTasks();
            let statusInfo = $scope.getNotificationAndRequestStatus(newStatus, false);
            task.changeTaskStatus[newStatus] = true;

            $scope._changeTaskStatus(task.task_id, statusInfo.newStatus)
                .then(res => {
                    task.status = res.status;
                    $scope.updateListTaskStatusChanged(task.task_id);
                    task.changeTaskStatus[newStatus] = false;
                    $scope.$apply();
                    //notificationToastService.showSuccessToast(statusInfo.successNotificationIcon, statusInfo.successNotificationMessage);
                })
                .catch(err => {
                    console.error(err);
                    task.changeTaskStatus[newStatus] = false;
                    $scope.$apply();
                    notificationToastService.showErrorToast('fa-light fa-triangle-exclamation', 'Something went wrong. Try again later.');
                });
        };

        $scope.forceLoadMoreOrReset = function() {
            if($scope.data.tasks.length === 0) {
                $scope.resetTasksList();
                $scope.getTasks(0, 'tasksFirstLoad');
            } else if($scope.data.tasks.length < $scope.data.infiniteScrollLimit && $scope.data.tasks.length < $scope.data.statusCounts[$scope.data.filters.status.key]) {
                $scope.data.forceLoadMore = true;
                $scope.loadMoreTasks();
            }
        };

        $scope.bulkUpdateListTaskStatusChanged = function () {
            Promise.all([
                $scope.getTasksCounts()
            ]).then(() => {
                $scope.forceLoadMoreOrReset();
            });
        };

        $scope.bulkChangeTaskStatus = function(newStatus) {
            const request = {
                task_ids: $scope.data.tasksBulkSelected,
                status: newStatus
            };
            let count = 0;
            let successCount = 0;
            let errorCount = 0;
            let statusInfo = $scope.getNotificationAndRequestStatus(newStatus, true);
            $scope.loading.bulkChangeTaskStatus = true;

            tasksService.updateTasksStatus(request)
                .then(res => {
                    _.forEach($scope.data.tasksBulkSelected, function (taskId) {
                        if (typeof res[taskId] === 'object' && res[taskId].result) {
                            const index = _.findIndex($scope.data.tasks, {task_id: taskId});
                            $scope.data.tasks[index].bulkSelected = false;

                            switch (res[taskId].result) {
                                case 'success':
                                    successCount++;
                                    $scope.data.tasks[index].status = newStatus;

                                    $scope.removeTaskFromListMoveToNext(taskId, 'statusChanged', false);

                                    if(count === ($scope.data.tasksBulkSelected.length - 1) && $scope.data.selectedTask !== null && _.includes($scope.data.tasksBulkSelected, $scope.data.selectedTask.task_id)) {
                                        $scope.setSelectedTask((index >= $scope.data.tasks.length) ? $scope.data.tasks[0] : $scope.data.tasks[index]);
                                    }
                                    break;
                                case 'error':
                                    errorCount++;
                                    break;
                            }
                        }
                        count++;
                    });
                })
                .then(() => {
                    $scope.unselectAllTasks();
                    $scope.loading.bulkChangeTaskStatus = false;

                    $scope.bulkUpdateListTaskStatusChanged();

                    $scope.$apply();
                    let message = statusInfo.successNotificationMessage + '<br><br>' + 'Success: ' + successCount + '<br>' + 'Error: ' + errorCount + '<br>';
                    notificationToastService.showInfoToast(statusInfo.successNotificationIcon, message);
                })
                .catch(err => {
                    console.error(err);
                    $scope.loading.bulkChangeTaskStatus = false;
                    $scope.$apply();
                    notificationToastService.showErrorToast('fa-light fa-triangle-exclamation', 'Something went wrong. Try again later.');
                });
        };

        $scope.loadContact = function(id) {
            var teamId = authService.getTeamId();
            var userId = authService.getUserId();

            const request = {
                team_id: teamId,
                user_id: userId,
                contact_id: id,
            };

            return new Promise((resolve, reject) => {
                contactService.getContactDetails(request)
                    .then(result => {
                        if (result.status !== 200) {
                            return;
                        }
                        resolve(result.contact);
                    })
                    .catch(err => {
                        console.error(err);
                        reject(err);
                    });
            })
        };

        $scope._setSelectedTask = function(task) {
            if ($scope.data.selectedTask && $scope.data.selectedTask.remove_after_unselect) {
                $scope.removeTaskFromListMoveToNext($scope.data.selectedTask.task_id, 'statusChanged', true);
            }
            if (task.contact_id) {
                $scope.loadContact(task.contact_id)
                    .then(contact => {
                        task.contact = contact;
                        $scope.data.selectedTask = task;
                        if (task && task.unread) {
                            $scope.markTaskAsRead(task);
                        }
                        storeSelectedTaskService.setTask(task);
                        $rootScope.$broadcast('setSelectedTask', $scope.data.selectedTask);
                    })
                    .catch(err => {
                        console.error(err);
                    })
                    .then(() => {
                        $scope.$apply();
                    });
            } else {
                task.contact = {};
                $scope.data.selectedTask = task;
                if (task && task.unread) {
                    $scope.markTaskAsRead(task);
                }
                storeSelectedTaskService.setTask(task);
                $rootScope.$broadcast('setSelectedTask', $scope.data.selectedTask);
            }
        };

        $scope.markTaskAsRead = function(task) {
            const req = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                task_id: task.task_id,
                unread: false
            };

            let checkUnreadPromise = Promise.resolve();
            if ($scope.data.filters.assignment.key !== 'all') {
                checkUnreadPromise = tasksService.hasUnreadInCategory({tasks_category: $scope.data.filters.assignment.key})
                    .then(result => {
                        $scope.data.unreadTasksByCategory[$scope.data.filters.assignment.key] = result.has_unread;
                        $scope.data.assignmentsUnreadCounts[$scope.data.filters.assignment.key] = result.has_unread ?
                            --$scope.data.assignmentsUnreadCounts[$scope.data.filters.assignment.key] : 0;
                    });
            }

            return tasksService.updateTask(req)
                .then(() => checkUnreadPromise)
                .then(() => {
                    task.unread = false;
                    $rootScope.$broadcast('mark.as.read.notification.badge', task);
                })
                .catch(err => {
                    console.error(err);
                });
        };

        $scope.setSelectedTask = function (task) {
            if(!task || task === null) {
                $scope._setSelectedTask(null);
            } else {
                if($scope.data.selectedTask === null || ($scope.data.selectedTask.task_id !== task.task_id && !$scope.data.selectedTask.loadingTimeline)) {
                    $scope._setSelectedTask(task);
                }
            }
        };

        $scope.concatTasksList = function(dest, src) {
            $scope.data.tasks = _.concat( dest, src );
        };

        $scope.getTasks = function (skip, loader) {
            $scope.loading[loader] = true;

            const request = Object.assign({
                limit: $scope.data.infiniteScrollLimit,
                skip: skip || 0
            }, Object.keys($scope.data.filters).reduce((request, key) => {
                if ($scope.data.filters[key]) {
                    request[key] = $scope.data.filters[key].key;
                }
                return request;
            }, {}));

            return Promise.all([
                tasksService.getTasks(request),
                $scope.getTasksCounts()
            ])
                .then(([results, counts]) => {
                    let newTasks = results.tasks.filter(task => !$scope.data.inboundCalls.list.map(inboundCallTask => inboundCallTask.task_id).includes(task.task_id));
                    let historicalTasks = newTasks.filter(task => task.task_type !== 'call' || (task.task_type === 'call' && task.call && task.call.completed_at));
                    let queuedTasks = newTasks.filter(task => task.task_type === 'call' && task.call && task.call.call_state === 'queued' && !task.call.completed_at).map(task => {
                        task.state = 'ringing';
                        return task;
                    });
                    $scope.concatTasksList($scope.data.tasks, historicalTasks);
                    $scope.data.inboundCalls.list = [...$scope.data.inboundCalls.list, ...queuedTasks];

                    if ($scope.data.selectedTask === null) {
                        $scope.setSelectedTask($scope.data.tasks[0]);
                    }

                    $scope.loading[loader] = false;
                    $scope.$apply();
                })
                .catch(err => {
                    console.error(err);
                    $scope.loading[loader] = false;
                    $scope.$apply();
                })
                .finally(res => {
                    $rootScope.$broadcast('inbox-reload');
                });
        };

        $scope.getNewTimelineItem = function (ablyEventData) {
            const teamId = authService.getTeamId();
            const userId = authService.getUserId();
            const request = {
                user_id: userId,
                team_id: teamId,
                task_id: ablyEventData.task_id,
                entry_id: ablyEventData.entry_id,
            }

            tasksService.getTaskTimelineItem(request)
                .then(item => {
                    const task = $scope.data.tasks.find(task => task.task_id === ablyEventData.task_id);
                    const index = $scope.data.tasks.indexOf(task);
                    $scope.data.tasks[index].last_record = item;
                    $scope.$apply();
                });
        }

        $scope.getTasksCounts = function () {
            const request = Object.keys($scope.data.filters).reduce((request, key) => {
                request[key] = $scope.data.filters[key].key;
                return request;
            }, {});

            return tasksService.getTasksCounts(request)
                .then(results => {
                    $scope.data.assignmentsCounts.all = results.total_count;

                    const yourTasks = results.assignee_counts.find(count => count.assigned_to === 'your') || {};
                    $scope.data.assignmentsCounts.you = yourTasks.count || 0;
                    $scope.data.unreadTasksByCategory.you = yourTasks.has_unread || false;
                    $scope.data.assignmentsUnreadCounts.you = yourTasks.unread_count || 0;

                    const yourTeamsTasks = results.assignee_counts.find(count => count.assigned_to === 'team') || {};
                    $scope.data.assignmentsCounts.yourteams = yourTeamsTasks.count || 0;
                    $scope.data.unreadTasksByCategory.yourteams = yourTeamsTasks.has_unread || false;
                    $scope.data.assignmentsUnreadCounts.yourteams = yourTeamsTasks.unread_count || 0;

                    const unassignedTasks = results.assignee_counts.find(count => count.assigned_to === 'unassigned') || {};
                    $scope.data.assignmentsCounts.unassigned = unassignedTasks.count || 0;
                    $scope.data.unreadTasksByCategory.unassigned = unassignedTasks.has_unread || false;
                    $scope.data.assignmentsUnreadCounts.unassigned = unassignedTasks.unread_count || 0;

                    $scope.data.statusCounts.open = (results.status_counts.find(count => count.status === 'open') || {}).count || 0;
                    $scope.data.statusCounts.snoozed = (results.status_counts.find(count => count.status === 'snoozed') || {}).count || 0;
                    $scope.data.statusCounts.closed = (results.status_counts.find(count => count.status === 'closed') || {}).count || 0;
                })
                .catch(err => {
                    console.error(err);
                    $scope.$apply();
                });
        };

        $scope.hasUnread = function() {
            return $scope.data.unreadTasksByCategory.you || $scope.data.unreadTasksByCategory.yourteams || $scope.data.unreadTasksByCategory.unassigned;
        }

        $scope.loadMoreTasks = function () {
            if(!$scope.loading.tasks) {
                let total = $scope.data.statusCounts[$scope.data.filters.status.key];
                let skip = $scope.data.tasks.length;

                if(skip > 0 && skip < total) {
                    $scope.getTasks(skip, 'tasks');
                }
            }
        };

        $scope.renderedTasksListener = function() {
            if($scope.data.isNewTaskAdded) $scope.data.isNewTaskAdded = false;
            if($scope.data.forceLoadMore) $scope.data.forceLoadMore = false;

            _.forEach($scope.data.tasks, function(task) {
                if(!task.changeTaskStatus) {
                    task.changeTaskStatus = {
                        open: false,
                        snoozed: false,
                        closed: false
                    };
                }
            });
        };

        $scope.setHeightTasksScrollDiv = function(height) {
            $('#tasks-list').css('height', height);
            $('.inbox-left-sidebar-section .left-sidebar .loader').css('height', height);
        };

        $scope.resizeMainDiv = function() {
            let height = 'calc(100vh - (282px + ' + $('#inbound-calls-list').height() + 'px))';
            $scope.setHeightTasksScrollDiv(height);
        };
        $scope.resizeInboundCallsMainDiv = function(size) {
            $scope.resizeMainDiv();
        };

        $scope.initInboundCallTasksCollapse = function () {
            document.querySelector('#inboundCallTasksCollapse').addEventListener('transitionend', $scope.resizeMainDiv);
            $('#inboundCallTasksCollapse').on('show.bs.collapse', function () {
                $scope.data.inboundCalls.isCollapsed = false;
            });
            $('#inboundCallTasksCollapse').on('hide.bs.collapse', function () {
                $scope.data.inboundCalls.isCollapsed = true;
            });

            generalHelperService.onRemoveElement('#inbound-calls-list')
                .then(() => {
                    $scope.setHeightTasksScrollDiv('');
                });
        };

        $scope.inboundCallAction = function (task) {
            switch (task.state) {
                case 'ringing':
                    $scope.setSelectedTask(task);
                    let req = {
                        team_id: authService.getTeamId(),
                        user_id: authService.getUserId(),
                        access_number: $rootScope.access_number,
                        initial_action: callService.CONTROL_ACTIONS.CALL_INTERCEPT,
                        intercept_cid: task.call_id,
                        enable_audio: true
                    };
                    callService.dial(req);
                    break;
                case 'answered':
                    const teamId = authService.getTeamId();
                    const userId = authService.getUserId();

                    const request = {
                        team_id: teamId,
                        user_id: userId,
                        call_id: task.call_id
                    };

                    phoneService.hangupCall(request)
                        .then(results => {
                            delete task.state;
                            $scope.moveCallFromRingingToInbound(task.call_id);
                            $scope.$apply();
                        })
                        .catch(err => {
                            console.error(err);
                            delete task.state;
                            $scope.$apply();
                        });
                    break;
            }
        };

        $scope.infiniteScrollListener = function(scrollsDown) {};

        $scope.getNewTask = function (request, force = false) {
            let req = {};

            if (!force) {
                req = Object.keys($scope.data.filters).reduce((request, key) => {
                    request[key] = $scope.data.filters[key].key;
                    return request;
                }, {});
            }
            if (typeof request.call_id !== 'undefined') {
                req.call_id = request.call_id;
            }
            if (typeof request.task_id !== 'undefined') {
                req.task_id = request.task_id;
            }

            tasksService.getTask(req)
                .then(task => {
                    if (task) {
                        if (task.task_type === 'inbound_call') {
                            task.state = 'ringing';
                            $scope.data.inboundCalls.list.push(task);
                        } else {
                            const taskToUpdate = $scope.data.tasks.find(existingTask => existingTask.task_id === task.task_id);
                            if (typeof taskToUpdate === 'undefined') {
                                $scope.data.tasks.unshift(task);
                                $scope.data.isNewTaskAdded = true;
                            } else {
                                if ($scope.data.selectedTask.task_id !== req.task_id) {
                                    taskToUpdate.unread = true;
                                }
                            }
                        }
                        $scope.getTasksCounts();
                        $scope.$apply();
                    }
                })
        }

        $scope.getLastRecordForTask = function(request) {
            let req = {
                task_id: request.task_id,
                with_last_record: true
            };

            tasksService.getTask(req)
                .then(task => {
                    let taskIndex = $scope.data.tasks.findIndex(task => task.task_id === request.task_id);
                    if(taskIndex > -1) {
                        $scope.data.tasks[taskIndex].last_record = task.last_record;

                        if ($scope.data.selectedTask.task_id !== req.task_id) {
                            $scope.data.tasks[taskIndex].unread = true;
                        }
                    }

                    $scope.getTasksCounts();
                })
        }

        $scope.moveCallFromRingingToInbound = function (call_id) {
            const call = $scope.data.inboundCalls.list.splice($scope.data.inboundCalls.list.indexOf(task => task.call_id === call_id), 1)[0];
            if (typeof call !== 'undefined') {
                const request = Object.assign({
                    limit: 10,
                    skip: 0
                }, Object.keys($scope.data.filters).reduce((request, key) => {
                    request[key] = $scope.data.filters[key].key;
                    return request;
                }, {}));

                tasksService.getTasks(request)
                    .then(result => {
                        const taskIndex = result.tasks.findIndex(task => task.call_id === call.call_id);
                        if (taskIndex !== -1) {
                            $scope.data.tasks.splice(taskIndex, 0, result.tasks[taskIndex]);
                            $scope.data.isNewTaskAdded = true;
                            $scope.$apply();
                        } else {
                            $rootScope.$broadcast('unsetSelectedTask', true);
                        }
                    })
            }
        }

        $scope.getDuration = function (task) {
            const diff = moment().unix() - moment(task.answered_at).unix();
            return Number(moment.utc(diff)) || 0
        }

        $scope.$on('bulkTasksUnselectAll', function () {
            $scope.unselectAllTasks();
        });

        $scope.$on('moveCallFromRingingToInbound', function (e) {
            $scope.moveCallFromRingingToInbound(e)
        });

        $scope.$on('ivr.hangup', function (e, ablyEvent) {
            $scope.moveCallFromRingingToInbound(ablyEvent.data.call_id);
            $rootScope.$broadcast('task.call.hangup');
        });

        $scope.$on('agent.hangup', function (e, ablyEvent) {
            $scope.moveCallFromRingingToInbound(ablyEvent.data.call_id)
            $rootScope.$broadcast('task.call.hangup', ablyEvent.data.call_id);
        });

        $scope.$on('task.created', function (e, ablyEvent) {
            if (ablyEvent.data.task_type !== 'inbound_call') {
                $scope.getNewTask(ablyEvent.data);
            }
        });

        $scope.$on('ivr.queued', function (e, ablyEvent) {
            $scope.getNewTask(ablyEvent.data, true);
        });

        $scope.$on('task.opened', function (e, ablyEvent) {
            $scope.handleRemoteStatusChange(ablyEvent.data.task_id, ablyEvent.data.user_id, ablyEvent.data.status);
        });

        $scope.$on('task.closed', function (e, ablyEvent) {
            $scope.handleRemoteStatusChange(ablyEvent.data.task_id, ablyEvent.data.user_id, ablyEvent.data.status);
        });

        $scope.$on('call.answer', function (e, ablyEvent) {
            let eventData = ablyEvent.data || {};
            const answeredTask = $scope.data.inboundCalls.list.find(task => task.call_id === eventData.call_id);
            if (typeof answeredTask !== "undefined") {
                answeredTask.answered_user = `${authService.getUser().first_name} ${authService.getUser().last_name}`;
                answeredTask.answered_at = Date.now();
                answeredTask.state = 'answered';
                $scope.$apply();
            }
            $rootScope.$broadcast('task.call.answer', answeredTask);
        });

        $scope.$on('task.status.changed', function (event, ablyEvent) {
            const task = $scope.data.tasks.find(task => task.task_id === ablyEvent.data.task_id);
            if (typeof task !== 'undefined') {
                $scope.updateListTaskStatusChanged(task.task_id);
            } else {
                $scope.getNewTask(ablyEvent.data);
            }
        });

        $scope.$on('changeTaskStatusEvent', function (event, task) {
            $scope.updateListTaskStatusChanged(task.task_id);
        });

        $scope.$on('changeTaskAssignmentEvent', function (event, task) {
            $scope.updateListTaskAssignmentChanged(task);
        });

        $scope.$on('getTasks', function () {
            $scope.resetTasksList();
            let params = $location.search();
            $scope.data.taskIdUrl = params.task_id; // /inbox?task_id=

            if($scope.data.taskIdUrl) {
                $scope.data.filters.assignment = $scope.availableAssignments[4];

                tasksService.getTask({task_id: $scope.data.taskIdUrl})
                    .then(result => {
                        $scope.setSelectedTask(result);
                    })
            }
            $scope.getTasks(0, 'tasksFirstLoad');
        });

        $scope.$on('$stateChangeStart', function (e, toState, toParams, fromState, fromParams) {
            $scope.hideLeftSideBar();
            $scope.showLeftSideToggleBtn(false, () => {
            });
            if(fromState.url === '/inbox') {
                storeSelectedTaskService.setTask(null);
            }
        });

        $scope.$on('email.received', function (e, ablyEvent) {
            $scope.getLastRecordForTask(ablyEvent.data);
        });

        $scope.$on('email.sent', function (e, ablyEvent) {
            $scope.getLastRecordForTask(ablyEvent.data);
        });

        $scope.$on('sms.received', function (e, ablyEvent) {
            $scope.getLastRecordForTask(ablyEvent.data);
        });

        $scope.$on('sms.sent', function (e, ablyEvent) {
            $scope.getLastRecordForTask(ablyEvent.data);
        });

        $scope.$on('note.created', function (e, ablyEvent) {
            $scope.getLastRecordForTask(ablyEvent.data);
        });

        $scope.$on('mark.as.read.notification.badge', function (e, task) {
            if(!task.unread) {
                $scope.getTasksCounts();
            }
        });

        $scope.handleRemoteStatusChange = function (taskId, userId, newStatus) {
            if (authService.getUserId() !== userId) {
                const index = _.findIndex($scope.data.tasks, function (t) {
                    return t.task_id === taskId;
                });
                if ($scope.data.selectedTask && $scope.data.selectedTask.task_id === taskId) {
                    $scope.data.selectedTask.showOverlayTasksList = true;
                    $scope.data.selectedTask.newStatus = newStatus;
                    $scope.data.selectedTask.remove_after_unselect = true;
                    $scope.data.tasks[index].status = newStatus;
                    $scope.data.tasks[index].remove_after_unselect = true;
                    $scope.$apply();
                } else {
                    $scope.data.tasks[index].status = newStatus;
                    $scope.removeTaskFromListMoveToNext(taskId, 'statusChanged', true);
                }
            }
        }

        $scope.getOverlayText = function (task) {
            return `Task status has been changed to ${task.newStatus}.`;
        }

        $scope.onInit = function () {
            $scope.checkVisibilityLeftSideBar();

            $scope.$watch('data.tasksBulkSelected', function (newValue, oldValue) {
                $scope.tasksListPanelMode = ($scope.data.tasksBulkSelected.length > 0) ? 'bulkSelect' : 'view';
                $scope.data.allTasksSelected = ($scope.data.tasksBulkSelected.length === $scope.data.tasks.length) ? true : false;

                $rootScope.$broadcast('bulkTasksSelect', $scope.data.tasksBulkSelected.length);
            }, true);
        };

        if (authService.hasAccount()) {
            $scope.onInit();
        } else {
            $scope.$on('auth_complete', function (event, args) {
                $scope.onInit();
            });
        }
    }

    module.exports = TasksLeftSidebarController;
})();
