(function () {

    'use strict';

    function CampaignResultsController($rootScope, $scope, $location, authService, campaignService, $window, $interval) {
        const vm = this;
        vm.authService = authService;

        $rootScope.viewData = $rootScope.viewData || {};
        $rootScope.viewData.breadcrumbs = $rootScope.viewData.breadcrumbs || [];
        $rootScope.viewData.breadcrumbs = [{
            title: 'RVM Campaigns',
            link_name: 'RVM Campaigns',
            link_url: '#/bulk-campaigns',
            help_url: $rootScope.help_url
        }, {
            title: 'Campaign Results',
            link_name: 'Campaign Results',
            link_url: '#/voicemail-campaign-results',
            help_url: $rootScope.help_url
        }];


        $scope.staticData = {
            header: {
                titles: ['Messages Sent', 'Messages Failed', 'Messages Pending']
            },
            graph: {
                series: ["Messages Sent", "Messages Failed", "Messages Pending"],
                message: "No Data in",
                statisticPeriod: "24 Hours",
                loading: "Loading",
                switch: {
                    btn24Hours: {title: "Last 24 Hours"},
                    btn7Days: {title: "Last 7 Days"}
                }
            },
            campaings: {
                title: "Campaign Results",
                subtitle: "View or export the results of your campaign...",
                retryBtn: { title: "Retry Failed Phone Numbers" },
                exportBtn: { title: "Export Results" },
                table: {
                    header: [ "Phone Number", "Attempts", "Status", "Status Reason", "Carrier", "Callbacks", "SMS Count", "Date" ],
                    recordingWarningPart1: "Recording will be not available after ",
                    recordingWarningPart2: " days",
                    recordingIsNotAvaliablePart1: "Recording expired ",
                    recordingIsNotAvaliablePart2: " and may not be available",
                    noRes: "No Results",
                    loading: "Loading",
                    total: "TOTAL:"
                }
            }
        };
        
        $scope.showHideSMSmessage = function(id) {
            $('#sms-message-' + id + '-div .sms-message').toggleClass('more');
            var moreLessAtext = $('#sms-message-' + id + '-div .sms-message').hasClass('more') ? "LESS" : "MORE";
            $('#sms-message-' + id + '-div .more-less-a').text(moreLessAtext);
        };
        
        /////////////////////////////////////////////////////////////////////
        $scope.timeFormat = {
            defaultTimezone: 'America/New_York',
            hours: 24,
            minutes: 60,
            minutes3h: 180,
            minutesBaseFormat: 'YYYY-MM-DD HH:mm',
            minutesDisplayFormat: 'hh:mm A',
            hoursBaseFormat: 'YYYY-MM-DD HH',
            hoursMinFormat: 'YYYY-MM-DD HH:mm',
            hoursDisplayFormat: 'hh A',
            daysBaseFormat: 'YYYY-MM-DD',
            daysDisplayFormat: 'MMM D',
            days: 7
        };
        
        $scope.data = {
            campaign_id: null,
            summary: null,
            screenWidth: $window.innerWidth,
            graph: {
                isGraphEmpty: undefined,
                loadingGraph: true,
                statisticPeriod: moment().subtract(60,'m').valueOf(),
                success: [],
                pending: [],
                failure: [],
                labels: []
            },
            statistic: {
               success: 0, 
               pending: 0,
               failure: 0,
               summaryTime: undefined
            },
            results: {
                campaign: {},
                loading: true,
                exporting: false,
                saving: false,
                export_id: undefined,
                records: [],
                itemsPerPage: 10,
                currentPage: 1,
                maxSize: 3,
                total: 0,
                stopTime: undefined
            },
            voicemails: {
                failure: 0,
                pending: 0,
                success: 0
            }
        };

        $scope.export = {
            id: null,
            status: null,
            link: null
        };
        
        $scope.changePeriod = function(btnId, callback) {
            $scope.data.graph.isGraphEmpty = undefined;
            $scope.data.graph.loadingGraph = true;
            switch(btnId) {
                case 'btn24Hours':
                    $( "#btn7Days" ).removeClass( "active" );
                    $( "#btn24Hours" ).addClass( "active" );
                    $scope.staticData.graph.statisticPeriod = "24 Hours";
                    $scope.data.graph.statisticPeriod = moment().subtract(1,'d').valueOf();

                    $scope._changePeriod(
                        true,
                        true,
                        true,
                        true,
                        $scope.data.graph.statisticPeriod,
                        $scope.timeFormat.hoursDisplayFormat,
                        $scope.timeFormat.hoursBaseFormat,
                        'hours',
                        $scope.timeFormat.hours,
                        null
                    );
                    break;
                case 'btn7Days':
                    $( "#btn24Hours" ).removeClass( "active" );
                    $( "#btn7Days" ).addClass( "active" );
                    $scope.staticData.graph.statisticPeriod = "7 Days";
                    $scope.data.graph.statisticPeriod = moment().subtract(7,'d').valueOf();

                    $scope._changePeriod(
                        true,
                        true,
                        true,
                        false,
                        $scope.data.graph.statisticPeriod,
                        $scope.timeFormat.daysDisplayFormat,
                        $scope.timeFormat.daysBaseFormat, 'days',
                        $scope.timeFormat.days,
                        null
                    );
                    break;
            }

            if (callback) {
                callback();
            }
        };
        
        $scope._changePeriod = function(yearly, monthly, daily, hourly, start_at, format, _format, type, subtract, interval) {
            $scope.loadSummary(yearly, monthly, daily, hourly, start_at, function(res) {
                if (res !== null) {
                    $scope.data.summary = res.summary;
                    $scope.data.statistic.success = 0;
                    $scope.data.statistic.failure = 0;
                    $scope.data.statistic.pending = 0;
                    $scope.data.graph.labels = [];
                    $scope.data.graph.success = [];
                    $scope.data.graph.failure = [];
                    $scope.data.graph.pending = [];

                    $scope.calcStatistic(res.summary, subtract, type, _format, interval, function() {
                        $scope.barChart(format);
                    });
                }
            });
        };
        
        $scope.loadSummary = function(yearly, monthly, daily, hourly, start_at, callback) {

            if (!$scope.loading_summary) {
                $scope.loading_summary = true;

                const teamId = authService.getTeamId();
                const userId = authService.getUserId();

                let req = {
                    campaign_id: $scope.data.campaign_id,
                    team_id: teamId,
                    user_id: userId,
                    yearly: yearly,
                    monthly: monthly,
                    daily: daily,
                    hourly: hourly,
                    timezone: moment.tz.guess() || $scope.timeFormat.defaultTimezone,
                    start_at: start_at
                };

                campaignService.getSummary(req)
                    .then(results => {
                        $scope.loading_summary = false;
                        $scope.$apply();
                        callback(results);
                    })
                    .catch(err => {
                        console.log(err);
                        $scope.loading_summary = false;
                        $scope.$apply();
                        callback(null);
                    });
            }
        };
        
        $scope.calcStatistic = function(summary, subtract, type, format, interval, callback) {
            $scope.data.graph.isGraphEmpty = (summary && Object.keys(summary).length > 0 ? false : true);

            if (!$scope.data.graph.isGraphEmpty) {
                var dateArr = [];
                for(var i = 0; i < subtract; i++) {
                    var mom = moment().tz(moment.tz.guess() || $scope.timeFormat.defaultTimezone).minutes(0).subtract(i, type);
                    var dateStr = mom.format(format);
                    var ms = mom.valueOf();
                    
                    if (interval !== null) {
                        if (i%interval === 0) {
                            dateArr.push({ x: ms, y: 0, dateStr: dateStr });
                            $scope.data.graph.labels.push(ms);
                        }
                    } else {
                        dateArr.push({ x: ms, y: 0, dateStr: dateStr });
                        $scope.data.graph.labels.push(ms);
                    }
                }

                $scope.data.graph.success = _.map(dateArr, _.clone);
                $scope.data.graph.failure = _.map(dateArr, _.clone);
                $scope.data.graph.pending = _.map(dateArr, _.clone);

                _.map(summary, function(value, key) {
                    $scope.data.statistic.success += value.rvm.success;
                    $scope.data.statistic.failure += value.rvm.failure;
                    $scope.data.statistic.pending += value.rvm.pending;

                    var index = _.findIndex(dateArr, ['dateStr', key]);
                    if(index > -1) {
                        $scope.data.graph.success[index].y = value.rvm.success;
                        $scope.data.graph.failure[index].y = value.rvm.failure;
                        $scope.data.graph.pending[index].y = value.rvm.pending;
                    }
                });
                
                $scope.data.graph.labels = _.sortBy($scope.data.graph.labels);
                $scope.data.graph.success = _.sortBy($scope.data.graph.success, 'x');
                $scope.data.graph.failure = _.sortBy($scope.data.graph.failure, 'x');
                $scope.data.graph.pending = _.sortBy($scope.data.graph.pending, 'x');
            }
            $scope.data.graph.loadingGraph = false;
            callback();
        };
        
        $scope.barChart = function(labelFormat) {
            var data = {
                labels: $scope.data.graph.labels,
                series: [
                    {
                        name: 'series-1',
                        data: $scope.data.graph.success
                    },
                    {
                        name: 'series-2',
                        data: $scope.data.graph.failure
                    },
                    {
                        name: 'series-3',
                        data: $scope.data.graph.pending
                    }
                ]
            };

            var options = {
              seriesBarDistance: 15,
              axisX: {
                    labelInterpolationFnc: function(value) {
                        return moment.tz(value, moment.tz.guess() || $scope.timeFormat.defaultTimezone).format(labelFormat);
                    }
                },
                axisY: {
                    onlyInteger: true          
                }
            };

            var responsiveOptions = [
              ['screen and (max-width: 1250px)', {
                seriesBarDistance: 5,
                axisX: {
                  labelInterpolationFnc: function (value) {
                    return value[0];
                  }
                }
              }]
            ];
            
            new Chartist.Bar('.ct-chart', data, options, responsiveOptions);
        };
        
        $scope.formatCarrier = function(value) {
            if(value) {
                return value.split(':')[0];
            }
            return '-';
        };

        $scope.formatCount = function(record, value) {
            var dispos = record.dispos || [];
            var dispoStatus = (dispos[dispos.length - 1] || {}).status || record.status;
            if (dispoStatus === 'success') {
                return (value || []).length;
            }
            return 0;
        };

        $scope.formatDate = function(value) {
            if(value) {
                return moment(value).format('lll');
            }
            return '';
        };
        
        $scope.findIndexInRulesArrayByMediaId = function(id, callback) {
            var index = _.findIndex($scope.data.results.records.dispos, {_id: id });
            if(index > -1) {
                callback(index);
            } else console.log('Can not find recording!');
        };
        
        $scope.dateDiffDays = function(date) {
            var deliveredAtPlus7Days = moment(date).subtract(7,'d');
            var currentDate = moment();
            var diff = currentDate.diff(deliveredAtPlus7Days, 'd');
            return diff;
        };

        $scope.exportCampaign = function() {
            $scope.__export();
        };

        $scope.__export = function() {

            if (!$scope.data.results.exporting) {

                $scope.data.results.exporting = true;

                const teamId = authService.getTeamId();
                const userId = authService.getUserId();

                let req = {
                    team_id: teamId,
                    user_id: userId,
                    campaign_id: $scope.data.campaign_id
                };

                campaignService.export(req)
                    .then(result => {
                        $scope.export.id = result.export_id;
                        console.log('Export ' + result.export_id);
                        //swal("Export", "The export is processing and will download to your browser automatically when it is complete.", "success");
                    })
                    .catch(err => {
                        console.error(err);
                        $scope.data.results.exporting = false;
                        $scope.$apply();
                    });
            }
        };

        $scope.retryCampaign = function() {
            if (!$scope.data.results.saving) {
                $scope.__startCampaign();
            }
        };
        
        $scope.__startCampaign = function(camp) {
            $scope.data.results.saving = true;

            const teamId = authService.getTeamId();
            const userId = authService.getUserId();

            let req = {
                team_id: teamId,
                user_id: userId,
                campaign_id: $scope.data.campaign_id,
                status_filters: ['failure']
            };

            campaignService.startCampaign(req)
                .then(results => {
                    $scope.data.results.saving = false;
                    $scope.$apply();

                    // Exit the wizard
                    $location.search({});
                    $location.path('/bulk-campaigns');
                })
                .catch(err => {
                    $scope.data.results.saving = false;
                    console.log(err);
                    alert('Unable to start campaign');
                });
        };

        $scope.calcTotal = function(summary, callback) {
            _.map(summary, function(value, key) {
                $scope.data.voicemails.success += value.rvm.success;
                $scope.data.voicemails.failure += value.rvm.failure;
                $scope.data.voicemails.pending += value.rvm.pending;
            });
            $scope.data.results.total = $scope.data.voicemails.success + $scope.data.voicemails.failure + $scope.data.voicemails.pending;
            callback();
        };


        $scope.resultsPageChanged = function() {

            let results = $scope.data.results || {};

            $scope.loadCampaignResults((results.currentPage - 1) * results.itemsPerPage, results.itemsPerPage);
        }

        $scope.loadCampaignResults = function(skip, limit) {

            const teamId = authService.getTeamId();
            const userId = authService.getUserId();

            $scope.data.campaign_id = $location.search().campaign_id;

            let req = {
                team_id: teamId,
                user_id: userId,
                campaign_id: $scope.data.campaign_id,
                skip: skip,
                limit: limit
            };

            $scope.data.results.loading = true;

            campaignService.getCampaignResults(req)
                .then(results => {
                    $scope.data.results.loading = false;
                    $scope.data.results.records = results.records || [];
                    $scope.data.results.campaign = results.campaign || {};
                    $scope.data.results.total =  $scope.data.results.total || (results.records || []).length;

                    let deliveryType = $scope.data.results.campaign.delivery_type || '';
                    $scope.data.results.in_progress = !deliveryType.endsWith('-complete');
                })
                .catch(err => {
                    console.log(err);
                    swal("Failed", "Failed to get campaign results.  Please try again later.", "error");
                    $scope.data.results.loading = false;
                    $scope.$apply();
                });
        };

        $scope.$on('export.success', function(event, args) {
            if (args && args.data.user_id === authService.getUserId()) {
                $scope.data.results.exporting = false;
                $scope.$apply();
            }
        });
        
        $scope.initData = function() {
            $scope.data.campaign_id = $location.search().campaign_id;

            if (!$scope.data.campaign_id) {
                $location.path('/bulk-campaigns');
                $scope.$apply();
            }

            $scope.changePeriod('btn24Hours', function() {
                $scope.calcTotal($scope.data.summary, function() {
                    $scope.loadCampaignResults(0, $scope.data.results.itemsPerPage);
                });
            });
        };
        
        $scope.onInit = function() {
            $window.scrollTo(0, 0);

            $scope.data.screenWidth = $window.innerWidth;
            angular.element($window).bind('resize', function () {
                $scope.data.screenWidth = $window.innerWidth;
                $scope.$apply();
            });

            $scope.initData();
        };
        
        if (authService.hasAccount()) {
            $scope.onInit();
        }
        else {
            $scope.$on('auth_complete', function(event, args) {
                $scope.onInit();
            });
        }

        $scope.$on('$destroy', function() {
            $interval.cancel($scope.data.results.stopTime);
            $interval.cancel($scope.data.statistic.summaryTime);
        });
    }

    module.exports = CampaignResultsController;
})();
