(function() {
    'use strict';

    function WebRtcSettingsModalController(authService, accountService, $rootScope, $scope, $window, rtcSettingsService, userService) {
        var vm = this;
        vm.authService = authService;

        $scope.staticData = {
            title: 'Settings',
            subtitle: "Configure your computer's microphone and speaker settings...",
            dropDownPlaceholder: 'Please Choose...',
            audioInputWarning: 'Browser does not support input device selection.',
            audioOutputWarning: 'Browser does not support output device selection.',
            doNotDisturbTitle: 'Do Not Disturb',
            submitBtn: 'Save',
            test: {
                startBtn: 'Start Test',
                stopBtn: 'Stop Test'
            }
        };

        $scope.doNotDisturbEnabled = undefined;
        $scope.audioPlaying = false;

        $scope.audioInputSelect = [];
        $scope.audioOutputSelect = [];

        $scope.audioInputSelected = undefined;
        $scope.audioOutputSelected = undefined;

        $scope.hideModal = function() {
            $rootScope.hideWebRtcSettingsModal();
        };

        $scope.doNotDisturbChanged = function() {
            authService.setDoNotDisturbEnabled($scope.doNotDisturbEnabled);
        };

        $scope.getDoNotDisturbEnabled = function() {
            authService.getDoNotDisturbEnabled(function(doNotDisturb) {
                $scope.doNotDisturbEnabled = doNotDisturb;
            });
        };

        $rootScope.$on('user.changed', function(event, args) {
            if (authService.getUserId() === args.data.user_id) {
                const userReq = {
                    target_user_id: authService.getUserId(),
                    team_id: authService.getTeamId(),
                    user_id: authService.getUserId()
                };
                userService.getUser(userReq)
                    .then(user => {
                        $scope.doNotDisturbEnabled = user.do_not_disturb;
                    })
                    .catch(err => {
                        console.error(err);
                    });
            }
        });

        $scope.gotDevices = function(deviceInfos, callback) {
            $scope.audioInputSelect = [];
            $scope.audioOutputSelect = [];
            for (let i = 0; i !== deviceInfos.length; ++i) {
                const deviceInfo = deviceInfos[i];
                if(deviceInfo.deviceId !== 'default') {
                    var option = {
                        value: deviceInfo.deviceId,
                        text: ''
                    };

                    switch(deviceInfo.kind) {
                        case 'audioinput':
                            option.text = deviceInfo.label || `microphone ${$scope.audioInputSelect.length + 1}`;
                            $scope.audioInputSelect.push(option);
                            break;
                        case 'audiooutput':
                            option.text = deviceInfo.label || `speaker ${$scope.audioOutputSelect.length + 1}`;
                            $scope.audioOutputSelect.push(option);
                            break;
                        default:
                            // console.log('Ignoring source/device: ', deviceInfo);
                    }
                }
                if(i === deviceInfos.length -1) {
                    callback();
                }
            }
        };

        $scope.drawCanvas = function(barWidth, analyser, dataArray, ctx, WIDTH, HEIGHT, bufferLength) {
            var barHeight;
            var x = 0;

            function renderFrame() {
                requestAnimationFrame(renderFrame);
                x = 0;
                analyser.getByteFrequencyData(dataArray);

                ctx.fillStyle = '#fff';
                ctx.fillRect(0, 0, WIDTH, HEIGHT);

                for (var i = 0; i < bufferLength; i++) {
                    barHeight = dataArray[i];

                    ctx.fillStyle = 'rgb(255, 33, 79)';
                    ctx.fillRect(x, HEIGHT - barHeight/7, barWidth, barHeight/7);

                    x += barWidth + 2;
                }
            }
            renderFrame();
        };

        $scope.initCanvas = function(canvas, bufferLength, callback) {
            var canvasInfo = canvas.getBoundingClientRect();
            canvas.width = canvasInfo.width;
            canvas.height = canvasInfo.height;
            var ctx = canvas.getContext('2d');

            var WIDTH = canvasInfo.width;
            var HEIGHT = canvasInfo.height;
            var barWidth = (WIDTH / bufferLength) * 0.6;

            callback(barWidth, ctx, WIDTH, HEIGHT);
        };

        $scope.createAudioVisualization = function(stream, canvasId) {
            var canvas = document.getElementById(canvasId);
            if(canvas) {
                var context = new window.AudioContext();
                var analyser = context.createAnalyser();
                var src = context.createMediaStreamSource(stream);
                src.connect(analyser);
                analyser.fftSize = 256;
                var bufferLength = analyser.frequencyBinCount;
                var dataArray = new Uint8Array(bufferLength);

                $scope.initCanvas(canvas, bufferLength, function(barWidth, ctx, WIDTH, HEIGHT) {
                    $scope.drawCanvas(barWidth, analyser, dataArray, ctx, WIDTH, HEIGHT, bufferLength);
                });
            }
        };

        $scope.drawStaticCanvas = function(canvasId) {
            var bufferLength = 128;
            var canvas = document.getElementById(canvasId);
            if(canvas) {
                $scope.initCanvas(canvas, bufferLength, function(barWidth, ctx, width, height) {
                    var barHeight;
                    var x = 0;

                    function getRandomArbitrary(min, max) {
                        return Math.random() * (max - min) + min;
                    }

                    function renderFrame() {
                        x = 0;
                        ctx.fillStyle = '#fff';
                        ctx.fillRect(0, 0, width, height);

                        for (var i = 0; i < bufferLength; i++) {
                            barHeight = getRandomArbitrary(8, height);

                            ctx.fillStyle = '#dad9d9';
                            ctx.fillRect(x, height - barHeight, barWidth, barHeight);

                            x += barWidth + 2;
                        }
                    }
                    renderFrame();
                });
            }
        };

        $scope.stopAudio = function() {
            rtcSettingsService.stopAudio();
            $scope.audioPlaying = false;
            setTimeout(function() {
                $scope.drawStaticCanvas('audio-canvas-static');
            }, 50);
        };

        $scope.playAudio = function() {
            rtcSettingsService.playAudio();
            $scope.audioPlaying = true;
        };

        $scope.checkAudio = function(event) {
            if(event) {
                event.preventDefault();
            }

            (!$scope.audioPlaying) ? $scope.playAudio() : $scope.stopAudio();
        };

        $scope.changeAudioSink = function(audioInput) {
            if($scope.audioPlaying) {
                $scope.stopAudio();
            }

            $scope.audioInputSelected = audioInput;
            rtcSettingsService.changeAudioInput(audioInput);
        };

        $scope.changeAudioDestination = function(audioOutput) {
            if($scope.audioPlaying) {
                $scope.stopAudio();
            }

            $scope.audioOutputSelected = audioOutput;
            rtcSettingsService.changeAudioOutput(audioOutput, function(status, jumpFirstOutputDevice, message) {
                console.log(status + ' ' + message);
                if(jumpFirstOutputDevice) {
                    $scope.selectDefaultDevices('audioOutputSelect', 'changeAudioDestination');
                }
                $scope.$apply();
            });
        };

        $scope.selectDefaultDevices = function(arrayName, functionName) {
            if($scope[arrayName].length > 0) {
                $scope[functionName]($scope[arrayName][0]);
            }
        };

        $scope.findDeviceInArray = function(arrayName, selectedName, functionName) {
            let foundObject = _.find($scope[arrayName], function(e) {
                return e.value === $scope[selectedName].value;
            });
            if(!foundObject) {
                $scope.selectDefaultDevices(arrayName, functionName);
            }
        };

        $scope.getDevices = function(callback) {
            rtcSettingsService.getDevices(function(devices) {
                $scope.gotDevices(devices, function() {
                    callback();
                });
            });
        };

        $scope.onInit = function() {
            $scope.getDoNotDisturbEnabled();
            rtcSettingsService.init();

            $scope.getDevices(function() {
                $scope.selectDefaultDevices('audioInputSelect', 'changeAudioSink');
                $scope.selectDefaultDevices('audioOutputSelect', 'changeAudioDestination');
                $scope.$apply();
            });

            $('#webRtcSettingsModal').on('shown.bs.modal', function(){
                $scope.drawStaticCanvas('audio-canvas-static');
            });
            $('#webRtcSettingsModal').on('hide.bs.modal', function() {
                if($scope.audioPlaying) {
                    $scope.stopAudio();
                }
            });

            rtcSettingsService.subscribeDeviceChange($scope, function() {
                $scope.getDevices(function() {
                    $scope.findDeviceInArray('audioInputSelect', 'audioInputSelected', 'changeAudioSink');
                    $scope.findDeviceInArray('audioOutputSelect', 'audioOutputSelected', 'changeAudioDestination');
                    $scope.$apply();
                });
            });
            rtcSettingsService.subscribeAudioStreamPlaying($scope, function() {
                var stream = rtcSettingsService.getRemoteAudioStream();
                $scope.createAudioVisualization(stream, 'audio-canvas');
            });
        };

        if (authService.hasAccount()) {
            $scope.onInit();
        }
        else {
            $scope.$on('auth_complete', function(event, args) {
                $scope.onInit();
            });
        }
    }
    module.exports = WebRtcSettingsModalController;
})();
