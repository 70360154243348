(function () {
    'use strict';
    
    function CampaignService(apiService, $rootScope) {
        return {
            getCampaigns: function (request) {
                return apiService.get($rootScope.settings.API_CAMPAIGN_URL + '/campaign', request);
            },

            getCampaignsCount: function (request) {
                return apiService.get($rootScope.settings.API_CAMPAIGN_URL + '/campaign/count', request);
            },

            createCampaign: function (request) {
                return apiService.post($rootScope.settings.API_CAMPAIGN_URL + '/campaign', request);
            },

            updateCampaign: function (request) {
                return apiService.post($rootScope.settings.API_CAMPAIGN_URL + '/campaign/' + request.campaign_id, request);
            },

            deleteCampaign: function (request) {
                return apiService.del($rootScope.settings.API_CAMPAIGN_URL + '/campaign/' + request.campaign_id, request);
            },

            getCampaignResults: function (request) {
                return apiService.post($rootScope.settings.API_CAMPAIGN_URL + '/campaign/' + request.campaign_id + '/results', request);
            },

            getCampaign: function (request) {
                return apiService.get($rootScope.settings.API_CAMPAIGN_URL + '/campaign/' + request.campaign_id, request);
            },

            balance: function (request) {
                return apiService.get($rootScope.settings.API_CAMPAIGN_URL + '/campaign/team/' + request.team_id + '/balance', request);
            },

            updateTeamTriggers: function (request) {
                return apiService.post($rootScope.settings.API_CAMPAIGN_URL + '/campaign/team/' + request.team_id + '/balance/update-triggers', request);
            },

            startCampaign: function (request) {
                return apiService.get($rootScope.settings.API_CAMPAIGN_URL + '/campaign/team/' + request.team_id + '/' + request.campaign_id + '/start', request);
            },

            pauseCampaign: function (request) {
                return apiService.get($rootScope.settings.API_CAMPAIGN_URL + '/campaign/team/' + request.team_id + '/' + request.campaign_id + '/pause', request);
            },

            getSummary: function (request) {
                return apiService.post($rootScope.settings.API_CAMPAIGN_URL + '/campaign/team/' + request.team_id + '/' + request.campaign_id + '/summary', request);
            },

            export: function(request) {
                return apiService.post($rootScope.settings.API_CAMPAIGN_URL + '/campaign/team/' + request.team_id + '/' + request.campaign_id + '/export', request);
            },

            loadExport: function(request) {
                return json(request.url, {});
            },
        };
    }
    module.exports = CampaignService;
})();




