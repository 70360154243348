(function () {
    'use strict';

    function MailboxesService(apiService, $rootScope) {
        return {
            getMailboxes: function (request) {
                return apiService.get($rootScope.settings.API_DOMAIN_URL + '/mailbox', request);
            },
            createMailbox: function (request) {
                return apiService.post($rootScope.settings.API_DOMAIN_URL + '/mailbox', request);
            },
            removeMailbox: function (request) {
                return apiService.del($rootScope.settings.API_DOMAIN_URL + `/mailbox/${request.mailbox_id}`, request);
            },
        };
    }
    module.exports = MailboxesService;
})();
