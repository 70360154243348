(function() {
    'use strict';

    function AddFundsModalController($rootScope, $scope, authService, accountService) {

        var vm = this;
        vm.authService = authService;

        $scope.data = {
            form: { amount: 50 },
            loading: false
        };

        $scope.staticData = {
            title: 'Free Trial | Add Funds',
            form: {
                amount: {
                    title: 'Dollar Amount (USD)',
                }
            },
            text: [
                'Funds in your account balance can be used for ringless voicemail & bulk SMS, call tracking, call recording & transcription and more. Discounted rates are available based on your selected',
                'usage plan',
            ],
            submitButton: 'Proceed to Checkout',
            errors: {
                minMaxError: 'Amount must be between $50 to $4000',
                requiredError: 'Field is required',
                numberError: 'Field must contain only numbers'
            },
            processing: 'Processing'
        };

        $scope.validateAmount = function(value) {
            return !(!value || typeof value !== 'number' || value < 50 || value > 4000);
        };

        $scope.openUsagePlan = function() {
            //modal mode: 'usage-plans',
            //active tab: 'ringless_voicemail'
            $rootScope.showUpsellModal('usage-plans', 'ringless_voicemail', false);
        };

        $scope.checkout = function(event) {
            event.preventDefault();

            if($scope.addFundsForm.addFundsAmount.$error.required ||
                $scope.addFundsForm.addFundsAmount.$error.number ||
                $scope.addFundsForm.addFundsAmount.$error.min ||
                $scope.addFundsForm.addFundsAmount.$error.max) {
                return;
            }

            let value = Math.floor((($scope.addFundsForm || {}).addFundsAmount || {}).$viewValue * 100) / 100;
            if (!$scope.validateAmount(value)) {
                return;
            }
            $scope.data.loading = true;
            $('#free-trial-add-funds-modal').modal('hide');

            $rootScope.collectPayment('one_time_charge', {amount: Math.floor(value * 100)}, () => {
                $scope.data.loading = false;
                $scope.$apply();
            });

            /*
            const teamId = authService.getTeamId();
            const userId = authService.getUserId();
            const request = {
                team_id: teamId,
                user_id: userId,
            };

            accountService.getBilling(request)
                .then(results => {
                    let billingData = results || {};
                    let submitLabel = 'Pay ' + $rootScope.formatMoney(value, 'USD', false) + ' Now';
                    let cartData = {
                        billing_address: billingData.billing_address || null,
                        primary_payment: billingData.primary_payment || null,
                        total_amount_today: Math.floor(value * 100),
                        total_amount_recurring: 0,
                        renewal_timestamp: 0,
                        has_recurring_charges: false,
                        submit_label: submitLabel,
                    };

                    $scope.data.loading = false;
                    $('#free-trial-add-funds-modal').modal('hide');

                    $rootScope.showCartModal(cartData);
                    $scope.$apply();
                });

             */
        };

        $rootScope.$on('setFreeTrialFundAmount', function(event, fundAmount) {
            $scope.data.form.amount = fundAmount;
        });

        $scope.onInit = function() {
            $('#free-trial-add-funds-modal').on('hide.bs.modal', function() {
                $scope.data.form.amount = 50;
                $scope.data.loading = false;
            });
        };

        $scope.onInit();
    }

    module.exports = AddFundsModalController;
})();
