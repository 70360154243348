(function() {
    'use strict';

    function AudioPlayerController(authService, $rootScope, $scope) {
        var vm = this;
        vm.authService = authService;

        $scope.isPreparingToPlay = undefined;

        $scope.toggleAudioMute = function() {
            vm.audio.muting = !vm.audio.muting;
            var audioElement = document.getElementById('player-' + vm.pref + '-' + vm.recordId);
            audioElement.muted = vm.audio.muting;
        };

        $scope.converToFormatTimePlayer = function(time) {
            if(time !== undefined && !isNaN(time)) {
                time = Math.round(time);
                var minutes = Math.floor(time / 60);
                var seconds = time - (minutes * 60);

                if (minutes.toString().length === 1) {
                    minutes = '0' + (Math.floor(time / 60)).toString();
                }
                if (seconds.toString().length === 1) {
                    seconds = '0' + (time - (minutes * 60)).toString();
                }

                var output = minutes + ':' + seconds;

                return output;
            }
            return '00:00';
        };

        $scope.setTransform = function(id) {
            $('#audio-player-' + vm.pref + '-' + id).addClass('transform-active');
            setTimeout(()=> {
                $('#player-progress-' + vm.pref + '-' + id + '-container').removeClass('hide-elem');
                $('#span-time-' + vm.pref + '-' + id + '-player').removeClass('hide-elem');
                $('#audio-player-muting-' + vm.pref + '-' + id).removeClass('hide-elem');
            }, 250);
        };

        $scope.removeTransform = function(id) {
            $('#player-progress-' + vm.pref + '-' + id + '-container').addClass('hide-elem');
            $('#span-time-' + vm.pref + '-' + id + '-player').addClass('hide-elem');
            $('#audio-player-muting-' + vm.pref + '-' + id).addClass('hide-elem');
            $('#audio-player-' + vm.pref + '-' + id).removeClass('transform-active');
        };

        $scope.playRecordPlayer = function(elem, id) {
            vm.currentOpenedPlayerId = id;
            document.getElementById('play-btn-' + vm.pref + '-' + id + '-player').className = 'fa-light fa-pause pause';
            $scope.setTransform(id);
            elem.play();
        };

        $scope.pauseRecordPlayer = function(elem, id) {
            document.getElementById('play-btn-' + vm.pref + '-' + id + '-player').className = 'fa-light fa-play play';
            elem.pause();
        };

        $scope.timeUpdatePlayer = function(audioElement) {
            var length = audioElement.duration;
            var currentTime = audioElement.currentTime;

            vm.audio.currentTime = currentTime;
            vm.audio.duration = length;

            var progress = (audioElement.currentTime / audioElement.duration) * 100;
            vm.audio.progress = progress;
            $scope.$apply();
        };

        $scope.goTo = function(event) {
            var audioElement = document.getElementById('player-' + vm.pref + '-' + vm.recordId);
            const rect = event.target.getBoundingClientRect();
            const position = event.offsetX / rect.width * audioElement.duration
            audioElement.currentTime = position;
        }

        $scope.addEndedEventListener = function(elem, id) {
            elem.addEventListener('ended', function() {
                document.getElementById('play-btn-' + vm.pref + '-' + id + '-player').className = 'fa-light fa-play play';
                $scope.removeTransform(id);
            }, true);
        };

        $scope.addTimeUpdateEventListener = function(elem) {
            elem.addEventListener('timeupdate', function() {
                $scope.timeUpdatePlayer(elem);
            }, true);
        };

        $scope.updateAudioControlPlayer = function(elem) {
            if(elem.currentSrc === '' || elem.currentSrc !== vm.audio.url) {
                elem.src = vm.audio.url;
                if(vm.audio.progress < 100) {
                    elem.currentTime = vm.audio.currentTime;
                }

                $scope.addEndedEventListener(elem, vm.recordId);
                elem.addEventListener('seeked', function() {
                    $scope.addTimeUpdateEventListener(elem);
                }, true);
                $scope.playRecordPlayer(elem, vm.recordId);
            } else {
                $scope.playRecordPlayer(elem, vm.recordId);
            }
        };

        $scope.initAudioSrcPlayer = function(audioElement) {
            var audioInit = {
                url: vm.recordSrc,
                currentTime: 0,
                duration: 0,
                progress: 0,
                muting: undefined
            };

            $scope.isPreparingToPlay = true;
            vm.audio = audioInit;

            audioElement.src = vm.recordSrc;
            $scope.addEndedEventListener(audioElement, vm.recordId);
            $scope.addTimeUpdateEventListener(audioElement);

            $scope.isPreparingToPlay = false;
            $scope.playRecordPlayer(audioElement, vm.recordId);
        };

        $scope.checkPrevRecordStatusPlayer = function() {
            if(vm.currentOpenedPlayerId !== -1 && vm.currentOpenedPlayerId !== vm.recordId) {
                var audioElementPrev = document.getElementById('player-' + vm.pref + '-' + vm.currentOpenedPlayerId);
                if(audioElementPrev !== null && audioElementPrev !== undefined && !audioElementPrev.paused) {
                    $scope.pauseRecordPlayer(audioElementPrev, vm.currentOpenedPlayerId);
                }
            }
        };

        $scope.togglePlayer = function() {
            // console.log('vm.recordId ' + vm.recordId);
            // console.log('vm.currentOpenedPlayerId ' + vm.currentOpenedPlayerId);
            // console.log('vm.recordIndex ' + vm.recordIndex);
            // console.log('vm.audio ' + vm.audio);
            // console.log('vm.recordSrc ' + vm.recordSrc);
            // console.log('vm.pref ' + vm.pref);
            // console.log('vm.recordType ' + vm.recordType);

            $scope.checkPrevRecordStatusPlayer();
            var audioElement = document.getElementById('player-' + vm.pref + '-' + vm.recordId);

            if(vm.audio !== undefined && vm.audio !== null) {
                (!audioElement.paused) ? $scope.pauseRecordPlayer(audioElement, vm.recordId) : $scope.updateAudioControlPlayer(audioElement);
            } else {
                $scope.initAudioSrcPlayer(audioElement);
            }
        };

        $scope.$watch(angular.bind(vm, function () { return vm.audio; }), function (newValue) {
            $rootScope.$broadcast('audioObjectChanged', newValue, vm.recordIndex, vm.recordType);
        }, true);
        $scope.$watch(angular.bind(vm, function () { return vm.currentOpenedPlayerId; }), function (newValue) {
            $rootScope.$broadcast('currentOpenedRecordChanged', newValue, vm.recordType);
        });
    }
    module.exports = AudioPlayerController;
})();
