(function() {
    'use strict';

    function ConfigFunction($stateProvider, $locationProvider, $urlRouterProvider, $qProvider) { 
//        $urlRouterProvider.otherwise('/dash');
        $locationProvider.hashPrefix('');
        $qProvider.errorOnUnhandledRejections(false);

        if (typeof am4core !== 'undefined') {
            am4core.addLicense("CH205926242");
            am4core.addLicense("TL205926242");
        }
        
        $stateProvider
            .state('empty-wrapper', {
                abstract: true,
                controller: 'emptyWrapperController',
                templateUrl: 'views/_components/empty-wrapper/empty-wrapper.html'
            })
            .state('empty-wrapper.signin', {
                url: '/signin',
                controller: 'signinController',
                templateUrl: 'views/auth/signin.html',
                controllerAs: 'vm'
            })
            .state('empty-wrapper.signup', {
                url: '/signup',
                controller: 'signupStepsController',
                templateUrl: 'views/auth/new/signup-step.html',
                controllerAs: 'vm'
            })
            .state('empty-wrapper.passwordreset', {
                url: '/passwordreset',
                controller: 'passwordResetController',
                templateUrl: 'views/auth/forgot.html',
                controllerAs: 'vm'
            })
            .state('main-wrapper', {
                abstract: true,
                controller: 'mainWrapperController',
                templateUrl: 'views/_components/main-wrapper/main-wrapper.html'
            })
            .state('main-wrapper./', {
                url: '',
                controller: 'myDashboardController',
                templateUrl: 'views/my-dashboard/my-dashboard.html',
                controllerAs: 'vm',
                reloadOnSearch: false,
                authenticate: true,
                rolePermission: 'team_reporting'
            })
            .state('main-wrapper.my-dashboard', {
                url: '/my-dashboard',
                controller: 'myDashboardController',
                templateUrl: 'views/my-dashboard/my-dashboard.html',
                controllerAs: 'vm',
                authenticate: true,
                rolePermission: 'team_reporting'
            })
            .state('main-wrapper.dash', {
                url: '/dash',
                controller: 'dashController',
                templateUrl: 'views/dash/dash.html',
                controllerAs: 'vm',
                authenticate: true,
                rolePermission: 'team_reporting'
            })
           .state('main-wrapper.activity', {
               url: '/activity',
               controller: 'activityDashController',
               templateUrl: 'views/activity/activity.html',
               controllerAs: 'vm',
               authenticate: true,
               rolePermission: 'team_reporting'
           })
           .state('main-wrapper.tracking', {
               url: '/tracking',
               controller: 'callTrackingDashController',
               templateUrl: 'views/tracking/tracking.html',
               controllerAs: 'vm',
               authenticate: true,
               rolePermission: 'team_reporting'
           })
            .state('main-wrapper.campaigns', {
                url: '/bulk-campaigns',
                controller: 'campaignsController',
                templateUrl: 'views/campaigns/campaigns.html',
                controllerAs: 'vm',
                authenticate: true,
                rolePermission: 'bulk_campaigns'
            })
            .state('main-wrapper.voicemail-campaign', {
                url: '/voicemail-campaign',
                controller: 'createCampaignController',
                templateUrl: 'views/campaigns/create-campaign.html',
                controllerAs: 'vm',
                authenticate: true,
                rolePermission: 'bulk_campaigns'
            })
            .state('main-wrapper.campaign-results', {
                url: '/voicemail-campaign-results',
                controller: 'campaignResultsController',
                templateUrl: 'views/campaigns/campaign-results.html',
                controllerAs: 'vm',
                authenticate: true,
                rolePermission: 'bulk_campaigns'
            })
            .state('main-wrapper.admin', {
                url: '/admin',
                controller: 'adminController',
                templateUrl: 'views/admin/admin.html',
                controllerAs: 'vm',
                authenticate: true,
                rolePermission: 'app_admin'
            })
            .state('main-wrapper.api', {
                url: '/api',
                controller: 'apiController',
                templateUrl: 'views/api/api.html',
                controllerAs: 'vm',
                authenticate: true,
                rolePermission: 'users'
            })
            .state('main-wrapper.contacts', {
                url: '/contacts',
                controller: 'contactsController',
                templateUrl: 'views/contacts/contacts.html',
                controllerAs: 'vm',
                authenticate: true,
                rolePermission: 'contacts'
            })
            .state('main-wrapper.faxes', {
                url: '/faxes',
                controller: 'faxesController',
                templateUrl: 'views/faxes/faxes.html',
                controllerAs: 'vm',
                authenticate: true,
                rolePermission: 'dial'
            })
            .state('main-wrapper.byoc', {
                url: '/byoc',
                controller: 'byocController',
                templateUrl: 'views/byoc/byoc.html',
                controllerAs: 'vm',
                authenticate: true,
                rolePermission: 'users'
            })
            .state('main-wrapper.list', {
                url: '/lists',
                controller: 'listsController',
                templateUrl: 'views/contacts/lists.html',
                controllerAs: 'vm',
                authenticate: true,
                rolePermission: 'contacts'
            })
            .state('main-wrapper.contactDetails', {
                url: '/contacts/detail',
                controller: 'contactDetailsController',
                templateUrl: 'views/contacts/contact-details.html',
                controllerAs: 'vm',
                authenticate: true,
                rolePermission: 'contacts'
            })
            .state('main-wrapper.subscription', {
                url: '/subscriptions',
                controller: 'subscriptionController',
                templateUrl: 'views/subscription/subscription.html',
                controllerAs: 'vm',
                authenticate: true,
                // rolePermission: 'app_admin'
            })
            .state('main-wrapper.reloadSubscription', {
                parent: 'main-wrapper',
                controller: ['$state', function($state) { $state.go('^.subscription');}]
            })
            .state('main-wrapper.webforms', {
                url: '/webforms',
                controller: 'webformsController',
                templateUrl: 'views/webforms/webforms.html',
                controllerAs: 'vm',
                authenticate: true,
                rolePermission: 'contacts'
            })
            .state('main-wrapper.customFields', {
                url: '/custom-fields',
                controller: 'customFieldsController',
                templateUrl: 'views/custom-fields/custom-fields.html',
                controllerAs: 'vm',
                authenticate: true,
                rolePermission: 'contacts'
            })
            .state('main-wrapper.webformEdit', {
                url: '/webforms/edit',
                controller: 'webformEditController',
                templateUrl: 'views/webforms/webform-edit.html',
                controllerAs: 'vm',
                authenticate: true,
                rolePermission: 'contacts'
            })
            .state('main-wrapper.webformDetails', {
                url: '/webforms/detail',
                controller: 'webformDetailsController',
                templateUrl: 'views/webforms/webform-details.html',
                controllerAs: 'vm',
                authenticate: true,
                rolePermission: 'contacts'
            })
            .state('main-wrapper.outcomes', {
                url: '/outcomes',
                controller: 'outcomesController',
                templateUrl: 'views/outcomes/outcomes.html',
                controllerAs: 'vm',
                authenticate: true,
                rolePermission: 'dial'
            })
            .state('main-wrapper.dnc', {
                url: '/dnc',
                controller: 'dncController',
                templateUrl: 'views/dnc/dnc.html',
                controllerAs: 'vm',
                authenticate: true,
                rolePermission: 'dial'
            })
            .state('main-wrapper.phone-numbers', {
                url: '/phone-numbers',
                controller: 'phoneNumbersController',
                templateUrl: 'views/phone-numbers/phone-numbers.html',
                controllerAs: 'vm',
                authenticate: true,
                rolePermission: 'dial'
            })
            .state('main-wrapper.phone-number-purchase', {
                url: '/phone-number-purchase',
                controller: 'phoneNumberPurchaseController',
                templateUrl: 'views/phone-numbers/phone-number-purchase.html', 
                controllerAs: 'vm',
                authenticate: true,
                rolePermission: 'dial',
                roleAction: 'can_create'
            })
            .state('main-wrapper.call-routing', {
                url: '/call-routing',
                controller: 'phoneTreeIVRcontroller',
                templateUrl: 'views/phone-tree-ivr/phone-tree-ivr.html',
                controllerAs: 'vm',
                authenticate: true,
                rolePermission: 'dial'
            })
            .state('main-wrapper.ivr-config', {
                url: '/ivr-config',
                controller: 'ivrConfigController',
                templateUrl: 'views/phone-tree-ivr/ivr-config.html',
                controllerAs: 'vm',
                authenticate: true,
                rolePermission: 'dial',
                roleAction: 'can_edit'
            })
            .state('main-wrapper.list-config', {
                url: '/list-config',
                controller: 'listConfigController',
                templateUrl: 'views/contacts/list-config.html',
                controllerAs: 'vm',
                authenticate: true,
                rolePermission: 'dial',
                roleAction: 'can_edit'
            })
            .state('main-wrapper.dialing-rules', {
                url: '/dialing-rules',
                controller: 'dialingRulesController',
                templateUrl: 'views/contacts/dialing-rules.html',
                controllerAs: 'vm',
                authenticate: true,
                rolePermission: 'dial',
                roleAction: 'can_edit'
            })
            .state('main-wrapper.trust-center', {
                url: '/trust-center',
                controller: 'trustCenterController',
                templateUrl: 'views/trust-center/trust-center.html',
                controllerAs: 'vm',
                authenticate: true
            })
           .state('main-wrapper.number-pool', {
               url: '/number-pool',
               controller: 'numberPoolController',
               templateUrl: 'views/number-pools/number-pools.html',
               controllerAs: 'vm',
               authenticate: true
           })
            .state('main-wrapper.recent-calls', {
                url: '/recent-calls',
                controller: 'recentCallsController',
                templateUrl: 'views/recent-calls/recent-calls.html',
                controllerAs: 'vm',
                authenticate: true,
                rolePermission: 'dial'
            })
            .state('main-wrapper.recordings', {
                url: '/recordings',
                controller: 'recordingsController',
                templateUrl: 'views/recordings/recordings.html',
                controllerAs: 'vm',
                authenticate: true,
                rolePermission: 'dial'
            })
            .state('main-wrapper.voices', {
                url: '/voices',
                controller: 'voicesController',
                templateUrl: 'views/voices/voices.html',
                controllerAs: 'vm',
                authenticate: true,
                rolePermission: 'dial'
            })
            .state('main-wrapper.tags', {
                url: '/tags',
                controller: 'tagsController',
                templateUrl: 'views/tags/tags.html',
                controllerAs: 'vm',
                authenticate: true
            })
            .state('main-wrapper.sms-autoresponders', {
                url: '/sms-autoresponders',
                controller: 'smsAutorespondersController',
                templateUrl: 'views/sms-autoresponders/sms-autoresponders.html',
                controllerAs: 'vm',
                authenticate: true,
                rolePermission: 'dial'
            })
            .state('main-wrapper.sms-config', {
                url: '/sms-config',
                controller: 'smsConfigController',
                templateUrl: 'views/sms-autoresponders/sms-config.html',
                controllerAs: 'vm',
                authenticate: true,
                rolePermission: 'dial',
                roleAction: 'can_edit'
            })
            .state('main-wrapper.team', {
                url: '/team',
                controller: 'teamController',
                templateUrl: 'views/team/team.html',
                controllerAs: 'vm',
                authenticate: true,
                rolePermission: 'users'
            })
            .state('main-wrapper.teams', {
                url: '/teams',
                controller: 'teamsController',
                templateUrl: 'views/teams/teams.html',
                controllerAs: 'vm',
                authenticate: true,
                rolePermission: 'users'
            })
            .state('main-wrapper.userAdd', {
                url: '/user/add',
                controller: 'userAddController',
                templateUrl: 'views/team/user-add.html',
                controllerAs: 'vm',
                authenticate: true,
                rolePermission: 'users',
                roleAction: 'can_edit'
            })
            .state('main-wrapper.text-messages', {
                url: '/text-messages',
                controller: 'textMessagesController',
                templateUrl: 'views/text-messages/text-messages.html',
                controllerAs: 'vm',
                authenticate: true,
                rolePermission: 'dial'
            })
            .state('main-wrapper.voicemail', {
                url: '/voicemail',
                controller: 'voicemailController',
                templateUrl: 'views/voicemail/voicemail.html',
                controllerAs: 'vm',
                authenticate: true,
                rolePermission: 'dial'
            })
            .state('main-wrapper.how-it-works', {
                url: '/how-it-works',
                controller: 'howItWorksController',
                templateUrl: 'views/how-it-works/how-it-works.html',
                controllerAs: 'vm',
                authenticate: true
            })
            .state('main-wrapper.campaign-wizard', {
                url: '/campaign-wizard',
                controller: 'campaignWizardController',
                templateUrl: 'views/campaign-wizard/campaign-wizard.html',
                controllerAs: 'vm',
                authenticate: true
            })
            .state('main-wrapper.integrations', {
                url: '/integrations',
                controller: 'integrationsController',
                templateUrl: 'views/integrations/integrations.html?v=' + APP_VERSION,
                controllerAs: 'vm',
                authenticate: true
            })
            .state('main-wrapper.automation', {
                url: '/automation',
                controller: 'automationController',
                templateUrl: 'views/automation/ipaas-automation.html',
                controllerAs: 'vm',
                authenticate: true
            })
            .state('main-wrapper.app-marketplace', {
                url: '/app-marketplace',
                controller: 'appMarketplaceController',
                templateUrl: 'views/automation/app-marketplace.html',
                controllerAs: 'vm',
                authenticate: true
            })
            .state('main-wrapper.workflow', {
                url: '/workflow/:workflowId',
                controller: 'workflowController',
                templateUrl: 'views/automation/workflow.html',
                controllerAs: 'vm',
                authenticate: true
            })
            .state('main-wrapper.rule-config', {
                url: '/rule-config',
                controller: 'ruleConfigController',
                templateUrl: 'views/automation/rule-config.html',
                controllerAs: 'vm',
                authenticate: true
            })
            .state('main-wrapper.inbox', {
                url: '/inbox',
                controller: 'inboxController',
                templateUrl: 'views/inbox/inbox.html',
                controllerAs: 'vm',
                authenticate: true
            })
            .state('main-wrapper.script', {
                url: '/scripts',
                controller: 'scriptController',
                templateUrl: 'views/script/script.html',
                controllerAs: 'vm',
                authenticate: true
            })
            .state('main-wrapper.email', {
                url: '/email',
                controller: 'emailController',
                templateUrl: 'views/email/email.html',
                controllerAs: 'vm',
                authenticate: true
            })
            .state('main-wrapper.welcome', {
                url: '/welcome',
                controller: 'welcomeController',
                templateUrl: 'views/welcome/new-welcome.html',
                controllerAs: 'vm',
                authenticate: true
            })
            .state('main-wrapper.buckets', {
                url: '/buckets',
                controller: 'bucketsController',
                templateUrl: 'views/buckets/buckets.html',
                controllerAs: 'vm',
                authenticate: true
            })
            .state('main-wrapper.checklist', {
                url: '/checklist',
                controller: 'checklistController',
                templateUrl: 'views/checklist/checklist.html',
                controllerAs: 'vm',
                authenticate: true
            });
    }
    module.exports = ConfigFunction;
})();

