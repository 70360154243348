(function () {
    'use strict';

    function TextAreaController(
        $q, $rootScope, $scope, $location, $window, $attrs,
        tasksService, authService, contactService, userService,
        phoneService, callService, dispatcherService,
        notificationToastService,
        emailService, faxService, templateService, mailboxesService, accountService) {

        var vm = this;

        $scope.staticData = {
            loading: 'Loading',
            saving: 'Saving',
            sending: 'Sending',
            view: {
                footer: {
                    text: {
                        tabName: 'Text',
                        placeholder: 'Please enter message...',
                        btn: 'Send Text',
                        btnClosedSnoozed: 'Send Text and Reopen',
                    },
                    note: {
                        tabName: 'Note',
                        placeholder: 'Please enter notes...',
                        btn: 'Add Note',
                        btnClosedSnoozed: 'Add Note and Reopen',
                    },
                    email: {
                        tabName: 'Email',
                        placeholder: 'Please enter email message...',
                        btn: 'Send Email',
                        btnClosedSnoozed: 'Send Email and Reopen',
                        emailAddress: 'Address',
                        emailName: 'Name',
                        openedAccordion: false,
                        hideEmailFrom: false,
                        hideEmailTo: false,
                        hideEmailCC: false,
                        hideEmailBCC: false,
                        hideEmailSubject: false,
                    }
                }
            },
            available_task_statuses: {
                open: 'open',
                snoozed: 'snoozed',
                closed: 'closed'
            }
        };

        $scope.page = null;
        $scope.mode = null; //'create' || 'send'
        $scope.localStorageCollection = null;

        $scope.data = {
            selectedTask: null,
            selectedEmailTemplate: null,
            templates: {
                email: [],
                script: []
            },
            user: null,

            brands: [],
            campaignMap: [],
            smsSendFrom: {
                from: null,
                callerId: null
            },
            selectedSmsMacros: null,
            macros: [],
            fromPhoneNumbers: [],

            messageObject: {
                contact_id: null,
                html: '',
                subject: '',
                to: [],
                from: [],
                cc: [],
                bcc: [],
                template_id: null
            },
            availableFrom: [],
            mailboxes: [],
            firstInitTabs: false,
            isValidTo: true
        };

        $scope.formatBytes = function(bytes, decimals = 2) {
            if (!bytes) {
                return '0 B';
            }

            const k = 1024;
            const dm = decimals < 0 ? 0 : decimals;
            const sizes = ['B', 'KB', 'MB'];

            const i = Math.floor(Math.log(bytes) / Math.log(k));

            return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
        };

        $scope.assignToGroupBy = function (item) {
            switch (item.type) {
                case 'email':
                    return 'Email Templates';
                case 'macros':
                    return 'Macros';
                default:
                    return '';
            }
        };

        $scope.selectSmsMacros = function() {
            console.log($scope.data.selectedSmsMacros);
        };

        $scope.selectEmailTemplate = function() {
            $scope._applyEmailTemplate($scope.data.selectedEmailTemplate);
        };

        $scope.loadTemplates = function() {

            const teamId = authService.getTeamId();
            const userId = authService.getUserId();

            const request = {
                team_id: teamId,
                user_id: userId
            };

            templateService.getTemplates(request)
                .then(results => {
                    const templates = results.templates || [];
                    $scope.data.templates.email = templates.filter(item => item.type === 'email');
                    $scope.data.templates.script = templates.filter(item => item.type === 'script');
                    $scope.$apply();
                })
                .catch(err => {
                    console.error(err);
                    $scope.$apply();
                });
        };

        $scope._mergeTemplateAttributes = function(html_text, contact, user) {
            const contactAttributes = [
                'main_phone',
                'secondary_phone',
                'first_name',
                'last_name',
                'company',
                'email',
                'fax_number',
                'address',
                'suite/apt',
                'city',
                'state',
                'country',
                'zip_code',
                'reason'
            ];
            const userAttributes = [
                'username',
                'first_name',
                'last_name',
                'email',
                'home_phone',
                'office_phone',
                'mobile_phone'
            ];

            let returnHtmlText = html_text;

            contactAttributes.map(contactAttribute => {
                const value = contact && contact[contactAttribute] ? contact[contactAttribute] : '';

                returnHtmlText = returnHtmlText.replace(new RegExp(`{{contact.${contactAttribute}}}`, "g"), value);
                returnHtmlText = returnHtmlText.replace(new RegExp(`{{ contact.${contactAttribute}}}`, "g"), value);
                returnHtmlText = returnHtmlText.replace(new RegExp(`{{contact.${contactAttribute} }}`, "g"), value);
                returnHtmlText = returnHtmlText.replace(new RegExp(`{{ contact.${contactAttribute} }}`, "g"), value);
            });

            userAttributes.map(userAttribute => {
                const value = user && user[userAttribute] ? user[userAttribute] : '';

                returnHtmlText = returnHtmlText.replace(new RegExp(`{{user.${userAttribute}}}`, "g"), value);
                returnHtmlText = returnHtmlText.replace(new RegExp(`{{ user.${userAttribute}}}`, "g"), value);
                returnHtmlText = returnHtmlText.replace(new RegExp(`{{user.${userAttribute} }}`, "g"), value);
                returnHtmlText = returnHtmlText.replace(new RegExp(`{{ user.${userAttribute} }}`, "g"), value);
            });

            return returnHtmlText;
        };

        $scope.getFromlocalStorageCollection = function() {
            var checkValue = ($scope.mode === 'create') ? $scope.data.selectedTask.contact_id : $scope.data.selectedTask.task_id;
            var messageText = '';
            var messageNote = '';

            if($scope.localStorageCollection.has(checkValue)) {
                var localStorageValue = $scope.localStorageCollection.get(checkValue);
                if(localStorageValue.email !== null) {
                    $scope.data.selectedTask.messageEmailObject.to = localStorageValue.email.to;
                    $scope.data.selectedTask.messageEmailObject.from = localStorageValue.email.from;
                    $scope.data.selectedTask.messageEmailObject.cc = localStorageValue.email.cc;
                    $scope.data.selectedTask.messageEmailObject.bcc = localStorageValue.email.bcc;
                    $scope.data.selectedTask.messageEmailObject.subject = localStorageValue.email.subject;
                    $scope.data.selectedTask.messageEmailObject.html = localStorageValue.email.html;
                    $scope.data.selectedTask.messageEmailObject.template_id = localStorageValue.email.template_id;
                }
                messageText = (localStorageValue.sms !== null) ? localStorageValue.sms : '';
                messageNote = (localStorageValue.note !== null) ? localStorageValue.note : '';
            }
            $scope.data.selectedTask.messageText = messageText;
            $scope.data.selectedTask.messageNote = messageNote;
        };

        $scope.emailTextAreaTaskObjectsInit = function() {
            $scope.data.selectedTask.messagesMode = 'note';
            $scope.resetEmailMessageData();
            $scope.resetSmsData();

            $scope.getFromlocalStorageCollection();
            $scope.resetEmailQuill();
            $scope.resetSmsQuill();
        };

        $scope.setSelectedTask = function (task) {
            $scope.closeEmailDetailsInputs();
            $scope.staticData.view.footer.email.openedAccordion = false;

            $scope.data.selectedTask = task;

            if ($scope.data.selectedTask) {
                $scope.data.firstInitTabs = true;

                $scope.emailTextAreaTaskObjectsInit();
            }
        };

        $scope.reopenAfterSend = function() {
            if($scope.data.selectedTask.status && $scope.data.selectedTask.status !== 'open') {
                $rootScope.$broadcast('changeTaskStatusFromTextArea', 'open', true);
            }
        };

        $scope.sendSms = function() {
            if(!$scope.isSmsTextAreaValid() || $scope.data.selectedTask.sendingMessageTextArea) {
                return;
            }
            if(!$rootScope.isBalance() && !$rootScope.isSubscribedOutboundCommunicationPlan()) {
                $rootScope.showUpsellModalOutboundCommunicationSwal();
                return;
            }

            if ($scope.data.fromPhoneNumbers.length === 0) {
                swal('Cancelled', 'Your account does not have any SMS enabled phone numbers.', 'error');
                return;
            }

            if (!$scope.data.smsSendFrom.from || $scope.data.smsSendFrom.from === null ||
                !$scope.data.smsSendFrom.callerId || $scope.data.smsSendFrom.callerId === null) {
                swal('Cancelled', 'Please setup at least one phone number with an approved SMS use case.', 'error');
                return;
            }

            $scope.data.selectedTask.sendingMessageTextArea = true;
            $scope.data.selectedTask.smsQuill.enable(false);

            const teamId = authService.getTeamId();
            const userId = authService.getUserId();
            const taskId = $scope.data.selectedTask.task_id;
            const contactId = $scope.data.selectedTask.contact_id;
            const replySmsItem = vm.timelineItemToAnswerSms;

            const smsRequest = {
                team_id: teamId,
                user_id: userId,
                task_id: taskId,
                contact_id: contactId,
                phone_number:
                    $scope.mode === 'create' ?
                        $scope.data.selectedTask.contact.main_phone :
                        replySmsItem ?
                            replySmsItem.from :
                            $scope.data.selectedTask.sms ? $scope.data.selectedTask.sms.from : $scope.data.selectedTask.contact.main_phone,
                caller_id: $scope.data.smsSendFrom.from.phone_number,
                sms_body: $scope.data.selectedTask.messageText.trim(),
                action: 'Say',
                create_task: $scope.mode === 'create'
            };

            return phoneService.sendSms(smsRequest)
                .then(result => {
                    $scope.clearSmsUpload();
                    $scope.smsDraftLocalStorage();
                    $scope.data.selectedTask.smsQuill.enable(true);
                    $scope.data.selectedTask.sendingMessageTextArea = false;
                    $scope.resizeTimelineCardFooter();
                    $scope.reopenAfterSend();
                    $rootScope.$broadcast('messageSent', result.entry_id);
                    $scope.$apply();
                    notificationToastService.showSuccessToastBottomRight('fa-light fa-message-check', 'Sms has been sent!');
                })
                .then(() => {
                    $scope.changeTextAreaTab('note');
                    $scope.$apply();
                })
                .catch(err => {
                    console.error(err);
                    $scope.data.selectedTask.smsQuill.enable(true);
                    $scope.data.selectedTask.sendingMessageTextArea = false;
                    $scope.$apply();
                    if (err && err.message) {
                        if (err.message === 'Team is missing subscription or verification') {
                            swal('Cancelled', 'Please verify your identity to use this feature', 'error');
                        } else if (err.message === 'Delivery failed') {
                            swal('Cancelled', 'Delivery failed, please try again', 'error');
                        } else if (err.message === 'Contact is on DNC list') {
                            swal('Cancelled', 'Contact is on your Do Not Contact list', 'error');
                        } else if (err.message === 'Contact is known litigator') {
                            swal('Cancelled', 'Contact is a known litigator', 'error');
                        } else if ('Subscription plan does not have SMS capabilities.  Please upgrade.') {
                            swal({
                                text: 'Current plan does not have SMS capabilities.  Please upgrade.',
                                buttons: ['Cancel', 'Upgrade Plan'],
                                closeOnClickOutside: false,
                                icon: 'error'
                            }).then((confirm) => {
                                if (!confirm) { return; }

                                $location.path('/team');
                            });
                        } else {
                            swal("Cancelled", err.message);
                        }
                    } else {
                        notificationToastService.showErrorToastBottomRight('fa-light fa-triangle-exclamation', 'We were unable to send sms. Try again later.');
                    }
                });
        };

        $scope.sendNote = function() {
            if(!$scope.isNoteTextAreaValid() || $scope.data.selectedTask.sendingMessageTextArea) {
                return;
            }
            $scope.data.selectedTask.sendingMessageTextArea = true;

            const teamId = authService.getTeamId();
            const userId = authService.getUserId();

            const noteReq = {
                team_id: teamId,
                user_id: userId,
                type: 'contact',
                note: $scope.data.selectedTask.messageNote.trim(),
                contact_id: $scope.data.selectedTask.contact_id || $scope.data.selectedTask.contact.contact_id,
                task_id: $scope.data.selectedTask.task_id,
                create_task: $scope.mode === 'create'
            };

            contactService.setContactNotes(noteReq)
                .then(note => {
                    $scope.data.selectedTask.messageNote = '';
                    $scope.noteDraftLocalStorage();
                    $scope.data.selectedTask.sendingMessageTextArea = false;
                    $scope.resizeTimelineCardFooter();
                    $scope.reopenAfterSend();
                    $rootScope.$broadcast('messageSent',
                    {
                        task_id: $scope.mode === 'create' ? $scope.data.selectedTask.task_id : note.task_id,
                        entry_id: note.entry_id
                    });
                    $scope.$apply();
                    notificationToastService.showSuccessToastBottomRight('fa-light fa-memo-circle-check', 'Note has been saved!');
                })
                .catch(err => {
                    $scope.data.selectedTask.sendingMessageTextArea = false;
                    $scope.$apply();
                    notificationToastService.showErrorToastBottomRight('fa-light fa-triangle-exclamation', 'We were unable to save note. Try again later.');
                });
        };

        $scope.replyToEmail = function(item) {
            $scope.clearEmailUpload();
            const personalMailbox = $scope.data.mailboxes.find(mailbox => mailbox.user_id !== null);
            let to = [];
            let from = [];
            if (item.direction === 'outbound') {
                let itemToAnswer = null;
                if (item.from && typeof $scope.data.mailboxes.find(mailbox => mailbox.title === item.from[0].address) !== 'undefined') {
                    const mailbox = $scope.data.mailboxes.find(mailbox => mailbox.title === item.from[0].address);
                    itemToAnswer = [{
                        address:  mailbox.title,
                        name: `${$scope.data.user.first_name} ${$scope.data.user.last_name}`
                    }];
                } else {
                    itemToAnswer = [{
                        address:  personalMailbox.title,
                        name: `${$scope.data.user.first_name} ${$scope.data.user.last_name}`
                    }]
                }
                from = itemToAnswer;
                to = (item.to || []);
            } else {
                let itemToAnswer = null;
                if (item.to && typeof $scope.data.mailboxes.find(mailbox => mailbox.title === item.to[0].address) !== 'undefined') {
                    const mailbox = $scope.data.mailboxes.find(mailbox => mailbox.title === item.to[0].address);
                    itemToAnswer = [{
                        address:  mailbox.title,
                        name: `${$scope.data.user.first_name} ${$scope.data.user.last_name}`
                    }];
                } else {
                    itemToAnswer = [{
                        address:  personalMailbox.title,
                        name: `${$scope.data.user.first_name} ${$scope.data.user.last_name}`
                    }]
                }
                from = itemToAnswer;
                to = (item.from || []);
            }
            $scope.data.selectedTask.messageEmailObject = {
                contact_id: $scope.data.selectedTask.contact.contact_id,
                reply_to_email_id: item.email_id,
                html: '',
                to: to,
                to_string: to.map(i => `${i.name} (${i.address})`).join(', '),
                from: from,
                fromString: from.map(i => `${i.name} (${i.address})`).join(', '),
                cc: item.cc || [],
                ccString: '',
                bcc: item.bcc || [],
                bccString: '',
                state: 'draft',
                template_id: null
            };

            if (item.subject) {
                if (!(new RegExp(/Re:/)).test(item.subject)) {
                    $scope.data.selectedTask.messageEmailObject.subject = `Re: ${item.subject}`;
                } else {
                    $scope.data.selectedTask.messageEmailObject.subject = item.subject;
                }
            } else {
                $scope.data.selectedTask.messageEmailObject.subject = `Re:`;
            }

            if($scope.data.selectedTask.messagesMode !== 'email') {
                $scope.changeTextAreaTab('email');
            }
        };

        $scope.isEmailMessageDataValid = function() {
            var isMessageValid = ($scope.data.selectedTask && $scope.data.selectedTask.emailQuill && $scope.data.selectedTask.emailQuill.getText().trim().length > 0) ? true : false;
            var isAttachmentValid = ($scope.data.selectedTask && $scope.data.selectedTask.formDataEmail.get('attachments') !== null) ? true : false;

            if(!isMessageValid && !isAttachmentValid) {
                return false;
            }
            return true;
        };

        $scope.sendEmail = function() {
            if(!$scope.isEmailMessageDataValid() || $scope.data.selectedTask.sendingMessageTextArea) {
                return;
            }
            const toArr = $scope.data.selectedTask.messageEmailObject.to_string && $scope.data.selectedTask.messageEmailObject.to_string.split(',').map(i => $scope.__processEmailInput(i)) || [];
            const regEmail = new RegExp(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
            if (toArr.some(toObj => !regEmail.test(toObj.address))) {
                $scope.data.isValidTo = false;
                return;
            }
            if(!$rootScope.isBalance() && !$rootScope.isSubscribedOutboundCommunicationPlan()) {
                $rootScope.showUpsellModalOutboundCommunicationSwal();
                return;
            }
            $scope.data.selectedTask.sendingMessageTextArea = true;
            $scope.staticData.view.footer.email.openedAccordion = false;

            const request = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                task_id: $scope.data.selectedTask.task_id,
                contact_id: $scope.data.selectedTask.contact.contact_id,
                state: 'final',
                from: $scope.data.selectedTask.messageEmailObject.fromString && $scope.data.selectedTask.messageEmailObject.fromString.split(',').map(i => $scope.__processEmailInput(i)) || [],
                to: $scope.data.selectedTask.messageEmailObject.to_string && $scope.data.selectedTask.messageEmailObject.to_string.split(',').map(i => $scope.__processEmailInput(i)) || [],
                cc: $scope.data.selectedTask.messageEmailObject.ccString && $scope.data.selectedTask.messageEmailObject.ccString.split(',').map(i => $scope.__processEmailInput(i)) || [],
                bcc: $scope.data.selectedTask.messageEmailObject.bccString && $scope.data.selectedTask.messageEmailObject.bccString.split(',').map(i => $scope.__processEmailInput(i)) || [],
                template_id: $scope.data.selectedTask.messageEmailObject.template_id,
                create_task: $scope.mode === 'create'
            };

            if (!$scope.validateEmail(request)) {
                $scope.staticData.view.footer.email.openedAccordion = true;
                $scope.data.selectedTask.sendingMessageTextArea = false;
                return;
            }
            $scope.data.selectedTask.emailQuill.enable(false);
            const data = Object.assign($scope.data.selectedTask.messageEmailObject, request);
            for (const key in data) {
                if (
                    typeof data[key] === 'object' &&
                    data[key] !== null
                ) {
                    $scope.data.selectedTask.formDataEmail.append(key, JSON.stringify(data[key]));
                } else {
                    $scope.data.selectedTask.formDataEmail.append(key, data[key]);
                }
            }
            const attachments = $scope.data.selectedTask.formDataEmail.get('attachments');
            if (attachments) {
                const reader = new FileReader();
                reader.readAsText(attachments);
                reader.onload = function (evt) {
                    attachments.content = evt.target.result;
                    $scope.createEmail();
                }
                reader.onerror = function (evt) {
                    $scope.data.selectedTask.formDataEmail.attachments = [];
                }
            } else {
                return $scope.createEmail();
            }
        };

        $scope.createEmail = function() {
            emailService.createEmail($scope.data.selectedTask.formDataEmail)
                .then(result => {
                    if (result.success) {
                        $scope.clearEmailUpload();
                        $scope.emailDraftLocalStorage();
                        $scope.data.selectedTask.emailQuill.enable(true);
                        $scope.data.selectedTask.sendingMessageTextArea = false;
                        $scope.reopenAfterSend();
                        $scope.$apply();
                        notificationToastService.showSuccessToastBottomRight('fa-light fa-envelope-circle-check', 'Email has been sent!');
                        $scope.changeTextAreaTab('note');
                        $scope.$apply();
                    } else {
                        swal("Error", result.error, "error");
                        $scope.data.selectedTask.emailQuill.enable(true);
                        $scope.data.selectedTask.sendingMessageTextArea = false;
                        $scope.$apply();
                    }
                })
                .catch(err => {
                    console.error(err);
                    $scope.data.selectedTask.emailQuill.enable(true);
                    $scope.data.selectedTask.sendingMessageTextArea = false;
                    $scope.$apply();
                    notificationToastService.showErrorToastBottomRight('fa-light fa-triangle-exclamation', 'We were unable to send email. Try again later.');
                });
        }

        $scope.__processEmailInput = function(i) {
            const split = i.trim().split(' ');
            const regEmail = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            let address = split.find(val => regEmail.test(val.replace(/[(]/g, '').replace(/[)]/g, '')));
            if (address) {
                address = address.replace(/[(]/g, '').replace(/[)]/g, '');
            }
            const name = split.filter(val => !regEmail.test(val.replace(/[(]/g, '').replace(/[)]/g, '')) && val).join(' ');
            return { name, address };
        };

        $scope.validateEmail = function(request) {
            const errors = [];
            if (!request.to || !request.to.filter(to => to.address).filter(to => to).length) {
                errors.push('Email\'s receiver is required ("To").');
            }
            if (!request.from || !request.from.filter(from => from.address).filter(from => from).length) {
                errors.push('Email\'s sender is required ("From").');
            }

            if (errors.length) {
                _.forEach(errors, function(error) {
                    notificationToastService.showErrorToastWithCloseBtn('fa-light fa-triangle-exclamation', error, 3000);
                });
                return false;
            }
            return true;
        };

        $scope.toggleEmailInput = function(inputName) {
            $scope.staticData.view.footer.email[inputName] = !$scope.staticData.view.footer.email[inputName];
        }

        $scope.toggleAccordionTextAreaTabs = function(e) {
            e.preventDefault();
            $scope.staticData.view.footer.email.openedAccordion = !$scope.staticData.view.footer.email.openedAccordion;
        }

        $scope.setSmsQuillTextArea = function(value) {
            if($scope.data.selectedTask.smsQuill) {
                $scope.data.selectedTask.smsQuill.setText(value);
            }
        };

        $scope.setEmailQuillTextAreaHtml = function(htmlValue) {
            if($scope.data.selectedTask.emailQuill) {
                const value = htmlValue;
                const delta = $scope.data.selectedTask.emailQuill.clipboard.convert(value);
                $scope.data.selectedTask.emailQuill.setContents(delta, 'silent');
            }
        };

        $scope.checkAndRemoveDraftsLocalStorage = function(id) {
            if($scope.localStorageCollection.has(id)) {
                var localStorageValue = $scope.localStorageCollection.get(id);
                if(localStorageValue.sms === null && localStorageValue.email === null && localStorageValue.note === null ) {
                    $scope.localStorageCollection.remove(id);
                }
            }
        };
        $scope.updateDraftsLocalStorage = function(id, type, value) {
            if($scope.localStorageCollection.has(id)) {
                var localStorageValue = $scope.localStorageCollection.get(id);
                localStorageValue[type] = value;
                $scope.localStorageCollection.set(id, localStorageValue);
            }
        };
        $scope.addNewDraftsLocalStorage = function(id, sms, note, email) {
            $scope.localStorageCollection.set(id, {
                sms: sms,
                email: email,
                note: note
            });
        };

        $scope.messageDraftLocalStorage = function(id, type, value) {
            if($scope.localStorageCollection.has(id)) {
                $scope.updateDraftsLocalStorage(id, type, value);
            } else {
                var sms = (type === 'sms') ? value : null;
                var note = (type === 'note') ? value : null;
                var email = (type === 'email') ? value : null;
                $scope.addNewDraftsLocalStorage(id, sms, note, email);
            }
            $scope.checkAndRemoveDraftsLocalStorage(id);
        };

        $scope.emailDraftLocalStorage = function() {
            var emailDraft = null;
            if($scope.data.selectedTask.emailQuill.getText().trim().length > 0) {
                emailDraft = {
                    to: $scope.data.selectedTask.messageEmailObject.to,
                    from: $scope.data.selectedTask.messageEmailObject.from,
                    cc: $scope.data.selectedTask.messageEmailObject.cc,
                    bcc: $scope.data.selectedTask.messageEmailObject.bcc,
                    subject: $scope.data.selectedTask.messageEmailObject.subject,
                    html: $scope.data.selectedTask.messageEmailObject.html,
                    template_id: $scope.data.selectedTask.messageEmailObject.template_id
                }
            }
            $scope.messageDraftLocalStorage(($scope.mode === 'create') ? $scope.data.selectedTask.contact.contact_id : $scope.data.selectedTask.task_id, 'email', emailDraft);
        };

        $scope.isSmsTextAreaValid = function() {
            let isMessageValid = ($scope.data.selectedTask && $scope.data.selectedTask.smsQuill && $scope.data.selectedTask.smsQuill.getText().trim().length > 0) ? true : false;
            let isAttachmentValid = ($scope.data.selectedTask && $scope.data.selectedTask.formDataSms.get('attachments') !== null) ? true : false;

            if(!isMessageValid && !isAttachmentValid) {
                return false;
            }
            return true;
        };

        $scope.smsDraftLocalStorage = function() {
            var smsDraft = null;
            var sms = $scope.data.selectedTask.smsQuill.getText().trim();
            if(sms.length > 0) {
                smsDraft = sms;
            }
            $scope.messageDraftLocalStorage(($scope.mode === 'create') ? $scope.data.selectedTask.contact.contact_id : $scope.data.selectedTask.task_id, 'sms', smsDraft);
        };

        $scope.isNoteTextAreaValid = function() {
            var note = ($scope.data.selectedTask) ? $scope.data.selectedTask.messageNote.trim() : '';
            if(note.length > 0) {
                return true;
            }
            return false;
        };

        $scope.noteDraftLocalStorage = function() {
            var noteDraft = null;
            var note = $scope.data.selectedTask.messageNote.trim();
            if($scope.isNoteTextAreaValid()) {
                noteDraft = note;
            }
            $scope.messageDraftLocalStorage(($scope.mode === 'create') ? $scope.data.selectedTask.contact.contact_id : $scope.data.selectedTask.task_id, 'note', noteDraft);
        };

        $scope.noteChanged = function() {
            $scope.noteDraftLocalStorage();
            $scope.resizeTimelineCardFooter();
        };

        $scope.setEmailDetailsVariable = function(variableSrc, variableDest) {
            var temp = $scope.data.selectedTask.messageEmailObject[variableSrc] && $scope.data.selectedTask.messageEmailObject[variableSrc].split(',').map(i => $scope.__processEmailInput(i)) || [];
            $scope.data.selectedTask.messageEmailObject[variableDest] = temp;
        };

        $scope.emailDetailsChanged = function(variableName) {

            switch(variableName) {
                case 'from': $scope.setEmailDetailsVariable('fromString', 'from'); break;
                case 'to':
                    $scope.data.isValidTo = true;
                    $scope.setEmailDetailsVariable('to_string', 'to');
                    break;
                case 'cc': $scope.setEmailDetailsVariable('ccString', 'cc'); break;
                case 'bcc': $scope.setEmailDetailsVariable('bccString', 'bcc'); break;
            };

            $scope.emailDraftLocalStorage();
        };

        $scope.initEmailQuill = function() {
            if(!$scope.data.selectedTask.emailQuill) {
                $scope.data.selectedTask.emailQuill = new Quill('#emailTextarea', {
                    placeholder: $scope.staticData.view.footer.email.placeholder,
                    theme: 'snow',
                    modules: {
                        toolbar: '#emailToolbar'
                    }
                });

                $scope.data.selectedTask.emailQuill.on('editor-change', function() {
                    $scope.$evalAsync();
                    $scope.data.selectedTask.messageEmailObject.html = $scope.data.selectedTask.emailQuill.root.innerHTML;

                    $scope.emailDraftLocalStorage();
                    $scope.resizeTimelineCardFooter();
                });
            }
            if($scope.data.selectedTask.messageEmailObject && $scope.data.selectedTask.messageEmailObject.html) {
                $scope.setEmailQuillTextAreaHtml($scope.data.selectedTask.messageEmailObject.html);
            }
        };

        $scope.initSmsQuill = function() {
            if(!$scope.data.selectedTask.smsQuill) {
                $scope.data.selectedTask.smsQuill = new Quill('#smsTextarea', {
                    placeholder: $scope.staticData.view.footer.text.placeholder,
                    theme: 'snow',
                    modules: {
                        toolbar: '#smsToolbar'
                    }
                });

                $scope.data.selectedTask.smsQuill.on('editor-change', function() {
                    $scope.$evalAsync();
                    $scope.data.selectedTask.messageText = $scope.data.selectedTask.smsQuill.getText();

                    $scope.smsDraftLocalStorage();
                    $scope.resizeTimelineCardFooter();
                });
            }
            if($scope.data.selectedTask.messageText) {
                $scope.setSmsQuillTextArea($scope.data.selectedTask.messageText);
            }
        };

        $scope.setEmailDetails = function(subject, to, from) {
            $scope.data.selectedTask.messageEmailObject.subject = ($scope.data.selectedTask.messageEmailObject.subject && $scope.data.selectedTask.messageEmailObject.subject.length > 0) ? $scope.data.selectedTask.messageEmailObject.subject : subject;
            $scope.data.selectedTask.messageEmailObject.to = ($scope.data.selectedTask.messageEmailObject.to.length > 0) ? $scope.data.selectedTask.messageEmailObject.to : to;
            $scope.data.selectedTask.messageEmailObject.from = ($scope.data.selectedTask.messageEmailObject.from.length > 0) ? $scope.data.selectedTask.messageEmailObject.from : from;

            $scope.data.selectedTask.messageEmailObject.to_string = $scope.data.selectedTask.messageEmailObject.to.map(i => {
                const address = i.address ? ` (${i.address})` : ``;
                return `${i.name}${address}`;
            }).join(', ');
            $scope.data.selectedTask.messageEmailObject.fromString = $scope.data.selectedTask.messageEmailObject.from.map(i => {
                const address = i.address ? ` (${i.address})` : ``;
                return `${i.name}${address}`;
            }).join(', ');
            $scope.data.selectedTask.messageEmailObject.ccString = $scope.data.selectedTask.messageEmailObject.cc.map(i => {
                const address = i.address ? ` (${i.address})` : ``;
                return `${i.name}${address}`;
            }).join(', ');
            $scope.data.selectedTask.messageEmailObject.bccString = $scope.data.selectedTask.messageEmailObject.bcc.map(i => {
                const address = i.address ? ` (${i.address})` : ``;
                return `${i.name}${address}`;
            }).join(', ');
        };

        $scope.getFrom = function() {
            const itemToAnswer = vm.timelineItemToAnswerEmail;

            const personalMailbox = $scope.data.mailboxes.find(from => from.user_id !== null);
            let from = [];

            if (vm.timelineItemToAnswerEmail && typeof $scope.data.mailboxes.find(mailbox => mailbox.title === itemToAnswer.to[0].address) !== 'undefined') {
                from = [{
                    address:  itemToAnswer.to[0].address,
                    name: `${$scope.data.user.first_name} ${$scope.data.user.last_name}`
                }];
            } else {
                if (!personalMailbox) {
                    return from;
                }
                from = [{
                    address:  personalMailbox.title,
                    name: `${$scope.data.user.first_name} ${$scope.data.user.last_name}`
                }]
            }
            return from;
        }

        $scope.initEmailDetailsSelectedTask = function() {
            const from = $scope.getFrom();

            let selectedTaskName = `${$scope.data.selectedTask.contact.first_name} ${$scope.data.selectedTask.contact.last_name ? $scope.data.selectedTask.contact.last_name : ''}`;
            if (!$scope.data.selectedTask.contact.first_name && !$scope.data.selectedTask.contact.last_name){
                selectedTaskName = $scope.data.selectedTask.contact.email || 'Unknown Contact';
            }
            const itemToAnswer = vm.timelineItemToAnswerEmail;
            const to = itemToAnswer ? (itemToAnswer.from || []).concat(itemToAnswer.cc || [], itemToAnswer.bcc || []) : [{
                address: $scope.data.selectedTask.contact.email,
                name: selectedTaskName
            }];
            const subject = itemToAnswer ? itemToAnswer.subject ? `Re: ${itemToAnswer.subject}` : 'Re: ' : '';

            $scope.setEmailDetails(subject, to, from);
        };

        $scope.setSmsCallerId = function() {
            $scope.data.smsSendFrom.from = _.find($scope.data.fromPhoneNumbers, function(c) { return c.campaign_id === $scope.data.smsSendFrom.callerId.campaign_id; });
        };

        $scope.initSmsCallerId = function() {
            const replySmsItem = vm.timelineItemToAnswerSms;

            let from = $scope.data.fromPhoneNumbers[0];

            if (replySmsItem) {
                const prevSmsSentTo = $scope.data.fromPhoneNumbers.find(number => number.phone_number === replySmsItem.to);
                if (typeof prevSmsSentTo !== 'undefined') {
                    from = prevSmsSentTo;
                }
            }

            $scope.data.smsSendFrom.from = from;
            $scope.data.smsSendFrom.callerId = _.find($scope.data.campaignMap, function(c) { return c.campaign_id === from.campaign_id; });
        };

        $scope.selectEmailTab = function() {

            if($scope.data.user && $scope.data.selectedTask.contact) {
                $scope.initEmailDetailsSelectedTask();
            }

            $scope.$watch('data.selectedTask.contact', function(newVal, oldVal) {
                if(newVal && !_.isEmpty(newVal) && (!oldVal || _.isEmpty(oldVal))) {
                    $scope.initEmailDetailsSelectedTask();
                }
            }, true);

            $scope.$watch('data.mailboxes', function(newVal, oldVal) {
                if(newVal && !_.isEmpty(newVal) && (!oldVal || _.isEmpty(oldVal))) {
                    $scope.initEmailDetailsSelectedTask();
                }
            }, true);

            $scope.initEmailQuill();
        };

        $scope.selectSmsTab = function() {

            if($scope.data.campaignMap) {
                $scope.initSmsCallerId();
            }

            $scope.$watch('data.campaignMap', function(newVal, oldVal) {
                if(newVal && !_.isEmpty(newVal) && (!oldVal || _.isEmpty(oldVal))) {
                    $scope.initSmsCallerId();
                }
            }, true);

            $scope.initSmsQuill();
        };

        $scope.changeTextAreaTab = function(mode) {
            $scope.data.selectedTask.messagesMode = mode;
        };

        $scope.resetSmsQuill = function() {
            if($scope.data.selectedTask.smsQuill) {
                $scope.data.selectedTask.smsQuill.off('editor-change');
                delete $scope.data.selectedTask['smsQuill'];
            }
        };

        $scope.resetEmailQuill = function() {
            if($scope.data.selectedTask.emailQuill) {
                // $('#email-templates-btn-id .ql-picker-item:not([data-value=""])').off('click');
                $scope.data.selectedTask.emailQuill.off('editor-change');
                delete $scope.data.selectedTask['emailQuill'];
            }
        };

        $scope.changeTextAreaModeListener = function() {
            var emailTab = $('#email-tab');
            var textTab = $('#text-tab');
            var noteTab = $('#note-tab');

            $scope.$watch('data.selectedTask.messagesMode', function(newVal, oldVal) {
                switch(newVal) {
                    case 'note': noteTab.tab('show'); break;
                    case 'text': textTab.tab('show'); break;
                    case 'email': emailTab.tab('show'); break;
                };
            }, true);

            noteTab.on('show.bs.tab', function () {
                $scope.resizeTimelineCardFooter();
            });
            textTab.on('show.bs.tab', function () {
                $scope.selectSmsTab();
                $scope.resizeTimelineCardFooter();
            });

            emailTab.on('show.bs.tab', function () {
                $scope.selectEmailTab();
                $scope.resizeTimelineCardFooter();

                document.querySelector('.email-details-block').addEventListener('transitionend', $scope.resizeTimelineCardFooter);
            });
            emailTab.on('hide.bs.tab', function () {
                document.querySelector('.email-details-block').removeEventListener('transitionend', $scope.resizeTimelineCardFooter);
            });
        };

        $scope.setDisplayTextAreaTabs = function(isEmailTab, isTextTab, isNoteTab) {
            $('#email-tab').css('display', (isEmailTab) ? 'block' : '');
            $('#text-tab').css('display', (isTextTab) ? 'block' : '');
            $('#note-tab').css('display', (isNoteTab) ? 'block' : '');
        };

        $scope.initTextAreaTabs = function () {

            if($scope.data.selectedTask.task_type) {
                switch($scope.data.selectedTask.task_type) {
                    case 'sms':
                        $scope.setDisplayTextAreaTabs(false, true, true);
                        break;
                    case 'voicemail':
                        $scope.setDisplayTextAreaTabs(false, true, true);
                        break;
                    case 'fax':
                        $scope.setDisplayTextAreaTabs(false, false, true);
                        break;
                    case 'email':
                        $scope.setDisplayTextAreaTabs(true, false, true);
                        break;
                    case 'inbound_call':
                    case 'outbound_call':
                    case 'missed_call':
                        $scope.setDisplayTextAreaTabs(false, true, true);
                        break;
                    case 'note':
                        $scope.setDisplayTextAreaTabs(true, true, true);
                        break;
                    default:
                        $scope.setDisplayTextAreaTabs(false, false, true);
                };
            } else {
                $scope.setDisplayTextAreaTabs(true, true, true);
            }

            $scope.data.selectedTask.messagesMode = (vm.tabMode && vm.tabMode !== null) ? vm.tabMode : 'note';
            $scope.changeTextAreaModeListener();
        };

        $scope.setHeightTimeline = function(resetHeight) {
            var width = (window.innerWidth > 0) ? window.innerWidth : this.screen.width;
            var footerId = ($scope.mode === 'create') ? 'text-area-timeline-footer' : 'inbox-timeline-footer';
            var timelineId = ($scope.mode === 'create') ? 'contact-timeline' : 'task-timeline';

            var contactDetailsConversationOffset = (width < 768) ? 399 : 375;
            var contactDetailsOffset = (width < 768) ? 410 : 382;

            var offsetConversationPage = (vm.conversationPage && vm.conversationPage === 'contact-details') ? contactDetailsConversationOffset : 351;
            var offset = ($scope.mode === 'create') ? contactDetailsOffset : offsetConversationPage;

            var footer = $('#' + footerId);
            var timeline = $('#' + timelineId);

            setTimeout(function() {
                var heightOffset = (resetHeight) ? '' : 'calc(100vh - ' + (offset + footer.height()) + 'px)';
                timeline.css('height', heightOffset);
            }, 100);
        };

        $scope.resizeTimelineCardFooter = function(e) {
            $scope.setHeightTimeline($scope.data.firstInitTabs);
            $scope.data.firstInitTabs = ($scope.data.firstInitTabs) ? false : $scope.data.firstInitTabs;
        };

        window.onresize = $scope.resizeTimelineCardFooter;

        $scope.removeEmailAttachment = function(key) {
            $scope.data.selectedTask.formDataEmail.delete(key);
            $scope.resizeTimelineCardFooter();
        };

        $scope.removeSmsAttachment = function(key) {
            $scope.data.selectedTask.formDataSms.delete(key);
            $scope.resizeTimelineCardFooter();
        };

        $scope.emailInputFileChanged = function(event) {
            let target = event.currentTarget || {};
            if (target && target.files && target.files.length) {
                const file = target.files[0];

                for (const key of $scope.data.selectedTask.formDataEmail.keys()) {
                    $scope.data.selectedTask.formDataEmail.delete(key);
                }
                $scope.data.selectedTask.formDataEmail.append('attachments', file, file.fileName);

                target.value = ""; //reset file input
                $scope.resizeTimelineCardFooter();
                $scope.$apply();
            }
        };

        $scope.smsInputFileChanged = function(event) {
            let target = event.currentTarget || {};
            if (target && target.files && target.files.length) {
                const file = target.files[0];

                for (const key of $scope.data.selectedTask.formDataSms.keys()) {
                    $scope.data.selectedTask.formDataSms.delete(key);
                }
                $scope.data.selectedTask.formDataSms.append('attachments', file, file.fileName);

                target.value = ""; //reset file input
                $scope.resizeTimelineCardFooter();
                $scope.$apply();
            }
        };

        $scope.initSmsFileUpload = function() {
            let importFileSmsInput = document.getElementById('import-file-sms');
            importFileSmsInput.addEventListener('change', $scope.smsInputFileChanged);
        };

        $scope.initEmailFileUpload = function() {
            let importFileEmailInput = document.getElementById('import-file-email');
            importFileEmailInput.addEventListener('change', $scope.emailInputFileChanged);
        };

        $scope.resetSmsData = function() {
            $scope.data.selectedTask.messageText = '';
            $scope.data.smsSendFrom.from = null;
            $scope.data.smsSendFrom.callerId = null;
            $scope.data.selectedTask.formDataSms = new FormData();
        };

        $scope.resetEmailMessageData = function() {
            $scope.data.selectedTask.messageEmailObject = _.cloneDeep($scope.data.messageObject);
            $scope.data.selectedTask.formDataEmail = new FormData();
        };

        $scope.closeEmailDetailsInputs = function() {
            // close inputs
            $scope.staticData.view.footer.email.hideEmailTo = false;
            $scope.staticData.view.footer.email.hideEmailFrom = false;
            $scope.staticData.view.footer.email.hideEmailCC = false;
            $scope.staticData.view.footer.email.hideEmailBCC = false;
            $scope.staticData.view.footer.email.hideEmailSubject = false;
        };

        $scope.clearSmsUpload = function() {
            $scope.setSmsQuillTextArea('');

            $scope.resetSmsData();
            $scope.initSmsCallerId();

            $scope.resizeTimelineCardFooter();
        };

        $scope.clearEmailUpload = function() {
            $scope.closeEmailDetailsInputs();
            $scope.setEmailQuillTextAreaHtml('');

            $scope.resetEmailMessageData();
            $scope.initEmailDetailsSelectedTask();

            $scope.resizeTimelineCardFooter();
        };

        $scope._applyEmailTemplate = function(template) {
            // var templateMergedText = (template.mergedText) ? template.mergedText : $scope._mergeTemplateAttributes(template.text, $scope.data.selectedTask.contact, $rootScope.user);
            var templateMergedHtml = (template.mergedHtml) ? template.mergedHtml : $scope._mergeTemplateAttributes(template.html_text, $scope.data.selectedTask.contact, $rootScope.user);

            if($scope.data.selectedTask.messagesMode !== 'email') {
                $scope.data.selectedTask.messageEmailObject.html = templateMergedHtml;
                $scope.changeTextAreaTab('email');
            } else {
                $scope.setEmailQuillTextAreaHtml(templateMergedHtml);
            }
            $scope.data.selectedTask.messageEmailObject.template_id = template.template_id;
        };

        $scope.loadContact = function(id) {
            if(id && id !== null && $scope.page === 'contact-details') {
                var teamId = authService.getTeamId();
                var userId = authService.getUserId();

                const request = {
                    team_id: teamId,
                    user_id: userId,
                    contact_id: id,
                };

                contactService.getContactDetails(request)
                    .then(result => {
                        if (result.status !== 200) {
                            return;
                        }
                        $scope.data.selectedTask.contact = result.contact;
                        if (!Array.isArray($scope.data.selectedTask.contact.tags)) {
                            $scope.data.selectedTask.contact.tags = [];
                        }
                        $scope.getFromlocalStorageCollection();
                    })
                    .catch(err => {
                        console.error(err);
                    });
            }
        };

        $scope.checkPage = function() {
            switch($scope.page) {
                case 'contact-details':
                    $scope.mode = 'create';
                    $scope.setSelectedTask({contact: {}});
                    break;
                case 'inbox':
                    $scope.mode = 'send';
                    $scope.setSelectedTask(vm.task);
                    break;
            }
        };

        $scope.setFromItem = function (item) {
            const from = [{
                address: item,
                name: `${$scope.data.user.first_name} ${$scope.data.user.last_name}`
            }];
            $scope.data.selectedTask.messageEmailObject.from = from;

            $scope.data.selectedTask.messageEmailObject.fromString = $scope.data.selectedTask.messageEmailObject.from.map(i => {
                const address = i.address ? ` (${i.address})` : ``;
                return `${i.name}${address}`;
            }).join(', ');
        }

        $scope.getAvailableEmailSenders = function() {
            $scope.data.availableFrom = []
            const teamId = authService.getTeamId();
            const userId = authService.getUserId();

            const request = {
                team_id: teamId,
                user_id: userId,
                mailbox_user_id: userId,
                mailbox_user_group_ids: authService.getUser().user_group_ids,
            };
            return mailboxesService.getMailboxes(request)
                .then(results => {
                    $scope.data.availableFrom = results.map(res => res.title) || [];
                    $scope.data.mailboxes = results || [];
                    $scope.$apply();
                })
                .catch(err => {
                    console.error(err);
                    $scope.$apply();
                });
        }

        $scope.loadBrands = function() {
            let req = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                only_shared_campaigns: "1"
            };

            accountService.getBrands(req)
                .then(results => {
                    let brands = results || [];
                    $rootScope.no_client_opt_in = brands.filter(brand => brand && brand.opt_in_flow && brand.opt_in_flow.includes('I don\'t have opt-in from my contacts')).length > 0;

                    $scope.data.campaignMap = [];
                    for (let i = 0; i < brands.length; i++) {
                        let brand = brands[i];

                        let ucaasCampaigns = brand.ucaas_campaigns || [];
                        for (let u = 0; u < ucaasCampaigns.length; u++) {
                            let ucaasCampaign = ucaasCampaigns[u];
                            $scope.data.campaignMap = _.concat($scope.data.campaignMap, ucaasCampaign);
                        }

                        let bulkCampaigns = brand.bulk_campaigns || [];
                        for (let b = 0; b < bulkCampaigns.length; b++) {
                            let bulkCampaign = bulkCampaigns[b];
                            $scope.data.campaignMap = _.concat($scope.data.campaignMap, bulkCampaign);
                        }
                    }

                    $scope.data.brands = brands;
                    $scope.$apply();
                })
                .catch(err => {
                    console.log(err);
                });
        };

        $('#addEditUserModal').on('hide.bs.modal', function (e) {
            $scope.getAvailableEmailSenders()
                .then(() => {
                    const from = $scope.getFrom();
                    if ($scope.data.selectedTask.messageEmailObject.from.length === 0) {
                        $scope.data.selectedTask.messageEmailObject.from = ($scope.data.selectedTask.messageEmailObject.from.length > 0) ? $scope.data.selectedTask.messageEmailObject.from : from;
                        $scope.data.selectedTask.messageEmailObject.fromString = $scope.data.selectedTask.messageEmailObject.from.map(i => {
                            const address = i.address ? ` (${i.address})` : ``;
                            return `${i.name}${address}`;
                        }).join(', ');
                    } else {
                        if ($scope.data.availableFrom.length === 0) {
                            $scope.data.selectedTask.messageEmailObject.from = [];
                            $scope.data.selectedTask.messageEmailObject.fromString = '';
                        }
                    }
                    $scope.$apply();
                });
        });

        $scope.$on('shownRightSideBar', function () {
            $scope.resizeTimelineCardFooter();
        });

        $scope.$on('hiddenRightSideBar', function () {
            $scope.resizeTimelineCardFooter();
        });

        vm.$onInit = function() {
            $scope.localStorageCollection = new Fifo({ namespace: ($scope.page === 'contact-details') ? 'contactsNewTask' : 'tasks' });
            $scope.page = $attrs.page;
            $scope.checkPage();

            Promise.all([
                $scope.loadContact(vm.contactId),
                $scope.loadTemplates(),
                authService.getUser(),
                $scope.loadBrands()
            ]).then((results) => {
                const phoneNums = $rootScope.team_phone_numbers || [];
                $scope.data.fromPhoneNumbers = phoneNums.filter(number =>
                    number.features === 'voice_and_sms' &&
                    number.tcr_campaign_id &&
                    number.tcr_status === 'registered'
                );

                $scope.data.user = results[2];
                $scope.getAvailableEmailSenders();
            })
            .catch(err => {
                console.error(err);
                $scope.$apply();
            });
        };

        $scope.$on('applyTemplateTextArea', function (event, template) {
            $scope._applyEmailTemplate(template);
        });
        $scope.$on('replyToEmailTextArea', function (event, item) {
            $scope.replyToEmail(item);
        });
    }

    module.exports = TextAreaController;
})();
