(function() {
    'use strict';

    function NotificationToastService() {
        function show(type, icon, title, text, position, hideAfter, allowToastClose, stack, loader) {
            var content = '<div class="row ml-0 mr-0 toast-notification">' +
                '<div class="col-3 toast-icon-col ' + type + '">' +
                '<i class="' + icon + '"></i>' +
                '</div>' +
                '<div class="col-9 toast-content-col">' +
                '<h5 class="card-title mb-2">' + title + '</h5>' +
                '<h6 class="card-subtitle mt-0">' + text + '</h6>' +
                '</div>';

            $.toast({
                text: content,
                position: position,
                hideAfter : hideAfter,
                loaderBg: '#54667a',
                stack: stack,
                showHideTransition: 'slide',
                loader: loader,
                allowToastClose : allowToastClose
            });
        }

        function showSuccessToast(icon, message) {
            show('success', icon, 'Success!', message, 'top-center', 5000, false, false, true);
        }

        function showErrorToast(icon, message) {
            show('error', icon, 'Error!', message, 'top-center', 5000, false, false, true);
        }

        function showWarningToast(icon, message) {
            show('warning', icon, 'Warning!', message, 'top-center', 5000, false, false, true);
        }

        function showInfoToast(icon, message) {
            show('success', icon, title || '', message, 'top-center', 5000, false, false, true);
        }

        function showInfoToastWithCloseBtn(icon, title, message) {
            show('success', icon, title || '', message, 'top-center', 5000, true, 4, false);
        }

        function showSuccessToastBottomRight(icon, message) {
            show('success', icon, 'Success!', message, 'bottom-right', 5000, false, false, true);
        }

        function showErrorToastBottomRight(icon, message) {
            show('error', icon, 'Error!', message, 'bottom-right', 5000, false, false, true);
        }


        function showErrorToastWithCloseBtn(icon, message, hideAfter = false) {
            show('error', icon, 'Error!', message, 'bottom-right', hideAfter, true, 4, false);
        }

        return {
            showSuccessToast: showSuccessToast,
            showErrorToast: showErrorToast,
            showWarningToast: showWarningToast,
            showInfoToast: showInfoToast,
            showInfoToastWithCloseBtn: showInfoToastWithCloseBtn,
            showErrorToastWithCloseBtn: showErrorToastWithCloseBtn,
            showSuccessToastBottomRight: showSuccessToastBottomRight,
            showErrorToastBottomRight: showErrorToastBottomRight
        };
    }
    module.exports = NotificationToastService;
})();

