(function() {
    'use strict';

    function StoreSelectedTaskService() {
        let self = this;
        self.task = null;

        function setTask(task) {
            self.task = task;
        }

        function getTask() {
            return self.task;
        }

        return {
            getTask: getTask,
            setTask: setTask
        };
    }
    module.exports = StoreSelectedTaskService;
})();

