(function() {
    'use strict';

    function ViewComponentController(authService, $rootScope, $scope, $window) {
        var vm = this;
        vm.authService = authService;
        
    }
    module.exports = ViewComponentController;
})();
