(function() {
    'use strict';

    function UserAddController($rootScope, $scope, authService, userService, $location) {
        var vm = this;
        vm.authService = authService;

        $rootScope.viewData = $rootScope.viewData || {};
        $rootScope.viewData.breadcrumbs = $rootScope.viewData.breadcrumbs || [];
        $rootScope.viewData.breadcrumbs = [{
            title: 'Add User',
            link_name: 'Add User',
            link_url: '#/team',
            help_url: $rootScope.help_url
        }];

        $scope.staticContent = {
            title: "User Information",
            labels: [
                "First Name",
                "Last Name",
                "Office phone",
                "Home phone",
                "Mobile phone",
                "Email Address",
                "Username",
                "Password",
                "Mobile Access Pin (6 Digits)",
                "Role"
            ],
            inputPlaceholders: [
                "Enter new user first name",
                "Enter new user last name",
                "Enter new user office phone",
                "Enter new user home phone",
                "Enter new user mobile phone",
                "Enter new user email",
                "Enter new user username",
                "Enter new user password",
                "Enter new user mobile access pin",
                "Enter new user role"
            ],
            confirmationModal: {
                header: "Adding New User",
                body1: "Please select the plan for the user you're about to create. You can review plan feautres and pricing ",
                body2: "here",
                pricingUrl:  WEBPACK_URL + "pricing",
                confirm: "Confirm",
                cancel: "Cancel",
                plans: {
                    businessPhone: "Business Phone",
                    advancedTracking: "Advanced Tracking",
                    powerDialer: "Auto Dialer",
                    predictiveDialer: "Predictive Dialer"
                }
            },
            saveUser: "Save User"
        };

        $scope.data = {
            teamRoles: null,
            selectedPlan: 'back-office-monthly',
            userDetails : {
                username: '',
                password: '',
                first_name: '',
                last_name: '',
                email: '',
                pin: '',
                role_id: '',
                chargebee: '',
                office_phone: '',
                home_phone: '',
                mobile_phone: ''
            }
        };

        $scope.phoneVerification = {
            office_phone: {},
            home_phone: {},
            mobile_phone: {}
        };

        $scope.saveUser = function() {

            if($location.search().id){

                $scope.updateUser($location.search().id)
            }
            else {
                $scope.createUser($location.search().id)
            }
        };

        $scope.updateUser = function(userId) {

            if($scope.isValidPin()) {

                let request = {};
                for (let field in $scope.data.userDetails) {
                    request[field] = $scope.data.userDetails[field];
                }
                request.team_id = authService.getTeamId();
                request.user_id = authService.getUserId();
                request.updated_user_id = userId;
                request.needs_setup = !userId;

                $scope.hideConfirmationModal();

                userService.updateUser(request)
                    .then(user => {
                        swal("Added", "The user "+ request.username +" has been updated", "success");
                        $location.path('/team');
                        $scope.$apply();
                    })
                    .catch(err => {
                        swal("Server Error", "We were unable to update the user.  Try again later.", "error");
                    });
            }
        };

        $scope.createUser = function() {

            if($scope.isValidPin()) {

                let request = {};
                for (let field in $scope.data.userDetails) {
                    request[field] = $scope.data.userDetails[field];
                }
                request.team_id = authService.getTeamId();
                request.user_id = authService.getUserId();
                request.selected_plan = $scope.data.selectedPlan;

                $scope.hideConfirmationModal();

                userService.createUser(request)
                    .then(user => {
                        swal("Added", "The user "+ request.username +" has been added", "success");
                        $location.path('/team');
                        $scope.$apply();
                    })
                    .catch(err => {
                        swal("Server Error", "We were unable to add the user.  Try again later.", "error");
                    });
            }
        };

        $scope.setPhoneMask = function(elem, mask, placeholder) {
            elem.mask(mask, {
                placeholder: placeholder
            });
        };

        $scope.showConfirmationModal = function() {

            if($location.search().id){
                $scope.saveUser()
            } else {
                $("#confirmationModal").modal('show');
            }
        };

        $scope.hideConfirmationModal = function() {

            $("#confirmationModal").modal('hide');
        };

        $scope.modalPhoneNumberMask = function(number, phoneType) {
            var phoneNumberId = $("#" + phoneType);
            var phoneNumberIdInput = document.querySelector("#" + phoneType);

            if(phoneNumberId) {
                $scope[phoneType] = window.intlTelInput(phoneNumberIdInput, {
                    nationalMode: "false",
                    autoPlaceholder: 'aggressive',
                    separateDialCode: "true",
                    initialCountry: 'us',
                    utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/11.0.11/js/utils.js" ,
                    customPlaceholder: function(selectedCountryPlaceholder, selectedCountryData) {
                        var pl = selectedCountryPlaceholder.replace(/[0-9]/g, '0');
                        phoneNumberId.attr('maxlength', pl.length);
                        $scope.setPhoneMask(phoneNumberId, pl, pl);
                        return pl;
                    }
                });

                if(number){
                    $scope[phoneType].setNumber(number);
                }

                phoneNumberId.on("countrychange", function(e) {
                    $scope[phoneType].setNumber("");
                    phoneNumberId.unmask();

                    var mask = e.target.placeholder.replace(/[0-9]/g, '0');
                    phoneNumberId.attr('maxlength', mask.length);
                    $scope.setPhoneMask(phoneNumberId, mask, e.target.placeholder);
                });
            }
        };

        $scope.clearIti = function(phoneType) {
            $scope.phoneVerification[phoneType].is_valid = undefined;
            $scope[phoneType].setNumber("");
            $("#" + phoneType).unmask();
            $scope[phoneType].destroy();
        };


        $scope.formatPhone = function(phoneType) {
            if($scope[phoneType]) {
                $scope.phoneVerification[phoneType].is_valid = $scope[phoneType].isValidNumber();
                $scope.phoneVerification[phoneType].number = ($scope.phoneVerification[phoneType].is_valid) ? $scope[phoneType].getNumber(window.intlTelInput.utils.numberFormat.E164) : '';
                $scope.data.userDetails[phoneType] = $scope.phoneVerification[phoneType].number;
            }
        };

        $scope.isValidPin = function() {

            return $scope.data.userDetails.pin.length === 6;
        };

        $scope.loadUser = function(userId) {

            const userReq = {
                target_user_id: userId,
                team_id: authService.getTeamId(),
                user_id: authService.getUserId()
            };

            userService.getUser(userReq)
                .then(user => {

                    $scope.data.userDetails = {
                        username: user.username,
                        password: '',
                        first_name: user.identity.first_name,
                        last_name: user.identity.last_name,
                        email: user.identity.email,
                        pin: user.auth.pin || '',
                        role_id: user.role_id,
                        office_phone: user.identity.office_phone,
                        home_phone: user.identity.home_phone,
                        mobile_phone: user.identity.mobile_phone,
                    };

                    $scope.$apply();
                })
                .catch(err => {
                    console.log(err);
                });
        };

        $scope.onInit = function() {
            $scope.data.teamRoles = authService.getUser().team.roles;
            const nRoles = $scope.data.teamRoles.length;
            if (nRoles) {
                $scope.data.userDetails.role_id = $scope.data.teamRoles[nRoles-1].role_id;
            }

            if($location.search().id) {
                $scope.loadUser($location.search().id);
            }

            for(var phoneType in $scope.phoneVerification) {
                setTimeout(function () {
                    for (var phoneType in $scope.phoneVerification) {
                        $scope.modalPhoneNumberMask('', phoneType);
                    }
                }, 300);
            }
        };

        if (authService.hasAccount()) {
            $scope.onInit();
        } else {
            $scope.$on('auth_complete', function(event, args) {
                $scope.onInit();
            });
        }
    }
    module.exports = UserAddController;
})();
