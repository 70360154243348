(function() {
    'use strict';

    function NoteTypePlaceholderListItemController(authService) {
        var vm = this;
        vm.authService = authService;

    }
    module.exports = NoteTypePlaceholderListItemController;
})();
