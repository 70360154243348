(function () {
    'use strict';

    function ByocController(authService, $rootScope, $scope, $http, $location, $window, generalHelperService) {

        let vm = this;
        vm.authService = authService;

        $rootScope.viewData = $rootScope.viewData || {};
        $rootScope.viewData.breadcrumbs = $rootScope.viewData.breadcrumbs || [];
        $rootScope.viewData.breadcrumbs = [{
            title: 'Bring Your Own Carrier',
            link_name: 'Bring Your Own Carrier',
            link_url: '#/byoc',
            help_url: $rootScope.help_url
        }];

        $scope.staticContent = {
            byocExplainer: {
                subtitle: 'Already Have a Provider?',
                title: 'Bring Your Own Carrier',
                description: `Do you have phone numbers and SIP Trunks with another carrier? Want to call phone numbers outside of the US & Canada? Already completed KYC with another carrier? You may be able to use ${$rootScope.settings.APP_NAME} with your existing phone service.`,
                btn: {
                    title: 'Learn More',
                    href: 'https://help.callcowboy.com/'
                }
            },
            callToAction: {
                preferred: 'Preferred Partner',
                commio: {
                    title: 'Enterprise Voice and Texting Provider',
                    description: 'Commio is our premier partner providing enterprise grade voice phone and texting services. Reduce costs by up 70% compared to Twillo, Bandwidth, and other providers.',
                    btn: {
                        title: 'Create Account',
                        href: 'https://www.commio.com/'
                    }
                },
                twilio: {
                    title: 'Lorem ipsum dolor sit amet',
                    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
                    btn: {
                        title: 'Create Account',
                        href: 'https://www.twilio.com/en-us'
                    }
                },
                telnyx: {
                    title: 'Lorem ipsum dolor sit amet',
                    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
                    btn: {
                        title: 'Create Account',
                        href: 'https://telnyx.com/'
                    }
                },
                haveAccount: 'Already Have an Account? Enter your API Token Below.'
            },
            contactUs: {
                title: 'Don\'t See Your Provider?',
                subtitle: 'We Connect To Most SIP Providers!',
                description: `${$rootScope.settings.APP_NAME} connects with 100\'s of voice and text messaging providers. Contact us today to discuss how we can connect with your current provider.`,
                btn: { title: 'Contact Us' }
            },
            assistanceWithAccount: {
                title: 'Need Assistance With Your Account?',
                commio: {
                    description: `${$rootScope.settings.APP_NAME} connects with 100\'s of voice and text messaging providers. Contact us today to discuss how we can assist you with connecting to your current provider.`,
                    btn: { title: 'Contact Us' }
                },
                twilio: {
                    description: `${$rootScope.settings.APP_NAME} connects with 100\'s of voice and text messaging providers. Contact us today to discuss how we can assist you with connecting to your current provider.`,
                    btn: { title: 'Contact Us' }
                },
                telnyx: {
                    description: `${$rootScope.settings.APP_NAME} connects with 100\'s of voice and text messaging providers. Contact us today to discuss how we can assist you with connecting to your current provider.`,
                    btn: { title: 'Contact Us' }
                }
            },
            providers: {
                credentials: {
                    title: 'API Credentials',
                    inputPlaceholder: 'Please enter',
                    btn: 'Save',
                    successStatus: 'Connected',
                    errorStatus: 'Invalid Credentials'
                },
                defaultProvider: {
                    title: 'Default Provider'
                },
                channels: {
                    title: 'Channels'
                },
                cps: {
                    title: 'CPS'
                },
                btnNeedAnAccount: {
                    title: 'Need an account?',
                    href: {
                        commio: 'https://www.commio.com/',
                        twilio: 'https://www.twilio.com/en-us',
                        telnyx: 'https://telnyx.com/'
                    }
                },
            },
            logos: {
                commio: 'assets/logos/commio.webp',
                twilio: 'assets/logos/twilio.png',
                telnyx: 'assets/logos/telnyx.png'
            },
            loading: 'Loading',
            saving: 'Saving'
        };

        $scope.data = {
            pageMode: 'default', // 'default', 'provider'
            pageModeProvider: null,
            callToAction: 'commio', //'twilio', 'telnyx', 'commio'
            providers: [],
            cpsLimit: { min: 1, max: 9999 },
            channelLimit: { min: 1, max: 99999 }
        };

        $scope.loading = {
            providers: true
        };

        $scope.contactUs = function() {
            Intercom('show');
        };

        $scope.checkProviderCPS = function(newValue) {
            if (newValue.length === 0) {
                return 'Invalid Value';
            }
        };

        $scope.newProviderCPSValue = '';
        $scope.editProviderCPS = function(provider) {
            if($scope.newProviderCPSValue !== provider.cps) {
                $scope.newProviderCPSValue = provider.cps;

                provider.saving = true;

                //placeholder
                setTimeout(()=> {
                    provider.saving = false;
                }, 2000);
            }
        };

        $scope.checkProviderChannels = function(newValue) {
            if (newValue.length === 0) {
                return 'Invalid Value';
            }
        };

        $scope.newProviderChannelsValue = '';
        $scope.editProviderChannels = function(provider) {
            if($scope.newProviderChannelsValue !== provider.channels) {
                $scope.newProviderChannelsValue = provider.channels;

                provider.saving = true;

                //placeholder
                setTimeout(()=> {
                    provider.saving = false;
                }, 2000);
            }
        };

        $scope.setDefaultProvider = function(provider) {
            console.log(provider);
        };

        $scope.submitCredentials = function(event, provider, form) {
            event.preventDefault();
            let formName = provider.provider + 'Form';

            if(form[formName].$invalid) {
                return;
            }

            provider.saving = true;

            //placeholder
            setTimeout(()=> {
                provider.saving = false;
            }, 2000);
        };

        $scope.loadProviders = function() {
            $scope.loading.providers = true;

            setTimeout(()=> { //placeholder

                $scope.data.providers = [ //placeholder
                    {
                        provider: 'commio',
                        connected: true,
                        default: true,
                        channels: 200,
                        cps: 20
                    },
                    {
                        provider: 'twilio',
                        connected: false,
                        default: false,
                        channels: 10,
                        cps: 1
                    },
                    {
                        provider: 'telnyx',
                        connected: false,
                        default: false,
                        channels: 10,
                        cps: 2
                    }
                ];

                $scope.loading.providers = false;
            }, 2000); //placeholder
        };

        $scope.$on('hiddenRightSideBar', function () {
            $('.byoc-section').removeClass('open-right-sidebar');
        });
        $scope.$on('shownRightSideBar', function () {
            $('.byoc-section').addClass('open-right-sidebar');
        });

        $scope.onInit = function() {
            $window.scrollTo(0, 0);

            let params = $location.search();
            $scope.data.pageMode = (params.provider) ? 'provider' : 'default';
            $scope.data.pageModeProvider = (params.provider) ? params.provider : null;

            generalHelperService.waitForElement('#rightPanel')
                .then(rightPanel => {
                    if($(rightPanel).hasClass('shw-rside')) {
                        $('.byoc-section').addClass('open-right-sidebar');
                    }
                });

            $scope.loadProviders();
        };

        if (authService.hasAccount()) {
            $scope.onInit();
        }
        else {
            $scope.$on('auth_complete', function(event, args) {
                $scope.onInit();
            });
        }
    }
    module.exports = ByocController;
})();
