(function() {
    'use strict';

    function RulesTypePlaceholderListItemController(authService) {
        var vm = this;
        vm.authService = authService;

    }
    module.exports = RulesTypePlaceholderListItemController;
})();
