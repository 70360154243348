(function() {
    'use strict';

    function WelcomeModalController(authService, userService, accountService, phoneService, dispatcherService, $rootScope, $scope, $location, $interval, $window) {
        var vm = this;
        vm.authService = authService;

        $scope.global = $rootScope;
        $scope.state = 'welcome';

        $scope.forms = {
            verification: {
                number: '',
                is_valid_phone_number: undefined,
                is_valid_email: undefined,
            },
            office: {
                number: '',
                is_valid_phone_number: undefined,
            },
            mobile: {
                number: '',
                is_valid_phone_number: undefined,
            },
            home: {
                number: '',
                is_valid_phone_number: undefined,
            }
        };

        $scope.data = {
            ivr_config:'voicemail',
            team_config: 'noEmployees',
            consent: {
                material: false,
                compliance: false
            },
            dispatcher_data: Object.assign({}, $rootScope.dispatcher_template),
            left_footer_button: {
                text: '',
                isDisplayed: false,
                isDisabled: true
            },
            right_footer_button: {
                text: '',
                isDisplayed: false,
                isDisabled: true
            },
            welcome_submited: false,
            consent_submited: false,
            verification_submited: false,
            saving: false
        };

        $scope.clearIti = function(type) {
            if($scope.forms[type].iti) {
                $scope.forms[type].is_valid_phone_number = undefined;
                $scope.forms[type].iti.setNumber('');
                $('#' + type).unmask();
                $scope.forms[type].iti.destroy();
                $scope.forms[type].iti = undefined;
            }
        };

        $scope.saveUserNumbers = function() {
            $scope.data.saving = true;

            let req = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                updated_user_id: authService.getUserId(),
                office_phone: $scope.forms.office.number,
                mobile_phone: $scope.forms.mobile.number,
                home_phone: $scope.forms.home.number
            };

            $rootScope.user.office_phone = req.office_phone;
            $rootScope.user.mobile_phone = req.mobile_phone;
            $rootScope.user.home_phone = req.home_phone;

            userService.updateUser(req)
                .then(user => {
                })
                .catch(err => {
                    console.log(err);
                });
        };

        $scope.updateUserCookiePhone = function() {
            let userCookie = $rootScope.secureLS.get('user');
            userCookie.home_phone = $scope.forms.home.number;
            userCookie.office_phone = $scope.forms.office.number;
            userCookie.mobile_phone = $scope.forms.mobile.number;
            $rootScope.secureLS.set('user', userCookie);
            $scope.data.saving = false;
        };

        $scope.__getVoiceIvrRules = function() {

            switch($scope.data.ivr_config) {
                case 'voicemail':
                    return {
                        name: "Send to Voicemail",
                        rules:  [{
                            start_rule : true,
                            end_rule : false,
                            action: 'Voicemail',
                            action_data: {
                                say: 'Please leave your message after the tone.',
                                email_to: $rootScope.user.email
                            }
                        }]
                    };
                case 'office_phone':
                    return {
                        name: "Forward To Office Phone",
                        rules: [{
                            start_rule : true,
                            end_rule : false,
                            action : "Forward To",
                            action_data : {
                                repeat_count : 1,
                                route_to_type : $scope.data.ivr_config,
                                route_to: $rootScope.user.office_phone,
                                route_to_user_id: $rootScope.user.user_id,
                                route_to_valid : true
                            }
                        }]
                    };
                case 'mobile_phone':
                    return {
                        name: "Forward To Mobile Phone",
                        rules: [{
                            start_rule : true,
                            end_rule : false,
                            action : "Forward To",
                            action_data : {
                                repeat_count : 1,
                                route_to_type : $scope.data.ivr_config,
                                route_to: $rootScope.user.mobile_phone,
                                route_to_user_id: $rootScope.user.user_id,
                                route_to_valid : true
                            }
                        }]
                    };
                case 'home_phone':
                    return {
                        name: "Forward To Home Phone",
                        rules: [{
                            start_rule : true,
                            end_rule : false,
                            action : "Forward To",
                            action_data : {
                                repeat_count : 1,
                                route_to_type : $scope.data.ivr_config,
                                route_to: $rootScope.user.home_phone,
                                route_to_user_id: $rootScope.user.user_id,
                                route_to_valid : true
                            }
                        }]
                    };
            }

            return null;
        };

        $scope.__getSmsIvrRules = function() {

            switch($scope.data.ivr_config) {
                case 'voicemail':
                    return {
                        name: "Do Nothing",
                        rules: [{
                            start_rule : true,
                            end_rule : false,
                            action: 'Do Nothing',
                            action_data: {
                                email_to: $rootScope.user.email
                            }
                        }]
                    };
                case 'office_phone':
                    return {
                        name: "Forward To Office Phone",
                        rules: [{
                            start_rule : true,
                            end_rule : false,
                            action : "Forward To",
                            action_data : {
                                repeat_count : 1,
                                route_to_type : $scope.data.ivr_config,
                                route_to: $rootScope.user.office_phone,
                                route_to_user_id: $rootScope.user.user_id,
                                route_to_valid : true
                            }
                        }]
                    };
                case 'mobile_phone':
                    return {
                        name: "Forward To Mobile Phone",
                        rules: [{
                            start_rule : true,
                            end_rule : false,
                            action : "Forward To",
                            action_data : {
                                repeat_count : 1,
                                route_to_type : $scope.data.ivr_config,
                                route_to: $rootScope.user.mobile_phone,
                                route_to_user_id: $rootScope.user.user_id,
                                route_to_valid : true
                            }
                        }]
                    };
                case 'home_phone':
                    return {
                        name: "Forward To Home Phone",
                        rules: [{
                            start_rule : true,
                            end_rule : false,
                            action : "Forward To",
                            action_data : {
                                repeat_count : 1,
                                route_to_type : $scope.data.ivr_config,
                                route_to: $rootScope.user.home_phone,
                                route_to_user_id: $rootScope.user.user_id,
                                route_to_valid : true
                            }
                        }]
                    };
            }

            return null;
        };

        $scope.createDefaultIvrs = function() {
            $scope.data.saving = true;
            let promises = [];

            const voiceRules = $scope.__getVoiceIvrRules();
            if (voiceRules) {
                const voiceReq = {
                    team_id: authService.getTeamId(),
                    user_id: authService.getUserId(),
                    name: voiceRules.name,
                    rules: voiceRules.rules,
                    default: true,
                    type: 'voice'
                };

                promises.push(phoneService.createIvr(voiceReq));
            }

            const smsRules = $scope.__getSmsIvrRules();
            if (smsRules) {
                const smsReq = {
                    team_id: authService.getTeamId(),
                    user_id: authService.getUserId(),
                    name: smsRules.name,
                    rules: smsRules.rules,
                    default: true,
                    type: 'sms'
                };

                promises.push(phoneService.createIvr(smsReq));
            }

            Promise.all(promises)
                .then(results => {
                     $scope.data.saving = false;
                     $scope.$apply();
                })
                .catch(err => {
                    console.log(err);
                    $scope.data.saving = false;
                    $scope.$apply();
                });
        };

        $scope.getDialinFlags = function() {
            let hasEmployees;
            let requirePin;

            switch($scope.data.team_config) {
                case 'noEmployees':
                    hasEmployees = false;
                    requirePin = false;
                    break;
                case 'noSharedPhone':
                    hasEmployees = true;
                    requirePin = false;
                    break;
                case 'pinRequired':
                    hasEmployees = true;
                    requirePin = true;
                    break;
            }

            return {
                has_employees: hasEmployees,
                require_pin: requirePin
            };
        };

        $scope.saveTeamInfo = function() {
            $scope.data.saving = true;

            const updateTeamReq = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                dialin: $scope.getDialinFlags()
            };

            accountService.updateTeam(updateTeamReq)
                .then(results => {
                    $scope.user.needs_setup = false;
                    $rootScope.secureLS.set('user', $scope.user);
                    $scope.$apply();
                })
                .catch(err => {
                    console.log(err);
                });
        };

        $scope.createDispatcher = function() {
            const request = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                list_id: null,
                dispatcher_data: $scope.data.dispatcher_data
            };

            dispatcherService.createDispatcher(request)
                .then(results => {
                    $scope.data.saving = false;
                    $scope.$apply();
                })
                .catch(err => {
                    console.error(err);
                    $scope.data.saving = false;
                    $scope.$apply();
                });
        };

        $scope.saveConsent = function() {
            $scope.data.saving = true;

            var req = {
                user_id: authService.getUserId(),
                team_id: authService.getTeamId()
            };

            accountService.saveConsent(req)
                .then(results => {
                    $scope.user.team.consent = true;
                    $rootScope.secureLS.set('user', $scope.user);
                    $scope.data.saving = false;
                    $scope.$apply();
                })
                .catch(err => {
                    console.log(err);
                    $scope.data.saving = false;
                    $scope.$apply();
                });
        };

        $scope.skipIntro = function() {
            $('#welcome-modal').modal('hide');
        };

        $scope.startIntro = function() {
            $('#welcome-modal').modal('hide');
            $rootScope.showWalkthrough();
        };

        $scope.__isVerificationValid = function() {
            var isValid = ($scope.user.team.identity.first_name && $scope.user.team.identity.first_name.length > 0) &&
                ($scope.user.team.identity.last_name && $scope.user.team.identity.last_name.length > 0) &&
                (!!$scope.forms.verification.is_valid_phone_number && $scope.forms.verification.number !== '') &&
                $scope.isValidEmail();

            return isValid;
        };

        $scope.isValidEmail = function () {
            const regEmail = $rootScope.settings.USER_EMAIL_REGEX;
            if ($scope.user.team.identity.email && $scope.user.team.identity.email.length > 0 && regEmail.test($scope.user.team.identity.email)) {
                $scope.forms.verification.is_valid_email = true;
            } else {
                $scope.forms.verification.is_valid_email = false;
            }

            return $scope.forms.verification.is_valid_email;
        };

        $scope.sendVerifications = function() {
            $scope.data.saving = true;

            var req = {
                user_id: authService.getUserId(),
                team_id: authService.getTeamId(),
                phone: $scope.forms.verification.number,
                first_name: $scope.user.team.identity.first_name,
                last_name: $scope.user.team.identity.last_name,
                email: $scope.user.team.identity.email
            };

            accountService.sendVerifications(req)
                .then(results => {
                    if (results && results.success) {
                        $scope.state = 'verification_sent';
                        $rootScope.user.team.vouched_invite_id = results.vouched_invite_id;
                        $scope.user.team.vouched_invite_id = results.vouched_invite_id;
                        $rootScope.secureLS.set('user', $rootScope.user);
                    } else if (results && results.error) {
                        switch (results.error) {
                            case 'invites already sent':
                                swal('Error', "You already submitted a verification request. Please check your text messages or email. If you haven't received the verification link, please chat with us.", "error");
                                break;
                            default:
                                swal('Error', results.error, "error");
                                break
                        }
                    } else {
                        swal('Error', 'Unable to verify code at this time. Please try later', "error");
                    }
                    $scope.data.saving = false;
                    $scope.$apply();
                })
                .catch(err => {
                    console.log(err);
                    $scope.data.saving = false;
                    $scope.$apply();
                    swal('Error', 'Unable to verify code at this time. Please try later', "error");
                });
        };

        $scope.formatPhone = function(type) {
            if($scope.forms[type].iti) {
                $scope.forms[type].is_valid_phone_number = ($scope.forms[type].iti.getNumber().length > 0 ) ? $scope.forms[type].iti.isValidNumber() : undefined;
                $scope.forms[type].number = ($scope.forms[type].is_valid_phone_number) ? $scope.forms[type].iti.getNumber(window.intlTelInput.utils.numberFormat.E164) : '';
            }
        };

        $scope.__isWelcomeValid = function() {
            $scope.formatPhone('mobile');
            $scope.formatPhone('office');
            $scope.formatPhone('home');

            var isValidNumber = (!!$scope.forms.mobile.is_valid_phone_number && $scope.forms.mobile.number !== '') ||
                (!!$scope.forms.office.is_valid_phone_number && $scope.forms.office.number !== '') ||
                (!!$scope.forms.home.is_valid_phone_number && $scope.forms.home.number !== '');

            var isValid = ($scope.forms.mobile.is_valid_phone_number !== false &&
                $scope.forms.office.is_valid_phone_number !== false && $scope.forms.home.is_valid_phone_number !== false) &&
                isValidNumber;

            return isValid;
        };

        $scope.setPhoneMask = function(elem, mask, placeholder) {
            elem.mask(mask, {
                placeholder: placeholder
            });
        };

        $scope.modalPhoneNumberMask = function(number, type) {
            if(!$scope.forms[type].iti) {
                var phoneNumberId = $('#welcome-' + type);
                var phoneNumberIdInput = document.querySelector('#welcome-' + type);

                if(phoneNumberIdInput) {
                    $scope.forms[type].iti = window.intlTelInput(phoneNumberIdInput, {
                        nationalMode: 'false',
                        autoPlaceholder: 'aggressive',
                        separateDialCode: 'true',
                        validationNumberTypes: [
                            'FIXED_LINE',
                            'MOBILE',
                            'FIXED_LINE_OR_MOBILE',
                            'TOLL_FREE',
                            'PREMIUM_RATE',
                            'SHARED_COST',
                            'VOIP',
                            'PERSONAL_NUMBER',
                            'PAGER',
                            'UAN',
                            'VOICEMAIL',
                            'UNKNOWN'
                        ],
                        initialCountry: 'us',
                        // intl-tel-input is up to date as of dec 2024
                        loadUtils: () => import("https://cdn.jsdelivr.net/npm/intl-tel-input@25.2.0/build/js/utils.js"),
                        customPlaceholder: function(selectedCountryPlaceholder, selectedCountryData) {
                            var pl = selectedCountryPlaceholder.replace(/[0-9]/g, '0');
                            phoneNumberId.attr('maxlength', pl.length);
                            $scope.setPhoneMask(phoneNumberId, pl, pl);
                            return pl;
                        }
                    });

                    if(number){
                        $scope.forms[type].iti.setNumber(number);
                    }

                    phoneNumberId.on('countrychange', function(e) {
                        $scope.forms[type].iti.setNumber('');
                        phoneNumberId.unmask();
                        $scope.forms[type].is_valid_phone_number = undefined;

                        var mask = e.target.placeholder.replace(/[0-9]/g, '0');
                        phoneNumberId.attr('maxlength', mask.length);
                        $scope.setPhoneMask(phoneNumberId, mask, e.target.placeholder);
                        $scope.$apply();
                    });
                }
            }
        };

        $scope.initWelcomeState = function() {
            $scope.forms.office.number = $scope.user.office_phone || '';
            $scope.forms.mobile.number = $scope.user.mobile_phone || '';
            $scope.forms.home.number = $scope.user.home_phone || '';
            $scope.forms.verification.number = ($scope.user.team.identity || {}).phone || $scope.user.mobile_phone  || '';

            setTimeout(function () {
                for (var type in $scope.forms){
                    let number = $scope.forms[type].number;
                    $scope.modalPhoneNumberMask(number, type);
                }
            }, 300);
        };

        $scope.setInitialState = function() {
            if($scope.state !== 'verification') {
                $scope.state = $scope.user.owner ? 'welcome' : 'tour';
            }
        };

        $scope.$on('show_verification_modal', function(event, args) {
            $scope.state = 'verification';
            $scope.showModal();
        });

        $scope.showModal = function() {
            $scope.user = authService.getUser();
            $scope.setInitialState();

            $("#welcome-modal" ).on('show.bs.modal', function() {
                $scope.initWelcomeState();
            });

            $('#welcome-modal').modal({backdrop: 'static', keyboard: false}).modal('show');
        };

        $scope.leftFooterButton = function() {
            switch($scope.state) {
                case 'routing':
                    $scope.clearIti('mobile');
                    $scope.clearIti('office');
                    $scope.clearIti('home');
                    $scope.data.welcome_submited = false;
                    $scope.initWelcomeState();
                    $scope.state = 'welcome';
                    break;
                case 'business':
                    $scope.state = 'routing';
                    break;
                case 'consent':
                    $scope.state = 'business';
                    break;
                case 'tour':
                    $scope.skipIntro();
                    break;
                case 'verification':
                    $scope.clearIti('verification');
                    $scope.forms.verification.is_valid_email = undefined;
                    $scope.data.verification_submited = false;
                    $scope.skipIntro();
                    break;
            };
            $scope.data.saving = false;
        };

        $scope.rightFooterButton = function() {
            switch($scope.state) {
                case 'welcome':
                    $scope.data.welcome_submited = true;
                    if ($scope.__isWelcomeValid()) {
                        $scope.saveUserNumbers();
                        $scope.updateUserCookiePhone();
                        $rootScope.purchaseDefaultNumber();
                        $scope.state = 'routing';
                    }
                    break;
                case 'routing':
                    $scope.createDefaultIvrs();
                    $scope.state = 'business';
                    break;
                case 'business':
                    $scope.saveTeamInfo();
                    $scope.createDispatcher();
                    $scope.state = 'consent';
                    break;
                case 'consent':
                    $scope.data.consent_submited = true;
                    if($scope.data.consent.material && $scope.data.consent.compliance) {
                        $scope.saveConsent();
                        $scope.state = 'tour';
                    }
                    break;
                case 'tour':
                    $scope.startIntro();
                    break;
                case 'verification':
                    $scope.data.verification_submited = true;
                    $scope.formatPhone('verification');
                    if($scope.__isVerificationValid()) {
                        $scope.sendVerifications();
                    }
                    break;
                case 'verification_sent':
                    $scope.skipIntro();
                    break;
                case 'verification_completed':
                    $scope.skipIntro();
                    break;
            };
        };

        $scope.setFooterButton = function(button, text, isDisplayed, isDisabled) {
            $scope.data[button].text = text;
            $scope.data[button].isDisplayed = isDisplayed;
            $scope.data[button].isDisabled = isDisabled;
        };

        $scope.setFooterButtonsModal = function() {
            switch($scope.state) {
                case 'welcome':
                    $scope.setFooterButton('left_footer_button', '', false, true);
                    $scope.setFooterButton('right_footer_button', 'Next', true, false);
                    break;
                case 'routing':
                    $scope.setFooterButton('left_footer_button', 'Back', true, false);
                    $scope.setFooterButton('right_footer_button', 'Next', true, false);
                    break;
                case 'business':
                    $scope.setFooterButton('left_footer_button', 'Back', true, false);
                    $scope.setFooterButton('right_footer_button', 'Next', true, false);
                    break;
                case 'consent':
                    $scope.setFooterButton('left_footer_button', 'Back', true, false);
                    $scope.setFooterButton('right_footer_button', 'Submit', true, false);
                    break;
                case 'tour':
                    $scope.setFooterButton('left_footer_button', 'Skip Tour', true, false);
                    $scope.setFooterButton('right_footer_button', 'Get Started', true, false);
                    break;
                case 'verification':
                    $scope.setFooterButton('left_footer_button', 'Cancel', true, false);
                    $scope.setFooterButton('right_footer_button', 'Submit', true, false);
                    break;
                case 'verification_sent':
                    $scope.setFooterButton('left_footer_button', '', false, true);
                    $scope.setFooterButton('right_footer_button', 'OK', true, false);
                    break;
                case 'verification_completed':
                    $scope.setFooterButton('left_footer_button', '', false, true);
                    $scope.setFooterButton('right_footer_button', 'OK', true, false);
                    break;
            };
        };

        $scope.onInit = function() {
            $scope.user = authService.getUser();

            if(false && $scope.user && $scope.user.needs_setup) {
                $scope.showModal();
            }

            $scope.$watch('state', function(newValue, oldValue) {
                $scope.setFooterButtonsModal();
            });
        };

        if (authService.hasAccount()) {
            $scope.onInit();
        }
        else {
            $rootScope.$on('auth_complete', function(event, args) {
                $scope.onInit();
            });
        }
    }
    module.exports = WelcomeModalController;
})();
