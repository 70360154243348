(function () {
    'use strict';
    
    function UserService(apiService, $rootScope) { 
        return {
            listUsers: function (request) {
                return apiService.get($rootScope.settings.API_USER_URL + '/user', request);
            },

            getUsersCount: function (request) {
                return apiService.get($rootScope.settings.API_USER_URL + '/user/count', request);
            },

            getUser: function (request) {
                return apiService.get($rootScope.settings.API_USER_URL + '/user/' + request.target_user_id, request);
            },

            exists: function (request) {
                return apiService.get($rootScope.settings.API_USER_URL + '/user/exists', request);
            },

            createUser: function (request) {
                return apiService.post($rootScope.settings.API_USER_URL + '/user', request);
            },

            updateUser: function (request) {
                return apiService.post($rootScope.settings.API_USER_URL + '/user/' + request.updated_user_id, request);
            },

            deleteUser: function (request) {
                const req = Object.assign({}, {
                    team_id: authService.getTeamId(),
                }, request);
                return apiService.del($rootScope.settings.API_USER_URL + '/user/' + request.user_id, req);
            },

            setUserActivity: function (request) {
                return apiService.post($rootScope.settings.API_USER_URL + '/user/page/view', request);
            },

            sendInvite: function (request) {
                return apiService.post($rootScope.settings.API_USER_URL + '/user/' + request.updated_user_id + '/send-invite', request);
            },

            getUsersStatistics: function (request) {
                return apiService.get($rootScope.settings.API_USER_URL + '/user/users/statistics', request);
            },

            getGlobalStatistics: function (request) {
                return apiService.get($rootScope.settings.API_USER_URL + '/user/global/statistics', request);
            },


            listUserGroups: function (request) {
                return apiService.get($rootScope.settings.API_USER_URL + '/user_group', request);
            },

            getUserGroup: function (request) {
                return apiService.get($rootScope.settings.API_USER_URL + '/user_group/' + request.user_group_id, request);
            },

            createUserGroup: function (request) {
                return apiService.post($rootScope.settings.API_USER_URL + '/user_group', request);
            },

            updateUserGroup: function (request) {
                return apiService.post($rootScope.settings.API_USER_URL + '/user_group/' + request.user_group_id, request);
            },

            deleteUserGroup: function (request) {
                return apiService.del($rootScope.settings.API_USER_URL + '/user_group/' + request.user_group_id, request);
            },
        };
    }
    module.exports = UserService;
})();




