(function() {
    'use strict';

    function StoreDragDropContactBucketService($rootScope) {
        let self = this;
        self.contact = null;
        self.sourceList = null;
        self.destinationList = null;
        self.action = null;

        function setContact(contact) {
            self.contact = contact;
        }
        function getContact() {
            return self.contact;
        }

        function setSourceList(sourceList) {
            self.sourceList = sourceList;
        }
        function getSourceList() {
            return self.sourceList;
        }

        function setDestinationList(destinationList) {
            self.destinationList = destinationList;
        }
        function getDestinationList() {
            return self.destinationList;
        }

        function setAction(action) {
            self.action = action;
        }
        function getAction() {
            return self.action;
        }

        function clearAll() {
            self.contact = null;
            self.sourceList = null;
            self.destinationList = null;
            self.action = null;
        }

        function updateContactListIds(contact) {
            $rootScope.$broadcast('update.contact.bucket', contact);
        }

        return {
            setContact: setContact,
            getContact: getContact,
            setSourceList: setSourceList,
            getSourceList: getSourceList,
            setDestinationList: setDestinationList,
            getDestinationList: getDestinationList,
            setAction: setAction,
            clearAll: clearAll,
            getAction: getAction,
            updateContactListIds: updateContactListIds
        };
    }
    module.exports = StoreDragDropContactBucketService;
})();

