(function() {
    'use strict';

    function PlanUpdateModalController(authService, accountService, userService, $rootScope, $scope) {
        var vm = this;
        vm.authService = authService;

        $scope.staticText = {
            header: "Add Users",
            body: "Please select the number of users you want to add...",
            confirm: "Update Licenses",
            confirming: "Updating Licenses",
            cancel: "Cancel",
            table: {
                header: ['License Type', 'Per User', 'Users to Add']
            }
        };

        $scope.data = {
            plans: [],
            pricing: PRODUCT_PRICING,
            packages: {},
            cart_id: '',
            price: {
                subscription: 0,
                taxes: 0,
                total: 0
            }
        };

        $scope.updating = false;
        $scope.inputTimer = null;
        $scope.inputInterval = 3500;

        $scope.updateBillingPlan = function() {

            $scope.updating = true;

            let req = {
                cart: $scope.getPackages(),
                user_id: authService.getUserId(),
                team_id: authService.getTeamId()
            };

            if($scope.data.cart_id) {
                req.cart_id = $scope.data.cart_id
            }

            accountService.updateBilling(req)
                .then(results => {
                    //$rootScope.$broadcast('subscription.changed', { event: results });
                    $scope.updating = false;
                    $scope.hideUpdateModal();
                })
                .catch(err => {
                    swal("Server Error", "We were unable to update the billing. Try again later.", "error");
                    $scope.updating = false;
                    console.error(err);
                    $scope.$apply();
                });
        };

        $scope.subscriptionChanged = function() {
            clearTimeout($scope.inputTimer);
            $scope.inputTimer = setTimeout($scope.calculateTaxes, $scope.inputInterval);
        };

        $scope.calculateTaxes = function() {

            let req = {
                cart: $scope.getPackages(),
                user_id: authService.getUserId(),
                team_id: authService.getTeamId()
            };

            if($scope.data.cart_id) {
                req.cart_id = $scope.data.cart_id
            }

            accountService.calculateTaxes(req)
                .then(results => {
                    $scope.data.price.taxes = results.taxes;
                    $scope.data.price.total = results.total;
                    $scope.data.price.subscription = results.subtotal;
                    $scope.data.cart_id = results.cart_id;
                    //$scope.calculatePrice();
                    $scope.$apply();
                })
                .catch(err => {
                    swal("Server Error", "We were unable to calculate taxes. Try again later.", "error");
                    console.error(err);
                    $scope.$apply();
                });
        };

        $scope.roundPrice = function(num){
            var pow = Math.pow(10, 2);
            return Math.round(num*pow) / pow;
        }

        $scope.calculatePrice = function() {

            $scope.data.price.subscription = $scope.roundPrice($scope.getPackages().reduce((total, addon) => {
                total += Math.floor(plan.quantity * $scope.data.packages[addon.id].price);
                return total;
            }, 0));

            $scope.data.price.total = $scope.roundPrice($scope.data.price.subscription + $scope.data.price.taxes);
        };

        $scope.formatMoney = function(value) {
            return ((value || 0) / 100).toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
            });
        }

        $scope.hideUpdateModal = function() {
            $scope.data.cart_id = '';
            $("#plan-update-modal").modal('hide');
        };

        $scope.$on('openCheckout', function(event, utilization) {
            $scope.data.utilization = utilization;
            $scope.data.plans.map(addon => {
                addon.quantity = ($scope.data.utilization[addon.id] || {}).purchased || 0;
                return addon;
            });
            $scope.calculateTaxes();
            $("#plan-update-modal").modal('show');
        });

        $scope.getPackages = function() {
            let filteredAddons = [];

            for (let chargebeePackage of $scope.data.plans) {
                if (chargebeePackage.quantity > 0) {
                    filteredAddons.push({id: chargebeePackage.id, quantity: chargebeePackage.quantity});
                }
            }

            return filteredAddons;
        };

        $scope.onInit = function() {
            const team = authService.getUser().team;
            $scope.data.packages = (PRODUCT_PRICING[team.chargebee.plan_id] || {}).packages || [];
            $scope.data.plans = Object.values($scope.data.packages);
        };

        if (authService.hasAccount()) {
            $scope.onInit();
        }
        else {
            $scope.$on('auth_complete', function(event, args) {
                $scope.onInit();
            });
        }
    }
    module.exports = PlanUpdateModalController;
})();
