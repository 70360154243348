(function () {
    'use strict';

    function IVRconfigController($rootScope, $state, $scope, $location, authService, phoneService, mediaService, userService, notificationToastService, generalHelperService) {
        let vm = this;
        vm.authService = authService;

        $rootScope.viewData = $rootScope.viewData || {};
        $rootScope.viewData.breadcrumbs = $rootScope.viewData.breadcrumbs || [];
        $rootScope.viewData.breadcrumbs = [{
            title: 'Call Routing',
            link_name: 'Call Routing',
            link_url: '#/call-routing',
            help_url: $rootScope.help_url
        }, {
            title: 'Call Routing Configuration',
            link_name: 'Call Routing Configuration',
            link_url: '#/ivr-config',
            page_data: {
                ivr_id: $location.search().id,
            },
        }];

        $scope.data = {
            voice_ivr: {
                ivr_id: null
            },
            recordings: {},
            recordingsCorrectedNames: {},
            user_groups: [],
            users: [],
            submittedNormalHours: false,
            submittedAfterHours: false
        };

        $scope.loading = {
            call_rules: true,
            recordings: false,
            user_groups: false,
            users: false,
        };

        $scope.saving = {
            call_rules: false
        };

        $scope.staticData = {
            title: 'Inbound Call Rules',
            subtitle: 'Define a set of rules to handle inbound calls to this phone number',
            tabs: ['Availability', 'Normal Hours', 'After Hours'],
            availability: {
                subtitle: "Set when you're available to handle inbound calls...",
                days: [
                    { fullName: 'Sunday', shortName: 'Sun', letter: 'S', id: 'sun' },
                    { fullName: 'Monday', shortName: 'Mon', letter: 'M', id: 'mon' },
                    { fullName: 'Tuesday', shortName: 'Tue', letter: 'T', id: 'tue' },
                    { fullName: 'Wednesday', shortName: 'Wed', letter: 'W', id: 'wed' },
                    { fullName: 'Thursday', shortName: 'Thu', letter: 'T', id: 'thu' },
                    { fullName: 'Friday', shortName: 'Fri', letter: 'F', id: 'fri' },
                    { fullName: 'Saturday', shortName: 'Sat', letter: 'S', id: 'sat' }
                ],
                timezone: {
                    title: 'Timezone',
                    subtitle: 'The timezone that applies to your availability'
                },
                submitBtn: 'Save'
            },
            normalHours: {
                subtitle: 'Rules for handling inbound calls during normal operating hours...',
            },
            afterHours: {
                subtitle: 'Rules for handling inbound calls during after operating hours...',
            },
            rulesBlock: {
                addRule: {
                    title: 'Add Rule'
                },
                table: {
                    titleTd: {
                        whenText: 'When',
                        startRule: 'Inbound Call Received',
                        endRule: 'No Keys Pressed',
                        keyPress: 'Key Press'
                    },
                    optionDropdownTd: {
                        thenText: 'Then',
                        options: ['DNC', 'Forward To', 'Forward + Voicemail', 'Hang-up', 'Play', 'Say', 'Voicemail', 'Queue', 'Voice API']
                    },
                    optionTd: {
                        call_control: {
                            placeholder: 'https://example.com'
                        },
                        greeting: {
                            placeholder: 'Please wait for the next available agent'
                        },
                        voicemail: {
                            placeholder: 'Please leave your message after the tone'
                        },
                        hangup: {
                            placeholder: "We're sorry, an agent is not available to take your call.  Please try again later."
                        },
                        emailTo: {
                            placeholder: 'email@example.com'
                        },
                        smsTo: {
                            placeholder: '(000) 000-0000, (000) 000-0000'
                        },
                        forwardTo: {
                            placeholder: '(000) 000-0000'
                        },
                        say: {
                            placeholder: 'What do you want to say?'
                        }
                    },
                    actionsTd: ['Add Rule Before', 'Add Rule After', 'Delete Rule'],
                    recordings: {
                        createBtn: {
                            text: 'Create a Recording',
                            href: '#/recordings'
                        },
                        dropDownBtn: 'Please choose recording...',
                    },
                    users: {
                        createBtn: {
                            text: 'Add User',
                            href: '#/team'
                        },
                        dropDownBtn: 'Please choose user...',
                    },
                    user_groups: {
                        createBtn: {
                            text: 'Add Team',
                            href: '#/teams'
                        },
                        dropDownBtn: 'Please choose user group...',
                    },
                },
                actions: {
                    saveBtn: 'Save',
                    createBtn: 'Create'
                },
                loader: 'Loading',
                saving: 'Saving',
                creating: 'Creating',
                errorMessages: {
                    duplicatesErrorMessage: {
                        rules : 'Your IVR has duplicate Key Press actions for normal hours.',
                        after_hour_rules: 'Your IVR has duplicate Key Press actions for after hours.'
                    }
                },
            },
            errors: {
                phoneNumberErrors: {
                    'IS_POSSIBLE': 'The number length matches that of valid numbers for this region but the number is formatted incorrectly.',
                    'INVALID_COUNTRY_CODE': 'Invalid country calling code.',
                    'TOO_SHORT': 'The phone number is too short.',
                    'TOO_LONG': 'The phone number is too long.',
                    'NOT_A_NUMBER': 'Phone number is not valid'
                },
                required: 'This field is required.',
                http_required: 'Valid http:// or https:// url required'
            }
        };

        $scope.validateURL = function(url) {
            try {
                new URL(url);
            } catch (err) {
                return false;
            }

            return true;
        }

        $scope.validateEmail = function(email) {
            const regEmail = $rootScope.settings.USER_EMAIL_REGEX;
            if (!email || !regEmail.test(email)) {
                return false;
            }
            return true;
        };

        $scope.updateVoiceIvr = function(ivrReq) {

            phoneService.updateIvr(ivrReq)
                .then(results => {
                    notificationToastService.showSuccessToast('fa-light fa-clipboard-check', 'Updated!');
                })
                .catch(err => {
                    console.log(err);
                    swal('Error', 'Failed to save call routing.', 'error');
                })
                .then(() => {
                    $scope.saving.call_rules = false;
                    $scope.data.submittedNormalHours = false;
                    $scope.data.submittedAfterHours = false;
                    $scope.$apply();
                });
        };

        $scope.createVoiceIvr = function(ivrReq) {

            phoneService.createIvr(ivrReq)
                .then(results => {
                    notificationToastService.showSuccessToast('fa-light fa-clipboard-check', 'Created!');
                })
                .catch(err => {
                    console.log(err);
                    swal('Error', 'Failed to save call routing.', 'error');
                })
                .then(() => {
                    $scope.saving.call_rules = false;
                    $scope.data.submittedNormalHours = false;
                    $scope.data.submittedAfterHours = false;
                    $scope.$apply();
                });
        };

        $scope.isQueueRuleValid = function(rule) {
            const email = !!(rule.action_data.email_to);

            let skip_line_result = rule.action_data.skip_line_enabled;
            if (!rule.action_data.skip_line_enabled) {
                const skip_line = !!rule.action_data.skip_line_media_id || !!rule.action_data.skip_line_say;
                const skip_line_ms = !!rule.action_data.skip_line_offset_ms;
                skip_line_result = skip_line && skip_line_ms;
            }

            let switch_to_sms_result = rule.action_data.switch_to_sms_enabled;
            if (!rule.action_data.switch_to_sms_enabled) {
                const switch_to_sms = !!rule.action_data.switch_to_sms_media_id || !!rule.action_data.switch_to_sms_media_say;
                const switch_to_sms_ms = !!rule.action_data.switch_to_sms_offset_ms;
                switch_to_sms_result = switch_to_sms && switch_to_sms_ms;
            }

            const moh_silence_ms = rule.action_data.moh_silence_ms;

            let greeting_result = !!rule.action_data.greeting_media_id || !!rule.action_data.greeting_say;

            let vm_result = !rule.action_data.vm_enabled;
            let max_wait = !!rule.action_data.vm_enabled;

            if (rule.action_data.vm_enabled) {
                vm_result = !!rule.action_data.play_media_id || !!rule.action_data.say;
            }
            else {
                max_wait = !!rule.action_data.max_wait_media_id || !!rule.action_data.max_wait_say;
            }

            const max_wait_ms = !!rule.action_data.max_wait_ms;
            const max_wait_result = max_wait && max_wait_ms;

            let hunt_user_result = rule.action_data.hunt_enabled;
            if (!rule.action_data.hunt_enabled) {
                hunt_user_result = !!rule.action_data.hunt_user_id || !!rule.action_data.hunt_user_group;
            }

            const moh_media_result = !!(rule.action_data.moh_media_ids && rule.action_data.moh_media_ids.length);

            //return (email && skip_line_result && switch_to_sms_result && moh_silence_ms && greeting_result && max_wait_result && hunt_user_result && play_media_result && moh_media_result);
            return (greeting_result && max_wait_result && vm_result);
        };

        $scope.isCallControlRuleValid = function(rule) {
            rule.action_data.call_control_valid = $scope.validateURL(rule.action_data.webhook_url);
            return rule.action_data.call_control_valid;
        };

        $scope.isVoicemailRuleValid = function(rule) {
            let isPlayMediaId = (rule.action_data.play_media_id) ? true : false;
            let isSay = (rule.action_data.say.length > 0) ? true : false;
            return (isPlayMediaId || isSay);
        };

        $scope.isSayRuleValid = function(rule) {
            return (rule.action_data.say.length > 0) ? true : false;
        };

        $scope.isPlayRuleValid = function(rule) {
            return (rule.action_data.play_media_id) ? true : false;
        };

        $scope.isForwardVoicemailRuleValid = function(rule) {
            $scope.isValidPhoneNumber(rule);
            let isForwardWaitTime = (rule.action_data.forward_wait_time >= 6 &&  rule.action_data.forward_wait_time <= 30) ? true : false;
            let isPlayMediaId = (rule.action_data.play_media_id) ? true : false;
            let isSay = (rule.action_data.say && rule.action_data.say.length > 0) ? true : false;
            return (rule.action_data.route_to_valid && isForwardWaitTime && isPlayMediaId && isSay);
        };

        $scope.isForwardToRuleValid = function(rule) {
            $scope.isValidPhoneNumber(rule);
            return rule.action_data.route_to_valid;
        };

        $scope.isKeyInputValid = function(rule) {
            if(!rule.start_rule && !rule.end_rule) {
                return (rule.action_data.key_input >= 0 ) ? true : false;
            }
            return true;
        };

        $scope.isActionValid = function(rule) {
            switch(rule.action) {
                case 'Forward To': return $scope.isForwardToRuleValid(rule);
                case 'Forward + Voicemail': return $scope.isForwardVoicemailRuleValid(rule);
                case 'Play': return $scope.isPlayRuleValid(rule);
                case 'Say': return $scope.isSayRuleValid(rule);
                case 'Voicemail': return $scope.isVoicemailRuleValid(rule);
                case 'Queue': return $scope.isQueueRuleValid(rule);
                case 'Voice API': return $scope.isCallControlRuleValid(rule);
                default: return true;
            }
        };

        $scope.isRuleValid = function(rule) {
            let keyInputValid = $scope.isKeyInputValid(rule);
            let ruleValid = $scope.isActionValid(rule);
            return !!(keyInputValid && ruleValid);
        };

        $scope.rulesValidation = function(objName) {
            let isRulesValid;
            _.forEach($scope.data.voice_ivr[objName], function(rule) {
                isRulesValid = $scope.isRuleValid(rule);
                console.log(rule.action + ' ' + isRulesValid);
                if(isRulesValid === false) { return false; }
            });

            if (!$scope.validateKeyInputDuplicates(objName)) {
                swal('Error', $scope.staticData.rulesBlock.errorMessages.duplicatesErrorMessage[objName], 'error');
                return false;
            }
            return isRulesValid;
        };

        $scope.getHoursMinutes = function(time) {
            let hours = time.getHours();
            let minutes = time.getMinutes();
            return hours.toString() + '.' + minutes.toString();
        };

        $scope.getDayObject = function(day) {
            return {
                start: $scope.getHoursMinutes($scope.data.voice_ivr.availability.daysUI[day].start),
                end: $scope.getHoursMinutes($scope.data.voice_ivr.availability.daysUI[day].end),
                open: $scope.data.voice_ivr.availability.daysUI[day].open
            };
        };

        $scope.getAvailabilityReqObject = function() {
            return {
                days: {
                    '0': $scope.getDayObject(0), // Sunday
                    '1': $scope.getDayObject(1), // Monday,
                    '2': $scope.getDayObject(2), // Tuesday,
                    '3': $scope.getDayObject(3), // Wednesday
                    '4': $scope.getDayObject(4), // Thursday
                    '5': $scope.getDayObject(5), // Friday
                    '6': $scope.getDayObject(6), // Saturday
                },
                'tz': $scope.data.voice_ivr.availability.tz
            };
        };

        $scope.forwardWaitTime = function(rule) {
            let max = 30;
            let min = 6;
            let currentValue = rule.action_data.forward_wait_time;

            if(!Number.isInteger(currentValue) || Number.isNaN(currentValue)) {
                rule.action_data.forward_wait_time = min;
            } else {
                if(currentValue > max) {
                    rule.action_data.forward_wait_time = max;
                } else if(currentValue < min) {
                    rule.action_data.forward_wait_time = min;
                }
            }
        };

        $scope.setRecordCalls = function(rule) {
            rule.action_data.record_calls = !rule.action_data.record_calls;
        };

        $scope.initForwardToAction = function(rule) {
            rule.action_data.raw_route_to = '';
            rule.action_data.record_calls = false;
        };

        $scope.initForwardVoicemailAction = function(rule) {
            rule.action_data.raw_route_to = '';
            rule.action_data.record_calls = false;
            rule.action_data.forward_wait_time = 12;
            rule.action_data.play_media_id = undefined;
            rule.action_data.say = 'Please record your message after the beep. When you are done recording, you may hang up.';
            rule.action_data.email_to = '';
        };

        $scope.initSayAction = function(rule) {
            rule.action_data.say = '';
        };

        $scope.initPlayAction = function(rule) {
            rule.action_data.play_media_id = undefined;
        };

        $scope.initVoicemailAction = function(rule) {
            rule.action_data.play_media_id = undefined;
            rule.action_data.say = 'Please record your message after the beep. When you are done recording, you may hang up.';
            rule.action_data.email_to = '';
        };

        $scope.initCallControlAction = function(rule) {
            rule.action_data.webhook_url = '';
        };

        $scope.initQueueAction = function(rule) {
            const defaultData = {
                "repeat_count" : 1,
                "key_input" : null,
                "email_to" : "",
                "skip_line_media_id" : null,
                "skip_line_say" : "",
                "skip_line_enabled" : false,
                "skip_line_offset_ms" : 60 * 1000,
                "switch_to_sms_enabled" : false,
                "switch_to_sms_media_id" : null,
                "switch_to_sms_media_say" : "",
                "switch_to_sms_offset_ms" : 45 * 1000,
                "moh_silence_ms" : 1000,
                "greeting_say" : "",
                "greeting_media_id" : null,
                "vm_enabled": false,
                "max_wait_ms" : 115 * 1000,
                "max_wait_media_id" : null,
                "max_wait_say" : "",
                "hunt_enabled": false,
                "hunt_user_id" : null,
                "hunt_user_group" : null,
                "play_media_id" : null,
                "say" : "",
                "moh_media_ids" : [],
            };
            rule.action_data = Object.assign(defaultData, rule.action_data);
            rule.action_data.max_wait_sec = (rule.action_data.max_wait_ms || 115000) / 1000;
        };

        $scope.initPhoneNumberAction = function(tab, ruleAction, rule) {
            let phoneNumberElemId = '#' + tab + '-' + ruleAction + '-raw-route-to-' + rule.ruleId;

            $scope.clearIti(rule, phoneNumberElemId);
            $scope.phoneNumberInit(rule, phoneNumberElemId);
        };

        $scope.setAction = function(rule, value, tab) {
            rule.action = value;
            rule.action_data = {};
            rule.action_data.repeat_count = 1;
            rule.action_data.key_input = 0;

            switch(value) {
                case 'Forward To':
                    $scope.initForwardToAction(rule);
                    $scope.initPhoneNumberAction(tab, 'forward-to', rule);
                    break;
                case 'Forward + Voicemail':
                    $scope.initForwardVoicemailAction(rule);
                    $scope.initPhoneNumberAction(tab, 'forward-voicemail', rule);
                    break;
                case 'Say':
                    $scope.initSayAction(rule);
                    break;
                case 'Play':
                    $scope.initPlayAction(rule);
                    break;
                case 'Voicemail':
                    $scope.initVoicemailAction(rule);
                    break;
                case 'Queue':
                    $scope.initQueueAction(rule);
                    break;
                case 'Voice API':
                    $scope.initCallControlAction(rule);
                    break;
            }
        };

        $scope.setRecording = function(rule, value) {
            if (value) {
                rule.action_data = rule.action_data || {};
                rule.action_data.play_media_id = value.media_id;
            } else {
                rule.action_data.play_media_id = null;
            }
        };

        $scope.setSkipLineMediaId = function(rule, value) {
            rule.action_data = rule.action_data || {};
            rule.action_data.skip_line_media_id = value ? value.media_id : null;
        };

        $scope.setSkipLineEnabled = function(rule) {
            rule.action_data.skip_line_enabled = !rule.action_data.skip_line_enabled;
        }

        $scope.setSkipLineOffset = function(rule) {
            const max = 15 * 60 * 1000;
            const min = 1000;
            const currentValue = rule.action_data.skip_line_offset_ms;

            if (!Number.isInteger(currentValue) || Number.isNaN(currentValue)) {
                rule.action_data.skip_line_offset_ms = min;
            } else {
                if (currentValue > max) {
                    rule.action_data.skip_line_offset_ms = max;
                } else if (currentValue < min) {
                    rule.action_data.skip_line_offset_ms = min;
                }
            }
        }

        $scope.setSwitchToSmsEnabled = function(rule) {
            rule.action_data.switch_to_sms_enabled = !rule.action_data.switch_to_sms_enabled;
        }

        $scope.setSwitchToSmsMediaId = function (rule, value) {
            rule.action_data = rule.action_data || {};
            rule.action_data.switch_to_sms_media_id = value ? value.media_id : null;
        }
        
        $scope.setSwitchToSmsOffsetMs = function (rule) {
            const max = 15 * 60 * 1000;
            const min = 1000;
            const currentValue = rule.action_data.switch_to_sms_offset_ms;

            if (!Number.isInteger(currentValue) || Number.isNaN(currentValue)) {
                rule.action_data.switch_to_sms_offset_ms = min;
            } else {
                if (currentValue > max) {
                    rule.action_data.switch_to_sms_offset_ms = max;
                } else if (currentValue < min) {
                    rule.action_data.switch_to_sms_offset_ms = min;
                }
            }
        }

        $scope.setMohSilenceMs = function (rule) {
            const max = 15 * 1000;
            const min = 1000;
            const currentValue = rule.action_data.moh_silence_ms;

            if (!Number.isInteger(currentValue) || Number.isNaN(currentValue)) {
                rule.action_data.moh_silence_ms = min;
            } else {
                if (currentValue > max) {
                    rule.action_data.moh_silence_ms = max;
                } else if (currentValue < min) {
                    rule.action_data.moh_silence_ms = min;
                }
            }
        }

        $scope.setGreetingMediaId = function (rule, value) {
            rule.action_data = rule.action_data || {};
            rule.action_data.greeting_media_id = value ? value.media_id : null;
        }

        $scope.setVmEnabled = function (rule) {
            rule.action_data.vm_enabled = !rule.action_data.vm_enabled;
        }
        
        $scope.setMaxWaitOffset = function (rule) {
            if (rule.action_data.max_wait_sec) {
                rule.action_data.max_wait_sec = Math.abs(rule.action_data.max_wait_sec);
                rule.action_data.max_wait_ms = rule.action_data.max_wait_sec * 1000;
            }
            else {
                rule.action_data.max_wait_ms = 115 * 1000;
            }
        }

        $scope.setMaxWaitMediaId = function (rule, value) {
            rule.action_data = rule.action_data || {};
            rule.action_data.max_wait_media_id = value ? value.media_id : null;
        }

        $scope.setHuntEnabled = function (rule) {
            rule.action_data.hunt_enabled = !rule.action_data.hunt_enabled;
        }

        $scope.getRecordingName = function(media_id) {
            let index = _.findIndex($scope.data.recordings, ['media_id', media_id]);
            if(index > -1)
                return $scope.data.recordings[index].name;
            return $scope.staticData.rulesBlock.table.recordings.dropDownBtn;
        };

        $scope.substrRecordingName = function(mediaName) {
            if (!mediaName || mediaName.length === 0)
                return 'Recording';
            else if (mediaName.length > 40)
                return mediaName.substring(0, 40) + '...';
            else
                return mediaName;
        };

        $scope.addCallRule = function(e, index) {
            e.preventDefault();

            let maxId = _.maxBy($scope.data.voice_ivr.rules, function(r) { return r.ruleId; });
            let newId = (!maxId) ? 0 : (maxId.ruleId + 1);

            $scope.data.voice_ivr.rules.splice(index, 0, {
                start_rule: false,
                is_deletable: true,
                end_rule: false,
                action: 'Hang-up',
                action_data: {
                    repeat_count: 1,
                    key_input: 0
                },
                is_valid: true,
                ruleId: newId
            });
        };

        $scope.deleteCallRule = function(e, index) {
            if (!$scope.checkIsRuleDeletable($scope.data.voice_ivr.rules[index])) {
                return;
            }
            e.preventDefault();
            $scope.data.voice_ivr.rules.splice(index, 1);
        };

        $scope.addCallRuleAfterHours = function(e, index) {
            e.preventDefault();
            if (typeof $scope.data.voice_ivr.after_hour_rules === 'undefined') {
                $scope.data.voice_ivr.after_hour_rules = [];
            }

            let maxId = _.maxBy($scope.data.voice_ivr.after_hour_rules, function(r) { return r.ruleId; });
            let newId = (!maxId) ? 0 : (maxId.ruleId + 1);

            $scope.data.voice_ivr.after_hour_rules.splice(index, 0, {
                start_rule: false,
                end_rule: false,
                action: 'Hang-up',
                action_data: {
                    repeat_count: 1,
                    key_input: 0
                },
                is_valid: true,
                ruleId: newId
            });
        };

        $scope.deleteCallRuleAfterHours = function(e, index) {
            if (!$scope.checkIsRuleDeletable($scope.data.voice_ivr.after_hour_rules[index])) {
                return;
            }
            e.preventDefault();
            $scope.data.voice_ivr.after_hour_rules.splice(index, 1);
        };

        $scope.validateKeyInputDuplicates = function(objName) {

            const keyInputs =
                $scope.data.voice_ivr[objName]
                    .filter(item => item.action_data.key_input !== 0)
                    .map(item => item.action_data.key_input);

            // find duplicates
            const duplicates =
                keyInputs
                    .filter( function(item) {
                        return keyInputs.indexOf(item) !== keyInputs.lastIndexOf(item);
                    });

            // set is_valid to show error message
            $scope.data.voice_ivr[objName]
                .forEach((item, key) => {
                    $scope.data.voice_ivr[objName][key].is_valid = true;
                    if (duplicates && duplicates.length) {
                        $scope.data.voice_ivr[objName][key].is_valid = !(duplicates.indexOf(item.action_data.key_input) !== -1);
                    }
                });
            return !duplicates.length;
        }

        $scope.clearIti = function(rule, elemId) {
            if(rule.iti) {
                rule.action_data.route_to_valid = undefined;
                rule.action_data.route_to_error = undefined;
                rule.iti.setNumber('');
                $(elemId).unmask();
                rule.iti.destroy();
            }
        };

        $scope.setPhoneMask = function(elem, mask, placeholder) {
            elem.mask(mask, {
                placeholder: placeholder
            });
        };

        $scope.phoneNumberInit = function(rule, id) {
            let phoneNumberId = $(id);
            generalHelperService.waitForElement(id)
                .then((phoneNumberIdInput) => {
                    if(phoneNumberId) {
                        rule.iti = window.intlTelInput(phoneNumberIdInput, {
                            nationalMode: 'false',
                            autoPlaceholder: 'aggressive',
                            separateDialCode: 'true',
                            validationNumberTypes: [
                                'FIXED_LINE',
                                'MOBILE',
                                'FIXED_LINE_OR_MOBILE',
                                'TOLL_FREE',
                                'PREMIUM_RATE',
                                'SHARED_COST',
                                'VOIP',
                                'PERSONAL_NUMBER',
                                'PAGER',
                                'UAN',
                                'VOICEMAIL',
                                'UNKNOWN'
                            ],
                            initialCountry: 'us',
                            // intl-tel-input is up to date as of dec 2024
                            loadUtils: () => import("https://cdn.jsdelivr.net/npm/intl-tel-input@25.2.0/build/js/utils.js"),
                            customPlaceholder: function(selectedCountryPlaceholder, selectedCountryData) {
                                let pl = selectedCountryPlaceholder.replace(/[0-9]/g, '0');
                                phoneNumberId.attr('maxlength', pl.length);
                                $scope.setPhoneMask(phoneNumberId, pl, pl);
                                return pl;
                            }
                        });

                        phoneNumberId.on('countrychange', function(e) {
                            rule.iti.setNumber('');
                            phoneNumberId.unmask();

                            let mask = e.target.placeholder.replace(/[0-9]/g, '0');
                            phoneNumberId.attr('maxlength', mask.length);
                            $scope.setPhoneMask(phoneNumberId, mask, e.target.placeholder);
                        });
                    }
            });
        };

        $scope.isValidPhoneNumber = function(rule) {
            rule.action_data.route_to_valid = rule.iti.isValidNumber();

            let validationError = rule.iti.getValidationError();
            let errorKey = _.findKey(window.intlTelInput.utils.validationError, function(error) { return error === validationError; });
            rule.action_data.route_to_error = (!rule.action_data.route_to_valid) ? errorKey : undefined;
        };

        $scope.formatRawPhone = function(rule) {
            if(rule.iti) {
                $scope.isValidPhoneNumber(rule);
                rule.action_data.route_to = (rule.action_data.route_to_valid) ? rule.iti.getNumber(window.intlTelInput.utils.numberFormat.E164) : '';
            }
        };

        $scope.setDayAvailability = function(dayIndex) {
            $scope.data.voice_ivr.availability.daysUI[dayIndex].open = !$scope.data.voice_ivr.availability.daysUI[dayIndex].open;
        };

        $scope.showPopoverEventListener = function(elem) {
            $(elem).addClass('show');
        };
        $scope.hidePopoverEventListener = function(elem, dayIndex, type) {
            $(elem).removeClass('show');

            let time = $scope.data.voice_ivr.availability.daysUI[dayIndex][type];

            if(time === null) {
                let availabilityDays = (!$scope.data.voice_ivr.availability.days) ? $scope.data.voice_ivr.availability : $scope.data.voice_ivr.availability.days;
                let prevTime = availabilityDays[dayIndex][type];
                $scope.data.voice_ivr.availability.daysUI[dayIndex][type] = $scope.setTimeDateFormat(prevTime);
            }
        };

        $scope.closePopover = function(e) {
            e.preventDefault();
            $('.hours-btn').popover('hide');
        };

        $scope.removeEventListeners = function() {
            $(document).off('click', $scope.closePopoverByClickOutside);
            $('.nav-tabs .nav-item a').off('click', $scope.closePopover);
            $(document).off('click', '.hours-btn', $scope.hourBtnClick);
        };

        $scope.$on('$stateChangeStart', function() {
            $('.hours-btn').popover('hide');

            $scope.removeEventListeners();
        });

        $('.nav-tabs .nav-item a').on('click', $scope.closePopover);

        $scope.closePopoverByClickOutside = function(e) {
            let target = e.target;
            if($('.time-popover').has(target).length === 0 && !$('.hours-btn').is(target)) {
                $scope.closePopover(e);
            }
        };

        $scope.hourBtnClick = function(e) {
            e.preventDefault();
            $('.hours-btn').not(this).popover('hide');
        };

        $scope.popoverEventListeners = function(dayName, dayIndex) {
            $('#' + dayName + '-start-time').on('hide.bs.popover', function() {
                $scope.hidePopoverEventListener('#' + dayName + '-start-time', dayIndex, 'start');
            });
            $('#' + dayName + '-start-time').on('show.bs.popover', function() {
                $scope.showPopoverEventListener('#' + dayName + '-start-time');
            });
            $('#' + dayName + '-end-time').on('hide.bs.popover', function() {
                $scope.hidePopoverEventListener('#' + dayName + '-end-time', dayIndex, 'end');
            });
            $('#' + dayName + '-end-time').on('show.bs.popover', function() {
                $scope.showPopoverEventListener('#' + dayName + '-end-time');
            });

            $(document).on('click', '.hours-btn', $scope.hourBtnClick);
            $(document).on('click', $scope.closePopoverByClickOutside);
        };

        $scope.initPopover = function(popoverId, contentId, popoverDivId) {
            var templateStr = '<div class="popover time-popover" id="' + popoverDivId + '" role="tooltip"><div class="popover-body"></div></div>';
            $('#' + popoverId).popover({
                container: 'body',
                placement: 'bottom',
                html: true,
                content: $('#' + contentId),
                template: templateStr,
                trigger: 'click'
            });
        };

        $scope.initTimePopover = function(dayName) {
            $scope.initPopover(dayName + '-start-time', dayName + '-start-time-popover-content', dayName + '-start-time-popover');
            $scope.initPopover(dayName + '-end-time', dayName + '-end-time-popover-content', dayName +'-end-time-popover');
        };

        $scope.$on('ngRepeatFinished', function() {

            _.forEach($scope.staticData.availability.days, function(day, index) {
                $scope.initTimePopover(day.id);
                $scope.popoverEventListeners(day.id, index);
            });
        });

        $scope.getMinMaxTime = function(time, type) {
            let newTime = _.cloneDeep(time);
            return (type === 'min') ? newTime.setMinutes(newTime.getMinutes() + 1) : newTime.setMinutes(newTime.getMinutes() - 1);
        };

        $scope.changedStartTime = function(day) {
            if(day.start !== null) {
                day.min = $scope.getMinMaxTime(day.start, 'min');
            }
        };

        $scope.changedEndTime = function(day) {
            if(day.end !== null) {
                day.max = $scope.getMinMaxTime(day.end, 'max');
            }
        };

        $scope.setTimeDateFormat = function(timeStr) {
            let time = timeStr.toString().split('.');
            let d = new Date();
            d.setHours(parseInt(time[0]));
            d.setMinutes(parseInt(time[1]) || 0);
            return d;
        };

        $scope.initDayTime = function(startTime, endTime, open) {
            let start = $scope.setTimeDateFormat(startTime);
            let end = $scope.setTimeDateFormat(endTime);
            let day = {
                start: start,
                end: end,
                open: open,
                min: $scope.getMinMaxTime(start, 'min'),
                max: $scope.getMinMaxTime(end, 'max')
            };
            $scope.data.voice_ivr.availability.daysUI.push(day);
        };

        $scope.initRulesNormalHours = function() {
            $scope.data.voice_ivr.rules = [
                { start_rule: true, is_deletable: false, end_rule: false, notify_after: {}, ruleId: 0 },
                { start_rule: false, is_deletable: false, end_rule: true, ruleId: 1 }
            ];
            $scope.setAction($scope.data.voice_ivr.rules[0], 'Forward + Voicemail', 'normal-hours');
            $scope.setAction($scope.data.voice_ivr.rules[1], 'Hang-up', 'normal-hours');
        };

        $scope.initRulesAfterHours = function() {
            $scope.data.voice_ivr.after_hour_rules = [
                { start_rule: true, is_deletable: false, end_rule: false, notify_after: {}, ruleId: 0 },
                { start_rule: false, is_deletable: false, end_rule: true, ruleId: 1 }
            ];
            $scope.setAction($scope.data.voice_ivr.after_hour_rules[0], 'Voicemail', 'after-hours');
            $scope.setAction($scope.data.voice_ivr.after_hour_rules[1], 'Hang-up', 'after-hours');
        };

        $scope.initAvailability = function() {
            $scope.data.voice_ivr.availability.daysUI = [];

            $scope.initDayTime('9.0', '17.0', false);
            $scope.initDayTime('9.0', '17.0', true);
            $scope.initDayTime('9.0', '17.0', true);
            $scope.initDayTime('9.0', '17.0', true);
            $scope.initDayTime('9.0', '17.0', true);
            $scope.initDayTime('9.0', '17.0', true);
            $scope.initDayTime('9.0', '17.0', false);

            let availabilityObj = $scope.getAvailabilityReqObject();
            availabilityObj.daysUI = $scope.data.voice_ivr.availability.daysUI;
            $scope.data.voice_ivr.availability = availabilityObj;
        };

        $scope.setAvailabilityDays = function(availability) {
            let initAvailabilityObj = (!availability.days) ? availability : availability.days; //availability with old or new (with days object) structure
            $scope.data.voice_ivr.availability.daysUI = [];

            $scope.initDayTime(initAvailabilityObj[0].start, initAvailabilityObj[0].end, initAvailabilityObj[0].open);
            $scope.initDayTime(initAvailabilityObj[1].start, initAvailabilityObj[1].end, initAvailabilityObj[1].open);
            $scope.initDayTime(initAvailabilityObj[2].start, initAvailabilityObj[2].end, initAvailabilityObj[2].open);
            $scope.initDayTime(initAvailabilityObj[3].start, initAvailabilityObj[3].end, initAvailabilityObj[3].open);
            $scope.initDayTime(initAvailabilityObj[4].start, initAvailabilityObj[4].end, initAvailabilityObj[4].open);
            $scope.initDayTime(initAvailabilityObj[5].start, initAvailabilityObj[5].end, initAvailabilityObj[5].open);
            $scope.initDayTime(initAvailabilityObj[6].start, initAvailabilityObj[6].end, initAvailabilityObj[6].open);
        };

        $scope.setRules = function(rules, tab) {

            _.forEach(rules, function(rule, index) {
                rule.ruleId = index;

                switch(rule.action) {
                    case 'Forward To':
                        $scope.initPhoneNumberAction(tab, 'forward-to', rule);
                        break;
                    case 'Forward + Voicemail':
                        $scope.initPhoneNumberAction(tab, 'forward-voicemail', rule);
                        break;
                    case 'Say': break;
                    case 'Play': break;
                    case 'Voicemail': break;
                    case 'Queue':
                        $scope.initQueueAction(rule);
                        break;
                    case 'Voice API': break;
                };
            });
        };

        $scope.loadVoiceIvr = function(ivr_id) {
            let req = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                ivr_id: ivr_id
            };

            phoneService.getIvr(req)
                .then(results => {
                    if (results) {
                        $scope.data.voice_ivr = _.cloneDeep(results);
                        $scope.validateKeyInputDuplicates('rules');

                        if (results.availability) {
                            $scope.setAvailabilityDays(results.availability);
                        } else {
                            $scope.data.voice_ivr.availability = {
                                days: [],
                                daysUI: [],
                                tz: ''
                            };
                            $scope.initAvailability();
                        }

                        if(!$scope.data.voice_ivr.rules) {
                            $scope.initRulesNormalHours();
                        } else { $scope.setRules($scope.data.voice_ivr.rules, 'normal-hours'); }

                        if(!$scope.data.voice_ivr.after_hour_rules) {
                            $scope.initRulesAfterHours();
                        } else { $scope.setRules($scope.data.voice_ivr.after_hour_rules, 'after-hours'); }
                    } else {
                        $state.go('main-wrapper.call-routing');
                    }
                })
                .catch(err => { console.log(err); }).then(() => {
                    $scope.loading.call_rules = false;
                    $scope.$apply();
                });
        };

        $scope.loadRecordings = function() {
            $scope.loading.recordings = true;

            const request = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                sort: 'desc',
                // include_urls: true,
                // media_exists: true,
            };

            return mediaService.listMediaUrls(request)
                .then(results => {
                    const lists = (_.sortBy(results.medias, 'name').reverse()) || [];
                    $scope.data.recordings = lists;
                    $scope.data.recordingsCorrectedNames = lists.map(item => {
                        return {
                            name: $scope.substrRecordingName(item.name),
                            media_id: item.media_id
                        };
                    });
                })
                .catch(err => { console.error(err); })
                .then(() => {
                    $scope.loading.recordings = false;
                    $scope.$apply();
                });
        };

        $scope.loadUsers = function() {
            $scope.loading.users = true;

            const teamId = authService.getTeamId();
            const userId = authService.getUserId();

            const requestUsers = {
                team_id: teamId,
                user_id: userId,
            };

            userService.listUsers(requestUsers)
                .then(results => {
                    $scope.data.users = results.users;
                    $scope.loading.users = false;
                    $scope.$apply();
                })
        }

        $scope.loadUserGroups = function() {
            $scope.loading.user_groups = true;

            const teamId = authService.getTeamId();
            const userId = authService.getUserId();

            const requestUserGroups = {
                team_id: teamId,
                user_id: userId,
                sort: 'asc',
            };

            userService.listUserGroups(requestUserGroups)
                .then(results => {
                    $scope.data.user_groups = results.teams;
                    $scope.loading.user_groups = false;
                    $scope.$apply();
                });
        }

        $scope.getHuntUser = function(user_id) {
            const index = _.findIndex($scope.data.users, ['user_id', user_id]);
            if (index > -1) {
                return `${$scope.data.users[index].identity.first_name} ${$scope.data.users[index].identity.last_name}`;
            }
            return $scope.staticData.rulesBlock.table.users.dropDownBtn;
        }

        $scope.setUserId = function (rule, value) {
            rule.action_data = rule.action_data || {};
            rule.action_data.hunt_user_group = null;
            rule.action_data.hunt_user_id = value ? value.user_id : null;
        }

        $scope.getHuntUserGroup = function(user_group_id) {
            const index = _.findIndex($scope.data.user_groups, ['user_group_id', user_group_id]);
            if (index > -1) {
                return $scope.data.user_groups[index].name;
            }
            return $scope.staticData.rulesBlock.table.user_groups.dropDownBtn;
        }

        $scope.setUserGroupId = function (rule, value) {
            rule.action_data = rule.action_data || {};
            rule.action_data.hunt_user_id = null;
            rule.action_data.hunt_user_group = value ? value.user_group_id : null;
        }

        $scope.setMohMediaIds = function (rule, value) {
            rule.action_data = rule.action_data || {};
            rule.action_data.moh_media_ids = value ? value.user_group_id : null;
        }

        $scope.formatRules = function(rulesName) {
            let rulesRes = [];
            let index = 0;

            _.forEach($scope.data.voice_ivr[rulesName], function(rule, i) {
                rule = _.omit(rule, ['ruleId']);
                if(rule.iti) {
                    rule = _.omit(rule, ['iti']);
                }

                if (rule.action === 'Queue') {
                    rule.action_data.skip_line_say = rule.action_data.skip_line_media_id ? '' : rule.action_data.skip_line_say;
                    rule.action_data.switch_to_sms_media_say = rule.action_data.switch_to_sms_media_id ? '' : rule.action_data.switch_to_sms_media_say;
                    rule.action_data.greeting_say = rule.action_data.greeting_media_id ? '' : rule.action_data.greeting_say;
                    rule.action_data.max_wait_say = rule.action_data.max_wait_media_id ? '' : rule.action_data.max_wait_say;
                    rule.action_data.say = rule.action_data.play_media_id ? '' : rule.action_data.say;
                }

                rulesRes.push(rule);
                index = i;
            });

            if(index === ($scope.data.voice_ivr[rulesName].length - 1)) {
                return rulesRes;
            }
        };

        $scope.createEditCallRouting = function(e) {
            e.preventDefault();
            $scope.saving.call_rules = true;
            $scope.data.submittedNormalHours = true;
            $scope.data.submittedAfterHours = true;

            const isValid = {
                availability: true,
                rules: $scope.rulesValidation('rules'),
                after_hour_rules: $scope.rulesValidation('after_hour_rules')
            };

            if (!isValid.rules) {
                $scope.saving.call_rules = false;
                $('#normal-hours-tab').tab('show');
                return;
            }

            if (!isValid.after_hour_rules) {
                $scope.saving.call_rules = false;
                $('#after-hours-tab').tab('show');
                return;
            }

            let rulesRes = $scope.formatRules('rules');
            let afterHourRulesRes = $scope.formatRules('after_hour_rules');

            let req = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                ivr_id: $scope.data.voice_ivr.ivr_id,
                type: $scope.data.voice_ivr.type,
                name: $scope.data.voice_ivr.name,
                availability: $scope.getAvailabilityReqObject(),
                after_hour_rules: afterHourRulesRes,
                rules: rulesRes
            };

            if (Object.keys(isValid).every(sectionName => !!isValid[sectionName])) {
                if($scope.data.voice_ivr.ivr_id === null || $scope.data.voice_ivr.ivr_id === undefined) {
                    $scope.createVoiceIvr(req)
                        .then(() => {
                            $state.go('main-wrapper.call-routing');
                        });
                } else {
                    $scope.updateVoiceIvr(req);
                }
            } else {
                $scope.saving.call_rules = false;
            }
        };

        $scope.checkIsRuleDeletable = function (rule) {
            return rule.is_deletable || typeof rule.is_deletable === "undefined";
        }

        $scope.initData = function() {
            $scope.data.voice_ivr.type = 'voice';
            $scope.data.voice_ivr.name = 'Call Routing';
            $scope.data.voice_ivr.availability = {
                days: [],
                daysUI: [],
                tz: ''
            };
            $scope.initAvailability();
            $scope.initRulesNormalHours();
            $scope.initRulesAfterHours();

            $scope.data.notify = {};
            $scope.loading.call_rules = false;
        };
        
        $scope.onInit = function() {
            $scope.data.voice_ivr.ivr_id = $location.search().id;
            $('#availability-tab').tab('show');

            $scope.minTime = $scope.setTimeDateFormat('0.0');
            $scope.maxTime = $scope.setTimeDateFormat('24.0');

            // load ivr data after loading records to not produce error connected with multiselect
            $scope.loadRecordings()
                .then(() => {
                    $scope.loadUsers();
                    $scope.loadUserGroups();

                    if ($scope.data.voice_ivr.ivr_id) {
                        $scope.loadVoiceIvr($scope.data.voice_ivr.ivr_id);
                    } else $scope.initData();
                });
        };

        if (authService.hasAccount()) {
            $scope.onInit();
        }
        else {
            $scope.$on('auth_complete', function(event, args) {
                $scope.onInit();
            });
        }

    }
    module.exports = IVRconfigController;
})();
