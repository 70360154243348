(function() {
    'use strict';

    function ModalController(authService, callService, $rootScope, $scope, $window) {
        var vm = this;
        vm.authService = authService;

        $scope.answerCall = function(call_id) {
            let req = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                access_number: $rootScope.access_number,
                initial_action: callService.CONTROL_ACTIONS.CALL_INTERCEPT,
                intercept_cid: call_id,
                enable_audio: true
            };
            callService.dial(req);
        };

        $scope.onInit = function() {
            $scope.mainModal = $rootScope.mainModal || {};
        };

        if (authService.hasAccount()) {
            $scope.onInit();
        }
        else {
            $scope.$on('auth_complete', function(event, args) {
                $scope.onInit();
            });
        }
    }
    module.exports = ModalController;
})();
