(function () {
    'use strict';

    function DomainService(apiService, $rootScope) {
        return {
            getLists: function (request) {
                return apiService.get($rootScope.settings.API_DOMAIN_URL + '/domain', request);
            },

            getOne: function (request) {
                return apiService.get($rootScope.settings.API_DOMAIN_URL + '/domain/' + request.domain_id, request);
            },

            createOne: function (request) {
                return apiService.post($rootScope.settings.API_DOMAIN_URL + '/domain', request);
            },

            deleteOne: function (request) {
                return apiService.del($rootScope.settings.API_DOMAIN_URL + '/domain/' + request.domain_id, request);
            }
        };
    }
    module.exports = DomainService;
})();
