(function() {
    'use strict';

    function CampaignWizardController(authService, $rootScope, $scope) {
        var vm = this;
        vm.authService = authService;

        $rootScope.viewData = $rootScope.viewData || {};
        $rootScope.viewData.breadcrumbs = $rootScope.viewData.breadcrumbs || [];
        $rootScope.viewData.breadcrumbs = [{
            title: 'New Campaign',
            link_name: 'New Campaign',
            link_url: '#/new-campaign-wizard',
            help_url: $rootScope.help_url
        }];
    
        $scope.testObject = {
            name: {
                title: 'Campaign Name',
                subtitle: 'lorem ipsum lorem ipsum',
                inputPlaceholder: 'My Campaign Name'
            },
            contacts: {
                title: 'Add Contacts',
                subtitle: 'lorem ipsum lorem ipsum',
                buildListBtn: 'Build List',
                resetBtn: 'Reset'
            },
            tips: [
                {
                    title: 'Get contacts from XYZ',
                    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
                    btn: 'Read more',
                    href: '#'
                },
                {
                    title: 'CSV File',
                    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
                    btn: 'Read more',
                    href: '#'
                },
                {
                    title: 'Existing list',
                    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
                    btn: 'Read more',
                    href: '#'
                }
            ],
            options : {
                title: 'Options',
                subtitle: 'lorem ipsum lorem ipsum',
                checkbox1Text: 'Replace duplicates (by default we ignore)',
                checkbox2Text: 'Enrich phone numbers with caler name'
            },
            certifications: {
                title: 'Certifications',
                subtitle: 'lorem ipsum lorem ipsum',
                checkbox1Text: 'My use of this service is compliant with all applicable rules, regulations, and laws',
                checkbox2Text: 'I accept the Terms of Use governing this service'
            }
        };
        
    }
    module.exports = CampaignWizardController;
})();
