(function () {
    'use strict';
    
    function ContactService(apiService, $rootScope) { 
        return {
            getContacts: function (request) {
                return apiService.get($rootScope.settings.API_CONTACT_URL + '/contact', request);
            },

            getContactsCount: function (request) {
                return apiService.get($rootScope.settings.API_CONTACT_URL + '/contact/count', request);
            },

            getContactDetails: function (request) {
                return apiService.get($rootScope.settings.API_CONTACT_URL + '/contact/' + request.contact_id, request);
            },

            exportContacts: function (request) {
                return apiService.post($rootScope.settings.API_CONTACT_URL + '/export', request);
            },

            getExportStatus: function (request) {
                return apiService.get($rootScope.settings.API_CONTACT_URL + '/export/' + request.export_id, request);
            },

            dcExportContacts: function (request) {
                return apiService.post($rootScope.settings.API_CONTACT_URL + '/dc_export', request);
            },

            createContacts: function (request) {
                return apiService.post($rootScope.settings.API_CONTACT_URL + '/contact' , request);
            },

            createContactFields: function (request) {
                return apiService.post($rootScope.settings.API_CONTACT_URL + '/contact/field/contact_fields' , request);
            },

            setContactNotes: function (request) {
                return apiService.post($rootScope.settings.API_CONTACT_URL + '/contact/' + request.contact_id + '/notes' , request);
            },

            editContactNotes: function (request) {
                return apiService.post($rootScope.settings.API_CONTACT_URL + '/contact/' + request.contact_id + '/notes/' + request.note_id , request);
            },

            deleteContactNotes: function (request) {
                return apiService.del($rootScope.settings.API_CONTACT_URL + '/contact/' + request.contact_id + '/notes/' + request.note_id , request);
            },

            updateContact: function (request) {
                return apiService.post($rootScope.settings.API_CONTACT_URL + '/contact/' + request.contact_id, request);
            },

            deleteContact: function (request) {
                return apiService.del($rootScope.settings.API_CONTACT_URL + '/contact/' + request.contact_id, request);
            },

            getOutcomes: function (request) {
                return apiService.get($rootScope.settings.API_CONTACT_URL + '/outcome', request);
            },

            getFieldMap: function (request) {
                return apiService.get($rootScope.settings.API_CONTACT_URL + '/contact/field/field_map', request);
            },

            createList: function (request) {
                return apiService.post($rootScope.settings.API_CONTACT_URL + '/list', request);
            },

            getLists: function (request) {
                return apiService.get($rootScope.settings.API_CONTACT_URL + '/list', request);
            },

            getList: function (request) {
                return apiService.get($rootScope.settings.API_CONTACT_URL + '/list/' + request.list_id, request);
            },

            addToList: function (request) {
                return apiService.post($rootScope.settings.API_CONTACT_URL + '/list/' + request.list_id + '/add', request);
            },

            updateList: function (request) {
                return apiService.post($rootScope.settings.API_CONTACT_URL + '/list/' + request.list_id, request);
            },

            deleteList: function (request) {
                return apiService.del($rootScope.settings.API_CONTACT_URL + '/list/' +  request.list_id, request);
            },

            getWebforms: function (request) {
                return apiService.get($rootScope.settings.API_CONTACT_URL + '/webform', request);
            },

            getWebformDetails: function (request) {
                return apiService.get($rootScope.settings.API_CONTACT_URL + '/webform/' + request.webform_id, request);
            },

            createWebform: function (request) {
                return apiService.post($rootScope.settings.API_CONTACT_URL + '/webform', request);
            },

            updateWebform: function (request) {
                return apiService.post($rootScope.settings.API_CONTACT_URL + '/webform/' + request.webform_id, request);
            },

            deleteWebform: function (request) {
                return apiService.del($rootScope.settings.API_CONTACT_URL + '/webform/' +  request.webform_id, request);
            },

            getCustomFields: function (request) {
                return apiService.get($rootScope.settings.API_CONTACT_URL + '/custom-fields', request);
            },

            bulkUpdateCustomFields: function (request) {
                return apiService.put($rootScope.settings.API_CONTACT_URL + `/custom-fields/bulk-update`, request);
            },
            updateContactTag: function (request) {
                return apiService.post($rootScope.settings.API_CONTACT_URL + `/contact/${request.contact_id}/tags/${request.action}/${request.tag_id}`, request);
            }

        };
    }
    module.exports = ContactService;
})();




