(function () {
    'use strict';

    function ChecklistController(authService, $rootScope, $scope, $window, $location, campaignService, phoneService, contactService, userService, mediaService, integrationService) {

        $rootScope.viewData = $rootScope.viewData || {};
        $rootScope.viewData.breadcrumbs = $rootScope.viewData.breadcrumbs || [];
        $rootScope.viewData.breadcrumbs = [{
            title: 'Getting Started',
            link_name: 'Getting Started',
            link_url: '#/checklist',
            help_url: $rootScope.help_url
        }];

        $scope.staticData = {
            loading: 'Loading',
            header: {
                subtitleHighlighted: 'Let\'s get started',
                title: `Welcome to ${$rootScope.settings.APP_NAME}`,
                subtitle: 'Your all-in-one mass communication and contact management solution'
            },
            guidedTours: {
                title: 'Take a Look - Guided Tours',
                text: `Explore everything that ${$rootScope.settings.APP_NAME} has to offer by taking a guided tour of you new CRM and communication platform.`,
                url: '/welcome',
                urlParams: null,
                btnText: 'View Guided Tours',
            },
            needHelp: {
                title: 'Still Need Help?',
                text: 'We\'re here to help! Visit our online help center or chat with one of our friendly support agents.',
                href: 'https://help.callcowboy.com/',
                target: '_blank',
                btnText: 'Visit Help Center',
            },
            oneOnOne: {
                title: 'Talk to an Expert',
                text: 'Get a one-on-one session with a site expert, and we\'ll guide you through everything you need.',
                href: 'https://calendly.com/d/cpsm-nv4-v5g/call-cowboy-onboarding',
                target: '_blank',
                btnText: 'Connect With An Expert',
            },
            steps: [
                {
                    id: 0,
                    title: 'Howdy!',
                    description: `We assigned you a number to test ${$rootScope.settings.APP_NAME}`,
                    description1: 'Your number will be ready in 5-10 minutes.',
                    description2: [
                        'Meanwhile, ',
                        'complete your registration',
                        ` to unlock all ${$rootScope.settings.APP_NAME}\'s features—just click the red banner at the top!`
                    ],
                    description3: 'You can keep this number, or swap it',
                    actions: [
                        {
                            url: null,
                            title: 'Try Dialing'
                        },
                        {
                            url: null,
                            title: 'Swap/Add Number(s)'
                        }
                    ]
                },
                {
                    id: 1,
                    title: 'Import Your Contacts',
                    description: 'If you have existing contacts to import, you can do so now. You can export contacts from most CRMs in CSV format, or from one of our integrations.',
                    actions: [
                        {
                            url: '#/lists',
                            title: 'Take Me There'
                        }
                    ],
                    skipBlock: {
                        description: 'Don\'t have contacts to import?',
                        skipBtn: { title: 'Skip This Step' }
                    }
                },
                {
                    id: 2,
                    title: 'Set Business Rules',
                    description4: 'We\'ve set your business hours to',
                    description5: '9am - 5pm | Mon - Fri',
                    description6: 'Calls will send to your cell phone',
                    actions2: [
                        {
                            url: '#/call-routing',
                            title: 'Change Business Rules'
                        }
                    ],
                    skipBlock: {
                        description: 'Happy with your business rules?',
                        skipBtn: { title: 'Skip This Step' }
                    }
                },
                {
                    id: 3,
                    title: 'Send Your First Campaign',
                    description: `${$rootScope.settings.APP_NAME} offers powerful outbound calling tools including ringless voicemail campaigns and auto/power/predictive dialing from your computer, home, or mobile phone.`,
                    actions: [
                        {
                            url: '#/bulk-campaigns',
                            title: 'Send Ringless Voicemail'
                        }
                    ],
                    skipBlock: {
                        description: 'Already sending voicemails?',
                        skipBtn: { title: 'Skip This Step' }
                    }
                },
                {
                    id: 4,
                    title: 'One-on-One',
                    description: 'Need assistance? Schedule a session with an onboarding specialist below. Our specialists will guide you through the entire setup process. Schedule today!',
                    actions: [
                        {
                            url: 'https://calendly.com/d/cpsm-nv4-v5g/call-cowboy-onboarding',
                            title: 'Schedule'
                        }
                    ],
                    skipBlock: {
                        description: 'Set up and ready to go? No worries!',
                        skipBtn: { title: 'Skip This Step' }
                    }
                },
                {
                    id: 5,
                    title: 'Verify Your Identity',
                    description: 'Visit our trust center to get verified.',
                    description1: 'It only takes a few seconds!',
                    description2: [
                        `To reduce telephone fraud, ${$rootScope.settings.APP_NAME} is required to `,
                        'verify your identity',
                        '.'
                    ],
                    actions: [
                        {
                            url: '#/trust-center',
                            title: 'Trust Center'
                        }
                    ],
                    skipBlock: {
                        description: 'Have you finished verification?',
                        skipBtn: { title: 'Skip This Step' }
                    }
                },
                {
                    id: 6,
                    title: 'Add Recordings',
                    description: 'Let\'s create your first voicemail! You can create new recordings and manage your recordings list.',
                    actions: [
                        {
                            url: '#/recordings',
                            title: 'Recordings'
                        }
                    ],
                    skipBlock: {
                        description: 'Already have recordings and ready to send ringless voicemails?',
                        skipBtn: { title: 'Skip This Step' }
                    }
                },
                {
                    id: 7,
                    title: 'Update Shopify Policies',
                    description: 'Before sending messaging, we need you to attest to having valid policies! Click "How to Get Compliant" to attest or find out more.',
                    actions: [
                        {
                            url: '#/integrations',
                            title: 'Integrations'
                        },
                        {
                            url: null,
                            title: 'How to Get Compliant'
                        }
                    ],
                    skipBlock: {
                        description: 'Already have valid verbiage for your privacy policy and marketing consent, as well as opt-in?',
                        skipBtn: { title: 'Skip This Step' }
                    }
                },
                {
                    id: 8,
                    title: 'Sync Your Contacts',
                    description: 'Since you\'re connected with our Shopify integration, we can automatically sync your contacts to keep your data up to date. You have the flexibility to choose how this sync works — opt for a one-way sync to import/export contacts from/to Shopify, a two-way sync to keep contacts updated across both platforms, or disable syncing altogether to manage contacts separately.',
                    actions: [
                        {
                            url: '#/integrations',
                            title: 'Integrations'
                        }
                    ],
                    skipBlock: {
                        description: 'Contact sync settings already configured?',
                        skipBtn: { title: 'Skip This Step' }
                    }
                }
            ]
        };

        $scope.data = {
            loading: true,
            mainNumber: null,
            totalCount: {
                users: -1,
                phoneNumbers: -1,
                campaigns: -1,
                contacts: -1,
                recordings: -1
            },
            checklist: []
        };

        $scope.getLocalStorageChecklist = function(step) {
            return $scope.localStorageChecklist.has(step) ? $scope.localStorageChecklist.get(step) : false;
        };

        $scope.setLocalStorageChecklist = function(step, skipped, checked) {
            if(step && step !== null) {
                $scope.localStorageChecklist.set(step, {
                    skipped: skipped,
                    checked: checked
                });
            }
        };

        $scope.goToPage = function(url, urlParams) {
            $location.path(url).search((urlParams && urlParams !== null) ? urlParams : '');
        };

        $scope.visitHelpCenter = function(step) {
            $scope.setLocalStorageChecklist(step, false, true);
            $window.open($scope.staticData.needHelp.href, $scope.staticData.needHelp.target);
        };

        $scope.viewGuidedTours = function(step, url, urlParams) {
            $scope.setLocalStorageChecklist(step, false, true);
            $scope.goToPage(url, urlParams);
        };

        $scope.handleActionBtn = function(step, action) {
            switch(step.id) {
                case 0:
                    if (action.title === 'Try Dialing') {
                        $rootScope.$broadcast('toggleRightSideBar');
                    } else if  (action.title === 'Swap/Add Number(s)') {
                        $rootScope.showAddNumbersModal();
                    }
                    break;
                case 7:
                    if (action.title === 'How to Get Compliant') {
                        $rootScope.showUpdateShopifyPolicyModal();
                    }
                    break;
                default:
                    break;
            }
        };

        $scope.isStepCompleted = function(step) {
            const integrations = integrationService.get();

            switch(step) {
                case 0: return ($scope.data.totalCount.phoneNumbers > 1) ? true : false;
                case 1: return ($scope.data.totalCount.contacts > 0) ? true : false;
                case 3: return ($scope.data.totalCount.campaigns > 0) ? true : false;
                case 5: return ($rootScope.user && $rootScope.user.team && $rootScope.user.team.verified) ? true : false;
                case 6: return ($scope.data.totalCount.recordings > 0) ? true : false;
                case 7: return (integrations['shopify'].public_data
                    && (integrations['shopify'].public_data.valid_privacy_policy && integrations['shopify'].public_data.marketing_consent_enabled)
                ) ? true : false;
                default:
                    return false;
            }
        };

        $scope.isStepSkipped = function(step) {
            let localStorageValue = $scope.getLocalStorageChecklist(step);
            return localStorageValue ? localStorageValue.skipped : false;
        };

        $scope.skipStep = function(step) {
            let localStorageValue = $scope.getLocalStorageChecklist(step);
            let checked = localStorageValue ? localStorageValue.checked : false;
            $scope.setLocalStorageChecklist(step, true, checked);
        };

        $scope.checkSteps = function() {
            for(let step = 0; step < $scope.data.checklist.length; step++) {
                let localStorageValue = $scope.getLocalStorageChecklist(step);
                const stepId = $scope.data.checklist[step].id;
                let checked = $scope.isStepCompleted(stepId);
                $scope.setLocalStorageChecklist(step, (!localStorageValue) ? false : localStorageValue.skipped, checked);
            }
        };

        $scope.loadData = function() {
            $scope.data.loading = true;
            const [
                yourNewNumber,
                importContacts,
                setBusinessRules,
                rvmCampaign,
                oneOnOne,
                verifyIdentity,
                addRecordings,
                shopifyPolicies,
                syncContacts
            ] = $scope.staticData.steps;

            const defaultChecklist = [
                yourNewNumber,
                importContacts,
                setBusinessRules,
                oneOnOne
            ];

            const integrationList = integrationService.get();
            const user = authService.getUser();

            // check for integration users
            if (integrationList['shopify'] && integrationList['shopify'].active) {

                $scope.data.checklist = [
                    verifyIdentity,
                    shopifyPolicies,
                    syncContacts,
                    addRecordings,
                    rvmCampaign
                ];
            }
            // check for lead info, otherwise use default steps
            else if (user && user.user_preferences && user.user_preferences.lead) {

                // RVM
                if (user.user_preferences.lead.rvm) {
                    $scope.data.checklist = [
                        verifyIdentity,
                        importContacts,
                        addRecordings,
                        rvmCampaign
                    ];
                }
                // Call Tracking
                else if (user.user_preferences.lead.callTracking) {
                    $scope.data.checklist = [
                        verifyIdentity,
                        yourNewNumber,
                        setBusinessRules
                    ];
                }
                // Default
                else {
                    $scope.data.checklist = defaultChecklist;
                }
            }
            else {
                $scope.data.checklist = defaultChecklist;
            }

            const countReq = {
                user_id: authService.getUserId(),
                team_id: authService.getTeamId()
            };

            const mediaReq = {
                ...countReq,
                reviewed: true,
                media_exists: true
            };

            return Promise.all([
                phoneService.getNumbersCount(countReq),
                userService.getUsersCount(countReq),
                phoneService.getNumbers(countReq),
                campaignService.getCampaignsCount(countReq),
                contactService.getContactsCount(countReq),
                mediaService.listMediaUrls(mediaReq)
            ])
                .then(results => {
                    $scope.data.totalCount.phoneNumbers = results[0].totalItems;
                    $scope.data.totalCount.users = results[1].totalItems;
                    $scope.data.mainNumber = results[2].numbers.length ? $scope.formatPhone(results[2].numbers[0].phone_number) : '';
                    $scope.data.totalCount.campaigns = results[3].totalItems;
                    $scope.data.totalCount.contacts = results[4].totalItems;
                    $scope.data.totalCount.recordings = results[5].total;

                    $scope.checkSteps();
                    $scope.data.loading = false;

                    $rootScope.$broadcast('toggleRightSideBar');
                    $scope.$apply();
                });
        };

        $scope.formatPhone = function(value) {
            return $rootScope.libPhoneFormatPhone(value);
        };

        $scope.$on('hiddenRightSideBar', function () {
            $('.checklist-section').removeClass('open-right-sidebar');
        });
        $scope.$on('shownRightSideBar', function () {
            $('.checklist-section').addClass('open-right-sidebar');
        });

        $scope.onInit = function() {
            $window.scrollTo(0, 0);
            let userId = localStorage.getItem('auth_user_id');
            let localStorageNamespace = 'checklist-' + userId;
            $scope.localStorageChecklist = new Fifo({ namespace: localStorageNamespace });
        };

        if (authService.hasAccount() ) {
            $scope.onInit();
        } else {
            $scope.$on('auth_complete', function(event, args) {
                $scope.onInit();
            });
        }

        $scope.$on('integrations_seeded', function(event, args) {
            $scope.loadData();
        });
    }

    module.exports = ChecklistController;
})();
