(function () {

    'use strict';
    function IntegrationService (authService, apiService, $rootScope) {
        let integrations = {};

        return {
            set: (receivedIntegrations) => {
                integrations = {};
                if (receivedIntegrations && Array.isArray(receivedIntegrations)) {
                    receivedIntegrations.forEach(integration => {
                        switch (integration.integration_type) {
                            case 'webhook':
                                if (!integrations[integration.integration_type]) {
                                    integrations[integration.integration_type] = [];
                                }
                                integrations[integration.integration_type].push(integration);
                                break;
                            default:
                                integrations[integration.integration_type] = integration;
                                break;
                        }
                    })
                }
            },
            get: () => {
              return integrations;
            },
            list: (request) => {
                const req = Object.assign({}, {
                    team_id: authService.getTeamId(),
                    user_id: authService.getUserId(),
                }, request);
                return apiService.get($rootScope.settings.API_INTEGRATION_URL + '/integrations', req);
            },

            connect: function (request) {
                const req = Object.assign({}, {
                    team_id: authService.getTeamId(),
                    user_id: authService.getUserId(),
                }, request);
                return apiService.post($rootScope.settings.API_INTEGRATION_URL + '/integrations', req);
            },

            disconnect: function (request) {
                const req = Object.assign({}, {
                    team_id: authService.getTeamId(),
                    user_id: authService.getUserId(),
                }, request);
                return apiService.del($rootScope.settings.API_INTEGRATION_URL + '/integrations', req);
            },

            getRvmData: function () {
                const req = {
                    team_id: authService.getTeamId(),
                    user_id: authService.getUserId(),
                };
                return apiService.get($rootScope.settings.API_INTEGRATION_URL + '/integrations/drop-cowboy/rvm', req);
            },

            getNumberPools: function (integrationId) {
                const req = {
                    team_id: authService.getTeamId(),
                    user_id: authService.getUserId(),
                };
                return apiService.get($rootScope.settings.API_INTEGRATION_URL + `/integrations/${integrationId}/drop-cowboy/pools`, req);
            },

            getMediaUrl: function (mediaId) {
                const req = {
                    team_id: authService.getTeamId(),
                    user_id: authService.getUserId(),
                };
                return apiService.get($rootScope.settings.API_INTEGRATION_URL + `/integrations/drop-cowboy/media/${mediaId}/url`, req);
            },

            sendRvm: function (request) {
                const req = Object.assign({}, {
                    team_id: authService.getTeamId(),
                    user_id: authService.getUserId(),
                }, request);
                return apiService.post($rootScope.settings.API_INTEGRATION_URL + '/integrations/drop-cowboy/rvm', req);
            },

            syncContacts: function (integrationId, request) {
                const req = Object.assign({}, {
                    team_id: authService.getTeamId(),
                    user_id: authService.getUserId(),
                }, request);
                return apiService.post($rootScope.settings.API_INTEGRATION_URL + `/integrations/${integrationId}/drop-cowboy/sync-contacts`, req);
            },

            createWebhook: function (request) {
                const req = Object.assign({}, {
                    team_id: authService.getTeamId(),
                    user_id: authService.getUserId(),
                }, request);
                return apiService.post($rootScope.settings.API_INTEGRATION_URL + `/integrations/webhook`, req);
            },

            updateWebhook: function (webhookId, request) {
                const req = Object.assign({}, {
                    team_id: authService.getTeamId(),
                    user_id: authService.getUserId(),
                }, request);
                return apiService.put($rootScope.settings.API_INTEGRATION_URL + `/integrations/webhook/${webhookId}`, req);
            },

            deleteWebhook: function (webhookId) {
                const req = Object.assign({}, {
                    team_id: authService.getTeamId(),
                    user_id: authService.getUserId(),
                });
                return apiService.del($rootScope.settings.API_INTEGRATION_URL + `/integrations/webhook/${webhookId}`, req);
            },

            // data integration functions
            shopifyImport: function (request) {
                return apiService.get($rootScope.settings.API_SHOPIFY_URL + '/shopify/import/customers', request);
            },
            shopifyCheckout: function (request) {
                return apiService.post($rootScope.settings.API_SHOPIFY_URL + '/shopify/checkout', request);
            },
            shopifyCreateSubscription: function (request) {
                return apiService.post($rootScope.settings.API_SHOPIFY_URL + '/shopify/create/subscription', request);
            },
            deactivateShopify: function () {
                const req = Object.assign({}, {
                    team_id: authService.getTeamId(),
                    user_id: authService.getUserId(),
                });
                return apiService.post($rootScope.settings.API_SHOPIFY_URL + '/shopify/deactivate/integration', req);
            },
            shopifyUpdatePolicy: function (request) {
                const req = Object.assign({}, {
                    team_id: authService.getTeamId(),
                    user_id: authService.getUserId(),
                    valid_privacy_policy: request.valid_privacy_policy,
                    marketing_consent_enabled: request.marketing_consent_enabled
                });
                return apiService.post($rootScope.settings.API_SHOPIFY_URL + '/shopify/update/policy', req);
            }
        }
    }

    module.exports = IntegrationService;
})();




