(function() {
    'use strict';

    function WebformDetailsController($rootScope, $scope, authService, contactService, $location, $sce, $window) {
        var vm = this;
        vm.authService = authService;

        $rootScope.viewData = $rootScope.viewData || {};
        $rootScope.viewData.breadcrumbs = $rootScope.viewData.breadcrumbs || [];
        $rootScope.viewData.breadcrumbs = [{
            title: 'Web Forms',
            link_name: 'Web Forms',
            link_url: '#/webforms',
            help_url: $rootScope.help_url
        }, {
            title: 'Details',
            link_name: 'Details',
            link_url: '',
            page_data: {
                webform_id: $location.search().webformId,
            },
        }];

        $scope.staticData = {
            webformInfo: {
                editBtnTitle: 'Edit Web Form'
            }
        };

        $scope.data = {
            webform: {},
            webformCode: '',
            trustedWebformCode: '',
            alwaysShowFormSubmitMessages: true,
        };

        $scope.loading = {
            webform: true
        };

        $scope.loadWebform = function(webformId) {

            const request = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                webform_id: webformId
            };

            contactService.getWebformDetails(request)
                .then(webform => {
                    $scope.data.webform = webform;
                    $scope.generateCode();

                    $scope.loading.webform = false;
                    $scope.$apply();
                })
                .catch(err => {
                    console.error(err);
                });
        };

        $scope.generateCode = function() {
            let w = $scope.data.webform;
            let attr = ['label', 'required', 'placeholder', 'rows', 'min', 'max', 'step', 'value', 'hide_label'];
            let fields = "[";
            for (let i = 0; i < w.fields.length; i++) {
                let f = w.fields[i];
                if (!f.name) { continue; }
                if (i > 0) { fields += ','; }
                fields += '{';

                fields += '"name":"'+ f.name +'"';
                for (let j = 0; j < attr.length; j++) {
                    let a = attr[j];
                    if (typeof f[a] !== 'undefined') {
                        fields += ',"'+ a +'":"'+ f[a] +'"';
                    }
                }
                if (typeof f.class_names !== 'undefined') {
                    fields += ',"classNames":{';
                    let isFirst = true;
                    for (let j in f.class_names) {
                        if (!isFirst) { fields += ','; }
                        fields += '"'+ j +'":"'+ f.class_names[j] +'"';
                        isFirst = false;
                    }
                    fields += '}';
                }
                switch (f.type) {
                    case 'custom_select':
                        fields += ',"type":"select"';
                        fields += ',"options":[';
                        for (let j = 0; j < f.options.length; j++) {
                            let o = f.options[j];
                            if (j > 0) { fields += ','; }
                            fields += '{';
                            fields += '"label":"'+ o.label +'",';
                            fields += '"value":"'+ o.value +'"';
                            fields += '}';
                        }
                        fields += ']';
                        break;
                    case 'custom_text':
                        fields += ',"type":"textarea"';
                        break;
                    case 'custom_date':
                        fields += ',"type":"date"';
                        break;
                    case 'custom_number':
                        fields += ',"type":"number"';
                        break;
                    case 'custom_hidden':
                        fields += ',"type":"hidden"';
                        break;
                    case 'email':
                    case 'custom_email':
                        fields += ',"type":"email"';
                        break;
                    default:
                        fields += ',"type":"text"';
                        break;
                }

                fields += '}';
            }
            fields += "]";

            let msgOnComplete = '';
            if (w.success_msg && w.error_msg) {
                msgOnComplete = ',"msgOnComplete":true,' +
                    '"successMsg":{' +
                        '"name":"success_msg",' +
                        '"text":"'+ w.success_msg +'"' +
                    '},' +
                    '"errorMsg":{' +
                        '"name":"error_msg",' +
                        '"text":"'+ w.error_msg +'"' +
                    '}';
            } else if ($scope.data.alwaysShowFormSubmitMessages) {
                msgOnComplete = ',"msgOnComplete":true';
            }

            $scope.data.webformCode = '<script src="' + $rootScope.settings.WEB_FORM_JS_SOURCE_URL + '"></script>' +
                '<link rel="stylesheet" href="' + $rootScope.settings.WEB_FORM_CSS_SOURCE_URL + '"/>' +
                '<script>' +
                  'var CowboyFormCheck = function() {' +
                    'if (typeof CowboyForm != "function") {' +
                      'window.setTimeout(CowboyFormCheck, 500);' +
                      'return;' +
                    '}' +
                    'CowboyForm({' +
                        '"teamId":"'+ w.team_id +'",' +
                        '"webformId":"'+ w.webform_id +'",' +
                        '"listId":"'+ w.default_list_id +'",' +
                        '"fields":' + fields +
                        msgOnComplete +
                    '});' +
                  '};' +
                  'CowboyFormCheck();' +
                '</script>';
            $scope.data.trustedWebformCode = $sce.trustAsHtml($scope.data.webformCode);
        };

        $scope.editForm = function() {

            $location.path('/webforms/edit').search({webformId: $location.search().webformId});
        };

        $scope.ifRightPanelOpen = function() {
            $('.webform-info-col').removeClass('order-xl-2');
            $('.webform-info-col').removeClass('col-xl-7');
            $('.webform-col').removeClass('order-xl-1');
            $('.webform-col').removeClass('col-xl-5');

            $('.webform-info-col').addClass('col-xl-12');
            $('.webform-info-col').addClass('order-xl-1');
            $('.webform-col').addClass('col-xl-12');
            $('.webform-col').addClass('order-xl-2');
        };
        $scope.ifRightPanelClose = function() {
            $('.webform-info-col').removeClass('col-xl-12');
            $('.webform-info-col').removeClass('order-xl-1');
            $('.webform-col').removeClass('col-xl-12');
            $('.webform-col').removeClass('order-xl-2');

            $('.webform-info-col').addClass('order-xl-2');
            $('.webform-info-col').addClass('col-xl-7');
            $('.webform-col').addClass('order-xl-1');
            $('.webform-col').addClass('col-xl-5');
        };

        $scope.mediaWithRightPanel = function() {
            var width = (window.innerWidth > 0) ? window.innerWidth : this.screen.width;
            if((width > 1424 && width < 1550 && $('.right-sidebar').hasClass('shw-rside')) || (width > 1199 && width < 1250)) {
                $scope.ifRightPanelOpen();
            } else {
                $scope.ifRightPanelClose();
            }
        };

        $scope.onInit = function() {
            $scope.mediaWithRightPanel();
            $scope.loadWebform( $location.search().webformId );

            angular.element($window).bind('load', function(){
                $scope.mediaWithRightPanel();
            });
            angular.element($window).bind('resize', function(){
                $scope.mediaWithRightPanel();
            });
        };

        if (authService.hasAccount()) {
            $scope.onInit();
        }
        else {
            $scope.$on('auth_complete', function(event, args) {
                $scope.onInit();
            });
        }
    }
    module.exports = WebformDetailsController;
})();
