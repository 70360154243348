(function () {
    'use strict';
    
    function FaxService(apiService, $rootScope) {
        return {

            /* Document endpoints */

            createDocument: function (request) {
                return apiService.post($rootScope.settings.API_FAX_URL + '/document', request);
            },

            updateDocument: function (request) {
                return apiService.post($rootScope.settings.API_FAX_URL + '/document/' + request.document_id, request);
            },

            getDocuments: function (request) {
                return apiService.get($rootScope.settings.API_FAX_URL + '/document', request);
            },

            getDocumentDetails: function (request) {
                return apiService.get($rootScope.settings.API_FAX_URL + '/document/' + request.document_id, request);
            },

            getDocumentUrl: function (request) {
                return apiService.get($rootScope.settings.API_FAX_URL + '/document/' + request.document_id + '/url', request);
            },

            deleteDocument: function (request) {
                return apiService.del($rootScope.settings.API_FAX_URL + '/document/' + request.document_id, request);
            },

            /* Fax endpoints */

            sendFax: function (request) {
                return apiService.post($rootScope.settings.API_FAX_URL + '/fax', request);
            },

            updateFax: function (request) {
                return apiService.post($rootScope.settings.API_FAX_URL + '/fax/' + request.fax_id, request);
            },

            getFaxes: function (request) {
                return apiService.get($rootScope.settings.API_FAX_URL + '/fax', request);
            },

            getFaxDetails: function (request) {
                return apiService.get($rootScope.settings.API_FAX_URL + '/fax/' + request.fax_id, request);
            },

            deleteFax: function (request) {
                return apiService.del($rootScope.settings.API_FAX_URL + '/fax/' + request.fax_id, request);
            }

        };
    }
    module.exports = FaxService;
})();
