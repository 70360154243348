(function() {
    'use strict';

    function CustomFieldsTypePlaceholderListItemController(authService) {
        var vm = this;
        vm.authService = authService;

    }
    module.exports = CustomFieldsTypePlaceholderListItemController;
})();
