(function() {
    'use strict';

    function HowItWorksController(authService, $rootScope, $scope) {
        var vm = this;
        vm.authService = authService;

        $rootScope.viewData = $rootScope.viewData || {};
        $rootScope.viewData.breadcrumbs = $rootScope.viewData.breadcrumbs || [];
        $rootScope.viewData.breadcrumbs = [{
            title: 'How it Works?',
            link_name: 'How it Works?',
            link_url: '#/how-it-works',
            help_url: $rootScope.help_url
        }];

        $scope.testArray = [
            {
                step: "1",
                header: "Lorem ipsum",
                subheader: "lorem ipsum dolor sit amet",
                text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Aenean sed adipiscing diam donec. Commodo ullamcorper a lacus vestibulum sed arcu non. A lacus vestibulum sed arcu. Congue nisi vitae suscipit tellus mauris a diam maecenas sed. Integer malesuada nunc vel risus commodo viverra maecenas accumsan. Eu feugiat pretium nibh ipsum consequat nisl vel pretium. Vestibulum lorem sed risus ultricies tristique nulla. Sit amet commodo nulla facilisi. Augue neque gravida in fermentum et sollicitudin ac. Leo vel orci porta non pulvinar neque laoreet suspendisse. At consectetur lorem donec massa sapien faucibus et molestie ac. Tellus integer feugiat scelerisque varius morbi. Tortor vitae purus faucibus ornare suspendisse sed nisi lacus sed. Odio ut sem nulla pharetra diam sit amet. Mauris ultrices eros in cursus. Aenean et tortor at risus viverra adipiscing at in."
            },
            {
                step: "2",
                header: "Lorem ipsum",
                subheader: "lorem ipsum dolor sit amet",
                text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Aenean sed adipiscing diam donec. Commodo ullamcorper a lacus vestibulum sed arcu non. A lacus vestibulum sed arcu. Congue nisi vitae suscipit tellus mauris a diam maecenas sed. Integer malesuada nunc vel risus commodo viverra maecenas accumsan. Eu feugiat pretium nibh ipsum consequat nisl vel pretium. Vestibulum lorem sed risus ultricies tristique nulla. Sit amet commodo nulla facilisi. Augue neque gravida in fermentum et sollicitudin ac. Leo vel orci porta non pulvinar neque laoreet suspendisse. At consectetur lorem donec massa sapien faucibus et molestie ac. Tellus integer feugiat scelerisque varius morbi. Tortor vitae purus faucibus ornare suspendisse sed nisi lacus sed. Odio ut sem nulla pharetra diam sit amet. Mauris ultrices eros in cursus. Aenean et tortor at risus viverra adipiscing at in."
            },
            {
                step: "3",
                header: "Lorem ipsum",
                subheader: "lorem ipsum dolor sit amet",
                text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Aenean sed adipiscing diam donec. Commodo ullamcorper a lacus vestibulum sed arcu non. A lacus vestibulum sed arcu. Congue nisi vitae suscipit tellus mauris a diam maecenas sed. Integer malesuada nunc vel risus commodo viverra maecenas accumsan. Eu feugiat pretium nibh ipsum consequat nisl vel pretium. Vestibulum lorem sed risus ultricies tristique nulla. Sit amet commodo nulla facilisi. Augue neque gravida in fermentum et sollicitudin ac. Leo vel orci porta non pulvinar neque laoreet suspendisse. At consectetur lorem donec massa sapien faucibus et molestie ac. Tellus integer feugiat scelerisque varius morbi. Tortor vitae purus faucibus ornare suspendisse sed nisi lacus sed. Odio ut sem nulla pharetra diam sit amet. Mauris ultrices eros in cursus. Aenean et tortor at risus viverra adipiscing at in."
            }
        ];
    }
    module.exports = HowItWorksController;
})();
