(function() {
    'use strict';

    function AdHocCallModalController(authService, phoneService, $rootScope, $scope, $window) {
        var vm = this;
        vm.authService = authService;

        $scope.forms = {
            to: {
                number: '',
                submited: false,
                is_valid_phone_number: undefined,
            },
            from: {
                number: '',
                submited: false,
                is_valid_phone_number: undefined,
            },
        };

        $scope.contactCall = function(contact) {

            if (!$scope.forms.to.is_valid_phone_number) {
                return;
            }

            let req = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                contact_number: $scope.forms.to.number,
                user_phone_number: $scope.forms.user_phone_number,
                caller_id: $scope.adHocCallModal.phoneSelected,
            };

            phoneService.callContact(req)
                .then(results => {
                    swal("Success", "We're calling you", "success");
                    $("#callModal").modal('hide');
                })
                .catch(err => {
                    swal("Cancelled", "Unable to call contact.\nCalls to this contact may be blockeds.", "error");
                    $("#callModal").modal('hide');
                });
        };

        $scope.modalPhoneNumberMask = function(number, type) {
            var phoneNumberId = $('#' + type);
            var phoneNumberIdInput = document.querySelector('#' + type);

            if(phoneNumberId) {
                $scope.forms[type].iti = window.intlTelInput(phoneNumberIdInput, {
                    nationalMode: 'false',
                    autoPlaceholder: 'aggressive',
                    separateDialCode: 'true',
                    validationNumberTypes: [
                        'FIXED_LINE',
                        'MOBILE',
                        'FIXED_LINE_OR_MOBILE',
                        'TOLL_FREE',
                        'PREMIUM_RATE',
                        'SHARED_COST',
                        'VOIP',
                        'PERSONAL_NUMBER',
                        'PAGER',
                        'UAN',
                        'VOICEMAIL',
                        'UNKNOWN'
                    ],
                    initialCountry: 'us',
                    // intl-tel-input is up to date as of dec 2024
                    loadUtils: () => import("https://cdn.jsdelivr.net/npm/intl-tel-input@25.2.0/build/js/utils.js"),
                    customPlaceholder: function (selectedCountryPlaceholder, selectedCountryData) {
                        const pl = selectedCountryPlaceholder.replace(/[0-9]/g, '0');
                        phoneNumberId.attr('maxlength', pl.length);
                        $scope.setPhoneMask(phoneNumberId, pl, pl);
                        return pl;
                    }
                });

                if(number){
                    $scope.forms[type].iti.setNumber(number);
                }

                phoneNumberId.on('countrychange', function(e) {
                    $scope.forms[type].iti.setNumber('');
                    phoneNumberId.unmask();

                    var mask = e.target.placeholder.replace(/[0-9]/g, '0');
                    phoneNumberId.attr('maxlength', mask.length);
                    $scope.setPhoneMask(phoneNumberId, mask, e.target.placeholder);
                });
            }
        };

        $scope.formatPhone = function(type) {
            if($scope.forms[type].iti) {
                $scope.forms[type].is_valid_phone_number = $scope.forms[type].iti.isValidNumber();
                $scope.forms[type].number = $scope.forms[type].is_valid_phone_number ? $scope.forms[type].iti.getNumber(window.intlTelInput.utils.numberFormat.E164) : '';
                $scope.validateFields(type);
            }
        };

        $scope.validateFields = function(type) {
            $scope.forms[type].is_valid = !!($scope.forms[type].to_is_valid_phone_number && $scope.forms[type].from && $scope.forms[type].compliance);
        };

        $scope.clearIti = function(type) {
            $scope.forms[type].is_valid_phone_number = undefined;
            $scope.forms[type].iti.setNumber('');
            $('#' + type).unmask();
            $scope[typeIti].destroy();
        };

        $scope.loadNumbers = function() {
            $scope.adHocCallModal.rented_numbers = $rootScope.team_phone_numbers.map(number => {return number.phone_number});
            $scope.forms.user_numbers = authService.getUserNumbers();
            $scope.forms.user_phone_number = $scope.forms.user_numbers[0];
        };

        $scope.setSelectedPhone = function(number) {

            $scope.adHocCallModal.phoneSelected = number;
            $scope.$apply();
        };

        $scope.setPhoneMask = function(elem, mask, placeholder) {
            elem.mask(mask, {
                placeholder: placeholder
            });
        };

        $scope.onInit = function() {
            $scope.adHocCallModal = $rootScope.adHocCallModal || {};

            $( "#adhoc-call-modal" ).on('shown.bs.modal', function(){
                $scope.loadNumbers();
                setTimeout(function () {
                    $scope.modalPhoneNumberMask('', "to");
                }, 300);
            });
        };

        if (authService.hasAccount()) {
            $scope.onInit();
        }
        else {
            $scope.$on('auth_complete', function(event, args) {
                $scope.onInit();
            });
        }
    }
    module.exports = AdHocCallModalController;
})();
