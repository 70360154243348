(function() {
    'use strict';

    function CartModalController($rootScope, $scope, authService, accountService, $location) {

        $scope.staticData = {
            billingAddress: {
                billingAddressTitle: 'Billing Address',
                changeAddressBtn: 'Change Address',
                placeholders: ['Street Address']
            },
            paymentMethod: {
                paymentMethodTitle: 'Payment Method',
                weAcceptCardsTitle: 'We Accept',
                preview: 'Credit Card (Last 4):',
                changePaymentBtn: 'Change Payment',
                placeholders: [
                    'Name on Card',
                    'Card Number',
                    'CVV',
                    'MM',
                    'YY',
                ],
                masks: {
                    cardNumberMask: '0000 0000 0000 0000',
                    secureCodeMask: '0000',
                    paymentMonthMask: '00',
                    paymentYearMask: '00',
                },
            },
            agreeCheckboxes: {
                agree1: `By placing an order you agree to be billed by ${$rootScope.settings.PARENT_COMPANY} dba ${$rootScope.settings.APP_NAME}. The charge will appear on your statement as ${$rootScope.settings.APP_NAME}.`,
                agree2: 'By placing an order you understand that this is a monthly recurring subscription that will auto-renew each month and consent to incurring the monthly subscription fee that will be automatically charged to your payment card each month.',
                agree3: {
                    text: [
                        'I agree to ',
                        ' and '
                    ],
                    link1: { title: 'Terms of Service', href: $rootScope.settings.APP_TERMS_LINK },
                    link2: { title: 'Privacy Policy', href: $rootScope.settings.APP_PRIVACY_LINK }
                }
            },
            footerInfo: 'Secure Checkout',
            requiredMessage: '* Field is required.',
            processing: 'Please wait, this may take a minute',
            errorMessages: {
                required: 'Fields are required',
                payment: 'Must enter valid payment information'
            }
        };

        $scope.appLogo = $rootScope.settings.APP_LOGO;

        $scope.data = {
            cartData: {},
            addressMode: null,
            paymentMode: null,
            form: {
                billingAddress: '',
                billingAddressDummy: '',
                paymentMethod: {
                    name: '',
                    cardNumber: '',
                    cardCode: '',
                    month: '',
                    year: '',
                    formattedCardNumber: '',
                    formattedCardCode: '',
                    formattedMonth: '',
                    formattedYear: '',
                    dataDescriptor: null,
                    dataValue: null
                },
                agree1: false,
                agree2: false,
                agree3: false,
            },
            submitError: {
                show: false,
                message: ''
            }
        };

        $scope.knownErrors = {
            "The 'AnetApi/xml/v1/schema/AnetApiSchema.xsd:cardNumber' element is invalid" : "Card Format is invalid. Please verify your card details and try again.",
            "This transaction has been declined" : "Transaction declined. Please verify your card and billing address details and try again."
        };

        $scope.loading = {
            submit: false
        };

        $scope.cardNumberPattern = /\b[0-9 ]{16,19}\b/;
        $scope.cardCodePattern = /\b[0-9]{3,4}\b/;
        $scope.monthPattern = /\b((0[1-9])|(1[0-2]))\b/;
        $scope.yearPattern = /\b(([[0-9]{2}))\b/;

        $scope.validateYear = function(year) {
            var currentYear = new Date().getFullYear();
            var fullYear = Number(year) + 2000;

            if(fullYear < currentYear) {
                return false;
            }
            return true;
        };

        $scope.submitSuccess = function(alertMessage) {
            swal(alertMessage.title, alertMessage.body, 'success');
            $scope.loading.submit = false;
            $scope.$apply();
            $rootScope.$broadcast('cartModalSuccessClosedEvent');
            $('#cart-modal').modal('hide');
        };

        $scope.submitError = function(message) {
            $scope.data.submitError.message = message;
            $scope.data.submitError.show = true;
            $scope.loading.submit = false;
            $scope.$apply();
        };

        $scope.submitSubscriptionChanges = function(accountReq) {

            accountService.commitSubscriptionChanges(accountReq)
                .then(results => {
                    let commitResult = results || {};
                    if (commitResult.transaction_success) {
                        $scope.submitSuccess({title: 'Payment Complete', body: 'Your account will reflect any changes momentarily.'});
                    }
                    else {
                        $scope.submitError('Your payment method was declined. Please double check your payment details or chat with us for assistance.');
                    }
                })
                .catch(err => {
                    console.log(err);
                    $scope.submitError('Something went wrong.  Please try again or chat with us for assistance.');
                });
        };

        $scope.submitOneTimeCharges = function(accountReq) {
            let cartData = $scope.data.cartData || {};
            accountReq.amount = cartData.total_amount_today;
            accountReq.currency = 'USD';

            accountService.manualCharge(accountReq)
                .then(results => {
                    let commitResult = results || {};
                    if (commitResult.transaction_success) {
                        $scope.submitSuccess({title: 'Payment Complete', body: 'Your account will reflect any changes momentarily.'});
                    }
                    else {
                        $scope.submitError('Your payment method was declined. Please double check your payment details or chat with us for assistance.');
                    }
                })
                .catch(err => {
                    console.log(err);
                    $scope.submitError('Something went wrong. Please try again or chat with us for assistance.');
                });
        };

        $scope.submitForm = function(acceptResponse) {
            let billingAddress = $scope.data.form.billingAddress;
            let paymentMethod = $scope.data.form.paymentMethod;

            let storedBillingAddress = $scope.data.cartData.billing_address || {};

            let trimName = ((paymentMethod|| {}).name || '').trim();
            let nameParts = trimName.split(' ');
            let firstName = (nameParts[0] || '').trim() || storedBillingAddress.first_name || '';
            nameParts.shift();
            let lastName = (nameParts.join(' ')).trim() || storedBillingAddress.last_name || '';

            let cartData = $scope.data.cartData || {};

            let accountReq = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId()
            };

            if (paymentMethod && paymentMethod.month) {
                accountReq.payment_method = {
                    type: "tmp_token",
                    name: trimName || (firstName + ' ' + lastName),
                    expiration_month: paymentMethod.month,
                    expiration_year: paymentMethod.year,
                    token: acceptResponse
                };
            }

            if (billingAddress && billingAddress.Line1) {
                accountReq.billing_address = {
                    first_name: firstName,
                    last_name: lastName,
                    address1: billingAddress.Line1,
                    address2: billingAddress.Line2,
                    city: billingAddress.City,
                    state: billingAddress.ProvinceCode,
                    zip: billingAddress.PostalCode,
                    iso_country: billingAddress.CountryIso2
                };
            }

            var functionName = (cartData.has_recurring_charges) ? 'submitSubscriptionChanges' : 'submitOneTimeCharges';
            $scope[functionName](accountReq);
        };

        $scope.deobfuscateCardErrors = function(error) {
            for (let knownError in $scope.knownErrors) {
                if(error.indexOf(knownError) !== -1) {
                    return $scope.knownErrors[knownError];
                }
            }
            return 'Unable to process transaction. Please verify your card details and try again.';
        };

        $scope.processAcceptResponse = function(response) {

            try {
                let res = response || {};
                let messages = res.messages || {};

                if (messages.resultCode === 'Error') {
                    let errors = '';
                    _.forEach(messages.message, function(errMsg) {
                        if (errors) {
                            errors += '; ';
                        }
                        errors += errMsg.text;
                        console.log(errMsg.code + ': ' + errMsg.text);
                    });
                    $scope.loading.submit = false;

                    $scope.data.submitError.message = $scope.deobfuscateCardErrors(errors);
                    $scope.data.submitError.show = true;
                } else {
                    let data = res.opaqueData || {};
                    $scope.data.form.paymentMethod.dataDescriptor = data.dataDescriptor;
                    $scope.data.form.paymentMethod.dataValue = data.dataValue;

                    $scope.submitForm(data.dataValue);
                }
            } catch (error) {
                console.log(error);
            }
        };

        $scope.tokenizeCreditCardAcceptJs = function() {

            let cartData = $scope.data.cartData || {};
            // let zip = ($scope.data.form.billingAddress || {}).PostalCode || (cartData.billing_address || {}).zip;
            let zip = (($scope.data.form.billingAddress.PostalCode || '').split('-')[0])  || ((cartData.billing_address.zip || '').split('-')[0]);

            let secureData = {
                cardData: {
                    cardNumber: $scope.data.form.paymentMethod.cardNumber,
                    cardCode: $scope.data.form.paymentMethod.cardCode,
                    month: $scope.data.form.paymentMethod.month,
                    year: $scope.data.form.paymentMethod.year,
                    zip: zip,
                    fullName: $scope.data.form.paymentMethod.name,
                },
                authData: {
                    clientKey: $rootScope.settings.ACCEPT_CLIENT_KEY,
                    apiLoginID: $rootScope.settings.ACCEPT_API_LOGIN_ID
                }
            };

            Accept.dispatchData(secureData, $scope.processAcceptResponse);
        };

        $scope.pay = function(e) {
            e.preventDefault();

            if(!$scope.cartForm.$valid || $scope.loading.submit) {
                return;
            }

            $scope.data.form.paymentMethod.cardNumber = $scope.data.form.paymentMethod.formattedCardNumber.split(' ').join('');
            $scope.data.form.paymentMethod.cardCode = $scope.data.form.paymentMethod.formattedCardCode;
            $scope.data.form.paymentMethod.month = $scope.data.form.paymentMethod.formattedMonth;
            $scope.data.form.paymentMethod.year = $scope.data.form.paymentMethod.formattedYear;

            $scope.clearSubmitError();
            $scope.loading.submit = true;
            let cartData = $scope.data.cartData || {};

            // Don't attempt to tokenize card data if we're using stored card or running localhost
            if ((cartData.primary_payment && $scope.data.paymentMode === 'preview') || $rootScope.settings.RUNTIME_ENV === 'local') {
                $scope.submitForm($rootScope.settings.RUNTIME_ENV);
            } else { $scope.tokenizeCreditCardAcceptJs(); }
        };

        $scope.changePrimaryPayment = function() {
            $scope.data.paymentMode = ($scope.data.paymentMode === 'preview') ? 'form' : 'preview';
            $scope.resetForm();
            $scope.clearSubmitError();
        };

        $scope.changeAddress = function() {
            $scope.data.addressMode = ($scope.data.addressMode === 'preview') ? 'form' : 'preview';
            $scope.resetForm();
            $scope.clearSubmitError();
        };

        $scope.setInputMask = function(elemId, mask) {
            $('#' + elemId).mask(mask, {});
        };

        $scope.initLocate = function() {
            // Loqate's pca object is a global var and also injected into the window object.  Make sure it's loaded...
            let __pca = pca || window.pca;
            if (__pca) {
                var options = {
                    key: $rootScope.settings.LOQATE_KEY,
                    setCountryByIP: true
                };
                var displayHtml = '{Line1}, {City} {Province} {PostalCode}, {CountryName}';
                var billingFields = [ { element: 'street-billing-address-payment-form', field: displayHtml } ];
                var billingControl = new __pca.Address(billingFields, options);

                billingControl.listen('populate', function(address, variations) {
                    $scope.data.form.billingAddress = address;
                    $scope.data.form.fullAddressLabel = address.Label;
                    $scope.data.form.postalCode = address.PostalCode;
                    $('#street-billing-address-payment-form').blur();
                });
            }
        };

        $scope.initAddressForm = function() {
            $scope.initLocate();
        };

        $scope.initPaymentForm = function() {
            $scope.setInputMask('ccyear', $scope.staticData.paymentMethod.masks.paymentYearMask);
            $scope.setInputMask('ccmonth', $scope.staticData.paymentMethod.masks.paymentMonthMask);
            $scope.setInputMask('cardnumber', $scope.staticData.paymentMethod.masks.cardNumberMask);
            $scope.setInputMask('cvc', $scope.staticData.paymentMethod.masks.secureCodeMask);
        };

        $scope.resetForm = function() {
            $scope.cartForm.$setPristine();
            $scope.cartForm.$setUntouched();
        };

        $scope.clearSubmitError = function() {
            $scope.data.submitError.message = '';
            $scope.data.submitError.show = false;
        };

        $scope.clearData = function() {
            $scope.data.cartData = {};
            $scope.data.addressMode = null;
            $scope.data.paymentMode = null;

            $scope.data.form.billingAddress = '';
            $scope.data.form.billingAddressDummy = '';
            $scope.data.form.paymentMethod.name = '';
            $scope.data.form.paymentMethod.cardNumber = '';
            $scope.data.form.paymentMethod.cardCode = '';
            $scope.data.form.paymentMethod.month = '';
            $scope.data.form.paymentMethod.year = '';
            $scope.data.form.paymentMethod.formattedCardNumber = '';
            $scope.data.form.paymentMethod.formattedCardCode = '';
            $scope.data.form.paymentMethod.formattedMonth = '';
            $scope.data.form.paymentMethod.formattedYear = '';
            $scope.data.form.paymentMethod.dataDescriptor = null;
            $scope.data.form.paymentMethod.dataValue = null;

            $scope.data.form.agree1 = false;
            $scope.data.form.agree2 = false;
            $scope.data.form.agree3 = false;

            $scope.clearSubmitError();

            $scope.loading.submit = false;

            $scope.resetForm();
        };

        $scope.onInit = function() {
            var cartModal = $('#cart-modal');
            cartModal.on('shown.bs.modal', function() {
                $scope.data.cartData = $rootScope.cart_data || {};

                $scope.data.addressMode = (!$scope.data.cartData.billing_address || $scope.data.cartData.billing_address === null) ? 'form' : 'preview';
                $scope.data.paymentMode = (!$scope.data.cartData.primary_payment || $scope.data.cartData.primary_payment === null) ? 'form' : 'preview';

                $scope.$apply();
            });
            cartModal.on('hide.bs.modal', function() {
                $scope.clearData();
                $rootScope.$broadcast('cartModalClosedEvent');
            });
        };

        $scope.onInit();
    }

    module.exports = CartModalController;
})();
