(function () {
    'use strict';
    function MediaService(apiService, $rootScope) { 
        return {
            listMediaUrls: function (request) {
                return apiService.get($rootScope.settings.API_MEDIA_URL + '/media', request);
            },

            createMedia: function (request) {
                return apiService.post($rootScope.settings.API_MEDIA_URL + '/media', request);
            },

            getMedia: function (request) {
                return apiService.get($rootScope.settings.API_MEDIA_URL + '/media/' + request.media_id, request);
            },

            updateMedia: function (request) {
                return apiService.post($rootScope.settings.API_MEDIA_URL + '/media/'+ request.media_id, request);
            },

            deleteMedia: function (request) {
                return apiService.del($rootScope.settings.API_MEDIA_URL + '/media/'+ request.media_id, request);
            },

            getPolicy: function (request) {
                return apiService.get($rootScope.settings.API_MEDIA_URL + '/media/'+ request.media_id + '/policy', request);
            },

            getUrl: function (request) {
                return apiService.get($rootScope.settings.API_MEDIA_URL + '/media/' + request.media_id + '/url/' + request.ext, request);
            },

            pullUrl: function (request) {
                return apiService.post($rootScope.settings.API_MEDIA_URL + '/media/' + request.media_id + '/url', request);
            }
        };
    }
    module.exports = MediaService;
})();