(function() {
    'use strict';

    function CallTrackingDashController($rootScope, $scope, $location, $interval, authService, phoneService) {
        var vm = this;

        vm.authService = authService;

        $rootScope.viewData = $rootScope.viewData || {};
        $rootScope.viewData.breadcrumbs = $rootScope.viewData.breadcrumbs || [];
        $rootScope.viewData.breadcrumbs = [{
            title: 'Call Tracking',
            link_name: 'Call Tracking',
            link_url: '#!tracking',
            help_url: $rootScope.help_url
        }];

        $scope.staticData = {
            statistic: ['Texts', 'Calls', 'Talk Time', 'Conversions'],
            callsLineChart: {
                title: 'Calls',
                legend: ['Outbound Calls', 'Inbound Calls']
            },
            phoneNumbers: {
                title: 'Phone Numbers',
                subtitle: 'Recent activity by phone number',
                add: {
                    title: 'Add Number',
                    href: '#/phone-number-purchase'
                },
                viewAll: {
                    title: 'View All',
                    href: '#/phone-numbers'
                },
                labels: [
                    'Calls:',
                    'Last Call:',
                    'Messages:',
                    'Last Message:',
                    'Duration:',
                    'Conversions:',
                ]
            },
            callRouting: {
                title: 'Call Routes',
                subtitle: 'Recent activity by call route',
                add: {
                    title: 'Add Route',
                    href: '#/ivr-config'
                },
                viewAll: {
                    title: 'View All',
                    href: '#/call-routing'
                },
                label: 'Phone Numbers:'
            },
            timeAnalysisChart: {
                title: 'Statistics by time',
                colorsCalls: {
                    outbound: '#b5b4b4',
                    inbound: '#ee3843',
                    good_calls: '#ee3843',
                    bad_calls: '#b5b4b4',
                    answered: '#ee3843',
                    not_answered: '#b5b4b4',
                },
                colorsSms: {
                    outbound: '#b5b4b4',
                    inbound: '#009ffb',
                    good_calls: '#009ffb',
                    bad_calls: '#b5b4b4',
                    answered: '#009ffb',
                    not_answered: '#b5b4b4',
                },
                colorsCallRouting: {
                    outbound: '#b5b4b4',
                    inbound: '#ee3843',
                    good_calls: '#ee3843',
                    bad_calls: '#b5b4b4',
                    answered: '#ee3843',
                    not_answered: '#b5b4b4',
                }
            },
            mapCalls: {
                title: 'Calls statistics by region',
                subtitle: 'Select the statistic you want to see',
                legend: {
                    first: 'Outbound Calls',
                    second: 'Inbound Calls',
                    third: 'Good Dispositions',
                    fourth: 'Bad Dispositions',
                    fifth: 'Answer rate'
                },
                colors: {
                    outbound: '#b5b4b4',
                    inbound: '#ff214e',
                    good: '#ff214e',
                    bad: '#b5b4b4',
                    answered: '#ff214e'
                }
            },
            mapSms: {
                title: 'SMS location',
                subtitle: 'Location of your SMS',
                legend: {
                    first: 'Outbound SMS',
                    second: 'Inbound SMS',
                },
                colors: {
                    inbound: '#009ffb',
                    outbound: '#b5b4b4'
                }
            },
            loading: 'Loading',
            noDataMessage: 'No Data',
            noSubMessage: 'No Advanced Tracking Subscription'
        };

        $scope.data = {
            //hasAccess: $rootScope.isAdvancedTrackingPlan(authService.getUser().plan_id) || authService.getUser().team.trial_month,
            hasAccess: true,
            statistic: {},
            lineChartLimit: 30,

            timeAnalysis: {
                chart: {
                    'calls': {},
                    'sms': {},
                    'dispo': {},
                    'phone-numbers': {},
                    'call-routing': {}
                },
                data: {
                    'calls': {},
                    'sms': {},
                    'dispo': {},
                    'phone-numbers': {},
                    'call-routing': {}
                },
                regions: {},
                isEmptyCalls: true,
                isEmptySms: true,
                isEmptyCallRouting: true
            },

            phoneNumbers: {
                list: [],
                selected: null,
                type: 'inbound'
            },

            callRouting: {
                list: [],
                selected: null,
                type: 'inbound'
            },

            mapCalls: {
                type: 'inbound',
                state: '',
                data: {
                    'calls': {}
                }
            },

            mapSms: {
                type: 'inbound',
                state: '',
                data: {
                    'sms': {}
                }
            }
        };

        $scope.charts = {};
        $scope.currentScreenWidth = window.innerWidth;

        $scope.loading = {
            globalStatistics: true,
            callsLineChart: true,
            phoneNumbers: true,
            phoneNumbersTimeChart: true,
            callRouting: true,
            callRouteTimeChart: true,
            callsSmsData: true,
            mapCallsChart: true,
            callsTimeChart: true,
            mapSmsChart: true,
            smsTimeChart: true
        };

        $scope.daysOfTheWeek = {
            1: 'MON',
            2: 'TUE',
            3: 'WED',
            4: 'THU',
            5: 'FRI',
            6: 'SAT',
            7: 'SUN'
        };

        $scope.setScreenWidth = function() {
            $scope.currentScreenWidth = window.innerWidth;
        };

        $scope.$on('hiddenRightSideBar', function () {
            $scope.setScreenWidth();
        });
        $scope.$on('shownRightSideBar', function () {
            $scope.setScreenWidth();
        });

        window.onresize = $scope.setScreenWidth;

        $scope.formatSeconds = function (_totalSeconds) {
            var totalSeconds = parseInt(_totalSeconds || 0);
            let hours   = Math.floor(totalSeconds / 3600);
            let minutes = Math.floor((totalSeconds - (hours * 3600)) / 60);
            let seconds = totalSeconds - (hours * 3600) - (minutes * 60);

            if (hours   < 10 && hours > 0) {hours   = '0' + hours;}
            if (minutes < 10) {minutes = '0' + minutes;}
            if (seconds < 10) {seconds = '0' + seconds;}

            let total = minutes + ':' + seconds;
            if(hours > 0){
                total = hours + ':' + total;
            }

            return total;
        };

        $scope.disposeChart = function(chart) {
            if(!_.isEmpty($scope.charts) && $scope.charts[chart]) {
                $scope.charts[chart].dispose();
            }
        };

        $scope.getRegionalStatistics = function(state, callback) {
            var teamId = authService.getTeamId();
            var userId = authService.getUserId();

            const request = {
                team_id: teamId,
                user_id: userId,
                state: state
            };

            phoneService.getRegionalStatistics(request)
                .then(statistics => {
                    $scope.data.timeAnalysis.regions[state] = statistics;
                    $scope.$apply();
                })
                .catch(err => {
                    console.error(err);
                }).then(()=> {
                    callback();
                });
        };

        $scope.selectState = function(type, state) {
            var typeVarName = type === 'calls' ? 'mapCalls' : 'mapSms';
            var loaderName = type === 'calls' ? 'callsTimeChart' : 'smsTimeChart';

            if(type !== 'sms' && state && state.length > 0) {
                $scope.loading[loaderName] = true;

                $scope.getRegionalStatistics(state, ()=> {
                    $scope.data.timeAnalysis.chart[type] =
                        ($scope.data[typeVarName].type !== 'inbound' && $scope.data[typeVarName].type !== 'outbound')
                            ? $scope.data.timeAnalysis.regions[state].dispo
                            : $scope.data.timeAnalysis.regions[state][type];
                    $scope.loading[loaderName] = false;
                });
            } else {
                $scope.data.timeAnalysis.chart[type] =
                    ($scope.data[typeVarName].type !== 'inbound' && $scope.data[typeVarName].type !== 'outbound')
                        ? $scope.data.timeAnalysis.data.dispo
                        : $scope.data.timeAnalysis.data[type].weekly;
                $scope.loading[loaderName] = false;
            }
        };

        $scope.__buildMapChart = function(dataList, type, loader) {
            if(!dataList || dataList === null) {
                return;
            }

            const chartId = type + '-map-chart';

            // prevent creating chart if html is not ready
            if ($('#'+chartId).length === 0) {
                return;
            }

            $scope.disposeChart(chartId);
            am4core.useTheme(am4themes_animated);

            $scope.charts[chartId] = am4core.create(chartId, am4maps.MapChart);
            $scope.charts[chartId].maxZoomLevel = 64;
            $scope.charts[chartId].geodata = am4geodata_usaLow;

            $scope.charts[chartId].projection = new am4maps.projections.AlbersUsa();
            var typeVarName = type === 'calls' ? 'mapCalls' : 'mapSms';
            $scope.data[typeVarName].state = '';


            let zoomOut = $scope.charts[chartId].tooltipContainer.createChild(am4core.ZoomOutButton);
            zoomOut.align = 'right';
            zoomOut.valign = 'top';
            zoomOut.margin(20, 20, 20, 20);
            zoomOut.events.on('hit', function() {
                if (currentSeries) {
                    currentSeries.hide();
                }
                $scope.charts[chartId].goHome();
                zoomOut.hide();
                currentSeries = regionalSeries.US.series;
                currentSeries.show();
            });
            zoomOut.hide();

            function createSeries(heatfield) {
                let series = $scope.charts[chartId].series.push(new am4maps.MapImageSeries());
                series.dataFields.value = heatfield;

                let template = series.mapImages.template;
                template.verticalCenter = 'middle';
                template.horizontalCenter = 'middle';
                template.propertyFields.latitude = 'lat';
                template.propertyFields.longitude = 'long';

                if ($scope.data.mapCalls.type === 'answered') {
                    template.tooltipText = '{name}:\n[bold]{callPercent} %[/]';
                } else {
                    template.tooltipText = '{name}:\n[bold]{calls} calls[/]';
                }
                if (type === 'sms') {
                    template.tooltipText = '{name}:\n[bold]{calls} sms[/]';
                }

                let circle = template.createChild(am4core.Circle);
                circle.radius = 10;
                circle.fillOpacity = 0.7;
                circle.verticalCenter = 'middle';
                circle.horizontalCenter = 'middle';
                circle.nonScaling = true;

                let label = template.createChild(am4core.Label);
                if ($scope.data.mapCalls.type === 'answered') {
                    label.text = '{callPercent}%';
                } else {
                    label.text = '{calls}';
                }
                label.fill = am4core.color('#fff');
                label.verticalCenter = 'middle';
                label.horizontalCenter = 'middle';
                label.nonScaling = true;

                let heat = series.heatRules.push({
                    target: circle,
                    property: 'radius',
                    min: 10,
                    max: 30
                });

                // Set up drill-down
                series.mapImages.template.events.on('hit', function(ev) {

                    let data = ev.target.dataItem.dataContext;

                    if(data.state){
                        $scope.data[typeVarName].state = `State: ${data.state}`;
                        $scope.selectState(type, data.state);
                    }

                    // Determine what we've clicked on
                    /*let data = ev.target.dataItem.dataContext;

                    // No id? Individual call - nothing to drill down to further
                    if (!data.target) {
                        return;
                    }

                    // Create actual series if it hasn't been yet created
                    if (!regionalSeries[data.target].series) {
                        regionalSeries[data.target].series = createSeries("count");
                        regionalSeries[data.target].series.data = data.markerData;
                    }

                    // Hide current series
                    if (currentSeries) {
                        currentSeries.hide();
                    }

                    // Control zoom
                    if (data.type == "state") {
                        let statePolygon = polygonSeries.getPolygonById("US-" + data.state);
                        $scope.charts[chartId].zoomToMapObject(statePolygon);
                    }
                    else if (data.type == "city") {
                        $scope.charts[chartId].zoomToGeoPoint({
                            latitude: data.lat,
                            longitude: data.long
                        }, 64, true);
                    }
                    zoomOut.show();

                    // Show new targert series
                    currentSeries = regionalSeries[data.target].series;
                    currentSeries.show();*/
                });

                return series;
            }

            // Load data when map polygons are ready
            $scope.charts[chartId].events.on('ready', function (data) {
                // Init country-level series
                regionalSeries.US = {
                    markerData: [],
                    series: createSeries('calls')
                };

                // Set current series
                currentSeries = regionalSeries.US.series;

                // Process data
                am4core.array.each(dataList, function(item) {

                    // Get store data
                    let location = {};

                    if(item.location) {
                        var latLong = item.location.lat_long || [];
                        location.long = am4core.type.toNumber(latLong[0]);
                        location.lat = am4core.type.toNumber(latLong[1]);
                        location.city =  item.location.city;
                        location.state =  item.location.iso_state;
                        location.count = am4core.type.toNumber(1);
                    } else {
                        location.state =  item._id;
                        location.count = item[$scope.data[typeVarName].type];
                    }

                    // Process state-level data
                    if (regionalSeries[location.state] === undefined) {
                        let statePolygon = polygonSeries.getPolygonById('US-' + location.state);
                        if (statePolygon) {

                            // Add state data
                            regionalSeries[location.state] = {
                                target: location.state,
                                type: 'state',
                                name: statePolygon.dataItem.dataContext.name,
                                count: location.count,
                                calls: location.count,
                                callPercent: Math.round((am4core.type.toNumber(location.count) || 0) * 100).toFixed(0),
                                lat: statePolygon.visualLatitude,
                                long: statePolygon.visualLongitude,
                                state: location.state,
                                markerData: []
                            };
                            regionalSeries.US.markerData.push(regionalSeries[location.state]);

                        } else {
                            // State not found
                            return;
                        }
                    } else {
                        regionalSeries[location.state].calls++;
                        regionalSeries[location.state].count += location.count;
                    }

                    // Process city-level data
                    if(location.city) {

                        if (regionalSeries[location.city] === undefined) {
                            regionalSeries[location.city] = {
                                target: location.city,
                                type: 'city',
                                name: location.city,
                                count: location.count,
                                calls: 1,
                                lat: location.lat,
                                long: location.long,
                                state: location.state,
                                markerData: []
                            };
                            regionalSeries[location.state].markerData.push(regionalSeries[location.city]);
                        } else {
                            regionalSeries[location.city].calls++;
                            regionalSeries[location.city].count ++;
                        }

                        // Process individual store
                        regionalSeries[location.city].markerData.push({
                            name: location.location,
                            count: location.count,
                            calls: 1,
                            lat: location.lat,
                            long: location.long,
                            state: location.state
                        });
                    }
                });

                regionalSeries.US.series.data = regionalSeries.US.markerData;
                $scope.loading[loader] = false;
            });

            let polygonSeries = $scope.charts[chartId].series.push(new am4maps.MapPolygonSeries());
            polygonSeries.useGeodata = true;
            polygonSeries.calculateVisualCenter = true;


            let polygonTemplate = polygonSeries.mapPolygons.template;
            polygonTemplate.tooltipText = '{name}';
            polygonTemplate.fill = am4core.color($scope.staticData[typeVarName].colors[$scope.data[typeVarName].type]);

            let regionalSeries = {};
            let currentSeries;
        };

        $scope.selectCallsStatistic = function() {
            $scope.loading.mapCallsChart = true;
            $scope.loading.callsTimeChart = true;

            $scope.__buildMapChart($scope.data.mapCalls.data.calls[$scope.data.mapCalls.type], 'calls', 'mapCallsChart');
            $scope.selectState('calls', $scope.data.mapCalls.state);
        };

        $scope.selectSmsStatistic = function() {
            $scope.loading.mapSmsChart = true;
            $scope.loading.smsTimeChart = true;

            $scope.__buildMapChart($scope.data.mapSms.data.sms[$scope.data.mapSms.type], 'sms', 'mapSmsChart');
            $scope.selectState('sms', $scope.data.mapSms.state);
        };

        $scope.selectPhoneNumber = function(phone) {
            if(!phone || phone === null) {
                $scope.loading.phoneNumbersTimeChart = false;
                return;
            }

            $scope.data.phoneNumbers.selected = phone;
            $scope.loading.phoneNumbersTimeChart = true;

            var teamId = authService.getTeamId();
            var userId = authService.getUserId();

            const request = {
                team_id: teamId,
                user_id: userId,
                phone_number: phone.phone_number,
                limit: $scope.data.lineChartLimit
            };

            phoneService.getNumberTimeStatistics(request)
                .then(stats => {
                    $scope.data.timeAnalysis.chart['phone-numbers'] =
                        ($scope.data.phoneNumbers.type !== 'inbound' && $scope.data.phoneNumbers.type !== 'outbound')
                            ? $scope.data.timeAnalysis.data.dispo
                            : stats.weekly;
                    $scope.loading.phoneNumbersTimeChart = false;
                    $scope.$apply();
                })
                .catch(err => {
                    console.error(err);
                    $scope.loading.phoneNumbersTimeChart = false;
                    $scope.$apply();
                });
        };

        $scope.formatPhoneNumbers = function(numbers) {
            let phoneNumbers = numbers[0].phone_number;
            if (numbers.length > 1) {
                phoneNumbers += ' (+ ' + (numbers.length - 1) + ' more)';
            }
            return phoneNumbers;
        }

        $scope.selectCallRoute = function(ivr) {
            if(!ivr || ivr === null) {
                $scope.loading.callRouteTimeChart = false;
                return;
            }

            $scope.data.callRouting.selected = ivr;
            $scope.loading.callRouteTimeChart = true;

            var teamId = authService.getTeamId();
            var userId = authService.getUserId();

            const request = {
                team_id: teamId,
                user_id: userId,
                ivr_id: ivr.ivr_id,
                limit: $scope.data.lineChartLimit
            };

            phoneService.getCallRoutingTimeStatistics(request)
                .then(stats => {
                    $scope.data.timeAnalysis.chart['call-routing'] =
                        ($scope.data.callRouting.type !== 'inbound' && $scope.data.callRouting.type !== 'outbound')
                            ? $scope.data.timeAnalysis.data.dispo
                            : stats.weekly;
                    $scope.loading.callRouteTimeChart = false;
                    $scope.$apply();
                })
                .catch(err => {
                    console.error(err);
                    $scope.loading.callRouteTimeChart = false;
                    $scope.$apply();
                });
        };

        $scope.__buildCallsLineChart = function(calls, chartId, callback) {
            // prevent creating chart if html is not ready
            if ($('#'+chartId).length === 0) {
                return;
            }
            if(!calls || calls === null || calls.length === 0) {
                callback();
            }

            $scope.disposeChart(chartId);
            am4core.useTheme(am4themes_animated);

            $scope.charts[chartId] = am4core.create(chartId, am4charts.XYChart);
            $scope.charts[chartId].paddingRight = 20;
            $scope.charts[chartId].minHeight = 180;
            $scope.charts[chartId].responsive.enabled = true;

            $scope.charts[chartId].data = _.sortBy(calls, '_id');

            let dateAxis = $scope.charts[chartId].xAxes.push(new am4charts.DateAxis());
            dateAxis.baseInterval = {
                'timeUnit': 'day',
                'count': 1
            };
            dateAxis.tooltipDateFormat = 'yyyy-MM-dd';

            let valueAxis = $scope.charts[chartId].yAxes.push(new am4charts.ValueAxis());
            valueAxis.tooltip.disabled = true;
            valueAxis.title.text = 'Number of calls';

            let series1 = $scope.charts[chartId].series.push(new am4charts.LineSeries());
            series1.dataFields.dateX = '_id';
            series1.dataFields.valueY = 'contact';
            series1.tooltipText = 'Outbound Calls: [bold]{valueY}[/]';
            series1.fillOpacity = 0.3;
            series1.stroke = am4core.color('#b5b4b4');
            series1.fill = am4core.color('#b5b4b4');

            let series2 = $scope.charts[chartId].series.push(new am4charts.LineSeries());
            series2.dataFields.dateX = '_id';
            series2.dataFields.valueY = 'external';
            series2.tooltipText = 'Inbound calls: [bold]{valueY}[/]';
            series2.fillOpacity = 0.3;
            series2.stroke = am4core.color('#ee3843');
            series2.fill = am4core.color('#ee3843');

            $scope.charts[chartId].cursor = new am4charts.XYCursor();
            $scope.charts[chartId].cursor.lineY.opacity = 0;
            $scope.charts[chartId].scrollbarX = new am4charts.XYChartScrollbar();
            $scope.charts[chartId].scrollbarX.series.push(series1);
            $scope.charts[chartId].scrollbarX.series.push(series2);

            dateAxis.start = 0.8;
            dateAxis.keepSelection = true;

            callback();
        };

        $scope.loadLocationStatistics = function() {
            var teamId = authService.getTeamId();
            var userId = authService.getUserId();

            const request = {
                team_id: teamId,
                user_id: userId
            };

            return phoneService.getLocationStatistics(request);
        };

        $scope.loadPhoneNumbers = function() {
            var teamId = authService.getTeamId();
            var userId = authService.getUserId();

            const request = {
                team_id: teamId,
                user_id: userId,
                statistics: true
            };

            return phoneService.getNumbers(request);
        };

        $scope.loadCallRoutings = function() {
            var teamId = authService.getTeamId();
            var userId = authService.getUserId();

            const request = {
                team_id: teamId,
                user_id: userId,
                type: 'voice',
                statistics: true
            };

            return phoneService.getIvrs(request);
        };

        $scope.loadTimeStatistics = function() {
            var teamId = authService.getTeamId();
            var userId = authService.getUserId();

            const request = {
                team_id: teamId,
                user_id: userId,
                limit: $scope.data.lineChartLimit
            };

            return phoneService.getTimeStatistics(request);
        };

        $scope.loadDispositions = function() {
            var teamId = authService.getTeamId();
            var userId = authService.getUserId();

            const request = {
                team_id: teamId,
                user_id: userId
            };

            return phoneService.getDispositionStatistics(request);
        };

        $scope.loadGlobalStatistics = function() {
            var teamId = authService.getTeamId();
            var userId = authService.getUserId();

            const request = {
                team_id: teamId,
                user_id: userId
            };

            return phoneService.getGlobalStatistics(request);
        };

        $scope.disposeCharts = function() {
            $scope.disposeChart('chartist-chart');

            // $scope.disposeChart('phone-numbers-analysis-chart');
            // $scope.disposeChart('phone-numbers-analysis-chart-time');
            // $scope.disposeChart('phone-numbers-analysis-chart-weekday');

            // $scope.disposeChart('call-route-analysis-chart');
            // $scope.disposeChart('call-route-analysis-chart-time');
            // $scope.disposeChart('call-route-analysis-chart-weekday');

            $scope.disposeChart('calls-map-chart');
            $scope.disposeChart('calls-analysis-chart');
            $scope.disposeChart('calls-analysis-chart-time');
            $scope.disposeChart('calls-analysis-chart-weekday');

            $scope.disposeChart('sms-map-chart');
            $scope.disposeChart('sms-analysis-chart');
            $scope.disposeChart('sms-analysis-chart-time');
            $scope.disposeChart('sms-analysis-chart-weekday');
        };

        $scope.onInit = function() {
            $scope.disposeCharts();
            $scope.setScreenWidth();

            Promise.all([
                $scope.loadGlobalStatistics(),
                $scope.loadTimeStatistics(),
                $scope.loadDispositions(),
                $scope.loadPhoneNumbers(),
                $scope.loadCallRoutings(),
                $scope.loadLocationStatistics()
            ]).then((result) => {

                $scope.data.statistic = result[0];
                $scope.loading.globalStatistics = false;

                $scope.data.timeAnalysis.data.calls = result[1].calls;
                $scope.data.timeAnalysis.data.sms = result[1].sms;

                $scope.__buildCallsLineChart($scope.data.timeAnalysis.data.calls.line, 'chartist-chart', () => {
                    $scope.loading.callsLineChart = false;
                    $scope.$apply();
                });

                $scope.data.timeAnalysis.data.dispo = result[2].time;

                $scope.data.phoneNumbers.list = result[3].numbers;
                $scope.loading.phoneNumbers = false;
                $scope.selectPhoneNumber($scope.data.phoneNumbers.list[0]);

                $scope.data.callRouting.list = result[4];
                $scope.loading.callRouting = false;
                $scope.selectCallRoute($scope.data.callRouting.list[0]);

                $scope.data.mapCalls.data.calls = result[5].calls;
                $scope.data.mapSms.data.sms = result[5].sms;
                $scope.loading.callsSmsData = false;

                $scope.selectCallsStatistic();
                $scope.selectSmsStatistic();

                $scope.$apply();
            }).catch(err => {
                console.error(err);
            });
        };

        $scope.$on("$destroy", function () {
            $scope.disposeCharts();
        });

        if (authService.hasAccount()) {
            $scope.onInit();
        }
        else {
            $scope.$on('auth_complete', function(event, args) {
                $scope.onInit();
            });
        }

    }
    module.exports = CallTrackingDashController;
})();
