(function() {
    'use strict';

    function SendRvmModalController($rootScope, $scope, integrationService, phoneService, mediaService, authService) {
        var vm = this;

        $scope.data = {
            recordings: [],
            ivrs: [],
            sending: false,
            modalElementId: '#sendRvm',
            contact_id: null,
            main_phone: null,
        };

        $scope.loading = {
            recordings: true,
            ivrs: true
        };

        $scope.forms = {
            all_valid: false,
            phone_number: {
                selected_item: null,
                is_valid: true
            },
            ivr: {
                selected_item: null,
                is_valid: true
            },
            media: {
                selected_item: null,
                is_valid: true
            },
        }

        $scope.loadRecordings = function() {
            $scope.loading.recordings = true;

            const request = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                reviewed: true,
                media_exists: true
            };

            mediaService.listMediaUrls(request)
               .then(results => {
                console.log(111, results)
                   let mediaList = (results || {}).medias || [];
                   var lists = _.sortBy(mediaList, 'name') || [];
                   $scope.data.recordings = lists;
                   $scope.loading.recordings = false;
                   $scope.$apply();
               })
               .catch(err => {
                   console.error(err);
                   $scope.loading.recordings = false;
                   $scope.$apply();
               });
        };

        $scope.getNumbersUsingIVR = function(teamId, userId) {
            const ivrs = $scope.data.ivrs || [];

            async.each(ivrs, function(ivr, callback) {
                var req = {
                    ivr_id: ivr.ivr_id,
                    team_id: teamId,
                    user_id: userId
                };

                phoneService.getNumbersUsingIvr(req)
                   .then(results => {
                       ivr.phone_numbers = results;
                       callback();
                   }).catch(err => {
                    console.log(err);
                    $scope.loading.ivrs = false;
                    $scope.$apply();
                });
            }, function() {
                $scope.loading.ivrs = false;
                $scope.$apply();
            });
        };

        $scope.loadVoiceIVRs = function() {

            let req = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId()
            };

            $scope.loading.ivrs = true;

            phoneService.getIvrs(req)
               .then(results => {
                   $scope.data.ivrs = _.sortBy(results || [], 'name') || [];
                   $scope.getNumbersUsingIVR(req.team_id, req.user_id);
               })
               .catch(err => {
                   console.log(err);
                   alert('Error: Failed to get call routes.');
                   $scope.loading.ivrs = false;
                   $scope.$apply();
               });
        };

        $scope.getData = () => {
            $scope.loadRecordings();
            $scope.loadVoiceIVRs();
        }

        $scope.$on('showSendRvmModal', function (event, contactId, mainPhone) {
            $scope.data.contact_id = contactId;
            $scope.data.main_phone = mainPhone;
            $($scope.data.modalElementId).modal('show');
        });

        $scope.onInit = () => {
            $($scope.data.modalElementId).on('show.bs.modal', function (e) {
                $scope.getData();
            });
            $($scope.data.modalElementId).on('hide.bs.modal', function (e) {
                $scope.resetForm();
            });
        };

        $scope.resetForm = () => {
            $scope.forms = {
                all_valid: false,
                phone_number: {
                    selected_item: null,
                    is_valid: true
                },
                ivr: {
                    selected_item: null,
                    is_valid: true
                },
                media: {
                    selected_item: null,
                    is_valid: true
                }
            }
        }

        $scope.validateIvr = () => {
            let mediaValid = !!$scope.forms.media.selected_item;
            let ivrValid = ($scope.forms.ivr.selected_item && ($scope.forms.ivr.selected_item.phone_numbers || []).length);
            $scope.forms.ivr.is_valid = ivrValid;
            $scope.forms.all_valid = (mediaValid && ivrValid && $scope.data.contact_id && $scope.data.main_phone);
        }

        $scope.validateMedia = () => {
            let mediaValid = !!$scope.forms.media.selected_item;
            let ivrValid = ($scope.forms.ivr.selected_item && ($scope.forms.ivr.selected_item.phone_numbers || []).length);
            $scope.forms.media.is_valid = mediaValid;
            $scope.forms.all_valid = (mediaValid && ivrValid && $scope.data.contact_id && $scope.data.main_phone);
        }

        $scope.validate = () => {
            let mediaValid = !!$scope.forms.media.selected_item;
            let ivrValid = ($scope.forms.ivr.selected_item && ($scope.forms.ivr.selected_item.phone_numbers || []).length);
            $scope.forms.all_valid = (mediaValid && ivrValid && $scope.data.contact_id && $scope.data.main_phone);
        }

        $scope.sendVoicemail = () => {

            $scope.validate();
            if ($scope.forms.all_valid) {

                let rvmReq = {
                    team_id: authService.getTeamId(),
                    user_id: authService.getUserId(),
                    contact_id: $scope.data.contact_id,
                    phone_number: $scope.data.main_phone,
                    media_id: $scope.forms.media.selected_item.media_id,
                    voice_ivr_id: $scope.forms.ivr.selected_item.ivr_id
                };

                $scope.data.sending = true;
                phoneService.sendRvm(rvmReq)
                   .then(sendRvmResults => {
                       if ((sendRvmResults || {}).success) {
                           $($scope.data.modalElementId).modal('hide');
                           $scope.resetForm();
                       }
                   })
                   .catch(error => {
                       swal("Error", "Something went wrong.", "error");
                   })
                   .finally(() => {
                       $scope.data.sending = false;
                   });
            }
        }

        if (authService.hasAccount()) {
            $scope.onInit();
        }
        else {
            $scope.$on('auth_complete', function(event, args) {
                $scope.onInit();
            });
        }
    }
    module.exports = SendRvmModalController;
})();

