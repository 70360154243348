(function () {
    'use strict';

    function DialingRulesController($rootScope, $scope, $location, userService, authService, phoneService, mediaService, contactService, dispatcherService, notificationToastService) {

        $rootScope.viewData = $rootScope.viewData || {};
        $rootScope.viewData.breadcrumbs = $rootScope.viewData.breadcrumbs || [];
        $rootScope.viewData.breadcrumbs = [{
            title: 'Lists',
            link_name: 'Lists',
            link_url: '#/lists',
            help_url: $rootScope.help_url
        }, {
            title: 'Dialing Rules',
            link_name: 'Dialing Rules',
            link_url: '#/dialing-rules',
            page_data: {
                list_id: $location.search().listId,
            },
        }];

        $scope.staticData = {
            title: 'Default Dialing Rules',
            subtitle: "Configure your dialer's behavior when placing calls...",
            callRouting: {
                title: 'Return Call Route & Caller ID',
                description: 'Select a call route to use for handling return calls and texts. The dialer will select a caller id, with the closest geographic proximity to your contact, from the phone numbers assigned to the route.',
                options: [
                    {
                        title: 'Call Route',
                        options: [
                            {
                                title: 'Return Call Routing',
                                placeholder: 'Please Select...'
                            }
                        ]
                    }
                ]
            },
            dispositions: {
                title: 'Dispositions',
                description: 'Configure the expected outcomes from your calls...',
                inputPlecaholder: 'Label'
            },
            announcements: {
                title: 'Announcements',
                description: 'Know which contact you are calling by announcing the contact data field...',
                options: [ 'None', 'First/Last', 'Company', 'Address' ]
            },
            voicemailDrop: {
                title: 'Voicemail Drop',
                description: 'The recording to play to a contact when you hear the answering machine beep.  If you select more than one recording, we will randomly select from the list you designate here.',
                multiselectPlaceholder: 'Recordings'
            },
            predictive: {
                title: 'Call Abandonment',
                description: 'When abandoning a call, randomly play a recording from the list you designate here. Your recorded message is regulated by TCPA rules, your contact may opt-out by pressing any key.',
                multiselectPlaceholder: 'Abandon Recording',
                defaultRecording: {
                    title: 'Default Recording',
                    text: '"We\'re sorry, we missed you. We\'ll try back later. You may hang up now. If you no longer wish to receive our calls, press any key."'
                }
            },
            call_recording: {
                title: 'Premium Features',
                titleDetails: '(Requires a usage plan.  Usage deducts from your prepaid account balance.)',
                subtitle: 'Record and transcribe your calls ',
                description: 'Record and transcribe calls for users that purchased the premium version of the dialer license.  Recorded calls are saved for 12 months and the content of the calls is searchable within the portal.',
            },
            advanced: {
                title: 'Advanced',
                subtitle: 'Advanced dialer configuration is only recommended for expert users...',
                description: 'Changing these values may impact your regulatory compliance.',
                options: [
                    {
                        title: 'Fields to Dial',
                        multiselectPlaceholder: 'Please Select...'
                    },
                    {
                        title: 'Callable Hours',
                        spanStart: 'start',
                        spanEnd: 'end'
                    },
                    {
                        title: 'Minimum Time Between Calls (hours)',
                        inputPlaceholder: 'Label',
                        min: 12,
                        max: 720
                    },
                    {
                        title: 'Give Up After (# of calls)',
                        inputPlaceholder: 'Label',
                        min: 1,
                        max: 3
                    },
                    {
                        title: 'Record & Transcribe Calls'
                    },
                    {
                        title: 'Supervise Calls'
                    },
                    {
                        title: 'Prompt for Notes'
                    },
                    {
                        title: 'Predictive Line Count',
                        inputPlaceholder: 'Label',
                        options: [1, 2, 3]
                    }
                ]
            },
            saveBtn: 'Save',
            loading: 'Loading'
        };

        $scope.recordingMultiselectDefaultSettings = {
            idProperty: 'media_id',
            displayProp: 'name',
            template: '<p>{{option.name}}</p>',
            enableSearch: true,
            searchField: 'name',
            selectedToTop: true,
            styleActive: true,
            dynamicTitle: false
        };
        $scope.multiselectDefaultCustomTexts = {
            checkAll: 'Select All',
            uncheckAll: 'Unselect All',
            buttonDefaultText: $scope.staticData.loading + '...'
        };
        
        $scope.voicemailDropMultiselectCustomTexts = $scope.multiselectDefaultCustomTexts;
        $scope.predictiveMultiselectCustomTexts = $scope.multiselectDefaultCustomTexts;

        $scope.fieldsToDialMultiselectSettings = {
            template: '<p>{{option}}</p>',
            enableSearch: false,
            showCheckAll: true,
            showUncheckAll: true,
            selectedToTop: true,
            styleActive: true,
            smartButtonMaxItems: 2,
            smartButtonTextConverter(skip, option) { return option; }
        };
        $scope.fieldsToDialMultiselectCustomTexts = {
            checkAll: 'Select All',
            uncheckAll: 'Unselect All',
            buttonDefaultText: 'Please select...'
        };

        $scope.data = {
            recordings: [],
            ivrs: [],
            visibleAdvancedBlock: false,
            list_id: $location.search().listId || null
        };

        $scope.loading = {
            recordings: true,
            ivrs: true,
            ivrsPhoneNumbers: true,
            dispatcher: true
        };

        $scope.saving = {
            dispatcher: false
        };

        $scope.availableDispositions = [
            {
                id: 'redial', // Redial the contact
                friendly: 'Redial',
                label: 'REDIAL',
                sentiment: 'neutral'
            },
           {
                id: 'good', // Contact is interested, leave the contact in the dial rotation to try again later
                friendly: 'Good',
                label: 'GOOD',
                sentiment: 'good'
            },
            {
                id: 'sold', // Contact was sold, remove the contact from the dial rotation
                friendly: 'Sold',
                label: 'SOLD',
                sentiment: 'good'
            },
           {
                id: 'follow_up', // Contact is interested but wants a follow-up call later (in a week)
                friendly: 'Follow Up',
                label: 'FOLLOW UP',
                sentiment: 'neutral'
            },
            {
                id: 'no_contact',  // Contact wasn't available, leave the contact in the call rotation
                friendly: 'No Contact',
                label: 'NO CONTACT',
                sentiment: 'neutral'
            },
            {
                id: 'no_interest',  // Contact wasn't interested, remove the contact from the dial rotation
                friendly: 'No Interest',
                label: 'NO INTRST',
                sentiment: 'bad'
            },
            {
                id: 'bad_lead',  // The lead data for the contact is bad, remove the contact from the dial rotation
                friendly: 'Bad Lead',
                label: 'BAD LEAD',
                sentiment: 'bad'
            },
            {
                id: 'dnc',  // Add the contact to the DNC and remove them from the call rotation
                friendly: 'DNC',
                label: 'DNC',
                sentiment: 'bad'
            },
            {
                id: 'custom_1',
                friendly: 'Custom 1',
                label: '',
                sentiment: null,
                custom: true
            },
            {
                id: 'custom_2',
                friendly: 'Custom 2',
                label: '',
                sentiment: null,
                custom: true
            },
            {
                id: 'custom_3',
                friendly: 'Custom 3',
                label: '',
                sentiment: null,
                custom: true
            },
            {
                id: 'custom_4',
                friendly: 'Custom 4',
                label: '',
                sentiment: null,
                custom: true
            },
            {
                id: 'custom_5',
                friendly: 'Custom 5',
                label: '',
                sentiment: null,
                custom: true
            },
            {
                id: 'custom_6',
                friendly: 'Custom 6',
                label: '',
                sentiment: null,
                custom: true
            },
            {
                id: 'custom_7',
                friendly: 'Custom 7',
                label: '',
                sentiment: null,
                custom: true
            },
            {
                id: 'custom_8',
                friendly: 'Custom 8',
                label: '',
                sentiment: null,
                custom: true
            },
            {
                id: 'custom_9',
                friendly: 'Custom 9',
                label: '',
                sentiment: null,
                custom: true
            }
        ];

        $scope.dispatcher = Object.assign({}, $rootScope.dispatcher_template);

        $scope.multiselectSelectionChanged = function (dropdownToggleSelector, dispatcherSelected) {
            let title = '';
            let dropdownToggle = $(dropdownToggleSelector);

            if($scope.loading.dispatcher || $scope.loading.recordings) {
                title = $scope.staticData.loading + '...';
            } else if($scope.dispatcher[dispatcherSelected].length === 0) {
                title = 'Please select...';
            } else {
                title = $scope.dispatcher[dispatcherSelected].length + ' selected';
            }

            dropdownToggle.text(title);
        };

        $scope.voicemailDropMultiselectEvents = {
            onSelectionChanged: function () {
                $scope.multiselectSelectionChanged('#voicemail-drop-multiselect .dropdown-toggle', 'voicemail_ids');
            }
        };

        $scope.predictiveMultiselectEvents = {
            onSelectionChanged: function () {
                $scope.multiselectSelectionChanged('#predictive-multiselect .dropdown-toggle', 'abandon_ids');
            }
        };

        $scope.toggleAdvancedBlock = function() {
            if(!$scope.loading.dispatcher) {
                let offsetHeight = document.getElementById('advanced').offsetHeight;
                let advancedBlock = document.getElementById('advanced-content-block');

                advancedBlock.style.height = (!$scope.data.visibleAdvancedBlock) ? offsetHeight + 'px' : '';
                advancedBlock.style.overflow = (!$scope.data.visibleAdvancedBlock) ? 'visible' : 'hidden';
                $scope.data.visibleAdvancedBlock = !$scope.data.visibleAdvancedBlock;
            }
        };

        $scope.setVoiceIVR = function(voice) {
            $scope.dispatcher.ivr.voice = voice.ivr_id;
        };

        $scope.setSentiment = function(index, sentiment) {
            $scope.dispatcher.dispositions[index].sentiment = sentiment;
        };

        $scope.setMaxLineCount = function(option) {
            $scope.dispatcher.max_line_count = option;
        };

        $scope.setDisposition = function(oldDisposition, disposition) {
            let replaceIndex = null;
            for (let index in $scope.dispatcher.dispositions){
                if($scope.dispatcher.dispositions[index].id === oldDisposition.id){
                    replaceIndex = index;
                    break;
                }
            }
            $scope.dispatcher.dispositions[replaceIndex] = disposition;
        };

        $scope.voiceIVRdropdownTitle = function() {
            return _.find($scope.data.ivrs, function(o) { return $scope.dispatcher.ivr.voice === o.ivr_id; });
        };

        $scope.voiceIVRValidation = function() {
            let selectedIvr = _.find($scope.data.ivrs, function(o) { return $scope.dispatcher.ivr.voice === o.ivr_id; });
            return (((selectedIvr || {}).phone_numbers || []).length > 0);
        };

        $scope.announcementsChecked = function(value) {
            return _.includes($scope.dispatcher.announce, value);
        };

        $scope.setAnnouncements = function(option) {
            $scope.dispatcher.announce = option;
        };

        $scope.recordCallsOnSwitchChange = function() {
            // check if user allowed to use feature
            if (!$rootScope.isBalance() && $scope.dispatcher.record_calls) {

                //modal mode: 'default',
                //active tab: 'call_recording'
                $rootScope.showUpsellModal('default', 'call_recording', false);
            }
        };

        $scope.saveDispatcherData = function() {
            $scope.saving.dispatcher = true;

            let dispatcherData = angular.copy($scope.dispatcher);
            dispatcherData.minimum_time_between_dials = dispatcherData.minimum_time_between_dials*3600;
            dispatcherData.voicemail_ids = _.map(dispatcherData.voicemail_ids, 'media_id');
            dispatcherData.abandon_ids = _.map(dispatcherData.abandon_ids, 'media_id');

            const request = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                list_id: $scope.data.list_id || 'default',
                dispatcher_data: dispatcherData
            };

            dispatcherService.updateDispatcher(request)
                .then(results => {
                    notificationToastService.showSuccessToast('fa-light fa-circle-check', 'Dialing rules configured.');
                })
                .catch(err => {
                    console.error(err);
                    notificationToastService.showErrorToast('fa-light fa-triangle-exclamation', 'Something went wrong. Try again later.');
                })
                .then(() => {
                    $scope.saving.dispatcher = false;
                    $scope.$apply();
                });
        };

        $scope.loadRecordings = function() {

            const request = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                include_urls: true
            };

            return mediaService.listMediaUrls(request);
        };

        $scope.getDispatcherData = function() {

            const request = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                list_id: $scope.data.list_id || 'default'
            };

            return dispatcherService.getDispatcherData(request);
        };

        $scope.getNumbersUsingIVR = function() {
            $scope.loading.ivrsPhoneNumbers = true;

            let ivrs = $scope.data.ivrs || [];
            const teamId = authService.getTeamId();
            const userId = authService.getUserId();

            async.each(ivrs, function(ivr, callback) {
                let req = {
                    ivr_id: ivr.ivr_id,
                    team_id: teamId,
                    user_id: userId
                };

                phoneService.getNumbersUsingIvr(req)
                    .then(results => {
                        ivr.phone_numbers = results || [];
                        callback();
                    });
            }, function() {
                $scope.loading.ivrsPhoneNumbers = false;
                $scope.$apply();
            });
        };

        $scope.loadVoiceIVRs = function() {

            let req = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                type: 'voice'
            };

            return phoneService.getIvrs(req);
        };

        $scope.onInit = function() {

            Promise.all([
                $scope.loadVoiceIVRs(),
                $scope.loadRecordings(),
                $scope.getDispatcherData(),
            ])
            .then((results) => {

                $scope.data.ivrs = _.sortBy(results[0] || [], 'name') || [];
                $scope.getNumbersUsingIVR();

                let recordings = (results[1] && results[1].medias) ? results[1].medias : [];
                $scope.data.recordings = _.map(recordings, function(recording) {
                    recording.name = (!recording.name || recording.name.length === 0) ? 'Recording' : recording.name;
                    return recording;
                });

                let dispatcher = results[2];
                if (typeof dispatcher.dispatcher_data.callable_hours.start_time !== 'number') {
                    dispatcher.dispatcher_data.callable_hours.start_time = $rootScope.dispatcher_template.callable_hours.start_time;
                }
                if (typeof dispatcher.dispatcher_data.callable_hours.end_time !== 'number') {
                    dispatcher.dispatcher_data.callable_hours.end_time = $rootScope.dispatcher_template.callable_hours.end_time;
                }
                dispatcher.dispatcher_data.minimum_time_between_dials = Math.round(dispatcher.dispatcher_data.minimum_time_between_dials/3600);
                $scope.data.dispatcher_id = dispatcher.dispatcher_id;

                dispatcher.dispatcher_data.voicemail_ids = _.map(dispatcher.dispatcher_data.voicemail_ids, function(id) {
                    return { media_id: id };
                });
                dispatcher.dispatcher_data.abandon_ids = _.map(dispatcher.dispatcher_data.abandon_ids, function(id) {
                    return { media_id: id };
                });

                $scope.dispatcher = dispatcher.dispatcher_data;

            })
            .catch(err => {
                console.log(err);
            })
            .then(() => {
                $scope.loading.ivrs = false;
                $scope.loading.recordings = false;
                $scope.loading.dispatcher = false;

                $scope.multiselectSelectionChanged('#voicemail-drop-multiselect .dropdown-toggle', 'voicemail_ids');
                $scope.multiselectSelectionChanged('#predictive-multiselect .dropdown-toggle', 'abandon_ids');

                $scope.$apply();
            });
        };

        if (authService.hasAccount()) {
            $scope.onInit();
        }
        else {
            $scope.$on('auth_complete', function(event, args) {
                $scope.onInit();
            });
        }

    }
    module.exports = DialingRulesController;
})();
