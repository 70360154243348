(function () {

    'use strict';
    function IntegrationService (authService, apiService, $rootScope) {
        return {
            getTags: (request) => {
                const req = Object.assign({}, {
                    team_id: authService.getTeamId(),
                    user_id: authService.getUserId(),
                }, request);
                return apiService.get($rootScope.settings.API_TAGS_URL + '/tags', req);
            },
            createTag: (request) => {
                return apiService.post($rootScope.settings.API_TAGS_URL + '/tags', request);
            },
            updateTag: (request) => {
                const req = Object.assign({}, {
                    team_id: authService.getTeamId(),
                    user_id: authService.getUserId(),
                }, request);
                return apiService.put($rootScope.settings.API_TAGS_URL + `/tags/${request.tag_id}`, req);
            },
            deleteTag: (request) => {
                const req = Object.assign({}, {
                    team_id: authService.getTeamId(),
                    user_id: authService.getUserId(),
                }, request);
                return apiService.del($rootScope.settings.API_TAGS_URL + `/tags/${request.tag_id}`, req);
            },
        }
    }

    module.exports = IntegrationService;
})();




