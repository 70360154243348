(function() {
    'use strict';

    function FooterController(authService, $rootScope, $scope, $window) {
        var vm = this;
        vm.authService = authService;

        $scope.appName = $rootScope.settings.APP_NAME;
        
        $scope.currentYear = new Date().getFullYear();
        $(function () {
            $(".preloader").fadeOut();
        });
    }
    module.exports = FooterController;
})();
