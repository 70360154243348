(function() {
    'use strict';

    function TextMessagesController(authService, phoneService, $rootScope, $scope, $location, $window, $interval, $cookies) {
        var vm = this;
        vm.authService = authService;

        $rootScope.viewData = $rootScope.viewData || {};
        $rootScope.viewData.breadcrumbs = $rootScope.viewData.breadcrumbs || [];
        $rootScope.viewData.breadcrumbs = [{
            title: 'Text Messages',
            link_name: 'Text Messages',
            link_url: '#/text-messages',
            help_url: $rootScope.help_url
        }];

        $scope.staticContent = {
            title: 'Recent Messages',
            subtitle: 'Check out your recent messages',
            loading: 'Loading',
            searchInputPlaceholder: 'Search...',
            headerMenu: [ 'Export', 'Processing', 'Download Available' ],
            views: ['Default', 'New', 'Hidden', 'All'],
            dispositions: {
                good: [ 'good', 'sold' ],
                info: [ 'follow up', 'no contact', 'no answer', 'busy', 'abandoned' ],
                bad: [ 'not interested', 'bad lead', 'dnc', 'bad' ]
            },
            infoMessages: {
                outcome: {
                    good: 'Good',
                    bad: 'Bad',
                    info: 'Follow-up'
                }
            },
            smsMenu: ['Dial', 'Reply', 'View Contact', 'Unhide Sms', 'Hide Sms'],
            noRes: 'No Results'
        };
    
        $('#textMessagesReportrange').on('apply.daterangepicker', function(ev, picker) {
            $scope.minTs = picker.startDate._d.getTime();
            $scope.maxTs = picker.endDate._d.getTime();
            $('#textMessagesReportrange span').html(picker.startDate.format('MMM D, YYYY') + ' - ' + picker.endDate.format('MMM D, YYYY'));
            $scope.loadSms();
            console.log(picker.startDate.format("MMMM DD, YYYY"));
            console.log(picker.endDate.format("MMMM DD, YYYY"));
        });

        $scope.sms = [];
        $scope.loading = {
            sms: true
        };
        $scope.search = {
            term: '',
            submit: false
        };
        $scope.selectedListId = $location.search().listId;

        $scope.export = {
            id: null,
            status: null,
            link: null
        };

        $scope.forms = {
            sms: {
                to: '',
                from: '',
                body: '',
                campaign_id: null,
                action: 'Say',
                compliance: false,
                phone_selected: null,
                is_valid: undefined,
                submited: false,
                sending: false
            },
            call: {
                to: '',
                from: '',
                body: '',
                compliance: false,
                phone_selected: null,
                is_valid: undefined,
                submited: false,
                calling: false
            }
        };

        $scope.rented_numbers = null;
        $scope.view = 'Default';

        $scope.currentPage = 1;
        $scope.itemsPerPage = 7;
        $scope.maxSize = 3; 

        $scope.setPage = function (pageNo) {
            $scope.currentPage = pageNo;
        };

        $scope.pageChanged = function() {
            $window.scrollTo(0, 0);
            $scope.loadSms();
        };

        $scope.setItemsPerPage = function(num) {
            $scope.itemsPerPage = num;
            $scope.currentPage = 1; //reset to first page
        };

        $scope.viewContactDetails = function(sms) {
            $location.path('/contacts/detail').search('id=' + sms.contact_id);
        };

        $scope.loadNumbers = function() {

            if(!$scope.rented_numbers){
                $scope.rented_numbers = $rootScope.team_phone_numbers.map(number => {return number.phone_number});
                $scope.setSelectedPhone($scope.rented_numbers[0], 'sms');
                $scope.setSelectedPhone($scope.rented_numbers[0], 'call');
            }

            if(!$scope.forms.user_numbers){
                $scope.forms.user_numbers = authService.getUserNumbers();
            }
        };

        $scope.textContact = function(text) {
            $rootScope.openTextArea(text);
        };

        $scope.callContact = function(text) {
            $rootScope.$broadcast('openCallTab', text);
        };

        $scope.setPhoneMask = function(elem, mask, placeholder) {
            elem.mask(mask, {
                placeholder: placeholder
            });
        };

        $scope.modalPhoneNumberMask = function(number, type) {
            var phoneNumberId = $("#" + type + "ToPhoneNumber");
            var phoneNumberIdInput = document.querySelector("#" + type + "ToPhoneNumber");
            const typeIti = type === 'call' ? 'addCallIti' : 'addSmsIti';

            if(phoneNumberId) {
                $scope[typeIti] = window.intlTelInput(phoneNumberIdInput, {
                    nationalMode: "false",
                    autoPlaceholder: 'aggressive',
                    separateDialCode: "true",
                    initialCountry: 'us',
                    utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/11.0.11/js/utils.js" ,
                    customPlaceholder: function(selectedCountryPlaceholder, selectedCountryData) {
                        var pl = selectedCountryPlaceholder.replace(/[0-9]/g, '0');
                        phoneNumberId.attr('maxlength', pl.length);
                        $scope.setPhoneMask(phoneNumberId, pl, pl);
                        return pl;
                    }
                });

                if(number){
                    $scope[typeIti].setNumber(number);
                }

                phoneNumberId.on("countrychange", function(e) {
                    $scope[typeIti].setNumber("");
                    phoneNumberId.unmask();

                    var mask = e.target.placeholder.replace(/[0-9]/g, '0');
                    phoneNumberId.attr('maxlength', mask.length);
                    $scope.setPhoneMask(phoneNumberId, mask, e.target.placeholder);
                });
            }
        };

        $scope.clearIti = function(type) {
            const typeIti = type === 'call' ? 'addCallIti' : 'addSmsIti';
            $scope.forms[type].to_is_valid_phone_number = undefined;
            $scope[typeIti].setNumber("");
            $("#" + type + "ToPhoneNumber").unmask();
            $scope[typeIti].destroy();
        };

        $scope.formatPhone = function(type) {
            const typeIti = type === 'call' ? 'addCallIti' : 'addSmsIti';
            if($scope[typeIti]) {
                $scope.forms[type].to_is_valid_phone_number = $scope[typeIti].isValidNumber();
                $scope.forms[type].to = ($scope.forms[type].to_is_valid_phone_number) ? $scope[typeIti].getNumber(window.intlTelInput.utils.numberFormat.E164) : '';
                $scope.validateFields(type);
            }
        };

        $scope.validateFields = function(type) {
            $scope.forms[type].is_valid = !!($scope.forms[type].to_is_valid_phone_number && $scope.forms[type].from && $scope.forms[type].compliance);
        };

        $scope.callContactClear = function() {

            if($scope.addCallIti) {
                $scope.clearIti('call');
            }

            $scope.forms.call = {
                compliance: false,
                is_valid: undefined,
                submited: false,
                calling: false
            };
        };

        $scope.setSelectedPhone = function(value, type) {
            if (value) {
                $scope.forms[type].phone_selected = value;
                $scope.forms[type].from = value;
            }
            $scope.validateFields(type);
        };

        $scope.setHidden = function(sms, index) {

            let req = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                sms_id: sms.sms_id,
                hidden: !sms.hidden
            };

            $scope.loading.sms = true;

            phoneService.updateSms(req)
                .then(results => {
                    //swal("Success", "Text " + (!sms.hidden ? 'hidden' : 'unhidden'), "success");
                    $scope.sms.splice(index, 1);
                    $scope.loading.sms = false;
                    $scope.$apply();
                })
                .catch(err => {
                    swal("Cancelled", "Unable to " + (!sms.hidden ? 'hide text' : 'unhide text'), "error");
                    $scope.loading.sms = false;
                });
        };

        $scope.sendSms = function() {

            let req = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                phone_number: $scope.forms.sms.to,
                caller_id: $scope.forms.sms.from,
                list_id: $scope.selectedListId,
                contact_id: $scope.forms.sms.contact_id,
                sms_body: $scope.forms.sms.body,
                action: 'Say'
            };

            phoneService.sendSms(req)
                .then(results => {
                    swal("Success", "Text message sent.", "success");
                    $scope.forms.sms.sending = false;
                    $("#smsModal").modal('hide');
                })
                .catch(err => {
                    swal("Cancelled", "Unable to send text message.\nMessages to this contact may be blocked.", "error");
                    $scope.forms.sms.sending = false;
                    $("#smsModal").modal('hide');
                });
        };

        $scope.contactCall = function() {

            let req = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                contact_id: $scope.forms.call.contact_id,
                user_phone_number: $scope.forms.user_phone_number,
                caller_id: $scope.forms.call.from,
            };

            $cookies.put('userPhoneNumber', $scope.forms.call.to);

            phoneService.callContact(req)
                .then(results => {
                    swal("Success", "We're calling you", "success");
                    $scope.forms.call.sending = false;
                    $("#callModal").modal('hide');
                })
                .catch(err => {
                    swal("Cancelled", "Unable to call contact.\nCalls to this contact may be blocked.", "error");
                    $scope.forms.call.sending = false;
                    $("#callModal").modal('hide');
                });
        };

        $scope.clearSearchSms = function() {
            $scope.search.submit = false;
            $scope.search.term = '';
            $scope.loadSms();
        };

        $scope.searchSms = function(view) {
            $scope.search.term = $.trim($scope.search.term);
            if($scope.search.term !== '') {
                $scope.search.submit = true;
                $scope.loadSms();
            } else if($scope.search.submit) {
                $scope.clearSearchSms();
            }
        };

        $scope.filterSms = function(view) {
            if(view !== $scope.view) {
                $scope.view = view;
                $scope.loadSms();
            }
        };

        $scope.__getViewParams = function () {

            switch($scope.view) {
                case 'New':
                    return {
                        only_new: true
                    };
                case 'Hidden':
                    return {
                        hidden: true
                    };
                case 'All':
                    return {
                        all: true
                    };
                case 'Default':
                default:
                    return {};
            }
        };

        $scope.loadSms = function() {
            $scope.loading.sms = true;
            $window.scrollTo(0, 0);
            var teamId = authService.getTeamId();
            var userId = authService.getUserId();

            const request = {
                team_id: teamId,
                user_id: userId,
                names: true,
                minTs: $scope.minTs,
                maxTs: $scope.maxTs,
                skip: ($scope.currentPage - 1) * $scope.itemsPerPage,
                limit: $scope.itemsPerPage,
                include_total: true
            };

            if($scope.contactId){
                request.contact_id =  $scope.contactId;
            }

            if($scope.view) {
                $.extend(request, $scope.__getViewParams());
            }

            if($scope.search.term){
                request.search_term =  $scope.search.term;
            }

            phoneService.getSms(request)
                .then(smss => {
                    $scope.totalItems = smss.total;
                    $scope.sms = smss.smss;

                    $scope.sms.map(sms => {
                        sms.contact_number = sms.sms_type === 'outbound' ? sms.to : sms.from;
                        sms.contact_name = (sms.first_name || sms.last_name) ? ((sms.first_name || '') + ' ' + (sms.last_name || '')).trim() : null;
                    });

                    $scope.loading.sms = false;
                    $scope.$apply();
                    $rootScope.$broadcast('sms_seen');
                })
                .catch(err => {
                    console.error(err);
                    $scope.loading.sms = false;
                    $scope.$apply();
                });
        };

        $scope.formatDate = function(date) {
            return new Date(date).toLocaleDateString();
        };

        $scope.hasPermissions = function(entity, action) {
            return $rootScope.hasPermissions(entity, action)
        };

        $scope.exportSms = function() {
            var teamId = authService.getTeamId();
            var userId = authService.getUserId();

            const request = {
                team_id: teamId,
                user_id: userId,
                minTs: $scope.minTs,
                maxTs: $scope.maxTs
            };

            if($scope.contactId){
                request.contact_id =  $scope.contactId;
            }

            if($scope.search.term){
                request.search_term =  $scope.search.term;
            }

            if($scope.view) {
                $.extend(request, $scope.__getViewParams());
            }

            phoneService.exportSms(request)
                .then(result => {
                    $scope.export.id = result.export_id;
                    $scope.export.status = result.status;
                    $scope.$apply();
                })
                .catch(err => {
                    console.error(err);
                    $scope.$apply();
                });
        };

        $scope.__checkExportStatus = function() {

            if ($scope.export.id && $scope.export.status === "pending") {

                const request = {
                    team_id: authService.getTeamId(),
                    user_id: authService.getUserId(),
                    export_id: $scope.export.id
                };

                phoneService.getExportStatus(request)
                    .then(result => {
                        $scope.export.status = result.status;
                        $scope.export.link = result.link;
                        if($scope.export.status === 'done'){
                            window.open($scope.export.link, '_self');
                            setTimeout(()=>{
                                $scope.export.status = undefined;
                            }, 100);
                        }
                        $scope.$apply();
                    })
                    .catch(err => {
                        console.error(err);
                        $scope.$apply();
                    });
            }
        };

        $scope.initDateRange = function() {
            var start = moment().subtract(7, 'days');
            var end = moment();

            function cb() {
                $('#textMessagesReportrange span').html(start.format('MMM D, YYYY') + ' - ' + end.format('MMM D, YYYY'));
                $scope.minTs = start.valueOf();
                $scope.maxTs = end.valueOf();
            }

            $('#textMessagesReportrange').daterangepicker({
                startDate: start,
                endDate: end,
                opens: 'left',
                ranges: {
                    'Today': [moment().startOf('day'), moment().endOf('day')],
                    'Yesterday': [moment().subtract(1, 'days').startOf('day'), moment().startOf('day')-1],
                    'Last 7 Days': [moment().subtract(6, 'days').startOf('day'), moment().endOf('day')]
                }
            }, cb);

            cb();
        };

        $scope.onInit = function() {
            $scope.contactId = $location.search().contactId;
            $scope.initDateRange();
            $scope.loadSms();
        };

        $scope.exportTime = $interval($scope.__checkExportStatus, 5000);

        if (authService.hasAccount()) {
            $scope.onInit();
        }
        else {
            $scope.$on('auth_complete', function(event, args) {
                $scope.onInit();
            });
        }
    }
    module.exports = TextMessagesController;
})();
