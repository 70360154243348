(function () {
    'use strict';

    function FaxesController(authService, faxService, $rootScope, $scope, $window, $location) {

        var vm = this;
        vm.authService = authService;

        $rootScope.viewData = $rootScope.viewData || {};
        $rootScope.viewData.breadcrumbs = $rootScope.viewData.breadcrumbs || [];
        $rootScope.viewData.breadcrumbs = [{
            title: 'Faxes',
            link_name: 'Faxes',
            link_url: '#/faxes',
            help_url: $rootScope.help_url
        }];

        $scope.staticContent = {
            title: 'Faxes',
            subtitle: 'Check out your recent faxes',
            sendFaxButton: 'Send a Fax',
            searchInputPlaceholder: 'Search...',
            faxMenu: [ 'Dial', 'Send Text', 'View Contact', 'Download Document', 'Unhide Fax', 'Hide Fax', 'Resend Fax' ],
            views: ['Visible', 'Hidden', 'All'],
            loading: 'Loading',
            noRes: 'No Results'
        };

        $scope.loading = {
            faxes: true
        };
        $scope.data = {
            faxes: []
        };
        $scope.search = {
            term: '',
            submit: false,
            view: 'Visible',
            sortBy: 'created_at',
            minTs: 0,
            maxTs: 0
        };

        $scope.totalItems = 0;
        $scope.currentPage = 1;
        $scope.itemsPerPage = 8;
        $scope.maxSize = 3;

        $scope.showFaxModal = function(fax) {
            $rootScope.showFaxModal({
                fax: fax,
                callback: function() { $scope.loadFaxes(); }
            });
        };

        $scope.textContact = function(fax) {
            $rootScope.openTextArea(fax.contact);
        };

        $scope.callContact = function(fax) {
            $rootScope.$broadcast('openCallTab', fax.contact);
        };

        $scope.viewContact = function(fax) {
            $location.path('/contacts/detail').search('id=' + fax.contact_id);
        };

        $scope.viewDocument = function(fax) {

            const request = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                document_id: fax.document_id
            };

            faxService.getDocumentUrl(request)
                .then(result => {
                    if (!result || !result.url) { throw new Error(); }
                    window.open(result.url, '_blank');
                })
                .catch(err => {
                    swal("Error", "The document could not be retrieved.", "error");
                });
        };

        $scope.setHidden = function(fax, index) {
            $scope.loading.faxes = true;

            const request = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                fax_id: fax.fax_id,
                hidden: !fax.hidden
            };

            faxService.updateFax(request)
                .then(results => {
                    fax.hidden = !fax.hidden;
                    if ($scope.search.view == 'All') { return; }
                    $scope.data.faxes.splice(index, 1);
                })
                .catch(err => {
                    console.error(err);
                }).then(() => {
                    $scope.loading.faxes = false;
                    $scope.$apply();
                });
        };

        $scope.deleteFax = function(fax, index) {
            $scope.loading.faxes = true;

            const request = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                fax_id: fax.fax_id
            };

            faxService.deleteFax(request)
                .then(results => {
                    $scope.data.faxes.splice(index, 1);
                })
                .catch(err => {
                    console.error(err);
                }).then(() => {
                    $scope.loading.faxes = false;
                    $scope.$apply();
                });
        };

        $scope.clearSearchFaxes = function() {
            $scope.search.submit = false;
            $scope.search.term = '';
            $scope.loadFaxes();
        };

        $scope.searchFaxes = function() {
            $scope.search.term = $.trim($scope.search.term);
            if ($scope.search.term !== '') {
                $scope.search.submit = true;
                $scope.loadFaxes();
            } else if ($scope.search.submit) {
                $scope.clearSearchFaxes();
            }
        };

        $('#faxesReportRange').on('apply.daterangepicker', function(ev, picker) {
            $scope.search.minTs = picker.startDate._d.getTime();
            $scope.search.maxTs = picker.endDate._d.getTime();
            $scope.loadFaxes();
        });

        $scope.loadFaxes = function() {
            $scope.loading.faxes = true;
            $window.scrollTo(0, 0);

            const request = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                sort_by: $scope.search.sortBy,
                sort_order: ($scope.search.sortBy == 'created_at' ? -1 : 1),
                search_term: $scope.search.term,
                min_ts: $scope.search.minTs,
                max_ts: $scope.search.maxTs,
                skip: ($scope.currentPage - 1) * $scope.itemsPerPage,
                limit: $scope.itemsPerPage,
                get_total: true
            };
            if ($scope.search.view != 'All') {
                request.hidden = ($scope.search.view == 'Hidden');
            }

            faxService.getFaxes(request)
                .then(results => {
                    $scope.data.faxes = results.faxes || [];
                    $scope.totalItems = results.total_faxes || 0;
                    for (let i = 0; i < $scope.data.faxes.length; i++) {
                        var fax = $scope.data.faxes[i];
                        if (fax.status) {
                            fax.status = fax.status.replace(/[\.]/g, ' ');
                        }
                        if (fax.telnyx_reason) {
                            fax.telnyx_reason = fax.telnyx_reason.replace(/[_]/g, ' ');
                        }
                    }
                })
                .catch(err => {
                    console.error(err);
                }).then(() => {
                    $scope.loading.faxes = false;
                    $scope.$apply();
                });
        };

        $scope.onInit = function() {
            $scope.loadFaxes();
        };

        if (authService.hasAccount()) {
            $scope.onInit();
        }
        else {
            $scope.$on('auth_complete', function(event, args) {
                $scope.onInit();
            });
        }
    }
    module.exports = FaxesController;
})();
