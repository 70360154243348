(function() {
    'use strict';

    function IncomingOutgoingCallsController(authService, contactService, dncService, phoneService, callService, dispatcherService, notificationToastService, $scope, $rootScope, $location, $window, $cookies, $interval, $state, tagsService, generalHelperService) {
        let vm = this;
        vm.authService = authService;

        $scope.staticData = {
            // textingDisabled: {
            //     title: 'Texting Disabled',
            //     subtitle1: 'To enable the texting, you need to enable SMS on at least one phone number.',
            //     subtitle2: 'To enable the texting, you need to register a brand in the Trust Center.',
            //     actionBtn: 'Trust Center'
            // },
            dispositions: {
                good: [ 'good', 'sold' ],
                info: [ 'follow up', 'no contact', 'no answer', 'busy', 'abandoned' ],
                bad: [ 'not interested', 'bad lead', 'dnc', 'bad' ]
            },
            errorMessages: {
                phoneErrorMessage: 'Phone number is not valid.',
                callerIdErrorMessage: 'Please select Caller Id.',
                requiredErrorMessage: 'Field is required.',
                emptyChat: 'No Messages'
            },
            loading: 'Loading',
            sending: 'Sending',
            saving: 'Saving',
            calling: 'Calling',
            freeTrialMessage1: "You're on a free trial with call limits.",
            freeTrialMessage2: "Upgrade to unlock full access!"
        };

        moment.updateLocale('en', {
            relativeTime : {
                past: input=>input + ' ago',
                s  : number=>number + 's',
                future: 'in %s',
                ss : '%d seconds',
                m:  'a minute',
                mm: '%d minutes',
                h:  'an hour',
                hh: '%d hours',
                d:  'a day',
                dd: '%d days',
                M:  'a month',
                MM: '%d months',
                y:  'a year',
                yy: '%d years'
            }
        });

        $scope.data = {
            debug: false,
            tabs: {
                call: {
                    mode: '', //'idle', 'dialing', 'talking', 'wrapup', 'incoming'
                    title: '',
                    duration: 0,
                    timer: '00:00',
                    talkingToContact: undefined,
                    outboundCalls: {},
                    inboundCalls: {},
                    form: {
                        to: '',
                        from: '',
                        location: '',
                        compliance: false,
                        phone_selected: null,
                        is_valid: undefined,
                        submited: false,
                        calling: false
                    },
                    dialPad: {
                        input: ''
                    },
                    disposition: {
                        saving: false
                    },
                    notes: {
                        textArea: '',
                        saving: false
                    },
                    actionAlert: '',
                    phoneCountryDropdownState: null
                },
                // text: {
                //     mode: '', //'idle', 'talking'
                //     title: '',
                //     talkingToContact: undefined,
                //     messages: [],
                //     form: {
                //         to: '',
                //         from: '',
                //         body: '',
                //         campaign_id: null,
                //         action: 'Say',
                //         compliance: false,
                //         phone_selected: null,
                //         is_valid: undefined,
                //         submited: false,
                //         sending: false
                //     },
                //     disposition: {
                //         saving: false
                //     },
                //     notes: {
                //         textArea: '',
                //         saving: false
                //     },
                //     actionAlert: '',
                //     loadingContact: false,
                //     loadingMessages: false
                // },
                recentConversations: {
                    loading: false,
                    list: []
                }
            },
            defaultAvatar: $rootScope.settings.NO_AVATAR_IMG,
            // toggleProfileInfoChatTextTabContent: 'Talking To',

            agentState: 'idle', //'idle', 'dialing', 'talking', 'wrapup', 'hangup'
            outboundCalls: {}, // Calls being placed by the dialer
            inboundCalls: {}, // Calls that are routed to the user via an IVR
            lists: {}, // Cached list data (list_id, name, dispatcher_data.dispositions)
            ivrs: {}, // Cached ivr data (ivr_id, name)
            available_dispositions: null, // Available dispositions given the dispatcher serving the latest call
            contacts: {},
            calls: {},
            sms: {},

            startDialing: {
                listName: '',
                startDialingEvent: undefined
            },

            contact: {},
            contactLists: [],
            contactTags: [],
            currentPage: 'default'
        };

        $scope.limits = {
            sms: 100,
            calls: 100
        };

        $scope.outboundCallCount = function() {
            return Object.keys($scope.data.outboundCalls || {}).length;
        };

        $scope.loadContactLists = function() {
            let teamId = authService.getTeamId();
            let userId = authService.getUserId();

            const request = {
                team_id: teamId,
                user_id: userId
            };

            contactService.getLists(request)
                .then(results => {
                    $scope.data.contactLists = results;
                    $scope.$apply();
                })
                .catch(err => {
                    console.error(err);
                });
        };

        $scope.getListName = function(listId) {
            const lists = $scope.data.contactLists;
            let list = _.find(lists, { 'list_id': listId });
            return (!list) ? 'Unknown' : list.list_name;
        };

        $scope.freeTrialActive = function() {
            return $rootScope.isFreeTrial();
        };

        $scope.loadTagsList = function() {
            let teamId = authService.getTeamId();
            let userId = authService.getUserId();

            const request = {
                team_id: teamId,
                user_id: userId
            };

            tagsService.getTags(request)
                .then(results => {
                    $scope.data.contactTags = results;
                    $scope.$apply();
                })
                .catch(err => {
                    console.error(err);
                });
        };

        $scope.getTag = function(tag, index, currentTags) {
            const tags = $scope.data.contactTags;
            let foundTag = _.find(tags, { 'tag_id': tag.tag_id });
            if(foundTag) {
                currentTags[index] = foundTag;
                return true;
            }
            return false;
        };

        $scope.showIncomingCallNotification = function(request) {

            var contact = request.contact || {};
            var call = request.call || {};

            var fullName = ((call.first_name || '') + " " + (call.last_name || '')).trim();

            //var content = "<div class='row ml-0 mr-0 incoming-outgoing-calls-notification'><div class='col-2 avatar-col open-call-details-btn'><img width='45' height='45' src=" + $scope.data.defaultAvatar + " alt='User' class='img-circle'><span class='ringing_phone'><i class='fa-light fa-phone'></i></span></div><div class='col-10 info-col open-call-details-btn'><h5 class='card-title mb-2'>" + fullName + "</h5><h6 class='card-subtitle mt-0' ng-if='" + ($scope.profiles[0].company && $scope.profiles[0].company !== undefined) + "'><i class='fa-light fa-building-o'></i><span class='vertical-align-middle pl-1'>" + $scope.profiles[0].company + "</span></h6></div><div class='row action-row'><div class='col-2 open-call-details-btn'></div><div class='col'><button type='button' class='btn btn-outline-danger action-btn' id='decline-call-btn'><i class='fa-light fa-close m-r-5'></i>Decline</button></div><div class='col'><button type='button' class='btn btn-outline-success action-btn' id='answer-call-btn'><i class='fa-light fa-phone m-r-5 vertical-align-middle'></i>Answer</button></div></div><div class='row details-row'><div class='col-2 open-call-details-btn'></div><div class='col status-col open-call-details-btn'><span>Incoming Call</span></div><div class='col contact-details-col'><button type='button' class='btn btn-outline-secondary details-btn' id='view-contact-details-btn'><i class='icon-user m-r-5'></i>Details</button></div></div>";
            var content = $scope.libPhoneFormatPhone(call.from);
            if (fullName) {
                content += '<br />'+ fullName;
            }
            if (contact.company) {
                content += '<br />'+ contact.company;
            }

            notificationToastService.showInfoToastWithCloseBtn('fa-light fa-phone', 'Inbound Call', content);
         };

        // $scope.showIncomingCalls = function() {
        //     // test data start
        //     $scope.data.tabs.call.inboundCalls = $scope.testData.calls;
        //     $scope.data.tabs.call.form.to = $scope.data.tabs.call.inboundCalls[0].phone_numbers[0];
        //     $scope.data.tabs.call.form.to_is_valid_phone_number = true;
        //     // test data end
        //     $scope.data.tabs.call.mode = 'incoming';
        //     $('#call-tab-main').tab('show');
        // };

        $scope.formatDisposition = function(disposition, type) {
            let dispositions = $scope.staticData.dispositions;
            return (type) ? (dispositions[type].indexOf(disposition) !== -1) : (dispositions.good.indexOf(disposition) === -1 && dispositions.bad.indexOf(disposition) === -1 && dispositions.info.indexOf(disposition) === -1);
        };

        $scope.formatDateWithoutAgo = function(date) {
            return moment(date).fromNow(true);
        };

        $scope.formatDate = function(date) {
            return moment(date).fromNow();
        };

        $scope.viewContactDetails = function(id) {
            if (id) {
                $location.path('/contacts/detail').search('id=' + id);
            }
        };

        $scope.openGoogleMapsInNewTab = function(item) {
            let url = 'https://maps.google.com/?q=' + item.address + ' ' + item.city + ', ' + item.state + ' ' + item.zip_code;
            $window.open(url, '_blank');
        };

        // $scope.isVisibleAvatarTimeOfMessage = function(currentIndex) {
        //     /*
        //     if(currentIndex < ($scope.data.tabs.text.messages.length - 1)) {
        //         var currentDate = moment($scope.data.tabs.text.messages[currentIndex].created_at);
        //         var nextDate = moment($scope.data.tabs.text.messages[currentIndex + 1].created_at);
        //         var currentUserId = $scope.data.tabs.text.messages[currentIndex].user_id;
        //         var nextUserId = $scope.data.tabs.text.messages[currentIndex + 1].user_id;
        //
        //         return (currentUserId !== nextUserId) ? true : ((nextDate.diff(currentDate, 'seconds') > 120) ? true : false);
        //     }
        //      */
        //     return true;
        // };

        $scope.validateFields = function(type) {
            switch(type) {
                // case 'text':
                //     $scope.data.tabs.text.form.is_valid = !!$scope.data.tabs.text.form.to_is_valid_phone_number &&
                //         ($scope.data.tabs.text.form.to !== '') && ($scope.data.tabs.text.form.from !== '') && ($scope.data.tabs.text.form.body !== '');
                //     // && $scope.data.tabs.text.form.compliance
                //     break;
                case 'call':
                    $scope.data.tabs.call.form.is_valid = !!$scope.data.tabs.call.form.to_is_valid_phone_number &&
                        ($scope.data.tabs.call.form.to !== '') && ($scope.data.tabs.call.form.from !== '') && ($scope.data.tabs.call.form.location !== '');
                    // && $scope.data.tabs.call.form.compliance
                    break;
            }
        };

        // $scope.isImage = function(mimeType) {
        //     return mimeType.startsWith('image/');
        // };

        // $scope.isVideo = function(mimeType) {
        //     return mimeType.startsWith('video/');
        // };

        // $scope.isAudio = function(mimeType) {
        //     return mimeType.startsWith('audio/');
        // };

        // $scope.formatPhoneOld = function(type) {
        //     const typeIti = type === 'call' ? 'addCallIti' : 'addTextIti';
        //     if($scope[typeIti]) {
        //         $scope.data.tabs[type].form.to_is_valid_phone_number = $scope[typeIti].isValidNumber();
        //         $scope.data.tabs[type].form.to = ($scope.data.tabs[type].form.to_is_valid_phone_number) ? $scope[typeIti].getNumber(window.intlTelInput.utils.numberFormat.E164) : '';
        //         $scope.validateFields(type);
        //     }
        //
        //     if (type === 'text' && $scope.data.tabs.text.form.to_is_valid_phone_number) {
        //
        //         $scope.getContact(function(contact) {
        //             $scope.data.tabs.text.talkingToContact = contact || {};
        //             $scope.getContactConversation($scope.data.tabs.text.talkingToContact);
        //         });
        //
        //         $scope.data.tabs.text.mode = 'talking';
        //     }
        // };

        $scope.libPhoneFormatPhone = function(value) {
            return $rootScope.libPhoneFormatPhone(value);
        };

        $scope.formatPhone = function(type) {
            const typeIti = type === 'call' ? 'addCallIti' : 'addTextIti';
            if($scope[typeIti]) {
                setTimeout(function() {
                    const phoneNumber = $scope[typeIti].getNumber(window.intlTelInput.utils.numberFormat.E164)
                    $scope.data.tabs[type].form.submited = false;
                    $scope.data.tabs[type].form.to_is_valid_phone_number = !phoneNumber || $scope[typeIti].isValidNumber();
                    $scope.data.tabs[type].form.to = ($scope.data.tabs[type].form.to_is_valid_phone_number) ? $rootScope.libPhoneFormatPhone(phoneNumber) : '';
                    $scope.validateFields(type);
                }, 150);
            }
        };

        $scope.setPhoneMask = function(elem, mask, placeholder) {
            elem.mask(mask, {
                placeholder: placeholder
            });
        };

        $scope.setPhoneNumber = function(phoneNumber, type) {

            let phoneNumberId = $('#' + type + 'ToPhoneNumber');
            const typeIti = type === 'call' ? 'addCallIti' : 'addTextIti';

            $scope[typeIti].setNumber(phoneNumber);

            phoneNumberId.triggerHandler('change');
            if(!$scope[typeIti].isValidNumber() && phoneNumber.length > 0) {
                phoneNumberId.blur();
            }

            $scope.formatPhone(type);
        };

        $scope.inputPhoneNumberMask = function(number, type) {

            let phoneNumberId = '#' + type + 'ToPhoneNumber';
            const typeIti = type === 'call' ? 'addCallIti' : 'addTextIti';

            return generalHelperService.waitForElement(phoneNumberId)
                .then(phoneNumberIdInput => {
                    phoneNumberId = $(phoneNumberId);
                    if (phoneNumberId) {
                        $scope[typeIti] = window.intlTelInput(phoneNumberIdInput, {
                            nationalMode: 'false',
                            autoPlaceholder: 'aggressive',
                            separateDialCode: 'true',
                            validationNumberTypes: [
                                'FIXED_LINE',
                                'MOBILE',
                                'FIXED_LINE_OR_MOBILE',
                                'TOLL_FREE',
                                'PREMIUM_RATE',
                                'SHARED_COST',
                                'VOIP',
                                'PERSONAL_NUMBER',
                                'PAGER',
                                'UAN',
                                'VOICEMAIL',
                                'UNKNOWN'
                            ],
                            initialCountry: 'us',
                            // intl-tel-input is up to date as of dec 2024
                            loadUtils: () => import("https://cdn.jsdelivr.net/npm/intl-tel-input@25.2.0/build/js/utils.js"),
                            customPlaceholder: function (selectedCountryPlaceholder, selectedCountryData) {
                                const pl = selectedCountryPlaceholder.replace(/[0-9]/g, '0');
                                phoneNumberId.attr('maxlength', pl.length);
                                $scope.setPhoneMask(phoneNumberId, pl, pl);
                                return pl;
                            }
                        });
                        return $scope[typeIti].promise;
                    }
                })
                .then(() => {
                    if (phoneNumberId) {
                        if(number) {
                            $scope.setPhoneNumber(number, type);
                        }

                        phoneNumberId.on('countrychange', function (e) {

                            if($scope.data.tabs.call.phoneCountryDropdownState === 'close:countrydropdown') {
                                $scope[typeIti].setNumber('');
                                phoneNumberId.unmask();

                                const mask = e.target.placeholder.replace(/[0-9]/g, '0');
                                phoneNumberId.attr('maxlength', mask.length);
                                $scope.setPhoneMask(phoneNumberId, mask, e.target.placeholder);

                                phoneNumberId.triggerHandler('change');
                            }

                            $scope.data.tabs.call.phoneCountryDropdownState = 'countrychange';
                        });

                        phoneNumberId.on('open:countrydropdown', function (e) {
                            $scope.data.tabs.call.phoneCountryDropdownState = 'open:countrydropdown';
                        });
                        phoneNumberId.on('close:countrydropdown', function (e) {
                            $scope.data.tabs.call.phoneCountryDropdownState = 'close:countrydropdown';
                        });
                    }
                });
        };

        // $scope.setToNumberDropdown = function(number, type) {
        //     switch (type) {
        //         // case 'text':
        //         //     $scope.data.tabs.text.form.to_is_valid_phone_number = true;
        //         //     $scope.data.tabs.text.form.to = number;
        //         //     break;
        //         case 'call':
        //             $scope.data.tabs.call.talkingToContact.to = number;
        //             break;
        //     }
        // };

        $scope.setLocation = function(device) {
            $scope.data.tabs.call.form.location = device;
            $scope.data.tabs.call.form.location_phone = $scope.data.locations[$scope.data.tabs.call.form.location];
        };

        $scope.$on('deviceChanged', function(event, device) {
            $scope.setLocation(device);
        });

        $scope.showWebRtcSettingsModal = function() {
            $rootScope.showWebRtcSettingsModal();
        };

        $scope.setCallerId = function(value, type) {
            if (value) {
                $scope.data.tabs[type].form.phone_selected = value;
                $scope.data.tabs[type].form.from = value;
            }
            $scope.validateFields(type);
        };

        $scope.unsetCallerId = function() {
            $scope.data.tabs['call'].form.phone_selected = null;
            $scope.data.tabs['call'].form.from = '';
            $scope.validateFields('call');
            $scope.$apply();
        };

        $scope.destroyIti = function(type) {
            const typeIti = type === 'call' ? 'addCallIti' : 'addTextIti';
            $('#' + type + 'ToPhoneNumber').unmask();
            $scope[typeIti].destroy();
        };

        $scope.clearIti = function(type) {
            const typeIti = type === 'call' ? 'addCallIti' : 'addTextIti';
            $scope.data.tabs[type].form.to_is_valid_phone_number = undefined;
            $scope[typeIti].setNumber('');
        };

        // $scope.textIdleClear = function() {
        //     if($scope.addTextIti) {
        //         $scope.clearIti('text');
        //     }
        //
        //     $scope.data.tabs.text.form.to = '';
        //     $scope.data.tabs.text.form.body = '';
        //     $scope.data.tabs.text.form.campaign_id = null;
        //     $scope.data.tabs.text.form.action = 'Say';
        //     $scope.data.tabs.text.form.compliance = false;
        //     $scope.data.tabs.text.form.is_valid = undefined;
        //     $scope.data.tabs.text.form.submited = false;
        //     $scope.data.tabs.text.form.sending = false;
        //     $scope.data.tabs.text.notes.textArea = '';
        //     $('#dispositions-text-collapse').collapse('hide');
        //     $('#notes-text-collapse').collapse('hide');
        //
        //     $scope.data.tabs.text.timeline = [];
        // };

        $scope.callIdleClear = function() {
            if($scope.addCallIti) {
                $scope.clearIti('call');
            }

            $scope.data.tabs.call.form.to = '';
            $scope.data.tabs.call.form.compliance = false;
            $scope.data.tabs.call.form.is_valid = undefined;
            $scope.data.tabs.call.form.submited = false;
            $scope.data.tabs.call.form.calling = false;

            $scope.data.tabs.call.talkingToContact = undefined;

            $scope.data.tabs.call.phoneCountryDropdownState = null;
        };

        $scope.initIdleMode = function(type) {
            const typeIti = type === 'call' ? 'addCallIti' : 'addTextIti';
            const clear = type === 'call' ? 'callIdleClear' : 'textIdleClear';

            if($scope[typeIti]) {
                $scope[clear]();
                $scope.destroyIti(type);
            }

            Promise.all([
                $scope.inputPhoneNumberMask($scope.data.tabs.call.form.to, type)
            ]).then(()=> {
                $scope.validateFields(type);
                $scope.$apply();
            });
        };

        $scope.warningEmptyRentedNumbers = function() {
            swal({
                title: 'Warning!',
                text: 'You need to purchase a phone number\nto use this functionality.',
                buttons: ['Cancel', 'View Phone Numbers']
            }).then((isConfirm)=> {
                if (isConfirm) {
                    $location.path('phone-numbers');
                    $scope.$apply();
                }
            });
        };

        // $scope.setToggleProfileInfoChatTextTabContent = function() {
        //     let contact = $scope.data.tabs.text.talkingToContact || {};
        //     let name = ((contact.first_name || '') + ' ' + (contact.last_name || '')).trim();
        //     let userName = name || contact.company || 'Unknown Contact';
        //     $scope.data.toggleProfileInfoChatTextTabContent = 'Texting with ' + userName;
        //     $scope.checkHeightTextTab();
        // };

        // $scope.textTabModeWatch = function(newVal) {
        //     switch(newVal) {
        //         case 'idle':
        //             $scope.data.tabs.text.title = 'Send Text';
        //             $scope.initIdleMode('text');
        //             break;
        //         case 'talking':
        //             $scope.data.tabs.text.title = 'Texting With';
        //             $scope.setToggleProfileInfoChatTextTabContent();
        //             break;
        //     }
        // };

        $scope.callTabModeWatch = function(newVal) {
            switch(newVal) {
                case 'idle':
                    $scope.data.tabs.call.title = '';
                    $scope.initIdleMode('call');
                    break;
                case 'dialing':

                    if ($scope.data.startDialing.mode === 'list' && $scope.data.startDialing.list) {
                        $scope.data.tabs.call.title = `Calling ${$scope.data.startDialing.list.list_name} ...`;
                    } else {
                        $scope.data.tabs.call.title = `Calling ${$scope.data.tabs.call.form.to} ...`;
                    }
                    break;
                case 'talking':
                    $scope.data.tabs.call.title = '';
                    break;
                case 'wrapup':

                    //Disposition may have been set during call.
                    const dispo = ($scope.data.calls[$scope.data.ongoing_outbound_call_id] || {}).disposition;
                    if(!dispo || !dispo.id || dispo.id === 'none') {
                        $scope.data.tabs.call.title = 'Please disposition';
                        $('#dispositions-collapse').collapse('show');
                    }

                    break;
                case 'incoming':
                    $scope.data.tabs.call.title = 'Incoming Calls';
                    break;
                case 'change_disposition':
                    $scope.data.tabs.call.title = 'Please disposition';
                    $('#dispositions-collapse').collapse('show');
                    break;
            }
        };

        $scope.changeDisposition = function(item) {

            $scope.enrichCall(item)
                .then(call => {
                    $scope.data.tabs.call.mode = 'change_disposition';
                    $scope.data.agentState = 'change_disposition';
                    $scope.data.available_dispositions = (call.dispatcher_data || {}).dispositions;
                    $scope.openCallTab(call, true);
                })
                .catch(err => {
                    console.log(err);
                });
        };

        $scope.updateDuration = function() {
            $scope.data.tabs.call.duration += 1;
            $scope.data.tabs.call.timer = $scope.formatSeconds($scope.data.tabs.call.duration);
        };

        $scope.openCallTab = function(item, preventDial) {
            $('#call-tab-main').tab('show');
            $rootScope.$broadcast('showRightSideBar');

            if($scope.data.tabs.call.mode === 'idle') {
                $scope.callIdleClear();

                if($scope.data.startDialing.mode !== 'list') {
                    $scope.data.tabs.call.talkingToContact = item;
                    if (item.selected_number) {
                        $scope.data.tabs.call.form.to = item.selected_number;
                    } else if (item.contact_number) {
                        $scope.data.tabs.call.form.to = item.contact_number;
                    } else if (Array.isArray(item.phone_numbers) && item.phone_numbers.length > 0) {
                        $scope.data.tabs.call.form.to = item.phone_numbers[0];
                    }

                    if($scope.addCallIti) {
                        $scope.setPhoneNumber($scope.data.tabs.call.form.to, 'call');
                    }
                }
            }

            if (!preventDial) {
                $scope.placeACall();
            }
        };

        $scope.textContact = function(item) {
            $rootScope.openTextArea(item);
        };

        $scope.formatSeconds = function (totalSeconds) {

            if(totalSeconds < 0){
                return 'now';
            }

            totalSeconds = parseInt(totalSeconds || 0);
            let hours   = Math.floor(totalSeconds / 3600);
            let minutes = Math.floor((totalSeconds - (hours * 3600)) / 60);
            let seconds = totalSeconds - (hours * 3600) - (minutes * 60);

            if (hours   < 10 && hours > 0) {hours   = "0"+hours;}
            if (minutes < 10) {minutes = "0"+minutes;}
            if (seconds < 10) {seconds = "0"+seconds;}

            let total = minutes+':'+seconds;
            if(hours > 0){
                total = hours+':'+total;
            }

            return total;
        };

        $scope._setIdleMode = function(type) {
            $scope.data.tabs[type].mode = 'idle';
        };

        $scope.setIdleMode = function(type) {
            switch (type) {
                case 'call':
                    if((!$scope.data.tabs.call.talkingToContact || _.isEmpty($scope.data.tabs.call.talkingToContact)) &&
                        _.isEmpty($scope.data.inboundCalls) && _.isEmpty($scope.data.outboundCalls)) {
                        $scope._setIdleMode(type);
                        $scope.callIdleClear();
                    }
                    break;
                // case 'text':
                //     if((!$scope.data.tabs.text.talkingToContact || $scope.data.tabs.text.talkingToContact === {})) {
                //         $scope._setIdleMode(type);
                //     }
                //     break;
            }
        };

        // $scope.closeTalkingMode = function(type) {
        //
        //     switch($scope.data.agentState) {
        //         case 'dialing':
        //         case 'talking':
        //         case 'wrapup':
        //             $scope.hangUpCall();
        //             break;
        //         default: {
        //             $scope.data.tabs[type].mode = 'idle';
        //             $scope.data.tabs[type].talkingToContact = undefined;
        //             $scope.data.tabs.text.notes.textArea = '';
        //             $('#dispositions-text-collapse').collapse('hide');
        //             $('#notes-text-collapse').collapse('hide');
        //             break;
        //         }
        //     }
        // };

        // $scope.sendText = function() {
        //     $scope.validateFields('text');
        //     $scope.data.tabs.text.form.sending = true;
        //     $scope.data.tabs.text.form.submited = true;
        //     if (!$scope.data.tabs.text.form.is_valid && !$scope.data.tabs.text.form.sending) {
        //         return;
        //     }
        //
        //     $scope.getContact(function (contact) {
        //         $.extend($scope.data.tabs.text.talkingToContact, contact);
        //         $scope.sendSms();
        //     })
        // };

        $scope.placeACall = function() {

            if($rootScope.noOptIn() && $scope.data.startDialing.list && $scope.data.startDialing.list.contact_count > 1) {
                $rootScope.showNoBulkModalSwal();
                return;
            }

            if(!$scope.data.rentedNumbers || $scope.data.rentedNumbers.length === 0) {
                $scope.warningEmptyRentedNumbers();
                $scope.data.tabs.call.form.calling = false;
                return;
            }

            switch($scope.data.agentState) {
                case 'idle':
                    $scope.validateFields('call');
                    $scope.data.tabs.call.form.submited = true;
                    if($scope.data.startDialing.mode === 'list') {
                        return $scope.dialList();
                    }
                    if($scope.data.tabs.call.form.is_valid) {
                        $scope.data.tabs.call.form.calling = true;
                        $scope.__clearOutboundCalls();
                        return $scope.adHocCall();
                    }
                    break;
                case 'change_disposition':
                    $('#dispositions-collapse').collapse('show');
                    break;
                case 'dialing':
                case 'talking':
                default:
                    console.log('Call ongoing [State=' + $scope.data.agentState + ']');
                    break;
            }
        };

        $scope.dialList = function() {

            let userLoc = $scope.data.tabs.call.form.location.toLowerCase() || authService.getUserDefaultLocation().toLowerCase();
            if (userLoc === 'computer') {
                return $scope.dialListWebRTC();
            }

            return $scope.dialListPOTS();
        };

        $scope.dialListPOTS = function() {

            $scope.data.tabs.call.form.calling = true;

            let list = $scope.data.startDialing.list || {};

            let dialReq = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                list_id: list.list_id,
                user_location: $scope.data.tabs.call.form.location.toLowerCase() || authService.getUserDefaultLocation().toLowerCase(),
                caller_id: $scope.data.tabs.call.form.phone_selected
            };

            phoneService.startDialing(dialReq)
                .then(call => {
                    $scope.data.tabs.call.form.calling = false;
                    $scope.data.tabs.call.mode = 'dialing';
                    $scope.$apply();
                })
                .catch(err => {
                    $scope.data.tabs.call.form.calling = false;
                    $scope.$apply();
                    swal("Cancelled", "Unable to call contact.\nThis occurs if the contact is on the DNC, the number you're dialing isn't in service, or a temporary service disruption has occurred", "error");
                });
        }

        $scope.dialListWebRTC = function() {

            $scope.data.tabs.call.form.calling = true;

            let list = $scope.data.startDialing.list || {};

            let dialReq = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                access_number: $rootScope.access_number,
                initial_action: callService.CONTROL_ACTIONS.DIAL_LIST,
                list_id: list.list_id,
                user_location: $scope.data.tabs.call.form.location.toLowerCase() || authService.getUserDefaultLocation().toLowerCase(),
                caller_id: $scope.data.tabs.call.form.phone_selected,
                enable_audio: true
            };

            if (callService.dial(dialReq)) {
                $scope.data.tabs.call.form.calling = false;
                $scope.data.tabs.call.mode = 'dialing';
            }
            else {
                $scope.data.tabs.call.form.calling = false;
                swal("Cancelled", "Unable to call contact.\nThis occurs if the contact is on the DNC, the number you're dialing isn't in service, or a temporary service disruption has occurred", "error");
            }
        }

        $scope.adHocCall = function() {

            let userLoc = $scope.data.tabs.call.form.location.toLowerCase() || authService.getUserDefaultLocation().toLowerCase();
            if (userLoc === 'computer') {
                return $scope.adHocCallWebRTC();
            }

            return $scope.adHocCallPOTS();
        };

        $scope.adHocCallPOTS = function() {
            $scope.data.tabs.call.form.calling = true;

            let req = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                contact_id: ($scope.data.tabs.call.talkingToContact || {}).contact_id,
                contact_number: $scope.data.tabs.call.form.to,
                user_location: $scope.data.tabs.call.form.location.toLowerCase() || authService.getUserDefaultLocation().toLowerCase(),
                caller_id: $scope.data.tabs.call.form.phone_selected
            };

            phoneService.callContact(req)
               .then(call => {
                   $scope.data.tabs.call.form.calling = false;
                   $scope.data.tabs.call.mode = 'dialing';
                   $scope.$apply();
               })
               .catch(err => {
                   $scope.data.tabs.call.form.calling = false;
                   $scope.$apply();
                   swal("Cancelled", "Unable to call contact!\nThis occurs if the contact is on the DNC, the number you're dialing isn't in service, or a temporary service disruption has occurred", "error");
               });
        };

        $scope.adHocCallWebRTC = function() {
            $scope.data.tabs.call.form.calling = true;

            let req = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                access_number: $rootScope.access_number,
                initial_action: callService.CONTROL_ACTIONS.DIAL_ADHOC,
                contact_id: ($scope.data.tabs.call.talkingToContact || {}).contact_id,
                contact_tn: $scope.data.tabs.call.form.to,
                caller_id: $scope.data.tabs.call.form.phone_selected,
                enable_audio: true
            };

            if (callService.dial(req)) {
                $scope.data.tabs.call.form.calling = false;
                $scope.data.tabs.call.mode = 'dialing';
            }
            else {
                $scope.data.tabs.call.form.calling = false;
                swal("Cancelled", "Unable to call contact.\nThis occurs if the contact is on the DNC, the number you're dialing isn't in service, or a temporary service disruption has occurred", "error");
            }
        };

        $scope.interceptCallWebRTC = function(request) {
            $scope.data.tabs.call.form.calling = true;

            let req = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                access_number: $rootScope.access_number,
                initial_action: callService.CONTROL_ACTIONS.CALL_INTERCEPT,
                intercept_cid: request.intercept_cid,
                //contact_id: ($scope.data.tabs.call.talkingToContact || {}).contact_id,
                //contact_tn: $scope.data.tabs.call.form.to,
                //caller_id: $scope.data.tabs.call.form.phone_selected,
                enable_audio: true
            };

            if (callService.dial(req)) {
                $scope.data.tabs.call.form.calling = false;
                $scope.data.tabs.call.mode = 'dialing';
            }
            else {
                $scope.data.tabs.call.form.calling = false;
                swal("Cancelled", "Unable to intercept call or a temporary service disruption has occurred", "error");
            }
        };

        $scope.eavesdropCallWebRTC = function(request) {
            $scope.data.tabs.call.form.calling = true;

            let req = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                access_number: $rootScope.access_number,
                initial_action: callService.CONTROL_ACTIONS.CALL_EAVESDROP,
                eavesdrop_cid: request.eavesdrop_cid,
                contact_id: ($scope.data.tabs.call.talkingToContact || {}).contact_id,
                contact_tn: $scope.data.tabs.call.form.to,
                caller_id: $scope.data.tabs.call.form.phone_selected,
                enable_audio: true
            };

            if (callService.dial(req)) {
                $scope.data.tabs.call.form.calling = false;
                $scope.data.tabs.call.mode = 'dialing';
            }
            else {
                $scope.data.tabs.call.form.calling = false;
                swal("Cancelled", "Unable to intercept call or a temporary service disruption has occurred", "error");
            }
        };

        // $scope.getContactConversation = function(contact) {
        //
        //     if((contact || {}).contact_id) {
        //         $scope.getContactTimeline(contact, function(timeline) {
        //             $scope.data.tabs.text.timeline = timeline.entries;
        //             $('#text-tab-main').tab('show');
        //             $scope.data.tabs.text.loadingMessages = false;
        //             $scope.$apply();
        //         });
        //     }
        //     else {
        //         //$scope.getContactSms();
        //     }
        // };

        // $scope.getContactTimeline = function(contact, callback) {
        //
        //     var contactId = (contact || $scope.data.tabs.text.talkingToContact || {}).contact_id;
        //     if (!contactId) {
        //         return;
        //     }
        //
        //     var teamId = authService.getTeamId();
        //     var userId = authService.getUserId();
        //
        //     const request = {
        //         team_id: teamId,
        //         user_id: userId,
        //         contact_id: contactId,
        //         timeline_types: 'sms,note,external_disposition',
        //         limit: $scope.limits.sms,
        //         sort: 'asc'
        //     };
        //
        //     phoneService.getTimeline(request)
        //         .then(timeline => {
        //             callback(timeline || {});
        //         })
        //         .catch(err => {
        //             console.error(err);
        //             $scope.$apply();
        //         });
        // };

        // $scope.getContactSms = function() {
        //     var teamId = authService.getTeamId();
        //     var userId = authService.getUserId();
        //
        //     const request = {
        //         team_id: teamId,
        //         user_id: userId,
        //         contact_phone_number: $scope.data.tabs.text.form.to,
        //         conversation: true,
        //         limit: $scope.limits.sms,
        //         sort: 'asc'
        //     };
        //
        //     phoneService.getSms(request)
        //         .then(smss => {
        //             $scope.data.tabs.text.messages = smss.smss;
        //             $('#text-tab-main').tab('show');
        //             $scope.$apply();
        //         })
        //         .catch(err => {
        //             console.error(err);
        //             $scope.$apply();
        //         });
        // };

        $scope.saveNotes = function(type) {

            if (!($scope.data.tabs[type].talkingToContact || {}).contact_id || $.trim($scope.data.tabs[type].notes.textArea).length === 0) {
                return;
            }

            $scope.data.tabs[type].notes.saving = true;
            var teamId = authService.getTeamId();
            var userId = authService.getUserId();

            const req = {
                team_id: teamId,
                user_id: userId,
                type: type,
                note: $.trim($scope.data.tabs[type].notes.textArea),
                contact_id: $scope.data.tabs[type].talkingToContact.contact_id,
                call_id: $scope.data.ongoing_outbound_call_id
            };

            contactService.setContactNotes(req)
                .then(results => {
                    $scope.data.tabs[type].notes.textArea = '';
                    $scope.showActionAlert(type, 'Note added.', 'action-alert-success');
                    // if(type === 'text') {
                    //     $scope.getContactConversation($scope.data.tabs[type].talkingToContact);
                    // }
                    $scope.data.tabs[type].notes.saving = false;
                    var notesCollapse = (type === 'text') ? '#notes-text-collapse' : '#notes-collapse';
                    $(notesCollapse).collapse('hide');
                    $scope.$apply();
                })
                .catch(err => {
                    console.error(err);
                    $scope.showActionAlert(type, 'Something went wrong.', 'action-alert-error');
                    $scope.data.tabs[type].notes.saving = false;
                    $scope.$apply();
                });
        }

        $scope.addToDnc = function(contact) {
            var teamId = authService.getTeamId();
            var userId = authService.getUserId();

            const request = {
                team_id: teamId,
                user_id: userId,
                phone_number: contact.phone_numbers,
                method: "contact search"
            };

            const dncPromise = contact.dnc ? dncService.deleteOne(request) : dncService.createOne(request);

            dncPromise
                .then(results => {
                    if (!contact.dnc) {
                        swal("Added to DNC", "Contact "+ (contact.first_name || '') + " "+ (contact.last_name || '') +" has been added to the DNC list", "success");
                    } else {
                        swal("Removed from DNC", "Contact "+ (contact.first_name || '') + " "+ (contact.last_name || '') +" has been removed from the DNC list", "success");
                    }
                    contact.dnc = !contact.dnc;
                    $scope.$apply();
                })
                .catch(err => {
                    console.error(err);
                })
        };

        // $scope.sendSms = function() {
        //
        //     if (!$scope.data.tabs.text.form.is_valid) {
        //         return;
        //     }
        //
        //     let user = authService.getUser();
        //
        //     if(!user.team.verified) {
        //         swal("Verify your identity", "Please verify your identity to enable sending text messages.", "error");
        //         $scope.data.tabs.text.form.sending  = false;
        //         return;
        //     }
        //
        //     let req = {
        //         team_id: authService.getTeamId(),
        //         user_id: authService.getUserId(),
        //         phone_number: $scope.data.tabs.text.form.to,
        //         caller_id: $scope.data.tabs.text.form.phone_selected,
        //         sms_body: $scope.data.tabs.text.form.body,
        //     };
        //
        //     phoneService.sendSms(req)
        //         .then(sms => {
        //
        //             $scope.data.tabs.text.form.sending  = false;
        //             $scope.data.tabs.text.mode = 'talking';
        //
        //             if(!$scope.data.tabs.text.talkingToContact) {
        //                 $scope.data.tabs.text.talkingToContact = {
        //                     main_phone: $scope.data.tabs.call.form.to,
        //                     phone_numbers: [$scope.data.tabs.call.form.to]
        //                 };
        //             }
        //             $scope.data.tabs.text.form.body = '';
        //
        //             $scope.$apply();
        //         })
        //         .catch(err => {
        //             $scope.data.tabs.text.form.sending  = false;
        //             $scope.$apply();
        //             console.log(err);
        //             swal("Cancelled", "Unable to send text message.\n\nMessages to this contact may be blocked", "error");
        //         });
        // };

        // $scope.addMessageToChat = function(sms) {
        //     $scope.textIdleClear();
        //     $scope.data.tabs.text.form.to_is_valid_phone_number = true;
        //
        //     if(!$scope.data.tabs.text.talkingToContact) {
        //         $scope.data.tabs.text.talkingToContact = {
        //             main_phone: $scope.data.tabs.call.form.to,
        //             phone_numbers: [$scope.data.tabs.call.form.to]
        //         };
        //     }
        //
        //     /*
        //     if($scope.data.tabs.text.messages){
        //         $scope.data.tabs.text.messages.push(sms)
        //     } else {
        //         $scope.data.tabs.text.messages = [sms];
        //     }
        //      */
        // };

        $scope.getCountryNumberMaxLength = function(iti) {
            const countryData = iti.getSelectedCountryData();
            if (!countryData.iso2 || !window.intlTelInput.utils) return Infinity;

            // Get an example number for the country in NATIONAL format
            const exampleNumber = window.intlTelInput.utils.getExampleNumber(
                countryData.iso2,
                true,
                window.intlTelInput.utils.numberType.FIXED_LINE
            );

            // Remove all non-digit characters to get the max length
            return exampleNumber.replace(/\D/g, '').length;
        }

        $scope.dialPadAddDigit = function(value) {
            // $scope.data.tabs.call.dialPad.input += value;
            $scope.data.tabs.call.dialPad.lastUpdate = new Date();

            if ($scope.data.tabs.call.mode !== 'idle') {
                $scope.sendDTMF(value);
            } else {
                const inputEl = document.getElementById('callToPhoneNumber');
                if (window.intlTelInput.utils && $scope.addCallIti) {
                    const maxLength = $scope.getCountryNumberMaxLength($scope.addCallIti);
                    let onlyNumbers = (typeof inputEl.value === 'string' ? inputEl.value.replace(/\D/g, '') : inputEl.value) || '';
                    if (typeof onlyNumbers === 'string' && onlyNumbers.length < maxLength) {
                        onlyNumbers += value;
                    }

                    const formattedNumber = window.intlTelInput.utils.formatNumber(
                        onlyNumbers,
                        $scope.addCallIti.getSelectedCountryData().iso2,
                        window.intlTelInput.utils.numberFormat.NATIONAL
                    );
                    inputEl.value = formattedNumber || inputEl.value;
                    $scope.formatPhone('call');

                }
            }

            //If there has been no changes in 3 seconds, send DTMF
            /*
            setTimeout(function() {
                if (new Date() - $scope.data.tabs.call.dialPad.lastUpdate > 200) {
                    $scope.sendDTMF($scope.data.tabs.call.dialPad.input);
                }
            }, 3000);
             */
        };
        $scope.dialPadRemoveDigit = function() {
            // $scope.data.tabs.call.dialPad.input = $scope.data.tabs.call.dialPad.input.slice(0, -1);
            const inputEl = document.getElementById('callToPhoneNumber');
            if (window.intlTelInput.utils && $scope.addCallIti) {
                if (inputEl.value.length > 0) {
                    inputEl.value = inputEl.value.slice(0, -1);
                }

                const formattedNumber = window.intlTelInput.utils.formatNumber(
                    inputEl.value,
                    $scope.addCallIti.getSelectedCountryData().iso2,
                    window.intlTelInput.utils.numberFormat.NATIONAL
                );
                inputEl.value = formattedNumber || inputEl.value;
                $scope.formatPhone('call');
            }
        };

        $scope.getLocations = function() {
            if(!$scope.data.locations) {
                let userNumbers = authService.getUserNumbers(true);
                $scope.data.locations = userNumbers;
            }
            $scope.setLocation(authService.getUserDefaultLocation());
        };

        $scope.loadNumbers = function() {
            if(!$scope.data.rentedNumbers && $rootScope.team_phone_numbers){
                $scope.data.rentedNumbers = $rootScope.team_phone_numbers.map(number => {return number.phone_number});
                // $scope.setCallerId($scope.data.rentedNumbers[0], 'text');
                $scope.setCallerId($scope.data.rentedNumbers[0], 'call');
            }

            $scope.getLocations();

            if( $rootScope.number_limit){
                $scope.data.limits = {};
                $scope.data.limits.sms_enabled = $rootScope.number_limit.sms_enabled;
            }

            if ($rootScope.team_brands) {
                $scope.data.brands = $rootScope.team_brands;
            }
        };

        $scope.loadDispatcher = function(dispatcherId) {

            var teamId = authService.getTeamId();
            var userId = authService.getUserId();

            const req = {
                team_id: teamId,
                user_id: userId,
                dispatcher_id: dispatcherId
            };

            dispatcherService.getDispatcherData(req)
               .then(results => {
                   $scope.data.dispatcher = results;
                   if(results && !$scope.data.available_dispositions) {
                       $scope.data.available_dispositions = (results.dispatcher_data || {}).dispositions;
                   }
                   $scope.$apply();
               })
               .catch(err => {
                   console.error(err);
               });
        };

        $scope.wrapupDisposition = function(disposition, digit) {
            $scope.data.tabs.call.disposition.saving = true;
            var teamId = authService.getTeamId();
            var userId = authService.getUserId();

            const dispositionReq = {
                team_id: teamId,
                user_id: userId,
                digits: digit,
                call_id: $scope.data.agent_call_id
            };

            phoneService.recvDTMF(dispositionReq)
                .then(results => {
                    ($scope.data.calls[$scope.data.ongoing_outbound_call_id] || {}).disposition = disposition;
                    $scope.showActionAlert('call', 'Disposition changed.', 'action-alert-success');
                    $scope.data.tabs.call.disposition.saving = false;
                    $('#dispositions-collapse').collapse('hide');
                    $scope.$apply();
                })
                .catch(err => {
                    console.error(err);
                    $scope.showActionAlert('call', 'Something went wrong.', 'action-alert-error');
                    $scope.data.tabs.call.disposition.saving = false;
                    $scope.$apply();
                });
        };

        $scope.setCallDisposition = function(disposition, digit) {
            switch($scope.data.tabs.call.mode) {
                case 'talking':
                case 'change_disposition':
                    $scope.changeCallDisposition(disposition, digit);
                    break;
                case 'wrapup':
                    $scope.wrapupDisposition(disposition, digit);
                    break;
            }
        };

        $scope.showActionAlert = function(type, message, alertId) {
            $scope.data.tabs[type].actionAlert = message;
            var alertElem = document.getElementById(alertId + '-' + type);
            alertElem.classList.add("d-block");
            setTimeout(function() { alertElem.classList.remove("d-block"); }, 2300);
        };

        // $scope.setSmsDisposition = function(disposition, digit) {
        //     if($scope.data.tabs.text.mode !== 'idle') {
        //         $scope.data.tabs.text.disposition.saving = true;
        //
        //         var teamId = authService.getTeamId();
        //         var userId = authService.getUserId();
        //
        //         const contactSmsReq = {
        //             team_id: teamId,
        //             user_id: userId,
        //             digit: digit,
        //             sms_id: $scope.data.tabs.text.talkingToContact.sms_id
        //         };
        //
        //         phoneService.setSmsDisposition(contactSmsReq)
        //             .then(results => {
        //                 $scope.showActionAlert('text', 'Disposition changed.', 'action-alert-success');
        //                 $scope.data.tabs.text.disposition.saving = false;
        //                 $scope.data.tabs.text.talkingToContact.disposition = disposition;
        //                 $('#dispositions-text-collapse').collapse('hide');
        //                 $scope.$apply();
        //             })
        //             .catch(err => {
        //                 console.error(err);
        //                 $scope.showActionAlert('text', 'Something went wrong.', 'action-alert-error');
        //                 $scope.data.tabs.text.disposition.saving = false;
        //                 $scope.$apply();
        //             });
        //     }
        // };

        $scope.changeCallDisposition = function(disposition, digit) {
            $scope.data.tabs.call.disposition.saving = true;

            var teamId = authService.getTeamId();
            var userId = authService.getUserId();

            const contactCallReq = {
                team_id: teamId,
                user_id: userId,
                digit: digit,
                call_id: $scope.data.tabs.call.talkingToContact.call_id || $scope.data.ongoing_outbound_call_id
            };

            phoneService.setCallDisposition(contactCallReq)
                .then(results => {
                    $scope.data.calls[$scope.data.tabs.call.talkingToContact.call_id || $scope.data.ongoing_outbound_call_id].disposition = disposition;
                    $scope.showActionAlert('call', 'Disposition changed.', 'action-alert-success');
                    $scope.data.tabs.call.disposition.saving = false;
                    $('#dispositions-collapse').collapse('hide');
                    $scope.$apply();
                })
                .catch(err => {
                    console.error(err);
                    $scope.showActionAlert('call', 'Something went wrong.', 'action-alert-error');
                    $scope.data.tabs.call.disposition.saving = false;
                    $scope.$apply();
                });
        };

        $scope.sendDTMF = function(digit) {
            var teamId = authService.getTeamId();
            var userId = authService.getUserId();

            const txRequest = {
                team_id: teamId,
                user_id: userId,
                digits: digit || $scope.data.tabs.call.dialPad.input,
                tx_call_id: $scope.data.agent_call_id,
                call_id: $scope.data.ongoing_outbound_call_id,
                play_tone: !digit
            };

            const rxRequest = {
                team_id: teamId,
                user_id: userId,
                digits: digit || $scope.data.tabs.call.dialPad.input,
                call_id: $scope.data.agent_call_id
            };

            Promise.resolve([phoneService.recvDTMF(rxRequest), phoneService.sendDTMF(txRequest)])
                .then(results => {
                    $scope.$apply();
                })
                .catch(err => {
                    console.error(err);
                    $scope.$apply();
                });
        };

        $scope.clearCall = function() {

            console.log('Clearing call');

            $scope.data.agentState = 'idle';
            $scope.data.tabs.call.mode = 'idle';
            $scope.data.ongoing_outbound_call_id = null;
            $scope.data.agent_call_id = null;
            $scope.data.startDialing.mode = null;

            $scope.__clearOutboundCalls();
        };

        $scope.hangUpClearCall = function() {
            if (callService.hangup()) {
                $scope.clearCall(callService.HANGUP_CAUSE.NORMAL_CLEARING);
            }
        };

        $scope.hangUpCall = function() {
            var teamId = authService.getTeamId();
            var userId = authService.getUserId();

            var hangupContact = ($scope.data.agentState === 'talking' && $scope.data.ongoing_outbound_call_id);
            var callId = hangupContact ? $scope.data.ongoing_outbound_call_id : $scope.data.agent_call_id;

            const request = {
                team_id: teamId,
                user_id: userId,
                call_id: callId
            };

            if(!callId) {
                $scope.hangUpClearCall();
            } else {
                phoneService.hangupCall(request)
                    .then(results => {})
                    .catch(err => {
                        console.error(err);
                    })
                    /*
                    .then(()=> {
                        $scope.clearCall();
                        $scope.$apply();
                    });
                     */
            }
        };

        $scope.loadRecentConversations = function(callback) {

            const request = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                names: true,
                timeline_types: 'call,sms',
                sort: 'desc',
                minTs: Date.now() - (10 * 24 * 60 * 60 * 1000), // last 10 days
                limit: $scope.limits.calls
            };

            phoneService.getAggregatedTimeline(request)
                .then(entries => {
                    $scope.data.tabs.recentConversations.list = entries.entries;

                    $scope.data.tabs.recentConversations.list.map(call => {
                        if(!call.phone_numbers || !call.phone_numbers.length) {
                            call.contact_number = call.direction === 'outbound' ? call.to : call.from;
                            call.phone_numbers = [call.contact_number];
                        }

                        return call;
                    });

                    callback();
                })
                .catch(err => {
                    console.error(err);
                    callback();
                });
        };

        $scope.getRootCall = function(callId) {

            if(!$scope.data.agent_call) {

                const callReq = {
                    team_id: authService.getTeamId(),
                    user_id: authService.getUserId(),
                    call_id: callId
                };

                phoneService.getCall(callReq)
                    .then(results => {
                        $scope.data.agent_call = results;
                        $scope.$apply();
                    })
                    .catch(err => {
                        console.error(err);
                    });
            }
        };

        $scope.enrichSms = function(event) {

            return new Promise((resolve, reject) => {

                let promises = [];
                var teamId = authService.getTeamId();
                var userId = authService.getUserId();

                if (event.sms_id) {

                    if (!$scope.data.sms[event.sms_id] || Object.keys($scope.data.sms[event.sms_id]).length === 0) {

                        let smsReq = {
                            team_id: teamId,
                            user_id: userId,
                            sms_id: event.sms_id
                        };

                        promises.push(phoneService.getOneSms(smsReq));
                    }
                    else {
                        promises.push(Promise.resolve($scope.data.sms[event.sms_id]));
                    }
                }
                else {
                    promises.push(Promise.resolve($scope.data.sms[event.sms_id] || {}));
                }

                if (event.contact_id) {

                    if (!$scope.data.contacts[event.contact_id] || Object.keys($scope.data.contacts[event.contact_id]).length === 0) {

                        let contactReq = {
                            team_id: teamId,
                            user_id: userId,
                            contact_id: event.contact_id
                        };

                        promises.push(contactService.getContactDetails(contactReq));
                    }
                    else {
                        promises.push(Promise.resolve($scope.data.contacts[event.contact_id]))
                    }
                }
                else {
                    promises.push(Promise.resolve($scope.data.contacts[event.contact_id] || {}));
                }

                if (event.ivr_id) {

                    if (!$scope.data.ivrs[event.ivr_id] || Object.keys($scope.data.ivrs[event.ivr_id]).length === 0) {

                        let ivrReq = {
                            team_id: teamId,
                            user_id: userId,
                            ivr_id: event.ivr_id
                        };

                        promises.push(phoneService.getIvr(ivrReq));
                    }
                    else {
                        promises.push(Promise.resolve($scope.data.ivrs[event.ivr_id]))
                    }
                }
                else {
                    promises.push(Promise.resolve($scope.data.ivrs[event.ivr_id] || {}));
                }

                let dispatcherReq = {
                    team_id: teamId,
                    user_id: userId
                };

                if (event.dispatcher_id) {
                    dispatcherReq.dispatcher_id = event.dispatcher_id;
                }

                promises.push(dispatcherService.getDispatcherData(dispatcherReq));

                Promise.all(promises)
                    .then(results => {
                        if (results[1].status !== 200) {
                            return;
                        }
                        $scope.data.sms[event.sms_id] = results[0];
                        $scope.data.contacts[event.contact_id] = results[1].status ? results[1].contact : results[1];
                        $scope.data.ivrs[event.ivr_id] = results [3];
                        $scope.data.sms[event.sms_id].dispatcher_data = (results[4] || {}).dispatcher_data;

                        // if ($scope.data.contacts[event.contact_id].gravatar_id) {
                        //     $scope.data.contacts[event.contact_id].avatar = 'https://secure.gravatar.com/avatar/' + $scope.data.contacts[event.contact_id].gravatar_id + '?d=mp';
                        // }

                        if (!$scope.data.sms[event.sms_id].phone_numbers || !$scope.data.sms[event.sms_id].phone_numbers.length) {
                            $scope.data.sms[event.sms_id].contact_number = $scope.data.sms[event.sms_id].sms_type === 'outbound' ? $scope.data.sms[event.sms_id].to : $scope.data.sms[event.sms_id].from;
                            $scope.data.sms[event.sms_id].phone_numbers = [$scope.data.sms[event.sms_id].contact_number];
                        }

                        $.extend($scope.data.sms[event.sms_id], $scope.data.contacts[event.contact_id], $scope.data.lists[event.list_id], $scope.data.ivrs[event.ivr_id]);
                        resolve($scope.data.sms[event.sms_id]);
                    })
                    .catch(err => {
                        console.error(err);
                        reject(err);
                    });
            })
        };

        $scope.enrichCall = function(event) {

            return new Promise((resolve, reject) => {

                let promises = [];
                var teamId = authService.getTeamId();
                var userId = authService.getUserId();

                if (event.call_id) {

                    if (!$scope.data.calls[event.call_id] || Object.keys($scope.data.calls[event.call_id]).length === 0) {

                        let callReq = {
                            team_id: teamId,
                            user_id: userId,
                            call_id: event.call_id
                        };

                        promises.push(phoneService.getCall(callReq));
                    }
                    else {
                        promises.push(Promise.resolve($scope.data.calls[event.call_id]));
                    }
                }
                else {
                    promises.push(Promise.resolve($scope.data.calls[event.call_id] || {}));
                }

                if (event.contact_id) {

                    if (!$scope.data.contacts[event.contact_id] || Object.keys($scope.data.contacts[event.contact_id]).length === 0) {

                        let contactReq = {
                            team_id: teamId,
                            user_id: userId,
                            contact_id: event.contact_id
                        };

                        promises.push(contactService.getContactDetails(contactReq));
                    }
                    else {
                        promises.push(Promise.resolve($scope.data.contacts[event.contact_id]))
                    }
                }
                else {
                    promises.push(Promise.resolve($scope.data.contacts[event.contact_id] || {}));
                }

                if (event.list_id) {

                    if (!$scope.data.lists[event.list_id] || Object.keys($scope.data.lists[event.list_id]).length === 0) {
                        promises.push(Promise.resolve());
                    }
                    else {
                        promises.push(Promise.resolve($scope.data.lists[event.list_id]));
                    }
                }
                else {
                    promises.push(Promise.resolve($scope.data.lists[event.list_id] || {}));
                }

                if (event.ivr_id) {

                    if (!$scope.data.ivrs[event.ivr_id] || Object.keys($scope.data.ivrs[event.ivr_id]).length === 0) {

                        let ivrReq = {
                            team_id: teamId,
                            user_id: userId,
                            ivr_id: event.ivr_id
                        };

                        promises.push(phoneService.getIvr(ivrReq));
                    }
                    else {
                        promises.push(Promise.resolve($scope.data.ivrs[event.ivr_id]))
                    }
                }
                else {
                    promises.push(Promise.resolve($scope.data.ivrs[event.ivr_id] || {}));
                }

                let dispatcherReq = {
                    team_id: teamId,
                    user_id: userId
                };

                if (event.dispatcher_id) {
                    dispatcherReq.dispatcher_id = event.dispatcher_id;
                }

                promises.push(dispatcherService.getDispatcherData(dispatcherReq));

                Promise.all(promises)
                    .then(results => {

                        let enrichedCall = results[0] || {};

                        if (!enrichedCall.call_id) {
                            let err = 'Failed to load call ' + event.call_id;
                            console.error(err);
                            reject(err);
                        }
                        else {
                            enrichedCall.dispatcher_data = (results[4] || {}).dispatcher_data;
                            $scope.data.calls[event.call_id] = enrichedCall;

                            let contact = results[1] || {};
                            $scope.data.contacts[event.contact_id] = contact;

                            let list = results[2] || {};
                            $scope.data.lists[event.list_id] = list;

                            let ivr = results[3] || {};
                            $scope.data.ivrs[event.ivr_id] = ivr;

                            // if (contact.gravatar_id) {
                            //     contact.avatar = 'https://secure.gravatar.com/avatar/' + contact.gravatar_id + '?d=mp';
                            // }

                            if (!enrichedCall.phone_numbers || !enrichedCall.phone_numbers.length) {
                                enrichedCall.contact_number = enrichedCall.call_direction === 'outbound' ? enrichedCall.to : enrichedCall.from;
                                enrichedCall.phone_numbers = [enrichedCall.contact_number];
                            }

                            $.extend(enrichedCall, contact, list, ivr);
                            resolve(enrichedCall);
                        }
                    })
                    .catch(err => {
                        console.error(err);
                        reject(err);
                    });
            })
        };

        $scope.processEvent = function(event) {

            $scope.enrichEntry(event)
                .then(entry => {
                    $.extend(entry, event);

                    if (entry) {
                        //Add entry to recent conversations
                        $scope.addRecentConversation(entry);
                        $scope.data.available_dispositions = (entry.dispatcher_data || {}).dispositions;

                        //If sms, add text to the sms conversation.
                        // if(entry.sms_id) {
                        //     $scope.data.tabs.text.timeline = $scope.data.tabs.text.timeline || [];
                        //     entry.type = 'sms';
                        //     $scope.data.tabs.text.timeline.push(entry);
                        // }
                    }

                    $scope.$apply();
                })
                .catch(err => {
                    console.error(err);
                });
        };

        $scope.addRecentConversation = function(entry) {

            entry._id = '+' + Math.min(entry.to, entry.from) + '+' + Math.max(entry.to, entry.from);

            for (var i=0; i<$scope.data.tabs.recentConversations.list.length; i++) {
                if($scope.data.tabs.recentConversations.list[i]._id === entry._id) {
                    $scope.data.tabs.recentConversations.list.splice(i, 1);
                    break;
                }
            }

            $scope.data.tabs.recentConversations.list.unshift(entry);
        };

        $scope.enrichEntry = function(event) {

            var data = ((event || {}).data || {});
            if (data.sms_id) {
                return $scope.enrichSms(data);
            }
            else if (data.call_id) {
                return $scope.enrichCall(data);
            }
        };

        $scope.toggleRightSideBar = function() {
            $rootScope.$broadcast('toggleRightSideBar');
        };

        // $scope.toggleProfileInfoChatTextTab = function() {
        //
        //     var contact = $scope.data.tabs.text.talkingToContact || {};
        //     var userName = ((contact.first_name || '') + ' ' + (contact.last_name || '')).trim() || 'Unknown Contact';
        //     var message1 = 'Talking To';
        //     var message2 = 'Talking To ' + userName;
        //     $scope.data.toggleProfileInfoChatTextTabContent = ($('#collapseProfileInfoMobileTextTalking').hasClass('show')) ? message2 : message1;
        //     $('.text-accordion-title').toggleClass('active');
        //     $('#collapseProfileInfoMobileTextTalking').collapse('toggle');
        // };
        // $scope.checkHeightTextTab = function() {
        //     if($('#collapseProfileInfoMobileTextTalking')) {
        //         var height = (window.innerHeight > 0) ? window.innerHeight : this.screen.height;
        //         if(height > 750) {
        //             $('.text-accordion-title').removeClass('active');
        //             setTimeout(()=> {
        //                 $('#collapseProfileInfoMobileTextTalking').collapse('show');
        //             }, 5);
        //         } else {
        //             setTimeout(()=> {
        //                 $('#collapseProfileInfoMobileTextTalking').collapse('hide');
        //             }, 5);
        //         }
        //     }
        // };
        // $scope.startDialing = function() {
        //     //test data start
        //     setTimeout(()=>{
        //         $scope.callIdleClear();
        //         $scope.data.tabs.call.talkingToContact = $scope.data.tabs.call.outboundCalls[0];
        //         $scope.data.tabs.call.form.to = $scope.data.tabs.call.form.to || $scope.data.tabs.call.talkingToContact.phone_numbers[0];
        //         $scope.data.tabs.call.form.to_is_valid_phone_number = true;
        //         $scope.data.tabs.call.mode = 'talking';
        //         $scope.$apply();
        //         $('#call-tab-main').tab('show');
        //     }, 5000);
        //     //test data end
        //
        //     $rootScope.$broadcast('showRightSideBar');
        // };
        // $scope.openTextTab = function(item) {
        //     $rootScope.$broadcast('showRightSideBar');
        //     $('#text-tab-main').tab('show');
        //
        //     $scope.data.tabs.text.loadingContact = true;
        //     $scope.data.tabs.text.loadingMessages = true;
        //
        //     if(item) {
        //         $scope.data.tabs.text.mode = 'talking';
        //     }
        //
        //     $scope.textIdleClear();
        //     $scope.data.tabs.text.talkingToContact = item;
        //     $scope.data.tabs.text.messages = [];
        //     $scope.data.tabs.text.form.to = item.selected_number || item.contact_number ||item.phone_numbers[0] || $scope.data.tabs.text.form.to;
        //     $scope.data.tabs.text.form.to_is_valid_phone_number = true;
        //
        //     $scope.getContact(function(contact) {
        //         $.extend($scope.data.tabs.text.talkingToContact, contact);
        //         $scope.setToggleProfileInfoChatTextTabContent();
        //         $scope.data.tabs.text.loadingContact = false;
        //         $scope.getContactConversation($scope.data.tabs.text.talkingToContact);
        //         $scope.$apply();
        //     });
        // };

        $scope.__enqueueInboundCall = function (request) {

            $scope.data.tabs.call.mode = 'incoming';

            $scope.enrichCall(request)
                .then(call => {

                    $scope.data.inboundCalls[call.call_id] = {
                        user_id: call.user_id,
                        call_id: call.call_id,
                        ivr_id: call.ivr_id,
                        list_id: call.list_id,
                        contact_id: call.contact_id,
                        call_state: call.call_state,
                        created_at: call.occurred_at
                    };

                    $scope.showIncomingCallNotification();
                    $('#call-tab-main').tab('show');
                    $scope.$apply();
                })
                .catch(err => {
                    console.error(err);
                });
        };

        $scope.__dequeueOutboundCall = function (request) {

            console.log('Dequeue outbound call ' + request.call_id);

            delete $scope.data.outboundCalls[request.call_id];
        };

        $scope.__dequeueOutboundOtherCalls = function (request) {

            console.log('Dequeue all other calls ' + request.call_id);

            var keys = Object.keys($scope.data.outboundCalls);
            for (var key of keys) {
                var call = $scope.data.outboundCalls[key];
                if (typeof call === 'object' && call && call.call_id && call.call_id !== request.call_id) {
                    console.log('Dequeue ' + key);
                    delete $scope.data.outboundCalls[key];
                }
            }
        };

        $scope.__clearOutboundCalls = function () {

            var keys = Object.keys($scope.data.outboundCalls);
            for (var key of keys) {
                var call = $scope.data.outboundCalls[key];
                if (typeof call === 'object' && call && call.call_id) {
                    console.log('Dequeue ' + key);
                    delete $scope.data.outboundCalls[key];
                }
            }
        };

        $scope.__enrichOutboundCall = function(request) {
            $scope.enrichCall(request);
        };

        $scope.__enrichInboundCall = function(request) {

            $scope.enrichCall(request);
        };

        $scope.__updateOutboundCallState = function(request) {

            let call = $scope.data.outboundCalls[request.call_id];
            if (!call) {
                return;
            }

            call.call_state = request.call_state;
            if (request.call_state === 'answer') {
                call.answered_at = request.occurred_at;
            }

            $scope.$apply();
        };

        $scope.__enqueueOutboundCall = function (request) {

            console.log('Enqueue outbound call ' + request.call_id);

            $scope.enrichCall(request)
                .then(call => {
                    if ($scope.data.ongoing_outbound_call_id === call.call_id ||
                       ($scope.data.agentState !== 'talking' && $scope.data.agentState !== 'wrapup')) {

                        $.extend(call, request);
                        $scope.data.outboundCalls[request.call_id] = call;
                        $scope.data.available_dispositions = (call.dispatcher_data || {}).dispositions;
                        $scope.openCallTab(call, true);
                    }
                    $scope.$apply();
                })
                .catch(err => {
                    console.error(err);
                });
        };

        $scope.getContact = function (callback) {

            var teamId = authService.getTeamId();
            var userId = authService.getUserId();

            var contactReq = {
                team_id: teamId,
                user_id: userId,
                limit: 1
            };

            // if($scope.data.tabs.text.form.to) {
            //     contactReq.phone_number = $scope.data.tabs.text.form.to;
            // }

            contactService.getContacts(contactReq)
                .then(contact => {
                    callback(contact.contacts[0] || {});
                })
                .catch(err => {
                    console.error(err);
                });
        };

        $scope.__showScreenPop = function (request) {

            var teamId = authService.getTeamId();
            var userId = authService.getUserId();

            $scope.enrichCall(request)
               .then(results => {

                   var promises = [];

                   var call = results || {};
                   request.call = call;

                   if (call.contact_id) {

                       if (!$scope.data.contacts[call.contact_id] || Object.keys($scope.data.contacts[call.contact_id]).length === 0) {

                           var contactReq = {
                               team_id: teamId,
                               user_id: userId,
                               contact_id: call.contact_id
                           };

                           promises.push(contactService.getContactDetails(contactReq))
                       }

                       promises.push(Promise.resolve($scope.data.contacts[call.contact_id]));
                   }
                   else {
                       promises.push(Promise.resolve({}));
                   }

                   if (call.list_id) {

                       if (!$scope.data.lists[call.list_id] || Object.keys($scope.data.lists[call.list_id]).length === 0) {
                           promises.push(Promise.resolve());
                       }
                       else {
                           promises.push(Promise.resolve($scope.data.lists[call.list_id]));
                       }
                   }
                   else {
                       promises.push(Promise.resolve($scope.data.lists[call.list_id] || {}));
                   }

                   if (call.ivr_id) {

                       if (!$scope.data.ivrs[call.ivr_id] || Object.keys($scope.data.ivrs[call.ivr_id]).length === 0) {

                           var ivrReq = {
                               team_id: teamId,
                               user_id: userId,
                               ivr_id: call.ivr_id
                           };

                           promises.push(phoneService.getIvr(ivrReq));
                       }
                       else {
                           promises.push(Promise.resolve($scope.data.ivrs[call.ivr_id]))
                       }
                   }
                   else {
                       promises.push(Promise.resolve($scope.data.ivrs[call.ivr_id] || {}));
                   }

                   return Promise.all(promises);
               })
               .then(results => {

                   request.contact = (results[0] || {}).contact || {};
                   request.list = results[1] || {};
                   request.ivr = results[2] || {};

                   let params = {};
                   params.title = 'Inbound Call';
                   params.iconClasses = 'icon-call-in';
                   params.id = request.call.call_id || request.call_id;

                   //if (request.list.name) { params.title += ' (<span>'+ request.list.name +'</span>)'; }

                   params.body = '';
                   var locations = request.contact.location || {};
                   var latLong = (locations.postal_code || {}).lat_long || (locations.npa || {}).lat_long;
                   var country = (locations.postal_code || {}).iso_country || (locations.npa || {}).iso_country;

                   if (request.contact.first_name || request.contact.last_name) {
                       params.body += '<div><span>Name:</span> '+ ((request.contact.first_name || '') + ' ' + (request.contact.last_name || '')).trim() +'</div>';
                   }

                   if (request.call.from) {
                       params.body += '<div><span>From:</span> '+ $scope.libPhoneFormatPhone(request.call.from) +'</div>';
                   }

                   if (request.contact.company) {
                       params.body += '<div><span>Company:</span> '+ (request.contact.company).trim() +'</div>';
                   }

                   if (request.contact.address) {
                       params.body += '<div><span>Address:</span> '+ (request.contact.address).trim() +'</div>';
                   }

                   if (request.contact.city) {
                       params.body += '<div><span>City:</span> '+ (request.contact.city).trim() +'</div>';
                   }

                   if (request.contact.state) {
                       params.body += '<div><span>State:</span> '+ (request.contact.state).trim() +'</div>';
                   }
                   if (request.contact.zip_code) {
                       params.body += '<div><span>Postal Code:</span> '+ (request.contact.zip_code).trim() +'</div>';
                   }

                   if (request.call.contact_id) {

                       params.gravatar_id = request.contact.gravatar_id;

                       if (country) {
                           params.body += '<div><span>Country:</span> '+ country +'</div>';
                       }

                       if (request.contact.address || request.contact.zip_code) {
                           var mapUrl = 'https://maps.google.com/?q=' + request.contact.address + ' ' + request.contact.city + ', ' + request.contact.state + ' ' + request.contact.zip_code;
                           params.body += '<div><a href="'+ mapUrl +'" target="_blank">View Map</a></div>';
                       }
                       else if (false && latLong) {

                           const mapUrl = 'https://maps.google.com/?q='+ parseFloat(latLong[1]) +'%2C'+ parseFloat(latLong[0]);
                           params.body += '<div><a href="'+ mapUrl +'" target="_blank">View Map</a></div>';
                       }

                       params.buttonAction = function() {
                           $location.path('/contacts/detail').search('id=' + request.call.contact_id);
                           $rootScope.hideModal();
                       }

                       params.buttonText = 'View Contact';
                   }
                   else {
                       params.body += '<div>Unknown Contact</div>';
                       params.body += '<div><span>Phone Number:</span> '+ $scope.libPhoneFormatPhone(request.call.from) +'</div>';
                   }

                   if ($scope.data.agentState !== 'idle') {
                       $scope.showIncomingCallNotification(request);
                   }
                   else {
                       $scope.data.screen_pop_id = request.call.call_id;
                       $rootScope.showModal(params);
                   }
               })
               .catch(err => console.log(err));
        }

        $scope.__hideScreenPop = function (request) {
            if ($scope.data.screen_pop_id === request.call_id) {
                $rootScope.hideModal({id: request.call_id});
            }
        }

        $scope.formatContactCustomFields = function(contact) {
            let fieldData = contact.field_data || [];
            return _.sortBy($rootScope.customFields, 'sort_index').map(customField => {
                const contactCustomData = fieldData.find(field => field.type === 'custom_field' && field.custom_field_id === customField.custom_field_id);
                if (typeof contactCustomData !== 'undefined') {
                    if (customField.type === 'list') {
                        const selectedItem = customField.list_items.find(item => item.value === contactCustomData.value);
                        if (typeof selectedItem !== 'undefined') {
                            customField.value = selectedItem;
                        }
                    } else {
                        customField.value = contactCustomData.value;
                    }
                } else {
                    customField.value = null;
                }
                return customField;
            });
        };

        $scope.checkRightPanelView = function(currentStateUrl) {
            switch(currentStateUrl) {
                case '/contacts/detail':
                    $scope.data.currentPage = 'contact-details';
                    $('#contact-profile-tab-main').tab('show');
                    break;
                case '/inbox':
                    $scope.data.currentPage = 'inbox';
                    $('#contact-profile-tab-main').tab('show');
                    break;
                default:
                    $scope.data.currentPage = 'default';
                    $('#call-tab-main').tab('show');
            }
        };

        $rootScope.$on('$stateChangeStart', function(e, toState, toParams, fromState, fromParams) {
            $scope.checkRightPanelView(toState.url);
        });

        vm.$onInit = () => {
            $scope.loadNumbers();
            if(!$scope.data.available_dispositions) {
                $scope.loadDispatcher('default');
            }
            $scope.loadContactLists();
            $scope.loadTagsList();

            $('#call-tab-main').on('show.bs.tab', function () {
                $('#right-panel-main-tabs-content .tab-pane').not('#call-main').removeClass('active');
                $scope.setIdleMode('call');
            });
            // $('#text-tab-main').on('show.bs.tab', function () {
            //     $('#right-panel-main-tabs-content .tab-pane').not('#text-main').removeClass('active');
            //     $scope.setIdleMode('text');
            //     $scope.textIdleClear();
            // });
            $('#contact-profile-tab-main').on('show.bs.tab', function () {
                $('#right-panel-main-tabs-content .tab-pane').not('#contact-profile-main').removeClass('active');

            });
            $('#recent-conversations-tab-main').on('show.bs.tab', function () {
                $('#right-panel-main-tabs-content .tab-pane').not('#recent-conversations-main').removeClass('active');

                if($scope.data.tabs.recentConversations.list.length === 0) {
                    $scope.data.tabs.recentConversations.loading = true;
                    $scope.$apply();

                    $scope.loadRecentConversations(()=> {
                        $scope.data.tabs.recentConversations.loading = false;
                        $scope.$apply();
                    });
                }
            });

            $scope.checkRightPanelView($state.current.url);

            // $scope.$watchCollection('data.tabs.text.messages', function(newVal){
            //     console.log(newVal);
            //     setTimeout(function(){
            //         var element = document.getElementById("text-chat-box");
            //         if (element) {
            //             $(element).animate({
            //                 scrollTop: (element.scrollHeight + 100)
            //             }, 500);
            //         }
            //     }, 50);
            // });
            // $scope.$watch('data.tabs.text.mode', function(newVal) {
            //     console.log('data.tabs.text.mode: ' + newVal);
            //     $scope.textTabModeWatch(newVal);
            // });

            $scope.$watch('data.tabs.call.mode', function(newVal) {
                $scope.callTabModeWatch(newVal);
                $rootScope.$broadcast('callModeRightSideBar', newVal);
            }, true);

            $scope.data.startDialing.startDialingEvent = $rootScope.$on('startDialing:broadcast', function (event, list) {
                if(!$rootScope.isBalance() && !$rootScope.isSubscribedOutboundCommunicationPlan()) {
                    $rootScope.showUpsellModalOutboundCommunicationSwal();
                    return;
                }
                const totalContacts = $scope.data.contactLists.reduce((partialSum, a) => partialSum + a.import.accepted_count, 0);
                if($rootScope.noOptIn() && ((list && list.contact_count > 1) || (!list && totalContacts > 1))) {
                    $rootScope.showNoBulkModalSwal();
                    return;
                }
                $scope.data.startDialing.list = list;
                $scope.data.startDialing.mode = 'list';
                $scope.openCallTab(list);
            });

            // angular.element($window).bind('resize', function(){
            //     $scope.checkHeightTextTab();
            // });

            $scope.timerInterval = $interval($scope.updateDuration, 1000);
        };

        $scope.$on('teamNumbersLoaded', function() {
            $scope.unsetCallerId();
            $scope.loadNumbers();
        });

        $scope.$on('teamNumbersLoaded', function(event, args) {
            $scope.data.rentedNumbers = undefined;
            $scope.loadNumbers();
        });

        $scope.$on('openCallTab', function(event, contact) {
            if(!$rootScope.isBalance() && !$rootScope.isSubscribedOutboundCommunicationPlan()) {
                $rootScope.showUpsellModalOutboundCommunicationSwal();
                return;
            }
            if($rootScope.noOptIn() && $scope.data.startDialing.mode === 'list') {
                console.log('mode', $scope.data.startDialing.mode, 'openCallTab', 'contact', contact)
                $rootScope.showNoBulkModalSwal();
                return;
            }
            $scope.openCallTab(contact);
        });

        $scope.$on('openTextTab', function(event, contact) {
            // if(!$rootScope.isBalance() && !$rootScope.isSubscribedOutboundCommunicationPlan()) {
            //     $rootScope.showUpsellModalOutboundCommunicationSwal();
            //     return;
            // }

            // $scope.openTextTab(contact);
        });

        $scope.$on('$destroy', function() {
            $interval.cancel($scope.timerInterval);

            // Unbind startDialingEvent
            $scope.data.startDialing.startDialingEvent();
            $scope.data.startDialing.startDialingEvent = null;
        });

        // TEST
        $scope.$on('ivr.queued', function(e, ablyEvent) {
            console.log('ivr.queued');
            // setTimeout(() => {
            //     $scope.interceptCallWebRTC({intercept_cid: ablyEvent.data.call_id});
            //     }, 100);
        });
        // END TEST

        $scope.$on('agent.logged_in', function(e, ablyEvent) {

            // check only on prod env
            if (process.env.RUNTIME_ENV === 'prod' && ablyEvent.data.fingerprint !== $rootScope.fingerprintVisitorId) {
                swal("Informing", "Your account is now in use on another device.", "info");
                authService.logout();
            }

            $scope.$apply();
        });

        $scope.$on('agent.idle', function(e, ablyEvent) {
            // Agent is connected to the dialing system and being prompted for what action they want to perform next

            console.log('Got ' + ablyEvent.event);
            $scope.data.agentState = 'idle';
            $scope.data.startDialing.mode = null;
            $scope.$apply();
        });

        $scope.$on('agent.trace', function(e, ablyEvent) {

            // The system is dialing the agent's phone (they press a button in the UI to place a call to them first then to the contact)

            console.log('Got ' + ablyEvent.event);

            let eventData = ablyEvent.data || {};
            $scope.data.agent_call_id = eventData.call_id;
            $scope.$apply();
        });

        $scope.$on('agent.dialing', function(e, ablyEvent) {

            // The agent is actively dialing contacts waiting for one of them to answer

            console.log('Got ' + ablyEvent.event);

            $scope.data.agentState = 'dialing';
            $scope.data.tabs.call.mode = 'dialing';
            $scope.data.agent_call_id = ablyEvent.data.call_id;
            $scope.data.outboundCalls = {};
            $scope.getRootCall($scope.data.agent_call_id);
            $scope.$apply();
        });

        $scope.$on('agent.talking', function(e, ablyEvent) {

            // The agent is talking to a contact

            console.log('Got ' + ablyEvent.event);

            if($scope.data.agentState !== 'idle') {
                let eventData = ablyEvent.data || {};

                $scope.data.agentState = 'talking';
                $scope.data.tabs.call.mode = 'talking';
                $scope.data.ongoing_outbound_call_id = eventData.contact_call_id;
                $scope.data.tabs.call.duration = 0;

                let contactCallData = {
                    user_id: eventData.user_id,
                    call_id: eventData.contact_call_id,
                    list_id: eventData.list_id,
                    contact_id: eventData.contact_id,
                    dispatcher_id: eventData.dispatcher_id
                };

                $scope.__dequeueOutboundOtherCalls(contactCallData);

                $scope.enrichCall(contactCallData)
                    .then(call => {
                        console.log('Talking to:');
                        console.log(call);
                        $scope.data.tabs.call.talkingToContact = call.contact;
                        $scope.data.tabs.call.talkingToContact.customFields = $scope.formatContactCustomFields($scope.data.tabs.call.talkingToContact);
                        $scope.$apply();
                    })
                    .catch(err => {
                        console.error(err);
                    });
            }
        });

        $scope.$on('agent.wrapup', function(e, ablyEvent) {

            // The contact hung up and the system is asking the agent to disposition the call

            console.log('Got ' + ablyEvent.event);
            let call = ablyEvent.data || {};
            $scope.data.tabs.call.mode = 'wrapup';
            $scope.data.agentState = 'wrapup';
            $scope.$apply();
        });

        $scope.$on('agent.hangup', function(e, ablyEvent) {

            // The contact hung up and the system is asking the agent to disposition the call

            console.log('Got ' + ablyEvent.event);
            $scope.hangUpClearCall();

            $scope.$apply();
        });

        $scope.$on('agent.logged_in', function(e, ablyEvent) {

            // check only on prod env
            if (process.env.RUNTIME_ENV === 'prod' && ablyEvent.data.fingerprint !== $rootScope.fingerprintVisitorId) {
                swal("Informing", "Your account is now in use on another device.", "info");
                authService.logout();
            }

            $scope.$apply();
        });

        $scope.$on('webrtc.ua.disconnected', function(e, ablyEvent) {

            // The webrtc connection to the server was disconnected.  This could be because a call terminated normally...
            // Or...it could be that the JsSip was unable to establish a connection with the call control service.

            console.log('Got webrtc.ua.disconnected');
            $scope.clearCall();

            $scope.$apply();
        });

        $scope.$on('call.setup', function(e, ablyEvent) {

            // The dialing system is informing us that it's about to dial a contact.
            // We can prefetch the contact details here...

            console.log('Got ' + ablyEvent.event);

            $('#dispositions-collapse').collapse('hide');
            $scope.$apply();
        });

        $scope.$on('call.ringing', function(e, ablyEvent) {

            // The contact's call leg is ringing

            console.log('Got ' + ablyEvent.event);

            // Don't display any calls that come in while the agent is talking
            if ($scope.data.agentState !== 'talking' && $scope.data.agentState !== 'wrapup') {
                let eventData = ablyEvent.data || {};
                $scope.__enqueueOutboundCall(eventData);
            }
            else {
                console.log('Ignoring call, agents state = ' + $scope.data.agentState);
            }
            $scope.$apply();
        });

        $scope.$on('call.answer', function(e, ablyEvent) {

            // The contact answered the call (in predictive mode several contacts may answer at the same time
            // so you can't assume this is the contact the agent is talking to)

            console.log('Got ' + ablyEvent.event);

            let eventData = ablyEvent.data || {};

            // TODO: we need to wait for the call to become available - if the enqueue process is still fetching

            $scope.__updateOutboundCallState(eventData);
            $scope.$apply();
        });

        $scope.$on('call.abandon', function(e, ablyEvent) {

            // The predictive dialer had to abandon this contact call because the agent is already talking to another contact

            console.log('Got ' + ablyEvent.event);

            let eventData = ablyEvent.data || {};
            //$scope.__updateOutboundCallState(call);
            $scope.__dequeueOutboundCall(eventData);
            $scope.$apply();
        });

        $scope.$on('call.parked', function(e, ablyEvent) {

            // The predictive dialer parked the contact call waiting for another agent to become available prior to abandoning it

            console.log('Got ' + ablyEvent.event);

            let eventData = ablyEvent.data || {};
            $scope.__updateOutboundCallState(eventData);
            $scope.$apply();
        });

        $scope.$on('call.hangup', function(e, ablyEvent) {

            // The contact hung up (in predictive dialing more, you can't assume this is the contact that the agent is talking to)

            console.log('Got ' + ablyEvent.event);

            let eventData = ablyEvent.data || {};
            $scope.__dequeueOutboundCall(eventData);
            $scope.$apply();
        });

        $scope.$on('call.inbound.queued', function(e, ablyEvent) {

            // For future use...not implemented in the dialing system yet

            console.log('Got ' + ablyEvent.event);

            let call = ablyEvent.data || {};
            call.call_state = 'queued';
            call.occurred_at = ablyEvent.occurred_at;
            $scope.__enqueueInboundCall(call);
            $scope.$apply();
        });

        $scope.$on('sms.received', function(e, payload) {

            // TODO: Show toaster if user not in DND mode

            payload.direction = 'inbound';
            $scope.processEvent(payload);
            $scope.$apply();
        });

        $scope.$on('sms.sent', function(e, payload) {
            payload.direction = 'outbound';
            $scope.processEvent(payload);
            $scope.$apply();
        });

        $scope.$on('fax.failed', function(e, payload) {
            payload.direction = 'outbound';
            $scope.processEvent(payload);
            $scope.$apply();
        });

        $scope.$on('fax.sent', function(e, payload) {
            payload.direction = 'outbound';
            $scope.processEvent(payload);
            $scope.$apply();
        });

        $scope.$on('fax.received', function(e, payload) {
            payload.direction = 'outbound';
            $scope.processEvent(payload);
            $scope.$apply();
        });

        $scope.$on('ivr.forward', function(e, payload) {

            let data = payload.data || {};
            const user = authService.getUser();

            // If DND is on, don't display notifications
            if (user.do_not_disturb) {
                return;
            }

            $scope.__showScreenPop(data);
            $scope.$apply();
        });

        $scope.$on('ivr.queued', function(e, payload) {

            let data = payload.data || {};
            const user = authService.getUser();

            // If DND is on, don't display notifications
            if (user.do_not_disturb) {
                return;
            }

            // IF ON A CALL, SHOW TOASTER
            $scope.__showScreenPop(data);
            $scope.$apply();
        });

        $scope.$on('ivr.missed', function(e, payload) {

            let data = payload.data || {};
            $scope.__hideScreenPop(data);
        });

        $scope.$on('ivr.hangup', function(e, payload) {

            let data = payload.data || {};
            $scope.__hideScreenPop(data);
        });
    }
    module.exports = IncomingOutgoingCallsController;
})();
