(function() {
    'use strict';

    function RecentCallsController(authService, phoneService, $scope, $location, $rootScope, $window, $interval, $cookies, notificationToastService) {
        var vm = this;
        vm.authService = authService;
        
        $rootScope.viewData = $rootScope.viewData || {};
        $rootScope.viewData.breadcrumbs = $rootScope.viewData.breadcrumbs || [];
        $rootScope.viewData.breadcrumbs = [{
            title: 'Recent Calls',
            link_name: 'Recent Calls',
            link_url: '#/recent-calls',
            help_url: $rootScope.help_url
        }];

        $scope.staticContent = {
            title: 'Recent Calls',
            subtitle: 'Check out your recent calls',
            searchInputPlaceholder: 'Search...',
            headerMenu: [ 'Today', 'Yesterday', 'Custom Date', 'Export', 'Processing', 'Download Available' ],
            callMenu: [ 'Dial', 'Send Text', 'View Contact', 'Unhide Call', 'Hide Call', 'Show Full Transcript' ],
            views: ['Default', 'New', 'Hidden', 'All'],
            infoMessages: {
                noAnswer: 'No Answer'
            },
            loading: 'Loading',
            noRes: 'No Results',
            transcription_header: 'From: :from To: :to Start Time: :start_time Duration: :duration \n\n'
        };

        $scope.chosenDatePickerOption = undefined;

        $scope.ranges = {
            'Today': { start: moment().startOf('day'), end: moment().startOf('day') },
            'Yesterday': { start: moment().subtract(1, 'days').startOf('day'), end: moment().subtract(1, 'days').startOf('day') },
            'Last 7 Days': { start: moment().subtract(6, 'days').startOf('day'), end: moment().endOf('day') },
            'All Time': { start: moment().startOf('day'), end: moment().startOf('day') },
        };

        $scope.calls = [];
        $scope.search = {
            term: '',
            submit: false
        };
        $scope.selectedListId = $location.search().listId;
        $scope.view = 'Default';
        $scope.viewAll = false;

        $scope.loading = {
            calls: false,
            callsFirstLoad: true,
            numbers: true,
            transcript: false
        };

        $scope.export = {
            id: null,
            status: null,
            link: null
        };

        $scope.currentPage = 1;
        $scope.itemsPerPage = 25;
        $scope.maxSize = 3;
        $scope.totalItems = 0;

        // player start
        $scope.currentOpenedPlayerId = -1;
        $scope.$on('currentOpenedRecordChanged', function(event, newCurrentOpenedPlayerId, recordType) {
            $scope.currentOpenedPlayerId = newCurrentOpenedPlayerId;
        });
        $scope.$on('audioObjectChanged', function(event, audioObject, index, recordType) {
            $scope.calls[index].audio = audioObject;
        });
        // player end

        $scope.isContactName = function(call) {
            if((!call.first_name || call.first_name === '' || call.first_name === null) && (!call.last_name || call.last_name === '' || call.last_name === null)) {
                return false;
            }
            return true;
        };

        $scope.textContact = function(call) {
            $rootScope.openTextArea(call);
        };

        $scope.callContact = function(call) {
            $rootScope.$broadcast('openCallTab', call);
        };

        $scope.hasPermissions = function(entity, action) {
            return $rootScope.hasPermissions(entity, action)
        };

        $scope.setHidden = function(call, index) {
            let req = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                call_id: call.call_id,
                hidden: !call.hidden
            };

            $scope.loading.calls = true;

            phoneService.updateCall(req)
                .then(results => {
                    $scope.calls.splice(index, 1);
                    $scope.loading.calls = false;
                    $scope.$apply();
                    notificationToastService.showSuccessToast('fa-sharp fa-light fa-circle-check', 'Call ' + (!call.hidden ? 'hidden.' : 'unhidden.'));
                })
                .catch(err => {
                    console.log(err);
                    $scope.loading.calls = false;
                    $scope.$apply();
                    notificationToastService.showErrorToast('fa-light fa-triangle-exclamation', 'Unable to ' + (!call.hidden ? 'hide call.' : 'unhide call.'));
                });
        };

        $scope.setPage = function (pageNo) {
            $scope.currentPage = pageNo;
        };

        $scope.pageChanged = function() {
            $scope.loadCalls('calls', (loader)=>{
                $scope.loading[loader] = false;
                $scope.$apply();
            });
        };

        $scope.setItemsPerPage = function(num) {
            $scope.itemsPerPage = num;
            $scope.currentPage = 1; //reset to first page
        };

        $scope.viewContactDetails = function(call) {
            $location.path('/contacts/detail').search('id=' + call.contact_id);
        };

        $scope.getFullTranscription = function(call, index) {
            $scope.loading.transcript = true;
            $('#transcriptCallModal').modal('show');

            let req = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                recording_id: call.call_recording.call_recording_id,
            };

            phoneService.getTranscription(req)
                .then(transcription => {
                    $scope.transcript = {
                        conversation: transcription,
                        details: $scope.calls[index]
                    };
                    $scope.loading.transcript = false;
                    $scope.$apply();
                })
                .catch(err => {
                    console.log(err);
                });
        };

        $scope.buildTranscriptExport = function() {

            let fullText =  $scope.staticContent.transcription_header
                .replace(':from', $scope.transcript.details.from)
                .replace(':to', $scope.transcript.details.to)
                .replace(':start_time', moment($scope.transcript.details.started_at))
                .replace(':duration', $scope.formatSecondsToMinutes($scope.transcript.details.duration));

            for (var sentence of  $scope.transcript.conversation) {
                let speaker = sentence.label === 'ch_0' ? 'Contact' : 'Agent';
                fullText += '[' + speaker + ']: ' + sentence.sentence + '\n';
            }

            return fullText;
        };

        $scope.downloadTranscript = function() {
            let element = document.createElement('a');
            let text = $scope.buildTranscriptExport();

            element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
            element.setAttribute('download', 'transcript_' + $scope.transcript.details.call_recording.call_recording_id + '.txt');

            element.style.display = 'none';
            document.body.appendChild(element);

            setTimeout(function() {
                element.click();
                document.body.removeChild(element);
            });
        };

        $scope.exportCalls = function() {
            var teamId = authService.getTeamId();
            var userId = authService.getUserId();

            const request = {
                team_id: teamId,
                user_id: userId,
                call_type: 'contact',
                minTs: $scope.minTs,
                maxTs: $scope.maxTs
            };

            if(!$scope.contactId) {
                request.omit_missed = true;
            }

            if($scope.selectedListId){
                request.list_id =  $scope.selectedListId;
            }

            if($scope.contactId){
                request.contact_id =  $scope.contactId;
            }

            if($scope.view) {
                $.extend(request, $scope.__getViewParams());
            }

            if($scope.search.term){
                request.search_term =  $scope.search.term;
            }

            phoneService.exportCalls(request)
                .then(result => {
                    $scope.export.id = result.export_id;
                    $scope.export.status = result.status;
                    $scope.$apply();
                })
                .catch(err => {
                    console.error(err);
                    $scope.$apply();
                });
        };

        $scope.__checkExportStatus = function() {

            if ($scope.export.id && $scope.export.status === 'pending') {

                const request = {
                    team_id: authService.getTeamId(),
                    user_id: authService.getUserId(),
                    export_id: $scope.export.id
                };

                phoneService.getExportStatus(request)
                    .then(result => {
                        $scope.export.status = result.status;
                        $scope.export.link = result.link;
                        if($scope.export.status === 'done'){
                            window.open($scope.export.link, '_self');
                            setTimeout(()=>{
                                $scope.export.status = undefined;
                            }, 100);
                        }
                        $scope.$apply();
                    })
                    .catch(err => {
                        console.error(err);
                        $scope.$apply();
                    });
            }
        };

        $scope.formatSecondsToMinutes = function (seconds = 0) {
            var minutes = Math.floor(seconds / 60);
            var secondsRes = (seconds % 60).toFixed(0);
            return minutes + ':' + (secondsRes < 10 ? '0' : '') + secondsRes;
        };

        $scope.__getViewParams = function () {
            switch($scope.view) {
                case 'New':
                    return { only_new: true };
                case 'Hidden':
                    return { hidden: true };
                case 'All':
                    return { all: true };
                case 'Default':
                default:
                    return {};
            }
        };

        $scope.loadCalls = function(loader, callback) {
            $scope.loading[loader] = true;
            $window.scrollTo(0, 0);
            var teamId = authService.getTeamId();
            var userId = authService.getUserId();

            // TODO: Make these selectable in the UI
            let callTypes = "contact,external";
            let direction = null;
            let wantMissedCalls = true;

            const request = {
                team_id: teamId,
                user_id: userId,
                names: true,
                call_type: callTypes,
                minTs: $scope.minTs,
                // minTs: 1612130400000,
                maxTs: $scope.maxTs,
                skip: ($scope.currentPage - 1) * $scope.itemsPerPage,
                limit: $scope.itemsPerPage,
                include_total: true,
                with_recording: true,
            };

            if (direction) {
                request.direction = direction;
            }

            if(!$scope.contactId) {
                if (!wantMissedCalls) {
                    request.omit_missed = true;
                }
            } else {
                request.contact_id = $scope.contactId
            }

            if($scope.view) {
                $.extend(request, $scope.__getViewParams());
            }

            if($scope.selectedListId){
                request.list_id =  $scope.selectedListId;
            }

            if($scope.search.term){
                request.search_term =  $scope.search.term;
            }

            phoneService.getCalls(request)
                .then(calls => {
                    $scope.totalItems = calls.total;
                    $scope.calls = calls.calls;

                    $scope.calls.map(call => {
                        call.contact_number = call.call_direction === 'outbound' ? call.to : call.from;
                    });

                    $rootScope.$broadcast('calls_seen');
                })
                .catch(err => {
                    console.error(err);
                })
                .then(() => {
                    callback(loader);
                });
        };

        $scope.clearSearchCalls = function() {
            $scope.search.submit = false;
            $scope.search.term = '';
            $scope.setPage(1);
            $scope.loadCalls('calls', (loader)=> {
                $scope.loading[loader] = false;
                $scope.$apply();
            });
        };

        $scope.searchCalls = function(view) {
            $scope.search.term = $.trim($scope.search.term);
            if($scope.search.term !== '') {
                $scope.search.submit = true;

                $scope.loadCalls('calls', (loader)=> {
                    $scope.loading[loader] = false;
                    $scope.$apply();
                });
            } else if($scope.search.submit) {
                $scope.clearSearchCalls();
            }
        };

        $scope.filterCalls = function(view) {
            if(view !== $scope.view) {
                $scope.view = view;
                $scope.setPage(1);
                $scope.loadCalls('calls', (loader)=> {
                    $scope.loading[loader] = false;
                    $scope.$apply();
                });
            }
        };

        $('#reportrange').on('apply.daterangepicker', function(ev, picker) {
            var currentLabel = picker.chosenLabel;

            if($scope.chosenDatePickerOption && ($scope.chosenDatePickerOption !== currentLabel || currentLabel === 'Custom Range')) {
                var minTs = (currentLabel === 'All Time') ? null : picker.startDate._d.getTime();
                var maxTs = (currentLabel === 'All Time') ? null : picker.endDate._d.getTime();
                $scope.updateDateRangeInput(picker.startDate, picker.endDate, currentLabel, minTs, maxTs);

                $scope.setPage(1);
                $scope.loadCalls('calls', (loader)=> {
                    $scope.loading[loader] = false;
                    $scope.$apply();
                });
            }
            $('#reportrange').data('daterangepicker').hideCalendars();
        });

        $('#reportrange').on('showCalendar.daterangepicker', function(ev, picker) {
            if($('.daterangepicker .ranges ul li[data-range-key="All Time"]').hasClass('active') &&
                $scope.chosenDatePickerOption === 'All Time' &&
                $('.daterangepicker').hasClass('show-calendar')) {

                $('.daterangepicker .ranges ul li[data-range-key="All Time"]').removeClass('active');
                $('.daterangepicker .ranges ul li[data-range-key="Today"]').addClass('active');
            }
        });

        $('#reportrange').on('show.daterangepicker', function(ev, picker) {
            if($('.daterangepicker .ranges ul li[data-range-key="Today"]').hasClass('active') &&
                $scope.chosenDatePickerOption === 'All Time' &&
                !$('.daterangepicker').hasClass('show-calendar')) {

                $('.daterangepicker .ranges ul li[data-range-key="Today"]').removeClass('active');
                $('.daterangepicker .ranges ul li[data-range-key="All Time"]').addClass('active');
            }

            $('#reportrange i').addClass('main-blue-color-important');
            $('#reportrange span').addClass('main-blue-color-important');
        });

        $('#reportrange').on('hide.daterangepicker', function(ev, picker) {
            $('#reportrange i').removeClass('main-blue-color-important');
            $('#reportrange span').removeClass('main-blue-color-important');
        });

        $scope.updateDateRangeInput = function(start, end, currentLabel, minTs, maxTs) {
            $scope.chosenDatePickerOption = currentLabel;

            var startFormat = start.format('MMM D, YYYY');
            var endFormat = end.format('MMM D, YYYY');
            var htmlText = (currentLabel === 'All Time') ? 'All Time' : startFormat + ' - ' + endFormat;
            $('#reportrange span').html(htmlText);

            $scope.minTs = minTs;
            $scope.maxTs = maxTs;
        };

        $scope.initDateRange = function(_label) {
            var initLabel = _.has($scope.ranges, _label) ? _label : 'Last 7 Days';
            var start = $scope.ranges[initLabel].start;
            var end = $scope.ranges[initLabel].end;
            var minTs = (initLabel === 'All Time') ? null : start.valueOf();
            var maxTs = (initLabel === 'All Time') ? null : end.valueOf();

            $('#reportrange').daterangepicker({
                startDate: start,
                endDate: end,
                opens: 'left',
                ranges: {
                    'Today': [$scope.ranges['Today'].start, $scope.ranges['Today'].end],
                    'Yesterday': [$scope.ranges['Yesterday'].start, $scope.ranges['Yesterday'].end],
                    'Last 7 Days': [$scope.ranges['Last 7 Days'].start, $scope.ranges['Last 7 Days'].end],
                    'All Time': [$scope.ranges['All Time'].start, $scope.ranges['All Time'].end],
                }
            });

            $scope.updateDateRangeInput($scope.ranges[initLabel].start, $scope.ranges[initLabel].end, initLabel, minTs, maxTs);
        }

        $scope.onInit = function() {
            $scope.viewAll = $location.search().viewAll;
            $scope.contactId = $location.search().contactId;
            $scope.initDateRange($scope.viewAll ? 'All Time' : 'Last 7 Days');

            $scope.loadCalls('callsFirstLoad', (loader)=> {
                $scope.loading[loader] = false;
                $scope.$apply();
            });
        };

        $scope.exportTime = $interval($scope.__checkExportStatus, 5000);

        $scope.$on("$destroy", function () {
            $interval.cancel($scope.exportTime);
        });

        if (authService.hasAccount()) {
            $scope.onInit();
        }
        else {
            $scope.$on('auth_complete', function(event, args) {
                $scope.onInit();
            });
        }

        // OLD VERSION
        // $scope.forms = {
        //     sms: {
        //         to: '',
        //         from: '',
        //         body: '',
        //         campaign_id: null,
        //         action: 'Say',
        //         compliance: false,
        //         phone_selected: null,
        //         is_valid: undefined,
        //         submited: false,
        //         sending: false
        //     },
        //     call: {
        //         to: '',
        //         from: '',
        //         body: '',
        //         compliance: false,
        //         phone_selected: null,
        //         is_valid: undefined,
        //         submited: false,
        //         calling: false
        //     }
        // };
        //
        // $scope.rented_numbers = null;
        //
        // $scope.setPhoneMask = function(elem, mask, placeholder) {
        //     elem.mask(mask, {
        //         placeholder: placeholder
        //     });
        // };
        //
        // $scope.modalPhoneNumberMask = function(number, type) {
        //     var phoneNumberId = $("#" + type + "ToPhoneNumber");
        //     var phoneNumberIdInput = document.querySelector("#" + type + "ToPhoneNumber");
        //     const typeIti = type === 'call' ? 'addCallIti' : 'addSmsIti';
        //
        //     if(phoneNumberId) {
        //         $scope[typeIti] = window.intlTelInput(phoneNumberIdInput, {
        //             nationalMode: "false",
        //             autoPlaceholder: 'aggressive',
        //             separateDialCode: "true",
        //             initialCountry: 'us',
        //             utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/11.0.11/js/utils.js" ,
        //             customPlaceholder: function(selectedCountryPlaceholder, selectedCountryData) {
        //                 var pl = selectedCountryPlaceholder.replace(/[0-9]/g, '0');
        //                 phoneNumberId.attr('maxlength', pl.length);
        //                 $scope.setPhoneMask(phoneNumberId, pl, pl);
        //                 return pl;
        //             }
        //         });
        //
        //         if(number){
        //             $scope[typeIti].setNumber(number);
        //         }
        //
        //         phoneNumberId.on("countrychange", function(e) {
        //             $scope[typeIti].setNumber("");
        //             phoneNumberId.unmask();
        //
        //             var mask = e.target.placeholder.replace(/[0-9]/g, '0');
        //             phoneNumberId.attr('maxlength', mask.length);
        //             $scope.setPhoneMask(phoneNumberId, mask, e.target.placeholder);
        //         });
        //     }
        // };
        // $scope.clearIti = function(type) {
        //     const typeIti = type === 'call' ? 'addCallIti' : 'addSmsIti';
        //     $scope.forms[type].to_is_valid_phone_number = undefined;
        //     $scope[typeIti].setNumber("");
        //     $("#" + type + "ToPhoneNumber").unmask();
        //     $scope[typeIti].destroy();
        // };
        //
        // $scope.formatPhone = function(type) {
        //     const typeIti = type === 'call' ? 'addCallIti' : 'addSmsIti';
        //     if($scope[typeIti]) {
        //         $scope.forms[type].to_is_valid_phone_number = $scope[typeIti].isValidNumber();
        //         $scope.forms[type].to = ($scope.forms[type].to_is_valid_phone_number) ? $scope[typeIti].getNumber(window.intlTelInput.utils.numberFormat.E164) : '';
        //         $scope.validateFields(type);
        //     }
        // };
        //
        // $scope.validateFields = function(type) {
        //     $scope.forms[type].is_valid = !!($scope.forms[type].to_is_valid_phone_number && $scope.forms[type].from && $scope.forms[type].compliance);
        // };
        //
        // $scope.callContactClear = function() {
        //
        //     if($scope.addCallIti) {
        //         $scope.clearIti('call');
        //     }
        //
        //     $scope.forms.call = {
        //         compliance: false,
        //         is_valid: undefined,
        //         submited: false,
        //         calling: false
        //     };
        // };
        // $scope.setSelectedPhone = function(value, type) {
        //     if (value) {
        //         $scope.forms[type].phone_selected = value;
        //         $scope.forms[type].from = value;
        //     }
        //     $scope.validateFields(type);
        // };
        // $scope.sendSms = function(data) {
        //
        //     let req = {
        //         team_id: authService.getTeamId(),
        //         user_id: authService.getUserId(),
        //         phone_number: $scope.forms.sms.to,
        //         caller_id: $scope.forms.sms.from,
        //         list_id: $scope.selectedListId,
        //         contact_id: $scope.forms.sms.contact_id,
        //         sms_body: $scope.forms.sms.body,
        //         action: 'Say'
        //     };
        //
        //     phoneService.sendSms(req)
        //         .then(results => {
        //             //swal("Success", "Text message sent.", "success");
        //             $scope.forms.sms.sending = false;
        //             $("#smsModal").modal('hide');
        //         })
        //         .catch(err => {
        //             swal("Cancelled", "Unable to send text message.\n\nMessages to this contact may be blocked.", "error");
        //             $scope.forms.sms.sending = false;
        //             $("#smsModal").modal('hide');
        //         });
        // };
        // $scope.contactCall = function(call) {
        //
        //     let req = {
        //         team_id: authService.getTeamId(),
        //         user_id: authService.getUserId(),
        //         contact_id: $scope.forms.call.contact_id,
        //         user_phone_number: $scope.forms.user_phone_number,
        //         caller_id: $scope.forms.call.from,
        //     };
        //
        //     $cookies.put('userPhoneNumber', $scope.forms.call.to);
        //
        //     phoneService.callContact(req)
        //         .then(results => {
        //             swal("Success", "We're calling you", "success");
        //             $scope.forms.call.sending = false;
        //             $("#callModal").modal('hide');
        //         })
        //         .catch(err => {
        //             swal("Cancelled", "Unable to call contact.\n\nMessages to this contact may be blocked.", "error");
        //             $scope.forms.call.sending = false;
        //             $("#callModal").modal('hide');
        //         });
        // };
        // $scope.loadNumbers = function() {
        //
        //     if(!$scope.rented_numbers){
        //         $scope.rented_numbers = $rootScope.team_phone_numbers.map(number => {return number.phone_number});
        //         $scope.setSelectedPhone($scope.rented_numbers[0], 'sms');
        //     }
        //
        //     if(!$scope.forms.user_numbers){
        //         $scope.forms.user_numbers = authService.getUserNumbers();
        //         $scope.setSelectedPhone($scope.rented_numbers[0], 'call');
        //     }
        // };
    }
    module.exports = RecentCallsController;
})();
