(function() {
    'use strict';

    function DropdownmenuPositionService() {
        return {
            toggleDropUp: function ($event) {
                var dropdownContainer = $event.currentTarget;
                var position = dropdownContainer.getBoundingClientRect().top;
                var buttonHeight = dropdownContainer.getBoundingClientRect().height;

                var buttonParent = dropdownContainer.parentElement;

                var dropdownMenu = $(buttonParent).find('.dropdown-menu');
                var menuHeight = dropdownMenu.height();

                var $win = $(window);

                if (position > menuHeight && $win.height() - position < buttonHeight + menuHeight) {
                    dropdownMenu.css('bottom', 0);
                }
                else {
                    dropdownMenu.css('top', 0);
                }
            },
            toggleDropUpSetMainParent: function($event, mainParentSelector, dropdownTopVariable, offsetUp, offsetDown) {
                var dropdownContainer = $event.currentTarget;
                var dropdownPosition = dropdownContainer.getBoundingClientRect().top;
                var buttonHeight = dropdownContainer.getBoundingClientRect().height;

                var buttonParent = dropdownContainer.parentElement;
                var mainParentHeight = $(mainParentSelector).innerHeight();
                var mainParentPosition = $(mainParentSelector).offset().top;

                var dropdownMenu = $(buttonParent).find('.dropdown-menu');
                var menuHeight = dropdownMenu.outerHeight();
                var position = dropdownPosition - mainParentPosition;

                var positionTop = buttonHeight + offsetDown;
                if((menuHeight + buttonHeight + position + 5) > mainParentHeight) {
                    positionTop = -(menuHeight + offsetUp);
                }

                const root = document.documentElement;
                root.style.setProperty(dropdownTopVariable, positionTop + 'px');
            }
        };
    }
    module.exports = DropdownmenuPositionService;
})();

