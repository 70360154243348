(function() {
    'use strict';

    function IpaasAutomationController($rootScope, $scope, $location, $window, authService, automationService, $sce) {
        var vm = this;
        vm.authService = authService;

        $rootScope.viewData = $rootScope.viewData || {};
        $rootScope.viewData.breadcrumbs = $rootScope.viewData.breadcrumbs || [];
        $rootScope.viewData.breadcrumbs = [{
            title: 'App Mixer',
            link_name: 'App Mixer',
            link_url: '#/automation',
            help_url: $rootScope.help_url
        }];

        $scope.data = {};
        $scope.loading = true;

        $scope.staticText = {};

        $scope.getAccessToken = async () => {
            let auth;
            const username = 'hunter@dropcowboy.com';
            const usertoken = 'HOFeQ7y3Esiktqgrx627p8';

            try {
                auth = await $rootScope.app_automation.api.authenticateUser(username, usertoken);
                $rootScope.app_automation.set('accessToken', auth.token);

                return { token: auth.token };
            } catch (err) {
                if (err.response && err.response.status === 403) {
                    // Virtual user not yet created in Appmixer. Create one.
                    try {
                        auth = await $rootScope.app_automation.api.signupUser(username, usertoken);
                        $rootScope.app_automation.set('accessToken', auth.token);

                        return { token: auth.token };
                    } catch (err) {
                        alert('Something went wrong creating a virtual user. ' + err.message);
                    }
                } else {
                    alert('Something went wrong authenticating a virtual user. '+ err.message);
                }
            }
        };

        $scope.onAutomationAuth = () => {

            if ($rootScope.app_automation) {

                let req = {
                    team_id: authService.getTeamId(),
                    user_id: authService.getUserId()
                };

                $scope.getAccessToken(req)
                    .then(results => {

                        if (results && results.token) {

                            const automations = $rootScope.app_automation.ui.FlowManager({
                                el: '#fm-placeholder',
                                options: {
                                    menu: [
                                        { event: 'flow:open', label: 'Open' },
                                        { event: 'flow:rename', label: 'Rename' },
                                        // { event: 'flow:insights', label: 'Insights' },
                                        { event: 'flow:clone', label: 'Clone' },
                                        { event: 'flow:share', label: 'Share' },
                                        { event: 'flow:remove', label: 'Remove' }
                                    ],
                                    filters: [
                                        { property: 'stage', value: 'running', label: 'Running flows' },
                                        { property: 'stage', value: 'stopped', label: 'Stopped flows' },
                                        { property: 'sharedWith', value: 'myFlows', label: 'My flows' },
                                        { property: 'sharedWith', value: 'sharedWithOthers', label: 'Shared with others' },
                                        { property: 'sharedWith', value: 'sharedWithMe', label: 'Shared with me' }
                                    ],
                                    sorting: [
                                        { label: 'Last Modified', property: 'mtime', value: -1 },
                                        { label: 'Last Created', property: 'btime', value: -1 }
                                    ]
                                }
                            });

                            automations.state('layout', 'list');

                            const designer = $rootScope.app_automation.ui.Designer({
                                el: '#fd-placeholder',
                                options: {
                                    showButtonHome: true,
                                    menu: [
                                        { event: 'flow:rename', label: 'Rename' }
                                    ],
                                    toolbar: [
                                        ['undo', 'redo'],
                                        ['zoom-to-fit', 'zoom-in', 'zoom-out'],
                                        ['logs']
                                    ]
                                }
                            });

                            automations.on('flow:open', flowId => {
                                document.querySelector('bread-crumb-component').style.display = 'none';
                                document.querySelector('#fm-placeholder').style.height = '85vh';

                                designer.close();
                                designer.set('flowId', flowId);
                                designer.open();
                            });

                            designer.on('navigate:flows', () => {
                                document.querySelector('bread-crumb-component').style.display = 'block';
                                document.querySelector('#fm-placeholder').style.height = '75vh';

                                designer.close();
                                automations.reload();
                            });

                            automations.open();

                            $scope.app_automation_available = true;
                            $scope.loading = false;
                        }
                        else {
                            $scope.loading = false;
                        }
                        $scope.$apply();
                    })
                    .catch(err => {
                        console.log(err);
                        $scope.loading = false;
                        $scope.$apply();
                    });
            }
            else {
                $scope.loading = false;
            }
        }

        $scope.onInit = () => {
            $scope.onAutomationAuth();
        }

        if (authService.hasAccount()) {
            $scope.onInit();
        }
        else {
            $scope.$on('auth_complete', function(event, args) {
                $scope.onInit();
            });

            $scope.$on('automation_auth', function(event, args) {
                $scope.onAutomationAuth();
            });
        }
    }
    module.exports = IpaasAutomationController;
})();
