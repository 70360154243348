(function () {

    'use strict';

    function CampaignsController(authService, $rootScope, $scope, $window, campaignService, $location, dropdownmenuPositionService) {
        var vm = this;
        vm.authService = authService;

        $rootScope.viewData = $rootScope.viewData || {};
        $rootScope.viewData.breadcrumbs = $rootScope.viewData.breadcrumbs || [];
        $rootScope.viewData.breadcrumbs = [{
            title: 'RVM Campaigns',
            link_name: 'RVM Campaigns',
            link_url: '#/bulk-campaigns',
            help_url: $rootScope.help_url
        }];

        $scope.staticContent = {
            title: 'Ringless Voicemail & SMS Campaigns',
            subtitle: 'Find a summary of your campaign activity below...',
            addBtn: {
                title: "Create New Campaign"
            },
            analyticsBtn: {
                title: "Analytics"
            },
            campaignMenu: [ 'Edit Campaign',  'Start Campaign', 'Pause Campaign', 'Delete Campaign'],
            headerMenu: [ 'Today', 'Yesterday', 'Custom Date', 'Export', 'Processing', 'Download Available' ],
            callMenu: [ 'Dial', 'Send Text', 'View Contact', 'Unhide Call', 'Hide Call', 'Show Full Transcript' ],
            tableHeaderMenu: [ 'Next Delivery', 'Speed', 'Progress'],
            statusLabels: [ 'NOT STARTED', 'ACTIVE', 'PAUSED', 'Complete', 'INSUFFICIENT CREDIT'],
            views: ['Default', 'New', 'Hidden', 'All'],
            infoMessages: {
                noAnswer: 'No Answer'
            },
            loading: 'Loading',
            noRes: 'No Results',
            loader: {
                loading: 'Loading'
            }
        };

        $scope.campaignStatus = {
            NOT_STARTED: 'not_started',
            ACTIVE: 'active',
            PAUSED: 'paused',
            COMPLETE: 'complete',
            INSUFFICIENT_CREDIT: 'insufficient_credit'
        };

        $scope.loading = {
            campaigns: true
        };

        $scope.data = {
            campaigns: [],
            searchTerm: ($location.search().searchTerm || ''),
        };

        $scope.dropdownmenuPositionService = dropdownmenuPositionService;

        $scope.totalItems = 0;
        $scope.currentPage = 1;
        $scope.itemsPerPage = 8;
        $scope.maxSize = 3;

        $scope.setPage = function (pageNo) {
            $scope.currentPage = pageNo;
        };

        $scope.pageChanged = function() {
            $window.scrollTo(0, 0);
        };

        $scope.setItemsPerPage = function(num) {
            $scope.itemsPerPage = num;
            $scope.currentPage = 1; //reset to first page
        };

        $scope.hasPermissions = function(entity, action) {
            return $rootScope.hasPermissions(entity, action)
        };

        $scope.viewResults = function(campaignId) {
            $location.path('/voicemail-campaign-results').search({campaign_id: campaignId});
        };

        $scope.onInit = function() {
            $window.scrollTo(0, 0);
            $scope.loadCampaigns(()=> {});
        };

        $scope.loadCampaigns = function(callback) {

            $window.scrollTo(0, 0);

            const teamId = authService.getTeamId();
            const userId = authService.getUserId();

            const request = {
                team_id: teamId,
                user_id: userId,
                skip: ($scope.currentPage - 1) * $scope.itemsPerPage,
                limit: $scope.itemsPerPage
            };

            if ($location.search().searchTerm) {
                request.search_term = $location.search().searchTerm;
            }

            $scope.loading.campaigns = true;

            campaignService.getCampaigns(request)
                .then(results => {
                    let campaignResults = results || {}
                    $scope.totalItems = campaignResults.totalItems || 0;
                    $scope.data.campaigns = campaignResults.campaigns || [];

                    // Compute progress
                    let campaigns = $scope.data.campaigns;
                    for (let i = 0; i < campaigns.length; i++) {
                        let campaign = campaigns[i];
                        let campaignData = (campaign || {}).campaign_data || {};
                        let progressCount = campaignData.progress_count || 0;
                        let totalCount = Math.max(progressCount, Math.max(1, campaignData.contact_count || 0));

                        campaign.total_contacts = totalCount;
                        campaign.progress_contacts = progressCount;
                        campaign.progress = Math.max(0, Math.min(100, Math.ceil(progressCount / totalCount * 100)));

                        campaign.drip_rate = '';
                        switch (campaign.delivery_type) {
                            case 'drip': {
                                campaign.drip_rate = ((campaignData.drip || {}).rate || 0).toLocaleString() + '/hour';
                                break;
                            }
                            case 'immediate': {
                                campaign.drip_rate = 'maximum';
                                break;
                            }
                        }
                    }

                    $scope.loading.campaigns = false;
                    $scope.$apply();
                    callback();
                })
                .catch(err => {
                    console.error(err);
                    $scope.loading.campaigns = false;
                    $scope.$apply();
                    callback();
                });
        };

        $scope.campaignDetails = function(item) {
            $location.path('/voicemail-campaign-results').search({campaign_id: item.campaign_id});
        };

        $scope.editCampaign = function(item) {
            $location.path('/voicemail-campaign').search({campaign_id: item.campaign_id});
        };

        $scope.confirmDelete = function(camp, callback) {

            const teamId = authService.getTeamId();
            const userId = authService.getUserId();

            let req = {
                team_id: teamId,
                user_id: userId,
                campaign_id: camp.campaign_id
            };

            campaignService.deleteCampaign(req)
                .then(results => {
                    $scope.loadCampaigns(callback);
                })
                .catch(err => {
                    console.log(err);
                    callback(err);
                });
        };

        $scope.deleteCampaign = function(camp) {
            if(camp.type !== 'api') {
                swal({
                    title: "Confirm",
                    text: 'Are you sure you want to delete "' + camp.name + '"?',
                    buttons: ["No", "Yes"],
                    dangerMode: true,
                    closeOnClickOutside: false
                }).then(confirm => {
                    if (confirm) {
                        $scope.confirmDelete(camp, function (err) {
                            if (!err) {
                                swal("Deleted!", "This campaign has been deleted!", "success");
                            } else {
                                swal("Cancelled", "Unable to delete campaign", "error");
                            }
                        });
                    }
                });
            }
        };

        $scope.startCampaign = function(item) {

            const teamId = authService.getTeamId();
            const userId = authService.getUserId();

            const request = {
                team_id: teamId,
                user_id: userId,
                campaign_id: item.campaign_id
            };

            campaignService.startCampaign(request)
                .then(result => {
                    swal("Success!", "The campaign started successfully.", "success")
                        .then(() => {
                            $scope.onInit();
                        });
                })
                .catch(err => {
                    if (err.message === 'Approval Required') {
                        swal("Approval Required", "We placed a temporary hold on your campaign.\n\nA human needs to review your account before the campaign can proceed.  This normally takes less than an hour.  Once approved, your campaign will proceed as scheduled.  For more information, feel free to email " + $rootScope.settings.APP_INFO_EMAIL + " or chat with us.", "warning");
                    } else {
                        swal("Error", "Something went wrong.", "error");
                    }
                });
        };

        $scope.pauseCampaign = function(item) {

            const teamId = authService.getTeamId();
            const userId = authService.getUserId();

            const request = {
                team_id: teamId,
                user_id: userId,
                campaign_id: item.campaign_id
            };

            campaignService.pauseCampaign(request)
                .then(result => {
                    swal("Success!", "The campaign paused successfully.", "success")
                        .then(() => {
                            $scope.onInit();
                        });
                })
                .catch(err => {
                    console.error(err);
                    swal("Error", "Something went wrong.", "error");
                });
        };

        $scope.updateLocation = function(updateSearchTerm) {
            let search = {};
            if (updateSearchTerm) {
                if ($scope.data.searchTerm) { search.searchTerm = $scope.data.searchTerm; }
            } else if ($location.search().searchTerm) {
                search.searchTerm = $location.search().searchTerm;
            }
            $location.path('/bulk-campaigns').search(search);
        };

        $scope.clearSearch = function() {
            $scope.data.searchTerm = '';
            $location.path('/bulk-campaigns').search('');
        };

        $scope.$on('$locationChangeSuccess',
            function(event, newUrl, oldUrl) {
                if ( $location.path() == '/bulk-campaigns' ) {
                    $scope.currentPage = 1;
                    $scope.data.lists = [];
                    $scope.data.searchTerm = ($location.search().searchTerm || '');
                    $scope.loadCampaigns(()=> {});
                }
            }
        );

        if (authService.hasAccount()) {
            $scope.onInit();
        }
        else {
            $scope.$on('auth_complete', function(event, args) {
                $scope.onInit();
            });
        }
    }
    module.exports = CampaignsController;
})();
