(function () {
    'use strict';
    
    function DispatcherService(apiService, $rootScope) { 
        return {
            createDispatcher: function (request) {
                return apiService.post($rootScope.settings.API_DISPATCHER_URL + '/dispatcher', request);
            },

            updateDispatcher: function (request) {
                return apiService.post($rootScope.settings.API_DISPATCHER_URL + '/dispatcher/' + request.list_id, request);
            },

            getDispatcherData: function (request) {
                if (request.list_id) {
                    return apiService.get($rootScope.settings.API_DISPATCHER_URL + '/dispatcher/' + request.list_id, request);
                }

                return apiService.get($rootScope.settings.API_DISPATCHER_URL + '/dispatcher', request);
            },

            getDispatcherContacts: function (request) {
                return apiService.get($rootScope.settings.API_DISPATCHER_URL + '/dispatcher/' + request.list_id + '/contact', request);
            },

            getAvailableDispositions: function (request) {
                return apiService.get($rootScope.settings.API_DISPATCHER_URL + '/dispatcher/all/dispositions', request);
            },

            getListsDispositions: function (request) {
                return apiService.get($rootScope.settings.API_DISPATCHER_URL + '/dispatcher/dispositions/lists', request);
            }
        };
    }
    module.exports = DispatcherService;
})();




