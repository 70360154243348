(function () {
    'use strict';
    
    function PhoneService(apiService, $rootScope) { 
        return {
            searchAvailable: function (request) {
                return apiService.post($rootScope.settings.API_PHONE_URL + '/phone/available', request);
            },

            getNumbers: function (request) {
                return apiService.get($rootScope.settings.API_PHONE_URL + '/phone/numbers', request);
            },

            getNumbersCount: function (request) {
                return apiService.get($rootScope.settings.API_PHONE_URL + '/phone/numbers/count', request);
            },

            getNumber: function (request) {
                return apiService.get($rootScope.settings.API_PHONE_URL + '/phone/numbers/' + request.number, request);
            },

            rentNumber: function (request) {
                return apiService.post($rootScope.settings.API_PHONE_URL + '/phone/numbers/rent', request);
            },

            unrentNumber: function (request) {
                return apiService.del($rootScope.settings.API_PHONE_URL + '/phone/numbers/rent/' + request.number, request);
            },

            updateNumber: function (request) {
                return apiService.post($rootScope.settings.API_PHONE_URL + '/phone/numbers/' + request.number, request);
            },

            rentDialIn: function (request) {
                return apiService.post($rootScope.settings.API_PHONE_URL + '/phone/numbers/rent/dialin', request);
            },

            setDialIn: function (request) {
                return apiService.post($rootScope.settings.API_PHONE_URL + '/phone/numbers/' + request.number + '/dialin', request);
            },

            getIvr: function (request) {
                return apiService.get($rootScope.settings.API_PHONE_URL + '/phone/ivr/' + request.ivr_id, request);
            },

            getNumbersUsingIvr: function (request) {
                return apiService.get($rootScope.settings.API_PHONE_URL + '/phone/ivr/' + request.ivr_id + '/numbers', request);
            },

            getIvrs: function (request) {
                return apiService.get($rootScope.settings.API_PHONE_URL + '/phone/ivr', request);
            },

            createIvr: function (request) {
                return apiService.post($rootScope.settings.API_PHONE_URL + '/phone/ivr', request);
            },

            updateIvr: function (request) {
                return apiService.post($rootScope.settings.API_PHONE_URL + '/phone/ivr/' + request.ivr_id, request);
            },

            setDefaultIvr: function (request) {
                return apiService.post($rootScope.settings.API_PHONE_URL + '/phone/ivr/' + request.ivr_id + '/default', request);
            },

            deleteIvr: function (request) {
                return apiService.del($rootScope.settings.API_PHONE_URL + '/phone/ivr/' + request.ivr_id, request);
            },

            getPool: function (request) {
                return apiService.get($rootScope.settings.API_PHONE_URL + '/phone/pools/' + request.pool_id, request);
            },

            getPools: function (request) {
                return apiService.get($rootScope.settings.API_PHONE_URL + '/phone/pools/', request);
            },

            createPool: function (request) {
                return apiService.post($rootScope.settings.API_PHONE_URL + '/phone/pools/', request);
            },

            updatePool: function (request) {
                return apiService.post($rootScope.settings.API_PHONE_URL + '/phone/pools/' + request.pool_id, request);
            },

            registerPool: function (request) {
                return apiService.post($rootScope.settings.API_PHONE_URL + '/phone/pools/' + request.pool_id + '/register', request);
            },

            deletePool: function (request) {
                return apiService.del($rootScope.settings.API_PHONE_URL + '/phone/pools/' + request.pool_id, request);
            },

            addNumberToPool: function (request) {
                return apiService.post($rootScope.settings.API_PHONE_URL + '/phone/pools/' + request.pool_id + '/number/' + request.phone_number, request);
            },

            removeNumberFromPool: function (request) {
                return apiService.post($rootScope.settings.API_PHONE_URL + '/phone/pools/' + request.pool_id + '/number/' + request.phone_number, request);
            },

            getCalls: function (request) {
                return apiService.get($rootScope.settings.API_PHONE_URL + '/phone/calls', request);
            },

            getCallsCount: function (request) {
                return apiService.get($rootScope.settings.API_PHONE_URL + '/phone/calls/count', request);
            },

            getNumberOfCallsInQueue: function (request) {
                return apiService.get($rootScope.settings.API_PHONE_URL + '/phone/calls/queue/count', request);
            },

            getCall: function (request) {
                return apiService.get($rootScope.settings.API_PHONE_URL + '/phone/calls/' + request.call_id, request);
            },

            updateCall: function (request) {
                return apiService.post($rootScope.settings.API_PHONE_URL + '/phone/calls/' + request.call_id, request);
            },

            sendDTMF: function (request) {
                return apiService.post($rootScope.settings.API_PHONE_URL + '/phone/calls/' + request.call_id + '/send_dtmf', request);
            },

            recvDTMF: function (request) {
                return apiService.post($rootScope.settings.API_PHONE_URL + '/phone/calls/' + request.call_id + '/recv_dtmf', request);
            },

            setCallDisposition: function (request) {
                return apiService.post($rootScope.settings.API_PHONE_URL + '/phone/calls/' + request.call_id + '/disposition', request);
            },

            setSmsDisposition: function (request) {
                return apiService.post($rootScope.settings.API_PHONE_URL + '/phone/sms/' + request.sms_id + '/disposition', request);
            },

            manualUpdateSmsDisposition: function (request) {
                return apiService.put($rootScope.settings.API_PHONE_URL + '/phone/sms/' + request.sms_id + '/disposition/manual-update', request);
            },

            updateDisposition: function (request) {
                return apiService.put($rootScope.settings.API_PHONE_URL + '/phone/calls/' + request.call_id + '/disposition', request);
            },

            manualUpdateDisposition: function (request) {
                return apiService.put($rootScope.settings.API_PHONE_URL + '/phone/calls/' + request.call_id + '/disposition/manual-update', request);
            },

            hangupCall: function (request) {
                return apiService.post($rootScope.settings.API_PHONE_URL + '/phone/calls/' + request.call_id + '/hangup', request);
            },

            callContact: function (request) {
                return apiService.post($rootScope.settings.API_PHONE_URL + '/phone/call', request);
            },

            sendRvm: function (request) {
                return apiService.post($rootScope.settings.API_PHONE_URL + '/phone/rvm', request);
            },

            startDialing: function (request) {
                return apiService.post($rootScope.settings.API_PHONE_URL + '/phone/list', request);
            },

            exportCalls: function (request) {
                return apiService.post($rootScope.settings.API_PHONE_URL + '/phone/export/calls', request);
            },

            exportSms: function (request) {
                return apiService.post($rootScope.settings.API_PHONE_URL + '/phone/export/sms', request);
            },

            getExportStatus: function (request) {
                return apiService.get($rootScope.settings.API_PHONE_URL + '/phone/export/calls/' + request.export_id, request);
            },

            getSms: function (request) {
                return apiService.get($rootScope.settings.API_PHONE_URL + '/phone/sms', request);
            },

            getOneSms: function (request) {
                return apiService.get($rootScope.settings.API_PHONE_URL + '/phone/sms/' + request.sms_id, request);
            },

            updateSms: function (request) {
                return apiService.post($rootScope.settings.API_PHONE_URL + '/phone/sms/' + request.sms_id, request);
            },

            getTimeline: function (request) {
                return apiService.get($rootScope.settings.API_PHONE_URL + '/phone/timeline', request);
            },

            getConversations: function (request) {
                return apiService.get($rootScope.settings.API_PHONE_URL + '/phone/timeline/grouped', request);
            },

            getSingleConversation: function (request) {
                return apiService.get($rootScope.settings.API_PHONE_URL + `/phone/timeline/grouped/${request.entry_id}`, request);
            },

            getAggregatedTimeline: function (request) {
                return apiService.get($rootScope.settings.API_PHONE_URL + '/phone/timeline/aggregated', request);
            },

            sendSms: function (request) {
                return apiService.post($rootScope.settings.API_PHONE_URL + '/phone/sms', request);
            },

            getVms: function (request) {
                return apiService.get($rootScope.settings.API_PHONE_URL + '/phone/voicemails', request);
            },

            getTranscription: function (request) {
                return apiService.get($rootScope.settings.API_PHONE_URL + '/phone/recordings/' + request.recording_id + '/transcription', request);
            },

            updateVoicemail: function (request) {
                return apiService.post($rootScope.settings.API_PHONE_URL + '/phone/voicemails/' + request.call_recording_id, request);
            },

            getUsersStatistics: function (request) {
                return apiService.get($rootScope.settings.API_PHONE_URL + '/phone/statistics/users', request);
            },

            getRecentConversions: function (request) {
                return apiService.get($rootScope.settings.API_PHONE_URL + '/phone/statistics/conversions', request);
            },

            getListStatistics: function (request) {
                return apiService.get($rootScope.settings.API_PHONE_URL + '/phone/statistics/lists', request);
            },

            getGlobalStatistics: function (request) {
                return apiService.get($rootScope.settings.API_PHONE_URL + '/phone/statistics/global', request);
            },

            getUnseenItems: function (request) {
                return apiService.get($rootScope.settings.API_PHONE_URL + '/phone/statistics/unseen', request);
            },

            getDispositionStatistics: function (request) {
                return apiService.get($rootScope.settings.API_PHONE_URL + '/phone/statistics/dispositions', request);
            },

            getTimeStatistics: function (request) {
                return apiService.get($rootScope.settings.API_PHONE_URL + '/phone/statistics/time', request);
            },

            getNumberTimeStatistics: function (request) {
                return apiService.get($rootScope.settings.API_PHONE_URL + '/phone/statistics/time/number', request);
            },

            getCallRoutingTimeStatistics: function (request) {
                return apiService.get($rootScope.settings.API_PHONE_URL + '/phone/statistics/time/call-routing', request);
            },

            getLocationStatistics: function (request) {
                return apiService.get($rootScope.settings.API_PHONE_URL + '/phone/statistics/location', request);
            },

            getRegionalStatistics: function (request) {
                return apiService.get($rootScope.settings.API_PHONE_URL + '/phone/statistics/regional', request);
            },

            createSubscriber: function (request) {
                return apiService.post($rootScope.settings.API_PHONE_URL + '/phone/subscriber', request);
            },

            getSubscriber: function (request) {
                return apiService.get($rootScope.settings.API_PHONE_URL + '/phone/subscriber/' + request.subscriber_id, request);
            },

            updateSubscriber: function (request) {
                return apiService.post($rootScope.settings.API_PHONE_URL + '/phone/subscriber/' + request.subscriber_id, request);
            },

            deleteSubscriber: function (request) {
                return apiService.del($rootScope.settings.API_PHONE_URL + '/phone/subscriber/' + request.subscriber_id, request);
            },

            deleteSubscriberBySendBeacon: function (subscriber_id, request) {
                return apiService.sendBeacon($rootScope.settings.API_PHONE_URL + '/phone/subscriber/delete/' + subscriber_id, request);
            },

            keepAliveSubscriber: function (request) {
                return apiService.post($rootScope.settings.API_PHONE_URL + '/phone/subscriber/' + request.subscriber_id + '/heartbeat', request, {logout_on_unauthorized: true});
            },
        };
    }
    module.exports = PhoneService;
})();




