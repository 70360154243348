(function() {
    'use strict';

    function TimeAnalysisChartController($scope) {
        var vm = this;

        $scope.charts = {};
        $scope.isEmpty = true;

        $scope.daysOfTheWeek = {
            1: 'MON',
            2: 'TUE',
            3: 'WED',
            4: 'THU',
            5: 'FRI',
            6: 'SAT',
            7: 'SUN'
        };

        $scope.staticData = {
            statistic: ['Texts', 'Calls', 'Talk Time', 'Conversions'],
            callsLineChart: {
                title: 'Calls',
                legend: ['Outbound Calls', 'Inbound Calls']
            },
            phoneNumbers: {
                title: 'Phone Numbers',
                subtitle: 'Recent activity by call route',
                add: {
                    title: 'Add Number',
                    href: '#/phone-number-purchase'
                },
                viewAll: {
                    title: 'View All',
                    href: '#/phone-numbers'
                },
                labels: [
                    'Calls:',
                    'Last Call:',
                    'Messages:',
                    'Last Message:',
                    'Duration:',
                    'Conversions:',
                ]
            },
            callRouting: {
                title: 'Call Routing',
                subtitle: 'Recent activity by call route',
                add: {
                    title: 'Add Number',
                    href: '#/phone-number-purchase'
                },
                viewAll: {
                    title: 'View All',
                    href: '#/phone-numbers'
                },
                label: 'Phone Numbers:'
            },
            timeAnalysisChart: {
                title: 'Statistics by time',
                colorsCalls: {
                    outbound: '#b5b4b4',
                    inbound: '#ee3843',
                    good_calls: '#ee3843',
                    bad_calls: '#b5b4b4',
                    answered: '#ee3843',
                    not_answered: '#b5b4b4',
                },
                colorsSms: {
                    outbound: '#b5b4b4',
                    inbound: '#009ffb',
                    good_calls: '#009ffb',
                    bad_calls: '#b5b4b4',
                    answered: '#009ffb',
                    not_answered: '#b5b4b4',
                },
                colorsCallRouting: {
                    outbound: '#b5b4b4',
                    inbound: '#ee3843',
                    good_calls: '#ee3843',
                    bad_calls: '#b5b4b4',
                    answered: '#ee3843',
                    not_answered: '#b5b4b4',
                }
            },
            mapCalls: {
                title: 'Calls statistics by region',
                subtitle: 'Select the statistic you want to see',
                legend: {
                    first: 'Outbound Calls',
                    second: 'Inbound Calls',
                    third: 'Good Dispositions',
                    fourth: 'Bad Dispositions',
                    fifth: 'Answer rate'
                },
                colors: {
                    outbound: '#b5b4b4',
                    inbound: '#ff214e',
                    good: '#ff214e',
                    bad: '#b5b4b4',
                    answered: '#ff214e'
                }
            },
            mapSms: {
                title: 'SMS location',
                subtitle: 'Location of your SMS',
                legend: {
                    first: 'Outbound SMS',
                    second: 'Inbound SMS',
                },
                colors: {
                    inbound: '#009ffb',
                    outbound: '#b5b4b4'
                }
            },
            loading: 'Loading',
            noDataMessage: 'No Data',
            noSubMessage: 'No Advanced Tracking Subscription'
        };

        $scope.currentScreenWidth = window.innerWidth;

        $scope.setScreenWidth = function() {
            $scope.currentScreenWidth = window.innerWidth;
        };

        $scope.$on('hiddenRightSideBar', function () {
            $scope.setScreenWidth();
        });
        $scope.$on('shownRightSideBar', function () {
            $scope.setScreenWidth();
        });

        window.onresize = $scope.setScreenWidth;

        $scope.__formatHourlyData = function(data, axis1, axis2){
            const hours = _.range(24);
            const hourlyData = {};
            const formattedHours = [];
            let maxHourlyCalls = 0;

            _.forEach(data, function(hour) {
                hourlyData[hour.hour] = hour;
            });

            _.forEach(hours, function(i) {
                if(!hourlyData[i]){
                    formattedHours.push({
                        hour: i,
                        axis1: 0,
                        axis2: 0
                    });
                } else {
                    formattedHours.push({
                        hour: i,
                        axis1: hourlyData[i][axis1],
                        axis2: hourlyData[i][axis2]
                    });

                    maxHourlyCalls = maxHourlyCalls <  Math.max(hourlyData[i][axis1],  hourlyData[i][axis2]) ? Math.max(hourlyData[i][axis1],  hourlyData[i][axis2]) : maxHourlyCalls;
                }
            });

            return {
                hours: formattedHours,
                max: maxHourlyCalls
            };
        };

        $scope.__formatWeeklyData = function(data, axis1, axis2){

            const weekDays = _.range(1, 8);
            const weekData = {};
            const formattedWeeks = [];
            let maxWeeklyItems = 0;

            _.forEach(data, function(week) {
                weekData[week._id] = week;
            });

            _.forEach(weekDays, function(i) {
                if(!weekData[i]){
                    formattedWeeks.push({
                        _id: $scope.daysOfTheWeek[i],
                        axis1: 0,
                        axis2: 0,
                        hours: $scope.__formatHourlyData([]).hours
                    });
                } else {
                    const hours = $scope.__formatHourlyData(weekData[i].hours, axis1, axis2);
                    maxWeeklyItems = hours.max > maxWeeklyItems ? hours.max : maxWeeklyItems;

                    formattedWeeks.push({
                        _id: $scope.daysOfTheWeek[i],
                        axis1: weekData[i][axis1],
                        axis2: weekData[i][axis2],
                        hours: hours.hours
                    });
                }
            });

            return {
                week: formattedWeeks,
                max: maxWeeklyItems
            };
        };

        $scope.__getTimeAnalysisData = function(rawData, type) {

            let chartInfo = {};

            switch(vm.type) {
                case 'bad':
                case 'good':
                    chartInfo.data = $scope.__formatWeeklyData(rawData, 'good_calls', 'bad_calls');
                    chartInfo.color1Call = $scope.staticData.timeAnalysisChart.colorsCalls.good_calls;
                    chartInfo.color2Call = $scope.staticData.timeAnalysisChart.colorsCalls.bad_calls;
                    chartInfo.color1Sms = $scope.staticData.timeAnalysisChart.colorsSms.good_calls;
                    chartInfo.color2Sms = $scope.staticData.timeAnalysisChart.colorsSms.bad_calls;
                    chartInfo.color1CallRouting = $scope.staticData.timeAnalysisChart.colorsCallRouting.good_calls;
                    chartInfo.color2CallRouting = $scope.staticData.timeAnalysisChart.colorsCallRouting.bad_calls;
                    chartInfo.text1 = 'Good/Sold';
                    chartInfo.text2 = 'Bad/DNC';
                    chartInfo.statisticCalls = 'Dispostions';
                    chartInfo.statisticSms = 'Dispostions';
                    chartInfo.statisticCallRouting = 'Dispostions';
                    break;
                case 'inbound':
                case 'outbound':
                    chartInfo.data = $scope.__formatWeeklyData(rawData, 'inbound', 'outbound');
                    chartInfo.color1Call = $scope.staticData.timeAnalysisChart.colorsCalls.inbound;
                    chartInfo.color2Call = $scope.staticData.timeAnalysisChart.colorsCalls.outbound;
                    chartInfo.color1Sms = $scope.staticData.timeAnalysisChart.colorsSms.inbound;
                    chartInfo.color2Sms = $scope.staticData.timeAnalysisChart.colorsSms.outbound;
                    chartInfo.color1CallRouting = $scope.staticData.timeAnalysisChart.colorsCallRouting.inbound;
                    chartInfo.color2CallRouting = $scope.staticData.timeAnalysisChart.colorsCallRouting.outbound;
                    chartInfo.text1 = 'Inbound';
                    chartInfo.text2 = 'Outbound';
                    chartInfo.statisticCalls = 'Calls';
                    chartInfo.statisticSms = 'SMS';
                    chartInfo.statisticCallRouting = 'Calls';
                    break;
                case 'answered':
                    chartInfo.data = $scope.__formatWeeklyData(rawData, 'answered', 'not_answered');
                    chartInfo.color1Call = $scope.staticData.timeAnalysisChart.colorsCalls.answered;
                    chartInfo.color2Call = $scope.staticData.timeAnalysisChart.colorsCalls.not_answered;
                    chartInfo.color1Sms = $scope.staticData.timeAnalysisChart.colorsSms.answered;
                    chartInfo.color2Sms = $scope.staticData.timeAnalysisChart.colorsSms.not_answered;
                    chartInfo.color1CallRouting = $scope.staticData.timeAnalysisChart.colorsCallRouting.answered;
                    chartInfo.color2CallRouting = $scope.staticData.timeAnalysisChart.colorsCallRouting.not_answered;
                    chartInfo.text1 = 'Answered';
                    chartInfo.text2 = 'Not Answered';
                    chartInfo.statisticCalls = 'Answer rate';
                    chartInfo.statisticSms = 'Answer rate';
                    chartInfo.statisticCallRouting = 'Answer rate';
                    break;
            }

            return chartInfo;
        };

        $scope.__buildTimeAnalysisChart = function(data, type) {

            if(!data || data === null) {
                // callback();
            }

            const chartId = vm.name + '-analysis-chart';

            // prevent creating chart if html is not ready
            if ($('#'+chartId).length === 0) {
                return;
            }

            const weekdayChartId = chartId + '-weekday';
            const timeChartId = chartId + '-time';

            $scope.disposeChart(chartId);
            $scope.disposeChart(weekdayChartId);
            $scope.disposeChart(timeChartId);

            const chartInfo = $scope.__getTimeAnalysisData(data, type);

            const weekData = chartInfo.data.week;
            const max = chartInfo.data.max;

            $scope.isEmpty = (max <= 0) ? true : false;

            var color1, color2, statistic;

            switch(vm.name) {
                case 'calls':
                    color1 = chartInfo.color1Call;
                    color2 = chartInfo.color2Call;
                    statistic = chartInfo.statisticCalls;
                    break;
                case 'sms':
                    color1 = chartInfo.color1Sms;
                    color2 = chartInfo.color2Sms;
                    statistic = chartInfo.statisticSms;
                    break;
                case 'call-routing':
                case 'phone-numbers':
                    color1 = chartInfo.color1CallRouting;
                    color2 = chartInfo.color2CallRouting;
                    statistic = chartInfo.statisticCallRouting;
                    break;
            }
            const text1 = chartInfo.text1;
            const text2 = chartInfo.text2;

            am4core.useTheme(am4themes_animated);

            /**
             * Create container for charts
             */
            $scope.charts[chartId] = am4core.create(chartId, am4core.Container);
            $scope.charts[chartId].width = am4core.percent(100);
            $scope.charts[chartId].height = am4core.percent(100);
            $scope.charts[chartId].layout = (($scope.currentScreenWidth >= 1024 && $scope.currentScreenWidth <= 1150) ||
                ($scope.currentScreenWidth >= 1425 && $scope.currentScreenWidth <= 1500) || ($scope.currentScreenWidth <= 850)) ? 'vertical' : 'horizontal';

            /**
             * Population pyramid chart
             */

            $scope.charts[weekdayChartId] = $scope.charts[chartId].createChild(am4charts.XYChart);
            $scope.charts[weekdayChartId].responsive.enabled = true;
            $scope.charts[weekdayChartId].cursor = new am4charts.XYCursor();
            $scope.charts[weekdayChartId].cursor.behavior = 'none';

            $scope.charts[weekdayChartId].numberFormatter.numberFormat = '#,###.#a';
            $scope.charts[weekdayChartId].numberFormatter.bigNumberPrefixes = [
                { 'number': 1e+3, 'suffix': 'M' }
            ];

            // An adapter which filters data for the current weekday
            let currentWeekday = $scope.daysOfTheWeek[new Date().getDay()];

            function getCurrentData() {
                let week = _.find(weekData, function(w) { return w._id == currentWeekday; });
                return (week) ? week.hours : [];
            }

            $scope.charts[weekdayChartId].data = getCurrentData();

            function updateData() {
                let data = getCurrentData();
                if (data.length === 0) {
                    return;
                }
                $scope.charts[weekdayChartId].data = data;
                //$scope.charts.dispoByWeekday.invalidateRawData();

                // Set title
                $scope.charts[weekdayChartId].titles.getIndex(0).text = currentWeekday;
            }

            let pyramidXAxisOutbound = $scope.charts[weekdayChartId].xAxes.push(new am4charts.ValueAxis());
            pyramidXAxisOutbound.min = 0;
            pyramidXAxisOutbound.max = max;
            pyramidXAxisOutbound.strictMinMax = true;

            let outboundRange = pyramidXAxisOutbound.axisRanges.create();
            outboundRange.value = 0;
            outboundRange.endValue = max;
            outboundRange.label.text = text1;
            outboundRange.label.inside = true;
            outboundRange.label.valign = 'top';
            outboundRange.label.fontSize = 16;
            outboundRange.label.fontWeight = 400;
            outboundRange.label.fill = am4core.color(color1);

            let pyramidXAxisInbound = $scope.charts[weekdayChartId].xAxes.push(new am4charts.ValueAxis());
            pyramidXAxisInbound.min = 0;
            pyramidXAxisInbound.max = max;
            pyramidXAxisInbound.renderer.inversed = true;
            pyramidXAxisInbound.strictMinMax = true;

            let inboundRagnge = pyramidXAxisInbound.axisRanges.create();
            inboundRagnge.value = 0;
            inboundRagnge.endValue = max;
            inboundRagnge.label.text = text2;
            inboundRagnge.label.inside = true;
            inboundRagnge.label.valign = 'top';
            inboundRagnge.label.fontSize = 16;
            inboundRagnge.label.fontWeight = 400;
            inboundRagnge.label.fill = am4core.color(statistic);

            $scope.charts[weekdayChartId].bottomAxesContainer.layout = 'horizontal';

            let pyramidYAxis = $scope.charts[weekdayChartId].yAxes.push(new am4charts.CategoryAxis());
            pyramidYAxis.dataFields.category = 'hour';
            pyramidYAxis.renderer.minGridDistance = 18;
            pyramidYAxis.renderer.grid.template.location = 0;
            pyramidYAxis.renderer.inside = true;
            pyramidYAxis.title.text = 'Hours of the day';
            pyramidYAxis.title.dx = -8;
            pyramidYAxis.renderer.labels.template.adapter.add('textOutput', function(text, target) {
                return text;
            });

            let pyramidSeriesOutbound = $scope.charts[weekdayChartId].series.push(new am4charts.ColumnSeries());
            pyramidSeriesOutbound.dataFields.categoryY = 'hour';
            pyramidSeriesOutbound.dataFields.valueX = 'axis1';
            pyramidSeriesOutbound.tooltipText = '{valueX}';
            pyramidSeriesOutbound.name = text1;
            pyramidSeriesOutbound.xAxis = pyramidXAxisOutbound;
            pyramidSeriesOutbound.clustered = false;
            pyramidSeriesOutbound.columns.template.strokeOpacity = 0;
            pyramidSeriesOutbound.stroke = am4core.color(color1);
            pyramidSeriesOutbound.fill = am4core.color(color1);
            pyramidSeriesOutbound.fillOpacity = 0.7;

            let pyramidSeriesInbound = $scope.charts[weekdayChartId].series.push(new am4charts.ColumnSeries());
            pyramidSeriesInbound.dataFields.categoryY = 'hour';
            pyramidSeriesInbound.dataFields.valueX = 'axis2';
            pyramidSeriesInbound.tooltipText = '{valueX}';
            pyramidSeriesInbound.name = text2;
            pyramidSeriesInbound.xAxis = pyramidXAxisInbound;
            pyramidSeriesInbound.clustered = false;
            pyramidSeriesInbound.columns.template.strokeOpacity = 0;
            pyramidSeriesInbound.stroke = am4core.color(color2);
            pyramidSeriesInbound.fill = am4core.color(color2);
            pyramidSeriesInbound.fillOpacity = 0.7;

            let pyramidTitle = $scope.charts[weekdayChartId].titles.create();
            pyramidTitle.text = currentWeekday;
            pyramidTitle.fontSize = 16;
            pyramidTitle.marginBottom = 29;
            pyramidTitle.dy = 12;

            /**
             * Create week chart
             */
            $scope.charts[timeChartId] = $scope.charts[chartId].createChild(am4charts.XYChart);
            $scope.charts[timeChartId].marginLeft = 15;
            $scope.charts[timeChartId].data = weekData;
            $scope.charts[timeChartId].responsive.enabled = true;

            let popSubtitle = $scope.charts[timeChartId].titles.create();
            popSubtitle.text = '(hover to see breakdown)';
            popSubtitle.fontSize = 14;
            popSubtitle.marginBottom = 8;

            let popTitle = $scope.charts[timeChartId].titles.create();
            popTitle.text = statistic + ' by day of the week';
            popTitle.fontSize = 16;
            popTitle.marginBottom = 4;

            let popXAxis = $scope.charts[timeChartId].xAxes.push(new am4charts.CategoryAxis());

            popXAxis.dataFields.category = '_id';
            popXAxis.renderer.minGridDistance = 40;

            let popYAxis = $scope.charts[timeChartId].yAxes.push(new am4charts.ValueAxis());
            popYAxis.renderer.opposite = true;

            let weekSeriesOutbound = $scope.charts[timeChartId].series.push(new am4charts.LineSeries());
            weekSeriesOutbound.dataFields.categoryX = '_id';
            weekSeriesOutbound.dataFields.valueY = 'axis1';
            weekSeriesOutbound.propertyFields.strokeDasharray = 'dash';
            weekSeriesOutbound.propertyFields.fillOpacity = 'opacity';
            weekSeriesOutbound.stacked = true;
            weekSeriesOutbound.strokeWidth = 1;
            weekSeriesOutbound.fillOpacity = 0.3;
            weekSeriesOutbound.name = text1;
            weekSeriesOutbound.stroke = am4core.color(color1);
            weekSeriesOutbound.fill = am4core.color(color1);

            let weekSeriesInbound = $scope.charts[timeChartId].series.push(new am4charts.LineSeries());
            weekSeriesInbound.dataFields.categoryX = '_id';
            weekSeriesInbound.dataFields.valueY = 'axis2';
            weekSeriesInbound.propertyFields.strokeDasharray = 'dash';
            weekSeriesInbound.propertyFields.fillOpacity = 'opacity';
            weekSeriesInbound.stacked = true;
            weekSeriesInbound.strokeWidth = 1;
            weekSeriesInbound.fillOpacity = 0.3;
            weekSeriesInbound.tooltipText = '[bold]' + statistic +' on {categoryX}[/]\n[font-size: 14]' + text1 + ': {axis1}\n' + text2 + ': {axis2}';
            weekSeriesInbound.name = text2;
            weekSeriesInbound.stroke = am4core.color(color2);
            weekSeriesInbound.fill = am4core.color(color2);

            $scope.charts[timeChartId].cursor = new am4charts.XYCursor();
            $scope.charts[timeChartId].snapToSeries = weekSeriesInbound;
            $scope.charts[timeChartId].cursor.events.on('cursorpositionchanged', function(ev) {
                currentWeekday = $scope.daysOfTheWeek[popXAxis.positionToIndex(popXAxis.toAxisPosition(ev.target.xPosition)) + 1];
                updateData();
            });

            $scope.charts[timeChartId].cursor.events.on('hidden', function(ev) {
                currentWeekday = $scope.daysOfTheWeek[new Date().getDay()];
                updateData();
            });
            // callback();
        };

        $scope.disposeChart = function(chart) {
            if(!_.isEmpty($scope.charts) && $scope.charts[chart]) {
                $scope.charts[chart].dispose();
            }
        };

        $scope.$watch(angular.bind(vm, function () { return vm.chartData; }), function (newValue) {
            $scope.__buildTimeAnalysisChart(newValue, vm.type)
        });
        $scope.$watch(angular.bind(vm, function () { return vm.type; }), function (newValue) {
            $scope.__buildTimeAnalysisChart(vm.chartData, newValue)
        });
    }
    module.exports = TimeAnalysisChartController;
})();
