(function () {
    "use strict";

    function SigninController($rootScope, $scope, $location, authService, accountService, $cookies, $interval, $state) {
        var vm = this;
        vm.authService = authService;

        $rootScope.viewData = $rootScope.viewData || {};
        $rootScope.viewData.breadcrumbs = $rootScope.viewData.breadcrumbs || [];
        $rootScope.viewData.breadcrumbs = [{
            name: 'Sign In',
            href: WEBPACK_URL + 'pricing'
        }];

        $scope.appLogo = $rootScope.settings.APP_LOGO;
    
        $scope.staticContent = {
            title: `Enter your username and password to access ${$rootScope.settings.APP_NAME}.`,
            labels: [
                "Username",
                "Password"
            ],
            inputPlaceholders: [
                "Enter Your Username",
                "Enter Your Password"
            ],
            actionLoginIn: "Log In",
            actionForgotPassword: {
                text: "Forgot your password?",
                title: "Reset",
                href: "#/forgot"
            },
            actionSignUp: {
                text: "Don't have an account?",
                title: "Sign Up",
                href: '#/signup'
            },

            forgot: {
                title: 'Reset Your Password',
                subtitle: 'Please enter your email and we will send you instructions to reset your password:',
                emailPlaceholder: 'you@example.com',
                errors: {
                    requiredError: 'Field is required',
                    emailError: 'Must enter valid email'
                },
                submitBtn: 'Send',
                sending: 'Sending'
            }
        };

        $scope.data = {
            username: "",
            password: "",

            forgotForm: {
                email: ''
            }
        };

        $scope.loading = {
            forgotProcessing: false
        };

        $scope.emailPattern = $rootScope.settings.USER_EMAIL_REGEX;

        $scope.showForgotModal = function() {
            $('#forgotModal').modal('show');
        };

        $scope.resetPassword = function(e) {
            e.preventDefault();

            if($scope.forgotModalForm.$invalid) {
                return;
            }

            $scope.loading.forgotProcessing = true;

            const resendReq = {
                email: $scope.data.forgotForm.email
            };

            // Initialize the agent
            const fpPromise = import(FINGERPRINTJS_URL).then(FingerprintJS => FingerprintJS.load({
                endpoint: FINGERPRINTJS_ENDPOINT
            }));

            // Get the visitor identifier
            fpPromise
                .then(fp => fp.get())
                .then(result => {

                    $rootScope.fingerprintVisitorId = result.visitorId;
                    resendReq.fingerprint = $rootScope.fingerprintVisitorId;

                    return accountService.resetPassword(resendReq);
                })
                .then(result => {
                    swal('Email Sent', 'An email with reset password instructions has been sent to ' + $scope.data.forgotForm.email, 'success');
                    $scope.loading.forgotProcessing = false;
                    $scope.$apply();
                    $('#forgotModal').modal('hide');
                })
                .catch(err => {
                    console.log(err);
                    swal('Error', err.message, 'error');
                    $scope.loading.forgotProcessing = false;
                    $scope.$apply();
                });
        };

        $scope.clearForgotModal = function() {
            $scope.data.forgotForm.email = '';
            $scope.loading.forgotProcessing = false;

            $scope.forgotModalForm.$setPristine();
            $scope.forgotModalForm.$setUntouched();
        };

        $scope.login = function() {

            if (!$scope.data.username || !$scope.data.password) {
                swal("Please fill your username and password.");
                return;
            }

            $scope.login_in = true;

            const loginReq = {
                username: $scope.data.username,
                password: $scope.data.password
            };

            // Initialize the agent
            const fpPromise = import(FINGERPRINTJS_URL).then(FingerprintJS => FingerprintJS.load({
                endpoint: FINGERPRINTJS_ENDPOINT
            }));

            // Get the visitor identifier
            fpPromise
                .then(fp => fp.get())
                .then(result => {

                    $rootScope.fingerprintVisitorId = result.visitorId;
                    loginReq.fingerprint = $rootScope.fingerprintVisitorId;

                    return authService.login(loginReq);
                })
                .then(results => {
                    $scope.login_in = false;
                })
                .catch(err => {
                    console.log(err);
                    const msg = (err || {}).message === 'Not Authorized' ?
                        'Please double check your username and password.' :
                        'Your account may be inactive or need additional verifications. Please contact ' + APP_INFO_EMAIL;

                    swal(
                        'Unable to Sign In!',
                        msg,
                        'error'
                    );

                    $scope.login_in = false;
                    $scope.$apply();
                });
        };

        $scope.showVerificationSucceed = function() {
            swal('Sign up completed!', `You can now sign into your ${$rootScope.settings.APP_NAME} account`, 'success');
        };

        $scope.onInit = function() {
            $('.preloader').fadeOut();

            if (authService.isAuthenticated()) {
                $state.go('main-wrapper.dash');
            }
            if (!authService.isAuthenticated()) {
                authService.logoutOrlocalLogout();
            }

            const params = new URL(window.location.href).searchParams;
            if(params.get('verified')){
                $scope.showVerificationSucceed();
            }

            if(params.get('username')){
                $scope.data.username = params.get('username');
            }

            if(params.get('shopifyToken')){
                const token = params.get('shopifyToken');
                $cookies.put('shopifyToken', token);
                $rootScope.shopifyToken = token;
            }

            if(params.get('session')){
                const encodedData = params.get('session');
                const decodedData = decodeURIComponent(encodedData);
                const serializedObject = Buffer.from(decodedData, 'base64').toString('utf8');
                const parsedObject = JSON.parse(serializedObject);

                parsedObject.redirectPath = params.get('path')

                authService.handleSuccesfulSigin(parsedObject);
            }

            $('#forgotModal').on('hide.bs.modal', function() {
                $scope.clearForgotModal();
            });
        };

        $scope.$on('$destroy', function() {
            $interval.cancel($scope.stopTime);
        });

        $scope.onInit();
    }
    module.exports = SigninController;
})();
