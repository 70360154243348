(function() {
    'use strict';

    function AddTeamModalController($rootScope, $scope, authService, userService) {
        var vm = this;
        vm.authService = authService;

        $scope.staticContent = {
            createTitle: 'Create Team',
            editTitle: 'Edit Team',
            labels: [
                'Team Name',
            ],
            actions: [ 'Save', 'Create', 'Cancel'],
            loading: 'Loading',
            saving: 'Saving',
            creating: 'Creating',
            errorMessages: {
                team: {
                    teamNameLength: 'Team names must be at least 1 character in length',
                },
            }
        };

        $scope.data = {
            name: {
                is_valid: undefined
            },
            teamForm : {
                name: ''
            },
            formSubmitted: false,
            creatingTeam: false
        };

        $scope.hideModal = function() {
            $scope.data.teamForm.name = '';
            $scope.data.name.is_valid = undefined;
            $scope.data.creatingTeam = false;
            $scope.data.formSubmitted = false;
            $("#addEditTeamModal").modal('hide');
        };

        $scope.isTeamNameValid = function() {
            $scope.data.name.is_valid = $scope.data.teamForm.name.length > 0;
            return $scope.data.name.is_valid;
        }

        $scope.submitForm = function() {
            $scope.data.formSubmitted = true;
            if (!$scope.isTeamNameValid()) {
                return;
            }

            $scope.data.creatingTeam = true;
            var teamId = authService.getTeamId();
            var userId = authService.getUserId();

            const request = {
                team_id: teamId,
                user_id: userId,
                name: $scope.data.teamForm.name
            };

            return userService.createUserGroup(request)
                .then(result => {
                    $scope.data.creatingTeam = false;
                    $rootScope.$broadcast('teamCreated');
                    $scope.hideModal();
                    swal("Success", "Team has been created!", "success");
                })
                .catch(err => {
                    swal("Error", "We were unable to create team. Please try again.", "error");
                });
        }

        $scope.$on('addTeamEvent', function(event, userReq) {
            $scope.data.mode = 'create';
            $('#addEditTeamModal').modal('show');
        });

        $('#addEditTeamModal').on('hide.bs.modal', function() {
            $scope.data.teamForm.name = '';
            $scope.data.name.is_valid = undefined;
            $scope.data.creatingTeam = false;
            $scope.data.formSubmitted = false;
        });

    }
    module.exports = AddTeamModalController;
})();
