(function() {
    'use strict';

    function FaxesTypePlaceholderListItemController(authService) {
        var vm = this;
        vm.authService = authService;

    }
    module.exports = FaxesTypePlaceholderListItemController;
})();
