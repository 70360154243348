(function() {
    'use strict';

    function ContactDetailsController(
        $rootScope, $scope, callService, authService, contactService, phoneService, dispatcherService, $window, $location, $cookies, integrationService, faxService, tagsService, tasksService, dropdownmenuPositionService, storeSelectedContactService, notificationToastService
    ) {
        var vm = this;
        vm.authService = authService;

        $rootScope.viewData = $rootScope.viewData || {};
        $rootScope.viewData.breadcrumbs = $rootScope.viewData.breadcrumbs || [];
        $rootScope.viewData.breadcrumbs = [{
            title: 'Contacts',
            link_name: 'Contacts',
            link_url: '#/contacts',
            help_url: $rootScope.help_url
        }, {
            title: 'Contact Details',
            link_name: 'Contact Details',
            link_url: '',
            page_data: {
                contact_id: $location.search().id,
            },
        }];
        $scope.mode = 'timeline'; // 'timeline' || 'conversation'
        $scope.defaultAvatar = $rootScope.settings.NO_AVATAR_IMG;

        $scope.currentOpenedPlayerIdVoicemail = -1;
        $scope.currentOpenedPlayerIdCall = -1;

        $scope.availableTimelineTypes = [
            {key: 'all', title: 'All', icon: 'fa-light fa-square-list'},
            {key: 'sms', title: 'SMS', icon: 'fa-light fa-message'},
            {key: 'call', title: 'Calls', icon: 'fa-light fa-phone'},
            {key: 'email', title: 'Email', icon: 'fa-light fa-envelope'},
            {key: 'fax', title: 'Fax', icon: 'fa-light fa-fax'},
            {key: 'rvm', title: 'Voicemail', icon: 'fa-light fa-voicemail'},
            {key: 'note', title: 'Notes', icon: 'fa-light fa-sticky-note'},
        ];

        $scope.staticContent = {
            title: 'Timeline',
            subtitle: 'These are your recent conversations with this contact',
            emptyTimeline: {
                icon: 'fa-light fa-list-timeline',
                message: 'Timeline is Empty'
            },
            type: {
                call: {
                    title: 'Call',
                    inbound: { title: 'Inbound Call', icon: 'fa-light fa-phone-arrow-down-left' },
                    outbound: { title: 'Outbound Call', icon: 'fa-light fa-phone-arrow-up-right' },
                    missed: { title: 'Missed Call', icon: 'fa-light fa-phone-missed' },
                    queued: { title: 'In Queue', icon: 'fa-light fa-phone-arrow-down-left' },
                },
                sms: {
                    title: 'Text',
                    inbound: { title: 'Inbound Text', icon: 'fa-light fa-message-arrow-down' },
                    outbound: { title: 'Outbound Text', icon: 'fa-light fa-message-arrow-up' }
                },
                note: { title: 'Note', icon: 'fa-light fa-sticky-note' },
                rvm: { title: 'Voicemail', icon: 'fa-light fa-voicemail' },
                fax: {
                    title: 'Fax',
                    inbound: { title: 'Inbound Fax', icon: 'fa-light fa-fax' },
                    outbound: { title: 'Outbound Fax', icon: 'fa-light fa-fax' }
                },
                email: {
                    title: 'Email',
                    inbound: { title: 'Inbound Email', icon: 'fa-light fa-envelope' },
                    outbound: { title: 'Outbound Email', icon: 'fa-light fa-envelope' }
                },
                'task.opened': { title: 'Task Opened', icon: 'fa-light fa-diagram-subtask' } //System Note
            },
            infoMessages: {
                noAnswer: 'No Answer',
                emptySms: 'Empty Message',
                emptySubject: 'Empty Subject',
                emptyEmailFaxMessage: 'Empty Message',
                inQueue: 'In the Queue'
            },
            loading: 'Loading',
            processing: 'Processing',
            saving: 'Saving',
            infoDivider: '·',
            actions: [
                {icon: 'fa-light fa-phone', title: 'Dial'},
                {icon: 'fa-light fa-voicemail', title: 'RVM'},
                {icon: 'fa-light fa-fax', title: 'Fax'},
                {icon: 'fa-light fa-user-tag', title: 'Change Disposition'},
                {icon: 'fa-light fa-thumbtack', title: 'Pin Note'},
                {icon: 'fa-solid fa-thumbtack', title: 'Unpin Note'}
            ],
            defaultEntriesTypes: [
                'note',
                'call',
                'sms',
                'rvm',
                'fax',
                'document',
                'email'
            ]
        };

        $scope.data = {
            textAreaMode: null,
            currentContactId: null,
            contactDetails: null,
            filters: {
                type: $scope.availableTimelineTypes[0]
            },
            list: [],
            lists: [],
            available_dispositions: {},
            isNewEntryAddedTimeline: false
        };

        $scope.timelineData = [];
        $scope.timelinePagination = {
            totalItems: 0,
            itemsPerPage: 25
        };

        $scope.loading = {
            timelineFirstLoad: true,
            timeline: false
        };

        $scope.$on('currentOpenedRecordChanged', function(event, newCurrentOpenedPlayerId, recordType) {
            switch(recordType) {
                case 'voicemail':
                    $scope.currentOpenedPlayerIdVoicemail = newCurrentOpenedPlayerId;
                    break;
                case 'call':
                    $scope.currentOpenedPlayerIdCall = newCurrentOpenedPlayerId;
                    break;
            }
        });
        $scope.$on('audioObjectChanged', function(event, audioObject, index, recordType) {
            $scope.timelineData[index].audio = audioObject;
        });

        $scope.answerCall = function(call_id) {
            let req = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                access_number: $rootScope.access_number,
                initial_action: callService.CONTROL_ACTIONS.CALL_INTERCEPT,
                intercept_cid: call_id,
                enable_audio: true
            };
            callService.dial(req);
        };

        $scope.isSystemNote = function(item) {
            return (item.type === 'task.opened');
        };

        $scope.formatSecondsToMinutes = function (seconds = 0) {
            var minutes = Math.floor(seconds / 60);
            var seconds = (seconds % 60).toFixed(0);
            return minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
        };

        $scope.showCallPanel = function() {
            $rootScope.$broadcast('openCallTab', $scope.data.contactDetails);
        };

        $scope.sendFax = function() {
            $rootScope.showFaxModal({ contact: $scope.data.contactDetails });
        };

        $scope.sendRVM = function() {
            $rootScope.showRvmModal($scope.data.contactDetails);
        };

        $scope.changeFilter = function(filterType, filter) {
            $scope.data.filters[filterType] = filter;

            $scope.loadContactTimeline(0, 'timelineFirstLoad');
        };

        $scope.downloadFaxDocument = function(entry, attachment) {
            entry.downloadingDocument = true;

            const request = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                document_id: attachment.document_id
            };

            faxService.getDocumentUrl(request)
                .then(result => {
                    if (!result || !result.url) { throw new Error(); }
                    entry.downloadingDocument = false;
                    window.open(result.url, '_blank');
                    $scope.$apply();
                })
                .catch(err => {
                    console.log(err);
                    entry.downloadingDocument = false;
                    $scope.$apply();
                    notificationToastService.showErrorToast('fa-light fa-triangle-exclamation', 'We were unable to download file. Try again later.');
                });
        };

        $scope.downloadEmailDocument = function(entry, attachment) {
            const entryId = entry.entry_id;
            const attachmentId = attachment.attachment_id;
            if(!entryId) {
                return;
            }

            entry.downloadingDocument = true;
            const request = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                task_id: entry.task_id,
                entry_id: entryId,
                attachment_id: attachmentId
            };
            tasksService.getTaskAttachmentUrl(request)
                .then(result => {
                    if (!result || !result.url) { throw new Error(); }
                    entry.downloadingDocument = false;
                    window.open(result.url, '_blank');
                    $scope.$apply();
                })
                .catch(err => {
                    console.log(err);
                    entry.downloadingDocument = false;
                    $scope.$apply();
                    notificationToastService.showErrorToast('fa-light fa-triangle-exclamation', 'We were unable to download file. Try again later.');
                });
        };

        $scope.downloadAttachment = function(entry, attachment) {
            switch(attachment.type) {
                case 'fax_document':
                    $scope.downloadFaxDocument(entry, attachment);
                    break;
                case 'email_attachment':
                    $scope.downloadEmailDocument(entry, attachment);
                    break;
            }
        };

        $scope.hasPermissions = function(entity, action) {
            return $rootScope.hasPermissions(entity, action);
        };

        $scope.isNotePinned = function(note_id) {
            return (($scope.data.contactDetails && $scope.data.contactDetails.notes) || []).map(note => note.note_id).includes(note_id);
        };

        $scope.addNoteToList = function(note) {
            $scope.data.contactDetails.notes.push(note);
        };

        $scope.removeNoteFromList = function(note) {
            $scope.data.contactDetails.notes = _.remove($scope.data.contactDetails.notes, function (n) {
                return n.note_id !== note.note_id;
            });
        };

        $scope.updateEntryNoteText = function(note) {
            var index = _.findIndex($scope.timelineData, function(item) { return item.entry_id === note.note_id || item.note_id === note.note_id; });
            if(index !== -1) {
                $scope.timelineData[index].note = _.cloneDeep(note.newEdit);
            }
        };

        $scope.deleteNoteEntry = function(note) {
            var index = _.findIndex($scope.timelineData, function(item) { return item.entry_id === note.note_id || item.note_id === note.note_id; });
            if(index !== -1) {
                $scope.timelineData = _.remove($scope.timelineData, function (n) {
                    return n.note_id !== note.note_id;
                });
            }
        };

        $scope.$on('updateContactNotesEvent', function (event, note, action) {
            switch (action) {
                case 'add': $scope.addNoteToList(note); break;
                case 'remove': $scope.removeNoteFromList(note); break;
                case 'edit': $scope.updateEntryNoteText(note); break;
                case 'deleteEntry': $scope.deleteNoteEntry(note); break;
            }
        });

        $scope.pinUnpinNote = function(item, note_id) {
            item.pinUnpinNote = true;
            const action = $scope.isNotePinned(note_id) ? 'unpin' : 'pin';
            const req = {
                contact_id: $scope.data.currentContactId,
                note_id: note_id,
                action
            };
            return tasksService.pinUnpinNote(req)
                .then((res) => {
                    if(res.success) {
                        var newNote = {
                            note_id: note_id,
                            contact_id: $scope.data.currentContactId,
                            task_id: item.task_id,
                            note: item.note,
                            created_at: item.created_at,
                            username: item.user_name || item.from
                        };
                        storeSelectedContactService.updateContactNotes((action === 'unpin') ? item : newNote, (action === 'unpin') ? 'remove' : 'add');
                    }

                    item.pinUnpinNote = false;
                    notificationToastService.showSuccessToast('fa-light fa-user-tag', `Note successfully ${action === 'unpin' ? 'unpinned' : 'pinned'}!`);
                    $scope.$apply();
                })
                .catch(err => {
                    console.error(err);
                    item.pinUnpinNote = false;
                    $scope.$apply();
                    notificationToastService.showErrorToast('fa-light fa-triangle-exclamation', 'Something went wrong. Try again later.');
                });
        };

        $scope.openConversation = function (item) {
            if(item.task_id) {
                $scope.mode = 'conversation';
                $scope.getNewTask(item.task_id)
                    .then(task => {
                        $rootScope.$broadcast('setSelectedTask', task);
                        $scope.$apply();
                    });
            }
        };

        $scope.getNewTask = function (taskId) {
            const req = {
                task_id: taskId
            };

            return tasksService.getTask(req)
                .then(task => {
                    if (task) {
                        return task;
                    }
                    return null;
                });
        };

        $rootScope.$on('returnBack', function(event, args) {
            $scope.mode = 'timeline';
        });

        $scope.showDispositionsList = function(item) {
            var dispositionDd = $('#entry-menu-' + item.entry_id);
            if(!$._data(dispositionDd[0], 'events') || !$._data(dispositionDd[0], 'events').hidden) {
                dispositionDd.on('hidden.bs.dropdown', function() {
                    if (item.ddState.showDispositions) {
                        item.ddState.showDispositions = false;
                        item.ddState.shownDispositions = true;
                        dispositionDd.find('.dropdown-toggle').dropdown('toggle');
                    } else {
                        item.ddState.shownDispositions = false;
                    }
                });
            }

            item.ddState.showDispositions = true;
        };

        $scope.changeDisposition = function(entry, disposition) {
            entry.changingDisposition = true;
            const teamId = authService.getTeamId();
            const userId = authService.getUserId();

            const callReq = {
                user_id: userId,
                team_id: teamId,
                call_id: entry.call_id,
                sms_id: entry.sms_id,
                sms_body: entry.sms_body,
                to: entry.to,
                contact_id: entry.contact_id,
                disposition: {
                    id : disposition.id,
                    sentiment : disposition.sentiment
                },
                recording_id: entry.recording_id,
                call_direction: entry.direction,
                list_id: entry.list_id,
                duration: entry.duration,
                from: entry.from,
                entry_id: entry.entry_id
            };

            phoneService.manualUpdateDisposition(callReq)
                .then(updatedEntry => {
                    entry.disposition = updatedEntry.disposition;
                    entry.changingDisposition = false;
                    $scope.$apply();
                    notificationToastService.showSuccessToast('fa-light fa-user-tag', 'Disposition has been changed!');
                })
                .catch(err => {
                    console.error(err);
                    entry.changingDisposition = false;
                    $scope.$apply();
                    notificationToastService.showErrorToast('fa-light fa-triangle-exclamation', 'We were unable to change disposition. Try again later.');
                });
        };

        $scope.loadContactTimeline = function(skip, loader) {
            $scope.loading[loader] = true;

            var teamId = authService.getTeamId();
            var userId = authService.getUserId();

            const request = {
                team_id: teamId,
                user_id: userId,
                contact_id: $location.search().id,
                names: true,
                skip: skip,
                limit: $scope.timelinePagination.itemsPerPage,
                with_recording: true
            };

            if ($scope.data.filters.type.key !== 'all') {
                request.timeline_types = $scope.data.filters.type.key;
            } else {
                request.timeline_types = [
                    'note',
                    'call',
                    'sms',
                    'rvm',
                    'fax',
                    'document',
                    'email'
                ];
            }

            phoneService.getConversations(request)
                .then(results => {
                    $scope.timelinePagination.totalItems = results.total;
                    var newData = results.entries.map(timeline => {
                        timeline.contact_number = timeline.call_direction === 'outbound' ? timeline.to : timeline.from;
                        timeline.contact_name = (timeline.first_name || timeline.last_name) ? ((timeline.first_name || '')+ ' ' + (timeline.last_name || '')).trim() : undefined;
                        return timeline;
                    });
                    if (loader === 'timelineFirstLoad') {
                        $scope.timelineData = newData;
                    } else {
                        $scope.timelineData = _.concat( $scope.timelineData, newData );
                    }

                    $scope.loading[loader] = false;
                    $scope.$apply();
                })
                .catch(err => {
                    console.error(err);
                    $scope.loading[loader] = false;
                    $scope.$apply();
                });
        };

        $scope.getSingleConversation = function(entryId) {
            var teamId = authService.getTeamId();
            var userId = authService.getUserId();

            const request = {
                team_id: teamId,
                user_id: userId,
                contact_id: $location.search().id,
                entry_id: entryId.entry_id ? entryId.entry_id : entryId,
                names: true,
                with_recording: true
            };

            if ($scope.data.filters.type.key !== 'all') {
                request.timeline_types = $scope.data.filters.type.key;
            } else {
                request.timeline_types = [
                    'note',
                    'call',
                    'sms',
                    'rvm',
                    'fax',
                    'document',
                    'email'
                ];
            }

            phoneService.getSingleConversation(request)
                .then(entry => {
                    if (entry && typeof $scope.timelineData.find(existingEntry => existingEntry.entry_id === entry.entry_id) === 'undefined') {
                        $scope.timelineData.unshift(entry);
                        $scope.data.isNewEntryAddedTimeline = true;
                    }

                    $scope.$apply();
                })
                .catch(err => {
                    console.error(err);
                    $scope.$apply();
                });
        }

        $scope.loadMoreTimelineItems = function() {
            if(!$scope.loading.timeline) {
                var skip = $scope.timelineData.length;

                if(skip > 0 && skip < $scope.timelinePagination.totalItems) {
                    $scope.loadContactTimeline(skip, 'timeline');
                }
            }
        };

        $scope.infiniteScrollListener = function(scrollsDown) {
            $('.contact-details-section .show .dropdown-toggle').dropdown('toggle');
            $('.contact-details-section .dropdown-toggle').blur();
        };

        $scope.scrollTopList = function() {
            $('#contact-timeline').stop();
            $('#contact-timeline').animate({scrollTop: 0}, 350);
        };

        $scope.renderedTimelineItemsListener = function() {
            if($scope.data.isNewEntryAddedTimeline) {
                $scope.scrollTopList();
                $scope.data.isNewEntryAddedTimeline = false;
            }

            _.forEach($scope.timelineData, function(item) {
                switch(item.type) {
                    case 'call':
                        if(!item.ddState) {
                            item.ddState = {
                                showDispositions: false,
                                shownDispositions: false,
                                action: ''
                            };
                        }
                        break;
                };

                var list = $scope.getListName(item.list_id);
                item.listName = (list) ? list.list_name : null;
            });
        };

        $scope.loadDispositions = function() {
            if(Object.keys($rootScope.available_dispositions).length > 0) {
                $scope.data.available_dispositions = $rootScope.available_dispositions;
                return;
            }

            var teamId = authService.getTeamId();
            var userId = authService.getUserId();

            const request = {
                team_id: teamId,
                user_id: userId,
            };

            dispatcherService.getAvailableDispositions(request)
                .then(results => {
                    $rootScope.available_dispositions = results || {};
                    $scope.data.available_dispositions = $rootScope.available_dispositions;
                    $scope.$apply();
                })
                .catch(err => {
                    console.error(err);
                    $scope.$apply();
                });
        };

        $scope.loadListById = function(list_id) {

            const request = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                list_id: list_id
            };

            return contactService.getList(request)
                .then(results => {
                    $scope.data.lists.push(results)
                })
                .catch(err => {
                    console.error(err);
                })
                .then(() => {
                    $scope.$apply();
                });
        };

        $scope.loadLists = function(list_ids) {
            const promises = [];

            for (let i = 0; i < list_ids.length; i++) {
                promises.push($scope.loadListById(list_ids[i]));
            }

            return Promise.all(promises);
        };

        $scope.getListName = function(listId) {
            return _.find($scope.data.lists, function(list) { return list.list_id === listId; });
        };

        $scope.loadContact = function(id, callback) {
            var teamId = authService.getTeamId();
            var userId = authService.getUserId();

            const request = {
                team_id: teamId,
                user_id: userId,
                contact_id: id,
            };

            contactService.getContactDetails(request)
                .then(result => {
                    if (result.status !== 200) {
                        swal({
                            title: 'Error',
                            text: result.message
                        }).then(()=> {
                            $scope.returnContactsPage();
                            $scope.$apply();
                        });
                        return;
                    }

                    $scope.data.contactDetails = result.contact || {};
                    if (!Array.isArray($scope.data.contactDetails.tags)) {
                        $scope.data.contactDetails.tags = [];
                    }
                    $scope.data.contactDetails.id = id;
                    const opt_in_date = $scope.data.contactDetails.field_data.find(field => field.type === 'opt_in_date') || {};
                    $scope.data.contactDetails.opt_in_date = opt_in_date.value;
                    const opt_in_source = $scope.data.contactDetails.field_data.find(field => field.type === 'opt_in_source') || {};
                    $scope.data.contactDetails.opt_in_source = opt_in_source.value;

                    return result.contact;
                })
                .then((contact) => {
                    if (callback) {
                        callback(contact.list_ids || []);
                    }
                })
                .catch(err => {
                    swal({
                        title: 'Error',
                        text: "Internal error."
                    }).then(()=> {
                        $scope.returnContactsPage();
                        $scope.$apply();
                    });
                });
        };

        $scope.loadMainData = function(callback) {
            Promise.all([
                $scope.loadContact($scope.data.currentContactId, callback),
                $scope.loadContactTimeline(0, 'timelineFirstLoad'),
                integrationService.list(),
                $scope.loadDispositions()
            ]).then(results => {
                integrationService.set(results[1]);
                $scope.integrationsCredentials = integrationService.get();

                $rootScope.$broadcast('loadContactProfileRightPanel', $scope.data.currentContactId);
                $scope.$apply();
            });
        };

        $scope.returnContactsPage = function() {
            $location.path('/contacts').search('');
        };

        $rootScope.$on('$stateChangeStart', function(e, toState, toParams, fromState, fromParams) {
            if(fromState.url === '/contacts/detail') {
                storeSelectedContactService.setContactId(null);
            }
        });

        $rootScope.$on('messageSent', function(event, eventData) {
            $scope.getSingleConversation(eventData);
        });

        $scope.$on('email.sent', function (e, ablyEvent) {
            $scope.getSingleConversation(ablyEvent.data.entry_id);
        });

        $scope.$on('email.received', function (e, ablyEvent) {
            $scope.getSingleConversation(ablyEvent.data.entry_id);
        });

        $scope.$on('sms.received', function (e, ablyEvent) {
            if ($scope.data.currentContactId === ablyEvent.data.contact_id) {
                $scope.getSingleConversation(ablyEvent.data);
            }
        });

        $scope.onInit = () => {
            setTimeout(() => {
                $rootScope.$broadcast('showRightSideBar');
            })
            $scope.mode = 'timeline';
            var params = $location.search();
            $scope.data.currentContactId = params.id;
            $scope.data.textAreaMode = params.mode;

            if(!$scope.data.currentContactId || $scope.data.currentContactId === null || $scope.data.currentContactId.length === 0) {
                $scope.returnContactsPage();
                return;
            }

            storeSelectedContactService.setContactId($scope.data.currentContactId);
            $scope.loadMainData((list_ids) => {
                $scope.loadLists(list_ids);
            });
        };

        if (authService.hasAccount()) {
            $scope.onInit();
        }
        else {
            $scope.$on('auth_complete', function(event, args) {
                $scope.onInit();
            });
        }
    }
    module.exports = ContactDetailsController;
})();
