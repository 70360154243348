(function () {
    'use strict';

    function ListsController(authService, $scope, $rootScope, contactService, dispatcherService, importService, phoneService, $window, $interval, $location, $http, $cookies, integrationService, userService, notificationToastService, generalHelperService) {

        var vm = this;
        vm.authService = authService;

        $rootScope.viewData = $rootScope.viewData || {};
        $rootScope.viewData.breadcrumbs = $rootScope.viewData.breadcrumbs || [];
        $rootScope.viewData.breadcrumbs = [{
            title: 'Lists',
            link_name: 'Lists',
            link_url: '#/lists',
            help_url: $rootScope.help_url
        }];

        $scope.staticData = {
            title: 'Lists',
            subtitle: 'Manage your lists below',
            dialAllBtn: 'Dial All',
            editDialingRulesBtn: 'Default Dialing Rules',
            addListBtn: 'Add List',
            importListBtn: 'Import',
            loading: 'Loading',
            importing: 'Importing',
            noRes: 'No Results',
            importBlock: {
                inProgress: 'Import in-progress',
                importedStr1: 'Number of Contacts: ',
                importedStr2: ''
            },
            nonCallableBlock: {
                title: 'DNC Contacts:'
            },
            callableBlock: {
                title: 'Callable:',
                options: [ 'Allow', 'Disable' ]
            },
            integrationBlock: {
                title: 'Integration Type:',
                options: [
                    'Shopify'
                ]
            },
            availableToDialBlock: {
                title: 'Available to Dial:'
            },
            lastDialedBlock: {
                title: 'Last Dialed:'
            },
            abandonmentBlock: {
                title: 'Abandonment:'
            },
            visibilityBlock: {
                title: 'Visibility:'
            },
            listOptions: [
                'Add Contacts',
                'View Contacts',
                'View Calls',
                'Start Dialing',
                'Dialing Rules',
                'Delete List'
            ],
            errors: {
                default: 'Field is required.',
                map: 'You must select the main phone number column.',
                listName: 'Please enter a name for your contact list',
                emptyFile: 'Drop a CSV, TSV, or TAB file containing your contacts',
                emptyMapping: 'Please select column with phone number',
                valid: 'Please drop file and fill all required fields',
                duplicateMapping: 'This value is already selected.'
            },
            integrationImportModal: {
                title: {
                    str1: 'Import Contacts ',
                    str2: 'to List ',
                    str3: 'to New List'
                },
                submitBtn: 'Import',
                syncBtn: 'Sync Data',
            },
            importModal: {
                title: {
                    str1: 'Add Contacts ',
                    str2: 'to List ',
                    str3: 'to New List'
                },
                listNameBlock: {
                    title: 'List Name',
                    subtitle: 'Enter a name for your list...'
                },
                addContactsBlock: {
                    title: 'Add Contacts',
                    subtitle: 'Drop a CSV, TSV, or TAB file containing your contacts below...',
                    desc: '(You can export csv from your CRM or lead capture software. Upload limit is 1 million rows per csv.)',
                    optional: '(optional)'
                },
                describeBlock: {
                    title: 'Describe Your Spreadsheet',
                    undoBtn: 'Undo Upload',
                    subtitle: 'We need to know which column contains the phone number to deliver you message to...',
                    subtitleList: [
                        'For each column in your spreadsheet...',
                        'Select a label that best describes the column.',
                        'Mapping all columns helps with reporting but only phone number is required.'
                    ],
                    spreadsheetHeader: [
                        'Main Phone',
                        'Secondary Phone',
                        'First Name',
                        'Last Name',
                        'Company',
                        'Email',
                        'Fax',
                        'Address',
                        'Suite/Apt',
                        'City',
                        'State',
                        'Country',
                        'Zip Code',
                        'Record ID',
                        'Opt-in Date',
                        'Opt-in Source'
                    ]
                },
                countryBlock: {
                    title: 'Choose a country',
                    subtitle: 'What country are your phone numbers in...'
                },
                replaceAppendContactBlock: {
                    str1: 'If contact already exists, ',
                    options: ['replace', 'append'],
                    str2: ' contact information'
                },
                compliance: 'The contacts in this list provided express written consent to be contacted using an automated dialing system (ATDS), ' +
                    'meet all other requirements to be contacted under the TCPA, and comply with any other applicable laws, rule, and regulations ' +
                    `governing my ability to call the contact including all requirements outlined in the ${$rootScope.settings.APP_NAME} `,
                complianceTermsOfService: {
                    text: 'Terms of Service',
                    link: 'https://www.callcowboy.com/terms'
                },
                submitBtn: 'Submit'
            },
            processing: 'Processing'
        };

        $scope.loading = {
            listsFirstLoad: true,
            lists: false,
            user_groups: true,
            uploading: false,
            imports: false,
            importing: false,
            importingIntegration: false
        };
        $scope.isGetStarted = false;

        $scope.data = {
            countries: [],
            lists: [],
            customFields: [],
            isMapped: false,
            country: {
                selected: {
                    code: 'US',
                    name: "United States"
                }
            },
            list: {
                list_id: null,
                list_name: "",
                import_id: null
            },
            file: null,
            csv: null,
            csv_preview: null,
            import_mode: "append",
            csv_headers: null,
            csv_has_header_row: false,
            valid: false,
            errors: {
                fields: {
                    listName:undefined,
                    csvValid:undefined
                },
                mappingValid: true,
                lastSelectedHeader: null
            },
            user_groups: [],
            searchTerm: ($location.search().searchTerm || ''),
            integrationImportModal: {
                integrationType: null
            }
        };

        $scope.totalItems = 0;
        $scope.callableItems = 0;
        $scope.currentPage = 1;
        $scope.itemsPerPage = 8;
        $scope.maxSize = 3;

        $scope.isFormSubmitted = false;

        $scope.fieldTypeMapping = {
            'Main Phone': 'main_phone',
            'Secondary Phone': 'secondary_phone',
            'First Name': 'first_name',
            'Last Name': 'last_name',
            'Company': 'company',
            'Email': 'email',
            'Fax': 'fax_number',
            'Address': 'address',
            'Suite/Apt': 'suite/apt',
            'City': 'city',
            'State': 'state',
            'Country': 'country',
            'Zip Code': 'zip_code',
            'Record ID': 'record_id',
            'Opt-in Source': 'opt_in_source',
            'Opt-in Date': 'opt_in_date'
        };

        $scope.dropify = {
            options: { },
            events: { }
        };

        // Basic
        $('.dropify-csv').dropify({
            messages: {
                'default': 'Drag and drop a csv file here or click',
                'replace': 'Drag and drop or click to replace',
                'remove':  'Remove',
                'error':   'Try again.'
            }});

        var drEvent = $('#import-file-events').dropify();

        drEvent.on('dropify.beforeClear', function(event, element) {
            return confirm("Do you really want to delete \"" + element.file.name + "\" ?");
        });

        drEvent.on('dropify.afterClear', function(event, element) {
            alert('File deleted');
        });

        drEvent.on('dropify.errors', function(event, element) {
            console.log('Has Errors');
        });

        drEvent.on('dropify.errors', function(event, element) {
            console.log('Has Errors');
        });

        $('.dropify-csv').change(function(event) {

            var target = event.currentTarget || {};
            if (target && target.files && target.files.length) {

                var file = target.files[0];

                var parts = (file.name || '').split('.');
                var ext = (parts[parts.length - 1] || '').toLowerCase();
                if (ext === 'csv' || ext === 'tab' || ext === 'tsv') {

                    $scope.data.file = file;

                    var rows = [];
                    Papa.parse(file, {
                        config: {
                            worker: true
                        },
                        step: function(results, parser) {
                            rows.push(results.data[0]);
                            if (rows.length > 4) {
                                parser.abort();
                            }
                        },
                        complete: function(results) {
                            $scope.data.csv = rows;
                            $scope.data.csv_preview = rows.slice(0, 4);

                            var headers = [];
                            var colHeaders = rows[0] || [];
                            for (var i = 0; i < colHeaders.length; i++) {
                                headers.push(null);
                            }
                            $scope.data.csv_headers = headers;

                            $scope.isFormSubmitted = false;
                            $scope.data.errors.fields.listName = undefined;
                            $scope.data.errors.fields.csvValid = undefined;

                            $scope.$apply();
                        }
                    });
                }
            }
        });

        $scope.checkListName = function(list) {
            if (list.length === 0) {
                return 'List Name must have at least 1 character.';
            }
        };

        $scope.newNameValue = '';
        $scope.editListName = function(list) {
            if($scope.newNameValue !== list.list_name) {
                $scope.newNameValue = list.list_name;

                const request = {
                    team_id: authService.getTeamId(),
                    user_id: authService.getUserId(),
                    list_id: list.list_id,
                    list_name: list.list_name
                };

                contactService.updateList(request)
                    .then(results => {
                        notificationToastService.showSuccessToast('fa-light fa-circle-check', 'List name has been updated!');
                    })
                    .catch(err => {
                        console.error(err);
                        notificationToastService.showErrorToast('fa-light fa-triangle-exclamation', 'We were unable to update list. Try again later.');
                    });
            }
        };

        $scope.mapHeader = function(index, value) {
            $scope.data.errors.mappingValid = true;
            $scope.data.errors.lastSelectedHeader = null;
            if ($scope.data.csv_headers.indexOf(value) !== -1) {
                $scope.data.errors.mappingValid = false;
                $scope.data.errors.lastSelectedHeader = value;
            } else {
                $scope.data.csv_headers[index] = value;
                //$scope.data.isMapped = $scope.data.csv_headers.some(field => {return !!field});
                $scope.data.isMapped =  $scope.__phoneMapped();
                $scope.validateStep();
            }
        };

        $scope.generateExistFieldMessage = function() {
            return `${$scope.data.errors.lastSelectedHeader} is already selected.`;
        }

        $scope.__phoneMapped = function() {
            var headers = $scope.data.csv_headers || [];
            for (var i = 0; i < headers.length; i++) {
                if (headers[i] === 'Main Phone') {
                    return true;
                }
            }

            return false;
        };

        $scope._validateFields = function(listName, csvValid) {
            $scope.data.errors.fields.listName = !!(listName);
            $scope.data.errors.fields.csvValid = !!(csvValid);
        };

        $scope.validateStep = function() {
            var csvValid = ($scope.__csvHasAtLeastOneRow() /*&& $scope.__csvMapped()*/ && $scope.__phoneMapped());

            if($scope.isFormSubmitted && !$scope.data.valid) {
                $scope._validateFields($scope.data.list.list_name, csvValid);
            }

            let csvRequired = !!($scope.data.list.list_id || $scope.data.file);
            $scope.data.valid = !!($scope.data.list.list_name && (csvValid || !csvRequired));
        };

        $scope.__csvHasAtLeastOneRow = function() {

            var minCount = $scope.data.csv_has_header_row ? 1 : 0;
            return (($scope.data.csv || []).length > minCount);
        };

        $scope.setIntegrationType = (type) => {
            $scope.data.integrationImportModal.integrationType = type;
            $scope.data.integrationImportModal.integrationType = type;
        };

        $scope.showIntegrationImport = function(list) {

            $scope.data.list = list || {
                list_id: null,
                list_name: 'My Contacts'
            };

            $scope.data.country = {
                selected: {
                    code: 'US',
                    name: "United States"
                }
            };
            $scope.isFormSubmitted = false;
            $scope.data.valid = false;
            $scope.data.errors.mappingValid = true;
            $scope.data.errors.lastSelectedHeader = null;
            $scope.data.isMapped = false;
            $scope.data.errors.fields.listName = undefined;
            $scope.data.errors.fields.csvValid = undefined;

            angular.element('.dropify-clear').triggerHandler('click');
            $('#integrationImportModal').modal('show');
        };

        $scope.showImport = function(list) {

            $scope.data.list = list || {
                list_id: null,
                list_name: 'My Contacts'
            };

            $scope.data.country = {
                selected: {
                    code: 'US',
                    name: "United States"
                }
            };
            $scope.isFormSubmitted = false;
            $scope.data.valid = false;
            $scope.data.errors.mappingValid = true;
            $scope.data.errors.lastSelectedHeader = null;
            $scope.data.isMapped = false;
            $scope.data.errors.fields.listName = undefined;
            $scope.data.errors.fields.csvValid = undefined;
            $scope.data.csv = null;
            $scope.data.csv_preview = null;
            $scope.data.csv_headers = null;
            $scope.data.csv_has_header_row = false;
            $scope.data.file = null;
            $scope.data.customFields = $rootScope.customFields;
            $scope.data.customFields.forEach(customField => {
                $scope.fieldTypeMapping[customField.custom_field_id] = 'custom_field';
            });

            angular.element('.dropify-clear').triggerHandler('click');
            $('#importModal').modal('show');
        };

        $scope.deleteList = function(list) {
            swal("Delete List", "Do you want to delete "+ (list.list_name) +"?", { buttons: true, dangerMode: true })
                .then(confirm => {
                    if (!confirm) { return; }

                    $scope.loading.uploading = true;
                    const request = {
                        team_id: authService.getTeamId(),
                        user_id: authService.getUserId(),
                        list_id: list.list_id
                    };

                    contactService.deleteList(request)
                        .then(results => {
                            var lists = $scope.data.lists || [];
                            for (var i = 0; i < lists.length; i++) {
                                if (lists[i].list_id == list.list_id) {

                                    lists.splice(i, 1);
                                    break;
                                }
                            }
                        })
                        .catch(err => {
                            console.error(err);
                            notificationToastService.showErrorToast('fa-light fa-triangle-exclamation', 'Our server failed to respond. Please try again.');
                        })
                        .then(() => {
                            $scope.loading.uploading = false;
                            $scope.$apply();
                        });
                });
        };

        $scope.flattenObject = (obj, prefix = '') => {
            let result = {};
            for (const key in obj) {
                if (obj.hasOwnProperty(key)) {
                    const newKey = prefix ? `${prefix}_${key}` : key;
                    if (typeof obj[key] === 'object' && obj[key] !== null) {
                        Object.assign(result, $scope.flattenObject(obj[key], newKey));
                    } else {
                        result[newKey] = obj[key];
                    }
                }
            }
            return result;
        }

        $scope.importShopifyClients = () => {

            const request = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                shopify_token: $rootScope.shopifyToken || $cookies.get('shopifyToken'),
                shopify_store: ($scope.integrationsCredentials['shopify'] || {}).integration_foreign_id || null
            };

            integrationService.shopifyImport(request)
                .then(results => {
                    const flatCSV = results.map(row => $scope.flattenObject(row));

                    var headers = [];
                    const keys = Object.keys(flatCSV[0] || {});
                    for (var i = 0; i < keys.length; i++) {
                        headers.push(null);
                    }
                    $scope.data.csv_headers = headers;
                    $scope.data.csv_has_header_row = 1;
                    const csvHeaders = keys;

                    $scope.data.csv = flatCSV;
                    const rows = flatCSV.map(obj =>
                        csvHeaders.map(header => obj[header] || '').join(',')
                    );
                    const csvFileContent = [csvHeaders.join(','), ...rows].join('\n');
                    const csvBlob = new Blob([csvFileContent], { type: 'text/csv' });

                    $scope.data.file = new File([csvBlob], 'shopifyData.csv', { type: 'text/csv' });

                    $scope.data.csv_preview = flatCSV.slice(0, 4);
                    $scope.loading.importingIntegration = false;

                    $scope.isFormSubmitted = false;
                    $scope.data.errors.fields.listName = undefined;
                    $scope.data.errors.fields.csvValid = undefined;

                    $scope.$apply();
                })
                .catch(err => {
                    $scope.loading.importingIntegration = false;
                    $scope.$apply();
                    swal("Server Error", "Our server failed to respond.", "error");
                });
        }

        $scope.importIntegrationData = () => {

            $scope.loading.importingIntegration = true;

            switch ($scope.data.integrationImportModal.integrationType) {
                case 'Shopify':
                    $scope.importShopifyClients();
                    break;
            }
        };

        $scope.viewCalls = function(list) {
            $location.path('/recent-calls').search({listId: list.list_id, viewAll: true});
        };

        $scope.listTitleClick = function(e) {
            $('.btn-group.show .dropdown-toggle').dropdown('toggle');
            e.stopPropagation();
        };

        $scope.openContacts = function(e, list) {
            var actionDiv = document.getElementById('action-col-' + list.list_id);
            var userGroupsDiv = document.getElementById('user-groups-' + list.list_id);
            var callableDiv = document.getElementById('callable-' + list.list_id);
            var editListNameDiv = document.getElementById('edit-list-name-' + list.list_id);
            var loader = document.getElementById('loader-' + list.list_id);

            if((!actionDiv || !actionDiv.contains(e.target)) && (!userGroupsDiv || !userGroupsDiv.contains(e.target)) &&
                (!callableDiv || !callableDiv.contains(e.target)) && (!editListNameDiv || !editListNameDiv.contains(e.target)) && !loader) {
                $scope.viewContacts(list);
            }
        };

        $scope.viewContacts = function(list) {
            if(list.list_id && list.list_id !== null) {
                $location.path('/contacts').search({listId: list.list_id});
            }
        };

        $scope.isDialerPlan = function() {
            return $rootScope.isDialerPlan();
        };

        $scope.configList = function(list) {
            $location.path('/list-config').search({listId: list.list_id});
        };

        $scope.editDialingRules = function(list) {
            if(list){
                $location.path('/dialing-rules').search({listId: list.list_id});
            }
            else {
                $location.path('/dialing-rules');
            }
        };

        $scope.hasPermissions = function(entity, action) {
            return $rootScope.hasPermissions(entity, action);
        };

        $scope.createList = function(list) {

            $scope.isFormSubmitted = true;

            if (!$scope.data.valid || !$scope.data.compliance) {
                swal("Error", $scope.staticData.errors.valid, "error");
                return;
            }

            $scope.loading.importing = true;

            // Adding contacts to an existing list...
            if ($scope.data.list.list_id) {
                $scope.__createImport($scope.data.list);
            }

            // Adding contacts to a new list...
            else {

                let request = {
                    team_id: authService.getTeamId(),
                    user_id: authService.getUserId(),
                    list_name: $scope.data.list.list_name,
                    callable: true,
                    compliance: $scope.data.compliance
                };

                contactService.createList(request)
                    .then(results => {
                        $scope.data.list = results;
                        $scope.data.list.abandonment = {
                            rate: 0
                        };
                        $scope.initVisibilityLabel($scope.data.list);
                        $scope.data.lists.unshift($scope.data.list);

                        if (!$scope.data.valid || $scope.loading.uploading || !$scope.data.file) {
                            $scope.loading.importing = false;
                            $scope.$apply();

                            $('#importModal').modal('hide');
                            $('#integrationImportModal').modal('hide');
                        } else {
                            $scope.__createImport($scope.data.list);
                        }
                    })
                    .catch(err => {
                        $scope.loading.uploading = false;
                        $scope.loading.importing = false;
                        $scope.$apply();
                        swal("Server Error", "Our server failed to respond.  You may have too many columns in your spreadsheet.  Please try uploading a sheet with only a phone number column.", "error");
                    });
            }
        };

        $scope.__toColLetters = function(value) {
            var colName = '';
            var dividend = Math.floor(Math.abs(value));
            var rest;

            while (dividend > 0) {
                rest = (dividend - 1) % 26;
                colName = String.fromCharCode(65 + rest) + colName;
                dividend = parseInt((dividend - rest) / 26);
            }
            return colName;
        };

        $scope.loadMoreLists = function() {
            $scope.loadLists(($scope.currentPage - 1) * $scope.itemsPerPage, 'lists');
        };

        $scope.loadLists = function(skip, loader) {
            $scope.loading[loader] = true;
            $window.scrollTo(0, 0);

            var teamId = authService.getTeamId();
            var userId = authService.getUserId();

            const request = {
                team_id: teamId,
                user_id: userId,
                skip: skip,
                limit: $scope.itemsPerPage,
                get_total: true
            };

            if ($location.search().searchTerm) {
                request.search_term = $location.search().searchTerm;
            }

            contactService.getLists(request)
                .then(results => {
                    $scope.data.lists = results.lists.sort((a, b) => {
                        if (a.delete_protected && b.delete_protected) {
                            return 0
                        } else if (a.delete_protected && !b.delete_protected) {
                            return -1;
                        } else if (!a.delete_protected && b.delete_protected) {
                            return 1;
                        }
                        return 0
                    })
                        .map(list => {
                            $scope.initVisibilityLabel(list);
                            return list;
                        });
                    $scope.totalItems = results.total_lists;
                    $scope.callableItems = results.callable_lists;
                    $scope.loading[loader] = false;
                })
                .catch(err => {
                    console.error(err);
                    $scope.loading[loader] = false;
                })
                .finally(() => {
                    $scope.initTooltip();
                    $scope.$apply();
                });
        };

        $scope.initVisibilityLabel = function(list) {
            var userGroupId = list.visibility.type === 'teams' ? list.visibility.user_group_id : null;
            list.visibilityNgModel = _.find($scope.data.user_groups, function(group) { return group.user_group_id === userGroupId; });
        }

        // $scope.getVisibilityLabel = function(list) {
        //     if (!list.visibility) {
        //         return 'Everyone';
        //     }
        //     return list.visibility.type === 'teams' ? list.user_group.name : list.visibility.type.charAt(0).toUpperCase() + list.visibility.type.slice(1);
        // }

        $scope.loadUserGroups = function() {
            $scope.loading.user_groups = true;

            const request = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                sort: 'asc'
            };

            userService.listUserGroups(request)
                .then(results => {
                    $scope.data.user_groups = results.teams;
                    $scope.data.user_groups.unshift({name: 'Everyone', user_group_id: null});
                    $scope.loading.user_groups = false;
                    $scope.$apply();
                })
                .catch(err => {
                    console.error(err);
                    $scope.loading.user_groups = false;
                    $scope.$apply();
                });
        };

        $scope.setCallable = function(list, callable) {
            list.processing = true;

            const request = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                list_id: list.list_id,
                callable: callable
            };

            contactService.updateList(request)
                .then(results => {
                    list.callable = callable;
                    if (callable) {
                        $scope.callableItems += 1;
                    } else {
                        $scope.callableItems -= 1;
                    }
                    list.processing = false;
                    $scope.$apply();
                    notificationToastService.showSuccessToast('fa-light fa-circle-check', 'List has been updated!');
                })
                .catch(err => {
                    console.error(err);
                    list.processing = false;
                    $scope.$apply();
                    notificationToastService.showErrorToast('fa-light fa-triangle-exclamation', 'We were unable to update list. Try again later.');
                });
        };

        $scope.setVisibility = function(list) {
            list.processing = true;

            const visibility = list.visibilityNgModel.user_group_id === null ? {type: 'everyone'} : {type: 'teams', user_group_id: list.visibilityNgModel.user_group_id, user_group_name: list.visibilityNgModel.name};
            const request = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                list_id: list.list_id,
                visibility: visibility
            };

            contactService.updateList(request)
                .then(results => {
                    list.visibility = visibility;
                    if (list.visibilityNgModel.user_group_id !== null) {
                        list.user_group = list.visibilityNgModel;
                    }

                    $scope.data.lists = $scope.data.lists.map(listItem => {
                        if (listItem.list_id === list.list_id) {
                            return list;
                        }
                        return listItem;
                    });

                    list.processing = false;
                    $scope.$apply();
                    notificationToastService.showSuccessToast('fa-light fa-circle-check', 'List has been updated!');
                })
                .catch(err => {
                    console.error(err);
                    list.processing = false;
                    $scope.$apply();
                    notificationToastService.showErrorToast('fa-light fa-triangle-exclamation', 'We were unable to update list. Try again later.');
                });
        };

        $scope.__uploadFile = function(list, file, policy) {

            $.ajax(policy.url, {
                method: 'PUT',
                contentType: policy.content_type,
                processData: false,
                data: file
            })
                .done(function(data, textStatus, jqXHR) {

                    $scope.__startImport(list);
                })
                .fail(function(jqXHR, textStatus, errorThrown) {
                    swal("Failure", "Your file failed to upload.  Please try again.", "error");
                    console.log(arguments);

                    $scope.loading.uploading = false;
                    $scope.loading.importing = false;
                    $scope.$apply();
                });
        };

        $scope.__startImport = function(list) {

            const request = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                import_id: list.import.import_id
            };

            importService.startImport(request)
                .then(results => {
                    $scope.loading.uploading = false;
                    $scope.loading.importing = false;
                    $scope.$apply();

                    $('#importModal').modal('hide');
                    $('#integrationImportModal').modal('hide');
                    if($scope.isGetStarted && $scope.data.lists.length === 1) {
                        $location.search({});
                        $location.path('dash');
                    }
                })
                .catch(err => {
                    $scope.loading.uploading = false;
                    $scope.loading.importing = false;
                    $scope.$apply();
                    swal("Server Error", "Our server failed to respond.  You may have too many columns in your spreadsheet.  Please try uploading a sheet with only a phone number column.", "error");
                });
        };

        $scope.__createImport = function(list) {

            $scope.loading.uploading = true;

            const request = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                list_id: list.list_id,
                start_row: $scope.data.csv_has_header_row ? 1 : 0,
                filename: $scope.data.file.name,
                import_mode: $scope.data.import_mode,
                field_map: $scope.__buildFieldMeta(),
                country_iso: $scope.data.country.selected.code
            };

            importService.createImport(request)
                .then(results => {
                    list.import = {
                        import_id: results.import_id,
                        status: 'pending',
                        old_accepted_count: list.import ? list.import.accepted_count : 0
                    };

                    for (var i = 0; i < $scope.data.lists; i++) {
                        if ($scope.data.lists[i].list_id == list.list_id) {
                            $scope.data.lists[i].import = list.import;
                            break;
                        }
                    }

                    $scope.__uploadFile(list, $scope.data.file, results.policy);
                })
                .catch(err => {
                    $scope.loading.uploading = false;
                    $scope.loading.importing = false;
                    $scope.$apply();
                    swal("Server Error", "Our server failed to respond.  You may have too many columns in your spreadsheet.  Please try uploading a sheet with only a phone number column.", "error");
                });
        };

        $scope.__buildFieldMeta = function() {

            let results = [];

            const headerRow = ($scope.data.csv || [])[0] || [];

            const headers = $scope.data.csv_headers || [];
            for (let i = 0; i < headers.length; i++) {
                let header = headers[i];
                if (header && typeof header === 'object' && header.custom_field_id) {
                    results.push({
                        type: 'custom_field',
                        field_id: header.custom_field_id,
                        field_name: (header.display_name.replace(/ /g,"_")).replace(/[^a-zA-Z0-9_]/g, '').toLowerCase(),
                        display_name: header.display_name
                    });
                } else  {
                    const typeMapping = $scope.fieldTypeMapping[header];

                    const fieldName = ((($scope.data.csv_has_header_row) ? headerRow[i] : 'Column ' + $scope.__toColLetters(i + 1)) || '').trim();
                    const displayName = typeMapping ? header : fieldName;

                    results.push({
                        type: typeMapping || null,
                        field_name: typeMapping || "",  // we have to store field_name like a type. for example secondary_phone
                        display_name: displayName || ""
                    });
                }
            }

            return results;
        };

        $scope.__checkImportStatus = function(list) {

            if (list.import && list.import.import_id && list.import.status !== 'done') {

                const request = {
                    team_id: authService.getTeamId(),
                    user_id: authService.getUserId(),
                    import_id: list.import.import_id
                };

                // Get import and just update lists if import is done
                importService.getImport(request)
                    .then(results => {
                        if (results.status === 'done') {
                            $scope.loadLists(($scope.currentPage - 1) * $scope.itemsPerPage, 'lists');
                        }
                        $scope.loading.imports = false;
                        $scope.$apply();
                    })
                    .catch(err => {
                        console.error(err);
                        $scope.loading.imports = false;
                        $scope.$apply();
                    });
            }
        };

        $scope.clearUpload = function ($event) {

            $event.preventDefault();

            $scope.isFormSubmitted = false;
            $scope.data.errors.fields.listName = undefined;
            $scope.data.errors.fields.csvValid = undefined;
            $scope.data.csv = null;
            $scope.data.csv_preview = null;
            $scope.data.csv_headers = null;
            $scope.data.csv_has_header_row = false;
            $scope.data.file = null;

            $scope.validateStep();

            angular.element('.dropify-clear').triggerHandler('click');
        };

        $scope.updateImports = function() {

            var lists = $scope.data.lists || [];
            for (var i = 0; i < lists.length; i++) {
                $scope.__checkImportStatus(lists[i]);
            }
        };

        $scope.setPage = function (pageNo) {
            $scope.currentPage = pageNo;
        };

        $scope.setItemsPerPage = function(num) {
            $scope.itemsPerPage = num;
            $scope.currentPage = 1; //reset to first page
        };

        $scope.startDialing = function(list) {
            if ($scope.callableItems === 0 || list && list.import.accepted_count === 0) {
                return;
            }
            $rootScope.$broadcast('startDialing:broadcast', list);
        };

        $scope.isCorrectDate = function(list, type) {
            switch (type) {
                case 'call':
                    if(list.last_dial && list.last_dial.call_info && list.last_dial.call_info.last_called_at && list.last_dial.call_info.last_called_at > 0 && moment(list.last_dial.call_info.last_called_at).isValid()) {
                        return true;
                    }
                    return false;
            }
        };

        $scope.updateLocation = function(updateSearchTerm) {
            let search = {};
            if (updateSearchTerm) {
                if ($scope.data.searchTerm) { search.searchTerm = $scope.data.searchTerm; }
            } else if ($location.search().searchTerm) {
                search.searchTerm = $location.search().searchTerm;
            }
            $location.path('/lists').search(search);
        };

        $scope.clearSearch = function() {
            $scope.data.searchTerm = '';
            $location.path('/lists').search('');
        };

        $scope.destroyTooltip = function(selector) {
            const tooltip = angular.element(document).find(selector + ' [data-toggle="tooltip"]');
            tooltip.tooltip('dispose');
        };

        $scope.initTooltip = function() {
            for (let i = 0; i < $scope.data.lists.length; i++) {
                $scope.destroyTooltip(`#list-info-tcpa-tooltip_${$scope.data.lists[i].list_id}`);
                generalHelperService.waitForElement(`#list-info-tcpa-tooltip_${$scope.data.lists[i].list_id}`)
                    .then(element => {
                        $(element).tooltip({ container: 'body' });
                    });
            }
        };

        $scope.$on('$locationChangeSuccess',
            function(event, newUrl, oldUrl) {
                if ( $location.path() == '/lists' ) {
                    $scope.currentPage = 1;
                    $scope.data.lists = [];
                    $scope.data.searchTerm = ($location.search().searchTerm || '');
                    $scope.loadLists(0, 'listsFirstLoad');
                }
            }
        );

        $scope.onInit = function() {
            $scope.isGetStarted = ($location.search().type === 'get_started') ? true : false;
            $scope.loadLists(0, 'listsFirstLoad');
            $scope.loadUserGroups();

            $http.get('assets/content/countries.json')
                .then(function(res){
                    $scope.data.countries = res.data;
                });

            $scope.$on('contact.import.complete', function(e, ablyEvent) {

                console.log('Got ' + ablyEvent.event);

                let eventData = ablyEvent.data || {};
                if (eventData.team_id === authService.getTeamId()) {
                    $scope.updateImports();
                }
            });

            integrationService.list()
                .then(integrations => {
                    integrationService.set(integrations);
                    $scope.integrationsCredentials = integrationService.get();
                })
        };

        if (authService.hasAccount()) {
            $scope.onInit();
        }
        else {
            $scope.$on('auth_complete', function(event, args) {
                $scope.onInit();
            });
        }
    }
    module.exports = ListsController;
})();
