(function() {
    'use strict';

    function ContactTimelineTypePlaceholderListItemController(authService) {
        var vm = this;
        vm.authService = authService;

    }
    module.exports = ContactTimelineTypePlaceholderListItemController;
})();
