(function () {
    'use strict';

    function BoardsService(authService, apiService, $rootScope) {
        return {
            getBoards: function (request) {
                return apiService.get($rootScope.settings.API_BOARDS_URL + '/boards', request);
            },
            createBoard: function (request) {
                return apiService.post($rootScope.settings.API_BOARDS_URL + '/boards', request);
            },
            updateBoard: function (request) {
                return apiService.put($rootScope.settings.API_BOARDS_URL + `/boards/${request.board_id}`, request);
            },
            addListToBoard: function (request) {
                return apiService.post($rootScope.settings.API_BOARDS_URL + `/boards/${request.board_id}/list`, request);
            },
            deleteListFromBoard: function (request) {
                return apiService.del($rootScope.settings.API_BOARDS_URL + `/boards/${request.board_id}/list/${request.list_id}`, request);
            },
            deleteBoard: function (request) {
                return apiService.del($rootScope.settings.API_BOARDS_URL + `/boards/${request.board_id}`, request);
            },
        };
    }
    module.exports = BoardsService;
})();
