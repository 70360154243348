(function () {
    'use strict';

    function JsSipService($window, $rootScope, rtcSettingsService) {

        let self = this;
        self.JsSIP = $window.JsSIP;
        self.ua = null;
        self.socket = null;
        self.eventHandler = false;
        self.stunServers = ['stun:stun.comsapi.com:3478', 'stun:stun.l.google.com:19302'];
        self.session = null;
        self.verbose = true;
        self.can_dial = true;
        self.ice_candidate_timeout = 2000;

        self.HangupCause = {
            UNALLOCATED_NUMBER: 'UNALLOCATED_NUMBER',
            NORMAL_CLEARING: 'NORMAL_CLEARING',
            NO_ANSWER: 'NO_ANSWER',
            USER_BUSY: 'USER_BUSY',
            CALL_REJECTED: 'CALL_REJECTED',
            ORIGINATOR_CANCEL: 'ORIGINATOR_CANCEL',
            DESTINATION_OUT_OF_ORDER: 'DESTINATION_OUT_OF_ORDER',
            NORMAL_TEMPORARY_FAILURE: 'NORMAL_TEMPORARY_FAILURE'
        };

        self.HangupCauseCode = {
            UNALLOCATED_NUMBER: 404,
            NORMAL_CLEARING: 16,
            NO_ANSWER: 480,
            USER_BUSY: 486,
            CALL_REJECTED: 603,
            ORIGINATOR_CANCEL: 487,
            DESTINATION_OUT_OF_ORDER: 502,
            NORMAL_TEMPORARY_FAILURE: 503
        };

        self.Events = {
            UA_CONNECTING: 'UA_CONNECTING',
            UA_CONNECTED: 'UA_CONNECTED',
            UA_DISCONNECTED: 'UA_DISCONNECTED',
            UA_REGISTERED: 'UA_REGISTERED',
            UA_UNREGISTERED: 'UA_UNREGISTERED',
            UA_REGISTRATION_FAILED: 'UA_REGISTRATION_FAILED',
            SESSION_CREATED: 'SESSION_CREATED',
            SESSION_DIALING: 'SESSION_DIALING',
            SESSION_PROGRESS: 'SESSION_PROGRESS',
            SESSION_ANSWERED: 'SESSION_ANSWERED',
            SESSION_HANGUP: 'SESSION_HANGUP',
            SESSION_FAILED: 'SESSION_FAILED'
        };

        function log(e) {
            if (self.verbose) {
                console.log(e);
            }
        }

        function __notify(eventName, eventData) {
            log(eventName);
            log(eventData);

            if (self.eventHandler) {
                self.eventHandler(eventName, eventData);
            }
        }

        function __onIceCandidate(session) {

            try {
                // On some computers with multiple network adaptors, searching for ice candidates can take a very long time.
                // We will limit the amount of time that JSSip spends evaluating each ice candidate.

                let candidateTimeout = null;
                session.on('icecandidate', function (candidate, ready) {
                    log('Getting icecandidate ' + candidate.candidate.candidate);
                    if (candidateTimeout !== null) {
                        clearTimeout(candidateTimeout);
                    }

                    // Set time limit of 2s for each candidate to ready up
                    candidateTimeout = setTimeout(candidate.ready, self.ice_candidate_timeout);
                });

                //console.log('con', session.connection);
            }
            catch(e) {
                log(e);
            }
        }

        function __onPeerConnection(e) {

            try {
                // If a new peer joins (conference / video calls) then we need to set the audio stream on that connection
                const peerConnection = e.peerconnection;
                peerConnection.onaddstream = function (e) {
                    rtcSettingsService.setRemoteAudioStream(e.stream);
                };

                let remoteStream = new window.MediaStream();
                peerConnection.getReceivers().forEach(function (receiver) {
                    log(receiver);
                    remoteStream.addTrack(receiver.track);
                });
            }
            catch(e) {
                log(e);
            }
        }

        function __setRemoteAudioStream(session) {
            try {
                session.on('peerconnection', (e) => __onPeerConnection(e));

                // Set the primary connection's audio stream
                session.connection.onaddstream = function(e) {
                    rtcSettingsService.setRemoteAudioStream(e.stream);
                };
            }
            catch(e) {
                log(e);
            }
        }

        function __connect(subscriber, eventHandler) {

            try {
                self.eventHandler = eventHandler;

                log('Starting webRTC...');

                if (!self.verbose) {
                    self.JsSIP.debug.disable('JsSIP:*');
                }

                let sub = subscriber || {};
                self.socket = new self.JsSIP.WebSocketInterface(sub.ws_uri);

                let configuration = {
                    sockets: [ self.socket ],
                    uri: sub.aor_uri,
                    username: sub.username,
                    password: sub.password,
                    register: sub.register || false
                };

                self.ua = new self.JsSIP.UA(configuration);
                self.ua.on('connecting', (e) => __notify(self.Events.UA_CONNECTING, e)); // Web socket connecting
                self.ua.on('connected', (e) => __notify(self.Events.UA_CONNECTED, e)); // Web socket connected
                self.ua.on('disconnected', (e) => __notify(self.Events.UA_DISCONNECTED, e));  // Web socket disconnected
                self.ua.on('registered', (e) => __notify(self.Events.UA_REGISTERED, e)); // Registered with the SIP Server (UA will receive inbound calls / messages)
                self.ua.on('unregistered', (e) => __notify(self.Events.UA_UNREGISTERED, e)); // Unregistered with the SIP Server (UA will stop receive inbound calls / messages)
                self.ua.on('registrationFailed', (e) => __notify(self.Events.UA_REGISTRATION_FAILED, e)); // Failed to register with SIP Server (subscriber may have expired or banned from server or network issue)

                // A new inbound or outbound session (audio or video).  You can answer (incoming session only) or terminate (incoming or outgoing) these sessions.
                self.ua.on('newRTCSession', (e) => {
                    self.session = e.session;

                    // Set audio streams on the session
                    __setRemoteAudioStream(self.session);

                    // Setup ice candidates for peer connections
                    __onIceCandidate(self.session);

                    // Notify the parent controller that a new session started (inbound or outbound call)
                    __notify(self.Events.SESSION_CREATED, e.session);
                });
            }
            catch(e) {
                log(e);
            }
        }

        function isConnected() {
            return self.ua && self.ua.isConnected();
        }

        function connect(subscriber, eventHandler) {

            if (!isConnected()) {
                return __connect(subscriber, eventHandler);
            }

            return true;
        }

        function start() {

            // Connects to the signaling server and restores the previous state if previously stopped.
            // The UA will automatically register with the signaling server and start listening for inbound sessions.
            // You do not need to call this function unless you manually call stop().

            let result = false;

            if (self.ua) {
                try {
                    self.ua.start();
                    result = true;
                }
                catch(e) {
                    console.log(e);
                }
            }

            return result;
        }

        function stop() {

            // Saves the current registration state and disconnects from the signaling server after gracefully unregistering and terminating active sessions if any.

            let result = false;

            if (self.ua) {
                try {
                    self.ua.stop();
                    result = true;
                }
                catch(e) {
                    console.log(e);
                }
            }

            return result;
        }

        function answer(session, options) {
            let result = false;

            if (session) {
                try {
                    session.answer();
                    result = true;
                }
                catch(e) {
                    console.log(e);
                }
            }

            return result;
        }

        function mute(session, options) {
            let result = false;

            if (session) {
                try {
                    session.mute();
                    result = true;
                }
                catch(e) {
                    console.log(e);
                }
            }

            return result;
        }

        function unmute(session, options) {
            let result = false;

            if (session) {
                try {
                    session.unmute();
                    result = true;
                }
                catch(e) {
                    console.log(e);
                }
            }

            return result;
        }

        function terminate(session, hangupCause, options) {
            let result = false;

            if (session) {
                try {
                    let cause = hangupCause || self.HangupCause.NORMAL_CLEARING;
                    let opt = {
                        status_code: self.HangupCauseCode[cause] || self.HangupCauseCode[self.HangupCause.NORMAL_CLEARING],
                        reason_phrase: cause
                    };
                    session.terminate(opt);
                    result = true;
                }
                catch(e) {
                    console.log(e);
                }
            }

            return result;
        }

        function terminateAll(hangupCause, options) {
            let result = false;

            if (self.ua) {
                try {
                    let cause = hangupCause || self.HangupCause.NORMAL_CLEARING;
                    let opt = {
                        status_code: self.HangupCauseCode[cause] || self.HangupCauseCode[self.HangupCause.NORMAL_CLEARING],
                        reason_phrase: cause
                    };
                    if (cause !== self.HangupCause.NORMAL_CLEARING) {
                        self.ua.terminateSessions(opt);
                    } else {
                        self.ua.terminateSessions();
                    }
                    // self.ua.terminateAll(opt);
                    result = true;
                }
                catch(e) {
                    console.log(e);
                }
            }

            return result;
        }

        function isInProgress(session) {
            return session && session.isInProgress();
        }

        function isEstablished(session) {
            return session && session.isEstablished();
        }

        function isEnded(session) {
            return session && session.isEnded();
        }

        function isMuted(session) {
            return session && session.isMuted();
        }

        function canDial() {
            return self.can_dial && !self.session;
        }

        function dial(uri, options) {

            let result = false;

            if (canDial()) {
                try {
                    let opt = options || {};

                    let eventHandlers = {
                        connecting: (e) => __notify(self.Events.SESSION_DIALING, e), // Preparing to send SIP INVITE (dialing)
                        progress: (e) => __notify(self.Events.SESSION_PROGRESS, e), // Received 1xx SIP MESSAGE (ringing)
                        confirmed: (e) => __notify(self.Events.SESSION_ANSWERED, e), // Received ACK to 200 SIP MESSAGE (answered)
                        ended: (e) => {
                            self.session = null;
                            disconnect();

                            self.can_dial = true;
                            __notify(self.Events.SESSION_HANGUP, e);
                        }, // Received BYE SIP MESSAGE (hangup)
                        failed: (e) => {
                            self.session = null;
                            disconnect();

                            self.can_dial = true;
                            __notify(self.Events.SESSION_FAILED, e) // Received SIP Error (call rejected / unable to complete call)
                        }
                    };

                    let callOptions = {
                        eventHandlers: eventHandlers,
                        extraHeaders: opt.extra_headers || [],
                        mediaConstraints: { audio: opt.enable_audio, video: opt.enable_video },
                        pcConfig: {
                            rtcpMuxPolicy: 'negotiate',
                            iceServers: [ { 'urls':  self.stunServers } ]
                        }
                    };

                    // Dial the call
                    self.can_dial = false;
                    self.ua.call(uri, callOptions);
                    result = true;
                }
                catch(e) {
                    self.can_dial = true;
                    log(e);
                }
            }

            return result;
        }

        function disconnect() {
            // For now this is an alias of stop().  JsSip will manage the lifespan of the underlying socket.
            return stop();
        }

        return {
            connect: connect, // Connect to sip server via web socket
            disconnect: disconnect, // Disconnect from server
            start: start,  // Register with sip server and start listening for inbound calls
            stop: stop, // Deregister with sip server and stop listening for inbound calls
            dial: dial, // Make an outbound call
            answer: answer, // Answer an inbound session (call)
            mute: mute, // Mute session
            unmute: unmute, // Unmute session
            terminate: terminate, // Hangup session
            terminateAll: terminateAll, // Hangup all sessions
            isInProgress: isInProgress,
            isEstablished: isEstablished,
            isEnded: isEnded,
            isMuted: isMuted,
            HangupCause: self.HangupCause,
            Events: self.Events
        };
    }
    module.exports = JsSipService;
})();


