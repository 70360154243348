(function() {
    'use strict';

    function ActivityDashController($rootScope, $scope, $location, $interval, $window, authService, phoneService, userService, contactService) {

        $rootScope.viewData = $rootScope.viewData || {};
        $rootScope.viewData.breadcrumbs = $rootScope.viewData.breadcrumbs || [];
        $rootScope.viewData.breadcrumbs = [{
            title: 'Agent Activity',
            link_name: 'Agent Activity',
            link_url: '#!activity',
            help_url: $rootScope.help_url
        }];

        $scope.staticData = {
            statistic: ['Agents Online', 'Talking to Contacts', 'Working Inbox', 'Agents Offline'],
            agentActivity: {
                title: 'Agent Activity',
                subtitle: 'View your user\'s activity...',
                sortByFilter: {
                    label: 'Sort By ',
                    filters: [
                        { key: 'first_name', name: 'First Name' },                  // user
                        { key: 'last_name', name: 'Last Name' },                    // user
                        { key: 'status', name: 'Status' },                          // user
                        { key: 'first_seen', name: 'First Seen' },                  // statistic
                        { key: 'last_seen', name: 'Last Seen' },                    // statistic
                        { key: 'routing_location', name: 'Location' },              // user
                        { key: 'calls', name: 'Call Count' },                       //
                        { key: 'sms', name: 'SMS Count' },                          // statistic
                        { key: 'email', name: 'Email Count' },                      // statistic
                        { key: 'chat', name: 'Chat Count' },                        // -
                        { key: 'call_duration', name: 'Talk Time' },                // statistic
                        { key: 'wrap_up_time', name: 'Wrap Up Time' },              // statistic
                        { key: 'activity_level', name: 'Activity Level' },          // statistic
                        { key: 'conversions', name: 'Conversions' },                // statistic
                        { key: 'task_opened', name: 'Tasks Opened' },               // statistic
                        { key: 'task_closed', name: 'Tasks Closed' },               // statistic
                        { key: 'task_snoozed', name: 'Tasks Snoozed' },             // statistic
                        { key: 'task_participated', name: 'Tasks Participated' },   // statistic
                    ]
                },
                list: {
                    titles: [ 'Status', 'Last Seen', 'Talking To' ],
                    agentStatus: [ 'Dialing', 'Talking', 'Wrap Up', 'Offline' ],
                    never: 'Never',
                    unset: 'Unset',
                    agentActions: [ 'Export Activity Log', 'Open Conversation', 'Eavesdrop' ],
                    detailsTitle: [
                        'First Seen:', 'Last Seen:', 'Location:', 'Do Not Disturb:',
                        'Calls:', 'SMS:', 'Emails:', 'Chats:',
                        'Talk Time:', 'Wrap Up Time:', 'Activity Level:', 'Conversions:',
                        'Tasks Open:', 'Tasks Closed:', 'Tasks Snoozed:', 'Tasks Participated:'
                    ]
                },
                exportAgentActivity: 'Export'
            },
            loading: 'Loading',
            noRes: 'No Results'
        };
        $scope.defaultAvatar = $rootScope.settings.NO_AVATAR_IMG;

        $scope.data = {
            statistic: {},
            agentActivity: [],
            userGroups: [],
            filters: {
                sortBy: $scope.staticData.agentActivity.sortByFilter.filters[1],
                userGroup: { id: null, name: '' },
                minTs: null,
                maxTs: null
            }
        };

        $scope.chosenDatePickerOption = undefined;
        $scope.ranges = {
            'Today': { start: moment().startOf('day'), end: moment() },
            'Yesterday': { start: moment().subtract(1, 'days').startOf('day'), end: moment().subtract(1, 'days').startOf('day') },
            'Last 7 Days': { start: moment().subtract(6, 'days').startOf('day'), end: moment().endOf('day') },
            // 'All Time': { start: moment().startOf('day'), end: moment() },
        };

        $scope.loading = {
            globalStatistics: true,
            agentActivityFirstLoad: true,
            agentActivity: false,
            userGroups: true,
        };

        $scope.isShowOpenConversation = function(user) {
            return (user.last_user_timeline_event && user.last_user_timeline_event.event_data &&
                user.last_user_timeline_event.event_data.page_title === 'Inbox' &&
                user.last_user_timeline_event.event_data.page_data && user.last_user_timeline_event.event_data.page_data.task_id);
        };

        $scope.isShowEavesdrop = function(user) {
            // return (user.last_user_timeline_event && user.last_user_timeline_event.event_type === 'agent.talking') || (user.agent_state && user.agent_state.event_type === 'agent.talking');
            return false;
        };

        $scope.isShowExportActivityLog = function(user) {
            return false;
        };

        $scope.formatDateFromNow = function (timestamp) {
            moment.updateLocale('en', {
                relativeTime: {
                    future: 'in %s',
                    past:   '%s ago',
                    s  : 'a few seconds',
                    ss : '%d seconds',
                    m:  'a minute',
                    mm: '%d minutes',
                    h:  'an hour',
                    hh: '%d hours',
                    d:  'a day',
                    dd: '%d days',
                    w:  'a week',
                    ww: '%d weeks',
                    M:  'a month',
                    MM: '%d months',
                    y:  'a year',
                    yy: '%d years'
                }
            });
            return moment(timestamp).fromNow();
        };

        $scope.formatTimeStr = function(seconds) {
            var duration = moment.duration(seconds, 'seconds');
            var asSeconds = duration.asSeconds().toFixed(0);
            var asMinutes = duration.asMinutes().toFixed(0);
            var asHours = duration.asHours().toFixed(0);
            var asDays = duration.asDays().toFixed(0);

            switch(true) {
                case (duration.days() > 0):
                    return asDays + (asDays > 1 ? ' days' : ' day');
                case (duration.hours() > 0):
                    return asHours + (asHours > 1 ? ' hours' : ' hour');
                case (duration.minutes() > 0):
                    return asMinutes + (asMinutes > 1 ? ' minutes' : ' minute');
                case (duration.seconds() > 0):
                    return asSeconds + (asSeconds > 1 ? ' seconds' : ' second');
                default: return 0;
            }
        };

        $scope.eavesdrop = function(user) {
            // not implemented yet
        };

        $scope.openConversationInbox = function(user) {
            const pageData = user.last_user_timeline_event.event_data.page_data;
            if (pageData && pageData.task_id) {
                $window.location.href = `#/inbox?task_id=${pageData.task_id}`;
            }
        };

        $scope.exportUserActivity = function(user) {
            // We will implement the export once the user_timeline collection is complete
        };

        $scope.viewContactDetails = function(contactId) {
            if (contactId && contactId !== null) {
                $location.path('/contacts/detail').search('id=' + contactId);
            }
        };

        $scope.setSortByFilter = function(filter) {
            $scope.data.filters.sortBy = filter;
            $scope.loadAgentStatistics('agentActivity');
        };

        $scope.setUserGroupFilter = function (userGroup) {
            $scope.data.filters.userGroup.id = userGroup ? userGroup.user_group_id : null;
            $scope.data.filters.userGroup.name = userGroup ? userGroup.name : '';
            $scope.loadAgentStatistics('agentActivity');
        };

        $scope.exportUsersToCSV = function() {
            const preparedJSON = $scope.data.agentActivity
                .map(u => {
                    let stringifiedUser = {};
                    Object.keys(u).forEach(key => {
                        stringifiedUser[key] = JSON.stringify(u[key]);
                    });
                    return stringifiedUser;
                });
            const csv = Papa.unparse(preparedJSON);
            const blob = new Blob([csv], {type: "text/csv;charset=utf-8"});
            saveAs(blob, "agents_activity.csv");
        };

        $scope.getContactName = function(user) {
            var teamId = authService.getTeamId();
            var userId = authService.getUserId();

            if(user.agent_state && user.agent_state.event_data && user.agent_state.event_data.contact_id &&
                teamId && teamId !== null && userId && userId !== null) {
                const request = {
                    team_id: teamId,
                    user_id: userId,
                    contact_id: user.agent_state.event_data.contact_id
                };

                contactService.getContactDetails(request)
                    .then(result => {
                        if (result.status !== 200) {
                            return;
                        }
                        var displayContact = (!result.contact.first_name && !result.contact.last_name) ? result.contact.main_phone : (result.contact.first_name || '') + ' ' + (result.contact.last_name || '');
                        user.agent_state.event_data.contact_name = displayContact;
                        $scope.$apply();
                    });
            }
        };

        $scope.loadGlobalStatistics = function() {
            var teamId = authService.getTeamId();
            var userId = authService.getUserId();

            if(teamId && teamId !== null && userId && userId !== null) {
                $scope.loading.globalStatistics = true;

                const request = {
                    team_id: teamId,
                    user_id: userId,
                    user_group_ids: [ $scope.data.filters.userGroup.id ]
                };

                userService.getGlobalStatistics(request)
                    .then(statistics => {
                        $scope.data.statistic = statistics;
                    })
                    .catch(err => { console.error(err); })
                    .then(() => {
                        $scope.loading.globalStatistics = false;
                        $scope.$apply();
                    });
            }
        };

        $scope.scrollTopList = function() {
            $('.agent-list-scroll').stop();
            $('.agent-list-scroll').animate({scrollTop: 0}, 350);
        };

        $scope.loadAgentStatistics = function(loader) {
            $scope.loading[loader] = true;
            $scope.scrollTopList();

            const request = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                minTs: $scope.data.filters.minTs,
                maxTs: $scope.data.filters.maxTs,
                user_group_ids: [ $scope.data.filters.userGroup.id ],
                sort_by: $scope.data.filters.sortBy.key,
                sort_dir: 'asc'
            };

            userService.getUsersStatistics(request)
                .then(statistics => {
                    _.forEach(statistics.users, function(user) {
                        $scope.getContactName(user);
                    });

                    $scope.data.agentActivity = statistics.users;
                })
                .catch(err => {
                    console.log(err);
                })
                .then(() => {
                    $scope.loading[loader] = false;
                    $scope.$apply();
                });
        };

        $scope.loadUserGroups = function() {
            $scope.loading.userGroups = true;

            const requestUserGroups = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                sort: 'asc',
            };

            userService.listUserGroups(requestUserGroups)
                .then(results => {
                    $scope.data.userGroups = results.teams;
                    $scope.loading.userGroups = false;
                    $scope.$apply();
                });
        };

        $('#reportrange').on('apply.daterangepicker', function(ev, picker) {
            var currentLabel = picker.chosenLabel;

            if($scope.chosenDatePickerOption && ($scope.chosenDatePickerOption !== currentLabel || currentLabel === 'Custom Range')) {
                var minTs = (currentLabel === 'All Time') ? null : picker.startDate._d.getTime();
                var maxTs = (currentLabel === 'All Time') ? null : picker.endDate._d.getTime();
                $scope.updateDateRangeInput(picker.startDate, picker.endDate, currentLabel, minTs, maxTs, ()=> {
                    $scope.loadAgentStatistics('agentActivity');
                });
            }
            $('#reportrange').data('daterangepicker').hideCalendars();
        });

        $('#reportrange').on('showCalendar.daterangepicker', function(ev, picker) {
            if($('.daterangepicker .ranges ul li[data-range-key="All Time"]').hasClass('active') &&
                $scope.chosenDatePickerOption === 'All Time' &&
                $('.daterangepicker').hasClass('show-calendar')) {

                $('.daterangepicker .ranges ul li[data-range-key="All Time"]').removeClass('active');
                $('.daterangepicker .ranges ul li[data-range-key="Today"]').addClass('active');
            }
        });

        $('#reportrange').on('show.daterangepicker', function(ev, picker) {
            $('#reportrange').data('daterangepicker').maxDate = moment();
            $('#reportrange').data('daterangepicker').updateCalendars();

            if($('.daterangepicker .ranges ul li[data-range-key="Today"]').hasClass('active') &&
                $scope.chosenDatePickerOption === 'All Time' &&
                !$('.daterangepicker').hasClass('show-calendar')) {

                $('.daterangepicker .ranges ul li[data-range-key="Today"]').removeClass('active');
                $('.daterangepicker .ranges ul li[data-range-key="All Time"]').addClass('active');
            }

            $('#reportrange i').addClass('main-blue-color-important');
            $('#reportrange span').addClass('main-blue-color-important');
        });

        $('#reportrange').on('hide.daterangepicker', function(ev, picker) {
            $('#reportrange i').removeClass('main-blue-color-important');
            $('#reportrange span').removeClass('main-blue-color-important');
        });

        $scope.updateDateRangeInput = function(start, end, currentLabel, minTs, maxTs, callback) {
            $scope.chosenDatePickerOption = currentLabel;

            var startFormat = start.format('MMM D, YYYY');
            var endFormat = end.format('MMM D, YYYY');
            var htmlText = (currentLabel === 'All Time') ? 'All Time' : startFormat + ' - ' + endFormat;
            $('#reportrange span').html(htmlText);

            $scope.data.filters.minTs = minTs;
            $scope.data.filters.maxTs = maxTs;

            callback();
        };

        $scope.initDateRange = function(_label, callback) {
            var initLabel = _.has($scope.ranges, _label) ? _label : 'Today';
            var start = $scope.ranges[initLabel].start;
            var end = $scope.ranges[initLabel].end;
            var minTs = (initLabel === 'All Time') ? null : start.valueOf();
            var maxTs = (initLabel === 'All Time') ? null : end.valueOf();

            $('#reportrange').daterangepicker({
                maxDate: moment(),
                startDate: start,
                endDate: end,
                opens: 'left',
                ranges: {
                    'Today': [$scope.ranges['Today'].start, $scope.ranges['Today'].end],
                    'Yesterday': [$scope.ranges['Yesterday'].start, $scope.ranges['Yesterday'].end],
                    'Last 7 Days': [$scope.ranges['Last 7 Days'].start, $scope.ranges['Last 7 Days'].end],
                    // 'All Time': [$scope.ranges['All Time'].start, $scope.ranges['All Time'].end],
                }
            });

            $scope.updateDateRangeInput($scope.ranges[initLabel].start, $scope.ranges[initLabel].end, initLabel, minTs, maxTs, ()=> {
                callback();
            });
        }

        $scope.__updateSingleUserStatistics = function(search_user_id) {
            var userId = authService.getUserId();
            var teamId = authService.getTeamId();

            if(userId && userId !== null && teamId && teamId !== null) {
                const request = {
                    search_user_id: search_user_id,
                    user_id: userId,
                    team_id: teamId,
                    minTs: $scope.data.filters.minTs,
                    maxTs: $scope.data.filters.maxTs,
                };

                userService.getUsersStatistics(request)
                    .then(statistics => {
                        if (!statistics.users.length || !$scope.data.agentActivity.length) {
                            return;
                        }

                        _.forEach(statistics.users, function(user) {
                            var userIndex = _.findIndex($scope.data.agentActivity, function(u) { return u.user_id === user.user_id; });
                            if(userIndex !== -1) {
                                $scope.data.agentActivity[userIndex] = user;
                                $scope.getContactName($scope.data.agentActivity[userIndex]);
                            }
                        });
                        $scope.$apply();
                    })
                    .catch(err => {
                        console.error(err);
                    });
            }
        }

        $scope.onInit = function() {
            $scope.initDateRange('Today', ()=> {
                $scope.loadAgentStatistics('agentActivityFirstLoad');
            });

            $scope.loadUserGroups();
            $scope.loadGlobalStatistics();
        };

        if (authService.hasAccount()) {
            $scope.onInit();
        }
        else {
            $scope.$on('auth_complete', function(event, args) {
                $scope.onInit();
            });
        }

        $scope.$on('hiddenRightSideBar', function () {
            $('.user-details').removeClass('right-side-bar-shown');
        });
        $scope.$on('shownRightSideBar', function () {
            $('.user-details').addClass('right-side-bar-shown');
        });

        $scope.$on('user.login', function(event, args) {
            $scope.__updateSingleUserStatistics(args.data.user_id);

            $scope.data.statistic.offline--;
            $scope.data.statistic.online++;
            $scope.$apply();
        });

        $scope.$on('user.logout', function(event, args) {
            $scope.__updateSingleUserStatistics(args.data.user_id);

            $scope.data.statistic.offline++;
            $scope.data.statistic.online--;
            $scope.$apply();
        });

        $scope.$on('app.page.view', function(event, args) {
            $scope.__updateSingleUserStatistics(args.data.user_id);
            // to check changes in inbox section
            $scope.loadGlobalStatistics();
        });

        $scope.$on('user.created', function(event, args) {
            $scope.__updateSingleUserStatistics(args.data.user_id);
            // to check changes in inbox section
            $scope.loadGlobalStatistics();
        });

        $scope.$on('user.deleted', function(event, args) {
            $scope.__updateSingleUserStatistics(args.data.user_id);
            // to check changes in inbox section
            $scope.loadGlobalStatistics();
        });

        $scope.$on('agent.dialing', function(event, args) {
            $scope.__updateSingleUserStatistics(args.data.user_id);
        });

        $scope.$on('agent.talking', function(event, args) {
            $scope.__updateSingleUserStatistics(args.data.user_id);
            // to check changes in talking section
            $scope.loadGlobalStatistics();
        });

        $scope.$on('agent.hangup', function(event, args) {
            $scope.__updateSingleUserStatistics(args.data.user_id);
            // to check changes in talking section
            $scope.loadGlobalStatistics();
        });

        $scope.$on('agent.wrapup', function(event, args) {
            $scope.__updateSingleUserStatistics(args.data.user_id);
        });
    }
    module.exports = ActivityDashController;
})();
