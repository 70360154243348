(function () {
    'use strict';

    function ConversationController(
        $q, $rootScope, $scope, $location, $window, $attrs,
        tasksService, authService, contactService, userService,
        phoneService, callService, dispatcherService,
        notificationToastService,
        emailService, faxService, templateService, storeSelectedContactService) {

        var vm = this;
        $scope.defaultAvatar = $rootScope.settings.NO_AVATAR_IMG;
        $scope.page = null;
        $scope.staticData = {
            noRes: 'No Results',
            loadingMessages: {
                removing: 'Removing',
                processing: 'Processing',
                sending: 'Sending',
                saving: 'Saving'
            },
            loading: 'Loading',
            view: {
                mapBtn: 'Map',
                openContact: 'Open Contact',
                assignedToSelect: {
                    none: 'Unassigned'
                },
                phoneActions: {
                    dial: 'Dial',
                    answer: 'Answer',
                    hangup: 'Hangup',
                    decline: 'Decline'
                },
                actions: {
                    markPriority: 'Mark as Priority',
                    unmarkPriority: 'Unmark as Priority',
                    snoozed: 'Snooze',
                    reopen: 'Reopen',
                    close: 'Close',
                },
                emptyTimeline: {
                    icon: 'fa-light fa-list-timeline',
                    message: 'Timeline is Empty'
                },
                timeline: {
                    types: {
                        note: {icon: 'fa-light fa-sticky-note', title: 'Note'},
                        sms: {iconIn: 'fa-light fa-message-arrow-down', iconOut: 'fa-light fa-message-arrow-up', title: 'Text Message'},
                        voicemail: {icon: 'fa-light fa-voicemail', title: 'Voicemail'},
                        fax: {icon: 'fa-light fa-fax', title: 'Fax'},
                        email: {icon: 'fa-light fa-envelope', title: 'Email'},
                        inboundCall: {icon: 'fa-light fa-phone-arrow-down-left', title: 'Inbound Call'},
                        outboundCall: {icon: 'fa-light fa-phone-arrow-up-right', title: 'Outbound Call'},
                        missedCall: {icon: 'fa-light fa-phone-missed', title: 'Missed Call'},
                        suggestedScripts: {icon: 'fa-light fa-scroll-old', title: 'Suggested Scripts'}
                    },
                    infoDivider: '·',
                    noteMenu: [
                        {icon: 'fa-light fa-trash', title: 'Delete'},
                        {icon: 'fa-light fa-thumbtack', title: 'Pin Note'},
                        {icon: 'fa-solid fa-thumbtack', title: 'Unpin Note'}
                    ],
                    callMenu: [
                        {icon: 'fa-light fa-user-tag', title: 'Change Disposition'}
                    ]
                },
                inboundCalls: {
                    talkingTo: 'Talking to'
                },
                footer: {
                    suggested: {
                        email: {
                            title: 'Suggested Email',
                            defaultName: 'Email Template',
                            actionBtn: 'Apply'
                        }
                    }
                }
            },
            available_task_statuses: {
                open: 'open',
                snoozed: 'snoozed',
                closed: 'closed'
            },
            timeline_types: [
                'note',
                'call',
                'sms',
                'rvm',
                'fax',
                'external_disposition',
                'external',
                'tag_added',
                'tag_removed',
                'document',
                'disposition_changed',
                'email',
            ],
        };
        $scope.tasksTypes = {
            inbound_call: 'Inbound Call',
            missed_call: 'Missed Call',
            fax: 'Fax',
            sms: 'Inbound SMS',
            voicemail: 'Voicemail',
            email: 'Email',
            note: 'Note'
        };
        $scope.data = {
            availableDispositions: {},
            selectedTask: null,
            selectedTaskTimelineItems: [],
            selectedTaskTimelineItemsTotal: 0,
            teamsUsers: [],
            templates: {
                email: [],
                script: []
            },
            currentSuggestionPreview: null,
            user: null,

            timelineItemToAnswerEmail: undefined,
            timelineItemToAnswerSms: undefined
        };

        $scope.userId = authService.getUserId();
        $scope.availableAssignments = [
            {key: 'you', title: 'You', icon: null},
            {key: 'yourteams', title: 'Your Teams', icon: 'fa-light fa-users-rectangle'},
            {key: 'mentions', title: 'Mentions', icon: 'fa-light fa-at'},
            {key: 'unassigned', title: 'Unassigned', icon: 'fa-light fa-user'},
            {key: 'all', title: 'All', icon: 'fa-light fa-user-group'}
        ];
        $scope.availableStatuses = [
            {key: 'open', title: 'Open', icon: 'fa-light fa-inbox'},
            {key: 'snoozed', title: 'Snoozed', icon: 'fa-light fa-clock'},
            {key: 'closed', title: 'Closed', icon: 'fa-light fa-check'},
        ];
        $scope.availableSorts = [
            {key: 'asc', title: 'Oldest'},
            {key: 'desc', title: 'Newest'},
            {key: 'urgent', title: 'Priority First'}
        ];

        $scope.loading = {
            timelineFirstLoad: false,
            timeline: false,
            usersTeams: false,
            updateTaskUrgentStatus: false,
            changeTaskStatus: {
                open: false,
                snoozed: false,
                closed: false
            },
            changeTaskAssignment: false
        };

        $scope.currentOpenedPlayerIdVoicemail = -1;
        $scope.currentOpenedPlayerIdCall = -1;

        $scope.formatPhone = function(value) {
            return $rootScope.libPhoneFormatPhone(value);
        };

        $scope.formatSeconds = function (totalSeconds) {
            if(totalSeconds < 0){
                return 'now';
            }

            totalSeconds = parseInt(totalSeconds || 0);
            let hours   = Math.floor(totalSeconds / 3600);
            let minutes = Math.floor((totalSeconds - (hours * 3600)) / 60);
            let seconds = totalSeconds - (hours * 3600) - (minutes * 60);

            if (hours   < 10 && hours > 0) {hours   = "0"+hours;}
            if (minutes < 10) {minutes = "0"+minutes;}
            if (seconds < 10) {seconds = "0"+seconds;}

            let total = minutes+':'+seconds;
            if(hours > 0){
                total = hours+':'+total;
            }
            return total;
        };

        $scope.getDuration = function (taskItem) {
            const diff = moment().unix() - moment(taskItem.answered_at).unix();
            return Number(moment.utc(diff)) || 0;
        };

        $scope.formatDateFromNow = function (timestamp) {
            moment.updateLocale('en', {
                relativeTime: {
                    future: 'in %s',
                    past: '%s',
                    s: '1s',
                    ss: '%ds',
                    m: '1m',
                    mm: '%dm',
                    h: '1h',
                    hh: '%dh',
                    d: '1d',
                    dd: '%dd',
                    M: '1mth',
                    MM: '%dmth',
                    y: '1y',
                    yy: '%dy'
                }
            });
            return moment(timestamp).fromNow();
        };

        $scope.formatDateFromNowAgo = function (timestamp) {
            moment.updateLocale('en', {
                relativeTime: {
                    future: 'in %s',
                    past:   '%s ago',
                    s  : 'a few seconds',
                    ss : '%d seconds',
                    m:  'a minute',
                    mm: '%d minutes',
                    h:  'an hour',
                    hh: '%d hours',
                    d:  'a day',
                    dd: '%d days',
                    w:  'a week',
                    ww: '%d weeks',
                    M:  'a month',
                    MM: '%d months',
                    y:  'a year',
                    yy: '%d years'
                }
            });
            return moment(timestamp).fromNow();
        };

        $scope.formatDate = function(timestamp) {
            var width = (window.innerWidth > 0) ? window.innerWidth : this.screen.width;
            var functionName = (width > 480) ? 'formatDateFromNowAgo' : 'formatDateFromNow';
            return $scope[functionName](timestamp);
        };

        $scope.formatBytes = function(bytes, decimals = 2) {
            if (!bytes) {
                return '0 B';
            }

            const k = 1024;
            const dm = decimals < 0 ? 0 : decimals;
            const sizes = ['B', 'KB', 'MB'];

            const i = Math.floor(Math.log(bytes) / Math.log(k));

            return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
        };

        $scope.returnTimelineItemTypeClass = function(item) {
            switch(item.type) {
                case 'inbound_call':
                    return 'call';
                case 'outbound_call':
                    return 'call';
                case 'missed_call':
                    return 'call';
                case 'system_note':
                    return 'system-note';
                default:
                    return item.type;
            }
        };

        $scope.selectEmailAttachment = function(item, attachment) {
            item.emailSelectedAttachment = attachment;
            $scope.closePopovers();
        };

        $scope.downloadEmailDocument = function(item) {
            var attachmentId = (item.emailSelectedAttachment) ? item.emailSelectedAttachment.attachment_id : item.attachments[0].attachment_id;
            var entryId = item.entry_id;
            if(!entryId) {
                return;
            }

            item.downloadingDocument = true;
            const request = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                task_id: $scope.data.selectedTask.task_id,
                entry_id: entryId,
                attachment_id: attachmentId
            };

            tasksService.getTaskAttachmentUrl(request)
                .then(result => {
                    if (!result || !result.url) { throw new Error(); }
                    item.downloadingDocument = false;
                    window.open(result.url, '_blank');
                    $scope.$apply();
                })
                .catch(err => {
                    console.log(err);
                    item.downloadingDocument = false;
                    $scope.$apply();
                    notificationToastService.showErrorToast('fa-light fa-triangle-exclamation', 'We were unable to download file. Try again later.');
                });
        };

        $scope.downloadFaxDocument = function(item) {
            item.downloadingDocument = true;
            const request = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                document_id: item.document_id
            };

            faxService.getDocumentUrl(request)
                .then(result => {
                    if (!result || !result.url) { throw new Error(); }
                    item.downloadingDocument = false;
                    window.open(result.url, '_blank');
                    $scope.$apply();
                })
                .catch(err => {
                    console.log(err);
                    item.downloadingDocument = false;
                    $scope.$apply();
                    notificationToastService.showErrorToast('fa-light fa-triangle-exclamation', 'We were unable to download file. Try again later.');
                });
        };

        $scope.appendPropertyArrayObjects = function (arrObj, property) {
            return _.map(arrObj, function (item) {
                return _.extend({}, item, property);
            });
        };

        $scope.appendNameUsers = function (arrObj) {
            return _.map(arrObj, function (item) {
                return _.extend({}, item, {name: item.identity.first_name + ' ' + item.identity.last_name});
            });
        };

        $scope.removeItemUsers = function (arrObj, userId) {
            return _.remove(arrObj, function (user) {
                return user.user_id === userId;
            });
        };

        $scope.showSuggestionScript = function(script) {
            var mergedHtml = $scope._mergeTemplateAttributes(script.html_text, $scope.data.selectedTask.contact, $rootScope.user);
            var suggestionsScriptContentElement = $('#suggestions-script-full-' + script.template_id + ' .suggestions-script-content');
            suggestionsScriptContentElement.html(mergedHtml);
        };

        $scope.suggestionPreview = function(template) {
            $scope.data.currentSuggestionPreview = template;
            $scope.data.currentSuggestionPreview.loading = true;
            $scope.data.currentSuggestionPreview.mergedText = $scope._mergeTemplateAttributes(template.text, $scope.data.selectedTask.contact, $rootScope.user);
            $scope.data.currentSuggestionPreview.mergedHtml = $scope._mergeTemplateAttributes(template.html_text, $scope.data.selectedTask.contact, $rootScope.user);
            var suggestionPreviewModal = $('#suggestionPreviewModal');

            suggestionPreviewModal.on('shown.bs.modal', function (e) {
                e.preventDefault();
                var templatePreviewElem = $(this).find('.template-preview');
                templatePreviewElem.html($scope.data.currentSuggestionPreview.mergedHtml);
                setTimeout(function() {
                    var height = templatePreviewElem.height();
                    templatePreviewElem.parent().css('height', height + 'px');
                    $scope.data.currentSuggestionPreview.loading = false;
                    $scope.$apply();
                });
            });
            suggestionPreviewModal.on('hidden.bs.modal', function (e) {
                e.preventDefault();
                $scope.data.currentSuggestionPreview = null;
                var templatePreviewElem = $(this).find('.template-preview');
                var minHeight = parseInt(templatePreviewElem.parent().css('min-height'),10);
                templatePreviewElem.html('');
                templatePreviewElem.parent().css('height', minHeight + 'px');
            });
            suggestionPreviewModal.modal('show');
        };

        $scope.initSuggestionsHoverEvent = function() {
            $('.suggestion-card').on({
                mouseenter: function () {
                    var currentElem = $(this);
                    var minHeight = parseInt(currentElem.css('min-height'),10);
                    var height = currentElem.children('.suggestion-card-content-total').height();
                    var marginTop = height - minHeight;

                    currentElem.css('margin-top', -marginTop + 'px');
                    currentElem.css('height', height + 'px');
                },
                mouseleave: function () {
                    var currentElem = $(this);
                    var minHeight = parseInt(currentElem.css('min-height'),10);

                    currentElem.css('height', minHeight + 'px');
                    currentElem.css('margin-top', 0);
                }
            });
        };

        $scope.$on('initSuggestedEmailBlock', function() {
            $scope.initSuggestionsHoverEvent();
        });

        $scope.applyEmailTemplate = function(template) {
            $rootScope.$broadcast('applyTemplateTextArea', template);
            $('#suggestionPreviewModal').modal('hide');
        };

        $scope.loadTemplates = function() {

            const teamId = authService.getTeamId();
            const userId = authService.getUserId();

            const request = {
                team_id: teamId,
                user_id: userId
            };

            return templateService.getTemplates(request)
                .then(results => {
                    const templates = results.templates || [];
                    $scope.data.templates.email = templates.filter(item => item.type === 'email');
                    $scope.data.templates.script = templates.filter(item => item.type === 'script');
                    $scope.$apply();
                })
                .catch(err => {
                    console.error(err);
                    $scope.$apply();
                });
        };

        $scope._mergeTemplateAttributes = function(html_text, contact, user) {
            const contactAttributes = [
                'main_phone',
                'secondary_phone',
                'first_name',
                'last_name',
                'company',
                'email',
                'fax_number',
                'address',
                'suite/apt',
                'city',
                'state',
                'country',
                'zip_code',
                'reason'
            ];
            const userAttributes = [
                'username',
                'first_name',
                'last_name',
                'email',
                'home_phone',
                'office_phone',
                'mobile_phone'
            ];

            let returnHtmlText = html_text;

            contactAttributes.map(contactAttribute => {
                const value = contact && contact[contactAttribute] ? contact[contactAttribute] : '';

                returnHtmlText = returnHtmlText.replace(new RegExp(`{{contact.${contactAttribute}}}`, "g"), value);
                returnHtmlText = returnHtmlText.replace(new RegExp(`{{ contact.${contactAttribute}}}`, "g"), value);
                returnHtmlText = returnHtmlText.replace(new RegExp(`{{contact.${contactAttribute} }}`, "g"), value);
                returnHtmlText = returnHtmlText.replace(new RegExp(`{{ contact.${contactAttribute} }}`, "g"), value);
            });

            userAttributes.map(userAttribute => {
                const value = user && user[userAttribute] ? user[userAttribute] : '';

                returnHtmlText = returnHtmlText.replace(new RegExp(`{{user.${userAttribute}}}`, "g"), value);
                returnHtmlText = returnHtmlText.replace(new RegExp(`{{ user.${userAttribute}}}`, "g"), value);
                returnHtmlText = returnHtmlText.replace(new RegExp(`{{user.${userAttribute} }}`, "g"), value);
                returnHtmlText = returnHtmlText.replace(new RegExp(`{{ user.${userAttribute} }}`, "g"), value);
            });

            return returnHtmlText;
        };

        $scope.loadTeamsUsers = function () {
            var teamId = authService.getTeamId();
            var userId = authService.getUserId();

            const request = {
                team_id: teamId,
                user_id: userId
            };

            var teams = [];
            var users = [];
            var you = {};

            return userService.listUserGroups(request)
                .then(teamsResult => {
                    if (teamsResult.total_teams > 0) {
                        teams = $scope.appendPropertyArrayObjects(teamsResult.teams, {assign_type: 'team'});
                    }
                    userService.listUsers(request)
                        .then(usersResult => {
                            if (usersResult.total_users > 0) {
                                users = $scope.appendNameUsers(usersResult.users);
                                users = $scope.appendPropertyArrayObjects(users, {assign_type: 'user'});

                                you = $scope.removeItemUsers(users, $scope.userId)[0];
                                you.name = 'You';
                                you.assign_type = 'current_user';
                            }
                            $scope.data.teamsUsers = _.concat(teams, users);
                            $scope.data.teamsUsers.unshift(you);
                            $scope.data.teamsUsers.unshift({assign_type: null, name: 'None'});

                            $scope.$apply();
                        })
                        .catch(err => {
                            console.error(err);
                            $scope.$apply();
                        });
                })
                .catch(err => {
                    console.error(err);
                    $scope.$apply();
                });
        };

        $scope.assignToGroupBy = function (item) {
            switch (item.assign_type) {
                case 'team':
                    return 'Assign to Team';
                case 'user':
                    return 'Assign to Teammate';
                default:
                    return '';
            }
        };

        $scope.getAssignToName = function(task) {
            switch (task.assigned_type) {
                case 'user':
                case 'current_user':
                    return (task.assigned_to !== null) ? _.find($scope.data.teamsUsers, {user_id: task.assigned_to}).name : undefined;
                case 'team':
                case 'user_group':
                    return (task.assigned_to !== null) ? _.find($scope.data.teamsUsers, {user_group_id: task.assigned_to}).name : undefined;
                default:
                    return undefined;
            };
        };

        $scope.assignTo = function () {
            $scope.loading.changeTaskAssignment = true;
            document.getElementById('assignToSelect').classList.add('disabled');

            const request = {
                task_id: $scope.data.selectedTask.task_id,
            };
            switch ($scope.data.selectedTask.assigned_to.assign_type) {
                case null:
                    request.assigned_to = null;
                    request.assigned_type = null;
                    break;
                case 'user':
                case 'current_user':
                    request.assigned_to = $scope.data.selectedTask.assigned_to.user_id;
                    request.assigned_type = 'user';
                    break;
                case 'team':
                    request.assigned_to = $scope.data.selectedTask.assigned_to.user_group_id;
                    request.assigned_type = 'user_group';
            };

            tasksService.updateTask(request)
                .then(res => {
                    $scope.initAssignedSelectedTask(res);
                    var assignToName = $scope.getAssignToName(res);
                    var notificationMessage = (!assignToName) ? 'Task has been unassigned!' : 'Task has been assigned to ' + assignToName + '!';

                    document.getElementById('assignToSelect').classList.remove('disabled');
                    $rootScope.$broadcast('changeTaskAssignmentEvent', $scope.data.selectedTask);
                    $scope.loading.changeTaskAssignment = false;
                    $scope.$apply();
                    notificationToastService.showSuccessToast('fa-light fa-user-check', notificationMessage);
                })
                .catch(err => {
                    console.error(err);
                    $scope.data.selectedTask.assigned_to = ($scope.data.selectedTask.assignedToPrev) ? _.cloneDeep($scope.data.selectedTask.assignedToPrev) : $scope.data.selectedTask.assigned_to;
                    $scope.data.selectedTask.assigned_type = ($scope.data.selectedTask.assignedTypePrev) ? _.cloneDeep($scope.data.selectedTask.assignedTypePrev) : $scope.data.selectedTask.assigned_type;

                    document.getElementById('assignToSelect').classList.remove('disabled');
                    $scope.loading.changeTaskAssignment = false;
                    $scope.$apply();
                    notificationToastService.showErrorToast('fa-light fa-triangle-exclamation', 'We were unable to assign this task. Try again later.');
                });
        };

        $scope.initAssignedSelectedTask = function(task) {
            switch (task.assigned_type) {
                case null:
                    $scope.data.selectedTask.assigned_to = null;
                    $scope.data.selectedTask.assigned_type = null;
                    break;
                case 'user':
                case 'current_user':
                    const assignedUser = $scope.data.teamsUsers.find(teamUser => teamUser.user_id === task.assigned_to);
                    if (typeof assignedUser !== 'undefined') {
                        $scope.data.selectedTask.assigned_to = assignedUser;
                        $scope.data.selectedTask.assigned_type = (assignedUser.user_id === authService.getUserId()) ? 'current_user' : 'user';
                    }
                    break;
                case 'team':
                case 'user_group':
                    const assignedUserGroup = $scope.data.teamsUsers.find(teamUser => teamUser.user_group_id === task.assigned_to);
                    if (typeof assignedUserGroup !== 'undefined') {
                        $scope.data.selectedTask.assigned_to = assignedUserGroup;
                        $scope.data.selectedTask.assigned_type = 'team';
                    }
                    break;
            }
            $scope.data.selectedTask.assignedToPrev = _.cloneDeep($scope.data.selectedTask.assigned_to);
            $scope.data.selectedTask.assignedTypePrev = _.cloneDeep($scope.data.selectedTask.assigned_type);
        };

        $scope.setSelectedTask = function (task) {
            $scope.data.timelineItemToAnswerEmail = undefined;
            $scope.data.timelineItemToAnswerSms = undefined;
            $scope.data.selectedTask = task;
            if (!$scope.data.selectedTask) {
                $scope.data.selectedTaskTimelineItems = [];
                return;
            }
            $scope.initAssignedSelectedTask($scope.data.selectedTask);

            if ($scope.data.selectedTask) {
                $scope.data.selectedTaskTimelineItems = [];

                $scope.data.selectedTask.isNewItemAddedTimeline = false;
                $scope.getTimeline(0, 'timelineFirstLoad');
            }
        };

        $scope.getLastTimelineEntryByTypeDirection = function(type, direction) {
            return _.findLast($scope.data.selectedTaskTimelineItems, function(item) {
                return item.type === type && item.direction === direction;
            });
        };

        $scope.getTimeline = function(skip, loader) {
            $scope.data.selectedTask.loadingTimeline = true;

            $scope.loading[loader] = true;
            var skipItems = skip || 0;

            tasksService.getTaskTimeline({
                task_id: $scope.data.selectedTask.task_id,
                types: $scope.staticData.timeline_types,
                limit: 15,
                skip: skipItems
            }).then(result => {
                $scope.data.selectedTaskTimelineItems = _.concat(result.items.reverse(), $scope.data.selectedTaskTimelineItems);
                $scope.data.selectedTaskTimelineItemsTotal = result.total;

                if ($scope.data.selectedTask && ($scope.data.selectedTask.state === 'ringing' || $scope.data.selectedTask.state === 'answered')) {
                    const inboundCallItem = $scope.data.selectedTaskTimelineItems.find(item => item.type === 'inbound_call' && item.direction === 'inbound')
                    if (typeof inboundCallItem !== 'undefined') {
                        inboundCallItem.answered_user = $scope.data.selectedTask.answered_user;
                        inboundCallItem.answered_at = $scope.data.selectedTask.answered_at;
                        inboundCallItem.state = $scope.data.selectedTask.state;
                    }
                }

                if(loader === 'timelineFirstLoad') {
                    $scope.data.timelineItemToAnswerEmail = $scope.getLastTimelineEntryByTypeDirection('email', 'inbound');
                    $scope.data.timelineItemToAnswerSms = $scope.getLastTimelineEntryByTypeDirection('sms', 'inbound');
                }

                $scope.data.selectedTask.loadingTimeline = false;
                $scope.loading[loader] = false;
                $scope.$apply();
            });
        };

        $scope.loadMoreTimelineItems = function() {
            if(!$scope.loading.timeline) {
                var skip = $scope.data.selectedTaskTimelineItems.length;

                if(skip > 0 && skip < $scope.data.selectedTaskTimelineItemsTotal) {
                    $scope.getTimeline(skip, 'timeline');
                }
            }
        };

        $scope.infiniteScrollListener = function(scrollsDown) {
            $scope.closePopovers();
        };

        $scope.showDispositionList = function(item) {
            item.ddState.shownDispositions = true;
        };

        $scope.initTimelineItemPopover = function(popoverId, contentId, popoverDivId) {
            var templateStr = '<div class="popover inbox-timeline-item-popover-menu" id="' + popoverDivId + '" role="tooltip"><div class="popover-body"></div></div>';
            $('#' + popoverId).popover({
                container: 'body',
                placement: 'bottom',
                html: true,
                content: $('#' + contentId),
                template: templateStr,
                trigger: 'click'
            });
        };

        $scope.timelineItemPopoverInitListeners = function(popoverIdStr, contentIdStr, popoverDivIdStr, parentIdStr, item) {
            var popoverId = popoverIdStr + item.entry_id;
            var contentId = contentIdStr + item.entry_id;
            var popoverDivId = popoverDivIdStr + item.entry_id;
            var parentId = parentIdStr + item.entry_id;
            $scope.initTimelineItemPopover(popoverId, contentId, popoverDivId);

            $('#' + popoverId).on('hide.bs.popover', function() {
                $('#' + parentId).removeClass('show');
            });
            $('#' + popoverId).on('show.bs.popover', function() {
                $('#' + parentId).addClass('show');
            });
        };

        $scope.addEmailFilesPopoverTimelineItem = function(item) {
            $scope.timelineItemPopoverInitListeners('item-email-files-dropdown-', 'item-email-files-popover', 'email-files-popover-', 'item-email-files-', item);
        };

        $scope.addNotePopoverMenuTimelineItem = function(item) {
            $scope.timelineItemPopoverInitListeners('note-timeline-dropdown-', 'note-timeline-item-popover', 'note-item-popover-', 'note-timeline-item-menu-', item);
        };

        $scope.addCallPopoverMenuTimelineItem = function(item) {
            $scope.timelineItemPopoverInitListeners('call-timeline-dropdown-', 'call-timeline-item-popover', 'call-item-popover-', 'call-timeline-item-menu-', item);

            $('#call-timeline-dropdown-' + item.entry_id).on('hidden.bs.popover', function() {
                if(item.ddState) {
                    item.ddState.shownDispositions = false;
                }
            });
        };

        $scope.closePopovers = function() {
            $('.timeline-popover-btn').popover('hide');
        };

        $scope.timelineClosePopovers = function() {
            $('body').on('click', function (e) {
                var popoverBtn = $('.timeline-popover-btn');
                var popovers = $('.inbox-timeline-item-popover-menu');

                if(!popoverBtn.is(e.target) && popoverBtn.has(e.target).length === 0 && !popovers.is(e.target) && popovers.has(e.target).length === 0) {
                    popoverBtn.popover('hide');
                    if(popovers.hasClass('show')) {
                        popovers.remove();
                    }
                }
            });
            $(document).on('click', '.timeline-popover-btn', function () {
                $('.timeline-popover-btn').not(this).popover('hide');
            });
        };

        $scope.scrollsDownNewTimelineItem = function(newItem) {
            if(((newItem.type === 'sms' || newItem.type === 'fax' || newItem.type === 'missed_call' || newItem.type === 'email')
                && newItem.direction === 'inbound') || newItem.type === 'inbound_call') {
                return false;
            }
            return true;
        };

        $scope.renderedTimelineItemsListener = function() {
            if($scope.data.selectedTask.isNewItemAddedTimeline) {
                $scope.data.selectedTask.isNewItemAddedTimeline = false;
                var listLength = $scope.data.selectedTaskTimelineItems.length;
                var newItem = $scope.data.selectedTaskTimelineItems[listLength - 1];
                if($scope.scrollsDownNewTimelineItem(newItem)) {
                    $('#task-timeline').scrollTop($('#task-timeline .timeline-container').height());
                }
            }
            _.forEach($scope.data.selectedTaskTimelineItems, function(item) {
                switch(item.type) {
                    case 'email':
                        $scope.addEmailFilesPopoverTimelineItem(item);
                        break;
                    case 'inbound_call':
                    case 'outbound_call':
                    case 'missed_call':
                        if(!item.ddState && item.state !== 'answered' && item.state !== 'ringing') {
                            item.ddState = {
                                shownDispositions: false
                            };
                        }
                        $scope.addCallPopoverMenuTimelineItem(item);
                        break;
                    case 'note':
                        $scope.addNotePopoverMenuTimelineItem(item);
                        break;
                };
            });
            $scope.timelineClosePopovers();
        };

        $scope.getNewTimelineItem = function (ablyEventData) {
            const teamId = authService.getTeamId();
            const userId = authService.getUserId();
            const request = {
                user_id: userId,
                team_id: teamId,
                task_id: ablyEventData.task_id,
                entry_id: ablyEventData.entry_id,
            }

            tasksService.getTaskTimelineItem(request)
                .then(item => {
                    const isItemAlreadyExists = typeof $scope.data.selectedTaskTimelineItems.find(existingItem => existingItem.entry_id === item.entry_id) !== 'undefined';
                    if (!isItemAlreadyExists) {
                        $scope.data.selectedTaskTimelineItems.push(item);
                    }
                    $scope.data.selectedTask.isNewItemAddedTimeline = true;
                    $scope.$apply();
                });
        }

        $scope.changeDisposition = function(entry, disposition) {
            $scope.closePopovers();
            entry.changingDisposition = true;
            const teamId = authService.getTeamId();
            const userId = authService.getUserId();

            const callReq = {
                user_id: userId,
                team_id: teamId,
                call_id: entry.call_id,
                sms_id: entry.sms_id,
                sms_body: entry.sms_body,
                to: entry.to,
                contact_id: entry.contact_id,
                disposition: {
                    id : disposition.id,
                    sentiment : disposition.sentiment
                },
                recording_id: entry.recording_id,
                call_direction: entry.direction,
                list_id: entry.list_id,
                duration: entry.duration,
                from: entry.from,
            };

            phoneService.manualUpdateDisposition(callReq)
                .then(updatedEntry => {
                    entry.changingDisposition = false;
                    entry.disposition = updatedEntry.disposition;
                    $scope.$apply();
                    notificationToastService.showSuccessToast('fa-light fa-user-tag', 'Disposition has been changed!');
                })
                .catch(err => {
                    console.error(err);
                    entry.changingDisposition = false;
                    $scope.$apply();
                    notificationToastService.showErrorToast('fa-light fa-triangle-exclamation', 'We were unable to change disposition. Try again later.');
                });
        };

        $scope.loadDispositions = function() {
            return new Promise(function(resolve, reject) {

                if(Object.keys($rootScope.available_dispositions).length > 0) {
                    $scope.data.availableDispositions = $rootScope.available_dispositions;
                    resolve();
                    return;
                }

                var teamId = authService.getTeamId();
                var userId = authService.getUserId();

                const request = {
                    team_id: teamId,
                    user_id: userId,
                };

                dispatcherService.getAvailableDispositions(request)
                    .then(results => {
                        $rootScope.available_dispositions = results || {};
                        $scope.data.availableDispositions = $rootScope.available_dispositions;
                        $scope.$apply();
                        resolve();
                    })
                    .catch(err => {
                        console.error(err);
                        $scope.$apply();
                        reject();
                    });
            });
        };

        $scope.openGoogleMapsInNewTab = function () {
            var url = 'https://maps.google.com/?q=' +
                $scope.data.selectedTask.contact.address + ' ' +
                $scope.data.selectedTask.contact.city + ', ' +
                $scope.data.selectedTask.contact.state + ' ' +
                $scope.data.selectedTask.contact.zip_code;
            $window.open(url, '_blank');
        };

        $scope.changeTaskStatus = function (newStatus, isSendAndReopenFeature) {
            var successNotificationIcon = '';
            var successNotificationMessage = '';
            switch (newStatus) {
                case $scope.staticData.available_task_statuses.open:
                    newStatus = $scope.staticData.available_task_statuses.open;
                    successNotificationIcon = 'fa-light fa-inbox';
                    successNotificationMessage = 'Task status has been changed to OPEN!';
                    break;
                case $scope.staticData.available_task_statuses.snoozed:
                    newStatus = $scope.staticData.available_task_statuses.snoozed;
                    successNotificationIcon = 'fa-light fa-clock';
                    successNotificationMessage = 'Task status has been changed to SNOOZED!';
                    break;
                case $scope.staticData.available_task_statuses.closed:
                    newStatus = $scope.staticData.available_task_statuses.closed;
                    successNotificationIcon = 'fa-light fa-check';
                    successNotificationMessage = 'Task status has been changed to CLOSED!';
                    break;
                default:
                    notificationToastService.showErrorToast('fa-light fa-triangle-exclamation', 'Unknown task status.');
                    return;
            }
            $scope.loading.changeTaskStatus[newStatus] = (!isSendAndReopenFeature) ? true : false;

            const request = {
                task_id: $scope.data.selectedTask.task_id,
                status: newStatus
            }

            tasksService.updateTaskStatus(request)
                .then(res => {
                    $scope.data.selectedTask.status = res.status;
                    if(isSendAndReopenFeature) {
                        $scope.data.selectedTask.showOverlayTasksList = true;
                        $scope.data.selectedTask.remove_after_unselect = true;
                        $scope.data.selectedTask.newStatus = 'open';
                    } else {
                        $rootScope.$broadcast('changeTaskStatusEvent', $scope.data.selectedTask);
                    }

                    $scope.loading.changeTaskStatus[newStatus] = false;
                    $scope.$apply();
                    //notificationToastService.showSuccessToast(successNotificationIcon, successNotificationMessage);
                })
                .then(() => {
                })
                .catch(err => {
                    console.error(err);
                    $scope.loading.changeTaskStatus[newStatus] = false;
                    $scope.$apply();
                    notificationToastService.showErrorToast('fa-light fa-triangle-exclamation', 'Something went wrong. Try again later.');
                })
        }

        $scope.updateTaskUrgentStatus = function () {
            $scope.loading.updateTaskUrgentStatus = true;
            const request = {
                task_id: $scope.data.selectedTask.task_id,
                urgent: !$scope.data.selectedTask.urgent
            }

            tasksService.updateTaskUrgentStatus(request)
                .then(res => {
                    $scope.data.selectedTask.urgent = res.urgent;
                    $scope.loading.updateTaskUrgentStatus = false;
                    var notificationMessage = ($scope.data.selectedTask.urgent) ? 'Task has been marked as priority!' : 'Task has been unmarked as priority!';
                    var notificationIcon = ($scope.data.selectedTask.urgent) ? 'fa-solid fa-circle-star' : 'fa-light fa-circle-star';
                    $scope.$apply();
                    notificationToastService.showSuccessToast(notificationIcon, notificationMessage);
                })
                .catch(err => {
                    console.error(err);
                    $scope.loading.updateTaskUrgentStatus = false;
                    $scope.$apply();
                    notificationToastService.showErrorToast('fa-light fa-triangle-exclamation', 'Something went wrong. Try again later.');
                })
        }

        $scope.viewDetails = function () {
            $location.path('/contacts/detail').search('id=' + $scope.data.selectedTask.contact_id);
        };

        $scope.openCallPanel = function () {
            $rootScope.$broadcast('openCallTab', $scope.data.selectedTask.contact);
        };

        $scope.answerCall = function() {
            let req = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                access_number: $rootScope.access_number,
                initial_action: callService.CONTROL_ACTIONS.CALL_INTERCEPT,
                intercept_cid: $scope.data.selectedTask.call_id,
                enable_audio: true
            };
            callService.dial(req);
        };

        $scope.declineCall = function() {
            $scope.hangupCall();
        };

        $scope.moveCallFromRingingToInbound = function(callId) {
            $rootScope.$broadcast('moveCallFromRingingToInbound', callId);
        };

        $scope.hangupCall = function() {
            const teamId = authService.getTeamId();
            const userId = authService.getUserId();

            const request = {
                team_id: teamId,
                user_id: userId,
                call_id: $scope.data.selectedTask.call_id
            };

            phoneService.hangupCall(request)
                .then(results => {
                    delete $scope.data.selectedTask.state;
                    $scope.moveCallFromRingingToInbound($scope.data.selectedTask.call_id);
                    $scope.$apply();
                })
                .catch(err => {
                    console.error(err);
                    delete $scope.data.selectedTask.state;
                    $scope.$apply();
                });
        };

        $scope.isNotePinned = function (note) {
            return ($scope.data.selectedTask.contact.pinned_notes_ids || []).includes(note.note_id);
        }

        $scope.pinUnpinNote = function (note) {
            const action = $scope.isNotePinned(note) ? 'unpin' : 'pin';
            const req = {
                contact_id: $scope.data.selectedTask.contact.contact_id,
                note_id: note.note_id,
                action
            };
            return tasksService.pinUnpinNote(req)
                .then(() => {
                    switch (action) {
                        case 'pin':
                            $scope.data.selectedTask.contact.pinned_notes_ids.push(note.note_id);
                            break;
                        case 'unpin':
                            $scope.data.selectedTask.contact.pinned_notes_ids.splice($scope.data.selectedTask.contact.pinned_notes_ids.indexOf(note.note_id), 1);
                            break;
                    }
                    $rootScope.$broadcast('loadContactProfileRightPanel', $scope.data.selectedTask.contact.contact_id);
                })
                .catch(err => {
                    console.error(err);
                    swal("Error", "Something went wrong.", "error");
                })
        }

        $scope.deleteNoteEntry = function(note) {
            var index = _.findIndex($scope.data.selectedTaskTimelineItems, function(item) { return item.entry_id === note.note_id || item.note_id === note.note_id; });
            if(index !== -1) {
                $scope.data.selectedTaskTimelineItems = _.remove($scope.data.selectedTaskTimelineItems, function (n) {
                    return n.note_id !== note.note_id;
                });
            }
        };

        $scope.$on('updateContactNotesEvent', function (event, note, action) {
            switch (action) {
                case 'deleteEntry': $scope.deleteNoteEntry(note); break;
            }
        });

        $scope.removeNote = function (note, index) {
            $scope.closePopovers();
            note.removingNote = true;
            const req = {
                task_id: $scope.data.selectedTask.task_id,
                contact_id: $scope.data.selectedTask.contact.contact_id,
                note_id: note.note_id,
            };
            return tasksService.deleteNote(req)
                .then(() => {
                    note.removingNote = false;
                    storeSelectedContactService.updateContactNotes(note, 'deleteEntry');
                    $scope.$apply();
                    notificationToastService.showSuccessToast('fa-light fa-trash-check', 'Note has been removed from the task!');
                })
                .catch(err => {
                    console.error(err);
                    $scope.$apply();
                    notificationToastService.showErrorToast('fa-light fa-triangle-exclamation', 'We were unable to remove note. Try again later.');
                })
        }

        $scope.resizeTranscriptionBlock = function(size, item) {
            var transcriptionBlock = document.getElementById('transcription-block-' + item.entry_id);
            var transcription = document.getElementById('transcription-' + item.entry_id);
            var height = transcription.clientHeight + (item.have_more_text ? 25 : 0);
            transcriptionBlock.style.height = height + 'px';
        };

        $scope.getFullTranscription = function (entry) {
            if (typeof entry.full_transcription !== "undefined") {
                return;
            }
            entry.loadingTranscription = true;
            let req = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                recording_id: entry.recording_id,
            };
            phoneService.getTranscription(req)
                .then(transcription => {
                    entry.full_transcription = transcription;
                    entry.loadingTranscription = false;
                    $scope.$apply();
                })
                .catch(err => {
                    console.log(err);
                    entry.loadingTranscription = false;
                    $scope.$apply();
                });
        }

        $scope.toggleFullTranscription = function(item) {
            var transcriptionBlock = document.getElementById('transcription-block-' + item.entry_id);
            if(item.showAllFields === undefined) {
                transcriptionBlock.classList.add('collapse-animation-transcription-block');
            }

            if(!item.showFullTranscription) {
                $scope.getFullTranscription(item);
            }

            item.showFullTranscription = !item.showFullTranscription;
        }

        $scope.replyToEmail = function(item) {
            $rootScope.$broadcast('replyToEmailTextArea', item);
        }

        $scope.returnBack = function () {
            $scope.setSelectedTask(null);
            $rootScope.$broadcast('returnBack');
        }

        $scope.$on('email.received', function (e, ablyEvent) {
            if (ablyEvent.data.task_id === $scope.data.selectedTask.task_id && !!ablyEvent.data.entry_id) {
                $scope.getNewTimelineItem(ablyEvent.data);
            }
        });

        $scope.$on('email.sent', function (e, ablyEvent) {
            if (ablyEvent.data.task_id === $scope.data.selectedTask.task_id && !!ablyEvent.data.entry_id) {
                $scope.getNewTimelineItem(ablyEvent.data);
            }
        });

        $scope.$on('sms.received', function (e, ablyEvent) {
            if (ablyEvent.data.task_id === $scope.data.selectedTask.task_id && !!ablyEvent.data.entry_id) {
                $scope.getNewTimelineItem(ablyEvent.data);
            }
        });

        $scope.$on('sms.sent', function (e, ablyEvent) {
            if (ablyEvent.data.task_id === $scope.data.selectedTask.task_id && !!ablyEvent.data.entry_id) {
                $scope.getNewTimelineItem(ablyEvent.data);
            }
        });

        $scope.$on('note.created', function (e, ablyEvent) {
            if (ablyEvent.data.task_id === $scope.data.selectedTask.task_id && !!ablyEvent.data.entry_id) {
                $scope.getNewTimelineItem(ablyEvent.data);
            }
        });

        $scope.$on('task.call.answer', function (e, task) {
            if ($scope.data.selectedTask.state === 'ringing' || $scope.data.selectedTask.state === 'answered') {
                const inboundCallItem = $scope.data.selectedTaskTimelineItems.find(item => item.type === 'inbound_call' && item.direction === 'inbound')
                if (typeof inboundCallItem !== 'undefined') {
                    inboundCallItem.state = task.state;
                    inboundCallItem.duration = task.duration;
                    $scope.$apply();
                }
            }
        });

        $scope.$on('task.call.hangup', function (event, callId) {
            const inboundCallItem = $scope.data.selectedTaskTimelineItems.find(item => item.type === 'inbound_call' && item.direction === 'inbound')
            if (typeof inboundCallItem !== 'undefined') {
                delete inboundCallItem.state;
                $scope.$apply();
            }
        });

        $scope.$on('setSelectedTask', function (event, task) {
            $scope.setSelectedTask(task);
        });

        $scope.$on('unsetSelectedTask', function (event, needApply = false) {
            $scope.data.selectedTask = null;
            $scope.data.selectedTaskTimelineItems = [];
            if (needApply) {
                $scope.$apply();
            }
        });

        $scope.$on('currentOpenedRecordChanged', function(event, newCurrentOpenedPlayerId, recordType) {
            switch(recordType) {
                case 'voicemail':
                    $scope.currentOpenedPlayerIdVoicemail = newCurrentOpenedPlayerId;
                    break;
                case 'call':
                    $scope.currentOpenedPlayerIdCall = newCurrentOpenedPlayerId;
                    break;
            };

        });
        $scope.$on('audioObjectChanged', function(event, audioObject, index, recordType) {
            $scope.data.selectedTaskTimelineItems[index].audio = audioObject;
        });

        $scope.$on('changeTaskStatusFromTextArea', function (event, status, isSendAndReopenFeature) {
            $scope.changeTaskStatus(status, isSendAndReopenFeature);
        });

        $scope.checkPage = function() {
            switch ($scope.page) {
                case 'inbox':
                    break;
                case 'contact-details':
                    $scope.data.selectedTask = null;
                    $scope.loading.timelineFirstLoad = true;
                    break;
            }
        };

        vm.$onInit = function() {
            $scope.page = $attrs.page;
            $scope.checkPage();

            // a setTimeout with 0 seconds just puts the handler at the end of the event queue, and will run after the current call stack is clear
            // this allows the table load to trigger without running into race issue
            setTimeout(function() {
                if($scope.page === 'inbox') {
                    $rootScope.$broadcast('getTasks');
                }
            }, 0);

            Promise.all([
                authService.getUser(),
                $scope.loadTeamsUsers(),
                $scope.loadTemplates(),
                $scope.loadDispositions()
            ]).then(function(results) {
                $scope.data.user = results[0];
            }).catch(function(err) {
                console.error(err);
                $scope.$apply();
            });
        };
    }

    module.exports = ConversationController;
})();
