(function() {
    'use strict';

    function CustomShoppingCartModalController($rootScope, $scope, authService, contactService, $http) {
        var vm = this;
        vm.authService = authService;

        $scope.appLogo = $rootScope.settings.APP_LOGO;

        $scope.staticText = {
            loading: 'Loading',
            requiredMessage: '* Field is required.',
            errorMessage: 'Fields are required.',
            step1: {
                subscriptionTitle: 'Subscription Summary',
                oneTimeChargesTitle: 'One Time Charges',
                removeBtn: 'remove',
                addOnsTitle: 'Recommended Add-Ons',
                couponPlaceholder: 'Coupon Code',
                subtotalTitle: 'Subtotal',
                subscriptionAnnual: 'annual subscription billed monthly',
                subscriptionMonthly: 'month-to-month recurring subscription',
                oneTimeChargesSubtotalSubtitle: 'non-recurring charges',
                addBtn: 'Add',
                errorMessage: 'Please select Add-On.',
                submitBtn: 'Proceed to Checkout'
            },
            step2: {
                title: 'Primary Account Holder',
                placeholders: [
                    'First Name',
                    'Last Name',
                    'Company Name',
                    'Email'
                ],
                submitBtn: 'Next Step'
            },
            step3: {
                shippingAddressTitle: 'Shipping Address',
                placeholders: [
                    'First Name',
                    'Last Name',
                    'Company Name',
                    'Street Address'
                ],
                billingAddressTitle: 'Billing Address',
                submitBtn: 'Next Step'
            },
            step4: {
                orderSummaryTitle: 'Order Summary',
                itemsColTitle: ['Item', 'Items'],
                orderSummaryPriceMonthly: 'per month',
                orderSummaryPriceNonRecurring: 'non-recurring',
                totalAmountTitle: 'Total Amount',
                totalAmountSubtitle: '(Includes Tax)',
                totalAmountDetails: 'due today',
                editBtn: 'edit',
                shippingAddressSummaryTitle: 'Shipping Address',
                billingAddressSummaryTitle: 'Billing Address',
                paymentMethodTitle: 'Payment Method',
                weAcceptCardsTitle: 'We Accept',
                placeholders: [
                    'Name on Card',
                    'Card Number',
                    'Card Code',
                    'MM',
                    'YYYY'
                ],
                agreeCheckboxes: [
                    { title: `By placing an order you agree to be billed by ${$rootScope.settings.PARENT_COMPANY} dba ${$rootScope.settings.APP_NAME}. The charge will appear on your statement as ${$rootScope.settings.APP_NAME}.` },
                    { title: 'By placing an order you understand that this is a monthly recurring subscription that will auto-renew each month and consent to incurring the monthly subscription fee that will be automatically charged to your payment card each month.' },
                    {
                        title1: 'I agree to ',
                        link1: {
                            title: 'Terms of Service',
                            href: APP_TERMS_LINK,
                        },
                        title2: ' and ',
                        link2: {
                            title: 'Privacy Policy',
                            href: APP_PRIVACY_LINK,
                        }
                    }
                ],
                cardNumberMask: '0000 0000 0000 0000',
                secureCodeMask: '0000',
                paymentMonthMask: '00',
                paymentYearMask: '0000',
                submitBtn1: 'Pay',
                submitBtn2: ' & Subscribe',
                secureCheckoutTitle: 'Secure Checkout'
            }
        };

        $scope.data = {
            step: '1', // 1, 2, 3, 4,

            planId: 'monthly-plan',
            cartId: null,
            billingAccountId: null,
            billingTerm: 'monthly',
            teamId: '',
            orderTaxes: 0,

            subscriptionSummary: [],
            subscriptionSubtotalPrice: 0,
            subscriptionSubtotalDescription: '',
            couponCode: '',
            checkingCoupon: false,
            isCouponCodeCorrect: undefined,
            oneTimeCharges: [],
            oneTimeChargesSubtotalPrice: 0,
            oneTimeChargesSubtotalDescription: '',
            recommendedAddOns: [],

            primaryAccountHolder: {
                firstName : '',
                lastName : '',
                companyName : '',
                email: '',
                phoneNumber: ''
            },

            shippingAddress: {
                firstName : '',
                lastName : '',
                companyName : '',
                address: '',
                postalCode: '',
                fullAddress: '',
                fullAddressLabel: ''
            },
            isSameAsShippingAddress: false,
            billingAddress: {
                firstName : '',
                lastName : '',
                companyName : '',
                address: '',
                postalCode: '',
                fullAddress: '',
                fullAddressLabel: ''
            },

            orderSummaryArray: [],
            orderSummaryItems: 0,
            totalAmount: 0,
            paymentMethod: {
                name: '',
                cardNumber: '',
                cardCode: '',
                month: '',
                year: '',
                formattedCardNumber: '',
                formattedCardCode: '',
                formattedMonth: '',
                formattedYear: '',
                agree1: false,
                agree2: false,
                agree3: false,
                dataDescriptor: '',
                dataValue: ''
            },
            isPaymentMethodValid: {
                cardNumber: undefined,
                cardCode: undefined,
                month: undefined,
                year: undefined
            },

            isPhoneNumberValid: undefined,
            isStep1Valid: undefined,
            isStep2Valid: undefined,
            isStep3Valid: undefined,
            isStep4Valid: undefined,

            step1Submitted: false,
            step2Submitted: false,
            step3Submitted: false,
            step4Submitted: false,

            loading: false,
            displayErrorAlert: false,
            errorAlertMessage: ''
        };
        $scope.knownErrors = {
            "The 'AnetApi/xml/v1/schema/AnetApiSchema.xsd:cardNumber' element is invalid" : "Card Format is invalid. Please verify your card details and try again.",
            "This transaction has been declined" : "Transaction declined. Please verify your card and billing address details and try again."
        };

        $scope.couponCodeChanged = function() {
            $scope.data.isCouponCodeCorrect = undefined;
            $scope.data.couponCode = $scope.data.couponCode.trim();
            if ($scope.data.couponCode.length > 2) {
                $scope.data.checkingCoupon = true;

                setTimeout(function() {
                    // is coupon code correct?
                    $scope.data.isCouponCodeCorrect = true;
                    // is coupon code incorrect?
                    // $scope.data.isCouponCodeCorrect = false;

                    $scope.data.checkingCoupon = false;
                }, 400);
            } else {
                $scope.data.isCouponCodeCorrect = undefined;
            }
        };

        $scope.checkProductCount = function(value) {
            var max = 5;
            var min = 1;

            if(!angular.isNumber(value) || Number.isNaN(value)) {
                return min;
            } else {
                if(value > max) { return max; }
                else if(value < min) { return min; }
                return value;
            }
        };

        $scope.calcSubtotalPrice = function(shoppingCartArray) {
            var total = 0;
            shoppingCartArray.forEach(function(item) {
                total += Number(item.count) * Number(item.price);
            });
            return total.toFixed(2);
        };

        $scope.countChanged = function(item) {
            item.count = $scope.checkProductCount(item.count);

            var arrayName = (item.type === 'subscription') ? 'subscriptionSummary' : 'oneTimeCharges';
            var subtotalPriceName = (item.type === 'subscription') ? 'subscriptionSubtotalPrice' : 'oneTimeChargesSubtotalPrice';
            $scope.data[subtotalPriceName] = $scope.calcSubtotalPrice($scope.data[arrayName]);
        };

        $scope.addItemToArrayFromRecommendedAddOns = function(item) {
            var arrayName = (item.type === 'subscription') ? 'subscriptionSummary' : 'oneTimeCharges';
            var subtotalPriceName = (item.type === 'subscription') ? 'subscriptionSubtotalPrice' : 'oneTimeChargesSubtotalPrice';
            $scope.data[arrayName].push(item);
            $scope.data[subtotalPriceName] = $scope.calcSubtotalPrice($scope.data[arrayName]);

            _.remove($scope.data.recommendedAddOns, {id:item.id});
            $scope.step1Validation();
        };

        $scope.removeFromArray = function(item) {
            var arrayName = (item.type === 'subscription') ? 'subscriptionSummary' : 'oneTimeCharges';
            var subtotalPriceName = (item.type === 'subscription') ? 'subscriptionSubtotalPrice' : 'oneTimeChargesSubtotalPrice';
            _.remove($scope.data[arrayName], {id:item.id});
            $scope.data[subtotalPriceName] = $scope.calcSubtotalPrice($scope.data[arrayName]);

            item.count = 1;
            $scope.data.recommendedAddOns.push(item);
            $scope.step1Validation();
        };

        $scope.isValidPhoneNumber = function() {
            $scope.data.isPhoneNumberValid = $scope.data.iti.isValidNumber();
        };

        $scope.formatPhone = function() {
            if($scope.data.iti) {
                $scope.isValidPhoneNumber();
                $scope.data.primaryAccountHolder.phoneNumber = ($scope.data.isPhoneNumberValid) ? $scope.data.iti.getNumber(window.intlTelInput.utils.numberFormat.E164) : '';
                $scope.step2Validation();
            }
        };

        $scope.setBillingAddress = function(callback) {
            $scope.data.billingAddress.firstName = $scope.data.shippingAddress.firstName;
            $scope.data.billingAddress.lastName = $scope.data.shippingAddress.lastName;
            $scope.data.billingAddress.companyName = $scope.data.shippingAddress.companyName;
            $scope.data.billingAddress.address = $scope.data.shippingAddress.address;
            $scope.data.billingAddress.postalCode = $scope.data.shippingAddress.postalCode;
            $scope.data.billingAddress.fullAddress = $scope.data.shippingAddress.fullAddress;
            $scope.data.billingAddress.fullAddressLabel = $scope.data.shippingAddress.fullAddressLabel;
            callback();
        };

        $scope.sameAsShippingAddress = function() {
            $('#first-name-billing-address-payment-form').attr('readonly', $scope.data.isSameAsShippingAddress);
            $('#last-name-billing-address-payment-form').attr('readonly', $scope.data.isSameAsShippingAddress);
            $('#company-billing-address-payment-form').attr('readonly', $scope.data.isSameAsShippingAddress);
            $('#street-billing-address-payment-form').attr('readonly', $scope.data.isSameAsShippingAddress);
            $scope.step3Validation();
        };

        $scope.validateNumberAndLengthField = function(value, length) {
            var reg = /^\d+$/;
            if((reg.test(value) || value === '') && value.length <= length) {
                return true;
            }
            return false;
        };

        $scope.validateYearField = function(value) {
            var currentYear = new Date().getFullYear();
            var year = value;
            var regYear = /\b((20[[0-9]{2}))\b/;
            if(!regYear.test(year) || (Number(year) < currentYear)) {
                return false;
            }
            return true;
        };

        $scope.validateMonthField = function(value) {
            var regMonth = /\b((0[1-9])|(1[0-2]))\b/;
            if(!regMonth.test(value)) {
                return false;
            }
            return true;
        };

        $scope.validateCardCodeField = function(value) {
            var regCardCode = /\b\d{3,4}\b/;
            if(!regCardCode.test(value)) {
                return false;
            }
            return true;
        };

        $scope.validateCardNumberField = function(value) {
            var regCardNumber = /\b\d{13,16}\b/;
            if(!regCardNumber.test(value)) {
                return false;
            }
            return true;
        };

        $scope.cardNumberValidation = function() {
            var number = document.getElementById('cardnumber');
            var cardNumber = number.value.split(' ').join('');
            if($scope.validateNumberAndLengthField(cardNumber, 16)) {
                $scope.data.isPaymentMethodValid.cardNumber = $scope.validateCardNumberField(cardNumber) && number.validity.valid;
                $scope.data.paymentMethod.cardNumber = ($scope.data.isPaymentMethodValid.cardNumber) ? cardNumber : '';
                $scope.step4Validation();
            }
        };

        $scope.cardCodeValidation = function() {
            var code = document.getElementById('cvc');
            var cardCode = code.value;
            if($scope.validateNumberAndLengthField(cardCode, 4)) {
                $scope.data.isPaymentMethodValid.cardCode = $scope.validateCardCodeField(cardCode) && code.validity.valid;
                $scope.data.paymentMethod.cardCode = ($scope.data.isPaymentMethodValid.cardCode) ?  cardCode : '';
                $scope.step4Validation();
            }
        };

        $scope.monthValidation = function(monthElem, value) {
            $scope.data.isPaymentMethodValid.month = $scope.validateMonthField(value) && monthElem.validity.valid;
            $scope.data.paymentMethod.month = ($scope.data.isPaymentMethodValid.month) ? value : '';
            $scope.step4Validation();
        };

        $scope.yearValidation = function(yearElem, value) {
            $scope.data.isPaymentMethodValid.year = $scope.validateYearField(value) && yearElem.validity.valid;
            $scope.data.paymentMethod.year = ($scope.data.isPaymentMethodValid.year) ? value : '';
            $scope.step4Validation();
        };

        $scope._validateDirtyMonthInput = function(monthElem, month) {
            if($scope.data.isPaymentMethodValid.month !== undefined) {
                $scope.monthValidation(monthElem, month);
            }
        };

        $scope._validateDirtyYearInput = function(yearElem, year) {
            if($scope.data.isPaymentMethodValid.year !== undefined) {
                $scope.yearValidation(yearElem, year);
            }
        };

        $scope._validateMonthYearInputs = function(monthElem, month, yearElem, year) {
            $scope.monthValidation(monthElem, month);
            $scope.yearValidation(yearElem, year);
        };

        $scope._monthYearValidation = function(month, year, monthElem, yearElem) {
            var currentYear = new Date().getFullYear();
            var currentMonth = new Date().getMonth() + 1;
            switch(true) {
                case (month.length === 0 && year.length === 0):
                    $scope._validateDirtyMonthInput(monthElem, month);
                    $scope._validateDirtyYearInput(yearElem, year);
                    if($scope.data.step4Submitted) {
                        $scope._validateMonthYearInputs(monthElem, month, yearElem, year);
                    }
                    break;
                case ((month.length > 0 && month.length <= 2) && year.length === 0):
                    $scope.monthValidation(monthElem, month);
                    $scope._validateDirtyYearInput(yearElem, year);
                    break;
                case (month.length === 0 && (year.length > 0 && year.length <= 4)):
                    $scope.yearValidation(yearElem, year);
                    $scope._validateDirtyMonthInput(monthElem, month);
                    break;
                case (month.length === 2 && year.length === 4):
                    if(currentYear === Number(year) && Number(month) < currentMonth) {
                        $scope._validateMonthYearInputs(monthElem, '', yearElem, '');
                    } else {
                        $scope._validateMonthYearInputs(monthElem, month, yearElem, year);
                    }
                    break;
            }
        };

        $scope.monthYearPaymentValidation = function() {
            var yearElem = document.getElementById('ccyear');
            var monthElem = document.getElementById('ccmonth');
            var year = yearElem.value;
            var month = monthElem.value;

            if($scope.validateNumberAndLengthField(year, 4) && $scope.validateNumberAndLengthField(month, 2)) {
                $scope._monthYearValidation(month, year, monthElem, yearElem);
            }
        };

        $scope.editSummaryOrder = function() {
            $scope.showHideErrorBottomAlert(false, '');
            $scope.data.isStep1Valid = undefined;
            $scope.data.step1Submitted = false;
            $scope.data.isPhoneNumberValid = undefined;
            $scope.data.isStep2Valid = undefined;
            $scope.data.step2Submitted = false;
            $scope.data.isStep3Valid = undefined;
            $scope.data.step3Submitted = false;
            $scope.data.isPaymentMethodValid.cardNumber = undefined;
            $scope.data.isPaymentMethodValid.cardCode = undefined;
            $scope.data.isPaymentMethodValid.month = undefined;
            $scope.data.isPaymentMethodValid.year = undefined;
            $scope.data.isStep4Valid = undefined;
            $scope.data.step4Submitted = false;

            $scope.initStep1();
            $scope.data.step = '1';
        };

        $scope.editAddress = function() {
            $scope.showHideErrorBottomAlert(false, '');
            $scope.data.isStep3Valid = undefined;
            $scope.data.step3Submitted = false;
            $scope.data.isPaymentMethodValid.cardNumber = undefined;
            $scope.data.isPaymentMethodValid.cardCode = undefined;
            $scope.data.isPaymentMethodValid.month = undefined;
            $scope.data.isPaymentMethodValid.year = undefined;
            $scope.data.isStep4Valid = undefined;
            $scope.data.step4Submitted = false;

            $scope.initStep3();
            $scope.data.step = '3';
        };

        $scope.showHideErrorBottomAlert = function(isDisplayed, message) {
            $scope.data.displayErrorAlert = isDisplayed;
            $scope.data.errorAlertMessage = message;
            if(isDisplayed) {
                var div = document.getElementById('custom-shopping-cart-modal-body');
                div.scrollTop = div.scrollHeight - div.clientHeight;
            }
        };

        $scope.submitSuccess = function(alertMessage) {
            swal(alertMessage.title, alertMessage.body, 'success');
            $scope.data.loading = false;
            $('#customShoppingCartModal').modal('hide');
            $scope.$apply();
        };

        $scope.submitError = function(message) {
            $scope.showHideErrorBottomAlert(true, message);
            $scope.data.loading = false;
            $scope.$apply();
        };

        $scope.submitFormMainFunction = function(acceptResponse) {
            var year = $scope.data.paymentMethod.year.slice(-2);

            let accountReq = {
                cart_id: $scope.data.cartId,
                payment_method: {
                    name: $scope.data.paymentMethod.name,
                    expiration_month: $scope.data.paymentMethod.month,
                    expiration_year: year,
                    token: acceptResponse,
                    address: $scope.data.billingAddress.fullAddress
                }
            };
            console.log(accountReq);

            // $scope.submitSuccess({title: 'Success', body: 'Success'});
            //OR error message
            $scope.submitError('Error');
        };

        $scope.deobfuscateCardErrors = function(error) {
            for (let knownError in $scope.knownErrors) {
                if(error.indexOf(knownError) !== -1) {
                    return $scope.knownErrors[knownError];
                }
            }
            return 'Unable to process transaction. Please verify your card details and try again.';
        };

        $scope.processAcceptResponse = function(response) {
            var res = response || {};
            var messages = res.messages || {};
            if (messages.resultCode === 'Error') {
                let errors = '';
                for (var errMsg of messages.message) {
                    if (errors) {
                        errors += '; ';
                    }
                    errors += errMsg.text;
                    console.log(errMsg.code + ': ' + errMsg.text);
                }
                $scope.data.loading = false;
                $scope.showHideErrorBottomAlert(true, $scope.deobfuscateCardErrors(errors));
            } else {
                var data = res.opaqueData || {};
                $scope.data.paymentMethod.dataDescriptor = data.dataDescriptor;
                $scope.data.paymentMethod.dataValue = data.dataValue;

                console.log('SEND TO SERVER HERE');
                $scope.submitFormMainFunction(data.dataValue);
            }
        };

        $scope.tokenizeCreditCardAcceptJs = function() {
            var year = $scope.data.paymentMethod.year.slice(-2);
            var postalCode = ($scope.data.billingAddress.postalCode || '').split('-')[0];

            var secureData = {
                cardData: {
                    cardNumber: $scope.data.paymentMethod.cardNumber,
                    cardCode: $scope.data.paymentMethod.cardCode,
                    month: $scope.data.paymentMethod.month,
                    year: year,
                    zip: postalCode,
                    fullName: $scope.data.paymentMethod.name,
                },
                authData: {
                    clientKey: $rootScope.settings.ACCEPT_CLIENT_KEY,
                    apiLoginID: $rootScope.settings.ACCEPT_API_LOGIN_ID
                }
            };

            Accept.dispatchData(secureData, $scope.processAcceptResponse);
        };

        $scope.calcTotalAmount = function() {
            var subscriptionSummarySubtotal = $scope.data.subscriptionSubtotalPrice;
            var oneTimeChargesSubtotal = $scope.data.oneTimeChargesSubtotalPrice;
            var taxes = $scope.data.orderTaxes;
            var totalAmount = Number(subscriptionSummarySubtotal) + Number(oneTimeChargesSubtotal) + Number(taxes);
            $scope.data.totalAmount = totalAmount.toFixed(2);
        };

        $scope.createOrderSummaryArray = function() {
            $scope.data.orderSummaryArray = [];
            if($scope.data.subscriptionSummary.length > 0) {
                var subscriptionOrder = {
                    id: 'subscription',
                    name: ($scope.data.planId !== 'monthly-plan') ? $scope.staticText.step1.subscriptionAnnual : $scope.staticText.step1.subscriptionMonthly,
                    totalPrice: $scope.data.subscriptionSubtotalPrice,
                    priceDescription: $scope.staticText.step4.orderSummaryPriceMonthly
                };
                $scope.data.orderSummaryArray.push(subscriptionOrder);
            }
            if($scope.data.oneTimeCharges.length > 0) {
                var oneTimeChargesOrder = {
                    id: 'oneTimeCharges',
                    name: 'One Time Charges',
                    totalPrice: $scope.data.oneTimeChargesSubtotalPrice,
                    priceDescription: $scope.staticText.step4.orderSummaryPriceNonRecurring
                };
                $scope.data.orderSummaryArray.push(oneTimeChargesOrder);
            }
        };

        $scope.setCardYearMask = function() {
            $('#ccyear').mask($scope.staticText.step4.paymentYearMask, {});
        };

        $scope.setCardMonthMask = function() {
            $('#ccmonth').mask($scope.staticText.step4.paymentMonthMask, {});
        };

        $scope.setSecureCodeMask = function() {
            $('#cvc').mask($scope.staticText.step4.secureCodeMask, {});
        };

        $scope.setCardNumberMask = function() {
            $('#cardnumber').mask($scope.staticText.step4.cardNumberMask, {});
        };

        $scope.initLocate = function() {
            // Loqate's pca object is a global var and also injected into the window object.  Make sure it's loaded...
            let __pca = pca || window.pca;
            if (__pca) {
                var options = {
                    key: $rootScope.settings.LOQATE_KEY,
                    setCountryByIP: true
                };
                var displayHtml = '{Line1}, {City} {Province} {PostalCode}, {CountryName}';
                var shippingFields = [ { element: 'street-address-payment-form', field: displayHtml } ];
                var billingFields = [ { element: 'street-billing-address-payment-form', field: displayHtml } ];
                var shippingControl = new __pca.Address(shippingFields, options);
                var billingControl = new __pca.Address(billingFields, options);

                shippingControl.listen('populate', function(address, variations) {
                    $scope.data.shippingAddress.fullAddress = address;
                    $scope.data.shippingAddress.fullAddressLabel = address.Label;
                    $scope.data.shippingAddress.postalCode = address.PostalCode;
                    $('#street-address-payment-form').blur();
                });

                billingControl.listen('populate', function(address, variations) {
                    $scope.data.billingAddress.fullAddress = address;
                    $scope.data.billingAddress.fullAddressLabel = address.Label;
                    $scope.data.billingAddress.postalCode = address.PostalCode;
                    $('#street-billing-address-payment-form').blur();
                });
            }
        };

        $scope.setPhoneMask = function(elem, mask, placeholder) {
            elem.mask(mask, {
                placeholder: placeholder
            });
        };

        $scope.initPhoneInput = function() {
            var phoneNumberId = $('#phone-number-input-payment-form');
            var phoneNumberIdInput = document.querySelector('#phone-number-input-payment-form');

            if(phoneNumberId && !$scope.data.iti) {
                $scope.data.iti = window.intlTelInput(phoneNumberIdInput, {
                    nationalMode: 'false',
                    autoPlaceholder: 'aggressive',
                    separateDialCode: 'true',
                    validationNumberTypes: [
                        'FIXED_LINE',
                        'MOBILE',
                        'FIXED_LINE_OR_MOBILE',
                        'TOLL_FREE',
                        'PREMIUM_RATE',
                        'SHARED_COST',
                        'VOIP',
                        'PERSONAL_NUMBER',
                        'PAGER',
                        'UAN',
                        'VOICEMAIL',
                        'UNKNOWN'
                    ],
                    initialCountry: 'us',
                    // intl-tel-input is up to date as of dec 2024
                    loadUtils: () => import("https://cdn.jsdelivr.net/npm/intl-tel-input@25.2.0/build/js/utils.js"),
                    customPlaceholder: function(selectedCountryPlaceholder) {
                        var pl = selectedCountryPlaceholder.replace(/[0-9]/g, '0');
                        phoneNumberId.attr('maxlength', pl.length);
                        $scope.setPhoneMask(phoneNumberId, pl, pl);
                        return pl;
                    }
                });

                if($scope.data.primaryAccountHolder.phoneNumber.length > 0) {
                    $scope.data.iti.setNumber($scope.data.primaryAccountHolder.phoneNumber);
                }

                phoneNumberId.on('countrychange', function(e) {
                    $scope.data.iti.setNumber('');
                    phoneNumberId.unmask();

                    var mask = e.target.placeholder.replace(/[0-9]/g, '0');
                    phoneNumberId.attr('maxlength', mask.length);
                    $scope.setPhoneMask(phoneNumberId, mask, e.target.placeholder);
                });
            }
        };

        $scope.step4Validation = function() {
            var fullName = $scope.data.paymentMethod.name && $scope.data.paymentMethod.name.length > 0;
            var isFullNameValid = $scope.customShoppingCartForm.ccname.$valid && fullName;
            var isCardNumberValid = $scope.data.isPaymentMethodValid.cardNumber;
            var isCardCodeValid = $scope.data.isPaymentMethodValid.cardCode;
            var isMonthValid = $scope.data.isPaymentMethodValid.month;
            var isYearValid = $scope.data.isPaymentMethodValid.year;
            var agree = $scope.data.paymentMethod.agree1 && $scope.data.paymentMethod.agree2 && $scope.data.paymentMethod.agree3;

            $scope.data.isStep4Valid = (isFullNameValid && isCardNumberValid && isCardCodeValid && isMonthValid && isYearValid && agree) ? true : false;

            var errorMessage = ($scope.data.isStep4Valid === false && $scope.data.step4Submitted) ? $scope.staticText.errorMessage : '';
            $scope.showHideErrorBottomAlert(($scope.data.isStep4Valid === false && $scope.data.step4Submitted) ? true : false, errorMessage);
        };

        $scope.submitStep4 = function() {
            $scope.data.step4Submitted = true;
            $scope.cardNumberValidation();
            $scope.cardCodeValidation();
            $scope.monthYearPaymentValidation();
            $scope.step4Validation();
            if($scope.data.isStep4Valid) {
                $scope.tokenizeCreditCardAcceptJs();
            } else {
                $scope.data.loading = false;
            }
        };

        $scope.initStep4 = function() {
            $scope.setCardYearMask();
            $scope.setCardMonthMask();
            $scope.setCardNumberMask();
            $scope.setSecureCodeMask();
            $scope.createOrderSummaryArray();
            $scope.data.orderSummaryItems = $scope.data.orderSummaryArray.length || 0;
            $scope.calcTotalAmount();
        };

        $scope._step3Validation = function() {
            var firstNameAddress = $scope.data.shippingAddress.firstName && $scope.data.shippingAddress.firstName.length > 0;
            var lastNameAddress = $scope.data.shippingAddress.lastName && $scope.data.shippingAddress.lastName.length > 0;
            var address = $scope.data.shippingAddress.address && $scope.data.shippingAddress.address.length > 0;
            var isFirstNameAddressValid = $scope.customShoppingCartForm.firstNameAddressPaymentForm.$valid && firstNameAddress;
            var isLastNameAddressValid = $scope.customShoppingCartForm.lastNameAddressPaymentForm.$valid && lastNameAddress;
            var isAddressValid = $scope.customShoppingCartForm.streetAddressPaymentForm.$valid && address;

            var isFirstNameBillingAddressValid = $scope.data.billingAddress.firstName && $scope.data.billingAddress.firstName.length > 0;
            var isLastNameBillingAddressValid = $scope.data.billingAddress.lastName && $scope.data.billingAddress.lastName.length > 0;
            var isBillingAddressValid = $scope.data.billingAddress.address && $scope.data.billingAddress.address.length > 0;

            $scope.data.isStep3Valid = (isFirstNameAddressValid && isLastNameAddressValid && isAddressValid &&
                isFirstNameBillingAddressValid && isLastNameBillingAddressValid && isBillingAddressValid) ? true : false;

            var errorMessage = ($scope.data.isStep3Valid === false && $scope.data.step3Submitted) ? $scope.staticText.errorMessage : '';
            $scope.showHideErrorBottomAlert(($scope.data.isStep3Valid === false && $scope.data.step3Submitted) ? true : false, errorMessage);
        };

        $scope.step3BillingAddressValidation = function() {
            if(!$scope.data.isSameAsShippingAddress) {
                $scope._step3Validation();
            }
        };

        $scope.step3Validation = function() {
            if($scope.data.isSameAsShippingAddress) {
                $scope.setBillingAddress(function() {
                    $scope._step3Validation();
                });
            } else {
                $scope._step3Validation();
            }

        };

        $scope.submitStep3 = function() {
            $scope.data.step3Submitted = true;
            $scope.step3Validation();
            if($scope.data.isStep3Valid) {
                $scope.data.step = '4';
                $scope.initStep4();
                $scope.data.loading = false;
            } else {
                $scope.data.loading = false;
            }
        };

        $scope.initStep3 = function() {
            setTimeout(function() {
                $scope.initLocate();
                $scope.sameAsShippingAddress();
            }, 150);
        };

        $scope.step2Validation = function() {
            var firstName = $scope.data.primaryAccountHolder.firstName && $scope.data.primaryAccountHolder.firstName.length > 0;
            var lastName = $scope.data.primaryAccountHolder.lastName && $scope.data.primaryAccountHolder.lastName.length > 0;
            var email = $scope.data.primaryAccountHolder.email && $scope.data.primaryAccountHolder.email.length > 0;
            var phone = $scope.data.primaryAccountHolder.phoneNumber && $scope.data.primaryAccountHolder.phoneNumber.length > 0;

            var isFirstNameValid = $scope.customShoppingCartForm.firstNameInputPaymentForm.$valid && firstName;
            var isLastNameValid = $scope.customShoppingCartForm.lastNameInputPaymentForm.$valid && lastName;
            var isEmailValid = $scope.customShoppingCartForm.emailInputPaymentForm.$valid && email;
            var isPhoneValid = $scope.data.isPhoneNumberValid && phone;

            $scope.data.isStep2Valid = (isFirstNameValid && isLastNameValid && isEmailValid && isPhoneValid) ? true : false;

            var errorMessage = ($scope.data.isStep2Valid === false && $scope.data.step2Submitted) ? $scope.staticText.errorMessage : '';
            $scope.showHideErrorBottomAlert(($scope.data.isStep2Valid === false && $scope.data.step2Submitted) ? true : false, errorMessage);
        };

        $scope.submitStep2 = function() {
            $scope.data.step2Submitted = true;
            $scope.formatPhone();
            $scope.step2Validation();
            if($scope.data.isStep2Valid) {
                $scope.initStep3();
                $scope.data.step = '3';
                $scope.data.loading = false;
                $scope.cleanIti();
            } else {
                $scope.data.loading = false;
            }
        };

        $scope.initStep2 = function() {
            setTimeout(function() {
                $scope.initPhoneInput();
            }, 150);
        };

        $scope.step1Validation = function() {
            var subscriptionLength = $scope.data.subscriptionSummary.length;
            var oneTimeChargesLength = $scope.data.oneTimeCharges.length;
            $scope.data.isStep1Valid = subscriptionLength > 0 || oneTimeChargesLength > 0;

            var errorMessage = ($scope.data.isStep1Valid === false && $scope.data.step1Submitted) ? $scope.staticText.step1.errorMessage : '';
            $scope.showHideErrorBottomAlert(($scope.data.isStep1Valid === false && $scope.data.step1Submitted) ? true : false, errorMessage);
        };

        $scope.submitStep1 = function() {
            $scope.data.step1Submitted = true;
            $scope.step1Validation();
            if($scope.data.isStep1Valid) {
                $scope.initStep2();
                $scope.data.step = '2';
                $scope.data.loading = false;
            } else {
                $scope.data.loading = false;
            }
        };

        $scope.initStep1 = function() { };

        $scope.submitShoppingCartForm = function() {
            $scope.data.loading = true;
            switch($scope.data.step) {
                case '1': $scope.submitStep1(); break;
                case '2': $scope.submitStep2(); break;
                case '3': $scope.submitStep3(); break;
                case '4': $scope.submitStep4(); break;
            }
        };

        $scope.cleanStep4 = function() {
            $scope.data.orderSummaryArray = [];
            $scope.data.orderSummaryItems = 0;
            $scope.data.totalAmount = 0;
            $scope.data.paymentMethod.name = '';
            $scope.data.paymentMethod.cardNumber = '';
            $scope.data.paymentMethod.cardCode = '';
            $scope.data.paymentMethod.month = '';
            $scope.data.paymentMethod.year = '';
            $scope.data.paymentMethod.formattedCardNumber = '';
            $scope.data.paymentMethod.formattedCardCode = '';
            $scope.data.paymentMethod.formattedMonth = '';
            $scope.data.paymentMethod.formattedYear = '';
            $scope.data.paymentMethod.agree1 = false;
            $scope.data.paymentMethod.agree2 = false;
            $scope.data.paymentMethod.agree3 = false;
            $scope.data.paymentMethod.dataDescriptor = '';
            $scope.data.paymentMethod.dataValue = '';

            $scope.data.billingAccountId = null;
            $scope.data.teamId = '';
            $scope.data.cartId = null;
            $scope.data.planId = 'monthly-plan';
            $scope.data.billingTerm = 'monthly';
            $scope.data.orderTaxes = 0;

            $scope.data.isPaymentMethodValid.cardNumber = undefined;
            $scope.data.isPaymentMethodValid.cardCode = undefined;
            $scope.data.isPaymentMethodValid.month = undefined;
            $scope.data.isPaymentMethodValid.year = undefined;

            $scope.data.isStep4Valid = undefined;
            $scope.data.step4Submitted = false;
            $scope.$apply();
        };

        $scope.cleanStep3 = function() {
            $scope.data.shippingAddress.firstName = '';
            $scope.data.shippingAddress.lastName = '';
            $scope.data.shippingAddress.companyName = '';
            $scope.data.shippingAddress.address = '';
            $scope.data.shippingAddress.postalCode = '';
            $scope.data.shippingAddress.fullAddress = '';
            $scope.data.shippingAddress.fullAddressLabel = '';
            $scope.data.billingAddress.firstName = '';
            $scope.data.billingAddress.lastName = '';
            $scope.data.billingAddress.companyName = '';
            $scope.data.billingAddress.address = '';
            $scope.data.billingAddress.postalCode = '';
            $scope.data.billingAddress.fullAddress = '';
            $scope.data.billingAddress.fullAddressLabel = '';
            $scope.data.isSameAsShippingAddress = false;

            $scope.data.isStep3Valid = undefined;
            $scope.data.step3Submitted = false;
            $scope.$apply();
        };

        $scope.cleanIti = function() {
            $scope.data.iti.setCountry('us');
            $scope.data.iti.setNumber('');
            $('#phone-number-input-payment-form').unmask();
            $scope.data.iti.destroy();
            $scope.data.iti = undefined;
        };

        $scope.cleanStep2 = function() {
            $scope.data.primaryAccountHolder.firstName = '';
            $scope.data.primaryAccountHolder.lastName = '';
            $scope.data.primaryAccountHolder.companyName = '';
            $scope.data.primaryAccountHolder.email = '';
            $scope.data.primaryAccountHolder.phoneNumber = '';
            $scope.data.isPhoneNumberValid = undefined;

            if($scope.data.iti) {
                $scope.cleanIti();
            }

            $scope.data.isStep2Valid = undefined;
            $scope.data.step2Submitted = false;
            $scope.$apply();
        };

        $scope.cleanStep1 = function() {
            $scope.data.subscriptionSummary = [];
            $scope.data.subscriptionSubtotalPrice = 0;
            $scope.data.subscriptionSubtotalDescription = '';
            $scope.data.couponCode = '';
            $scope.data.checkingCoupon = false;
            $scope.data.isCouponCodeCorrect = undefined;
            $scope.data.oneTimeCharges = [];
            $scope.data.oneTimeChargesSubtotalPrice = 0;
            $scope.data.oneTimeChargesSubtotalDescription = '';
            $scope.data.recommendedAddOns = [];

            $scope.data.isStep1Valid = undefined;
            $scope.data.step1Submitted = false;
            $scope.$apply();
        };

        $('#customShoppingCartModal').on('hidden.bs.modal', function () {
            switch($scope.data.step) {
                case '1':
                    $scope.cleanStep1();
                    break;
                case '2':
                    $scope.cleanStep1();
                    $scope.cleanStep2();
                    break;
                case '3':
                    $scope.cleanStep1();
                    $scope.cleanStep2();
                    $scope.cleanStep3();
                    break;
                case '4':
                    $scope.cleanStep1();
                    $scope.cleanStep2();
                    $scope.cleanStep3();
                    $scope.cleanStep4();
                    break;
            }
            $scope.data.step = '1';
            $scope.data.loading = false;
            $scope.data.displayErrorAlert = false;
            $scope.data.errorAlertMessage = '';
        });

        $scope.initShoppingCart = function(plan, subscriptionSummaryArray, oneTimeChargesArray, recommendedAddOnsArray) {
            $scope.data.planId = (plan || 'monthly-plan');
            $scope.data.billingTerm = plan.indexOf('monthly') !== 1 ? 'monthly' : 'annual';
            $scope.data.subscriptionSummary = subscriptionSummaryArray;
            $scope.data.subscriptionSubtotalPrice = $scope.calcSubtotalPrice($scope.data.subscriptionSummary);
            $scope.data.subscriptionSubtotalDescription = ($scope.data.planId !== 'monthly-plan') ? $scope.staticText.step1.subscriptionAnnual : $scope.staticText.step1.subscriptionMonthly;
            $scope.data.oneTimeCharges = oneTimeChargesArray;
            $scope.data.oneTimeChargesSubtotalPrice = $scope.calcSubtotalPrice($scope.data.oneTimeCharges);
            $scope.data.oneTimeChargesSubtotalDescription = $scope.staticText.step1.oneTimeChargesSubtotalSubtitle;
            $scope.data.recommendedAddOns = recommendedAddOnsArray;

            $('#customShoppingCartModal').modal('show');
        };

        $scope.$on('cshowShoppingCartEvent', function(event, plan, subscriptionSummaryArray, oneTimeChargesArray, recommendedAddOnsArray) {
            $scope.initShoppingCart(plan, subscriptionSummaryArray, oneTimeChargesArray, recommendedAddOnsArray);
        });
    }
    module.exports = CustomShoppingCartModalController;
})();
