(function () {
    'use strict';

    function DncController($rootScope, $scope, authService, importService , dncService, notificationToastService, $window, $interval, $http) {

        var vm = this;
        vm.authService = authService;

        $rootScope.viewData = $rootScope.viewData || {};
        $rootScope.viewData.breadcrumbs = $rootScope.viewData.breadcrumbs || [];
        $rootScope.viewData.breadcrumbs = [{
            title: 'DNC',
            link_name: 'DNC',
            link_url: '#/dnc',
            help_url: $rootScope.help_url
        }];

        $scope.staticText = {
            title: "DNC",
            subtitle: "Block all communication to these phone numbers...",
            addBtnText: "Add Phone Number",
            importBtnText: "Bulk Import",
            uploading: "Uploading",
            saving: "Saving",
            table: {
                header: ["DNC Phone Numbers", "Reason", "Date"],
                noRes: "No Results",
                loading: "Loading"
            },
            addSingleDNC: {
                title: "Add Phone Number",
                phoneNumberBlock: {
                    title: "DNC Phone Number",
                    subtitle: "The phone number you wish to block..."
                },
                reasonBlock: {
                    title: "Reason",
                    subtitle: "The reason that you're blocking the phone number..."
                },
                submitBtn: "Add",
                phoneErrorMessage: "Phone number is not valid"
            },
            importDNC: {
                name: {
                    title: "List Name",
                    subtitle: "Enter a name for your DNC list",
                    placeholder: "My List Name"
                },
                title: "Import DNC Phone Numbers",
                importFileBlock: {
                    title: "Import DNC Phone Numbers",
                    subtitle: "Drop a CSV, TSV, or TAB file containing your contacts below",
                    desc: "(You can export csv from your CRM or lead capture software. Upload limit is 1 million rows per csv.)"
                },
                checkFileBlock: {
                    title: "Describe Your Spreadsheet",
                    clearBtn: "Undo Upload",
                    subtitle: "We need to know which column contains the phone numbers to add to your DNC...",
                    steps: [
                        "For each column in your spreadsheet...",
                        "Select a label that best describes the column."
                    ],
                    checkboxLabel: "The first row of my data is a header row",
                    country: {
                        title: "Choose a country",
                        subtitle: "What country are your phone numbers in..."
                    }
                },
                submitBtn: "Submit",
                requiredErrorMessage: "The import should contains at least one row.",
                mappedErrorMessage: "The fields are not mapped. Please select columns under the list."
            }
        };

        $scope.loading = {
            dnc_list: true,
            uploading: false,
            saving: false
        };

        $scope.data = {
            dnc_list: [],
            total_items: 0,
            add_single_dnc: {
                phone_number: null,
                reason: "",
                is_valid_phone_number: undefined,
                is_valid: false
            },
            import_dnc: {
                file: null,
                csv: null,
                csv_preview: null,
                csv_headers: null,
                csv_has_header_row: false,
                is_valid: false,
                is_valid_required: false,
                is_valid_mapped: false,
                country: {
                    selected: {
                        code: 'US',
                        name: "United States"
                    }
                },
                countries: []
            }
        };

        $scope.fieldTypeMapping = {
            'Phone Number': 'main_phone',
            'Reason': 'reason'
        };

        $scope.dropify = {
            options: {},
            events: {}
        };

        $scope.currentPage = 1;
        $scope.itemsPerPage = 8;
        $scope.maxSize = 3;

        // Basic
        $('.dropify-csv').dropify({
            messages: {
                'default': 'Drag and drop a csv file here or click',
                'replace': 'Drag and drop or click to replace',
                'remove':  'Remove',
                'error':   'Please check file format and try again.'
            },
            error: {
                'fileExtension': 'The file format is not supported.'
            }
        });

        var drEvent = $('#import-file-events').dropify();

        drEvent.on('dropify.beforeClear', function(event, element) {
            return confirm("Do you really want to delete \"" + element.file.name + "\" ?");
        });

        drEvent.on('dropify.afterClear', function(event, element) {
            alert('File deleted');
        });

        drEvent.on('dropify.errors', function(event, element) {
            console.log('Has Errors');
        });

        $('.dropify-csv').change(function(event) {
            console.log('File ready');

            var target = event.currentTarget || {};
            if (target && target.files && target.files.length) {

                var file = target.files[0];

                var parts = (file.name || '').split('.');
                var ext = (parts[parts.length - 1] || '').toLowerCase();
                if (ext === 'csv' || ext === 'tab' || ext === 'tsv') {

                    $scope.data.import_dnc.file = file;

                    var rows = [];
                    Papa.parse(file, {
                        config: {
                            worker: true
                        },
                        step: function(results, parser) {
                            rows.push(results.data[0]);
                            if (rows.length > 4) {
                                parser.abort();
                            }
                        },
                        complete: function(results) {
                            $scope.data.import_dnc.csv = rows;
                            $scope.data.import_dnc.csv_preview = rows.slice(0, 4);

                            var headers = [];
                            var colHeaders = rows[0] || [];
                            for (var i = 0; i < colHeaders.length; i++) {
                                headers.push(null);
                            }
                            $scope.data.import_dnc.csv_headers = headers;
                            $scope.validateStep();
                            $scope.$apply();
                        }
                    });
                }
            }
        });

        $scope.showImport = function() {
            $scope.clearImport();
            $scope.data.import_dnc.country = {
                selected: {
                    code: 'US',
                    name: "United States"
                }
            };
            $scope.validateStep();

            angular.element('.dropify-clear').triggerHandler('click');
            $('#importDncModal').modal('show');
        };

        $scope.cancelImport = function() {
            $scope.clearImport();
            $('#importDncModal').modal('hide');
        };

        $scope.clearUpload = function ($event) {
            $event.preventDefault();
            $scope.clearImport();

            $scope.validateStep();
            angular.element('.dropify-clear').triggerHandler('click');
        };

        $scope.clearImport = function () {
            $scope.data.import_dnc.csv = null;
            $scope.data.import_dnc.csv_preview = null;
            $scope.data.import_dnc.csv_headers = null;
            $scope.data.import_dnc.csv_has_header_row = false;
            $scope.data.import_dnc.file = null;
        };

        $scope.mapHeader = function(index, value) {
            $scope.data.import_dnc.csv_headers[index] = value;
            $scope.validateStep();
        };

        $scope.__phoneMapped = function() {
            var headers = $scope.data.import_dnc.csv_headers || [];
            for (var i = 0; i < headers.length; i++) {
                if (headers[i] == 'Phone Number') {
                    return true;
                }
            }
            return false;
        };

        $scope.__reasonMapped = function() {
            var headers = $scope.data.import_dnc.csv_headers || [];
            for (var i = 0; i < headers.length; i++) {
                if (headers[i] == 'Reason') {
                    return true;
                }
            }
            return false;
        };

        $scope.__csvHasAtLeastOneRow = function() {
            var minCount = $scope.data.import_dnc.csv_has_header_row ? 1 : 0;
            return (($scope.data.import_dnc.csv || []).length > minCount);
        };

        $scope.validateStep = function() {
            $scope.data.import_dnc.is_valid_required = $scope.__csvHasAtLeastOneRow();
            $scope.data.import_dnc.is_valid_mapped = $scope.__phoneMapped();
            var csvValid = ($scope.data.import_dnc.is_valid_required && $scope.data.import_dnc.is_valid_mapped);
            $scope.data.import_dnc.is_valid = !!(csvValid);
        };

        $scope.__toColLetters = function(value) {
            var colName = '';
            var dividend = Math.floor(Math.abs(value));
            var rest;

            while (dividend > 0) {
                rest = (dividend - 1) % 26;
                colName = String.fromCharCode(65 + rest) + colName;
                dividend = parseInt((dividend - rest) / 26);
            }
            return colName;
        };

        $scope.__buildFieldMeta = function() {
            let results = [];

            const headerRow = ($scope.data.import_dnc.csv || [])[0] || [];

            const headers = $scope.data.import_dnc.csv_headers || [];
            for (let i = 0; i < headers.length; i++) {

                const header = headers[i];
                const typeMapping = $scope.fieldTypeMapping[header];

                const fieldName = ((($scope.data.import_dnc.csv_has_header_row) ? headerRow[i] : 'Column ' + $scope.__toColLetters(i + 1)) || '').trim();
                const displayName = typeMapping ? header : fieldName;

                results.push({
                    type: typeMapping || null,
                    field_name: fieldName || "",
                    display_name: displayName || ""
                });
            }

            return results;
        };

        $scope.deleteDNC = function(dnc, index) {
            if ($scope.isSelfAdded(dnc)) {
                return;
            }

            swal("Are you sure?", "Removing contacts that requested to be on your DNC may increase your legal liability.", { buttons: true, dangerMode: true })
                .then(confirm => {
                    if (!confirm) {
                        return;
                    }

                    let req = {
                        team_id: authService.getTeamId(),
                        user_id: authService.getUserId(),
                        phone_number: dnc.phone_number,
                    };

                    $scope.loading.dnc_list = true;

                    dncService.deleteOne(req)
                        .then(results => {
                            swal("Success", `Number ${dnc.phone_number} deleted from DNC list`, "success");
                            $scope.data.dnc_list.splice(index, 1);
                            $scope.loading.dnc_list = false;
                            $scope.$apply();
                        })
                        .catch(err => {
                            swal("Cancelled", `Unable to remove number ${dnc.phone_number} from DNC list`, "error");
                            $scope.loading.dnc_list = false;
                        });
                });
        };

        $scope.hasPermissions = function(entity, action) {
            return $rootScope.hasPermissions(entity, action)
        };

        $scope.hasPermissions = function(entity, action) {
            return $rootScope.hasPermissions(entity, action)
        };

        $scope.createImport = function() {
            if (!$scope.data.import_dnc.is_valid || $scope.loading.uploading) {
                return;
            }

            $scope.loading.uploading = true;

            var teamId = authService.getTeamId();
            var userId = authService.getUserId();

            const request = {
                team_id: teamId,
                user_id: userId,
                import_type: "dnc",
                start_row: $scope.data.import_dnc.csv_has_header_row ? 1 : 0,
                filename: $scope.data.import_dnc.file.name,
                field_map: $scope.__buildFieldMeta(),
                country_iso: $scope.data.import_dnc.country.selected.code
            };

            importService.createImport(request)
                .then(results => {
                    $scope.__uploadFile($scope.data.import_dnc.file, results.policy, results.import_id);
                })
                .catch(err => {
                    $scope.loading.uploading = false;
                    $scope.$apply();
                    swal("Server Error", "Our server failed to respond. You may have too many columns in your spreadsheet.", "error");
                    $scope.cancelImport();
                });
        };

        $scope.__uploadFile = function(file, policy, importId) {
            $.ajax({
                type: 'PUT',
                url: policy.url,
                contentType: policy.content_type,
                processData: false,
                data: file
            })
                .done(function(data, textStatus, jqXHR) {
                    $scope.__startImport(importId);
                })
                .fail(function(jqXHR, textStatus, errorThrown) {
                    swal("Failure", "Your file failed to upload.  Please try again.", "error");
                    console.log(arguments);

                    $scope.loading.uploading = false;
                    $scope.$apply();
                    $scope.cancelImport();
                });
        };

        $scope.__startImport = function(importId) {

            const request = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                import_id: importId
            };

            importService.startImport(request)
                .then(results => {
                    $scope.loading.uploading = false;
                    $scope.$apply();
                    $('#importDncModal').modal('hide');
                    setTimeout(function() {
                        $scope.loadDncList(0, $scope.itemsPerPage);
                        notificationToastService.showSuccessToast('fa-light fa-circle-check', 'Your DNC list has been updated!');
                    }, 5000);
                })
                .catch(err => {
                    $scope.loading.uploading = false;
                    $scope.$apply();
                    swal("Server Error", "Our server failed to respond. You may have too many columns in your spreadsheet.", "error");
                    $scope.cancelImport();
                });
        };



        $scope.showSingleDnc = function() {
            if($scope.iti)
                $scope.addSinglePhoneNumberClearModal();
            $scope.data.add_single_dnc.reason = '';
            $scope.validateAddSingleDncStep();

            $('#addSingleDncModal').modal('show');
            setTimeout(function() {
                $scope.phoneNumberMask();
            }, 300);

        };

        $scope.addSinglePhoneNumberClearModal = function() {
            $scope.data.add_single_dnc.is_valid_phone_number = undefined;
            $scope.iti.setNumber("");
            $("#addSingleDncPhoneNumber").unmask();
            $scope.iti.destroy();
        };

        $scope.addSinglePhoneNumber = function() {
            if (!$scope.data.add_single_dnc.is_valid || $scope.loading.saving) {
                return;
            }

            var teamId = authService.getTeamId();
            var userId = authService.getUserId();
            $scope.loading.saving = true;

            const request = {
                team_id: teamId,
                user_id: userId,
                phone_number: $scope.data.add_single_dnc.phone_number,
                reason: $scope.data.add_single_dnc.reason,
                method: 'admin'
            };

            dncService.createOne(request)
                .then(results => {
                    if (Array.isArray(results) && results.length === 3 &&
                        results[0].success && results[2]) {
                        swal("Informing", results[2].information_message, "info");
                    }
                    $scope.loading.saving = false;
                    $scope.$apply();
                    $('#addSingleDncModal').modal('hide');
                    $scope.loadDncList(0, $scope.itemsPerPage);
                })
                .catch(err => {
                    console.log(err);
                    $scope.loading.saving = false;
                    $scope.$apply();
                    swal("Server Error", "Something went wrong or phone number already exists.", "error");
                    $scope.cancelImport();
                });
        };

        $scope.formatPhoneInput = function() {
            if($scope.iti) {
                $scope.data.add_single_dnc.is_valid_phone_number = $scope.iti.isValidNumber();

                if($scope.data.add_single_dnc.is_valid_phone_number) {
                    $scope.data.add_single_dnc.phone_number = $scope.iti.getNumber(window.intlTelInput.utils.numberFormat.E164);
                }
                $scope.validateAddSingleDncStep();
            }
        };

        $scope.formatPhone = function(val) {
            return $rootScope.libPhoneFormatPhone(val);
        };

        $scope.setPhoneMask = function(elem, mask, placeholder) {
            elem.mask(mask, {
                placeholder: placeholder
            });
        };

        $scope.phoneNumberMask = function() {
            var phoneNumberId = $("#addSingleDncPhoneNumber");
            var phoneNumberIdInput = document.querySelector("#addSingleDncPhoneNumber");

            if(phoneNumberId) {
                $scope.iti = window.intlTelInput(phoneNumberIdInput, {
                    nationalMode: 'false',
                    autoPlaceholder: 'aggressive',
                    separateDialCode: 'true',
                    validationNumberTypes: [
                        'FIXED_LINE',
                        'MOBILE',
                        'FIXED_LINE_OR_MOBILE',
                        'TOLL_FREE',
                        'PREMIUM_RATE',
                        'SHARED_COST',
                        'VOIP',
                        'PERSONAL_NUMBER',
                        'PAGER',
                        'UAN',
                        'VOICEMAIL',
                        'UNKNOWN'
                    ],
                    initialCountry: 'us',
                    // intl-tel-input is up to date as of dec 2024
                    loadUtils: () => import("https://cdn.jsdelivr.net/npm/intl-tel-input@25.2.0/build/js/utils.js"),
                    customPlaceholder: function(selectedCountryPlaceholder, selectedCountryData) {
                        var pl = selectedCountryPlaceholder.replace(/[0-9]/g, '0');
                        phoneNumberId.attr('maxlength', pl.length);
                        $scope.setPhoneMask(phoneNumberId, pl, pl);
                        return pl;
                    }
                });

                phoneNumberId.on("countrychange", function(e) {
                    $scope.iti.setNumber("");
                    phoneNumberId.unmask();

                    var mask = e.target.placeholder.replace(/[0-9]/g, '0');
                    phoneNumberId.attr('maxlength', mask.length);
                    $scope.setPhoneMask(phoneNumberId, mask, e.target.placeholder);
                });
            }
        };

        $scope.validateAddSingleDncStep = function() {
            $scope.data.add_single_dnc.is_valid = !!$scope.data.add_single_dnc.is_valid_phone_number;
        };



        $scope.setPage = function (pageNo) {
            $scope.currentPage = pageNo;
        };

        $scope.pageChanged = function() {
            $window.scrollTo(0, 0);
            $scope.loadDncList((($scope.currentPage - 1) * $scope.itemsPerPage), $scope.itemsPerPage);
        };

        $scope.setItemsPerPage = function(num) {
            $scope.itemsPerPage = num;
            $scope.currentPage = 1;
        };

        $scope.loadDncList = function(skip, limit) {
            $scope.loading.dnc_list = true;

            var teamId = authService.getTeamId();
            var userId = authService.getUserId();

            const request = {
                team_id: teamId,
                user_id: userId,
                skip: skip,
                limit: limit
            };

            dncService.getLists(request)
                .then(results => {
                    $scope.loading.dnc_list = false;
                    $scope.data.dnc_list = results.data || [];
                    $scope.data.total_items = results.total_count;
                    $scope.$apply();
                })
                .catch(err => {
                    console.error(err);
                    $scope.loading.dnc_list = false;
                    $scope.$apply();
                });
        };

        $scope.isSelfAdded = (dncContact) => {
            return dncContact.method === "sms" && dncContact.reason === "STOP keyword" ||
                dncContact.method === 'voice'  && dncContact.reason === 'opt-out via keypress';
        }



        $scope.onInit = function() {
            $scope.loadDncList(0, $scope.itemsPerPage);

            $http.get('assets/content/countries.json')
                .then(function(res){
                    $scope.data.import_dnc.countries = res.data;
                });
        };

        if (authService.hasAccount()) {
            $scope.onInit();
        }
        else {
            $scope.$on('auth_complete', function(event, args) {
                $scope.onInit();
            });
        }
    }
    module.exports = DncController;
})();
