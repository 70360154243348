(function() {
    'use strict';

    function ContactDetailsProfileController(
        $rootScope, $scope, authService, contactService, phoneService, dispatcherService,
        $window, $location, $cookies, integrationService, faxService, tagsService, tasksService,
        notificationToastService, storeSelectedContactService, generalHelperService) {
        var vm = this;

        $scope.staticContent = {
            profileDetails: {
                actions: [ 'Dial', 'RVM', 'Fax', 'Download', 'Add Tag', 'Add Note', 'Add Document', 'Save'],
                infoTitle: [ 'Email Address', 'Phone', 'Secondary Phone', 'Address', 'Notes', 'Fax', 'Documents', 'Tags', 'Opt-in Date', 'Opt-in Source' ],
                documents: {
                    header: [ 'Name', 'Date' ],
                    actions: [ 'Download', 'Delete' ]
                } ,
                menu: [ 'Edit Contact', 'Add to / Remove from List', 'Dial', 'RVM', 'Fax' ],
                noTags: 'No Tags',
                noNotes: 'No Notes',
                noDocuments: 'No Documents'
            },
            loading: 'Loading',
            saving: 'Saving',
            noRes: 'No Results',
            noDocumentError: 'Drag and drop a file'
        };
        $scope.defaultAvatar = $rootScope.settings.NO_AVATAR_IMG;

        $scope.contactDetails = null;

        $scope.noteData = {
            add : ''
        };

        $scope.documentData = {
            file : null,
            isSubmitted: false
        };

        $scope.data = {
            mainFiledsDisplayLimit: 5,
            tags: [],
            availableTags: []
        };

        $scope.loading = {
            mainData: false,
            profile: true,
            addNote: false,
            addDocument: false,
            addRemoveTags: false
        };

        $scope.ddState = {
            showLists: false,
            shownLists: false,
            action: ''
        };

        $scope.showCallPanel = function() {
            $rootScope.$broadcast('openCallTab', $scope.contactDetails);
        };

        $scope.sendFax = function() {
            $rootScope.showFaxModal({ contact: $scope.contactDetails });
        };

        $scope.sendRVM = function() {
            $rootScope.showRvmModal($scope.contactDetails);
        };

        $scope.downloadDocument = function(document_id) {
            const request = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                document_id: document_id
            };
            faxService.getDocumentUrl(request)
                .then(result => {
                    if (!result || !result.url) { throw new Error(); }
                    window.open(result.url, '_blank');
                })
                .catch(err => {
                    console.log(err);
                    notificationToastService.showErrorToast('fa-light fa-triangle-exclamation', 'The document could not be retrieved.');
                });
        };

        $scope.createNote = function() {
            $scope.noteData.add.trim();
            if($scope.noteData.add !== '') {
                $scope.loading.addNote = true;

                const teamId = authService.getTeamId();
                const userId = authService.getUserId();

                const noteReq = {
                    team_id: teamId,
                    user_id: userId,
                    type: 'contact',
                    note: $scope.noteData.add,
                    contact_id: $location.search().id
                };

                contactService.setContactNotes(noteReq)
                    .then(note => {
                        $scope.contactDetails.notes.unshift(note);
                        $scope.closeAddContainer('.nav-link.add-note-btn');
                        $scope.noteData.add = '';
                        $scope.loading.addNote = false;
                        $scope.$apply();
                        notificationToastService.showSuccessToast('fa-light fa-circle-check', 'Note has been added!');
                    })
                    .catch(err => {
                        console.error(err);
                        $scope.loading.addNote = false;
                        $scope.$apply();
                        notificationToastService.showErrorToast('fa-light fa-triangle-exclamation', 'Something went wrong. Try again later.');
                    });
            }
        };

        $scope.editNote = function(note) {
            note.newEdit.trim();
            if(note.newEdit !== '') {
                note.editNoteSaving = true;

                const teamId = authService.getTeamId();
                const userId = authService.getUserId();

                const noteReq = {
                    team_id: teamId,
                    user_id: userId,
                    note_id: note.note_id,
                    note: note.newEdit,
                    contact_id: $location.search().id
                };

                contactService.editContactNotes(noteReq)
                    .then(results => {
                        storeSelectedContactService.updateContactNotes(note, 'edit');
                        $scope.cancelEditNote(note);
                        $scope.$apply();
                        notificationToastService.showSuccessToast('fa-light fa-circle-check', 'Note has been updated!');
                    })
                    .catch(err => {
                        console.error(err);
                        note.editNoteSaving = false;
                        $scope.$apply();
                        notificationToastService.showErrorToast('fa-light fa-triangle-exclamation', 'Something went wrong. Try again later.');
                    });
            }
        };

        $scope.deleteNote = function(note) {
            const teamId = authService.getTeamId();
            const userId = authService.getUserId();

            const noteReq = {
                team_id: teamId,
                user_id: userId,
                note_id: note.note_id,
                contact_id: $location.search().id
            };

            contactService.deleteContactNotes(noteReq)
                .then(results => {
                    note.note = $scope.noteData.edit;
                    storeSelectedContactService.updateContactNotes(note, 'deleteEntry');
                    $scope.cancelEditNote(note);
                    $scope.$apply();
                    notificationToastService.showSuccessToast('fa-light fa-trash-check', 'Note has been deleted!');
                })
                .catch(err => {
                    console.error(err);
                    $scope.$apply();
                    notificationToastService.showErrorToast('fa-light fa-triangle-exclamation', 'Something went wrong. Try again later.');
                });
        };

        $scope.showEditNote = function(note) {

            generalHelperService.waitForElement('#edit-note-container-' + note.note_id)
                .then((editNoteDiv) => {
                    editNoteDiv.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' });
                });

            note.newEdit = _.cloneDeep(note.note);
            note.editNoteSaving = false;
            note.editNote = true;
        };

        $scope.cancelEditNote = function(note) {
            note.editNoteSaving = false;
            note.editNote = false;
            note.newEdit = '';
        };

        $scope.closeAddContainer = function(elem) {
            var href = $(elem).attr('href').substring(1);
            $(elem).removeClass('active');
            $(elem).removeClass('show');
            $('.tab-pane[id="'+ href +'"]').removeClass('active');
            $('.tab-pane[id="'+ href +'"]').removeClass('show');
            $(elem).blur();
        };

        $(document).on('click','.nav-link.active.add-note-btn', function() {
            $scope.closeAddContainer(this);
        });

        $scope.addDocument = function() {
            $scope.documentData.isSubmitted = true;
            if($scope.documentData.file !== null) {
                $scope.loading.addDocument = true;

                const docReq = {
                    team_id: authService.getTeamId(),
                    user_id: authService.getUserId(),
                    document_type: 'general',
                    filename: $scope.documentData.file.name,
                    content_type: $scope.documentData.file.type,
                    contact_id: $scope.contactDetails.contact_id,
                    parent: 'contact'
                };

                faxService.createDocument(docReq)
                    .then(result => {
                        docReq.document_id = result.document_id;
                        return $scope.__uploadFile($scope.documentData.file, result.policy);
                    })
                    .then(() => {
                        docReq.created_at = moment.now();
                        $scope.contactDetails.documents.unshift(docReq);
                        $scope.closeAddContainer('.nav-link.add-document-btn');
                        $scope.clearUpload();
                        $scope.loading.addDocument = false;
                        $scope.$apply();
                        notificationToastService.showSuccessToast('fa-light fa-circle-check', 'Document has been added!');
                    })
                    .catch(err => {
                        console.error(err);
                        $scope.loading.addDocument = false;
                        $scope.$apply();
                        notificationToastService.showErrorToast('fa-light fa-triangle-exclamation', 'Unable to add Document. Please try again later.');
                    });
            }
        };

        $scope.__uploadFile = function(file, policy) {
            return new Promise((resolve, reject) => {
                $.ajax(policy.url, {
                    method: 'PUT',
                    contentType: 'application/octet-stream',
                    processData: false,
                    data: file
                })
                    .done(function(data, textStatus, jqXHR) {
                        resolve(true);
                    })
                    .fail(function(jqXHR, textStatus, errorThrown) {
                        reject('Your file could not be uploaded. Please try again.');
                    });
            });
        };

        $scope.updateDocumentFilename = function(document) {

            var docReq = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                document_id: document.document_id,
                filename: document.filename
            };

            faxService.updateDocument(docReq)
                .then(results => {
                    $scope.$apply();
                    notificationToastService.showSuccessToast('fa-light fa-circle-check', 'Document name has been updated!');
                })
                .catch(err => {
                    console.error(err);
                    $scope.$apply();
                    notificationToastService.showErrorToast('fa-light fa-triangle-exclamation', 'Unable to rename Document. Please try again later.');
                });
        };

        $scope.newDocumentFilename = '';
        $scope.editDocumentFilename = function(document) {
            if($scope.newDocumentFilename !== document.filename) {
                $scope.newDocumentFilename = document.filename;
                $scope.updateDocumentFilename(document);
            }
        };

        $scope.deleteDocument = function(e, document) {
            if (e) {
                e.preventDefault();
            }

            swal({
                title: 'Delete Document',
                text: 'Are you sure you want to delete this document?',
                buttons: ['No', 'Yes'],
                dangerMode: true,
                closeOnClickOutside: false
            }).then((confirm) => {
                if (!confirm) { return; }

                const docReq = {
                    team_id: authService.getTeamId(),
                    user_id: authService.getUserId(),
                    document_id: document.document_id
                };

                faxService.deleteDocument(docReq)
                    .then(results => {
                        $scope.contactDetails.documents = $scope.contactDetails.documents.filter(function( obj ) {
                            return obj.document_id !== document.document_id;
                        });
                        $scope.$apply();
                        notificationToastService.showSuccessToast('fa-light fa-trash-check', 'Document has been deleted!');
                    })
                    .catch(err => {
                        console.error(err);
                        $scope.$apply();
                        notificationToastService.showErrorToast('fa-light fa-triangle-exclamation', 'The document could not be deleted. Please try again later.');
                    });
            });
        };

        $(document).on('click','.nav-link.active.add-document-btn', function() {
            $scope.closeAddContainer(this);
            $scope.clearUpload();
        });

        $scope.editContact = function() {
            $rootScope.$broadcast('editContactEvent', $scope.contactDetails.contact_id, null);
        };
        $scope.$on('addEditContactEventDone', function(event, mode, isSuccess) {
            if(isSuccess && mode === 'edit') {
                $scope.getContact($scope.contactDetails.contact_id);
            }
        });

        $scope.hasPermissions = function(entity, action) {
            return $rootScope.hasPermissions(entity, action)
        };

        $scope.updateListInSearchResults = function(list, add, contact) {
            var teamId = authService.getTeamId();
            var userId = authService.getUserId();

            const request = {
                team_id: teamId,
                user_id: userId,
                list_id: list.list_id,
                remove_list: !add
            };

            if (!contact) {
                if ($location.search().searchTerm) {
                    request.search_term = $location.search().searchTerm;
                }
                if ($scope.data.selectedListId) {
                    request.previous_list_id = $scope.data.selectedListId;
                }
                if ($scope.data.selectedOutcomeId) {
                    request.outcome_id = $scope.data.selectedOutcomeId;
                }
            } else {
                request.contact_ids = [contact.contact_id];
            }

            contactService.addToList(request)
                .then(results => {
                    const msg = (add ? 'Added to' : 'Removed from');
                    var resNotification = msg + ' List: ' + contact.first_name + ' ' + contact.last_name + ' has been ' + msg.toLowerCase() + ' campaign ' + list.list_name;
                    notificationToastService.showSuccessToast('fa-light fa-circle-check', resNotification);

                    const listIndex = (contact.list_ids || []).indexOf(list.list_id);
                    if (listIndex === -1 && add) {
                        if (!Array.isArray(contact.list_ids)) {
                            contact.list_ids = [];
                        }
                        contact.list_ids.push(list.list_id);
                        contact.listNames += list.list_name + '<br>';
                    } else if (listIndex >= 0 && !add) {
                        contact.list_ids.splice(listIndex, 1);
                        if(contact.listNames) {
                            contact.listNames = contact.listNames.replace(list.list_name + '<br>', '');
                        }
                    }
                    $scope.$apply();
                })
                .catch(err => {
                    console.error(err);
                    $scope.$apply();
                });
        };

        $scope.contactDdAction = function(list) {
            switch ($scope.contactDetails.ddState.action) {
                case 'list':
                    const toAdd = ( ($scope.contactDetails.list_ids || []).indexOf(list.list_id) === -1 );
                    $scope.updateListInSearchResults(list, toAdd, $scope.contactDetails);
                    return;
            }
        };

        $scope.addDropdownListener = function(element, state) {
            element.on('hidden.bs.dropdown', function() {
                if (state.showLists) {
                    state.showLists = false;
                    state.shownLists = true;
                    element.find('.dropdown-toggle').dropdown('toggle');
                } else {
                    state.shownLists = false;
                    state.action = '';
                }
                $scope.$apply();
            });
        };

        $scope.addTag = function (index, tag) {
            document.getElementById('contact-tag-list').scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' });

            $scope.loading.addRemoveTags = true;
            const teamId = authService.getTeamId();
            const userId = authService.getUserId();

            const request = {
                team_id: teamId,
                user_id: userId,
                contact_id: $scope.contactDetails.contact_id,
                tag_id: tag.tag_id,
                action: 'add'
            };

            contactService.updateContactTag(request)
                .then(tags => {
                    $scope.contactDetails.tags = tags;
                    $scope.data.availableTags.splice(index, 1)
                    $scope.loading.addRemoveTags = false;
                    $scope.$apply();
                })
        }

        $scope.getTags = function () {
            return $scope.contactDetails.tags.filter(contactTag => {
                const availableTag = $scope.data.tags.find(tag => tag.tag_id === contactTag.tag_id);
                if (typeof availableTag !== 'undefined') {
                    contactTag.label = availableTag.label;
                    contactTag.color = availableTag.color;
                    contactTag.text_color = availableTag.text_color;
                    return true;
                }
                return false;
            })
        }

        $scope.deleteTag = function (tag) {
            $scope.loading.addRemoveTags = true;
            const teamId = authService.getTeamId();
            const userId = authService.getUserId();

            const request = {
                team_id: teamId,
                user_id: userId,
                contact_id: $scope.contactDetails.contact_id,
                tag_id: tag.tag_id,
                action: 'remove',
            };

            contactService.updateContactTag(request)
                .then(tags => {
                    $scope.contactDetails.tags = tags;
                    const tagToAdd = $scope.data.tags.find(tagToAdd => tagToAdd.tag_id === tag.tag_id);
                    if (typeof tagToAdd !== 'undefined') {
                        $scope.data.availableTags.push(tagToAdd);
                    }
                    $scope.loading.addRemoveTags = false;
                    $scope.$apply();
                })
        }

        $scope.updateNoteText = function(note) {
            note.note = _.cloneDeep(note.newEdit);
        };

        $scope.addNoteToList = function(note) {
            $scope.contactDetails.notes.push(note);
        };

        $scope.removeNoteFromList = function(note) {
            $scope.contactDetails.notes = _.remove($scope.contactDetails.notes, function (n) {
                return n.note_id !== note.note_id;
            });
        };

        $scope.unpinNote = function(note) {
            const action = 'unpin';
            const req = {
                contact_id: $scope.contactDetails.contact_id,
                note_id: note.note_id,
                action
            };

            return tasksService.pinUnpinNote(req)
                .then(() => {
                    storeSelectedContactService.updateContactNotes(note, 'remove');
                    $scope.$apply();
                })
                .catch(err => {
                    console.error(err);
                    $scope.$apply();
                    notificationToastService.showErrorToast('fa-light fa-triangle-exclamation', 'Something went wrong. Try again later.');
                })
        };

        $scope.loadLists = function() {

            var teamId = authService.getTeamId();
            var userId = authService.getUserId();

            const request = {
                team_id: teamId,
                user_id: userId
            };

            return contactService.getLists(request)
                .then(lists => {
                    $scope.data.lists = lists || [];
                    $scope.$apply();
                });
        };

        $scope.showOptInSourceBlock = function() {
            return !!($scope.contactDetails.opt_in_source && $scope.contactDetails.opt_in_source !== null && $scope.contactDetails.opt_in_source.length > 0);
        };
        $scope.showOptInDateBlock = function() {
            return !!($scope.contactDetails.opt_in_date && $scope.contactDetails.opt_in_date !== null && $scope.contactDetails.opt_in_date.length > 0);
        };
        $scope.showEmailBlock = function() {
            return !!($scope.contactDetails.email && $scope.contactDetails.email !==null && $scope.contactDetails.email.length > 0);
        };
        $scope.showSecondaryPhoneBlock = function() {
            return !!($scope.contactDetails.secondary_phone && $scope.contactDetails.secondary_phone !==null && $scope.contactDetails.secondary_phone.length > 0);
        };
        $scope.showMainPhoneBlock = function() {
            return !!($scope.contactDetails.main_phone && $scope.contactDetails.main_phone !==null && $scope.contactDetails.main_phone.length > 0);
        };
        $scope.showFaxNumber = function() {
            return !!($scope.contactDetails.fax_number && $scope.contactDetails.fax_number !==null && $scope.contactDetails.fax_number.length > 0);
        };
        $scope.showAddress = function() {
            return !!($scope.contactDetails.address && $scope.contactDetails.address !==null && $scope.contactDetails.address.length > 0);
        };

        $scope.setCustomFieldsDisplayItems = function() {
            var displayFieldsLength = $scope.contactDetails.displayFields.length;
            $scope.contactDetails.customFieldsDisplayItems = (displayFieldsLength > $scope.data.mainFiledsDisplayLimit) ? $scope.data.mainFiledsDisplayLimit : displayFieldsLength;
        };

        $scope.mainBlockSeeMoreLess = function() {
            var mainBlock = document.getElementById('contact-main-block');
            mainBlock.classList.add('collapse-animation-main-block');

            if(!$scope.contactDetails.showAllFields) {
                $scope.contactDetails.customFieldsDisplayItems = $scope.contactDetails.displayFields.length;
                $scope.contactDetails.showAllFields = true;
            } else {
                $scope.setCustomFieldsDisplayItems();
                $scope.contactDetails.showAllFields = false;
            }

            document.querySelector('#contact-main-block').addEventListener('transitionend', function() {
                mainBlock.classList.remove('collapse-animation-main-block');
            });
        };

        $scope.resizeContactMainBlock = function(size) {
            var mainBlock = document.getElementById('contact-main-block');
            mainBlock.style.height = size.innerHeight + 'px';
        };

        $scope.createMainDisplayField = function(type, title, value, icon) {
            var field = {
                fieldId: type,
                fieldType: type,
                title: title,
                value: value,
                icon: icon
            };
            if(type === 'address') {
                field.city = $scope.contactDetails.city;
                field.state = $scope.contactDetails.state;
                field.zip_code = $scope.contactDetails.zip_code;
                field['suite/apt'] = $scope.contactDetails['suite/apt'];
                field.country = $scope.contactDetails.country;
            }
            return field;
        };

        $scope.addMainDisplayField = function(checkFieldFunctionName, type, title, value, icon) {
            if($scope[checkFieldFunctionName]()) {
                $scope.contactDetails.displayFields.unshift($scope.createMainDisplayField(type, title, value, icon));
            }
        };

        $scope.formatContactCustomFields = function(contact) {
            let fieldData = contact.field_data || [];
            return _.sortBy($rootScope.customFields, 'sort_index').map(customField => {
                const contactCustomData = fieldData.find(field => field.type === 'custom_field' && field.custom_field_id === customField.custom_field_id);
                if (typeof contactCustomData !== 'undefined') {
                    if (customField.type === 'list') {
                        const selectedItem = customField.list_items.find(item => item.value === contactCustomData.value);
                        if (typeof selectedItem !== 'undefined') {
                            customField.value = selectedItem;
                        }
                    } else {
                        customField.value = contactCustomData.value;
                    }
                } else {
                    customField.value = null;
                }
                customField.fieldId = customField.custom_field_id;
                customField.fieldType = 'customField';
                return customField;
            })
                .filter(customField => customField.value);
        };

        $scope.loadContact = function(id, callback) {
            $scope.loading.profile = true;
            var teamId = authService.getTeamId();
            var userId = authService.getUserId();

            const request = {
                team_id: teamId,
                user_id: userId,
                contact_id: id,
            };

            contactService.getContactDetails(request)
                .then(result => {
                    if (result.status !== 200) {
                        return;
                    }
                    $scope.contactDetails = result.contact;
                })
                .catch(err => {
                    console.error(err);
                    $scope.contactDetails = {};
                })
                .then(() => {
                    if (!$scope.contactDetails) {
                        return;
                    }
                    if (!Array.isArray($scope.contactDetails.tags)) {
                        $scope.contactDetails.tags = [];
                    }
                    $scope.contactDetails.id = id;
                    $scope.contactDetails.ddState = _.cloneDeep($scope.ddState);
                    $scope.contactDetails.displayFields = [];

                    if($scope.contactDetails.field_data) {
                        const opt_in_date = $scope.contactDetails.field_data.find(field => field.type === 'opt_in_date') || {};
                        $scope.contactDetails.opt_in_date = opt_in_date.value;
                        const opt_in_source = $scope.contactDetails.field_data.find(field => field.type === 'opt_in_source') || {};
                        $scope.contactDetails.opt_in_source = opt_in_source.value;

                        $scope.contactDetails.displayFields = $scope.formatContactCustomFields($scope.contactDetails);
                    }

                    $scope.addMainDisplayField('showOptInDateBlock', 'optInDate', $scope.staticContent.profileDetails.infoTitle[8], $scope.contactDetails.opt_in_date, 'fa-light fa-calendar');
                    $scope.addMainDisplayField('showOptInSourceBlock', 'optInSource', $scope.staticContent.profileDetails.infoTitle[9], $scope.contactDetails.opt_in_source, 'fa-light fa-square-check');

                    $scope.addMainDisplayField('showAddress', 'address', $scope.staticContent.profileDetails.infoTitle[3], $scope.contactDetails.address, 'fa-light fa-location-dot');
                    $scope.addMainDisplayField('showFaxNumber', 'faxPhone', $scope.staticContent.profileDetails.infoTitle[5], $scope.contactDetails.fax_number, 'fa-light fa-fax');
                    for(var i = $scope.contactDetails.phone_numbers.length - 1; i >= 0; i--) {
                        const isMainPhone = i === 0;
                        $scope.addMainDisplayField(
                            isMainPhone ? 'showMainPhoneBlock' : 'showSecondaryPhoneBlock',
                            isMainPhone ? 'mainPhone' : 'secondaryPhone',
                            isMainPhone ? $scope.staticContent.profileDetails.infoTitle[1] : $scope.staticContent.profileDetails.infoTitle[2],
                            $scope.contactDetails.phone_numbers[i],
                            'fa-light fa-phone'
                        );
                    }
                    $scope.addMainDisplayField('showEmailBlock', 'email', $scope.staticContent.profileDetails.infoTitle[0], $scope.contactDetails.email, 'fa-light fa-at');

                    $scope.setCustomFieldsDisplayItems();
                    callback();
                });
        };

        $scope.popoverMenuClickListener = function() {
            $('.popover-menu-btn.active').not(this).popover('hide');
        };

        $scope.bodyClickListener = function(e) {
            let popoverBtn = $('.popover-menu-btn.active');
            let popovers = $('.contact-phone-popover-menu');

            if(!popoverBtn.is(e.target) && popoverBtn.has(e.target).length === 0 && !popovers.is(e.target) && popovers.has(e.target).length === 0) {
                popoverBtn.popover('hide');
                if(popovers.hasClass('show')) {
                    popovers.remove();
                }
            }
        };

        $scope.removeEventListeners = function() {
            document.body.removeEventListener('click', $scope.bodyClickListener, true);
            document.querySelector('.popover-menu-btn').removeEventListener('click', $scope.popoverMenuClickListener, true);
        };

        $scope.$on('$stateChangeStart', function() {
            $scope.closeActivePopovers();

            $scope.removeEventListeners();
        });

        $scope.closePopoversEventListeners = function() {
            document.body.addEventListener('click', $scope.bodyClickListener, true);
            document.querySelector('.popover-menu-btn').addEventListener('click', $scope.popoverMenuClickListener, true);
        };

        $scope.closeActivePopovers = function() {
            $('.popover-menu-btn.active').popover('hide');
        };

        $scope.initPopover = function(popoverId, contentId, popoverDivId, offset) {
            let templateStr = '<div class="popover contact-phone-popover-menu" id="' + popoverDivId + '" role="tooltip"><div class="popover-body"></div></div>';
            $('#' + popoverId).popover({
                container: '.contact-scroll-div.active-scroll',
                placement: 'bottom',
                html: true,
                content: $('#' + contentId),
                template: templateStr,
                trigger: 'click',
                offset: offset
            });
        };

        $scope.phonePopoverInitListeners = function() {
            let popoverId = 'phone-menu-dropdown';
            let contentId = 'phone-menu-popover';
            let popoverDivId = 'phone-item-popover';
            let parentId = 'phone-menu';

            $scope.initPopover(popoverId, contentId, popoverDivId, '-61px, 0');

            $('#' + popoverId).on('hide.bs.popover', function() {
                $('#' + parentId).removeClass('show');
                $('#' + popoverId).removeClass('active');
            });
            $('#' + popoverId).on('show.bs.popover', function() {
                $('#' + parentId).addClass('show');
                $('#' + popoverId).addClass('active');
            });
        };

        $scope.loadMainData = function(contactId) {
            tagsService.getTags().then(tags => {
                $scope.data.tags = tags;
                $scope.loadContact(contactId, function() {
                    $scope.initDocumentDropify();
                    $scope.data.availableTags = $scope.data.tags.filter(tag => typeof $scope.contactDetails.tags.find(contactTag => contactTag.tag_id === tag.tag_id) === 'undefined');

                    const contactDd = angular.element('#contact-dd');
                    $scope.addDropdownListener(contactDd, $scope.contactDetails.ddState);

                    $scope.loading.profile = false;
                    $scope.loading.mainData = false;
                    $scope.$apply();

                    $scope.phonePopoverInitListeners();
                    $scope.closePopoversEventListeners();
                });
            }).then(()=> {
                return Promise.all([
                    integrationService.list(),
                    $scope.loadLists()
                ])
                // let parentScrollSelector = '.contact-scroll-div.active-scroll';
                // generalHelperService.waitForElement(parentScrollSelector)
                //     .then((contactScrollDiv) => {
                //         $(contactScrollDiv).scroll(function() {
                //             $(parentScrollSelector + ' .dropdown-toggle').parent('.show').dropdown('toggle');
                //         });
                //     });
            }).then(results => {
                const [integrations] = results;
                integrationService.set(integrations);
                $scope.integrationsCredentials = integrationService.get();
            })
            .finally(() => {
                $scope.$apply();
            });
        };

        $scope.initDocumentDropify = function() {
            setTimeout(function() {
                const drEvent = $('.dropify-document').dropify({
                    messages: {
                        'default': 'Drag and drop a file here or click',
                        'replace': 'Drag and drop or click to replace',
                        'remove':  'Remove',
                        'error':   'Ooops, something went wrong. Try again.'
                    },
                    error: {
                        'fileExtension': 'The file format is not supported.'
                    }
                });
                $('.dropify-document').change(function(event) {
                    var target = event.currentTarget || {};
                    if (target && target.files && target.files.length) {
                        var file = target.files[0];
                        var parts = (file.name || '').split('.');
                        var ext = (parts[parts.length - 1] || '').toLowerCase();
                        if ( $rootScope.settings.NOT_ALLOWED_EXTENSIONS_TO_UPLOAD.indexOf(ext) === -1 ) {
                            $scope.documentData.file = file;
                            $scope.$apply();
                        } else {
                            swal("Error", "The file format is not supported.", "error");
                        }
                    }
                });
                drEvent.on('dropify.afterClear', function(event, element) {
                    $scope.documentData.file = null;
                });
            }, 300);
        };

        $scope.clearUpload = function($event) {
            if ($event) { $event.preventDefault(); }
            $scope.documentData.file = null;
            angular.element('.dropify-clear').triggerHandler('click');
        };

        $scope.getContact = function(contactId) {
            if(contactId && contactId !== null) {
                $scope.loading.mainData = true;
                $scope.loadMainData(contactId);
            }
        };

        vm.$onInit = () => {
            $scope.getContact(storeSelectedContactService.getContactId());
        };

        $scope.$on('loadContactProfileRightPanel', function (event, contactId) {
            if(!$scope.loading.mainData && (!$scope.contactDetails || $scope.contactDetails.contact_id !== contactId)) {
                $scope.contactDetails = null;
                $scope.getContact(contactId);
            }
        });

        $scope.$on('updateContactNotesEvent', function (event, note, action) {
            switch (action) {
                case 'add': $scope.addNoteToList(note); break;
                case 'remove': $scope.removeNoteFromList(note); break;
                case 'edit': $scope.updateNoteText(note); break;
                case 'deleteEntry': $scope.removeNoteFromList(note); break;
            }
        });

        $scope.$on('contact.updated', function (e, ablyEvent) {
            let eventData = ablyEvent.data || {};
            if (eventData.contact_id === storeSelectedContactService.getContactId()) {
                $scope.getContact(storeSelectedContactService.getContactId());
            }
        });
    }
    module.exports = ContactDetailsProfileController;
})();
