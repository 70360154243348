(function () {
    'use strict';

    function LongJobService(apiService, $rootScope) {
        return {
            getJob: function (request) {
                return apiService.get($rootScope.settings.API_LONG_JOB_URL + '/longjob/' + request.job_id, request);
            }
        };
    }
    module.exports = LongJobService;
})();
