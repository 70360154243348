(function () {
    'use strict';

    function TagsController($rootScope, $scope, $location, authService, mediaService, $interval, $window, tagsService) {
        var vm = this;
        vm.authService = authService;

        $rootScope.viewData = $rootScope.viewData || {};
        $rootScope.viewData.breadcrumbs = $rootScope.viewData.breadcrumbs || [];
        $rootScope.viewData.breadcrumbs = [{
            title: 'Tags',
            link_name: 'Tags',
            link_url: '#/tags',
            help_url: $rootScope.help_url
        }];

        $scope.staticData = {
            title: "Tags",
            subtitle: "You can manage your tags below...",
            addBtn: {
                title: "Add Tag"
            },
            table: {
                header: ["Color", "Text Color", "Name", "Date Created", "Example"],
                actions: [
                    {
                        title: "Delete Tag"
                    }
                ],
                noRes: "You don't have any tags."
            },
            loading: "Loading",
            uploading: "Uploading",
            saving: "Saving",
        };

        $scope.tags = [];
        $scope.isGetStarted = false;

        $scope.newRecording = {};

        $scope.loading = {
            tags: true,
            saving: false
        };

        $scope.totalItems = 0;
        $scope.currentPage = 1;
        $scope.itemsPerPage = 8;
        $scope.maxSize = 3;

        $scope.createTag = function() {
            $scope.loading.tags = true;
            const newTag = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                label: `Tag ${($scope.tags ? $scope.tags.length : 0) + 1}`,
                color: 'rgba(0, 158, 251, 1)',
                text_color: 'rgba(255, 255, 255, 1)',
            }

            tagsService.createTag(newTag)
                .then(result => {
                    $scope.getTags();
                })
        }

        $scope.updateTagLabel = function(tag) {
            var request = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                tag_id: tag.tag_id,
                label: tag.label
            };
            tagsService.updateTag(request)
                .then(results => {})
                .catch(err => {
                    console.log(err);
                    swal("Cancelled", "Unable to update Tag", "error");
                });
        };

        $scope.checkTagLabel = function(tag) {
            if (tag.length === 0) {
                return "Tag must have at least 1 character.";
            }
        };

        $scope.newNameValue = '';
        $scope.editNameField = function(tag) {
            if($scope.newNameValue !== tag.label) {
                $scope.newNameValue = tag.label;
            }
        };

        $scope.confirmDelete = function(recording, callback) {

            var req = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                tag_id: recording.tag_id
            };

            tagsService.deleteTag(req)
                .then(results => {
                    callback(null);
                })
                .catch(err => {
                    console.log(err);
                    callback(err);
                });
        };

        $scope.deleteTag = function(e, tag) {
            if (e) {
                e.preventDefault();
            }

            swal({
                title: "Confirm",
                text: "Are you sure you want to delete this tag?",
                buttons: ["No", "Yes"],
                dangerMode: true,
                closeOnClickOutside: false
            }).then(isConfirm =>{
                if (isConfirm) {
                    $scope.confirmDelete(tag, function(res) {
                        if(res === null) {
                            swal("Deleted!", "This Tag has been deleted!", "success");
                            $scope.getTags();
                            $scope.$apply();
                        } else swal("Cancelled", "Unable to delete Tag", "error");
                    });
                }
            });
        };

        $scope.setPage = function (pageNo) {
            $scope.currentPage = pageNo;
        };

        $scope.pageChanged = function() {
            $window.scrollTo(0, 0);
            $scope.getTags();
        };

        $scope.setItemsPerPage = function(num) {
            $scope.itemsPerPage = num;
            $scope.currentPage = 1;
        };

        $scope.getTags = function() {
            $scope.loading.tags = true;
            const request = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                skip: ($scope.currentPage - 1) * $scope.itemsPerPage,
                limit: $scope.itemsPerPage
            };

            return tagsService.getTags(request)
                .then(result => {
                    $scope.tags = result;
                    $scope.loading.tags = false;
                })
        }

        $scope.updateTagColor = function (propertyName, tag, colorString) {
            var request = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                tag_id: tag.tag_id,
                label: tag.label
            };
            request[propertyName] = colorString;
            tagsService.updateTag(request)
                .then(results => {
                    tag[propertyName] = colorString;
                })
                .catch(err => {
                    console.log(err);
                    swal("Cancelled", "Unable to update Tag", "error");
                });
        }

        $scope.initColorPicker = function (tag) {
            console.log(`initColorPicker => #tag-color-${tag.tag_id}`)
            setTimeout(() => {
                if (!tag.color) {
                    tag.color = 'rgba(0, 158, 251, 1)';
                }
                $(`#tag-color-${tag.tag_id}`).asColorPicker({
                    mode: 'complex',
                    onClose: function (e) {
                        const pickerValue = $(`#tag-color-${tag.tag_id}`).asColorPicker('get').value;
                        const colorString = `rgba(${pickerValue.r}, ${pickerValue.g}, ${pickerValue.b}, ${pickerValue.a})`;
                        if (tag.color !== colorString) {
                            $scope.updateTagColor('color', tag, colorString);
                        }
                    }
                });
                $(`#tag-color-${tag.tag_id}`).asColorPicker('set', tag.color);
            }, 100)
        }

        $scope.initTextColorPicker = function (tag) {
            console.log(`initTextColorPicker => #tag-text-color-${tag.tag_id}`)
            setTimeout(() => {
                if (!tag.text_color) {
                    tag.text_color = 'rgba(255, 255, 255, 1)';
                }
                $(`#tag-text-color-${tag.tag_id}`).asColorPicker({
                    mode: 'complex',
                    onClose: function (e) {
                        const pickerValue = $(`#tag-text-color-${tag.tag_id}`).asColorPicker('get').value;
                        const colorString = `rgba(${pickerValue.r}, ${pickerValue.g}, ${pickerValue.b}, ${pickerValue.a})`;
                        if (tag.text_color !== colorString) {
                            $scope.updateTagColor('text_color', tag, colorString);
                        }
                    }
                });
                $(`#tag-text-color-${tag.tag_id}`).asColorPicker('set', tag.text_color);
            }, 100)
        }

        $scope.onInit = function() {
            $scope.getTags()
                .then(() => {
                    $scope.loading.tags = false;
                })
        };


        if (authService.hasAccount()) {
            $scope.onInit();
        }
        else {
            $scope.$on('auth_complete', function(event, args) {
                $scope.onInit();
            });
        }
    }
    module.exports = TagsController;
})();
