(function() {
    'use strict';

    function ContactsController($rootScope, $scope, authService, contactService, dncService, phoneService, accountService, dispatcherService, mediaService, dropCowboyContactService, notificationToastService, $window, $location, $interval, $cookies, $http, integrationService, $uibModal, tagsService, generalHelperService) {
        var vm = this;
        vm.authService = authService;

        $rootScope.viewData = $rootScope.viewData || {};
        $rootScope.viewData.breadcrumbs = $rootScope.viewData.breadcrumbs || [];
        $rootScope.viewData.breadcrumbs = [{
            title: 'Contacts',
            link_name: 'Contacts',
            link_url: '#/contacts',
            help_url: $rootScope.help_url
        }];

        $scope.integrationsCredentials = [];
        $scope.appName = $rootScope.settings.APP_NAME;

        $scope.staticText = {
            title: 'Contacts',
            loading: 'Loading',
            processing: 'Processing',
            noRes: 'No Results',
            addContacts: {
                href: '#lists'
            }
        };
        $scope.defaultAvatar = $rootScope.settings.NO_AVATAR_IMG;

        $scope.export = {
            id: null,
            status: null,
            link: null
        };

        $scope.rented_numbers = null;

        $scope.data = {
            mapsUrl: 'https://www.google.com/maps/place/:address+:city+:state+:zip',
            contacts: [],
            lists: [],
            selectedListId: ($location.search().listId || ''),
            outcomes: [],
            tags: [],
            selectedOutcomeId: '',
            searchTerm: ($location.search().searchTerm || ''),
            available_dispositions: {},
            dispositions: {}
        };

        $scope.currentPage = 1;
        $scope.itemsPerPage = 8;
        $scope.maxSize = 3;
        $scope.totalItems = 0;
        $scope.dcExport = {
            isSelecting: false,
            numSelected: 0,
            exportAll: '1',
            dcListIndex: '0',
            dcListName: 'Imported Contacts',
            toNewList: '0',
            DROP_COWBOY_APP_URL: $rootScope.settings.DROP_COWBOY_APP_URL,
            DROP_COWBOY_MAIN_URL: $rootScope.settings.DROP_COWBOY_MAIN_URL,
            dcKeys: {
                team_id: '',
                secret: ''
            },
            dcLists: []
        };
        $scope.dd1 = {
            showLists: false,
            shownLists: false,
            action: ''
        };
        $scope.loading = {
            contacts: false,
            contactsFirstLoad: true
        };
        $scope.updating = {
            contacts: false
        };

        $scope.isCorrectDate = function(item, type) {
            const now  = Date.now();
            switch (type) {
                case 'call':
                    if(item.call_info && item.call_info.last_called_at && item.call_info.last_called_at > 0 && moment(item.call_info.last_called_at).isValid()) {
                        return true;
                    }
                    return false;
                case 'available':
                    if(item.call_info && item.call_info.callable_after && item.call_info.callable_after > now && moment(item.call_info.callable_after).isValid()) {
                        return true;
                    }
                    return false;
                case 'text':
                    if(item.message_info && item.message_info.last_message_at && item.message_info.last_message_at > 0 && moment(item.message_info.last_message_at).isValid()) {
                        return true;
                    }
                    return false;
                case 'disposition':
                    if(item.disposition && item.disposition > 0 && moment(item.disposition).isValid()) {
                        return true;
                    }
                    return false;
            }
        };

        $scope.updateLocation = function(updateSearchTerm) {
            let search = {};
            if (updateSearchTerm) {
                if ($scope.data.searchTerm) { search.searchTerm = $scope.data.searchTerm; }
            } else if ($location.search().searchTerm) {
                search.searchTerm = $location.search().searchTerm;
            }
            if ($scope.data.selectedListId) {
                search.listId = $scope.data.selectedListId;
            }
            if ($scope.data.selectedOutcomeId) {
                search.outcomeId = $scope.data.selectedOutcomeId;
            }
            $location.path('/contacts').search(search);
        };

        $scope.clearSearch = function() {
            $scope.data.searchTerm = '';
            $location.path('/contacts').search('');
        };

        $scope.$on('$locationChangeSuccess',
            function(event, newUrl, oldUrl) {
                if ( $location.path() == '/contacts' ) {
                    $scope.currentPage = 1;
                    $scope.data.contacts = [];
                    $scope.data.searchTerm = ($location.search().searchTerm || '');
                    $scope.data.selectedListId = ($location.search().listId || '');
                    $scope.data.selectedOutcomeId = ($location.search().outcomeId || '');
                    $scope.loadContacts(0, 'contactsFirstLoad');
                }
            }
        );

        $scope.preDcExport = function() {

            if (!$scope.data.contacts.length) { return; }
            $scope.updating.contacts = true;
            $scope.__getApiKeys()
                .then(keys => {
                    if (!keys) {
                        $("#dcKeysModal").modal('show');
                        return Promise.reject('');
                    }
                    $scope.dcExport.dcKeys.team_id = keys.dc_team_id;
                    $scope.dcExport.dcKeys.secret = keys.dc_secret;
                    return $scope.__getDcLists(keys);
                })
                .then(dcLists => {
                    $scope.dcExport.dcLists = dcLists;
                    $scope.dcExport.isSelecting = true;
                })
                .catch(err => {
                    if (err) { console.log(err); }
                })
                .then(() => {
                    $scope.updating.contacts = false;
                    $scope.$apply();
                });
        };

        $scope.__getApiKeys = function() {

            return new Promise((resolve, reject) => {

                if ($scope.dcExport.dcKeys.team_id && $scope.dcExport.dcKeys.secret) {
                    resolve({
                        dc_team_id: $scope.dcExport.dcKeys.team_id,
                        dc_secret: $scope.dcExport.dcKeys.secret
                    });
                    return;
                }

                const request = {
                    user_id: authService.getUserId(),
                    team_id: authService.getTeamId()
                };
                accountService.getApiKeys(request)
                    .then(keys => {
                        let dcTeamId = '', dcSecret = '';
                        for (let i = 0; i < keys.length; i++) {
                            if (keys[i].name == 'dc_team_id') { dcTeamId = keys[i].secret; }
                            else if (keys[i].name == 'dc_secret') { dcSecret = keys[i].secret; }
                        }
                        if (!dcTeamId || !dcSecret) {
                            resolve(null);
                            return;
                        }
                        resolve({
                            dc_team_id: dcTeamId,
                            dc_secret: dcSecret
                        });
                    })
                    .catch(err => {
                        swal("Server Error", "We were unable to retrieve API credentials for your account.  Try again later.", "error");
                        reject(err);
                    });
            });
        };

        $scope.__getDcLists = function(keys) {

            return new Promise((resolve, reject) => {

                const request = {
                    team_id: keys.dc_team_id,
                    secret: keys.dc_secret,
                    with_additional_info: false
                };
                dropCowboyContactService.getLists(request)
                    .then(dcLists => {
                        resolve(dcLists || []);
                    })
                    .catch(err => {
                        swal("Server Error", "We were unable to retrieve your Contact Lists from Drop Cowboy. Please check your dc_team_id and dc_secret in the API section.", "error");
                        reject(err);
                    });
            });
        };

        $scope.showDcExportModal = function() {
            $scope.dcExport.numSelected = 0;
            for (let i = 0; i < $scope.data.contacts.length; i++) {
                if ($scope.data.contacts[i].inDcExport) { $scope.dcExport.numSelected++; }
            }
            if (!$scope.dcExport.numSelected) {
                swal("Error", "Please select at least one contact.", "error");
                return;
            }
            if ($scope.dcExport.numSelected == $scope.data.contacts.length &&
            $scope.totalItems > $scope.itemsPerPage) {
                $scope.dcExport.exportAll = '1';
            } else {
                $scope.dcExport.exportAll = '0';
            }
            if ($scope.dcExport.dcLists.length) {
                $scope.dcExport.toNewList = '0';
            } else {
                $scope.dcExport.toNewList = '1';
            }
            $("#dcExportModal").modal('show');
        };

        $scope.startDcExport = function() {
            $scope.dcExport.dcListName = $.trim($scope.dcExport.dcListName);
            if ($scope.dcExport.toNewList == '1' && !$scope.dcExport.dcListName) { return; }
            $scope.updating.contacts = true;
            $("#dcExportModal").modal('hide');
            const request = {
                user_id: authService.getUserId(),
                team_id: authService.getTeamId(),
                dc_team_id: $scope.dcExport.dcKeys.team_id,
                dc_secret: $scope.dcExport.dcKeys.secret
            };

            if ($scope.dcExport.exportAll == '0') {
                request.contact_ids = [];
                for (let i = 0; i < $scope.data.contacts.length; i++) {
                    let c = $scope.data.contacts[i];
                    if (c.inDcExport) { request.contact_ids.push(c.contact_id); }
                }
            } else {
                if ($location.search().searchTerm) {
                    request.search_term = $location.search().searchTerm;
                }
                if ($scope.data.selectedListId) {
                    request.list_id = $scope.data.selectedListId;
                }
                if ($scope.data.selected_disposition) {
                    request.disposition = $scope.data.selected_disposition;
                }
                if ($scope.data.selectedOutcomeId) {
                    request.outcome_id = $scope.data.selectedOutcomeId;
                }
            }

            let listName;
            if ($scope.dcExport.toNewList == '0') {
                let selectedList = $scope.dcExport.dcLists[parseInt($scope.dcExport.dcListIndex)];
                request.dc_list_id = selectedList.list_id;
                listName = selectedList.list_name;
            } else {
                request.dc_list_name = $scope.dcExport.dcListName;
                listName = $scope.dcExport.dcListName;
            }

            contactService.dcExportContacts(request)
                .then(results => {
                    let msg = 'Exported ' + results.numAccepted + ' contact' + (results.numAccepted != 1 ? 's' : '') + ' to list ' + listName + ' on Drop Cowboy.';
                    if (results.numRejected) {
                        msg += ' ' + results.numRejected + ' contact' + (results.numRejected != 1 ? 's' : '') + ' could not be exported.';
                    }
                    if (results.numAccepted) {
                        swal("Exported", msg, "success");
                        $scope.dcExport.isSelecting = false;
                    } else {
                        swal("Error", msg, "error");
                    }
                })
                .catch(err => {
                    swal("Server Error", "We were unable to export your contacts to Drop Cowboy. Try again later.", "error");
                    console.error(err);
                })
                .then(() => {
                    $scope.updating.contacts = false;
                    $scope.$apply();
                });
        };

        $scope.setPage = function (pageNo) {
            $scope.currentPage = pageNo;
        };

        $scope.setItemsPerPage = function(num) {
            $scope.itemsPerPage = num;
            $scope.currentPage = 1;
        };

        $scope.filterByDisposition = function(dispositionId) {
            $scope.data.selected_disposition = dispositionId;
            $scope.currentPage = 1;
            $scope.data.contacts = [];
            $scope.loadContacts(0, 'contactsFirstLoad');
        };

        $scope.getDispositionFriendly = function(disposition) {
            return (disposition && disposition.id) ? $rootScope.available_dispositions[disposition.id].friendly : 'None';
        };

        $scope.destroyTooltip = function(selector) {
            const tooltip = angular.element(document).find(selector + ' [data-toggle="tooltip"]');
            tooltip.tooltip('dispose');
        };

        $scope.initTooltip = function() {
            for (let i = 0; i < $scope.data.contacts.length; i++) {
                $scope.destroyTooltip(`#dial-info-tcpa-tooltip_${$scope.data.contacts[i].contact_id}`);
                generalHelperService.waitForElement(`#dial-info-tcpa-tooltip_${$scope.data.contacts[i].contact_id}`)
                    .then(element => {
                        $(element).tooltip({ container: 'body' });
                    });
            }
        };

        $scope.loadMoreContacts = function() {
            $scope.loadContacts(($scope.currentPage - 1) * $scope.itemsPerPage, 'contacts');
        };

        $scope.loadContacts = function(skip, loader) {
            $scope.loading[loader] = true;
            $window.scrollTo(0, 0);

            var teamId = authService.getTeamId();
            var userId = authService.getUserId();

            const request = {
                team_id: teamId,
                user_id: userId,
                skip: skip,
                limit: $scope.itemsPerPage
            };

            if ($location.search().searchTerm) {
                request.search_term = $location.search().searchTerm;
            }

            if ($scope.data.selectedListId) {
                request.list_id = $scope.data.selectedListId;
            }

            if ($scope.data.selected_disposition) {
                request.disposition = $scope.data.selected_disposition;
            }

            if ($scope.data.selectedOutcomeId) {
                request.outcome_id = $scope.data.selectedOutcomeId;
            }

            $rootScope.contactsSearch = {
                search_term: $location.search().searchTerm,
                list_id: $scope.data.selectedListId,
                disposition: $scope.data.selected_disposition,
                outcome_id: $scope.data.selectedOutcomeId,
            }

            Promise.all([
                contactService.getContacts(request),
                tagsService.getTags(),
            ])
                .then(results => {
                    const [contacts, tags] = results;
                    $scope.data.tags = tags;
                    $scope.extendContacts(contacts.contacts);
                    $scope.data.contacts = contacts.contacts || [];
                    $scope.totalItems = contacts.total_contacts || 0;

                    $scope.loading[loader] = false;
                    $scope.$apply();

                    for (let c = 0; c < $scope.data.contacts.length; c++) {
                        const contact = $scope.data.contacts[c];
                        $scope.data.contacts[c].avatar = 'https://secure.gravatar.com/avatar/' + $scope.data.contacts[c].gravatar_id + '?d=mp';
                        contact.inDcExport = true;
                        if (contact.tags && contact.tags.length > 0) {
                            contact.tags = contact.tags.filter(contactTag => {
                                const tag = $scope.data.tags.find(availableTag => availableTag.tag_id === contactTag.tag_id);
                                if (typeof tag !== 'undefined') {
                                    contactTag = Object.assign(contactTag, tag);
                                    return true;
                                }
                                return false;
                            })

                        }
                        const contactDd = angular.element('#contact-dd-' + contact.contact_id);
                        $scope.addDropdownListener(contactDd, contact.ddState);
                    }

                    $scope.reloadTooltips('.contacts-list');
                    $scope.$apply();
                })
                .catch(err => {
                    console.error(err);
                    $scope.$apply();
                    $scope.loading[loader] = false;
                })
                .finally(() => {
                    $scope.initTooltip();
                    $scope.$apply();
                });
        };

        $scope.isEmptyAvailableDispositionsList = function() {
            return (Object.keys($scope.data.available_dispositions).length === 0);
        };

        $scope.isEmptyDefaultDispositionsList = function() {
            return (Object.keys($scope.data.dispositions).length === 0);
        };

        $scope.loadDispositions = function() {
            return new Promise(function(resolve, reject) {
                Promise.all([
                    $scope.loadDefaultDispositions(),
                    $scope.loadAvailableDispositions()
                ]).then(() => {
                    resolve();
                }).catch(err => {
                    console.error(err);
                    reject();
                });
            });
        };

        $scope.loadDefaultDispositions = function() {

            if($rootScope.dispatcher_template && Object.keys($rootScope.dispatcher_template.dispositions).length > 0) {
                $scope.data.dispositions = $rootScope.dispatcher_template.dispositions;
                return;
            }

            const request = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
            };

            return dispatcherService.getDispatcherData(request)
                .then(results => {
                    $rootScope.dispatcher_template = results.dispatcher_data || {};
                    $scope.data.dispositions = results.dispatcher_data.dispositions;
                    $scope.$apply();
                })
                .catch(err => {
                    console.error(err);
                    $scope.$apply();
                });
        };

        $scope.loadAvailableDispositions = function() {

            if($rootScope.available_dispositions && Object.keys($rootScope.available_dispositions).length > 0) {
                $scope.data.available_dispositions = $rootScope.available_dispositions;
                return;
            }

            const request = {
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
            };

            return dispatcherService.getAvailableDispositions(request)
                .then(results => {
                    $rootScope.available_dispositions = results || {};
                    $scope.data.available_dispositions = $rootScope.available_dispositions;
                    $scope.$apply();
                })
                .catch(err => {
                    console.error(err);
                    $scope.$apply();
                });
        };

        $scope.extendContacts = function(contacts) {
            for (let c = 0; c < contacts.length; c++) {
                let contact = contacts[c];
                if (!contact.list_ids) { contact.list_ids = []; }
                contact.listNames = $scope.__addListNames($scope.getListIds(contact));
                if (!contact.sold) { contact.sold = {}; }
                contact.soldListNames = $scope.__addListNames(contact.sold, true);
                contact.ddState = {
                    showLists: false,
                    showTags: false,
                    showDispositions: false,
                    shownLists: false,
                    shownTags: false,
                    shownDispositions: false,
                    action: ''
                };
            }
        };

        $scope.setContactDisposition = function(contact, disposition) {
            contact.processing = true;
            var teamId = authService.getTeamId();
            var userId = authService.getUserId();

            const request = {
                team_id: teamId,
                user_id: userId,
                contact_id: contact.contact_id,
                contact: {
                    disposition: disposition
                }
            };

            contactService.updateContact(request)
                .then(results => {
                    contact.disposition = results.disposition;
                    contact.processing = false;
                    $scope.$apply();
                    notificationToastService.showSuccessToast('fa-light fa-user-check', "Contact set as " + disposition.friendly);
                })
                .catch(err => {
                    console.error(err);
                    contact.processing = false;
                    $scope.updating.contacts = false;
                    $scope.$apply();
                });
        };

        $scope.updateContactDisposition = function(contact, disposition) {

            switch(disposition.id) {
                case 'dnc':
                    $scope.addToDNC(contact);
                    break;
                default:
                    $scope.setContactDisposition(contact, disposition);
            }
        };

        $scope.updateContactTag = function (contact, tag) {
            contact.processing = true;
            const action = contact.tags.map(contactTag => contactTag.tag_id).indexOf(tag.tag_id) !== -1 ? 'remove' : 'add'
            switch (action) {
                case 'add':
                case 'remove':
                    const teamId = authService.getTeamId();
                    const userId = authService.getUserId();

                    const request = {
                        team_id: teamId,
                        user_id: userId,
                        contact_id: contact.contact_id,
                        tag_id: tag.tag_id,
                        action,
                    };

                    contactService.updateContactTag(request)
                        .then(tags => {
                            contact.tags = (tags || []).filter(contactTag => {
                                const tag = $scope.data.tags.find(availableTag => availableTag.tag_id === contactTag.tag_id);
                                if (typeof tag !== 'undefined') {
                                    contactTag = Object.assign(contactTag, tag);
                                    return true;
                                }
                                return false;
                            })
                            contact.processing = false;
                        })
                    break;
                default:
                    swal("Error", `Unexpected action '${action}' with tag`, "error");
                    break;
            }
        }

        $scope.__addListNames = function(listIds, asKeys) {
            if (asKeys) {
                const keys = listIds;
                listIds = [];
                for (let k in keys) {
                    if (keys[k]) { listIds.push(k); }
                }
            }
            let listNames = '';
            for (let i = 0; i < listIds.length; i++) {
                listNames += $scope.getListName(listIds[i]) + '<br>';
            }
            return listNames;
        }

        $scope.openContact = function(e, item) {
            var actionDiv = document.getElementById('action-col-' + item.contact_id);
            if(!actionDiv.contains(e.target)) {
                $scope.viewDetails(item);
            }
        };

        $scope.getListIds = function(item) {
            return [...new Set(item.list_ids)];
        };

        $scope.viewDetails = function(contact) {
            $location.path('/contacts/detail').search('id=' + contact.contact_id);
        };

        $scope.exportContacts = function() {

            if (!$scope.data.exporting) {

                $scope.data.exporting = true;

                var teamId = authService.getTeamId();
                var userId = authService.getUserId();

                const request = {
                    team_id: teamId,
                    user_id: userId
                };

                if ($location.search().searchTerm) {
                    request.search_term = $location.search().searchTerm;
                }
                if ($scope.data.selectedListId) {
                    request.list_id = $scope.data.selectedListId;
                }
                if ($scope.data.selectedOutcomeId) {
                    request.outcome_id = $scope.data.selectedOutcomeId;
                }
                if ($scope.data.selected_disposition) {
                    request.disposition = $scope.data.selected_disposition;
                }



                contactService.exportContacts(request)
                    .then(result => {
                        $scope.export.id = result.export_id;
                        swal("Export", "The export is processing and will download to your browser automatically when it is complete.", "success");
                    })
                    .catch(err => {
                        console.error(err);
                        $scope.data.exporting = false;
                        $scope.$apply();
                    });
            }
        };

        $scope.$on('export.success', function(event, args) {
            if (args && args.data.user_id === authService.getUserId()) {
                $scope.data.exporting = false;
                $scope.$apply();
            }
        });

        $scope.__checkExportStatus = function() {

            if ($scope.export.id && $scope.export.status === "pending") {

                const request = {
                    team_id: authService.getTeamId(),
                    user_id: authService.getUserId(),
                    export_id: $scope.export.id
                };

                contactService.getExportStatus(request)
                    .then(result => {
                        $scope.export.status = result.status;
                        $scope.export.link = result.link;
                        if($scope.export.status === 'done'){
                            window.open($scope.export.link, '_self');
                        }
                        $scope.$apply();
                    })
                    .catch(err => {
                        console.error(err);
                        $scope.$apply();
                    });
            }
        };

        $scope.addToDNC = function(contact) {
            contact.processing = true;
            var teamId = authService.getTeamId();
            var userId = authService.getUserId();

            const request = {
                team_id: teamId,
                user_id: userId,
                phone_number: contact.phone_numbers,
                method: "contact search"
            };
            let promise = (
                !contact.dnc ? dncService.createOne(request) : dncService.deleteOne(request)
            );

            promise.then(results => {
                    var notificationMessage = (!contact.dnc) ? "Contact "+ contact.first_name +" "+ contact.last_name +" has been added to the DNC list" :
                        "Contact "+ contact.first_name +" "+ contact.last_name +" has been removed from the DNC list"
                    notificationToastService.showSuccessToast('fa-light fa-user-check', notificationMessage);
                    contact.dnc = !contact.dnc;
                })
                .catch(err => {
                    console.error(err);
                })
                .then(() => {
                    contact.processing = false;
                    $scope.$apply();
                });
        };

        // $scope.loadRecordings = function() {
        //     const request = {
        //         team_id: authService.getTeamId(),
        //         user_id: authService.getUserId(),
        //         include_urls: true,
        //         media_exists: true,
        //     };
        //
        //     mediaService.listMediaUrls(request)
        //         .then(results => {
        //             var lists = (_.sortBy(results.medias, 'created_at').reverse()) || [];
        //             $scope.data.recordings = lists;
        //             $scope.$apply();
        //         })
        //         .catch(err => {
        //             console.error(err);
        //             $scope.$apply();
        //         });
        // };

        $scope.contactDdAction = function(contact, entityToAdd) {
            switch (contact.ddState.action) {
                case 'list':
                    const toAdd = ( contact.list_ids.indexOf(entityToAdd.list_id) === -1 );
                    $scope.updateListInSearchResults(entityToAdd, toAdd, contact);
                    return;
            }
        };

        $scope.viewCalls = function(contact) {
            $location.path('/recent-calls').search({ contactId: contact.contact_id, viewAll: true });
        };

        $scope.viewTexts = function(contact) {
            $location.path('/text-messages').search({ contactId: contact.contact_id });
        };

        $scope.showOnMap = function(contact) {
            let url = $scope.data.mapsUrl
                .replace(':address', contact.address || '')
                .replace(':city', contact.city || '')
                .replace(':state', contact.state || '')
                .replace(':zip', contact.zip || '');

            window.open(encodeURI(url), '_blank');
        };

        $scope.sendEmail = function(contact) {
            $rootScope.openEmailArea(contact);
        };

        $scope.sendFax = function(contact) {
            $rootScope.showFaxModal({ contact: contact });
        };

        $scope.sendRVM = function(contact) {
            $rootScope.showRvmModal(contact);
        };

        $scope.deleteContact = function(contact) {
            swal("Delete Contact", "Do you want to delete "+ (contact.first_name || 'Unknown') +" "+ (contact.last_name || 'Contact') +"?", { buttons: true, dangerMode: true })
                .then(confirm => {
                    if (!confirm) { return; }

                    $scope.updating.contacts = true;
                    var teamId = authService.getTeamId();
                    var userId = authService.getUserId();

                    const request = {
                        team_id: teamId,
                        user_id: userId,
                        contact_id: contact.contact_id,
                    };

                    contactService.deleteContact(request)
                        .then(results => {
                            var contacts = $scope.data.contacts || [];
                            for (var i = 0; i < contacts.length; i++) {
                                if (contacts[i].contact_id == contact.contact_id) {
                                    contacts.splice(i, 1);
                                    break;
                                }
                            }
                        })
                        .catch(err => {
                            console.error(err);
                        })
                        .then(() => {
                            $scope.updating.contacts = false;
                            $scope.$apply();
                        });
                });
        };

        $scope.hasPermissions = function(entity, action) {
            return $rootScope.hasPermissions(entity, action)
        };

        $scope.loadLists = function() {

            var teamId = authService.getTeamId();
            var userId = authService.getUserId();

            const request = {
                team_id: teamId,
                user_id: userId,
                with_additional_info: false
            };

            return contactService.getLists(request);
        };

        $scope.getListName = function(listId) {
            const lists = $scope.data.lists;
            for (let i = 0; i < lists.length; i++) {
                if (listId === lists[i].list_id) {
                    return lists[i].list_name;
                }
            }
            return 'Unknown';
        };

        $scope.resultsDdAction = function(list) {
            switch ($scope.dd1.action) {
                case 'create':
                    $location.path('/contacts/edit').search({ listId: list.list_id });
                    return;
                case 'add':
                    $scope.updateListInSearchResults(list, true);
                    return;
                case 'remove':
                    $scope.updateListInSearchResults(list, false);
                    return;
            }
        };

        $scope.updateListInSearchResults = function(list, add, contact) {
            $scope.updating.contacts = true;
            var teamId = authService.getTeamId();
            var userId = authService.getUserId();

            const request = {
                team_id: teamId,
                user_id: userId,
                list_id: list.list_id,
                remove_list: !add
            };

            if (!contact) {
                if ($location.search().searchTerm) {
                    request.search_term = $location.search().searchTerm;
                }
                if ($scope.data.selectedListId) {
                    request.previous_list_id = $scope.data.selectedListId;
                }
                if ($scope.data.selectedOutcomeId) {
                    request.outcome_id = $scope.data.selectedOutcomeId;
                }
            } else {
                request.contact_ids = [contact.contact_id];
            }

            contactService.addToList(request)
                .then(results => {
                    $scope.updating.contacts = false;
                    const msg = (add ? 'Added to' : 'Removed from');
                    if (!contact) {
                        swal(msg +" List", "Contact search results have been "+ msg.toLowerCase() +" campaign " + list.list_name, "success");
                        for (let i = 0; i < $scope.data.contacts.length; i++) {
                            $scope.updateListInContact(list, add, $scope.data.contacts[i]);
                        }
                        $scope.$apply();
                        $scope.reloadTooltips('.contacts-list');
                    } else {
                        swal(msg +" List", `Contact ${contact.first_name ? contact.first_name : ''} ${contact.last_name ? contact.last_name : ''} has been ${msg.toLowerCase()} list ${list.list_name}`, "success");
                        $scope.updateListInContact(list, add, contact);
                        $scope.$apply();
                        $scope.reloadTooltips('#contact-'+ contact.contact_id);
                    }
                    $scope.loadContacts(0, 'contacts');
                })
                .catch(err => {
                    console.error(err);
                    $scope.updating.contacts = false;
                    $scope.$apply();
                });
        };

        $scope.textContact = function(contact) {
            $rootScope.openTextArea(contact);
        };

        $scope.showCallPanel = function(contact) {
            $rootScope.$broadcast('openCallTab', contact);
        };

        $scope.updateListInContact = function(list, add, contact) {
            const listIndex = contact.list_ids.indexOf(list.list_id);
            if (listIndex === -1 && add) {
                contact.list_ids.push(list.list_id);
                contact.listNames += list.list_name + '<br>';
            } else if (listIndex >= 0 && !add) {
                contact.list_ids.splice(listIndex, 1);
                contact.listNames = contact.listNames.replace(list.list_name + '<br>', '');
            }
        };

        $scope.addDropdownListener = function(element, state) {
            element.on('show.bs.dropdown', function() {
                element.find('.dropdown-menu').stop();
                element.find('.dropdown-menu').animate({scrollTop: 0}, 350);
            });
            element.on('hidden.bs.dropdown', function() {
                if (state.showLists) {
                    state.showLists = false;
                    state.shownLists = true;
                    element.find('.dropdown-toggle').dropdown('toggle');
                } else if (state.showTags) {
                    state.showTags = false;
                    state.shownTags = true;
                    element.find('.dropdown-toggle').dropdown('toggle');
                } else if (state.showDispositions) {
                    state.showDispositions = false;
                    state.shownDispositions = true;
                    element.find('.dropdown-toggle').dropdown('toggle');
                } else {
                    state.shownLists = false;
                    state.shownTags = false;
                    state.shownDispositions = false;
                    state.action = '';
                }
                $scope.$apply();
            });
        };

        $scope.getContactTagsIds = function (contact) {
            return (contact.tags || []).map(tag => tag.tag_id);
        }

        $scope.reloadTooltips = function(selector) {
            const tooltips = angular.element(document)
                .find(selector + ' [data-toggle="tooltip"]');
            tooltips.tooltip('dispose');
            tooltips.tooltip();
        };

        // $scope.loadNumbers = function() {
        //     if(!$scope.rented_numbers) {
        //         $scope.rented_numbers = $rootScope.team_phone_numbers.map(number => {return number.phone_number});
        //         $scope.setSelectedPhone($scope.rented_numbers[0], 'sms');
        //         $scope.setSelectedPhone($scope.rented_numbers[0], 'call');
        //     }
        //
        //     if(!$scope.user_numbers) {
        //         $scope.user_numbers = authService.getUserNumbers();
        //     }
        //
        // };

        $scope.createContact = function() {
            $rootScope.$broadcast('addContactEvent', $scope.data.selectedListId);
        };
        $scope.editContact = function(contact) {
            $rootScope.$broadcast('editContactEvent', contact.contact_id, $scope.data.selectedListId);
        };
        $scope.$on('addEditContactEventDone', function(event, mode, isSuccess) {
            if(isSuccess) {
                $scope.loadContacts(0, 'contacts');
            }
        });

        $scope.onInit = function() {
            $window.scrollTo(0, 0);
            $scope.addDropdownListener( angular.element('#contacts-dd1'), $scope.dd1 );
            $scope.loadLists()
                .then(lists => {
                    $scope.data.lists = lists || [];

                    return $scope.loadDispositions();
                })
                .then(lists => {
                    return $scope.loadContacts(0, 'contactsFirstLoad');
                })
                // .then(lists => {
                //     return $scope.loadRecordings();
                // })
                .catch(console.error);
            $scope.exportTime = $interval($scope.__checkExportStatus, 5000);
            integrationService.list()
                .then(integrations => {
                    integrationService.set(integrations);
                    $scope.integrationsCredentials = integrationService.get();
                })
        };

        if (authService.hasAccount()) {
            $scope.onInit();
        }
        else {
            $scope.$on('auth_complete', function(event, args) {
                $scope.onInit();
            });
        }

        $scope.$on('$destroy', function() {
            $interval.cancel($scope.exportTime);
        });
    }
    module.exports = ContactsController;
})();
