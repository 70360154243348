(function () {
    'use strict';

    function SignupStepsController($rootScope, $state, $scope, $location, $interval, $cookies, $http, authService, accountService, $window, generalHelperService) {

        $scope.forms = {
            work_email: { value: '' },
            password: { value: '' },
            password_again: { value: '' },
            first_name: { value: '' },
            last_name: { value: ''},
            code_sent: false,
            phone_number: {
                value: '',
                number: '',
                iti: undefined,
                code: ''
            },
            company_name: { value: '' },
            company_size: { value: '' },
            address: {
                value: '',
                fullAddress: '',
                fullAddressLabel: '',
                postalCode: ''
            },
            paymentMethod: {
                name: '',
                cardNumber: '',
                cardCode: '',
                month: '',
                year: '',
                formattedCardNumber: '',
                formattedCardCode: '',
                formattedMonth: '',
                formattedYear: '',
                dataDescriptor: null,
                dataValue: null
            },
            agree1: { value: '' },
            agree2: { value: '' },
            agree3: { value: '' }
        };

        $scope.data = {
            lead_id: null,
            billing_term: 'monthly',
            plan_id: 'monthly-plan',
            work_email: '',
            phone_number: '',
            add_ons: [],
            submitError: {
                show: false,
                message: ''
            },
            chargebeeErrorMessage: null
        };

        $scope.step = '1';

        $scope.staticData = {
            step1Title: 'Step 1 of 3',
            step2Title: 'Step 2 of 3',
            step3Title: 'Step 3 of 3',
            title: {
                plain: 'Start Your 30-Day',
                highlighted: 'Free Trial!'
            },
            subtitle: 'Get Instant Access, Cancel Anytime',
            step3FreeTrial: {
                title: 'Try it free for 30-days',
                subtitle: 'We\'ll send you a reminder email a few days before your trial ends.\nCancel your subscription anytime.'
            },
            form: {
                work_email: {
                    placeholder: 'Work email',
                    subtitle: [
                        `By submitting the information, I confirm that I have read and acknowledged ${$rootScope.settings.APP_NAME}\'s `,
                        'Privacy Policy',
                        `. ${$rootScope.settings.APP_NAME}\'s sales representative may contact me to introduce and discuss with me the ${$rootScope.settings.APP_NAME} product.`
                    ],
                    errorMessage: 'Must enter valid email'
                },
                password: {
                    placeholder: 'Password',
                    errorMessageRequired: 'Password is required',
                    errorMessageMinLength: 'Password must be at least 8 characters long',
                    errorMessagePattern: 'Password must contain at least one capital letter and one character'
                },
                password_again: {
                    placeholder: 'Password again',
                    errorMessage: 'Passwords do not match'
                },
                first_name: {
                    placeholder: 'First Name',
                    errorMessage: 'First and Last name should contain only letters and spaces'
                },
                last_name: {
                    placeholder: 'Last Name'
                },
                phone_number: {
                    placeholder: 'Phone Number',
                    errorMessage: 'Must enter valid phone number',
                    subtitle: [
                        `By submitting this form, I consent to communication from ${$rootScope.settings.APP_NAME} regarding my account at the number provided via SMS, pre-recorded audio, or phone calls; including those sent by autodialer. Msg & data rates may apply.`,
                    ]
                },
                code: {
                    placeholder: '6 Digit Code',
                    subtitle: [
                        'A SMS containing OTP (one time passcode) has been sent to',
                        'Please enter the 6-digit OTP value to complete your verification.'
                    ],
                },
                company_name: {
                    placeholder: 'Company Name'
                },
                address: {
                    placeholder: 'Billing Address',
                    errorMessage: 'Must enter valid address'
                },
                company_size: {
                    title: 'Company Size',
                    options: ['1-3', '4-15', '16-49', '50+']
                },
                paymentMethod: {
                    placeholders: [
                        'Name on Card',
                        'Card Number',
                        'CVV',
                        'MM',
                        'YY'
                    ],
                    masks: {
                        cardNumberMask: '0000 0000 0000 0000',
                        secureCodeMask: '0000',
                        paymentMonthMask: '00',
                        paymentYearMask: '00'
                    },
                },
                agree1: {
                    text: `By starting a free trial you agree to be billed by ${$rootScope.settings.PARENT_COMPANY} dba ${$rootScope.settings.APP_NAME} for any charges that you authorize. The charges will appear on your statement as ${$rootScope.settings.APP_NAME}.`
                },
                agree2: {
                    text: `By starting a 30-day free trial you understand that this is a monthly recurring subscription that will auto-renew each month beginning at the conclusion of your free trial and consent to incurring the monthly subscription fee (if any) that will be automatically charged to your payment card each month. You may cancel your subscription at anytime by clicking the "cancel subscription" link within the ${$rootScope.settings.APP_NAME} app or by using the live chat feature of the ${$rootScope.settings.APP_NAME} website.`,
                    // termsHref: 'https://' + window.location.hostname.replace('signup.', '') + '/terms',
                    // privacyHref: 'https://' + window.location.hostname.replace('signup.', '') + '/privacy',
                },
                agree3: {
                    text: [
                        `Yes, I agree to ${$rootScope.settings.APP_NAME}\'s `,
                        'Terms of Use',
                        ' and ',
                        'Privacy Policy',
                        '.'
                    ],
                    termsHref: APP_TERMS_LINK,
                    privacyHref: APP_PRIVACY_LINK,
                    errorMessage: 'Must agree to Terms of Use and Privacy Policy'
                },
                submit: {
                    step1Button: 'Try For Free',
                    step2Button: 'Get Verification Code',
                    verifyButton: 'Verify Code',
                    backButton: 'Send Code Again',
                    step3Button: 'Start Your Free Trial',
                    subtitle: 'Secure Checkout'
                },
                requiredError: 'Fields are required',
                paymentError: 'Must enter valid payment information'
            },
            loading: 'Loading',
            processing: 'Please wait, this may take a minute'
        };

        $scope.loading = {
            submit: false
        };

        $scope.knownErrors = {
            "The 'AnetApi/xml/v1/schema/AnetApiSchema.xsd:cardNumber' element is invalid": 'Card Format is invalid. Please verify your card details and try again.',
            'This transaction has been declined': 'Transaction declined. Please verify your card and billing address details and try again.'
        };

        $scope.vetRejectionTypes = {
            duplicate_signup: {
                error: 'duplicate_signup',
                text: 'You already have an account associated with that email, please log in instead.',
                buttons: ['Cancel', 'Login']
            },
            invalid_data: {
                error: 'invalid_data',
                text: 'Please check the information you provided and try again. Please chat with support if you need assistance.',
                buttons: ['Ok']
            },
            too_many_attempts: {
                error: 'too_many_attempts',
                text: 'You attempted to sign up to many times. Please wait 24 hours and try again or chat with support.',
                buttons: ['Ok']
            },
            high_risk: {
                error: 'high_risk',
                text: 'We need additional information to open your account. Please chat with support.',
                buttons: ['Ok']
            },
            unknown: {
                error: 'unknown',
                text: 'An error occurred. Please chat with support.',
                buttons: ['Ok']
            }
        };

        $scope.emailPattern = $rootScope.settings.USER_EMAIL_REGEX;
        $scope.passwordPattern = /(?=.*[A-Z])(?=.*[^.a-zA-Z\d])/;
        $scope.firstLastNamePattern = $rootScope.settings.USER_FIRST_LAST_NAME_REGEX;
        $scope.cardNumberPattern = /\b[0-9 ]{16,19}\b/;
        $scope.cardCodePattern = /\b[0-9]{3,4}\b/;
        $scope.monthPattern = /\b((0[1-9])|(1[0-2]))\b/;
        $scope.yearPattern = /\b(([0-9]{2}))\b/;

        $scope.validateAddress = function() {
            const { fullAddress, postalCode, value } = $scope.forms.address;
            if (!fullAddress || !postalCode) {
                return false;
            }

            return true;
        };

        $scope.validatePhoneNumber = function() {
            if (!$scope.forms.phone_number.iti) {
                return false;
            }

            const isValid = $scope.forms.phone_number.iti.isValidNumber();
            const value = (isValid) ? $scope.forms.phone_number.iti.getNumber(window.intlTelInput.utils.numberFormat.E164) : '';
            $scope.forms.phone_number.number = value;
            return isValid;
        };

        $scope.validateYear = function(year) {
            var currentYear = new Date().getFullYear();
            var fullYear = Number(year) + 2000;

            if(fullYear < currentYear) {
                return false;
            }
            return true;
        };

        $scope.clearSubmitError = function() {
            $scope.data.submitError.message = '';
            $scope.data.submitError.show = false;
        };

        $scope.setCompanySize = function (size) {
            $scope.forms.company_size.value = size;
            $scope.clearSubmitError();
        };

        $scope.initLocate = function() {
            const fullId = '#street-address-signup-form';

            return generalHelperService.waitForElement(fullId)
                .then(() => {
                    // Loqate's pca object is a global var and also injected into the window object.  Make sure it's loaded...
                    let __pca = pca || window.pca;
                    if (__pca) {
                        const options = {
                            key: $rootScope.settings.LOQATE_KEY,
                            setCountryByIP: true
                        };
                        const displayHtml = '{Line1}, {City} {Province} {PostalCode}, {CountryName}';
                        const addressFields = [ { element: 'street-address-signup-form', field: displayHtml } ];
                        const addressControl = new __pca.Address(addressFields, options);

                        addressControl.listen('populate', function(address, variations) {
                            $scope.forms.address.fullAddress = address;
                            $scope.forms.address.fullAddressLabel = address.Label;
                            $scope.forms.address.postalCode = address.PostalCode;

                            const streetAddressId = $('#street-address-signup-form');
                            streetAddressId.triggerHandler('change');
                            $('#street-address-signup-form').blur();
                        });
                    }
                });
        };

        $scope.setPhoneNumber = function() {
            const phoneNumberId = $('#phone_number');
            var number = $scope.data.phone_number;
            $scope.forms.phone_number.iti.setNumber(number);

            phoneNumberId.triggerHandler('change');
            if(!$scope.forms.phone_number.iti.isValidNumber() && number.length > 0) {
                phoneNumberId.blur();
            }
        };

        $scope.setEmail = function() {
            var emailFromLocalStorage = $scope.getFromLocalStorage('initial_email');
            var email = (emailFromLocalStorage) ? emailFromLocalStorage : $scope.data.work_email;
            $scope.forms.work_email.value = email;
        };

        $scope.setPhoneMask = function(elem, mask, placeholder) {
            elem.mask(mask, { placeholder: placeholder });
        };

        $scope.phoneNumberMask = function() {
            const fullId = '#phone_number';
            let phoneNumberId;
            return generalHelperService.waitForElement(fullId)
                .then(phoneNumberIdInput => {
                    phoneNumberId = $(fullId);
                    if (phoneNumberId) {
                        $scope.forms.phone_number.iti = window.intlTelInput(phoneNumberIdInput, {
                            nationalMode: 'false',
                            autoPlaceholder: 'aggressive',
                            separateDialCode: 'true',
                            validationNumberTypes: [
                                'FIXED_LINE',
                                'MOBILE',
                                'FIXED_LINE_OR_MOBILE',
                                'TOLL_FREE',
                                'PREMIUM_RATE',
                                'SHARED_COST',
                                'VOIP',
                                'PERSONAL_NUMBER',
                                'PAGER',
                                'UAN',
                                'VOICEMAIL',
                                'UNKNOWN'
                            ],
                            initialCountry: 'us',
                            // intl-tel-input is up to date as of dec 2024
                            loadUtils: () => import("https://cdn.jsdelivr.net/npm/intl-tel-input@25.2.0/build/js/utils.js"),
                            customPlaceholder: function (selectedCountryPlaceholder, selectedCountryData) {
                                const pl = selectedCountryPlaceholder.replace(/[0-9]/g, '0');
                                phoneNumberId.attr('maxlength', pl.length);
                                $scope.setPhoneMask(phoneNumberId, pl, pl);
                                return pl;
                            }
                        });
                        return $scope.forms.phone_number.iti.promise;
                    }
                })
                .then(() => {
                    if (phoneNumberId) {
                        $scope.setPhoneNumber();

                        phoneNumberId.on('countrychange', function (e) {
                            $scope.forms.phone_number.iti.setNumber('');
                            phoneNumberId.unmask();

                            const mask = e.target.placeholder.replace(/[0-9]/g, '0');
                            phoneNumberId.attr('maxlength', mask.length);
                            $scope.setPhoneMask(phoneNumberId, mask, e.target.placeholder);

                            phoneNumberId.triggerHandler('change');
                        });
                    }
                });
        };

        $scope.setInputMask = function(elemId, mask) {
            $('#' + elemId).mask(mask, {});
        };

        $scope.initPayment = function() {
            const fullId = '#ccyear';

            return generalHelperService.waitForElement(fullId)
                .then(() => {
                    $scope.setInputMask('ccyear', $scope.staticData.form.paymentMethod.masks.paymentYearMask);
                    $scope.setInputMask('ccmonth', $scope.staticData.form.paymentMethod.masks.paymentMonthMask);
                    $scope.setInputMask('cardnumber', $scope.staticData.form.paymentMethod.masks.cardNumberMask);
                    $scope.setInputMask('cvc', $scope.staticData.form.paymentMethod.masks.secureCodeMask);
                });
        };

        $scope.initStep = function() {
            switch($scope.step) {
                case '1':
                    $scope.setEmail();
                    break;
                case '2':
                    $scope.phoneNumberMask();
                    break;
                case '3':
                    Promise.all([
                        $scope.initLocate(),
                        $scope.phoneNumberMask(),
                        $scope.initPayment()
                    ]).then(()=>{
                        $scope.$apply();
                    });
                    break;
            }
        };

        $scope.removeFromLocalStorage = function(key) {
            $window.localStorage.removeItem(key);
        };

        $scope.getFromLocalStorage = function(key) {
            return $window.localStorage[key];
        };

        $scope.setToLocalStorage = function(key, value) {
            $window.localStorage[key] = value;
        };

        $scope.getLocationParameters = function() {
            let searchDictionary = $location.search();

            if (searchDictionary.email) {
                $scope.data.work_email = searchDictionary.email;
                $scope.forms.work_email.value = searchDictionary.email;
                $scope.setToLocalStorage('initial_email', searchDictionary.email);
            }

            if (searchDictionary.plan_id) {
                // Rewrite plans to only by monthly - this fixes any old links that may still be linking to annual plans
                $scope.data.plan_id = (searchDictionary.plan_id).replace('annual', 'monthly').replace('yearly', 'monthly');
            }

            if (searchDictionary.lead_id) {
                $scope.data.lead_id = searchDictionary.lead_id;
            }
            if (searchDictionary.lead_source) {
                $scope.data.lead_source = searchDictionary.lead_source;
            }
            const user_preferences = {
                lead: {},
                nav: {
                    inbox: true,
                    phoneNumbers: true,
                    contacts: true,
                    lists: true,
                    callRouting: true
                }
            };
            if (searchDictionary.rvm) {
                user_preferences.lead.rvm = searchDictionary.rvm;
                user_preferences.nav.rvmCampaigns = true;
                $scope.data.add_ons.push({
                    id: searchDictionary.plan_id,
                    quantity: 1
                });
            }
            if (searchDictionary.sms) {
                user_preferences.lead.sms = searchDictionary.sms;
                if (!$scope.data.add_ons.length) {
                    $scope.data.add_ons.push({
                        id: searchDictionary.plan_id,
                        quantity: 1
                    });
                }
            }
            if (searchDictionary.power_dialer || (searchDictionary.addon_id && searchDictionary.addon_id === 'auto-dialer-monthly')) {
                user_preferences.lead.powerDialer = searchDictionary.power_dialer || 1;
                user_preferences.nav.outboundCalling = true;
                $scope.data.add_ons.push({
                    id: 'auto-dialer-monthly',
                    quantity: searchDictionary.power_dialer || 1
                });
            }
            if (searchDictionary.predictive_dialer || (searchDictionary.addon_id && searchDictionary.addon_id === 'predictive-dialer-monthly')) {
                user_preferences.lead.predictiveDialer = searchDictionary.predictive_dialer || 1;
                user_preferences.nav.outboundCalling = true;
                $scope.data.add_ons.push({
                    id: 'predictive-dialer-monthly',
                    quantity: searchDictionary.predictive_dialer || 1
                });
            }
            if (searchDictionary.click_to_call || (searchDictionary.addon_id && searchDictionary.addon_id === 'front-office-monthly')) {
                user_preferences.lead.clickToCall = searchDictionary.click_to_call || 1;
                user_preferences.nav.outboundCalling = true;
                $scope.data.add_ons.push({
                    id: 'front-office-monthly',
                    quantity: searchDictionary.click_to_call || 1
                });
            }
            if (searchDictionary.local_numbers) {
                user_preferences.lead.localNumbers = searchDictionary.local_numbers;
                user_preferences.nav.outboundCalling = true;
                $scope.data.add_ons.push({
                    id: 'tn-local-monthly',
                    quantity: searchDictionary.local_numbers || 1
                });
            }
            if (searchDictionary.toll_free_numbers) {
                user_preferences.lead.tollFreeNumbers = searchDictionary.toll_free_numbers;
                user_preferences.nav.outboundCalling = true;
                $scope.data.add_ons.push({
                    id: 'tn-tollfree-monthly',
                    quantity: searchDictionary.toll_free_numbers || 1
                });
            }
            if (searchDictionary.voice) {
                user_preferences.lead.voice = searchDictionary.voice;
                user_preferences.nav.outboundCalling = true;
            }
            if (searchDictionary.mms) {
                user_preferences.lead.mms = searchDictionary.mms;
            }
            if (searchDictionary.call_tracking) {
                user_preferences.lead.callTracking = searchDictionary.call_tracking;
                user_preferences.nav.outboundCalling = true;
                user_preferences.nav.callTracking = true;
                user_preferences.nav.agentActivity = true;
                $scope.data.add_ons.push({
                    id: 'tn-local-monthly',
                    quantity: searchDictionary.call_tracking || 1
                });
            }
            if (searchDictionary.fund_amount) {
                user_preferences.lead.fundAmount = searchDictionary.fund_amount;
            }
            if(!$scope.data.add_ons.length) {
                $scope.data.add_ons.push({
                    id: 'front-office-monthly',
                    quantity: 1
                });
            }
            $scope.data.user_preferences = user_preferences;
        };

        $scope.signUp = function(acceptResponse) {

            if($scope.data.lead_id && $scope.forms.password.value) {

                let paymentMethod = $scope.forms.paymentMethod;

                let firstName = ($scope.forms.first_name.value || '').trim();
                let lastName = ($scope.forms.last_name.value || '').trim();

                let trimName = (firstName + ' ' + lastName);

                let tokenizedPaymentMethod = null;
                if (paymentMethod && paymentMethod.month) {
                    tokenizedPaymentMethod = {
                        type: "tmp_token",
                        name: trimName,
                        expiration_month: paymentMethod.month,
                        expiration_year: paymentMethod.year,
                        token: acceptResponse
                    };
                }
                // if (paymentMethod) {
                //     tokenizedPaymentMethod = {
                //         type: 'tmp_token',
                //         name: trimName,
                //         token: paymentMethod.token
                //     };
                // }

                $scope.signing_in = true;

                const { Line1, Line2, City, ProvinceCode, PostalCode, CountryIso2 } = $scope.forms.address.fullAddress;
                const billing_address = {
                    first_name: firstName,
                    last_name: lastName,
                    line1: Line1,
                    line2: Line2,
                    city: City,
                    state_code: ProvinceCode,
                    zip: PostalCode,
                    country: CountryIso2,
                    validation_status: 'valid'
                };

                let signUpReq = {
                    lead_id: $scope.data.lead_id,
                    password: $scope.forms.password.value,
                    billing_term: $scope.data.billing_term,
                    billing_address,
                    payment_method: tokenizedPaymentMethod,
                    // plan_id: $scope.data.plan_id,
                    plan_id: 'monthly-plan',
                    add_ons: $scope.data.add_ons,
                    trial: true,
                };

                accountService.trialSignUp(signUpReq)
                    .then(results => {
                        if (results.auth) {
                            $rootScope.initialState = 'checklist';
                            $rootScope.initialPath = '/checklist';
                            return authService.handleSuccesfulSigin(results.auth);
                        }
                        else {
                            return Promise.reject('Unable to complete sign-up');
                        }
                        $scope.loading.submit = false;
                        $scope.$apply();
                    })
                    .catch(err => {
                        $scope.signing_in = false;
                        $scope.loading.submit = false;
                        $scope.$apply();

                        //console.log(err);
                        swal(
                            'Unable to Sign Up',
                            'Please double check your information',
                            'error'
                        );
                    });
            }
        };

        $scope.deobfuscateCardErrors = function(error) {
            for (let knownError in $scope.knownErrors) {
                if(error.indexOf(knownError) !== -1) {
                    return $scope.knownErrors[knownError];
                }
            }
            return 'Unable to process transaction. Please verify your card details and try again.';
        };

        $scope.processAcceptResponse = function(response) {

            try {
                let res = response || {};
                let messages = res.messages || {};

                if (messages.resultCode === 'Error') {
                    let errors = '';
                    _.forEach(messages.message, function(errMsg) {
                        if (errors) {
                            errors += '; ';
                        }
                        errors += errMsg.text;
                        console.log(errMsg.code + ': ' + errMsg.text);
                    });
                    $scope.signing_in = false;

                    $scope.data.submitError.message = $scope.deobfuscateCardErrors(errors);
                    $scope.data.submitError.show = true;

                    $scope.loading.submit = false;
                    $scope.$apply();
                } else {
                    let data = res.opaqueData || {};
                    $scope.forms.paymentMethod.dataDescriptor = data.dataDescriptor;
                    $scope.forms.paymentMethod.dataValue = data.dataValue;

                    $scope.signUp(data.dataValue);
                }
            } catch (error) {
                console.log(error);
            }
        };

        $scope.initChargebee = function() {
            cbInstance.load('components').then(() => {
                const options = {
                    icon: true,
                    classes: {
                        focus: 'focus',
                        invalid: 'invalid',
                        empty: 'empty',
                        complete: 'complete',
                    }
                };
                // Mounting part
                $scope.cardComponent = cbInstance.createComponent('card', options);
                $scope.cardComponent.createField('number').at('#card-number');
                $scope.cardComponent.createField('expiry').at('#card-expiry');
                $scope.cardComponent.createField('cvv').at('#card-cvc');
                $scope.cardComponent.mount();

                $scope.cardComponent.on('change', function(event) {
                    if (event.cardType === 'amex') {
                        $scope.data.chargebeeErrorMessage = 'American Express cards are not accepted. Please use another card.';
                    } else {
                        $scope.data.chargebeeErrorMessage = null;
                    }
                    $scope.$apply();
                });
            });
        };

        $scope.tokenizeCreditCardAcceptJs = function() {

            let zip = ($scope.forms.address.postalCode || '').split('-')[0];

            let secureData = {
                cardData: {
                    cardNumber: $scope.forms.paymentMethod.cardNumber,
                    cardCode: $scope.forms.paymentMethod.cardCode,
                    month: $scope.forms.paymentMethod.month,
                    year: $scope.forms.paymentMethod.year,
                    zip: zip,
                    fullName: $scope.forms.paymentMethod.name,
                },
                authData: {
                    clientKey: $rootScope.settings.ACCEPT_CLIENT_KEY,
                    apiLoginID: $rootScope.settings.ACCEPT_API_LOGIN_ID
                }
            };

            Accept.dispatchData(secureData, $scope.processAcceptResponse);
        };

        $scope.tokenizeCreditCard = function() {

            $scope.forms.paymentMethod.cardNumber = $scope.forms.paymentMethod.formattedCardNumber.split(' ').join('');
            $scope.forms.paymentMethod.cardCode = $scope.forms.paymentMethod.formattedCardCode;
            $scope.forms.paymentMethod.month = $scope.forms.paymentMethod.formattedMonth;
            $scope.forms.paymentMethod.year = $scope.forms.paymentMethod.formattedYear;

            $scope.clearSubmitError();

            // Don't attempt to tokenize card data if we're using stored card or running localhost
            if ($rootScope.settings.RUNTIME_ENV === 'local') {
                $scope.signUp($rootScope.settings.RUNTIME_ENV);
            } else {
                $scope.tokenizeCreditCardAcceptJs();
            }
            // $scope.cardComponent.tokenize().then(data => {
            //     $scope.forms.paymentMethod.token = data.vaultToken;
            //     $scope.$apply();
            //
            //     $scope.signUp();
            // }).catch(error => {
            //     console.log(error);
            //     $scope.signingIn = false;
            //     $scope.loading.submit = false;
            //     $scope.$apply();
            //
            //     swal(
            //         'Unable to Validate Card Info',
            //         'Please double check your information',
            //         'error'
            //     );
            // });
        };

        $scope.sendTwilioVerification = function() {
            const number = $scope.forms.phone_number.iti ? $scope.forms.phone_number.iti.getNumber(window.intlTelInput.utils.numberFormat.E164) : $scope.forms.phone_number.value;

            accountService.twilioSendVerification({ to: number, email: $scope.forms.work_email.value })
                .then(results => {
                    $scope.loading.submit = false;
                    if (results.verificationResult && results.verificationResult.status === 'pending') {
                        $scope.forms.code_sent = true;
                    } else {
                        swal(
                            'Unable to Send Code',
                            'Please double check your information',
                            'error'
                        );
                    }
                })
                .catch(err => {
                    $scope.loading.submit = false;
                    if (err.message === 'already signed up with this phone number') {
                        swal(
                            'Unable to Send Code',
                            'Looks like you already have an account with this number',
                            'error'
                        );
                    } else {
                        swal(
                            'Unable to Send Code',
                            'Please double check your information',
                            'error'
                        );
                    }
                }).finally(() => {
                    $scope.$apply();
                });
        };
        $scope.verifyTwilioCode = function() {
            const number = $scope.forms.phone_number.iti ? $scope.forms.phone_number.iti.getNumber(window.intlTelInput.utils.numberFormat.E164) : $scope.forms.phone_number.value;

            accountService.twilioVerifyCode({ to: number, code: $scope.forms.phone_number.code })
                .then(results => {
                    $scope.loading.submit = false;
                    if (results.status === 'approved') {
                        $scope.step = '3';
                    } else {
                        swal(
                            'Unable to Verify Code',
                            'Please double check your code',
                            'error'
                        );
                    }
                })
                .catch(err => {
                    $scope.loading.submit = false;
                    console.log(err);
                    swal(
                        'Unable to Verify Code',
                        'Please double check your code',
                        'error'
                    );
                }).finally(() => {
                $scope.$apply();
            });
        };
        $scope.handleCodeKeydown = function() {
            if ($scope.forms.phone_number.code && $scope.forms.phone_number.code.length === 6) {
                $scope.loading.submit = true;
                $scope.verifyTwilioCode();
            }
            $scope.clearSubmitError();
        };

        $scope.vetResultRejectionAlert = function(vetResult) {
            $scope.signing_in = false;

            const errorKey = Object.keys($scope.vetRejectionTypes).find(key => key === vetResult.rejection_reason);
            if (errorKey && $scope.vetRejectionTypes[errorKey].buttons.length === 2) {
                swal({
                    title: 'Unable to Sign Up',
                    text: $scope.vetRejectionTypes[errorKey].text,
                    buttons: $scope.vetRejectionTypes[errorKey].buttons,
                }).then((confirm) => {
                    if (!confirm) { return; }

                    $location.url( "/signin" );
                    $scope.$apply();
                });
            } else if (errorKey) {
                swal({
                    title: 'Unable to Sign Up',
                    text: $scope.vetRejectionTypes[errorKey].text,
                });
            } else {
                swal(
                    'Unable to Sign Up',
                    'Please double check your information',
                    'error'
                );
            }
        };

        $scope.vet = function() {
            $scope.loading.submit = true;
            $scope.signing_in = true;

            async function execTalon() {
                await Talon.eHawkTalon();
                const talon = document.getElementById('talon6');
                const talonFingerprint = (talon || {}).value;
                const number = $scope.forms.phone_number.iti ? $scope.forms.phone_number.iti.getNumber(window.intlTelInput.utils.numberFormat.E164) : $scope.forms.phone_number.value;

                const vetReq = {
                    fingerprint_event_id: $rootScope.fingerprintRequestId,
                    talon: talonFingerprint,
                    email: $scope.forms.work_email.value,
                    password: $scope.forms.password.value,
                    first_name: $scope.forms.first_name.value,
                    last_name: $scope.forms.last_name.value,
                    phone_number: number,
                    company_name: $scope.forms.company_name.value,
                    company_size: $scope.forms.company_size.value,
                    fullAddress: $scope.forms.address.fullAddress,
                    fullAddressLabel: $scope.forms.address.fullAddressLabel,
                    postalCode: $scope.forms.address.postalCode,
                    funnel_step: $scope.step === '2' ? 2 : 1,
                    lead_source: $scope.data.lead_source,
                    user_preferences: $scope.data.user_preferences
                };

                accountService.trialVet(vetReq)
                    .then(results => {
                        let vetResult = results || {};
                        if(vetResult.rejection_reason) {
                            $scope.vetResultRejectionAlert(vetResult);
                            $scope.loading.submit = false;
                        } else {
                            $scope.data.lead_id = vetResult.lead_id;

                            switch($scope.step) {
                                case '1':
                                    $scope.setToLocalStorage('initial_email', $scope.forms.work_email.value);
                                    $scope.step = '2';
                                    $scope.loading.submit = false;
                                    break;
                                case '2':
                                    if (!$scope.forms.code_sent) {
                                        $scope.sendTwilioVerification();
                                    } else {
                                        $scope.verifyTwilioCode();
                                    }
                                    break;
                                case '3':
                                    $scope.setToLocalStorage('first_signin', true);
                                    $scope.setToLocalStorage('new_user_first_signin', true);
                                    $scope.tokenizeCreditCard();
                                    $scope.removeFromLocalStorage('initial_email');
                                    break;
                            }
                        }
                    })
                    .then(() => {
                        $scope.$apply();
                    });
            }
            execTalon();
        };

        $scope.verifyBackButton = function () {
            $scope.forms.code_sent = false;
        };

        $scope.submitForm = function(e) {
            e.preventDefault();

            if($scope.signupForm.$invalid) {
                return;
            }
            switch($scope.step) {
                case '1':
                    $scope.vet();
                    break;
                case '2':
                    $scope.vet();
                    break;
                case '3':
                    $scope.vet();
                    break;
            }
        };

        $scope.resetForm = function() {
            $scope.signupForm.$setPristine();
            $scope.signupForm.$setUntouched();
        };

        $scope.onInit = function() {
            if (authService.isAuthenticated()) {
                $state.go('main-wrapper.dash');
            }
            if (!authService.isAuthenticated() && localStorage.getItem('new_user_first_signin') === 'true') {
                authService.logoutOrlocalLogout();
            }
            $rootScope.loadFingerprintData();
            $scope.getLocationParameters();

            $scope.$watch('step', function(newStep) {
                if($scope.step !== '1' && (!$scope.data.lead_id || $scope.data.lead_id === null)) {
                    $scope.step = '1';
                }

                $scope.resetForm();
                $scope.initStep();
            });
            $scope.step = '1';
        };
        
        $scope.onInit();
    }

    module.exports = SignupStepsController;
})();
