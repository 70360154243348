(function() {
    'use strict';

    function AppMarketplaceController($rootScope, $scope, $location, $window, authService, automationService) {
        var vm = this;
        vm.authService = authService;

        $rootScope.viewData = $rootScope.viewData || {};
        $rootScope.viewData.breadcrumbs = $rootScope.viewData.breadcrumbs || [];
        $rootScope.viewData.breadcrumbs = [{
            title: 'Automation',
            link_name: 'Automation',
            link_url: '#/automation',
            help_url: $rootScope.help_url
        }, {
            title: 'App Marketplace',
            link_name: 'App Marketplace',
            link_url: '#/app-workflow',
        }];

        $scope.data = {
            sessionToken: $rootScope.cobalt.token || null,
            availableApps: [],
            selectedApp: null,
            configData: {}
        };
        $scope.loading = true;

        $scope.staticText = {
            title: 'App Marketplace',
            subtitle: 'Configure apps for your workflows...',
            table: {
                noRes: "You don't have any apps. ",
                loading: "Loading",
                actions: ['Expand']
            }
        };

        $scope.checkCompletion = (appId) => {
            // if all the inputs have a value, form is complete
            // getting entries for the appId, filtering out the formComplete/required keys, and non required inputs, and returning a bool based on data in the inputs
            $scope.data.configData[appId].formComplete = Object.entries($scope.data.configData[appId]).filter(([key, val]) => key !== 'formComplete' && key !== 'required' && val.required).every(([key, val]) => !!val.value);
        };

        $scope.setSelectedApp = (index) => {
            $scope.data.selectedApp = index === $scope.data.selectedApp ? null : index;
        };

        $scope.connectApp = (slug) => {
            $rootScope.cobalt.connect(slug);
        };

        $scope.initializeSession = () => {
            return automationService.getIpassSessionToken()
                .then(data => {
                    $rootScope.cobalt.token = data.token;
                    $scope.data.sessionToken = data.token;

                    $scope.$apply();

                    return data;
                })
                .catch(err => {
                    console.log(err);
                });
        };

        $scope.getApplications = () => {
            return automationService.getIpassApplications()
                .then(data => {
                    for (let dataIndex = 0; dataIndex < data.length; dataIndex++) {
                        const appConfig = {
                            formComplete: !data[dataIndex].auth_input_map.length // if there are no inputs, form is complete
                        };
                        for (let inputIndex = 0; inputIndex < data[dataIndex].auth_input_map.length; inputIndex++) {
                            appConfig[data[dataIndex].auth_input_map[inputIndex].name] = { required: data[dataIndex].auth_input_map[inputIndex].required, value: '' };
                        }
                        $scope.data.configData[data[dataIndex].app_id] = appConfig;
                    }
                    $scope.data.availableApps = data || [];
                    $scope.loading = false;

                    $scope.$apply();
                })
                .catch(err => {
                    console.log(err);

                    $scope.loading = false;
                    $scope.$apply();
                });
        };

        $scope.onInit = () => {
            $scope.initializeSession();
            $scope.getApplications();
        }

        if (authService.hasAccount()) {
            $scope.onInit();
        }
        else {
            $scope.$on('auth_complete', function(event, args) {
                $scope.onInit();
            });
        }
    }
    module.exports = AppMarketplaceController;
})();
