(function() {
    'use strict';

    function AutomationConfigTypePlaceholderListItemController(authService) {
        var vm = this;
        vm.authService = authService;

    }
    module.exports = AutomationConfigTypePlaceholderListItemController;
})();
