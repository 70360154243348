(function() {
    'use strict';

    function BreadCrumbController(authService, $rootScope, $scope, $location, generalHelperService) {
        var vm = this;
        vm.authService = authService;
        $scope.dontShowBreadCrumbsForUrls = [
            '#/inbox'
        ];

        $scope.breadcrunbBalanceTooltip = '<p class="breadcrumb-balance-tooltip mb-0">Use your account balance for ringless voicemail, bulk SMS, call routing, tracking, recording, and transcription. Discounts are available based on your usage plan subscription.</p>';
        
        $scope.viewData = $rootScope.viewData;
        $scope.user = $rootScope.user;
        $scope.currentPath = $location.path();

        $scope.isBreadCrumbsNeeded = function () {
            if ($scope.viewData.breadcrumbs.length && typeof $scope.viewData.breadcrumbs[$scope.viewData.breadcrumbs.length-1] !== 'undefined') {
                return !$scope.dontShowBreadCrumbsForUrls.includes($scope.viewData.breadcrumbs[$scope.viewData.breadcrumbs.length-1].link_url)
            }
            return true;
        };

        $scope.showAdHocModal = function() {
            $rootScope.showAdHocModal();
        };

        $scope.showWalkthrough = function() {
            $rootScope.showWalkthrough();
        };

        $scope.showAddFunds = function() {
            $rootScope.showAddFundsModal();
        };

        $scope.formatMoney = function(value) {
            return $rootScope.formatMoney(value);
        };

        $scope.toggleRightSideBar = function() {
            $rootScope.$broadcast('toggleRightSideBar');
        };

        $scope.destroyTooltip = function(selector) {
            const tooltip = angular.element(document)
                .find(selector + ' [data-toggle="tooltip"]');
            tooltip.tooltip('dispose');
        };

        $scope.initTooltip = function() {
            $scope.destroyTooltip('#breadcrumb-balance-tooltip');
            generalHelperService.waitForElement('#breadcrumb-balance-tooltip')
                .then(element => {
                    $(element).tooltip();
                });
        };

        $scope.onInit = function() {
            $scope.user = $rootScope.user;
            $scope.balance = $rootScope.balance;

            $scope.initTooltip();
        };

        $scope.onBalanceChange = function() {
            $scope.balance = $rootScope.balance;
        };

        $scope.$on('balance_change', function(event, args) {
            $scope.onBalanceChange();
        });

        $scope.$on('$locationChangeSuccess',
            function(event, newUrl, oldUrl) {
                $scope.currentPath = $location.path();
                $scope.initTooltip();
            }
        );

        if (authService.hasAccount()) {
            $scope.onInit();
        }
        else {
            $scope.$on('auth_complete', function(event, args) {
                $scope.onInit();
            });
        }
    }
    module.exports = BreadCrumbController;
})();
