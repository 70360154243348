(function () {
    'use strict';

    function EmailService(authService, apiService, $rootScope) {
        return {
            createEmail: function (request) {
                return apiService.post($rootScope.settings.API_EMAIL_URL + '/email', request, {send_as_form_data: true});
            },
        };
    }
    module.exports = EmailService;
})();
