(function() {
    'use strict';

    function AddEditUserModalController($rootScope, $scope, authService, userService, contactService, integrationService, $http) {
        var vm = this;
        vm.authService = authService;

        $scope.staticContent = {
            createTitle: 'Create User',
            editTitle: 'Edit User',
            labels: [
                'First Name',
                'Last Name',
                'Email',
                'Office Phone Number',
                'Home Phone Number',
                'Mobile Phone Number',
                'Username',
                'Password',
                'Mobile Access Pin',
                'Role',
                'License Type',
                'Password Again',
            ],
            roles: {
                Admin: 'full access',
                Manager: 'full access minus ability to change billing',
                Agent: 'read/write access to contact data & full phone privileges',
                Telemarketer: 'read only access to contact data & full phone access',
                Analyst: 'read only access to the portal'
            },
            actions: [ 'Save', 'Create' ],
            plansBlock: {
                title: 'Please select an available license '
            },
            loading: 'Loading',
            saving: 'Saving',
            creating: 'Creating',
            errorMessages: {
                password: {
                    errorMessageRequired: 'Password is required',
                    errorMessageMinLength: 'Password must be at least 8 characters long',
                    errorMessagePattern: 'Password must contain at least one capital letter and one character',
                },
                password_again: {
                    errorMessage: 'Passwords do not match',
                },
            }
        };

        $scope.data = {
            timer: null,
            timeoutVal: 200,
            userId: null,
            selectedPlan: {},
            selectedRole: undefined,
            teamRoles: null,
            plans: [],
            showPassword: false,
            phoneNumbers: {
                office: { iti: undefined },
                home: { iti: undefined },
                mobile: { iti: undefined }
            },
            email: {
                is_valid: undefined
            },
            first_name: {
                is_valid: undefined
            },
            last_name: {
                is_valid: undefined
            },
            userForm : {
                username: '',
                password: '',
                password_again: '',
                first_name: '',
                last_name: '',
                email: '',
                pin: '',
                role_id: '',
                chargebee: '',
                office_phone: '',
                home_phone: '',
                mobile_phone: '',
                office_phone_can_dial: '',
                home_phone_can_dial: '',
                mobile_phone_can_dial: '',
                status: '',
                addon_id: '',
                pending_addon_id: '',
            },
            username_exists: false,
            formSubmitted: false,
            formLocked: false,
            loadingUser: false,
            updatingUser: false,
            creatingUser: false,
            showLicenseType: true,
            isCurrentUser: false,
            integrations: {}
        };

        $scope.passwordPattern = /(?=.*[A-Z])(?=.*[^.a-zA-Z\d])/;

        $scope.togglePassword = function () {
            $scope.data.showPassword = !$scope.data.showPassword;
        };

        $scope.submitSuccess = function(alertMessage, showContinueOrCheckoutSwal) {
            swal(alertMessage.title, alertMessage.body, 'success');
            var loader = ($scope.data.mode === 'edit') ? 'updatingUser' : 'creatingUser';
            $scope.data[loader] = false;
            $scope.data.formSubmitted = false;
            $('#addEditUserModal').modal('hide');
            $rootScope.$broadcast('addEditUserEventDone', $scope.data.mode, true, showContinueOrCheckoutSwal);
            $scope.$apply();
        };
        $scope.submitError = function(alertMessage) {
            swal(alertMessage.title, alertMessage.body, 'error');
            var loader = ($scope.data.mode === 'edit') ? 'updatingUser' : 'creatingUser';
            $scope.data[loader] = false;
            $scope.data.formSubmitted = false;
            $rootScope.$broadcast('addEditUserEventDone', $scope.data.mode, false);
            $scope.$apply();
        };

        $scope.createUser = function(_teamId, _userId) {
            let request = {};
            for (let field in $scope.data.userForm) {
                request[field] = $scope.data.userForm[field];
            }
            request.team_id = _teamId;
            request.user_id = _userId;
            request.selected_plan = $scope.data.selectedPlan.id;
            request.pending_addon_id = $scope.data.selectedPlan.id;
            request.status = (request.addon_id === "inactive-monthly") ? "DISABLED" : "ENABLED";
            const showContinueOrCheckoutSwal = request.pending_addon_id !== "back-office-monthly" && request.pending_addon_id !== "inactive-monthly";

            userService.createUser(request)
                .then(user => {
                    var alertMessage = {
                        title: 'Created',
                        body: 'The user ' + request.username.toLowerCase() + ' has been added.'
                    };
                    $scope.submitSuccess(alertMessage, showContinueOrCheckoutSwal);
                })
                .catch(err => {
                    // console.error(err);
                    var alertMessage = {
                        title: 'Error',
                        body: err.message ? err.message : 'Username already taken.  Please select a different username.'
                    };
                    $scope.submitError(alertMessage);
                });
        };

        $scope.updateUser = function(_teamId, _userId) {
            let showContinueOrCheckoutSwal = false;
            let request = {};
            for (let field in $scope.data.userForm) {
                request[field] = $scope.data.userForm[field];
            }

            if (!request.pin) {
                delete request.pin;
            }

            delete request.password_again;
            if (!request.password) {
                delete request.password;
            }

            request.team_id = _teamId;
            request.user_id = _userId;
            request.updated_user_id = $scope.data.userId;
            request.needs_setup = ($scope.data.userId === null || !$scope.data.userId) ? true : false;
            if ($scope.data.selectedPlan && $scope.data.selectedPlan.id !== $scope.data.userForm.addon_id) {
                request.selected_plan = $scope.data.selectedPlan.id;
                request.pending_addon_id = $scope.data.selectedPlan.id;
                showContinueOrCheckoutSwal = request.pending_addon_id !== "back-office-monthly" && request.pending_addon_id !== "inactive-monthly";
            }
            request.status = (request.addon_id === "inactive-monthly") ? "DISABLED" : "ENABLED";

            userService.updateUser(request)
                .then(user => {
                    var alertMessage = {
                        title: 'Saved',
                        body: 'The user ' + request.username + ' has been updated.'
                    };
                    $scope.submitSuccess(alertMessage, showContinueOrCheckoutSwal);
                })
                .catch(err => {
                    console.error(err);
                    var alertMessage = {
                        title: 'Error',
                        body: err.message ? err.message : 'We were unable to update the user. Try again later.'
                    };
                    $scope.submitError(alertMessage);
                });
        };

        $scope.submitForm = function() {
            $scope.data.formSubmitted = true;
            $scope.isValidPhoneNumber('office');
            $scope.isValidPhoneNumber('home');
            $scope.isValidPhoneNumber('mobile');
            $scope.isValidEmail();
            $scope.isValidFirstAndLastName();

            var phoneNumbersValid = $scope.data.phoneNumbers.office.is_valid || $scope.data.phoneNumbers.home.is_valid || $scope.data.phoneNumbers.mobile.is_valid;

            if (
                $scope.addEditUserForm.$valid
                && phoneNumbersValid
                && $scope.data.email.is_valid
                && $scope.data.first_name.is_valid
                && $scope.data.last_name.is_valid
                && !$scope.data.username_exists
            ) {
                var loader = ($scope.data.mode === 'edit') ? 'updatingUser' : 'creatingUser';
                $scope.data[loader] = true;
                var _teamId = authService.getTeamId();
                var _userId = authService.getUserId();

                $scope.data.userForm.role_id = $scope.data.selectedRole.role_id;

                var mainFunction = ($scope.data.mode === 'edit') ? 'updateUser' : 'createUser';
                $scope[mainFunction](_teamId, _userId);
            }
        };

        $('#addEditUserModal').on('hidden.bs.modal', function () {
            $scope.clearIti('office');
            $scope.clearIti('home');
            $scope.clearIti('mobile');
            $scope.data.formSubmitted = false;
            $scope.data.updatingContact = false;
            $scope.data.creatingContact = false;
            $scope.data.username_exists = false;
            $scope.data.phoneNumbers = {
                office: { iti: undefined },
                home: { iti: undefined },
                mobile: { iti: undefined }
            };
            $scope.data.userForm = {
                username: '',
                password: '',
                password_again: '',
                first_name: '',
                last_name: '',
                email: '',
                pin: '',
                role_id: '',
                chargebee: '',
                office_phone: '',
                home_phone: '',
                status: '',
                addon_id: '',
                pending_addon_id: '',
            };
            $scope.data.userId = '';
            $scope.data.selectedPlan = null;
            $scope.data.teamRoles = null;

            $scope.addEditUserForm.$setPristine();
            $scope.addEditUserForm.$setUntouched();
        });

        $scope.clearIti = function(numberType) {
            ['office', 'home', 'mobile'].forEach(numberType => {
                $(`#user-${numberType}-phone`).off('countrychange');
            })
            $scope.data.phoneNumbers[numberType].is_valid = undefined;
            $scope.data.phoneNumbers[numberType].iti.setNumber('');
            $('#user-' + numberType + '-phone').unmask();
            $scope.data.phoneNumbers[numberType].iti.destroy();
        };

        $scope.setPlan = function(plan) {
            $scope.data.selectedPlan = plan;
        };

        $scope.isValidPin = function() {
            let existingUser = ($scope.data.mode !== 'create');
            if (existingUser && !$scope.data.userForm.pin) {
                return true;
            }

            if(!$scope.data.userForm.pin) { return false; }

            var pinLength = $scope.data.userForm.pin.toString().length;
            return (pinLength >= 3) && (pinLength <= 9);
        };

        $scope.isValidPinErrorContent = function() {
            if(!$scope.data.userForm.pin) { return "This field is required."; }
            var pinLength = $scope.data.userForm.pin.toString().length;
            switch (true) {
                case (pinLength === 0):
                    return "This field is required.";
                case (pinLength < 3):
                    return "This field can't have less than 3 symbols.";
                case (pinLength > 9):
                    return "This field can't have more than 9 symbols.";
            }
        };

        $scope.isValidPhoneNumber = function(numberType) {
            $scope.data.phoneNumbers[numberType].is_valid = $scope.data.phoneNumbers[numberType].iti.isValidNumber();
        };

        $scope.isValidEmail = function () {
            $scope.data.email.is_valid = true;
            const regEmail = $rootScope.settings.USER_EMAIL_REGEX;
            if (!regEmail.test($scope.data.userForm.email)) {
                $scope.data.email.is_valid = false;
                return;
            }
        };

        $scope.isValidFirstAndLastName = function () {
            $scope.data.first_name.is_valid = true;
            $scope.data.last_name.is_valid = true;

            const regFirstLastName = $rootScope.settings.USER_FIRST_LAST_NAME_REGEX;
            if (!regFirstLastName.test($scope.data.userForm.first_name)) {
                $scope.data.first_name.is_valid = false;
            }
            if (!regFirstLastName.test($scope.data.userForm.last_name)) {
                $scope.data.last_name.is_valid = false;
            }
        };

        $scope.formatPhone = function(numberType, variableInForm) {
            if($scope.data.phoneNumbers[numberType].iti) {
                $scope.isValidPhoneNumber(numberType);
                $scope.data.phoneNumbers[numberType].number = ($scope.data.phoneNumbers[numberType].is_valid) ? $scope.data.phoneNumbers[numberType].iti.getNumber(window.intlTelInput.utils.numberFormat.E164) : '';
                $scope.data.userForm[variableInForm] = $scope.data.phoneNumbers[numberType].number;
            }
        };

        $scope.setPhoneMask = function(elem, mask, placeholder) {
            elem.mask(mask, {
                placeholder: placeholder
            });
        };

        $scope.phoneNumberMask = function(number, numberType, variableInForm) {
            var phoneNumberId = $('#user-' + numberType + '-phone');
            var phoneNumberIdInput = document.querySelector('#user-' + numberType + '-phone');

            if(phoneNumberId) {
                $scope.data.phoneNumbers[numberType].iti = window.intlTelInput(phoneNumberIdInput, {
                    nationalMode: 'false',
                    autoPlaceholder: 'aggressive',
                    separateDialCode: 'true',
                    validationNumberTypes: [
                        'FIXED_LINE',
                        'MOBILE',
                        'FIXED_LINE_OR_MOBILE',
                        'TOLL_FREE',
                        'PREMIUM_RATE',
                        'SHARED_COST',
                        'VOIP',
                        'PERSONAL_NUMBER',
                        'PAGER',
                        'UAN',
                        'VOICEMAIL',
                        'UNKNOWN'
                    ],
                    initialCountry: 'us',
                    // intl-tel-input is up to date as of dec 2024
                    loadUtils: () => import("https://cdn.jsdelivr.net/npm/intl-tel-input@25.2.0/build/js/utils.js"),
                    customPlaceholder: function(selectedCountryPlaceholder, selectedCountryData) {
                        var pl = selectedCountryPlaceholder.replace(/[0-9]/g, '0');
                        phoneNumberId.attr('maxlength', pl.length);
                        $scope.setPhoneMask(phoneNumberId, pl, pl);
                        return pl;
                    }
                });

                if(number){
                    $scope.data.phoneNumbers[numberType].iti.setNumber(number);
                    $scope.data.userForm[variableInForm] = number;
                }

                phoneNumberId.on('countrychange', function(e) {
                    $scope.data.phoneNumbers[numberType].iti.setNumber('');
                    phoneNumberId.unmask();

                    var mask = e.target.placeholder.replace(/[0-9]/g, '0');
                    phoneNumberId.attr('maxlength', mask.length);
                    $scope.setPhoneMask(phoneNumberId, mask, e.target.placeholder);
                });
            }
        };

        $scope.setUserNumbers = function(officePhone, homePhone, mobilePhone) {
            $scope.phoneNumberMask(officePhone, 'office', 'office_phone');
            $scope.phoneNumberMask(homePhone, 'home', 'home_phone');
            $scope.phoneNumberMask(mobilePhone, 'mobile', 'mobile_phone');
        };

        $scope.loadUser = function(userId) {
            $scope.data.loadingUser = true;
            const userReq = {
                target_user_id: userId,
                team_id: authService.getTeamId(),
                user_id: authService.getUserId()
            };

            userService.getUser(userReq)
                .then(user => {
                    $scope.data.user = user;
                    $scope.data.userForm = {
                        username: user.username,
                        password: '',
                        password_again: '',
                        first_name: user.identity.first_name,
                        last_name: user.identity.last_name,
                        email: user.identity.email,
                        pin: user.auth.pin || '',
                        role_id: user.role_id,
                        office_phone: user.identity.office_phone,
                        home_phone: user.identity.home_phone,
                        mobile_phone: user.identity.mobile_phone,
                        office_phone_can_dial: user.identity.office_phone_can_dial,
                        home_phone_can_dial: user.identity.home_phone_can_dial,
                        mobile_phone_can_dial: user.identity.mobile_phone_can_dial,
                        addon_id: user.addon_id,
                    };
                    $scope.data.selectedRole = _.find($scope.data.teamRoles, function(o) { return o.role_id === user.role_id; });
                    $scope.setUserNumbers($scope.data.userForm.office_phone, $scope.data.userForm.home_phone, $scope.data.userForm.mobile_phone);
                    $scope.initPlans(user.addon_id);
                    $scope.checkAllowDialerAccess();
                    // disable user role if we edit current user
                    if (authService.getUserId() === userId) {
                        $scope.data.isCurrentUser = true;
                    }
                    $scope.data.loadingUser = false;
                    $scope.$apply();
                })
                .catch(err => {
                    $scope.data.loadingUser = false;
                    console.log(err);
                });
        };

        $scope.userExists = function() {
            $scope.data.formLocked = true;

            const userReq = {
                username: $scope.data.userForm.username,
                team_id: authService.getTeamId(),
                user_id: authService.getUserId(),
                current_user_id: $scope.data.userId
            };

            userService.exists(userReq)
                .then(results => {
                    if (!$scope.data.user || $scope.data.userForm.username !== $scope.data.user.username) {
                        $scope.data.username_exists = results.exists;
                    }
                    $scope.data.formLocked = false;
                    $scope.$apply();
                })
                .catch(err => {
                    console.log(err);
                    $scope.data.formLocked = false;
                });
        };

        $scope.initEditMode = function(userId) {
            $scope.data.userId = userId;
            $scope.loadUser(userId);
        };

        $scope.initRoles = function() {
            $scope.data.teamRoles = authService.getUser().team.roles;
            const nRoles = $scope.data.teamRoles.length;
            if (nRoles) {
                $scope.data.userForm.role_id = $scope.data.teamRoles[nRoles-1].role_id;
                $scope.data.selectedRole = _.find($scope.data.teamRoles, function(o) { return o.role_id === $scope.data.userForm.role_id; });
            }
        };

        $scope.initPlans = function(userPackage) {

            let user = authService.getUser();
            let chargebee = (user.team || {}).chargebee || {};
            $scope.data.utilization = chargebee.utilization || {};
            $scope.data.plans = [];

            let packages = PRODUCT_PRICING[chargebee.plan_id] && PRODUCT_PRICING[chargebee.plan_id].packages || {};
            const activeShopifyIntegration = $scope.data.integrations['shopify'] && $scope.data.integrations['shopify'].active;

            for (let itemId in packages) {
                let item = packages[itemId];
                if (item.isUserLicense
                    && !(user.owner && item.isInactiveLicense)
                    && ((activeShopifyIntegration && item.name === 'Shopify User') || (!activeShopifyIntegration && item.name !== 'Shopify User'))
                ) {
                    $scope.data.plans.push({
                        name: item.name,
                        id: itemId
                    });
                }
            }

            if (userPackage) {
                $scope.data.selectedPlan = {
                    name: (PRODUCT_PRICING[chargebee.plan_id] && PRODUCT_PRICING[chargebee.plan_id].packages[userPackage] || {}).name,
                    id: userPackage
                }
            }
        };

        $scope.checkAllowDialerAccess = function() {
            // list of checkboxes names
            const dialerAccessPhoneNames = [
                'home_phone_can_dial',
                'office_phone_can_dial',
                'mobile_phone_can_dial'
            ];
            // enabled all checkboxes
            $scope.isAllowDialerAccessDisabled = {
                home_phone_can_dial: false,
                office_phone_can_dial: false,
                mobile_phone_can_dial: false
            };
            // get available count of checkboxes
            let countAvailablePhoneNumbers = 1;

            // get count of checked
            const checkedPhoneNumbers = dialerAccessPhoneNames.filter(item => $scope.data.userForm[item]);
            // disable others checkboxes
            if (checkedPhoneNumbers.length === countAvailablePhoneNumbers) {
                dialerAccessPhoneNames
                    .filter(item => !checkedPhoneNumbers.includes(item))
                    .forEach(item => {
                        $scope.isAllowDialerAccessDisabled[item] = true;
                    });
            }
        };

        $scope.resetFormErrors = function() {
            $scope.data.email.is_valid = true;
            $scope.data.first_name.is_valid = true;
            $scope.data.last_name.is_valid = true;
            $scope.data.phoneNumbers.office.is_valid = true;
            $scope.data.phoneNumbers.home.is_valid = true;
            $scope.data.phoneNumbers.mobile.is_valid = true;
        };

        $scope.$on('editUserEvent', function(event, userId) {
            $scope.data.mode = 'edit';
            $scope.data.showLicenseType = true;
            $scope.data.isCurrentUser = false;
            $scope.initRoles();
            $scope.initEditMode(userId);
            $scope.addEditUserForm.$setPristine(true);
            $scope.resetFormErrors();
            $('#addEditUserModal').modal('show');
        });

        $scope.$on('addUserEvent', function(event, userReq) {
            $scope.data.mode = 'create';
            $scope.data.showLicenseType = true;
            $scope.data.isCurrentUser = false;
            $scope.initRoles();
            $scope.initPlans();
            $scope.checkAllowDialerAccess();
            $scope.setUserNumbers('', '', '');
            $scope.data.userForm.role_id = userReq.role_id;
            $scope.data.userForm.status = userReq.status;
            $scope.data.userForm.addon_id = userReq.addon_id;
            $scope.data.userForm.pending_addon_id = userReq.pending_addon_id;
            const defaultPlan = $scope.data.plans.find(plan => plan.id === userReq.pending_addon_id);
            if (typeof defaultPlan !== 'undefined') {
                $scope.data.selectedPlan = defaultPlan;
            }
            $scope.addEditUserForm.$setPristine(true);
            $scope.resetFormErrors();
            $('#addEditUserModal').modal('show');
        });

        $scope.$on('editUserProfileEvent', function(event, userId) {
            $scope.data.mode = 'edit';
            $scope.data.showLicenseType = false;
            $scope.data.isCurrentUser = false;
            $scope.initRoles();
            $scope.initEditMode(userId);
            $scope.addEditUserForm.$setPristine(true);
            $scope.resetFormErrors();
            $('#addEditUserModal').modal('show');
        });

        $scope.getWebhooks = () => {
            return integrationService.list()
                .then(res => {
                    integrationService.set(res);

                    $scope.data.integrations = integrationService.get();
                })
        }

        $scope.onInit = function() {
            $scope.getWebhooks()
        }

        if (authService.hasAccount()) {
            $scope.onInit();
        }
        else {
            $scope.$on('auth_complete', function(event, args) {
                $scope.onInit();
            });
        }

    }
    module.exports = AddEditUserModalController;
})();
