(function() {
    'use strict';

    function UpdateShopifyPolicyModalController($rootScope, $scope, $window, $cookies, authService, integrationService) {

        var vm = this;
        vm.authService = authService;

        $scope.data = {
            shopifyData: {
                storeName: null,
            },
            updateForm: {
                validPolicy: false,
                marketingConsentEnabled: false
            },
            shopifyLinks: {
                themePage: null,
                checkoutSettingPage: null
            }
        };

        $scope.loading = {
            updateLoading: false,
        };

        $scope.staticData = {
            title: 'Update Your Shopify Policy and Marketing Consent',
            loader: 'Loading',
            submitButton: 'Submit',
            copyButton: 'Copy to Clipboard',
            step1: {
                title: 'Step 1: Update Your Privacy Policy',
                subtitle: 'To update your privacy policy, follow these steps:'
            },
            step2: {
                title: 'Step 2: Enable SMS Marketing Consent',
                subtitle: 'To enable SMS marketing consent at checkout:'
            },
            updatePolicyInstructions: [
                [
                    'Go to ',
                    'Shopify Theme Editor',
                    '.'
                ],
                'Find and click the "Customize" button on your current theme.',
                'At the top of the screen, click the dropdown that says "Home page".',
                'Search for "Privacy Policy", and click on the "Policy page".',
                'In the "Footer" area, click "Add section".',
                'Navigate to the "Apps" tab on the section pop-up, and click the "Privacy Policy Block" by Drop Cowboy.',
                'Save your changes.',
            ],
            enableMarketingConsentInstructions: [
                [
                    'Go to ',
                    'Shopify Checkout Settings',
                    '.'
                ],
                'Scroll down to the "Marketing Options" section.',
                'Scroll down to the "Marketing Options" section.',
                'Enable the checkbox for "SMS" in the "Marketing options" section.',
                'Save your changes.',
                'In the same "Marketing options" section, click the "Customize labels" button.',
                'Scroll down to the "Checkout marketing" section.',
                'Copy and paste the following into the "Accept SMS checkbox label" input.',
                'Save your changes.',
            ],
            marketingConsentText: [
                'I am providing my ESIGN signature and express written consent agreement to permit',
                'to contact me at the number provided below for marketing purposes including through the use of automated technology, SMS/MMS messages, AI generative voice, and prerecorded and/or artifical voice messages. I acknowledge my consent is not required to obtain any good or service.'
            ],
            statements: {
                validPolicyStatement: 'I attest to having a valid privacy policy',
                marketingConsentStatement: 'I attest to having a valid marketing consent opt-in'
            }
        };

        $scope.copyMarketingConsent = function() {
            const text = `${$scope.staticData.marketingConsentText[0]} ${$scope.data.shopifyData.storeName} ${$scope.staticData.marketingConsentText[1]}`;

            navigator.clipboard.writeText(text)
                .then(() => {
                    swal('Copied to Clipboard', 'The consent verbiage has been copied!', 'success');
                })
                .catch(err => {
                    console.error("Failed to copy:", err);
                });
        };

        $scope.submit = function() {
            $scope.loading.updateLoading = true;

            const request = {
                valid_privacy_policy: $scope.data.updateForm.validPolicy,
                marketing_consent_enabled: $scope.data.updateForm.marketingConsentEnabled,
            };

            integrationService.shopifyUpdatePolicy(request)
                .then(res => {
                    $scope.loading.updateLoading = false;
                    $('#update-shopify-policy-modal').modal('hide');
                    $scope.$apply();
                })
                .catch(err => {
                    console.log(err);
                    $scope.loading.updateLoading = false;
                    $scope.$apply();
                });
        };

        $scope.onInit = function() {
            const shopifyIntegration = integrationService.get()['shopify'];

            const shopDomain = shopifyIntegration.integration_foreign_id;
            const storeName = (shopDomain || '').split('.')[0];
            $scope.data.shopifyData.storeName = storeName;
            $scope.data.shopifyLinks.themePage = `https://admin.shopify.com/store/${storeName}/themes`;
            $scope.data.shopifyLinks.checkoutSettingPage = `https://admin.shopify.com/store/${storeName}/settings/checkout`;

            if (shopifyIntegration.public_data) {
                $scope.data.updateForm.validPolicy = shopifyIntegration.public_data.valid_privacy_policy || false;
                $scope.data.updateForm.marketingConsentEnabled = shopifyIntegration.public_data.marketing_consent_enabled || false;
            }
        };

        if (integrationService.get()['shopify']) {
            $scope.onInit();
        }
        else {
            $scope.$on('integrations_seeded', function(event, args) {
                $scope.onInit();
            });
        }
    }

    module.exports = UpdateShopifyPolicyModalController;
})();
