(function() {
    'use strict';

    function RecentSmsTypePlaceholderListItemController(authService) {
        var vm = this;
        vm.authService = authService;

    }
    module.exports = RecentSmsTypePlaceholderListItemController;
})();
