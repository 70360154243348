(function() {
    'use strict';

    function CreateWebhookModalController($rootScope, $scope, integrationService, automationService) {
        var vm = this;

        $scope.data = {
            saving: false,
            isFormSubmitted: false,
            modalElementId: '#createWebhook',
            webhookId: null,
            availableWebhookEvents: automationService.getEventsNames()
        }

        $scope.forms = {
            webhook_name: {
                value: '',
                is_valid: true
            },
            webhook_url: {
                value: '',
                is_valid: true
            },
            use_auth: {
                value: false,
                is_valid: true
            },
            username: {
                value: null,
                is_valid: true
            },
            password: {
                value: null,
                is_valid: true
            },
            event_types: {
                value: [],
                is_valid: true,
            },
        };

        $scope.onInit = () => {
            $($scope.data.modalElementId).on('show.bs.modal', function (e) {
                $scope.data.webhookId = $(e.relatedTarget).data('webhook-id');
                if ($scope.data.webhookId) {
                    const webhookToEdit = integrationService.get()['webhook'].find(webhook => webhook.integration_id === $scope.data.webhookId);
                    if (webhookToEdit) {
                        $scope.forms.webhook_name.value = webhookToEdit.public_data.webhook_name;
                        $scope.forms.webhook_url.value = webhookToEdit.public_data.webhook_url;
                        $scope.forms.use_auth.value = webhookToEdit.public_data.use_auth;
                        $scope.forms.username.value = webhookToEdit.public_data.username;
                        $scope.forms.password.value = webhookToEdit.public_data.password;
                        $scope.forms.event_types.value = webhookToEdit.public_data.event_types;
                    }
                }
            });
            $($scope.data.modalElementId).on('hide.bs.modal', function (e) {
                $scope.resetForm();
            });
        }

        $scope.onInit();

        $scope.resetForm = () => {
            $scope.data.isFormSubmitted = false;
            $scope.forms = {
                webhook_name: {
                    value: '',
                    is_valid: true
                },
                webhook_url: {
                    value: '',
                    is_valid: true
                },
                use_auth: {
                    value: false,
                },
                username: {
                    value: null,
                    is_valid: true
                },
                password: {
                    value: null,
                    is_valid: true,
                    input_type: 'password'
                },
                event_types: {
                    value: [],
                    is_valid: true,
                },
            };
        }

        $scope.validateControl = (type) => {
            if ($scope.data.isFormSubmitted) {
                $scope.forms[type].is_valid = !!$scope.forms[type].value;
            }
        }

        $scope.changePasswordInputType = () => {
            if ($scope.forms.use_auth.value) {
                if ($scope.forms.password.input_type === 'password' || !$scope.forms.password.input_type) {
                    $scope.forms.password.input_type = 'text';
                } else {
                    $scope.forms.password.input_type = 'password';
                }
            }
        }

        $scope.changeUseAuth = () => {
            $scope.data.isFormSubmitted = false;
            $scope.forms.username.value = null;
            $scope.forms.password.value = null;
        }

        $scope.createWebhook = () => {
            $scope.data.isFormSubmitted = true;

            $scope.validateControl('webhook_name');
            $scope.validateControl('webhook_url');

            $scope.validateControl('username');
            $scope.validateControl('password');

            if (!$scope.forms.webhook_name.is_valid || !$scope.forms.webhook_url.is_valid || ($scope.forms.use_auth.value && (!$scope.forms.username.is_valid || !$scope.forms.password.is_valid))) {
                return;
            }
            $scope.data.saving = true;
            const req = Object.keys($scope.forms).reduce((reqObj, key) => {
                reqObj[key] = $scope.forms[key].value;
                return reqObj;
            }, {});

            let promise = Promise.resolve();
            if ($scope.data.webhookId) {
                promise = promise
                    .then(() => integrationService.updateWebhook($scope.data.webhookId, req))
            } else {
                promise = promise
                    .then(() => integrationService.createWebhook(req))
            }

            promise
                .then(() => {
                    $scope.data.saving = false;
                    $($scope.data.modalElementId).modal('hide');
                })

        }
    }
    module.exports = CreateWebhookModalController;
})();
