(function () {
    'use strict';

    function ScriptController(authService, domainService, templateService, $rootScope, $scope, $http, notificationToastService) {

        var vm = this;
        vm.authService = authService;

        $rootScope.viewData = $rootScope.viewData || {};
        $rootScope.viewData.breadcrumbs = $rootScope.viewData.breadcrumbs || [];
        $rootScope.viewData.breadcrumbs = [{
            title: 'Scripts',
            link_name: 'Scripts',
            link_url: '#/scripts',
            help_url: $rootScope.help_url
        }];

        $scope.staticContent = {
            title: 'Agent Scripts',
            subtitle: 'Manage your call scripts...',
            addScriptButton: 'Add Script',
            loading: 'Loading',
            saving: 'Saving',
            noRes: 'Click the \'Add Script\' to get started.',
            actions: ['Delete Script', 'Edit', 'Save']
        };

        $scope.loading = {
            scriptsFirstLoad: true,
            scripts: false
        };
        $scope.data = {
            scripts: []
        };

        $scope.paginationScripts = {
            itemsPerPage: 15,
            currentPage: 1,
            maxSize: 3,
            totalItems: 0
        };

        $scope.contactAttributes = [
            '{{ contact.main_phone }}',
            '{{ contact.secondary_phone }}',
            '{{ contact.first_name }}',
            '{{ contact.last_name }}',
            '{{ contact.company }}',
            '{{ contact.email }}',
            '{{ contact.fax_number }}',
            '{{ contact.address }}',
            '{{ contact.suite/apt }}',
            '{{ contact.city }}',
            '{{ contact.state }}',
            '{{ contact.country }}',
            '{{ contact.zip_code }}',
            '{{ contact.reason }}'
        ];

        $scope.userAttributes = [
            '{{ user.username }}',
            '{{ user.first_name }}',
            '{{ user.last_name }}',
            '{{ user.email }}',
            '{{ user.home_phone }}',
            '{{ user.office_phone }}',
            '{{ user.mobile_phone }}'
        ];

        $scope.addNewScriptAnimation = function(script) {
            var scriptItem = document.querySelector('#script-list-item-' + script.template_id);
            var scriptItemParent = document.querySelector('#script-list-item-' + script.template_id).parentNode;

            scriptItem.classList.add('show-open-edit');
            scriptItemParent.classList.add('show-open-edit');

            var scriptItemTransitionEnd = function(e) {
                if(e.propertyName === 'transform') {
                    scriptItemParent.style.overflow = 'inherit';
                    scriptItem.removeEventListener('transitionend', scriptItemTransitionEnd);
                    $scope.$apply();
                }
            };

            scriptItem.addEventListener('transitionend', scriptItemTransitionEnd);
        };

        $scope.createScript = function() {

            const teamId = authService.getTeamId();
            const userId = authService.getUserId();

            const newScript = {
                template_id: $scope.uuidv4(),
                team_id: teamId,
                user_id: userId,
                name: 'My Script Template',
                description: '',
                html_text: '',
                is_new: true,
                editing: true
            };
            $scope.data.scripts.unshift(newScript);

            setTimeout(function() {
                $scope.toggleFieldOptions(newScript);
                $scope.addNewScriptAnimation(newScript);
            });
        };

        $scope.uuidv4 = function() {
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
                let r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
                return v.toString(16);
            });
        };

        $scope.saveScript = function(item) {
            item.savingScript = true;

            const teamId = authService.getTeamId();
            const userId = authService.getUserId();
            const html_text = item.quill.root.innerHTML;

            const request = {
                team_id: teamId,
                user_id: userId,
                template_id: item.template_id,
                html_text: html_text,
                name: item.name,
                type: 'script'
            };

            templateService[(item.is_new) ? 'createTemplate' : 'updateTemplate'](request)
                .then(function(result) {
                    $scope.data.scripts = $scope.data.scripts.map(script => {
                        if (script.template_id === item.template_id) {
                            script.html_text = html_text;
                            script.template_id = result.template_id;
                        }
                        return script;
                    });
                    item.savingScript = false;
                    // we need a short delay before hide edit mode div
                    setTimeout(function(){
                        $scope.disableEditMode(item);
                    }, 100);
                    $scope.$apply();
                    notificationToastService.showSuccessToast('fa-light fa-clipboard-check', (item.is_new) ? 'Script has been created!' : 'Script has been updated!');
                }, function(err) {
                    console.error(err);
                    item.savingScript = false;
                    var message = (item.is_new) ? 'We were unable to create script. Try again later.' : 'We were unable to update script. Try again later.';
                    $scope.$apply();
                    notificationToastService.showErrorToast('fa-light fa-triangle-exclamation', message);
                });
        };

        $scope._deleteScript = function(item) {
            const teamId = authService.getTeamId();
            const userId = authService.getUserId();
            const request = {
                team_id: teamId,
                user_id: userId,
                template_id: item.template_id
            };

            return templateService.deleteTemplate(request);
        };

        $scope.deleteScript = function(script) {
            swal({
                title: 'Delete Script',
                text: 'Do you want to delete ' + script.name + ' ' + '?',
                buttons: ['No', 'Yes'],
                dangerMode: true,
                closeOnClickOutside: false
            }).then((confirm) => {
                if (!confirm) { return; }

                $scope.loading.scripts = true;

                $scope._deleteScript(script)
                    .then(results => {
                        $scope.loadScriptsPage(false);
                        $scope.$apply();

                        notificationToastService.showSuccessToast('fa-light fa-trash-check', 'Script has been deleted!');
                    })
                    .catch(err => {
                        console.error(err);
                        $scope.loading.scripts = false;
                        $scope.$apply();

                        notificationToastService.showErrorToast('fa-light fa-triangle-exclamation', 'We were unable to delete script. Try again later.');
                    });
            });
        };

        $scope.editNameField = function(script) {

            const teamId = authService.getTeamId();
            const userId = authService.getUserId();

            const request = {
                team_id: teamId,
                user_id: userId,
                template_id: script.template_id,
                name: script.name
            };

            templateService.updateTemplate(request)
                .then(function() {
                    $scope.$apply();
                    notificationToastService.showSuccessToast('fa-light fa-clipboard-check', 'Script name has been updated!');
                }, function(err) {
                    console.error(err);
                    $scope.$apply();
                    notificationToastService.showErrorToast('fa-light fa-triangle-exclamation', 'We were unable to update script. Try again later.');
                });
        };

        $scope.disableEditMode = function(script) {
            var detailsDiv = document.getElementById('scriptDetails' + script.template_id + 'Div');
            var toolbar = detailsDiv.querySelector('.details-body .ql-toolbar.ql-snow');

            script.editing = false;
            $(toolbar).removeClass('show');
            script.quill.enable(false);
            script.quill.root.dataset.placeholder = 'Empty Template';
        };

        $scope.enableEditMode = function(script) {
            var detailsDiv = document.getElementById('scriptDetails' + script.template_id + 'Div');
            var toolbar = detailsDiv.querySelector('.details-body .ql-toolbar.ql-snow');

            script.editing = true;
            script.quill.root.dataset.placeholder = 'Compose your html text...';
            script.quill.enable(true);
            $(toolbar).addClass('show');
        };

        $scope.editOrSave = function(script) {
            var detailsDiv = document.getElementById('scriptDetails' + script.template_id + 'Div');
            detailsDiv.querySelector('.action-btn').blur();

            $scope[(script.editing) ? 'saveScript' : 'enableEditMode'](script);
        };

        $scope.quillAttributesHandler = function(value) {
            if (value) {
                const cursorPosition = this.quill.getSelection().index;
                this.quill.insertText(cursorPosition, value);
                this.quill.setSelection(cursorPosition + value.length);
            }
        };

        $scope.quillUpdateContent = function(script) {
            const delta = script.quill.clipboard.convert(script.html_text);
            script.quill.setContents(delta, 'silent');
        };

        $scope.initQuill = function(script) {
            if (!script.quill) {
                script.quill = new Quill('#script_' + script.template_id, {
                    placeholder: 'Empty Script',
                    theme: 'snow',
                    modules: {
                        toolbar: {
                            container: [
                                [
                                    { 'header': [1, 2, 3, 4, 5, 6, false] },
                                    { 'size': ['small', false, 'large', 'huge'] },
                                    'bold', 'italic', 'underline', 'link',
                                    { 'list': 'ordered' }, { 'list': 'bullet' }, { 'align': [] }
                                ],
                                [
                                    { 'contactAttributes': $scope.contactAttributes },
                                    { 'userAttributes': $scope.userAttributes }
                                ]
                            ],
                            handlers: {
                                'contactAttributes': $scope.quillAttributesHandler,
                                'userAttributes': $scope.quillAttributesHandler
                            }
                        }
                    }
                });
                $scope.quillUpdateContent(script);

                if(script.editing) {
                    $scope.enableEditMode(script);
                } else {
                    script.quill.enable(false);
                }
            }
        };

        $scope.hideDetailsArea = function(detailsDiv, script) {
            detailsDiv.classList.remove('show');
            script.detailsVisible = !script.detailsVisible;
            detailsDiv.querySelector('.details-body #script_' + script.template_id).style.display = 'none';
            detailsDiv.querySelector('.details-body').style.display = 'none';
        };

        $scope.showDetailsArea = function(detailsDiv, script) {
            detailsDiv.querySelector('.details-body').style.display = 'block';
            detailsDiv.querySelector('.details-body #script_' + script.template_id).style.display = 'block';
            detailsDiv.classList.add('show');
            script.detailsVisible = true;
        };

        $scope.toggleFieldOptions = function (script) {
            var detailsDiv = document.getElementById('scriptDetails' + script.template_id + 'Div');
            if(script.detailsVisible && detailsDiv.classList.contains('show')) {
                $scope.hideDetailsArea(detailsDiv, script);
            } else {
                if(!script.detailsVisible) {
                    $scope.initQuill(script);
                }
                $scope.showDetailsArea(detailsDiv, script);
            }
        };

        $scope.loadScripts = function(skip, limit, loadingVariable, callback) {
            $scope.loading[loadingVariable || 'scripts'] = true;

            const teamId = authService.getTeamId();
            const userId = authService.getUserId();

            const request = {
                team_id: teamId,
                user_id: userId,
                type: 'script',
                skip: skip || 0,
                limit: limit || $scope.paginationScripts.itemsPerPage
            };

            templateService.getTemplates(request)
                .then(results => {
                    $scope.data.scripts = results.templates || [];
                    $scope.paginationScripts.totalItems = results.totalItems || 0;

                    $scope.loading[loadingVariable || 'scripts'] = false;
                    $scope.$apply();
                    callback();
                })
                .catch(err => {
                    console.error(err);
                    $scope.loading[loadingVariable || 'scripts'] = false;
                    $scope.$apply();
                    callback();
                    notificationToastService.showErrorToast('fa-light fa-triangle-exclamation', 'We were unable to load scripts. Try again later.');
                });
        };

        $scope.setPageScripts = function (pageNo) {
            $scope.paginationScripts.currentPage = pageNo;
        };

        $scope.setItemsPerPageScripts = function(num) {
            $scope.paginationScripts.itemsPerPage = num;
            $scope.paginationScripts.currentPage = 1; //reset to first page
        };

        $scope.loadScriptsPage = function(isAnimated) {
            var skip = (($scope.paginationScripts.currentPage - 1) * $scope.paginationScripts.itemsPerPage);
            $scope.loadScripts(skip, $scope.paginationScripts.itemsPerPage, 'scripts', function() {
                if(isAnimated) {
                    $([document.documentElement, document.body]).animate({
                        scrollTop: $("#scriptsSection").offset().top - 80
                    }, 350);
                }
            });
        };

        $scope.onInit = function() {
            $scope.loadScripts(0, $scope.paginationScripts.itemsPerPage, 'scriptsFirstLoad', function() {});
        };

        if (authService.hasAccount()) {
            $scope.onInit();
        }
        else {
            $scope.$on('auth_complete', function(event, args) {
                $scope.onInit();
            });
        }
    }
    module.exports = ScriptController;
})();
